import React, { useEffect, useState } from "react";
import FilterEmp from "./FilterEmp";
import DateRange from "./DateRange";
import ContentList from "./ContentList";
import InstoreTableViewData from "./InstoreTableViewData";
import OnlineTableViewData from "../OnlineOrder/OnlineTableViewData";
import MainOnline from "../OnlineOrder/MainOnline";
import { useAuthDetails } from "../../../Common/cookiesHelper";
import DateRangeComponent from "../../../reuseableComponents/DateRangeComponent";
import { useParams } from "react-router-dom";
import useMultiSelect from "../../../hooks/useMultiSelect";
// const transactionsList = [
//   {
//     title: "Cash & Credit Card",
//   },
//   {
//     title: "Cash",
//   },
//   {
//     title: "Credit Card",
//   },
// ];
// const transactionsListSuperadmin = [
//   {
//     title: "Cash & Credit Card",
//   },
//   {
//     title: "Cash",
//   },
//   {
//     title: "Credit Card",
//   },
//   {
//     title: "Unpaid",
//   },
// ];
const InStoreTransactionsList = [
  { id: 1, title: "Credit", apivalue: "Credit" },
  { id: 2, title: "Debit", apivalue: "Debit" },
  { id: 3, title: "Cash", apivalue: "Cash" },
  { id: 4, title: "Food EBT", apivalue: "FoodEbt" },
  { id: 5, title: "Cash EBT", apivalue: "CashEbt" },
  { id: 6, title: "Gift Card", apivalue: "Gift Card" },
  { id: 7, title: "Store Credit", apivalue: "Store Credit" },
  { id: 8, title: "Lottery", apivalue: "Lottery" },
];
const MainInStore = () => {
  const {
    LoginGetDashBoardRecordJson,
    LoginAllStore,
    userTypeData,
    GetSessionLogin,
    future_date,
  } = useAuthDetails();
  console.log("userTypeData",userTypeData)
  const { start_date, end_date, order_env, order_method } = useParams();
  const [activeTab, setActiveTab] = useState("offline");

  const [OrderSourceData, setOrderSourceData] = useState(null);
  const [OrderTypeData, setOrderTypeData] = useState(
    order_env ? "Closed" : "New"
  );
  const [OnlSearchIdData, setOnlSearchIdData] = useState("");
  const [OffSearchIdData, setOffSearchIdData] = useState("");
  const [EmployeeIDData, setEmployeeIDData] = useState(null);
  const [searchId, setSearchId] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [isloading, setIsLoading] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState("Both");

  const [dateRangefromorderTypePage, setDateRangeFromOrderTypePage] = useState(
    {}
  );
  const extraItem = 
  { id: 9, title: "Unpaid", apivalue: "Unpaid" }
  const enhancedInStoreTransactionsList = [
    ...InStoreTransactionsList,
    ...(userTypeData?.login_type === 'superadmin' ? [extraItem] : []),
  ];
  console.log("enhancedInStoreTransactionsList",enhancedInStoreTransactionsList)
  const {
    selectedItems: selectedInStoreTransactionsList,
    allOptionSelected: allInStoreTransactionsSelected,
    handleOptionClick: handleInStoreTransactionsClick,
  } = useMultiSelect(enhancedInStoreTransactionsList);

  // let futureDate = "5"
  const merchant_id = LoginGetDashBoardRecordJson?.data?.merchant_id;
  const handleDateRangeChange = (dateRange) => {
    setSelectedDateRange(dateRange);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setSearchId("");
  };

  // console.log("OrderTypeData",OrderTypeData)
  useEffect(() => {
    setActiveTab(order_env === "5" ? "online" : "offline");
    start_date && setSelectedDateRange({ start_date, end_date });
    // order_env === "5" && setOrderTypeData("Closed")
  }, [order_env, start_date, end_date]);
  // console.log("dateRangefromorderTypePage", dateRangefromorderTypePage);
  const handleFilterDataChange = (OrderSource, OrderType, SearchId) => {
    setOrderSourceData(OrderSource);
    setOrderTypeData(OrderType);
    setOnlSearchIdData(SearchId);
  };

  const handleFilterEmpDataChange = (OrderSource, EmployeeID, SearchId) => {
    setOrderSourceData(OrderSource);
    setEmployeeIDData(EmployeeID);
    setOffSearchIdData(SearchId);
  };
  const renderInStoreContent = () => {
    if (activeTab === "online") {
      return (
        <>
          <MainOnline
            onFilterDataChange={handleFilterDataChange}
            searchId={searchId}
            setSearchId={setSearchId}
            order_env={order_env}
            selectedTransaction={selectedTransaction}
            setSelectedTransaction={setSelectedTransaction}
          />
        </>
      );
    } else if (activeTab === "offline") {
      return (
        <>
          <FilterEmp
            onFilterEmpDataChange={handleFilterEmpDataChange}
            searchId={searchId}
            setSearchId={setSearchId}
            // transactionsList={transactionsList}
            // transactionsListSuperadmin={transactionsListSuperadmin}
            selectedInStoreTransactionsList={selectedInStoreTransactionsList}
            allInStoreTransactionsSelected={allInStoreTransactionsSelected}
            handleInStoreTransactionsClick={handleInStoreTransactionsClick}
            InStoreTransactionsList={enhancedInStoreTransactionsList}
          />
        </>
      );
    }
    return null;
  };

  return (
    <>
      <div className="q-order-main-page">
        <div className="box">
          <div
            className="box_shadow_div_order"
            style={{ overflow: "unset", paddingBottom: "0px" }}
          >
            <div className="mb6_border">
              <div
                onClick={() => handleTabClick("offline")}
                className={`${
                  activeTab === "offline"
                    ? "bg-[#EBF2FF] text-[#0A64F9] font-circular-bold relative  cursor-pointer"
                    : "bg-white text-[#6A6A6A]  cursor-pointer"
                } orderfilter`}
              >
                Orders (In-Store)
              </div>
              <div
                onClick={() => handleTabClick("online")}
                className={`${
                  activeTab === "online"
                    ? "bg-[#EBF2FF] text-[#0A64F9]  font-circular-bold relative cursor-pointer"
                    : "bg-white text-[#6A6A6A] cursor-pointer"
                } orderfilter`}
              >
                Orders (Online)
              </div>
            </div>
            {!searchId && (
              <>
                <div className="" style={{ paddingBottom: "0px" }}>
                  <h1 className="px-2 sm:px-5 pt-5">View and manage all orders. Filter by date range, payment type, or employee to find specific orders.</h1>
                  <DateRangeComponent
                    isloading={isloading}
                    selectedDateRange={selectedDateRange}
                    onDateRangeChange={handleDateRangeChange}
                    future_date={future_date}
                  />
                </div>
              </>
            )}
          </div>

          <div className="box_shadow_div_order">
            <div className="">{renderInStoreContent()}</div>
          </div>
          {activeTab === "offline" ? (
            ""
          ) : (
            <div className="q_dateRange_header">
              <ContentList />
            </div>
          )}

          <div className="q_dateRange_header">
            {activeTab === "offline" ? (
              <InstoreTableViewData
                OrderSourceData={OrderSourceData}
                EmployeeIDData={EmployeeIDData}
                OffSearchIdData={OffSearchIdData}
                selectedDateRange={selectedDateRange}
                merchant_id={merchant_id}
                userTypeData={userTypeData}
                setIsLoading={setIsLoading}
                order_method={order_method}
                activeTab={activeTab}
                selectedInStoreTransactionsList={
                  selectedInStoreTransactionsList
                }
                allInStoreTransactionsSelected={allInStoreTransactionsSelected}
              />
            ) : (
              <OnlineTableViewData
                OrderSourceData={OrderSourceData}
                OrderTypeData={OrderTypeData}
                OnlSearchIdData={OnlSearchIdData}
                selectedDateRange={selectedDateRange}
                merchant_id={merchant_id}
                userTypeData={userTypeData}
                order_env={order_env}
                order_method={order_method}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainInStore;

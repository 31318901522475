import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  BASE_URL,
  REFUND_REPORT,
  REFUND_REPORT_COUNT,
} from "../../../../Constants/Config";

const initialState = {
  loading: false,
  refundreportData: [],
  status: false,
  successMessage: "",
  error: "",

  countLoading: false,
  countData: 10,
};

// Generate pening , fulfilled and rejected action type
export const fetchRefundData = createAsyncThunk(
  "RefundDataList/fetchRefundData",
  async (data, { rejectWithValue }) => {
    try {
      const { token, ...dataNew } = data;
      const response = await axios.post(BASE_URL + REFUND_REPORT, dataNew, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data);
      if (response.data.status === true) {
        console.log(response.data);
        const arr = response.data.report_data;
        const status = response.data.status;
        return { arr, status };
      } else if (
        response.data.status === false &&
        response.data.msg === "No Data Found."
      ) {
        console.log("inside else", response.data);
        const arr = [];
        const status = false;
        return { arr, status };
      }
    } catch (error) {
      // throw new Error(error.response.data.message);
      const customError = {
        message: error.message,
        status: error.response ? error.response.status : "Network Error",
        data: error.response ? error.response.data : null,
      };
      return rejectWithValue(customError);
    }
  }
);

export const fetchRefundDataCount = createAsyncThunk(
  "products/fetchRefundDataCount",
  async (payload, { rejectWithValue }) => {
    const { token, ...dataNew } = payload;
    try {
      const response = await axios.post(
        BASE_URL + REFUND_REPORT_COUNT,
        dataNew,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.status) {
        return response?.data?.total_count;
      }
    } catch (error) {
      // throw new Error("Internal Server Error");
      const customError = {
        message: error.message,
        status: error.response ? error.response.status : "Network Error",
        data: error.response ? error.response.data : null,
      };
      return rejectWithValue(customError);
    }
  }
);
const RefundReportSlice = createSlice({
  name: "RefundDataList",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchRefundData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchRefundData.fulfilled, (state, action) => {
      state.loading = false;
      state.refundreportData = action.payload.arr;
      state.status = action.payload.status;
      state.error = "";
    });
    builder.addCase(fetchRefundData.rejected, (state, action) => {
      state.loading = false;
      state.refundreportData = {};
      state.error = action.error.message;
    });

    builder.addCase(fetchRefundDataCount.pending, (state) => {
      state.countLoading = true;
    });
    builder.addCase(fetchRefundDataCount.fulfilled, (state, action) => {
      state.countLoading = false;
      state.countData = action.payload;
    });
    builder.addCase(fetchRefundDataCount.rejected, (state, action) => {
      state.countLoading = false;
      state.countData = 10;
    });
  },
});

export default RefundReportSlice.reducer;

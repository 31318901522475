import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  BASE_URL,
  DETAILED_LOYALTY_POINTS,
  DETAILED_LOYALTY_POINTS_COUNT,
} from "../../../../Constants/Config";

const initialState = {
  loading: false,
  DetailedLoyaltyPointsReportArr: [],
  status: false,
  successMessage: "",
  error: "",
  offset: 0,
  limit: 10,
  hasMore: true,

  countLoading: false,
  countData: 10,
};

export const fetchDetailedLoyaltyPointsReportArr = createAsyncThunk(
  "DetailedLoyaltyPointsReport/fetchDetailedLoyaltyPointsReportArr",
  async (data, { rejectWithValue }) => {
    try {
      const { token, ...dataNew } = data;
      const res = await axios.post(
        BASE_URL + DETAILED_LOYALTY_POINTS,
        dataNew,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.data.status === true) {
        const arr = res.data.loyalty_points_data;
        const status = res.data.status;
        const totalCustomerCount = res.data.total_count;

        return { arr, status, totalCustomerCount };
      } else if (res.data.status === false) {
        const arr = [];
        const status = false;
        return { arr, status };
      }
    } catch (error) {
      const customError = {
        message: error.message,
        status: error.response ? error.response.status : "Network Error",
        data: error.response ? error.response.data : null,
      };
      return rejectWithValue(customError);
    }
  }
);

export const fetchDetailedLoyaltyPointsReportCount = createAsyncThunk(
  "products/fetchDetailedLoyaltyPointsReportCount",
  async (payload, { rejectWithValue }) => {
    const { token, ...dataNew } = payload;
    try {
      const response = await axios.post(
        BASE_URL + DETAILED_LOYALTY_POINTS_COUNT,
        dataNew,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.status) {
        return response?.data?.loyalty_points_count;
      }
    } catch (error) {
      // throw new Error("Internal Server Error");
      const customError = {
        message: error.message,
        status: error.response ? error.response.status : "Network Error",
        data: error.response ? error.response.data : null,
      };
      return rejectWithValue(customError);
    }
  }
);

const DetailedLoyaltyPointsReportSlice = createSlice({
  name: "DetailedLoyaltyPointsReport",
  initialState,
  reducers: {
    emptyLoyaltyReportData: (state, action) => {
      state.loading = false;
      state.DetailedLoyaltyPointsReportArr = [];
      state.status = false;
      state.successMessage = "";
      state.error = "";
      state.offset = 0;
      state.limit = 10;
      state.hasMore = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDetailedLoyaltyPointsReportArr.pending, (state) => {
      state.loading = true;
      state.DetailedLoyaltyPointsReportArr = [];
    });
    builder.addCase(
      fetchDetailedLoyaltyPointsReportArr.fulfilled,
      (state, action) => {
        state.loading = false;
        state.status = action.payload.status;
        state.DetailedLoyaltyPointsReportArr = action.payload.arr;
        state.error = "";
        state.countData = action.payload.totalCustomerCount;
        // state.hasMore =
        //   +action?.payload?.totalCustomerCount >
        //   +state?.DetailedLoyaltyPointsReportArr?.length;

        // if (
        //   +action?.payload?.totalCustomerCount >
        //   +state?.DetailedLoyaltyPointsReportArr?.length
        // ) {
        //   state.limit += 10; // Increment only if there are new products
        // }
        // if (Array.isArray(action?.payload?.arr)) {
        //   state.DetailedLoyaltyPointsReportArr = action.payload.arr;
        // }
      }
    );
    builder.addCase(
      fetchDetailedLoyaltyPointsReportArr.rejected,
      (state, action) => {
        state.loading = false;
        state.DetailedLoyaltyPointsReportArr = [];
        state.error = action.error.message;
      }
    );

    builder.addCase(fetchDetailedLoyaltyPointsReportCount.pending, (state) => {
      state.countLoading = true;
      state.countData = [];
    });
    builder.addCase(
      fetchDetailedLoyaltyPointsReportCount.fulfilled,
      (state, action) => {
        state.countLoading = false;
        state.countData = action.payload;
      }
    );
    builder.addCase(
      fetchDetailedLoyaltyPointsReportCount.rejected,
      (state, action) => {
        state.countLoading = false;
        state.countData = [];
      }
    );
  },
});

export const { emptyLoyaltyReportData } =
  DetailedLoyaltyPointsReportSlice.actions;

export default DetailedLoyaltyPointsReportSlice.reducer;

import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import SpikeCharts from "../SpikeCharts";
import { useSelector } from "react-redux";
import {
  removeCurrencySign,
  getDefaultDateRange,
  getXAxisData,
  getYAxisTicks,
  getStartDateAndEndDate,
  getPercentValue,
  getLastRangeStartDate,
  dateInYYYYMMDD,
  updateDataObject,
  getMinMaxValues,
  dataExists,
  deleteKey,
} from "../../../Constants/utils";
import { useDispatch } from "react-redux";
import PasswordShow from "../../../Common/passwordShow";
import { getSalesCount } from "../../../Redux/features/Dashboard/Charts/salesCountSlice";
import { priceFormate } from "../../../hooks/priceFormate";

export const SalesCountChart = ({
  merchantId,
  activeType,
  presentDate,
  salesCountChartInView,
  salesCountChartRef,
}) => {
  const { handleCoockieExpire, getUnAutherisedTokenMessage } = PasswordShow();
  const dispatch = useDispatch();
  const [xAxisDates, setXAxisDates] = useState([]);
  const [salesCountChartState, setSalesCountChartState] = useState(false);
  const salesCountChart = useSelector((state) => state.salesCountChart);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const chartKey = "salesCountChart";

  const defaultObj = {
    percent: null,
    xAxisData: [],
    minValue: 0,
    maxValue: 0,
    yAxisOptions: [],
    type: "number",
    totalValue: priceFormate(0),
  };
  const [salesCountChartData, setSalesCountChartData] = useState(defaultObj);

  useEffect(() => {
    const bool =
      salesCountChart &&
      salesCountChart?.salesCountData &&
      salesCountChart?.salesCountData.length > 3 &&
      !salesCountChart.loading;

    const date = dateInYYYYMMDD(presentDate);
    const dashboardData = sessionStorage.getItem("dashboardData");
    const oldDashboardData = dashboardData ? JSON.parse(dashboardData) : {};

    const dashboardDataExists = dataExists(
      oldDashboardData,
      merchantId,
      activeType,
      date,
      chartKey
    );

    if (Object.keys(dashboardDataExists).length > 0) {
      return;
    } else {
      if (bool) {
        const getValue = (data, index) => {
          const value =
            data &&
            data.salesCountData &&
            data.salesCountData.length > 2 &&
            data.salesCountData[data.salesCountData.length - index];
          return value.sale_count && parseFloat(value.sale_count)
            ? parseFloat(value.sale_count).toFixed(2)
            : 0;
        };

        // for Percentage variance --------------------------------------
        const oldValue = getValue(salesCountChart, 2);
        const newValue = getValue(salesCountChart, 1);
        const percent = getPercentValue(oldValue, newValue);

        // for X Axis data ----------------------------------------------
        const dataOfXAxis = salesCountChart?.salesCountData.map(
          (item, index) => ({
            name: xAxisDates[index],
            uv: item.sale_count
              ? parseFloat(parseFloat(item.sale_count).toFixed(2))
              : 0,
          })
        );

        // for Y Axis Data ----------------------------------------------
        const minAndMaxValue =
          dataOfXAxis && dataOfXAxis.length > 0 && getMinMaxValues(dataOfXAxis);

        const temp = getYAxisTicks(minAndMaxValue.maxUv || 0);
        const yAxisOptions = temp.map((val) => parseFloat(val));

        const amt =
          salesCountChart.totalSalesCount &&
          !isNaN(salesCountChart.totalSalesCount)
            ? parseFloat(salesCountChart.totalSalesCount)
            : 0;
        const totalValue = priceFormate(amt);

        const obj = {
          percent: parseFloat(percent)?.toFixed(2),
          xAxisData: dataOfXAxis,
          minValue: minAndMaxValue.minUv,
          maxValue: minAndMaxValue.maxUv,
          yAxisOptions,
          type: "number",
          totalValue,
        };

        const newDashboardData = updateDataObject(
          date,
          activeType,
          merchantId,
          obj,
          chartKey,
          { ...oldDashboardData }
        );

        sessionStorage.setItem(
          "dashboardData",
          JSON.stringify(newDashboardData)
        );
        setSalesCountChartData(() => obj);
      }
    }
  }, [salesCountChart, xAxisDates]);

  useEffect(() => {
    if (salesCountChartInView && !salesCountChartState) {
      setSalesCountChartState(() => true);
    }
  }, [salesCountChartInView, salesCountChartState]);

  // setting date range
  useEffect(() => {
    const dates = getDefaultDateRange(activeType, presentDate);
    setSalesCountChartState(() => false);

    if (!dates || !dates.date_range) return;
    const data = getXAxisData(activeType, dates.date_range);
    setXAxisDates(data);
  }, [activeType, presentDate]);

  // sales count api
  const fetchSalesCount = async () => {
    try {
      setRefreshLoading(true);
      const dateRange = getDefaultDateRange(activeType, presentDate);
      if (
        dateRange &&
        dateRange?.date_range &&
        merchantId &&
        salesCountChartState
      ) {
        const { endDate } = getStartDateAndEndDate(dateRange?.date_range);

        const data = {
          merchant_id: merchantId,
          start_date: getLastRangeStartDate(dateRange?.date_range),
          end_date: endDate,
          date_range: dateRange?.date_range,
        };
        await dispatch(getSalesCount(data)).unwrap();
      }
    } catch (error) {
      if (error?.status === 401 || error?.response?.status === 401) {
        getUnAutherisedTokenMessage();
        handleCoockieExpire();
      } else if (error?.status === "Network Error") {
        // getNetworkError();
      }
    } finally {
      setRefreshLoading(false);
    }
  };

  useEffect(() => {
    const date = dateInYYYYMMDD(presentDate);
    const dashboardData = sessionStorage.getItem("dashboardData");
    const oldDashboardData = dashboardData ? JSON.parse(dashboardData) : {};

    const dashboardDataExists = dataExists(
      oldDashboardData,
      merchantId,
      activeType,
      date,
      chartKey
    );

    // setting already present api data from Session storage
    if (Object.keys(dashboardDataExists).length > 0) {
      setSalesCountChartData(() => dashboardDataExists);
      return;
    }

    if (merchantId && salesCountChartState) {
      fetchSalesCount();
    }
  }, [merchantId, salesCountChartState, presentDate, activeType]);

  // upon refresh - remove chart data from session storage
  useEffect(() => {
    if (refreshLoading) {
      deleteKey(merchantId, activeType, presentDate, chartKey);
    }
  }, [refreshLoading, presentDate, activeType, merchantId]);

  return (
    <Grid item xs={12} md={6} lg={6} ref={salesCountChartRef}>
      <SpikeCharts
        title={"Total Transactions"}
        growth={salesCountChartData.percent}
        mainOutlet={salesCountChartData.totalValue}
        amount={salesCountChartData.totalValue}
        activeType={activeType}
        xAxisData={salesCountChartData.xAxisData}
        maxValue={salesCountChartData.maxValue}
        minValue={salesCountChartData.minValue}
        yAxisOptions={salesCountChartData.yAxisOptions}
        type={salesCountChartData.type}
        formatFunction={removeCurrencySign}
        loading={
          salesCountChartData.yAxisOptions.length > 0
            ? false
            : salesCountChart?.loading || salesCountChart?.isCancel
        }
        presentDate={presentDate}
        refreshAPI={fetchSalesCount}
        refreshLoading={refreshLoading}
        yAxisText={"Total Transactions"}
      />
    </Grid>
  );
};

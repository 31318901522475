import { useMediaQuery } from "@mui/material";

const OrderSummery_pickup_delivery_status = ({ orderSummeryData }) => {
  const isMobile = useMediaQuery("(max-width:480px)");
  const statusAcceptedDesktop = // Done
    (
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 660 100.9">
        <g id="d_s_accpted">
          <line id="Line_83" x1="60.3" y1="39.1" x2="157.2" y2="39.1" fill="none" stroke="#2ec278" stroke-opacity="0"/>
          <path id="Path_667" d="M62.7,39.1h78" fill="none" stroke="#2ec278"/>
          <line id="Line_85" x1="140.5" y1="39.1" x2="618.2" y2="39.1" fill="none" stroke="#d4d4d4"/>
          <circle id="Ellipse_96" cx="38.5" cy="38.5" r="38.5" fill="#d0f3e2"/>
          <ellipse id="Ellipse_97" cx="39" cy="38.5" rx="30" ry="30.5" fill="#2ec278"/>
          <g id="Group_671">
            <circle id="Ellipse_98" cx="233.5" cy="38.5" r="38.5" fill="#acffd6" fill-opacity="0"/>
            <path id="Path_668" d="M233.6,8.2c16.8,0,30.3,13.6,30.3,30.3s-13.6,30.3-30.3,30.3-30.3-13.6-30.3-30.3h0c0-16.8,13.6-30.3,30.3-30.3h0Z" fill="#f1f1f1"/>
          </g>
          <g id="Group_672">
            <ellipse id="Ellipse_99" cx="424" cy="38.5" rx="38" ry="38.5" fill="#ffeaaf" fill-opacity="0"/>
            <circle id="Ellipse_100" cx="424" cy="39" r="31" fill="#f1f1f1"/>
          </g>
          <g id="Group_673">
            <ellipse id="Ellipse_101" cx="621" cy="38.5" rx="39" ry="38.5" fill="#cffbff" fill-opacity="0"/>
            <ellipse id="Ellipse_102" cx="621" cy="38.5" rx="30" ry="30.5" fill="#f1f1f1"/>
          </g>
          <g id="thumbs_up">
            <g id="Group_676">
              <g id="Group_675">
                <path id="Path_672" d="M632.3,42.3c0-.8-.7-1.5-1.5-1.5.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5h-6.2c.7-1.7,1-3.5.9-5.3,0-1.2-1-2.3-2.3-2.3h-.5c-.3,0-.6.2-.7.6-.7,2.9-1.9,6.2-4.9,6.9v10.9l2.6.9c.8.3,1.6.4,2.4.4h7.1c.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5h1.5c.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5h0c.8,0,1.5-.7,1.5-1.5h.1Z" fill="#d9d9d9"/>
              </g>
            </g>
            <g id="Group_678">
              <g id="Group_677">
                <path id="Path_673" d="M613.4,36.3h-3c-.4,0-.8.3-.8.8h0v13.6c0,.4.3.8.8.8h3c1.3,0,2.3-1,2.3-2.3v-10.6c0-1.3-1-2.3-2.3-2.3ZM613.4,48.4c-.4,0-.8-.3-.8-.8s.3-.8.8-.8.8.3.8.8h0c0,.4-.3.8-.8.8Z" fill="#d9d9d9"/>
              </g>
            </g>
            <g id="Group_680">
              <g id="Group_679">
                <path id="Path_674" d="M620.1,28.9l-1.1-1.1c-.3-.3-.8-.3-1.1,0s-.3.8,0,1.1l1.1,1.1c.3.3.8.3,1.1,0s.3-.8,0-1.1h0Z" fill="#d9d9d9"/>
              </g>
            </g>
            <g id="Group_682">
              <g id="Group_681">
                <path id="Path_675" d="M628.6,27.9c-.3-.3-.8-.3-1.1,0h0l-1.1,1.1c-.3.3-.3.8,0,1.1s.8.3,1.1,0l1.1-1.1c.3-.3.3-.8,0-1.1h0Z" fill="#d9d9d9"/>
              </g>
            </g>
            <g id="Group_684">
              <g id="Group_683">
                <path id="Path_676" d="M623.3,25.6c-.4,0-.8.3-.8.7h0v1.6c0,.4.4.7.8.7s.7-.3.7-.7v-1.6c0-.4-.3-.8-.7-.8h0Z" fill="#d9d9d9"/>
              </g>
            </g>
          </g>
          <g id="verified-24px">
            <g id="Group_685">
              <rect id="Rectangle_309" x="24" y="25" width="28" height="28" fill="none"/>
            </g>
            <g id="Group_686">
              <path id="Path_677" d="M51.5,39.7l-2.8-3.3.4-4.3-4.2-1-2.2-3.7-4,1.7-4-1.7-2.2,3.7-4.2.9.4,4.3-2.9,3.3,2.8,3.3-.4,4.3,4.2,1,2.2,3.7,4-1.7,4,1.7,2.2-3.7,4.2-1-.4-4.3,2.9-3.3h0ZM36.5,45.2l-4.4-4.4,1.7-1.7,2.7,2.7,6.8-6.9,1.7,1.7-8.6,8.6h0Z" fill="#fff"/>
            </g>
          </g>
          <circle id="Ellipse_103" cx="141" cy="39" r="5" fill="#2ec278"/>
          <g id="inventory_2_black_24dp">
            <g id="Group_4910">
              <rect id="Rectangle_1309" x="221" y="27" width="26" height="26" fill="none"/>
            </g>
            <g id="Group_4911">
              <path id="Path_3926" d="M242.3,29.6h-16.6c-1.1,0-2,.9-2.1,2.1v3.1c0,.7.4,1.4,1,1.8v11.8c0,1.1,1,2,2.1,2.1h14.6c1.1,0,2-1,2.1-2.1v-11.8c.6-.4,1-1,1-1.8v-3.1c0-1.1-.9-2-2.1-2.1ZM237.1,42.1h-6.2v-2.1h6.2v2.1ZM242.3,34.8h-16.6v-3.1h16.6v3.1Z" fill="#d9d9d9"/>
            </g>
          </g>
          <g id="Group_5030">
            <g id="Group_5030-2">
              <path id="Path_4200" d="M429.3,40.4c-3.1,0-5.7,2.5-5.7,5.7s2.5,5.7,5.7,5.7,5.7-2.5,5.7-5.7h0c0-3.1-2.5-5.7-5.7-5.7h0M428.2,48.9l-2.3-2.3.8-.8,1.5,1.5,3.7-3.7.8.8-4.5,4.5Z" fill="#d9d9d9"/>
              <path id="Path_4201" d="M421.9,46.1c0-4.1,3.3-7.4,7.4-7.4s1.9.2,2.8.5v-2.6c.6-.3.9-.9.9-1.6v-2.8c0-1-.9-1.8-1.9-1.9h-15.1c-1,0-1.8.9-1.9,1.9v2.8c0,.7.4,1.3.9,1.6v10.6c0,1,.9,1.8,1.9,1.9h5.5c-.4-1-.7-2-.7-3.1M416.1,32.2h15.1v2.8h-15.1v-2.8Z" fill="#d9d9d9"/>
            </g>
          </g>
          <g>
            <path d="M407.6,87.8v-8.6h4c.5,0,.9.2,1.2.5s.6.6.7.9.2.8.2,1.3-.2,1.3-.5,1.7c-.3.5-.9.8-1.5.9h-3.1v3.2h-1.1.1ZM408.7,83.6h2.9c.3,0,.5-.2.7-.4.2-.2.3-.4.4-.6s.1-.5.1-.7,0-.5-.1-.7c0-.2-.2-.4-.4-.6-.2-.2-.4-.3-.7-.4h-2.9s0,3.4,0,3.4ZM412.8,87.8l-1.7-3.5,1.1-.3,1.9,3.8h-1.3Z" fill="#c9c9c9"/>
            <path d="M418.1,88c-.6,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2c-.3-.5-.4-1.1-.4-1.8s.1-1.3.4-1.8.6-.9,1.1-1.2c.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8s-.8-.6-1.4-.6-1.2.2-1.5.6-.5,1-.5,1.8.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3s-1.1.5-1.7.5h.1ZM415.7,84.9v-.9h4.8v.9h-4.8Z" fill="#c9c9c9"/>
            <path d="M424.1,88c-.5,0-.9,0-1.2-.3s-.6-.4-.7-.7-.2-.6-.2-1,0-.6.2-.9.3-.5.5-.6.5-.3.8-.4c.3,0,.7-.2,1-.2s.8,0,1.2-.2.8,0,1.1-.2l-.4.2c0-.6-.1-1-.3-1.3s-.7-.4-1.2-.4-.7,0-1,.3c-.3.2-.5.5-.6.9l-1-.3c.2-.6.5-1,.9-1.3s1-.5,1.8-.5,1.1,0,1.5.3c.4.2.7.5.9,1,0,.2.2.4.2.7v4.7h-.9v-1.6h.2c-.2.7-.6,1.1-1,1.3-.4.3-1,.4-1.6.4h-.2ZM424.3,87.1c.4,0,.7,0,1-.2s.5-.3.7-.5.3-.5.3-.7v-1.3l.4.2c-.3,0-.7,0-1,0s-.7,0-1,0-.6,0-.8.2c-.2,0-.3,0-.5.2-.2,0-.3.2-.4.3,0,0-.1.3-.1.5s0,.3.1.5.2.3.4.4c.2,0,.4.2.7.2h.2Z" fill="#c9c9c9"/>
            <path d="M431.6,88c-.6,0-1.1,0-1.6-.4-.4-.3-.8-.7-1-1.2s-.4-1.1-.4-1.7.1-1.2.4-1.7c.2-.5.6-.9,1-1.2.4-.3.9-.4,1.5-.4s1.1,0,1.5.4.7.7.9,1.2c.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.5.9-.9,1.2-.4.3-.9.5-1.5.5h.1ZM431.7,87c.4,0,.8,0,1.1-.3s.5-.5.6-.9.2-.8.2-1.3,0-.9-.2-1.3c-.1-.4-.3-.7-.6-.9-.3-.2-.6-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2,0,.9.2,1.2c.1.4.4.7.6.9.3.2.6.3,1.1.3v.2ZM433.6,87.8v-4.8h-.1v-3.9h1.1v8.6h-1Z" fill="#c9c9c9"/>
            <path d="M437.1,90.7l1.3-3.4v1l-2.8-7h1.1l2.2,5.6h-.4l2.1-5.6h1.1l-3.5,9.4h-1.1Z" fill="#c9c9c9"/>
          </g>
          <g>
            <path d="M7.7,96.3l3.3-10h2.1l3.3,10h-1.5l-3-9.2h.4l-3,9.2h-1.6ZM9.4,94.1v-1.4h5.2v1.4h-5.2Z"/>
            <path d="M20.8,96.6c-.8,0-1.4-.2-2-.5s-1-.8-1.2-1.4c-.3-.6-.4-1.3-.4-2s.2-1.5.4-2.1.7-1.1,1.3-1.4c.5-.3,1.2-.5,2-.5s1.5.2,2.1.6,1,1,1.2,1.7l-1.4.4c-.1-.4-.4-.8-.7-1-.3-.2-.7-.4-1.1-.4s-.9,0-1.2.3c-.3.2-.5.5-.7.9-.1.4-.2.8-.2,1.3,0,.8.2,1.4.5,1.9.4.5.9.7,1.6.7s.8,0,1.1-.3.5-.5.7-.9l1.5.3c-.2.7-.7,1.3-1.2,1.7-.6.4-1.3.6-2.1.6h-.2Z"/>
            <path d="M28.5,96.6c-.8,0-1.4-.2-2-.5s-1-.8-1.2-1.4c-.3-.6-.4-1.3-.4-2s.2-1.5.4-2.1.7-1.1,1.3-1.4c.5-.3,1.2-.5,2-.5s1.5.2,2.1.6,1,1,1.2,1.7l-1.4.4c-.1-.4-.4-.8-.7-1-.3-.2-.7-.4-1.1-.4s-.9,0-1.2.3c-.3.2-.5.5-.7.9-.1.4-.2.8-.2,1.3,0,.8.2,1.4.5,1.9.4.5.9.7,1.6.7s.8,0,1.1-.3.5-.5.7-.9l1.5.3c-.2.7-.7,1.3-1.2,1.7-.6.4-1.3.6-2.1.6h-.2Z"/>
            <path d="M36.3,96.6c-.7,0-1.4-.2-2-.5s-1-.8-1.3-1.4-.5-1.3-.5-2,.2-1.5.5-2.1.7-1.1,1.3-1.4,1.2-.5,1.9-.5,1.4.2,2,.5c.5.4.9.9,1.2,1.5.3.7.4,1.4.3,2.3h-1.5v-.5c0-.9-.2-1.5-.5-1.9s-.8-.6-1.5-.6-1.3.2-1.6.7c-.4.5-.6,1.1-.6,2s.2,1.5.6,1.9c.4.5.9.7,1.6.7s.8,0,1.2-.3c.3-.2.6-.5.8-.9l1.4.5c-.3.7-.7,1.2-1.3,1.6s-1.3.6-2,.6v-.2ZM33.6,93v-1.1h5.4v1.1h-5.4Z"/>
            <path d="M41.1,99.7v-10.9h1.3v5.4h.2v5.5h-1.5ZM44.6,96.6c-.7,0-1.3-.2-1.8-.5-.5-.4-.9-.8-1.1-1.4-.3-.6-.4-1.3-.4-2s0-1.4.4-2c.2-.6.6-1.1,1.1-1.4s1.1-.5,1.8-.5,1.3.2,1.8.5.9.8,1.2,1.4.4,1.3.4,2,0,1.4-.4,2c-.3.6-.7,1.1-1.2,1.4s-1.1.5-1.8.5ZM44.4,95.2c.5,0,.8,0,1.2-.4.3-.2.5-.6.7-1s.2-.9.2-1.3,0-.9-.2-1.3-.4-.7-.7-1c-.3-.2-.7-.4-1.2-.4s-.8,0-1.1.3c-.3.2-.5.5-.7.9s-.2.9-.2,1.4,0,1,.2,1.4.4.7.7.9c.3.2.7.3,1.2.3v.2Z"/>
            <path d="M48.7,90v-1.2h5.1v1.2h-5.1ZM53.8,96.3c-.5,0-.9,0-1.4,0s-.9,0-1.2-.3c-.4-.2-.6-.4-.8-.8-.2-.3-.2-.6-.3-1v-7.8h1.5v7.4c0,.2,0,.4,0,.6.2.3.4.5.8.5h1.3v1.2h0Z"/>
            <path d="M58.4,96.6c-.7,0-1.4-.2-2-.5s-1-.8-1.3-1.4-.5-1.3-.5-2,.2-1.5.5-2.1.7-1.1,1.3-1.4,1.2-.5,1.9-.5,1.4.2,2,.5c.5.4.9.9,1.2,1.5.3.7.4,1.4.3,2.3h-1.5v-.5c0-.9-.2-1.5-.5-1.9s-.8-.6-1.5-.6-1.3.2-1.6.7c-.4.5-.6,1.1-.6,2s.2,1.5.6,1.9c.4.5.9.7,1.6.7s.8,0,1.2-.3c.3-.2.6-.5.8-.9l1.4.5c-.3.7-.7,1.2-1.3,1.6s-1.3.6-2,.6v-.2ZM55.7,93v-1.1h5.4v1.1h-5.4Z"/>
            <path d="M66.4,96.6c-.7,0-1.3-.2-1.8-.5s-.9-.8-1.2-1.4-.4-1.3-.4-2,0-1.4.4-2c.3-.6.7-1.1,1.2-1.4s1.1-.5,1.8-.5,1.3.2,1.8.5.9.8,1.1,1.4.4,1.3.4,2,0,1.4-.4,2-.6,1.1-1.1,1.4c-.5.4-1.1.5-1.8.5ZM66.6,95.2c.5,0,.9,0,1.2-.3.3-.2.5-.5.7-.9,0-.4.2-.9.2-1.4s0-1-.2-1.4-.4-.7-.7-.9c-.3-.2-.7-.3-1.1-.3s-.9,0-1.2.4c-.3.2-.5.6-.7,1s-.2.8-.2,1.3,0,.9.2,1.3.4.7.7,1c.3.2.7.4,1.2.4v-.2ZM68.6,96.3v-5.4h-.2v-4.6h1.5v10h-1.3Z"/>
          </g>
          <g>
            <path d="M212.1,87.8v-8.6h4c.5,0,.9.2,1.2.5.3.2.6.6.7.9.2.4.2.8.2,1.3s0,.9-.2,1.3-.4.7-.7.9-.7.4-1.2.5h-2.9v3.2h-1.1,0ZM213.2,83.6h2.9c.3,0,.5-.2.7-.4.2-.2.3-.4.4-.6s.1-.5.1-.7,0-.5-.1-.7c0-.2-.2-.4-.4-.6s-.4-.3-.7-.4h-2.9s0,3.4,0,3.4Z" fill="#c9c9c9"/>
            <path d="M221.1,88c-.5,0-.9,0-1.2-.3-.3-.2-.6-.4-.7-.7s-.2-.6-.2-1,0-.6.2-.9.3-.5.5-.6c.2-.2.5-.3.8-.4.3,0,.7-.2,1-.2s.8,0,1.2-.2c.4,0,.8,0,1.1-.2l-.4.2c0-.6-.1-1-.3-1.3s-.7-.4-1.2-.4-.7,0-1,.3c-.3.2-.5.5-.6.9l-1-.3c.2-.6.5-1,.9-1.3s1-.5,1.8-.5,1.1,0,1.5.3c.4.2.7.5.9,1,0,.2.2.4.2.7v4.7h-.9v-1.6h.2c-.2.7-.6,1.1-1,1.3-.4.3-1,.4-1.6.4h-.2ZM221.3,87.1c.4,0,.7,0,1-.2.3,0,.5-.3.7-.5s.3-.5.3-.7v-1.3l.4.2c-.3,0-.7,0-1,0s-.7,0-1,0-.6,0-.8.2c-.2,0-.3,0-.5.2-.2,0-.3.2-.4.3,0,0-.1.3-.1.5s0,.3.1.5c0,.2.2.3.4.4s.4.2.7.2h.2Z" fill="#c9c9c9"/>
            <path d="M228.8,88c-.7,0-1.2,0-1.7-.4s-.8-.7-1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2c0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5h-.3Z" fill="#c9c9c9"/>
            <path d="M232.8,87.8v-8.6h1.1v5.3l2.7-3.1h1.4l-2.9,3.2,3.2,3.2h-1.5l-2.9-3.1v3.1h-1.1Z" fill="#c9c9c9"/>
            <path d="M239,80.2v-1.1h1.1v1.1h-1.1ZM239,87.8v-6.5h1.1v6.5h-1.1Z" fill="#c9c9c9"/>
            <path d="M241.9,87.8v-6.5h1v1.7h.1v4.7h-1.1ZM246.5,87.8v-4.1c0-.3-.1-.5-.3-.8-.1-.2-.3-.4-.5-.5s-.5-.2-.9-.2-.5,0-.7,0c-.2,0-.4.2-.6.4-.2.2-.3.4-.4.7,0,.3-.1.6-.1,1l-.7-.2c0-.6.1-1.2.3-1.6.2-.5.5-.8,1-1s.9-.4,1.4-.4.8,0,1.1.2.5.3.7.5.4.5.5.7c.1.3.2.5.2.8v4.4h-1.1.1Z" fill="#c9c9c9"/>
            <path d="M251.7,88c-.6,0-1.1,0-1.6-.4-.4-.3-.8-.7-1-1.2s-.4-1.1-.4-1.7.1-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1,0,1.5.4.7.7.9,1.2.3,1.1.3,1.7-.1,1.2-.3,1.7-.5.9-.9,1.2c-.4.3-.9.5-1.5.5h.1ZM251.7,90.9c-.3,0-.7,0-1-.2-.3,0-.6-.3-.9-.5s-.5-.5-.7-.8l1-.5c.2.3.4.5.7.7.3,0,.6.2.9.2s.8,0,1-.2c.3,0,.5-.4.6-.7.1-.3.2-.7.2-1.1v-1.8h.1v-4.7h.9v7.5c0,.5-.3.8-.5,1.1-.2.3-.6.5-1,.7-.4,0-.8.2-1.4.2h0ZM251.8,87c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.3,0-.9-.2-1.3c-.1-.4-.3-.7-.6-.9s-.6-.3-1-.3-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2,0,.9.2,1.2c.1.4.4.7.6.9.3.2.6.3,1.1.3v.2Z" fill="#c9c9c9"/>
          </g>
          <g>
            <path d="M591.8,87.8v-8.6h4c.5,0,.9.2,1.2.5s.6.6.7.9.2.8.2,1.3,0,.9-.2,1.3-.4.7-.7.9c-.3.2-.7.4-1.2.5h-2.9v3.2h-1.1ZM592.9,83.6h2.9c.3,0,.5-.2.7-.4.2-.2.3-.4.4-.6s0-.5,0-.7,0-.5,0-.7c0-.2-.2-.4-.4-.6s-.4-.3-.7-.4h-2.9s0,3.4,0,3.4Z" fill="#c9c9c9"/>
            <path d="M599.3,80.2v-1.1h1.1v1.1h-1.1ZM599.3,87.8v-6.5h1.1v6.5h-1.1Z" fill="#c9c9c9"/>
            <path d="M604.9,88c-.7,0-1.2,0-1.7-.4s-.8-.7-1-1.2-.4-1.1-.4-1.8,0-1.3.4-1.8c.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5.8.8,1,1.4l-1.1.3c0-.4-.4-.7-.7-.9s-.7-.3-1-.3-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2c0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5h-.3Z" fill="#c9c9c9"/>
            <path d="M608.9,87.8v-8.6h1.1v5.3l2.7-3.1h1.4l-2.9,3.2,3.2,3.2h-1.5l-2.9-3.1v3.1h-1.1Z" fill="#c9c9c9"/>
            <path d="M617.8,88c-.6,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8,0-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8s-.8-.6-1.4-.6-1.2.2-1.5.6-.5,1-.5,1.8.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3s-1.1.5-1.7.5h.1ZM615.4,84.9v-.9h4.8v.9h-4.8Z" fill="#c9c9c9"/>
            <path d="M624.6,88c-.6,0-1.1,0-1.6-.4-.4-.3-.8-.7-1-1.2s-.4-1.1-.4-1.7,0-1.2.4-1.7c.2-.5.6-.9,1-1.2.4-.3.9-.4,1.5-.4s1.1,0,1.5.4.7.7.9,1.2.3,1.1.3,1.7,0,1.2-.3,1.7-.5.9-.9,1.2c-.4.3-.9.5-1.5.5h0ZM624.7,87c.4,0,.8,0,1.1-.3s.5-.5.6-.9.2-.8.2-1.3,0-.9-.2-1.3c0-.4-.3-.7-.6-.9-.3-.2-.6-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2,0,.9.2,1.2c0,.4.4.7.6.9.3.2.6.3,1.1.3v.2ZM626.6,87.8v-4.8h0v-3.9h1.1v8.6h-1Z" fill="#c9c9c9"/>
            <path d="M629.1,84.5v-.9h3.6v.9h-3.6Z" fill="#c9c9c9"/>
            <path d="M637.7,88c-.7,0-1.3,0-1.8-.4s-.9-.7-1.2-1.2-.4-1.1-.4-1.8v-5.5h1.1v5.4c0,.4,0,.8.2,1.1,0,.3.3.6.5.8.2.2.5.3.8.4.3,0,.5,0,.8,0s.6,0,.8,0c.3,0,.5-.2.8-.4s.4-.4.5-.8c0-.3.2-.7.2-1.1v-5.4h1.1v5.5c0,.7,0,1.3-.4,1.8-.3.5-.7.9-1.2,1.2-.5.3-1.1.4-1.8.4h0Z" fill="#c9c9c9"/>
            <path d="M642.8,90.7v-9.4h1v4.8h0v4.6h-1.1ZM645.8,88c-.6,0-1.1-.2-1.5-.5s-.7-.7-.9-1.2-.3-1.1-.3-1.7,0-1.2.3-1.7.5-.9.9-1.2.9-.4,1.5-.4,1.1,0,1.6.4c.4.3.8.7,1,1.2s.3,1.1.3,1.7,0,1.2-.3,1.7-.6.9-1,1.2c-.4.3-1,.4-1.6.4h0ZM645.6,87c.4,0,.8,0,1.1-.3s.5-.5.6-.9.2-.8.2-1.2,0-.9-.2-1.2c0-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8,0-1,.3-.5.5-.6.9-.2.8-.2,1.3,0,.9.2,1.3c0,.4.3.7.6.9s.6.3,1.1.3v-.2Z" fill="#c9c9c9"/>
          </g>
        </g>
      </svg>
    );
  const statusAcceptedMobile = // Done
    (
      <svg id="statusAcceptedMobile_1_" data-name="statusAcceptedMobile (1)" xmlns="http://www.w3.org/2000/svg" width="480" height="100.7" viewBox="0 0 480 100.7">
  <line id="Line_83" x2="66.9" transform="translate(60.3 39.1)" fill="none"/>
  <path id="Path_667" d="M62.7,39.1h48" fill="none" stroke="#2ec278" stroke-width="1"/>
  <line id="Line_85" x2="327.7" transform="translate(110.5 39.1)" fill="none" stroke="#d4d4d4" stroke-width="1"/>
  <circle id="Ellipse_96" cx="38.5" cy="38.5" r="38.5" fill="#d0f3e2"/>
  <ellipse id="Ellipse_97" cx="30" cy="30.5" rx="30" ry="30.5" transform="translate(9 8)" fill="#2ec278"/>
  <g id="Group_671">
    <circle id="Ellipse_98" cx="38.5" cy="38.5" r="38.5" transform="translate(135)" opacity="0"/>
    <path id="Path_668" d="M173.6,8.2a30.3,30.3,0,1,1-30.3,30.3h0A30.28,30.28,0,0,1,173.6,8.2h0Z" fill="#f1f1f1"/>
  </g>
  <g id="Group_672">
    <ellipse id="Ellipse_99" cx="38" cy="38.5" rx="38" ry="38.5" transform="translate(266)" opacity="0"/>
    <circle id="Ellipse_100" cx="31" cy="31" r="31" transform="translate(273 8)" fill="#f1f1f1"/>
  </g>
  <g id="Group_673">
    <ellipse id="Ellipse_101" cx="39" cy="38.5" rx="39" ry="38.5" transform="translate(402)" opacity="0"/>
    <ellipse id="Ellipse_102" cx="30" cy="30.5" rx="30" ry="30.5" transform="translate(411 8)" fill="#f1f1f1"/>
  </g>
  <g id="Group_6684" data-name="Group 6684">
    <path id="Path_8471" data-name="Path 8471" d="M7.4,97.3l3.3-10.1h2.1l3.3,10.1H14.6l-3-9.2H12L9,97.3H7.4Zm1.7-2.2V93.7h5.3v1.4Z"/>
    <path id="Path_8472" data-name="Path 8472" d="M20.5,97.5a4.228,4.228,0,0,1-2-.5,2.758,2.758,0,0,1-1.2-1.4,4.638,4.638,0,0,1-.4-2.1,6.537,6.537,0,0,1,.4-2.1A2.539,2.539,0,0,1,18.6,90a3.939,3.939,0,0,1,2-.5,3.7,3.7,0,0,1,2.1.6,2.982,2.982,0,0,1,1.2,1.7l-1.5.4a1.834,1.834,0,0,0-.7-1,2.1,2.1,0,0,0-1.1-.4,1.676,1.676,0,0,0-1.2.3,2.178,2.178,0,0,0-.7.9,2.745,2.745,0,0,0-.2,1.3,3.557,3.557,0,0,0,.5,1.9,1.753,1.753,0,0,0,1.6.7c.7,0,.9,0,1.1-.3a2.178,2.178,0,0,0,.7-.9l1.5.3a3.528,3.528,0,0,1-1.2,1.7,3.7,3.7,0,0,1-2.1.6v.2Z"/>
    <path id="Path_8473" data-name="Path 8473" d="M28.3,97.5a4.228,4.228,0,0,1-2-.5,2.758,2.758,0,0,1-1.2-1.4,4.638,4.638,0,0,1-.4-2.1,6.537,6.537,0,0,1,.4-2.1A2.539,2.539,0,0,1,26.4,90a3.939,3.939,0,0,1,2-.5,3.7,3.7,0,0,1,2.1.6,2.982,2.982,0,0,1,1.2,1.7l-1.5.4a1.834,1.834,0,0,0-.7-1,2.1,2.1,0,0,0-1.1-.4,1.676,1.676,0,0,0-1.2.3,2.178,2.178,0,0,0-.7.9,2.745,2.745,0,0,0-.2,1.3,3.557,3.557,0,0,0,.5,1.9,1.753,1.753,0,0,0,1.6.7c.7,0,.9,0,1.1-.3a2.178,2.178,0,0,0,.7-.9l1.5.3a3.528,3.528,0,0,1-1.2,1.7,3.7,3.7,0,0,1-2.1.6v.2Z"/>
    <path id="Path_8474" data-name="Path 8474" d="M36.1,97.5a4.228,4.228,0,0,1-2-.5,2.949,2.949,0,0,1-1.3-1.4,4.66,4.66,0,0,1,0-4.2A2.949,2.949,0,0,1,34.1,90a4.136,4.136,0,0,1,1.9-.5,4.552,4.552,0,0,1,2,.5,4.428,4.428,0,0,1,1.2,1.5,4.175,4.175,0,0,1,.3,2.3H38v-.5a3.09,3.09,0,0,0-.5-1.9,1.755,1.755,0,0,0-1.5-.6,2.06,2.06,0,0,0-1.7.7,2.986,2.986,0,0,0-.6,2,2.546,2.546,0,0,0,.6,1.9,1.865,1.865,0,0,0,1.6.7c.7,0,.9,0,1.2-.3a2.361,2.361,0,0,0,.8-.9l1.4.5A3.435,3.435,0,0,1,38,97a3.606,3.606,0,0,1-2,.6h0ZM33.5,94V92.9h5.4V94Z"/>
    <path id="Path_8475" data-name="Path 8475" d="M40.9,100.7V89.8h1.3v5.4h.2v5.5Zm3.6-3.2a3.453,3.453,0,0,1-1.8-.5,2.586,2.586,0,0,1-1.1-1.4,6.465,6.465,0,0,1-.4-2,3.444,3.444,0,0,1,.4-2,2.586,2.586,0,0,1,1.1-1.4,3.49,3.49,0,0,1,3.6,0,3.325,3.325,0,0,1,1.2,1.4,4.477,4.477,0,0,1,.4,2,3.444,3.444,0,0,1-.4,2A3.325,3.325,0,0,1,46.3,97,2.73,2.73,0,0,1,44.5,97.5Zm-.2-1.3c.5,0,.9,0,1.2-.4a2.552,2.552,0,0,0,.7-1,3.178,3.178,0,0,0,.2-1.4,2.745,2.745,0,0,0-.2-1.3,3.592,3.592,0,0,0-.7-1,1.594,1.594,0,0,0-1.2-.4c-.5,0-.8,0-1.1.3a2.178,2.178,0,0,0-.7.9,5,5,0,0,0,0,2.8c0,.4.4.7.7.9a2.133,2.133,0,0,0,1.2.3v.3Z"/>
    <path id="Path_8476" data-name="Path 8476" d="M48.6,90.9V89.7h5.2v1.2Zm5.2,6.4H52.4a2.218,2.218,0,0,1-2-1.1,1.689,1.689,0,0,1-.3-1V87.4h1.5v8a.891.891,0,0,0,.8.5h1.3v1.2h0Z"/>
    <path id="Path_8477" data-name="Path 8477" d="M58.4,97.5a4.228,4.228,0,0,1-2-.5,2.949,2.949,0,0,1-1.3-1.4,4.66,4.66,0,0,1,0-4.2A2.949,2.949,0,0,1,56.4,90a4.136,4.136,0,0,1,1.9-.5,4.552,4.552,0,0,1,2,.5,4.428,4.428,0,0,1,1.2,1.5,4.175,4.175,0,0,1,.3,2.3H60.3v-.5a3.09,3.09,0,0,0-.5-1.9,1.755,1.755,0,0,0-1.5-.6,2.06,2.06,0,0,0-1.7.7,2.986,2.986,0,0,0-.6,2,2.546,2.546,0,0,0,.6,1.9,1.865,1.865,0,0,0,1.6.7c.7,0,.9,0,1.2-.3a2.361,2.361,0,0,0,.8-.9l1.4.5A3.435,3.435,0,0,1,60.3,97a3.606,3.606,0,0,1-2,.6h0ZM55.7,94V92.9h5.4V94Z"/>
    <path id="Path_8478" data-name="Path 8478" d="M66.4,97.5a3.453,3.453,0,0,1-1.8-.5,3.325,3.325,0,0,1-1.2-1.4,4.477,4.477,0,0,1-.4-2,3.444,3.444,0,0,1,.4-2,3.325,3.325,0,0,1,1.2-1.4,3.49,3.49,0,0,1,3.6,0,2.586,2.586,0,0,1,1.1,1.4,6.465,6.465,0,0,1,.4,2,3.444,3.444,0,0,1-.4,2c-.4.6-.6,1.1-1.1,1.4A2.73,2.73,0,0,1,66.4,97.5Zm.2-1.3a1.558,1.558,0,0,0,1.2-.3,2.178,2.178,0,0,0,.7-.9c0-.4.2-.9.2-1.4a3.178,3.178,0,0,0-.2-1.4,2.178,2.178,0,0,0-.7-.9,2.016,2.016,0,0,0-1.1-.3,1.374,1.374,0,0,0-1.2.4,6.071,6.071,0,0,0-.7,1,2.745,2.745,0,0,0-.2,1.3,3.178,3.178,0,0,0,.2,1.4,3.592,3.592,0,0,0,.7,1,2.144,2.144,0,0,0,1.2.4v-.3Zm2,1.1V91.9h-.2V87.3h1.5V97.4H68.6Z"/>
  </g>
  <g id="thumbs_up">
    <g id="Group_676">
      <g id="Group_675">
        <path id="Path_672" d="M452.3,42.3a1.538,1.538,0,0,0-1.5-1.5,1.5,1.5,0,0,0,0-3h-6.2a12.111,12.111,0,0,0,.9-5.3,2.263,2.263,0,0,0-2.3-2.3h-.5a.858.858,0,0,0-.7.6c-.7,2.9-1.9,6.2-4.9,6.9V48.6l2.6.9a6.722,6.722,0,0,0,2.4.4h7.1a1.5,1.5,0,0,0,0-3h1.5a1.5,1.5,0,0,0,0-3h0a1.538,1.538,0,0,0,1.5-1.5h.1Z" fill="#d9d9d9"/>
      </g>
    </g>
    <g id="Group_678">
      <g id="Group_677">
        <path id="Path_673" d="M433.4,36.3h-3a.789.789,0,0,0-.8.8h0V50.7a.789.789,0,0,0,.8.8h3a2.308,2.308,0,0,0,2.3-2.2h0V38.7a2.308,2.308,0,0,0-2.2-2.3h0Zm0,12.1a.789.789,0,0,1-.8-.8h0a.8.8,0,1,1,1.6,0h0a.789.789,0,0,1-.8.8Z" fill="#d9d9d9"/>
      </g>
    </g>
    <g id="Group_680">
      <g id="Group_679">
        <path id="Path_674" d="M440,28.9l-1.1-1.1a.778.778,0,1,0-1.1,1.1h0l1.1,1.1a.778.778,0,1,0,1.1-1.1Z" fill="#d9d9d9"/>
      </g>
    </g>
    <g id="Group_682">
      <g id="Group_681">
        <path id="Path_675" d="M448.6,27.9a.785.785,0,0,0-1.1,0h0L446.4,29a.778.778,0,0,0,1.1,1.1l1.1-1.1A.785.785,0,0,0,448.6,27.9Z" fill="#d9d9d9"/>
      </g>
    </g>
    <g id="Group_684">
      <g id="Group_683">
        <path id="Path_676" d="M443.3,25.6a.773.773,0,0,0-.8.7h0v1.6a.773.773,0,0,0,.8.7.684.684,0,0,0,.7-.7h0V26.3a.773.773,0,0,0-.7-.8h0Z" fill="#d9d9d9"/>
      </g>
    </g>
  </g>
  <g id="verified-24px">
    <g id="Group_685">
      <rect id="Rectangle_309" width="28" height="28" transform="translate(24 25)" fill="none"/>
    </g>
    <g id="Group_686">
      <path id="Path_677" d="M51.5,39.7l-2.8-3.3.4-4.3-4.2-1-2.2-3.7-4,1.7-4-1.7-2.2,3.7-4.2.9.4,4.3-2.9,3.3,2.8,3.3-.4,4.3,4.2,1,2.2,3.7,4-1.7,4,1.7,2.2-3.7,4.2-1-.4-4.3,2.9-3.3h0Zm-15,5.5-4.4-4.4,1.7-1.7,2.7,2.7,6.8-6.9L45,36.6l-8.6,8.6h0Z" fill="#fff"/>
    </g>
  </g>
  <circle id="Ellipse_103" cx="5" cy="5" r="5" transform="translate(106 34)" fill="#2ec278"/>
  <g id="inventory_2_black_24dp">
    <g id="Group_4910">
      <rect id="Rectangle_1309" width="26" height="26" transform="translate(161 27)" fill="none"/>
    </g>
    <g id="Group_4911">
      <path id="Path_3926" d="M182.3,29.6H165.7a2.182,2.182,0,0,0-2.1,2.1v3.1a2.234,2.234,0,0,0,1,1.8V48.4a2.258,2.258,0,0,0,2.1,2.1h14.6a2.258,2.258,0,0,0,2.1-2.1V36.6a2.234,2.234,0,0,0,1-1.8V31.7a2.182,2.182,0,0,0-2.1-2.1Zm-5.2,12.5h-6.2V40h6.2Zm5.2-7.3H165.7V31.7h16.6Z" fill="#d9d9d9"/>
    </g>
  </g>
  <g id="Group_5030">
    <g id="Group_8218">
      <g id="Group_5030-2">
        <path id="Path_4200" d="M309.3,40.4a5.7,5.7,0,1,0,5.7,5.7h0a5.763,5.763,0,0,0-5.7-5.7h0m-1.1,8.5-2.3-2.3.8-.8,1.5,1.5,3.7-3.7.8.8Z" fill="#d9d9d9"/>
        <path id="Path_4201" d="M301.9,46.1a7.383,7.383,0,0,1,7.4-7.4h0a8.675,8.675,0,0,1,2.8.5V36.6A1.7,1.7,0,0,0,313,35V32.2a2.043,2.043,0,0,0-1.9-1.9H296a2.043,2.043,0,0,0-1.9,1.9V35a1.7,1.7,0,0,0,.9,1.6V47.2a2.043,2.043,0,0,0,1.9,1.9h5.5a8.245,8.245,0,0,1-.7-3.1m-5.6-13.8h15.1V35H296.1Z" fill="#d9d9d9"/>
      </g>
    </g>
  </g>
  <g id="Group_6685" data-name="Group 6685">
    <path id="Path_8479" data-name="Path 8479" d="M287.3,89.8V81.2h4a1.656,1.656,0,0,1,1.2.5,2.016,2.016,0,0,1,.7.9,4.438,4.438,0,0,1,.2,1.3,3.371,3.371,0,0,1-.5,1.7,2.551,2.551,0,0,1-1.5.9h-3.1v3.2h-1Zm1.1-4.2h2.9a1.012,1.012,0,0,0,.7-.4,2.092,2.092,0,0,0,.4-.6,2.5,2.5,0,0,0,0-1.4c0-.2-.2-.4-.4-.6a1.612,1.612,0,0,0-.7-.4h-2.9v3.4Zm4.1,4.2-1.7-3.5,1.1-.3,1.9,3.8Z" fill="#bfbfbf"/>
    <path id="Path_8480" data-name="Path 8480" d="M297.8,90a2.543,2.543,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,4.25,4.25,0,0,1,0-3.6,3.179,3.179,0,0,1,1.1-1.2,2.934,2.934,0,0,1,1.6-.4,3.371,3.371,0,0,1,1.7.5,2.209,2.209,0,0,1,1,1.3,6.013,6.013,0,0,1,.3,2h-1.1v-.4a2.969,2.969,0,0,0-.5-1.8,1.678,1.678,0,0,0-1.4-.6,1.887,1.887,0,0,0-1.5.6,3.49,3.49,0,0,0,0,3.6,1.755,1.755,0,0,0,1.5.6c.7,0,.8,0,1.1-.3a1.779,1.779,0,0,0,.7-.8l1,.4a2.85,2.85,0,0,1-1.1,1.3,3.371,3.371,0,0,1-1.7.5h.1Zm-2.4-3.1V86h4.8v.9Z" fill="#bfbfbf"/>
    <path id="Path_8481" data-name="Path 8481" d="M303.8,90a1.558,1.558,0,0,1-1.2-.3c-.3-.3-.6-.4-.7-.7a2.958,2.958,0,0,1-.2-1,1.344,1.344,0,0,1,.2-.9c.2-.3.3-.5.5-.6a2.01,2.01,0,0,1,.8-.4c.3,0,.7-.2,1-.2a2.745,2.745,0,0,0,1.2-.2,1.978,1.978,0,0,0,1.1-.2l-.4.2a2.269,2.269,0,0,0-.3-1.3,1.461,1.461,0,0,0-1.2-.4c-.5,0-.7,0-1,.3a1.456,1.456,0,0,0-.6.9l-1-.3a2.54,2.54,0,0,1,.9-1.3,2.969,2.969,0,0,1,1.8-.5c.8,0,1.1,0,1.5.3a2.189,2.189,0,0,1,.9,1,2.544,2.544,0,0,0,.2.7v4.7h-.9V88.2h.2a1.994,1.994,0,0,1-1,1.3,2.726,2.726,0,0,1-1.6.4h-.2Zm.2-.9a1.645,1.645,0,0,0,1-.2,4.235,4.235,0,0,0,.7-.5,1.08,1.08,0,0,0,.3-.7V86.4l.4.2h-2a1.077,1.077,0,0,0-.8.2.551.551,0,0,0-.5.2c-.2,0-.3.2-.4.3a2.034,2.034,0,0,0-.1.5.9.9,0,0,0,.1.5.781.781,0,0,0,.4.4c.2,0,.4.2.7.2h.2Z" fill="#bfbfbf"/>
    <path id="Path_8482" data-name="Path 8482" d="M311.3,90a2.276,2.276,0,0,1-1.6-.4,2.94,2.94,0,0,1-1-1.2,4.711,4.711,0,0,1-.4-1.7,3.287,3.287,0,0,1,.4-1.7,2.94,2.94,0,0,1,1-1.2,2.41,2.41,0,0,1,1.5-.4,1.913,1.913,0,0,1,1.5.4,3.449,3.449,0,0,1,.9,1.2,4.967,4.967,0,0,1,0,3.4,2.73,2.73,0,0,1-.9,1.2,2.456,2.456,0,0,1-1.5.5h.1Zm.1-1a1.431,1.431,0,0,0,1.1-.3,1.8,1.8,0,0,0,.6-.9,5.066,5.066,0,0,0,.2-1.3,2.745,2.745,0,0,0-.2-1.3,2.034,2.034,0,0,0-.6-.9,1.689,1.689,0,0,0-1-.3,1.431,1.431,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.345,2.345,0,0,0,.2,1.2,2.034,2.034,0,0,0,.6.9,1.609,1.609,0,0,0,1.1.3V89Zm1.9.8V85h-.1V81.1h1.1v8.6h-1Z" fill="#bfbfbf"/>
    <path id="Path_8483" data-name="Path 8483" d="M316.8,92.7l1.3-3.4v1l-2.8-7h1.1l2.2,5.6h-.4l2.1-5.6h1.1l-3.5,9.4h-1.1Z" fill="#bfbfbf"/>
  </g>
  <g id="Group_6686" data-name="Group 6686">
    <path id="Path_8484" data-name="Path 8484" d="M152.2,89.8V81.2h4a1.656,1.656,0,0,1,1.2.5,2.016,2.016,0,0,1,.7.9,4.439,4.439,0,0,1,.2,1.3,2.745,2.745,0,0,1-.2,1.3,2.178,2.178,0,0,1-.7.9,3.337,3.337,0,0,1-1.2.5h-2.9v3.2h-1.1Zm1.1-4.2h2.9a1.012,1.012,0,0,0,.7-.4,2.092,2.092,0,0,0,.4-.6,2.5,2.5,0,0,0,0-1.4c0-.2-.2-.4-.4-.6a1.612,1.612,0,0,0-.7-.4h-2.9v3.4Z" fill="#bfbfbf"/>
    <path id="Path_8485" data-name="Path 8485" d="M161.2,90a1.558,1.558,0,0,1-1.2-.3c-.3-.3-.6-.4-.7-.7a2.959,2.959,0,0,1-.2-1,1.344,1.344,0,0,1,.2-.9c.2-.3.3-.5.5-.6a2.01,2.01,0,0,1,.8-.4c.3,0,.7-.2,1-.2a2.744,2.744,0,0,0,1.2-.2,1.978,1.978,0,0,0,1.1-.2l-.4.2a2.269,2.269,0,0,0-.3-1.3A1.461,1.461,0,0,0,162,84c-.5,0-.7,0-1,.3a1.456,1.456,0,0,0-.6.9l-1-.3a2.54,2.54,0,0,1,.9-1.3,2.969,2.969,0,0,1,1.8-.5c.8,0,1.1,0,1.5.3a2.189,2.189,0,0,1,.9,1,2.544,2.544,0,0,0,.2.7v4.7h-.9V88.2h.2a1.994,1.994,0,0,1-1,1.3,2.726,2.726,0,0,1-1.6.4h-.2Zm.2-.9a1.645,1.645,0,0,0,1-.2,4.233,4.233,0,0,0,.7-.5,1.08,1.08,0,0,0,.3-.7V86.4l.4.2h-2a1.077,1.077,0,0,0-.8.2.551.551,0,0,0-.5.2c-.2,0-.3.2-.4.3a2.034,2.034,0,0,0-.1.5.9.9,0,0,0,.1.5.781.781,0,0,0,.4.4,1.445,1.445,0,0,0,.7.2h.2Z" fill="#bfbfbf"/>
    <path id="Path_8486" data-name="Path 8486" d="M168.9,90a2.414,2.414,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,4.776,4.776,0,0,1-.4-1.8,3.425,3.425,0,0,1,.4-1.8,2.544,2.544,0,0,1,1.1-1.2,3.064,3.064,0,0,1,1.7-.4,3.453,3.453,0,0,1,1.8.5,2.434,2.434,0,0,1,1,1.4l-1.1.3a1.6,1.6,0,0,0-.7-.9,1.929,1.929,0,0,0-1-.3,1.548,1.548,0,0,0-1.1.3,1.8,1.8,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.86,2.86,0,0,0,.5,1.7,1.806,1.806,0,0,0,1.5.7c.7,0,.8,0,1.1-.3a2.1,2.1,0,0,0,.6-.8l1.1.3a2.981,2.981,0,0,1-1,1.4,3.1,3.1,0,0,1-1.7.5h-.2Z" fill="#bfbfbf"/>
    <path id="Path_8487" data-name="Path 8487" d="M172.9,89.8V81.2H174v5.3l2.7-3.1h1.4l-2.9,3.2,3.2,3.2h-1.5L174,86.7v3.1Z" fill="#bfbfbf"/>
    <path id="Path_8488" data-name="Path 8488" d="M179.1,82.2V81.1h1.1v1.1Zm0,7.6V83.3h1.1v6.5Z" fill="#bfbfbf"/>
    <path id="Path_8489" data-name="Path 8489" d="M182,89.8V83.3h1V85h.1v4.7H182Zm4.6,0V85.7c0-.3-.2-.5-.3-.8a1.205,1.205,0,0,0-.5-.5,2,2,0,0,0-.9-.2h-.7c-.2,0-.4.2-.6.4a1.612,1.612,0,0,0-.4.7c0,.3-.1.6-.1,1l-.7-.2a3.808,3.808,0,0,1,.3-1.6,1.651,1.651,0,0,1,1-1,3.708,3.708,0,0,1,1.4-.4,1.815,1.815,0,0,1,1.1.2,4.233,4.233,0,0,1,.7.5,3.039,3.039,0,0,1,.5.7,2.353,2.353,0,0,1,.2.8v4.4h-1Z" fill="#bfbfbf"/>
    <path id="Path_8490" data-name="Path 8490" d="M191.8,90a2.276,2.276,0,0,1-1.6-.4,2.94,2.94,0,0,1-1-1.2,4.711,4.711,0,0,1-.4-1.7,3.287,3.287,0,0,1,.4-1.7,2.94,2.94,0,0,1,1-1.2,2.41,2.41,0,0,1,1.5-.4,1.913,1.913,0,0,1,1.5.4,3.449,3.449,0,0,1,.9,1.2,4.967,4.967,0,0,1,0,3.4,2.73,2.73,0,0,1-.9,1.2,2.456,2.456,0,0,1-1.5.5h.1Zm.1,2.9a1.8,1.8,0,0,1-1-.2c-.3,0-.6-.3-.9-.5a2.7,2.7,0,0,1-.7-.8l1-.5a2.336,2.336,0,0,0,.7.7c.3,0,.6.2.9.2s.8,0,1-.2c.3,0,.5-.4.6-.7a3.6,3.6,0,0,0,.2-1.1V88h.1V83.3h.9v7.5a1.433,1.433,0,0,1-.5,1.1,3.592,3.592,0,0,1-1,.7c-.4,0-.8.2-1.4.2h0Zm0-3.9a1.431,1.431,0,0,0,1.1-.3,1.8,1.8,0,0,0,.6-.9,5.066,5.066,0,0,0,.2-1.3,2.745,2.745,0,0,0-.2-1.3,2.034,2.034,0,0,0-.6-.9,1.689,1.689,0,0,0-1-.3,1.431,1.431,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.345,2.345,0,0,0,.2,1.2,2.034,2.034,0,0,0,.6.9,1.609,1.609,0,0,0,1.1.3V89Z" fill="#bfbfbf"/>
  </g>
  <g id="Group_6687" data-name="Group 6687">
    <path id="Path_8491" data-name="Path 8491" d="M412.8,89.8V81.2h4a1.656,1.656,0,0,1,1.2.5,2.016,2.016,0,0,1,.7.9,4.438,4.438,0,0,1,.2,1.3,2.745,2.745,0,0,1-.2,1.3,2.178,2.178,0,0,1-.7.9,3.337,3.337,0,0,1-1.2.5h-2.9v3.2h-1.1Zm1-4.2h2.9a1.012,1.012,0,0,0,.7-.4,2.092,2.092,0,0,0,.4-.6,2.5,2.5,0,0,0,0-1.4c0-.2-.2-.4-.4-.6a1.612,1.612,0,0,0-.7-.4h-2.9v3.4Z" fill="#bfbfbf"/>
    <path id="Path_8492" data-name="Path 8492" d="M420.3,82.2V81.1h1.1v1.1Zm0,7.6V83.3h1.1v6.5Z" fill="#bfbfbf"/>
    <path id="Path_8493" data-name="Path 8493" d="M425.9,90a2.414,2.414,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,4.776,4.776,0,0,1-.4-1.8,3.425,3.425,0,0,1,.4-1.8,2.545,2.545,0,0,1,1.1-1.2,3.064,3.064,0,0,1,1.7-.4,3.453,3.453,0,0,1,1.8.5,2.434,2.434,0,0,1,1,1.4l-1.1.3a1.6,1.6,0,0,0-.7-.9,1.929,1.929,0,0,0-1-.3,1.548,1.548,0,0,0-1.1.3,1.8,1.8,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.86,2.86,0,0,0,.5,1.7,1.806,1.806,0,0,0,1.5.7c.7,0,.8,0,1.1-.3a2.1,2.1,0,0,0,.6-.8l1.1.3a2.981,2.981,0,0,1-1,1.4,3.1,3.1,0,0,1-1.7.5h-.2Z" fill="#bfbfbf"/>
    <path id="Path_8494" data-name="Path 8494" d="M429.9,89.8V81.2H431v5.3l2.7-3.1h1.4l-2.9,3.2,3.2,3.2h-1.5L431,86.7v3.1h-1.1Z" fill="#bfbfbf"/>
    <path id="Path_8495" data-name="Path 8495" d="M438.7,90a2.543,2.543,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,4.25,4.25,0,0,1,0-3.6,3.179,3.179,0,0,1,1.1-1.2,2.934,2.934,0,0,1,1.6-.4,3.371,3.371,0,0,1,1.7.5,2.209,2.209,0,0,1,1,1.3,6.013,6.013,0,0,1,.3,2h-1.1v-.4a2.969,2.969,0,0,0-.5-1.8,1.678,1.678,0,0,0-1.4-.6,1.887,1.887,0,0,0-1.5.6,3.49,3.49,0,0,0,0,3.6,1.755,1.755,0,0,0,1.5.6c.7,0,.8,0,1.1-.3a1.779,1.779,0,0,0,.7-.8l1,.4a2.85,2.85,0,0,1-1.1,1.3,3.371,3.371,0,0,1-1.7.5h.1Zm-2.4-3.1V86h4.8v.9Z" fill="#bfbfbf"/>
    <path id="Path_8496" data-name="Path 8496" d="M445.5,90a2.276,2.276,0,0,1-1.6-.4,2.94,2.94,0,0,1-1-1.2,4.711,4.711,0,0,1-.4-1.7,3.287,3.287,0,0,1,.4-1.7,2.94,2.94,0,0,1,1-1.2,2.41,2.41,0,0,1,1.5-.4,1.913,1.913,0,0,1,1.5.4,3.449,3.449,0,0,1,.9,1.2,4.967,4.967,0,0,1,0,3.4,2.73,2.73,0,0,1-.9,1.2,2.456,2.456,0,0,1-1.5.5h.1Zm.1-1a1.431,1.431,0,0,0,1.1-.3,1.8,1.8,0,0,0,.6-.9,5.066,5.066,0,0,0,.2-1.3,2.745,2.745,0,0,0-.2-1.3,2.034,2.034,0,0,0-.6-.9,1.689,1.689,0,0,0-1-.3,1.431,1.431,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.345,2.345,0,0,0,.2,1.2,2.034,2.034,0,0,0,.6.9,1.609,1.609,0,0,0,1.1.3V89Zm1.9.8V85h-.1V81.1h1.1v8.6h-1Z" fill="#bfbfbf"/>
    <path id="Path_8497" data-name="Path 8497" d="M450,86.5v-.9h3.6v.9Z" fill="#bfbfbf"/>
    <path id="Path_8498" data-name="Path 8498" d="M458.6,90a2.689,2.689,0,0,1-1.8-.4,4.561,4.561,0,0,1-1.2-1.2,3.425,3.425,0,0,1-.4-1.8V81.1h1.1v5.4a1.978,1.978,0,0,0,.2,1.1,5.583,5.583,0,0,0,.5.8,2.01,2.01,0,0,0,.8.4h1.6c.3,0,.5-.2.8-.4a1.578,1.578,0,0,0,.5-.8,3.6,3.6,0,0,0,.2-1.1V81.1H462v5.5a3.166,3.166,0,0,1-3.4,3.4Z" fill="#bfbfbf"/>
    <path id="Path_8499" data-name="Path 8499" d="M463.7,92.7V83.3h1v4.8h.1v4.6Zm3-2.7a2.456,2.456,0,0,1-1.5-.5,2.73,2.73,0,0,1-.9-1.2,4.967,4.967,0,0,1,0-3.4,2.73,2.73,0,0,1,.9-1.2,2.41,2.41,0,0,1,1.5-.4,2.276,2.276,0,0,1,1.6.4,2.94,2.94,0,0,1,1,1.2,4.967,4.967,0,0,1,0,3.4,2.94,2.94,0,0,1-1,1.2,2.726,2.726,0,0,1-1.6.4h0Zm-.2-1a1.431,1.431,0,0,0,1.1-.3,1.8,1.8,0,0,0,.6-.9,5.019,5.019,0,0,0,.2-1.2,2.345,2.345,0,0,0-.2-1.2,2.034,2.034,0,0,0-.6-.9,1.8,1.8,0,0,0-1.1-.3c-.5,0-.8,0-1,.3a1.456,1.456,0,0,0-.6.9,5.066,5.066,0,0,0-.2,1.3,2.745,2.745,0,0,0,.2,1.3,1.456,1.456,0,0,0,.6.9,1.8,1.8,0,0,0,1.1.3V89Z" fill="#bfbfbf"/>
  </g>
      </svg>
    );
  const statusPackedDesktop = //Done
    (
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 660 104.8">
        <g id="d_s_packed">
          <g id="Group_4701" opacity=".5">
            <line id="Line_64" x1="60.3" y1="39.1" x2="157.2" y2="39.1" fill="none" stroke="#2ec278" stroke-opacity="0"/>
            <path id="Path_664" d="M62.7,39.1h140" fill="none" stroke="#2ec278"/>
            <circle id="Ellipse_86" cx="38.5" cy="38.5" r="38.5" fill="#ffd5d5" fill-opacity="0"/>
            <ellipse id="Ellipse_82" cx="39" cy="38.5" rx="30" ry="30.5" fill="#2ec278"/>
            <g id="verified-24px">
              <g id="Group_564">
                <rect id="Rectangle_235" x="25" y="25" width="28" height="28" fill="none"/>
              </g>
              <g id="Group_565">
                <path id="Path_623" d="M52.5,39.7l-2.8-3.3.4-4.3-4.2-1-2.2-3.7-4,1.7-4-1.7-2.2,3.7-4.2.9.4,4.3-2.9,3.3,2.8,3.3-.4,4.3,4.2,1,2.2,3.7,4-1.7,4,1.7,2.2-3.7,4.2-1-.4-4.3,2.9-3.3h0ZM37.5,45.2l-4.4-4.4,1.7-1.7,2.7,2.7,6.8-6.9,1.7,1.7-8.6,8.6h0Z" fill="#fff"/>
              </g>
            </g>
            <g>
              <path d="M9.8,91.7l2.9-8.6h1.5l2.9,8.6h-1.1l-2.7-8h.3l-2.7,8h-1.1ZM11.2,89.7v-1h4.5v1h-4.5Z" fill="#bfbfbf"/>
              <path d="M20.9,91.9c-.7,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2c-.2-.5-.4-1.1-.4-1.8s.1-1.3.4-1.8c.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8,0-1.1.3-.5.5-.6.9-.2.8-.2,1.2c0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5h-.2,0Z" fill="#bfbfbf"/>
              <path d="M27.5,91.9c-.7,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2c-.2-.5-.4-1.1-.4-1.8s.1-1.3.4-1.8c.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8,0-1.1.3-.5.5-.6.9-.2.8-.2,1.2c0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5h-.2,0Z" fill="#bfbfbf"/>
              <path d="M34.1,91.9c-.6,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8.6-.9,1.1-1.2c.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8s-.8-.6-1.4-.6-1.2.2-1.5.6-.5,1-.5,1.8.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3s-1.1.5-1.7.5h.1ZM31.7,88.8v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
              <path d="M38.2,94.6v-9.4h1v4.8h0v4.6h-1.1,0ZM41.2,91.9c-.6,0-1.1-.2-1.5-.5s-.7-.7-.9-1.2-.3-1.1-.3-1.7,0-1.2.3-1.7.5-.9.9-1.2.9-.4,1.5-.4,1.1,0,1.6.4c.4.3.8.7,1,1.2s.3,1.1.3,1.7,0,1.2-.3,1.7-.6.9-1,1.2c-.4.3-1,.4-1.6.4h0ZM41,90.9c.4,0,.8,0,1.1-.3s.5-.5.6-.9.2-.8.2-1.2,0-.9-.2-1.2c0-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8,0-1,.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.3,0,.9.2,1.3c0,.4.3.7.6.9.3.2.6.3,1.1.3v-.2h0Z" fill="#bfbfbf"/>
              <path d="M44.7,86.1v-.9h4.3v.9h-4.3ZM49,91.7h-1.1c-.4,0-.7,0-1-.2s-.5-.4-.7-.7c0-.3-.2-.5-.2-.8v-6.7h1.1v7c0,.3.4.4.7.5h1.1v.9h.1Z" fill="#bfbfbf"/>
              <path d="M52.9,91.9c-.6,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8,0-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8s-.8-.6-1.4-.6-1.2.2-1.5.6-.5,1-.5,1.8.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3s-1.1.5-1.7.5h.1ZM50.5,88.8v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
              <path d="M59.7,91.9c-.6,0-1.1,0-1.6-.4-.4-.3-.8-.7-1-1.2s-.4-1.1-.4-1.7,0-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1,0,1.5.4.7.7.9,1.2.3,1.1.3,1.7,0,1.2-.3,1.7-.5.9-.9,1.2c-.4.3-.9.5-1.5.5h0ZM59.8,90.9c.4,0,.8,0,1.1-.3s.5-.5.6-.9.2-.8.2-1.3,0-.9-.2-1.3-.3-.7-.6-.9c-.3-.2-.6-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2,0,.9.2,1.2c0,.4.4.7.6.9s.6.3,1.1.3v.2h0ZM61.7,91.7v-4.8h0v-3.9h1.1v8.6h-1.1Z" fill="#bfbfbf"/>
            </g>
          </g>
          <line id="Line_63" x1="231.9" y1="39.1" x2="328.7" y2="39.1" fill="none" stroke="#ffc72e"/>
          <line id="Line_60" x1="333.4" y1="39.1" x2="618.2" y2="39.1" fill="none" stroke="#d4d4d4"/>
          <g id="Group_552">
            <circle id="Ellipse_88" cx="233.5" cy="38.5" r="38.5" fill="#ffeaaf"/>
            <path id="Path_638" d="M233.6,8.2c16.8,0,30.3,13.6,30.3,30.3s-13.6,30.3-30.3,30.3-30.3-13.6-30.3-30.3h0c0-16.8,13.6-30.3,30.3-30.3h0Z" fill="#ffc72e"/>
          </g>
          <g id="Group_550">
            <ellipse id="Ellipse_89" cx="424" cy="38.5" rx="38" ry="38.5" fill="#ffeaaf" fill-opacity="0"/>
            <ellipse id="Ellipse_84" cx="424" cy="38.5" rx="31" ry="30.5" fill="#f1f1f1"/>
          </g>
          <g id="Group_551">
            <ellipse id="Ellipse_90" cx="621" cy="38.5" rx="39" ry="38.5" fill="#cffbff" fill-opacity="0"/>
            <ellipse id="Ellipse_85" cx="621" cy="38.5" rx="30" ry="30.5" fill="#f1f1f1"/>
          </g>
          <circle id="Ellipse_87" cx="334" cy="39" r="5" fill="#ffc72e"/>
          <g id="thumbs_up">
            <g id="Group_676">
              <g id="Group_675">
                <path id="Path_672" d="M632.3,39.4c0-.8-.7-1.5-1.5-1.5.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5h-6.2c.7-1.7,1-3.5.9-5.3,0-1.2-1-2.3-2.3-2.3h-.5c-.3,0-.6.2-.7.6-.7,2.9-1.9,6.2-4.9,6.9v10.9l2.6.9c.8.3,1.6.4,2.4.4h7.1c.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5h1.5c.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5h0c.8,0,1.5-.7,1.5-1.5h.1Z" fill="#d9d9d9"/>
              </g>
            </g>
            <g id="Group_678">
              <g id="Group_677">
                <path id="Path_673" d="M613.4,33.4h-3c-.4,0-.8.3-.8.8h0v13.6c0,.4.3.8.8.8h3c1.3,0,2.3-1,2.3-2.3v-10.6c0-1.3-1-2.3-2.3-2.3ZM613.4,45.5c-.4,0-.8-.3-.8-.8s.3-.8.8-.8.8.3.8.8h0c0,.4-.3.8-.8.8Z" fill="#d9d9d9"/>
              </g>
            </g>
            <g id="Group_680">
              <g id="Group_679">
                <path id="Path_674" d="M620.1,26l-1.1-1.1c-.3-.3-.8-.3-1.1,0s-.3.8,0,1.1l1.1,1.1c.3.3.8.3,1.1,0s.3-.8,0-1.1h0Z" fill="#d9d9d9"/>
              </g>
            </g>
            <g id="Group_682">
              <g id="Group_681">
                <path id="Path_675" d="M628.6,25c-.3-.3-.8-.3-1.1,0h0l-1.1,1.1c-.3.3-.3.8,0,1.1s.8.3,1.1,0l1.1-1.1c.3-.3.3-.8,0-1.1h0Z" fill="#d9d9d9"/>
              </g>
            </g>
            <g id="Group_684">
              <g id="Group_683">
                <path id="Path_676" d="M623.3,22.7c-.4,0-.8.3-.8.7h0v1.6c0,.4.4.7.8.7s.7-.3.7-.7v-1.6c0-.4-.3-.8-.7-.8h0Z" fill="#d9d9d9"/>
              </g>
            </g>
          </g>
          <g id="inventory_2_black_24dp">
            <g id="Group_4910">
              <rect id="Rectangle_1309" x="221" y="26" width="26" height="26" fill="none"/>
            </g>
            <g id="Group_4911">
              <path id="Path_3926" d="M242.3,28.6h-16.6c-1.1,0-2,.9-2.1,2.1v3.1c0,.7.4,1.4,1,1.8v11.8c0,1.1,1,2,2.1,2.1h14.6c1.1,0,2-1,2.1-2.1v-11.8c.6-.4,1-1,1-1.8v-3.1c0-1.1-.9-2-2.1-2.1ZM237.1,41.1h-6.2v-2.1h6.2v2.1ZM242.3,33.8h-16.6v-3.1h16.6v3.1Z" fill="#fff"/>
            </g>
          </g>
          <g id="Group_5031">
            <g id="Group_5030">
              <path id="Path_4200" d="M429.3,40.7c-3.1,0-5.7,2.5-5.7,5.7s2.5,5.7,5.7,5.7,5.7-2.5,5.7-5.7h0c0-3.1-2.5-5.7-5.7-5.7h0M428.2,49.2l-2.3-2.3.8-.8,1.5,1.5,3.7-3.7.8.8-4.5,4.5Z" fill="#d9d9d9"/>
              <path id="Path_4201" d="M421.9,46.3c0-4.1,3.3-7.4,7.4-7.4s1.9.2,2.8.5v-2.6c.6-.3.9-.9.9-1.6v-2.8c0-1-.9-1.8-1.9-1.9h-15.1c-1,0-1.8.9-1.9,1.9v2.8c0,.7.4,1.3.9,1.6v10.6c0,1,.9,1.8,1.9,1.9h5.5c-.4-1-.7-2-.7-3.1M416.1,32.4h15.1v2.8h-15.1v-2.8Z" fill="#d9d9d9"/>
            </g>
          </g>
          <g>
            <path d="M207.7,99.2v-10.1h4.9c.6,0,1,.3,1.4.6s.7.7.8,1.1.3.9.3,1.5,0,1-.3,1.5c-.2.4-.5.8-.8,1.1s-.8.5-1.4.6h-3.4v3.6h-1.5ZM209.1,94.2h3.2c.3,0,.5-.2.7-.4.2-.2.3-.4.4-.7,0-.2.1-.5.1-.7s0-.5-.1-.7-.2-.5-.4-.7-.4-.3-.7-.4h-3.2v3.7h0Z"/>
            <path d="M218.3,99.4c-.6,0-1,0-1.4-.3s-.7-.5-.9-.8-.3-.7-.3-1.1,0-.7.2-1c.1-.3.3-.5.6-.7s.6-.4,1-.5c.3,0,.7-.2,1.1-.3.4,0,.9,0,1.3-.2.5,0,.9,0,1.4-.2l-.5.3c0-.6-.1-1-.4-1.3s-.7-.4-1.3-.4-.8,0-1.1.3c-.3.2-.6.5-.7.9l-1.4-.4c.2-.6.5-1.2,1.1-1.5.5-.4,1.2-.6,2.1-.6s1.3,0,1.8.3c.5.2.9.6,1.1,1.1.1.2.2.5.2.8v5.6h-1.3v-1.7l.3.2c-.3.6-.7,1-1.2,1.3s-1.1.4-1.8.4v-.2h0ZM218.6,98.3c.4,0,.8,0,1.1-.2.3,0,.5-.3.7-.6.2-.2.3-.5.4-.7,0-.2.1-.5.1-.8v-.7l.5.2c-.5,0-.9,0-1.3.2-.4,0-.7,0-1,.2s-.6,0-.8.2c-.2,0-.4.2-.5.3-.2,0-.3.2-.4.4s-.1.3-.1.5,0,.4.1.5c0,.2.2.3.4.4h.8Z"/>
            <path d="M227.4,99.4c-.8,0-1.4-.2-2-.5-.5-.3-1-.8-1.2-1.4-.3-.6-.4-1.3-.4-2.1s.2-1.5.4-2.1.7-1.1,1.3-1.4c.5-.3,1.2-.5,2-.5s1.5.2,2.1.6,1,1,1.2,1.7l-1.5.4c-.1-.4-.4-.8-.7-1s-.7-.4-1.1-.4-.9,0-1.2.3c-.3.2-.5.5-.7.9s-.2.8-.2,1.3c0,.8.2,1.4.5,1.9s.9.7,1.6.7.9,0,1.1-.3c.3-.2.5-.5.7-.9l1.5.3c-.2.7-.7,1.3-1.2,1.7-.6.4-1.3.6-2.1.6v.2h-.1Z"/>
            <path d="M232.1,99.2v-10.1h1.5v6.2l3-3.6h1.9l-3.1,3.8,3.5,3.8h-2l-3.2-3.6v3.6h-1.6,0Z"/>
            <path d="M239.6,90.4v-1.4h1.5v1.4h-1.5ZM239.6,99.2v-7.6h1.5v7.6h-1.5Z"/>
            <path d="M243.2,99.2v-7.6h1.3v2.2h.2v5.4h-1.5ZM248.5,99.2v-4.6c0-.3-.1-.6-.3-.9-.1-.3-.3-.5-.6-.6s-.6-.2-1-.2h-.7c-.2,0-.4.2-.6.4s-.3.4-.4.8-.2.7-.2,1.1l-.9-.3c0-.7.1-1.3.4-1.8.3-.5.6-.9,1.1-1.2s1.1-.4,1.7-.4,1,0,1.3.3.6.4.9.7c.2.3.4.6.5.9s.2.6.2.9v5h-1.5v-.2h.1Z"/>
            <path d="M254.8,99.4c-.7,0-1.3-.2-1.8-.5s-.9-.8-1.2-1.4-.4-1.3-.4-2,.1-1.4.4-2c.3-.6.7-1.1,1.2-1.4s1.1-.5,1.8-.5,1.3.2,1.8.5.9.8,1.1,1.4.4,1.3.4,2-.1,1.4-.4,2-.6,1.1-1.1,1.4c-.5.4-1.1.5-1.8.5ZM254.9,102.8c-.4,0-.8,0-1.2-.2-.4,0-.7-.3-1-.6s-.6-.5-.8-.9l1.4-.7c.2.3.4.6.7.7s.6.2,1,.2.8,0,1.1-.2c.3,0,.5-.4.7-.7.2-.3.2-.7.2-1.1v-2.1h.2v-5.6h1.3v8.9c-.1.5-.3.9-.6,1.3s-.7.6-1.1.7c-.5.2-1,.2-1.6.2h-.3,0ZM255,98.1c.5,0,.9,0,1.2-.3.3-.2.5-.5.7-.9.1-.4.2-.9.2-1.4s0-1-.2-1.4-.4-.7-.7-.9c-.3-.2-.7-.3-1.1-.3s-.9,0-1.2.4-.5.6-.7,1-.2.8-.2,1.3,0,1,.2,1.4.4.7.7,1c.3.2.7.4,1.2.4v-.3h-.1Z"/>
          </g>
          <g>
            <path d="M407.3,91.7v-8.6h4c.5,0,.9.2,1.2.5.3.2.6.6.7.9s.2.8.2,1.3-.2,1.3-.5,1.7-.9.8-1.5.9h-3.1v3.2h-1.1.1,0ZM408.4,87.5h2.9c.3,0,.5-.2.7-.4s.3-.4.4-.6.1-.5.1-.7,0-.5-.1-.7c0-.2-.2-.4-.4-.6s-.4-.3-.7-.4h-2.9s0,3.4,0,3.4ZM412.5,91.7l-1.7-3.5,1.1-.3,1.9,3.8h-1.3Z" fill="#bfbfbf"/>
            <path d="M417.8,91.9c-.6,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8.6-.9,1.1-1.2c.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8s-.8-.6-1.4-.6-1.2.2-1.5.6-.5,1-.5,1.8.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3s-1.1.5-1.7.5h.1ZM415.4,88.8v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
            <path d="M423.8,91.9c-.5,0-.9,0-1.2-.3s-.6-.4-.7-.7-.2-.6-.2-1,0-.6.2-.9.3-.5.5-.6c.2-.2.5-.3.8-.4.3,0,.7-.2,1-.2s.8,0,1.2-.2c.4,0,.8,0,1.1-.2l-.4.2c0-.6-.1-1-.3-1.3s-.7-.4-1.2-.4-.7,0-1,.3c-.3.2-.5.5-.6.9l-1-.3c.2-.6.5-1,.9-1.3s1-.5,1.8-.5,1.1,0,1.5.3.7.5.9,1c0,.2.1.4.2.7v4.7h-.9v-1.6h.2c-.2.7-.6,1.1-1,1.3-.4.3-1,.4-1.6.4h-.2,0ZM424,91c.4,0,.7,0,1-.2s.5-.3.7-.5.3-.5.3-.7v-1.3l.4.2h-2c-.3,0-.6,0-.8.2-.2,0-.3,0-.5.2-.2,0-.3.2-.4.3,0,0-.1.3-.1.5s0,.3.1.5.2.3.4.4c.2,0,.4.2.7.2h.2v.2Z" fill="#bfbfbf"/>
            <path d="M431.3,91.9c-.6,0-1.1,0-1.6-.4-.4-.3-.8-.7-1-1.2s-.4-1.1-.4-1.7.1-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1,0,1.5.4.7.7.9,1.2.3,1.1.3,1.7-.1,1.2-.3,1.7-.5.9-.9,1.2c-.4.3-.9.5-1.5.5h.1ZM431.4,90.9c.4,0,.8,0,1.1-.3s.5-.5.6-.9.2-.8.2-1.3,0-.9-.2-1.3-.3-.7-.6-.9c-.3-.2-.6-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2,0,.9.2,1.2c.1.4.4.7.6.9s.6.3,1.1.3v.2h-.1ZM433.3,91.7v-4.8h-.1v-3.9h1.1v8.6h-1Z" fill="#bfbfbf"/>
            <path d="M436.8,94.6l1.3-3.4v1l-2.8-7h1.1l2.2,5.6h-.4l2.1-5.6h1.1l-3.5,9.4h-1.1Z" fill="#bfbfbf"/>
          </g>
          <g>
            <path d="M592.8,91.7v-8.6h4c.5,0,.9.2,1.2.5.3.2.6.6.7.9s.2.8.2,1.3,0,.9-.2,1.3-.4.7-.7.9c-.3.2-.7.4-1.2.5h-2.9v3.2s-1.1,0-1.1,0ZM593.8,87.5h2.9c.3,0,.5-.2.7-.4s.3-.4.4-.6,0-.5,0-.7v-.7c0-.2-.2-.4-.4-.6s-.4-.3-.7-.4h-2.9s0,3.4,0,3.4Z" fill="#bfbfbf"/>
            <path d="M600.3,84.1v-1.1h1.1v1.1h-1.1ZM600.3,91.7v-6.5h1.1v6.5h-1.1Z" fill="#bfbfbf"/>
            <path d="M605.9,91.9c-.7,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2c-.2-.5-.4-1.1-.4-1.8s0-1.3.4-1.8c.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5.8.8,1,1.4l-1.1.3c0-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8,0-1.1.3-.5.5-.6.9-.2.8-.2,1.2c0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5h-.2,0Z" fill="#bfbfbf"/>
            <path d="M609.9,91.7v-8.6h1.1v5.3l2.7-3.1h1.4l-2.9,3.2,3.2,3.2h-1.5l-2.9-3.1v3.1h-1.1Z" fill="#bfbfbf"/>
            <path d="M618.7,91.9c-.6,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8,0-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8s-.8-.6-1.4-.6-1.2.2-1.5.6-.5,1-.5,1.8.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3s-1.1.5-1.7.5h.1ZM616.3,88.8v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
            <path d="M625.5,91.9c-.6,0-1.1,0-1.6-.4-.4-.3-.8-.7-1-1.2s-.4-1.1-.4-1.7,0-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1,0,1.5.4.7.7.9,1.2.3,1.1.3,1.7,0,1.2-.3,1.7-.5.9-.9,1.2c-.4.3-.9.5-1.5.5h0ZM625.6,90.9c.4,0,.8,0,1.1-.3s.5-.5.6-.9.2-.8.2-1.3,0-.9-.2-1.3-.3-.7-.6-.9c-.3-.2-.6-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2,0,.9.2,1.2c0,.4.4.7.6.9s.6.3,1.1.3v.2h0ZM627.5,91.7v-4.8h0v-3.9h1.1v8.6h-1.1Z" fill="#bfbfbf"/>
            <path d="M630,88.4v-.9h3.6v.9h-3.6Z" fill="#bfbfbf"/>
            <path d="M638.6,91.9c-.7,0-1.3,0-1.8-.4s-.9-.7-1.2-1.2-.4-1.1-.4-1.8v-5.5h1.1v5.4c0,.4,0,.8.2,1.1s.3.6.5.8c.2.2.5.3.8.4h1.6c.3,0,.5-.2.8-.4.2-.2.4-.4.5-.8,0-.3.2-.7.2-1.1v-5.4h1.1v5.5c0,.7,0,1.3-.4,1.8-.3.5-.7.9-1.2,1.2-.5.3-1.1.4-1.8.4h0Z" fill="#bfbfbf"/>
            <path d="M643.7,94.6v-9.4h1v4.8h0v4.6h-1.1,0ZM646.7,91.9c-.6,0-1.1-.2-1.5-.5s-.7-.7-.9-1.2-.3-1.1-.3-1.7,0-1.2.3-1.7.5-.9.9-1.2.9-.4,1.5-.4,1.1,0,1.6.4c.4.3.8.7,1,1.2s.3,1.1.3,1.7,0,1.2-.3,1.7-.6.9-1,1.2c-.4.3-1,.4-1.6.4h0ZM646.5,90.9c.4,0,.8,0,1.1-.3s.5-.5.6-.9.2-.8.2-1.2,0-.9-.2-1.2c0-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8,0-1,.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.3,0,.9.2,1.3c0,.4.3.7.6.9.3.2.6.3,1.1.3v-.2h0Z" fill="#bfbfbf"/>
          </g>
        </g>
      </svg>
    );
  const statusPackedMobile = // Done
    (
      <svg id="statusPackedMobile_1_" data-name="statusPackedMobile (1)" xmlns="http://www.w3.org/2000/svg" width="480" height="103.8" viewBox="0 0 480 103.8">
  <g id="Group_4701" opacity="0.5">
    <line id="Line_64" x2="36.9" transform="translate(60.3 39.1)" fill="none"/>
    <path id="Path_664" d="M62.7,39.1h80" fill="none" stroke="#2ec278" stroke-width="1"/>
    <circle id="Ellipse_86" cx="38.5" cy="38.5" r="38.5" opacity="0"/>
    <ellipse id="Ellipse_82" cx="30" cy="30.5" rx="30" ry="30.5" transform="translate(9 8)" fill="#2ec278"/>
    <g id="verified-24px">
      <g id="Group_564">
        <rect id="Rectangle_235" width="28" height="28" transform="translate(25 25)" fill="none"/>
      </g>
      <g id="Group_565">
        <path id="Path_623" d="M52.5,39.7l-2.8-3.3.4-4.3-4.2-1-2.2-3.7-4,1.7-4-1.7-2.2,3.7-4.2.9.4,4.3-2.9,3.3,2.8,3.3-.4,4.3,4.2,1,2.2,3.7,4-1.7,4,1.7,2.2-3.7,4.2-1-.4-4.3,2.9-3.3h0Zm-15,5.5-4.4-4.4,1.7-1.7,2.7,2.7,6.8-6.9L46,36.6l-8.6,8.6h0Z" fill="#fff"/>
      </g>
    </g>
    <g id="Group_6696" data-name="Group 6696">
      <path id="Path_8558" data-name="Path 8558" d="M12.3,91.7l2.9-8.6h1.5l2.9,8.6H18.5l-2.7-8h.3l-2.7,8Zm1.4-2v-1h4.5v1H13.7Z" fill="#bfbfbf"/>
      <path id="Path_8559" data-name="Path 8559" d="M23.4,91.9a2.414,2.414,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,4.776,4.776,0,0,1-.4-1.8,3.425,3.425,0,0,1,.4-1.8,2.545,2.545,0,0,1,1.1-1.2,3.064,3.064,0,0,1,1.7-.4,3.453,3.453,0,0,1,1.8.5,2.434,2.434,0,0,1,1,1.4l-1.1.3a1.6,1.6,0,0,0-.7-.9,1.929,1.929,0,0,0-1-.3,1.548,1.548,0,0,0-1.1.3,1.8,1.8,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.86,2.86,0,0,0,.5,1.7,1.806,1.806,0,0,0,1.5.7c.7,0,.8,0,1.1-.3a2.1,2.1,0,0,0,.6-.8l1.1.3a2.981,2.981,0,0,1-1,1.4,3.1,3.1,0,0,1-1.7.5h-.2Z" fill="#bfbfbf"/>
      <path id="Path_8560" data-name="Path 8560" d="M29.9,91.9a2.414,2.414,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,4.776,4.776,0,0,1-.4-1.8,3.425,3.425,0,0,1,.4-1.8,2.545,2.545,0,0,1,1.1-1.2,3.064,3.064,0,0,1,1.7-.4,3.453,3.453,0,0,1,1.8.5,2.434,2.434,0,0,1,1,1.4l-1.1.3a1.6,1.6,0,0,0-.7-.9,1.929,1.929,0,0,0-1-.3,1.548,1.548,0,0,0-1.1.3,1.8,1.8,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.86,2.86,0,0,0,.5,1.7,1.806,1.806,0,0,0,1.5.7c.7,0,.8,0,1.1-.3a2.1,2.1,0,0,0,.6-.8l1.1.3a2.981,2.981,0,0,1-1,1.4,3.1,3.1,0,0,1-1.7.5h-.2Z" fill="#bfbfbf"/>
      <path id="Path_8561" data-name="Path 8561" d="M36.5,91.9a2.543,2.543,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,3.425,3.425,0,0,1-.4-1.8,2.689,2.689,0,0,1,.4-1.8,3.179,3.179,0,0,1,1.1-1.2,2.934,2.934,0,0,1,1.6-.4,3.371,3.371,0,0,1,1.7.5,2.209,2.209,0,0,1,1,1.3,6.013,6.013,0,0,1,.3,2H38.3v-.4a2.969,2.969,0,0,0-.5-1.8,1.678,1.678,0,0,0-1.4-.6,1.887,1.887,0,0,0-1.5.6,3.49,3.49,0,0,0,0,3.6,1.755,1.755,0,0,0,1.5.6c.7,0,.8,0,1.1-.3a1.779,1.779,0,0,0,.7-.8l1,.4a2.85,2.85,0,0,1-1.1,1.3,3.371,3.371,0,0,1-1.7.5h.1Zm-2.4-3.1v-.9h4.8v.9Z" fill="#bfbfbf"/>
      <path id="Path_8562" data-name="Path 8562" d="M40.6,94.6V85.2h1V90h0v4.6H40.5Zm3-2.7a2.456,2.456,0,0,1-1.5-.5,2.73,2.73,0,0,1-.9-1.2,4.671,4.671,0,0,1-.3-1.7,3.233,3.233,0,0,1,.3-1.7,3.778,3.778,0,0,1,.9-1.2,2.41,2.41,0,0,1,1.5-.4,2.276,2.276,0,0,1,1.6.4,2.94,2.94,0,0,1,1,1.2,4.671,4.671,0,0,1,.3,1.7,3.233,3.233,0,0,1-.3,1.7,4,4,0,0,1-1,1.2,2.726,2.726,0,0,1-1.6.4h0Zm-.1-1a1.431,1.431,0,0,0,1.1-.3,1.8,1.8,0,0,0,.6-.9,5.019,5.019,0,0,0,.2-1.2,2.345,2.345,0,0,0-.2-1.2c0-.4-.4-.7-.6-.9a1.8,1.8,0,0,0-1.1-.3c-.5,0-.8,0-1,.3a1.456,1.456,0,0,0-.6.9,5.067,5.067,0,0,0-.2,1.3,2.745,2.745,0,0,0,.2,1.3,1.149,1.149,0,0,0,.6.9,1.8,1.8,0,0,0,1.1.3v-.2Z" fill="#bfbfbf"/>
      <path id="Path_8563" data-name="Path 8563" d="M47.1,86.1v-.9h4.3v.9Zm4.4,5.6H50.4a1.645,1.645,0,0,1-1-.2,2.336,2.336,0,0,1-.7-.7c0-.3-.2-.5-.2-.8V83.3h1.1v7c0,.3.4.4.7.5h1.1v.9Z" fill="#bfbfbf"/>
      <path id="Path_8564" data-name="Path 8564" d="M55.3,91.9a2.543,2.543,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,3.425,3.425,0,0,1-.4-1.8,2.689,2.689,0,0,1,.4-1.8,3.179,3.179,0,0,1,1.1-1.2,2.934,2.934,0,0,1,1.6-.4,3.371,3.371,0,0,1,1.7.5,2.209,2.209,0,0,1,1,1.3,6.013,6.013,0,0,1,.3,2H57.1v-.4a2.969,2.969,0,0,0-.5-1.8,1.678,1.678,0,0,0-1.4-.6,1.887,1.887,0,0,0-1.5.6,3.49,3.49,0,0,0,0,3.6,1.755,1.755,0,0,0,1.5.6c.7,0,.8,0,1.1-.3a1.779,1.779,0,0,0,.7-.8l1,.4a2.85,2.85,0,0,1-1.1,1.3,3.371,3.371,0,0,1-1.7.5h.1Zm-2.4-3.1v-.9h4.8v.9Z" fill="#bfbfbf"/>
      <path id="Path_8565" data-name="Path 8565" d="M62.1,91.9a2.276,2.276,0,0,1-1.6-.4,2.94,2.94,0,0,1-1-1.2,4.711,4.711,0,0,1-.4-1.7,2.543,2.543,0,0,1,.4-1.7,2.94,2.94,0,0,1,1-1.2,2.41,2.41,0,0,1,1.5-.4,1.913,1.913,0,0,1,1.5.4,3.449,3.449,0,0,1,.9,1.2,4.671,4.671,0,0,1,.3,1.7,3.233,3.233,0,0,1-.3,1.7,3.778,3.778,0,0,1-.9,1.2A2.456,2.456,0,0,1,62,92h0Zm.2-1a1.431,1.431,0,0,0,1.1-.3,1.8,1.8,0,0,0,.6-.9,5.066,5.066,0,0,0,.2-1.3,2.745,2.745,0,0,0-.2-1.3,2.034,2.034,0,0,0-.6-.9,1.689,1.689,0,0,0-1-.3,1.431,1.431,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.345,2.345,0,0,0,.2,1.2c0,.4.4.7.6.9a1.609,1.609,0,0,0,1.1.3v.2Zm1.9.8V86.9h0V83h1.1v8.6h-1Z" fill="#bfbfbf"/>
    </g>
  </g>
  <line id="Line_63" x2="66.8" transform="translate(171.9 39.1)" fill="none" stroke="#ffc72e" stroke-width="1"/>
  <line id="Line_60" x2="194.8" transform="translate(243.4 39.1)" fill="none" stroke="#d4d4d4" stroke-width="1"/>
  <g id="Group_552">
    <circle id="Ellipse_88" cx="38.5" cy="38.5" r="38.5" transform="translate(135)" fill="#ffeaaf"/>
    <path id="Path_638" d="M173.6,8.2a30.3,30.3,0,1,1-30.3,30.3h0A30.28,30.28,0,0,1,173.6,8.2h0Z" fill="#ffc72e"/>
  </g>
  <g id="Group_550">
    <ellipse id="Ellipse_89" cx="38" cy="38.5" rx="38" ry="38.5" transform="translate(266)" opacity="0"/>
    <ellipse id="Ellipse_84" cx="31" cy="30.5" rx="31" ry="30.5" transform="translate(273 8)" fill="#f1f1f1"/>
  </g>
  <g id="Group_551">
    <ellipse id="Ellipse_90" cx="39" cy="38.5" rx="39" ry="38.5" transform="translate(402)" opacity="0"/>
    <ellipse id="Ellipse_85" cx="30" cy="30.5" rx="30" ry="30.5" transform="translate(411 8)" fill="#f1f1f1"/>
  </g>
  <circle id="Ellipse_87" cx="5" cy="5" r="5" transform="translate(239 34)" fill="#ffc72e"/>
  <g id="thumbs_up">
    <g id="Group_676">
      <g id="Group_675">
        <path id="Path_672" d="M452.3,39.4a1.538,1.538,0,0,0-1.5-1.5,1.5,1.5,0,0,0,0-3h-6.2a12.111,12.111,0,0,0,.9-5.3,2.263,2.263,0,0,0-2.3-2.3h-.5a.858.858,0,0,0-.7.6c-.7,2.9-1.9,6.2-4.9,6.9V45.7l2.6.9a6.722,6.722,0,0,0,2.4.4h7.1a1.5,1.5,0,0,0,0-3h1.5a1.5,1.5,0,0,0,0-3h0a1.538,1.538,0,0,0,1.5-1.5h.1Z" fill="#d9d9d9"/>
      </g>
    </g>
    <g id="Group_678">
      <g id="Group_677">
        <path id="Path_673" d="M433.4,33.4h-3a.789.789,0,0,0-.8.8h0V47.8a.789.789,0,0,0,.8.8h3a2.308,2.308,0,0,0,2.3-2.2h0V35.8a2.308,2.308,0,0,0-2.2-2.3h0Zm0,12.1a.789.789,0,0,1-.8-.8h0a.8.8,0,1,1,1.6,0h0a.789.789,0,0,1-.8.8Z" fill="#d9d9d9"/>
      </g>
    </g>
    <g id="Group_680">
      <g id="Group_679">
        <path id="Path_674" d="M440,26l-1.1-1.1a.778.778,0,1,0-1.1,1.1h0l1.1,1.1A.778.778,0,0,0,440,26Z" fill="#d9d9d9"/>
      </g>
    </g>
    <g id="Group_682">
      <g id="Group_681">
        <path id="Path_675" d="M448.6,25a.785.785,0,0,0-1.1,0h0l-1.1,1.1a.778.778,0,0,0,1.1,1.1l1.1-1.1A.785.785,0,0,0,448.6,25Z" fill="#d9d9d9"/>
      </g>
    </g>
    <g id="Group_684">
      <g id="Group_683">
        <path id="Path_676" d="M443.3,22.7a.773.773,0,0,0-.8.7h0V25a.773.773,0,0,0,.8.7.684.684,0,0,0,.7-.7h0V23.4a.773.773,0,0,0-.7-.8h0Z" fill="#d9d9d9"/>
      </g>
    </g>
  </g>
  <g id="inventory_2_black_24dp">
    <g id="Group_4910">
      <rect id="Rectangle_1309" width="26" height="26" transform="translate(161 26)" fill="none"/>
    </g>
    <g id="Group_4911">
      <path id="Path_3926" d="M182.3,28.6H165.7a2.182,2.182,0,0,0-2.1,2.1v3.1a2.234,2.234,0,0,0,1,1.8V47.4a2.258,2.258,0,0,0,2.1,2.1h14.6a2.258,2.258,0,0,0,2.1-2.1V35.6a2.234,2.234,0,0,0,1-1.8V30.7a2.182,2.182,0,0,0-2.1-2.1Zm-5.2,12.5h-6.2V39h6.2Zm5.2-7.3H165.7V30.7h16.6Z" fill="#fff"/>
    </g>
  </g>
  <g id="Group_5031">
    <g id="Group_8221">
      <g id="Group_5030">
        <path id="Path_4200" d="M309.3,40.7a5.7,5.7,0,1,0,5.7,5.7h0a5.763,5.763,0,0,0-5.7-5.7h0m-1.1,8.5-2.3-2.3.8-.8,1.5,1.5,3.7-3.7.8.8Z" fill="#d9d9d9"/>
        <path id="Path_4201" d="M301.9,46.3a7.383,7.383,0,0,1,7.4-7.4h0a8.675,8.675,0,0,1,2.8.5V36.8a1.7,1.7,0,0,0,.9-1.6V32.4a2.043,2.043,0,0,0-1.9-1.9H296a2.043,2.043,0,0,0-1.9,1.9v2.8a1.7,1.7,0,0,0,.9,1.6V47.4a2.043,2.043,0,0,0,1.9,1.9h5.5a8.245,8.245,0,0,1-.7-3.1m-5.6-13.8h15.1v2.8H296.1Z" fill="#d9d9d9"/>
      </g>
    </g>
  </g>
  <g id="Group_6697" data-name="Group 6697">
    <path id="Path_8566" data-name="Path 8566" d="M287.3,91.7V83.1h4a1.656,1.656,0,0,1,1.2.5,2.016,2.016,0,0,1,.7.9,4.438,4.438,0,0,1,.2,1.3,3.371,3.371,0,0,1-.5,1.7,2.551,2.551,0,0,1-1.5.9h-3.1v3.2h-1Zm1.1-4.2h2.9a1.012,1.012,0,0,0,.7-.4,2.092,2.092,0,0,0,.4-.6,2.5,2.5,0,0,0,0-1.4c0-.2-.2-.4-.4-.6a1.612,1.612,0,0,0-.7-.4h-2.9v3.4Zm4.1,4.2-1.7-3.5,1.1-.3,1.9,3.8Z" fill="#bfbfbf"/>
    <path id="Path_8567" data-name="Path 8567" d="M297.8,91.9a2.543,2.543,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,4.25,4.25,0,0,1,0-3.6,3.179,3.179,0,0,1,1.1-1.2,2.934,2.934,0,0,1,1.6-.4,3.371,3.371,0,0,1,1.7.5,2.209,2.209,0,0,1,1,1.3,6.013,6.013,0,0,1,.3,2h-1.1v-.4a2.969,2.969,0,0,0-.5-1.8,1.678,1.678,0,0,0-1.4-.6,1.887,1.887,0,0,0-1.5.6,3.49,3.49,0,0,0,0,3.6,1.755,1.755,0,0,0,1.5.6c.7,0,.8,0,1.1-.3a1.779,1.779,0,0,0,.7-.8l1,.4a2.85,2.85,0,0,1-1.1,1.3,3.371,3.371,0,0,1-1.7.5h.1Zm-2.4-3.1v-.9h4.8v.9Z" fill="#bfbfbf"/>
    <path id="Path_8568" data-name="Path 8568" d="M303.8,91.9a1.558,1.558,0,0,1-1.2-.3c-.3-.3-.6-.4-.7-.7a2.958,2.958,0,0,1-.2-1,1.344,1.344,0,0,1,.2-.9c.2-.3.3-.5.5-.6a2.01,2.01,0,0,1,.8-.4c.3,0,.7-.2,1-.2a2.745,2.745,0,0,0,1.2-.2,1.978,1.978,0,0,0,1.1-.2l-.4.2a2.269,2.269,0,0,0-.3-1.3,1.461,1.461,0,0,0-1.2-.4c-.5,0-.7,0-1,.3a1.456,1.456,0,0,0-.6.9l-1-.3a2.54,2.54,0,0,1,.9-1.3,2.969,2.969,0,0,1,1.8-.5c.8,0,1.1,0,1.5.3a2.189,2.189,0,0,1,.9,1,2.544,2.544,0,0,0,.2.7v4.7h-.9V90.1h.2a1.994,1.994,0,0,1-1,1.3,2.726,2.726,0,0,1-1.6.4h-.2Zm.2-.9a1.645,1.645,0,0,0,1-.2,4.235,4.235,0,0,0,.7-.5,1.08,1.08,0,0,0,.3-.7V88.3l.4.2h-2a1.077,1.077,0,0,0-.8.2.551.551,0,0,0-.5.2c-.2,0-.3.2-.4.3a2.034,2.034,0,0,0-.1.5.9.9,0,0,0,.1.5.781.781,0,0,0,.4.4c.2,0,.4.2.7.2h.2Z" fill="#bfbfbf"/>
    <path id="Path_8569" data-name="Path 8569" d="M311.3,91.9a2.276,2.276,0,0,1-1.6-.4,2.94,2.94,0,0,1-1-1.2,4.711,4.711,0,0,1-.4-1.7,3.287,3.287,0,0,1,.4-1.7,2.94,2.94,0,0,1,1-1.2,2.41,2.41,0,0,1,1.5-.4,1.913,1.913,0,0,1,1.5.4,3.449,3.449,0,0,1,.9,1.2,4.967,4.967,0,0,1,0,3.4,2.73,2.73,0,0,1-.9,1.2,2.456,2.456,0,0,1-1.5.5h.1Zm.1-1a1.431,1.431,0,0,0,1.1-.3,1.8,1.8,0,0,0,.6-.9,5.066,5.066,0,0,0,.2-1.3,2.745,2.745,0,0,0-.2-1.3,2.034,2.034,0,0,0-.6-.9,1.689,1.689,0,0,0-1-.3,1.431,1.431,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.345,2.345,0,0,0,.2,1.2,2.034,2.034,0,0,0,.6.9,1.609,1.609,0,0,0,1.1.3v.2Zm1.9.8V86.9h-.1V83h1.1v8.6h-1Z" fill="#bfbfbf"/>
    <path id="Path_8570" data-name="Path 8570" d="M316.8,94.6l1.3-3.4v1l-2.8-7h1.1l2.2,5.6h-.4l2.1-5.6h1.1l-3.5,9.4h-1.1Z" fill="#bfbfbf"/>
  </g>
  <g id="Group_6698" data-name="Group 6698">
    <path id="Path_8571" data-name="Path 8571" d="M148.2,100.2V90.1h4.9a2.246,2.246,0,0,1,1.4.6,2.1,2.1,0,0,1,.8,1.1,5.665,5.665,0,0,1,.3,1.5,2.544,2.544,0,0,1-.3,1.5,4.44,4.44,0,0,1-.8,1.1,2.594,2.594,0,0,1-1.4.6h-3.4v3.6h-1.5Zm1.4-5h3.2a1.012,1.012,0,0,0,.7-.4,1.612,1.612,0,0,0,.4-.7c0-.2.1-.5.1-.7a1.7,1.7,0,0,0-.1-.7,2.507,2.507,0,0,0-.4-.7,1.612,1.612,0,0,0-.7-.4h-3.2v3.7h0Z"/>
    <path id="Path_8572" data-name="Path 8572" d="M158.8,100.4a2.092,2.092,0,0,1-1.4-.3,3.631,3.631,0,0,1-.9-.8,2.016,2.016,0,0,1-.3-1.1,1.645,1.645,0,0,1,.2-1,1.344,1.344,0,0,1,.6-.7,3.128,3.128,0,0,1,1-.5,6.149,6.149,0,0,0,1.1-.3,2.954,2.954,0,0,0,1.3-.2,3.4,3.4,0,0,0,1.4-.2l-.5.3a1.453,1.453,0,0,0-1.7-1.7c-.6,0-.8,0-1.1.3a1.6,1.6,0,0,0-.7.9l-1.4-.4a2.4,2.4,0,0,1,1.1-1.5,3.281,3.281,0,0,1,2.1-.6,3.078,3.078,0,0,1,1.8.3,1.981,1.981,0,0,1,1.1,1.1,1.884,1.884,0,0,1,.2.8v5.6h-1.3V98.7l.3.2a3.039,3.039,0,0,1-1.2,1.3,3.425,3.425,0,0,1-1.8.4v-.2Zm.3-1.2a1.978,1.978,0,0,0,1.1-.2c.3,0,.5-.3.7-.6a2.506,2.506,0,0,0,.4-.7c0-.2.1-.5.1-.8v-.7l.5.2a2.745,2.745,0,0,0-1.3.2,1.645,1.645,0,0,0-1,.2c-.3.2-.6,0-.8.2a.85.85,0,0,0-.5.3c-.2,0-.3.2-.4.4a.9.9,0,0,0-.1.5.749.749,0,0,0,.1.5c0,.2.2.3.4.4h.8Z"/>
    <path id="Path_8573" data-name="Path 8573" d="M167.9,100.4a4.228,4.228,0,0,1-2-.5,2.758,2.758,0,0,1-1.2-1.4,4.638,4.638,0,0,1-.4-2.1,6.537,6.537,0,0,1,.4-2.1,2.539,2.539,0,0,1,1.3-1.4,3.939,3.939,0,0,1,2-.5,3.7,3.7,0,0,1,2.1.6,2.981,2.981,0,0,1,1.2,1.7l-1.5.4a1.834,1.834,0,0,0-.7-1,2.1,2.1,0,0,0-1.1-.4,1.676,1.676,0,0,0-1.2.3,2.178,2.178,0,0,0-.7.9,2.745,2.745,0,0,0-.2,1.3,3.557,3.557,0,0,0,.5,1.9,1.753,1.753,0,0,0,1.6.7c.7,0,.9,0,1.1-.3a2.178,2.178,0,0,0,.7-.9l1.5.3a3.528,3.528,0,0,1-1.2,1.7,3.7,3.7,0,0,1-2.1.6v.2Z"/>
    <path id="Path_8574" data-name="Path 8574" d="M172.6,100.2V90.1h1.5v6.2l3-3.6H179l-3.1,3.8,3.5,3.8h-2l-3.2-3.6v3.6h-1.6Z"/>
    <path id="Path_8575" data-name="Path 8575" d="M180.1,91.4V90h1.5v1.4Zm0,8.8V92.6h1.5v7.6Z"/>
    <path id="Path_8576" data-name="Path 8576" d="M183.7,100.2V92.6H185v2.2h.2v5.4Zm5.3,0V95.6a1.613,1.613,0,0,0-.3-.9.9.9,0,0,0-.6-.6,2.959,2.959,0,0,0-1-.2h-.7c-.2,0-.4.2-.6.4a1.506,1.506,0,0,0-.4.8,4.253,4.253,0,0,0-.2,1.1l-.9-.3a3.425,3.425,0,0,1,.4-1.8,3.179,3.179,0,0,1,1.1-1.2,3.287,3.287,0,0,1,1.7-.4c.6,0,1,0,1.3.3s.6.4.9.7a4.054,4.054,0,0,1,.5.9,2.92,2.92,0,0,1,.2.9v5h-1.5v-.2Z"/>
    <path id="Path_8577" data-name="Path 8577" d="M195.3,100.4a3.453,3.453,0,0,1-1.8-.5,3.325,3.325,0,0,1-1.2-1.4,5.2,5.2,0,0,1,0-4,3.325,3.325,0,0,1,1.2-1.4,3.49,3.49,0,0,1,3.6,0,2.586,2.586,0,0,1,1.1,1.4,6.465,6.465,0,0,1,.4,2,4.477,4.477,0,0,1-.4,2,3.142,3.142,0,0,1-1.1,1.4A2.73,2.73,0,0,1,195.3,100.4Zm.1,3.4a2.537,2.537,0,0,1-1.2-.2,1.524,1.524,0,0,1-1-.6,3.45,3.45,0,0,1-.8-.9l1.4-.7a1.575,1.575,0,0,0,.7.7,2.959,2.959,0,0,0,1,.2,1.978,1.978,0,0,0,1.1-.2c.3,0,.5-.4.7-.7a1.978,1.978,0,0,0,.2-1.1V98.2h.2V92.6H199v8.9a3.1,3.1,0,0,1-.6,1.3,1.9,1.9,0,0,1-1.1.7,4.145,4.145,0,0,1-1.6.2h-.3Zm.1-4.7a1.558,1.558,0,0,0,1.2-.3,2.178,2.178,0,0,0,.7-.9,5.9,5.9,0,0,0,.2-1.4,3.178,3.178,0,0,0-.2-1.4,2.178,2.178,0,0,0-.7-.9,2.016,2.016,0,0,0-1.1-.3,1.374,1.374,0,0,0-1.2.4,6.07,6.07,0,0,0-.7,1,2.745,2.745,0,0,0-.2,1.3,3.178,3.178,0,0,0,.2,1.4,3.591,3.591,0,0,0,.7,1,2.144,2.144,0,0,0,1.2.4v-.3Z"/>
  </g>
  <g id="Group_6699" data-name="Group 6699">
    <path id="Path_8578" data-name="Path 8578" d="M412.8,91.7V83.1h4a1.656,1.656,0,0,1,1.2.5,2.016,2.016,0,0,1,.7.9,4.438,4.438,0,0,1,.2,1.3,2.745,2.745,0,0,1-.2,1.3,2.178,2.178,0,0,1-.7.9,3.337,3.337,0,0,1-1.2.5h-2.9v3.2h-1.1Zm1-4.2h2.9a1.012,1.012,0,0,0,.7-.4,2.092,2.092,0,0,0,.4-.6,2.5,2.5,0,0,0,0-1.4c0-.2-.2-.4-.4-.6a1.612,1.612,0,0,0-.7-.4h-2.9v3.4Z" fill="#bfbfbf"/>
    <path id="Path_8579" data-name="Path 8579" d="M420.3,84.1V83h1.1v1.1Zm0,7.6V85.2h1.1v6.5Z" fill="#bfbfbf"/>
    <path id="Path_8580" data-name="Path 8580" d="M425.9,91.9a2.414,2.414,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,4.776,4.776,0,0,1-.4-1.8,3.425,3.425,0,0,1,.4-1.8,2.545,2.545,0,0,1,1.1-1.2,3.064,3.064,0,0,1,1.7-.4,3.453,3.453,0,0,1,1.8.5,2.434,2.434,0,0,1,1,1.4l-1.1.3a1.6,1.6,0,0,0-.7-.9,1.929,1.929,0,0,0-1-.3,1.548,1.548,0,0,0-1.1.3,1.8,1.8,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.86,2.86,0,0,0,.5,1.7,1.806,1.806,0,0,0,1.5.7c.7,0,.8,0,1.1-.3a2.1,2.1,0,0,0,.6-.8l1.1.3a2.981,2.981,0,0,1-1,1.4,3.1,3.1,0,0,1-1.7.5h-.2Z" fill="#bfbfbf"/>
    <path id="Path_8581" data-name="Path 8581" d="M429.9,91.7V83.1H431v5.3l2.7-3.1h1.4l-2.9,3.2,3.2,3.2h-1.5L431,88.6v3.1h-1.1Z" fill="#bfbfbf"/>
    <path id="Path_8582" data-name="Path 8582" d="M438.7,91.9a2.543,2.543,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,4.25,4.25,0,0,1,0-3.6,3.179,3.179,0,0,1,1.1-1.2,2.934,2.934,0,0,1,1.6-.4,3.371,3.371,0,0,1,1.7.5,2.209,2.209,0,0,1,1,1.3,6.013,6.013,0,0,1,.3,2h-1.1v-.4a2.969,2.969,0,0,0-.5-1.8,1.678,1.678,0,0,0-1.4-.6,1.887,1.887,0,0,0-1.5.6,3.49,3.49,0,0,0,0,3.6,1.755,1.755,0,0,0,1.5.6c.7,0,.8,0,1.1-.3a1.779,1.779,0,0,0,.7-.8l1,.4a2.85,2.85,0,0,1-1.1,1.3,3.371,3.371,0,0,1-1.7.5h.1Zm-2.4-3.1v-.9h4.8v.9Z" fill="#bfbfbf"/>
    <path id="Path_8583" data-name="Path 8583" d="M445.5,91.9a2.276,2.276,0,0,1-1.6-.4,2.94,2.94,0,0,1-1-1.2,4.711,4.711,0,0,1-.4-1.7,3.287,3.287,0,0,1,.4-1.7,2.94,2.94,0,0,1,1-1.2,2.41,2.41,0,0,1,1.5-.4,1.913,1.913,0,0,1,1.5.4,3.449,3.449,0,0,1,.9,1.2,4.967,4.967,0,0,1,0,3.4,2.73,2.73,0,0,1-.9,1.2,2.456,2.456,0,0,1-1.5.5h.1Zm.1-1a1.431,1.431,0,0,0,1.1-.3,1.8,1.8,0,0,0,.6-.9,5.066,5.066,0,0,0,.2-1.3,2.745,2.745,0,0,0-.2-1.3,2.034,2.034,0,0,0-.6-.9,1.689,1.689,0,0,0-1-.3,1.431,1.431,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.345,2.345,0,0,0,.2,1.2,2.034,2.034,0,0,0,.6.9,1.609,1.609,0,0,0,1.1.3v.2Zm1.9.8V86.9h-.1V83h1.1v8.6h-1Z" fill="#bfbfbf"/>
    <path id="Path_8584" data-name="Path 8584" d="M450.1,88.4v-.9h3.6v.9Z" fill="#bfbfbf"/>
    <path id="Path_8585" data-name="Path 8585" d="M458.6,91.9a2.689,2.689,0,0,1-1.8-.4,4.561,4.561,0,0,1-1.2-1.2,3.425,3.425,0,0,1-.4-1.8V83h1.1v5.4a1.978,1.978,0,0,0,.2,1.1,5.583,5.583,0,0,0,.5.8,2.01,2.01,0,0,0,.8.4h1.6c.3,0,.5-.2.8-.4a1.578,1.578,0,0,0,.5-.8,3.6,3.6,0,0,0,.2-1.1V83H462v5.5a3.166,3.166,0,0,1-3.4,3.4Z" fill="#bfbfbf"/>
    <path id="Path_8586" data-name="Path 8586" d="M463.7,94.6V85.2h1V90h.1v4.6Zm3-2.7a2.456,2.456,0,0,1-1.5-.5,2.73,2.73,0,0,1-.9-1.2,4.967,4.967,0,0,1,0-3.4,2.73,2.73,0,0,1,.9-1.2,2.41,2.41,0,0,1,1.5-.4,2.276,2.276,0,0,1,1.6.4,2.94,2.94,0,0,1,1,1.2,4.967,4.967,0,0,1,0,3.4,2.94,2.94,0,0,1-1,1.2,2.726,2.726,0,0,1-1.6.4h0Zm-.1-1a1.431,1.431,0,0,0,1.1-.3,1.8,1.8,0,0,0,.6-.9,5.019,5.019,0,0,0,.2-1.2,2.345,2.345,0,0,0-.2-1.2,2.034,2.034,0,0,0-.6-.9,1.8,1.8,0,0,0-1.1-.3c-.5,0-.8,0-1,.3a1.456,1.456,0,0,0-.6.9,5.066,5.066,0,0,0-.2,1.3,2.745,2.745,0,0,0,.2,1.3,1.456,1.456,0,0,0,.6.9,1.8,1.8,0,0,0,1.1.3v-.2Z" fill="#bfbfbf"/>
  </g>
      </svg>
    );
  const statusReadyDesktop = //Done
    (
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 660 104.9">
        <g id="d_s_ready">
          <g id="Group_4699" opacity=".5">
            <line id="Line_64" x1="60.3" y1="39.1" x2="157.2" y2="39.1" fill="none" stroke="#2ec278" stroke-opacity="0"/>
            <line id="Line_59" x1="69.6" y1="39.1" x2="202.7" y2="39.1" fill="none" stroke="#2ec278"/>
            <line id="Line_63" x1="231.9" y1="39.1" x2="390.6" y2="39.1" fill="none" stroke="#ff9557"/>
            <circle id="Ellipse_86" cx="38.5" cy="38.5" r="38.5" fill="#ffd5d5" fill-opacity="0"/>
            <circle id="Ellipse_82" cx="38.5" cy="38.5" r="30.5" fill="#2ec278"/>
            <g id="Group_552">
              <circle id="Ellipse_88" cx="234.5" cy="38.5" r="38.5" fill="#acffd6" fill-opacity="0"/>
              <path id="Path_638" d="M234.1,8.2c17,0,30.9,13.8,30.9,30.9s-13.8,30.9-30.9,30.9-30.9-13.8-30.9-30.9h0c0-17,13.8-30.9,30.9-30.9Z" fill="#ffc72e"/>
              <g id="inventory_2_black_24dp">
                <g id="Group_4910">
                  <rect id="Rectangle_1309" x="221" y="26" width="26" height="26" fill="none"/>
                </g>
                <g id="Group_4911">
                  <path id="Path_3926" d="M242.3,28.6h-16.6c-1.1,0-2,.9-2.1,2.1v3.1c0,.7.4,1.4,1,1.8v11.8c0,1.1,1,2,2.1,2.1h14.6c1.1,0,2-1,2.1-2.1v-11.8c.6-.4,1-1,1-1.8v-3.1c0-1.1-.9-2-2.1-2.1ZM237.1,41.1h-6.2v-2.1h6.2v2.1ZM242.3,33.8h-16.6v-3.1h16.6v3.1Z" fill="#fff"/>
                </g>
              </g>
            </g>
            <g id="verified-24px">
              <g id="Group_564">
                <rect id="Rectangle_235" x="25" y="26" width="28" height="28" fill="none"/>
              </g>
              <g id="Group_565">
                <path id="Path_623" d="M51.5,39.7l-2.8-3.3.4-4.3-4.2-1-2.2-3.7-4,1.7-4-1.7-2.2,3.7-4.2.9.4,4.3-2.9,3.3,2.8,3.3-.4,4.3,4.2,1,2.2,3.7,4-1.7,4,1.7,2.2-3.7,4.2-1-.4-4.3,2.9-3.3h0ZM36.5,45.2l-4.4-4.4,1.7-1.7,2.7,2.7,6.8-6.9,1.7,1.7-8.6,8.6h0Z" fill="#fff"/>
              </g>
            </g>
            <g>
              <path d="M11.8,91.8l2.9-8.6h1.5l2.9,8.6h-1.1l-2.7-8h.3l-2.7,8h-1.1ZM13.2,89.8v-1h4.5v1h-4.5Z" fill="#c9c9c9"/>
              <path d="M22.9,92c-.7,0-1.2,0-1.7-.4s-.8-.7-1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2c0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5h-.3,0Z" fill="#c9c9c9"/>
              <path d="M29.4,92c-.7,0-1.2,0-1.7-.4s-.8-.7-1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2c0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5h-.3,0Z" fill="#c9c9c9"/>
              <path d="M36,92c-.6,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8,0-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8s-.8-.6-1.4-.6-1.2.2-1.5.6-.5,1-.5,1.8.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3s-1.1.5-1.7.5h.1ZM33.6,88.9v-.9h4.8v.9h-4.8Z" fill="#c9c9c9"/>
              <path d="M40.1,94.7v-9.4h1v4.8h0v4.6h-1.1,0ZM43.1,92c-.6,0-1.1-.2-1.5-.5s-.7-.7-.9-1.2-.3-1.1-.3-1.7,0-1.2.3-1.7.5-.9.9-1.2c.4-.3.9-.4,1.5-.4s1.1,0,1.6.4.8.7,1,1.2.3,1.1.3,1.7,0,1.2-.3,1.7-.6.9-1,1.2c-.4.3-1,.4-1.6.4h0ZM43,91c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.2,0-.9-.2-1.2c0-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8,0-1,.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.3,0,.9.2,1.3c0,.4.3.7.6.9.3.2.6.3,1.1.3v-.2h0Z" fill="#c9c9c9"/>
              <path d="M46.6,86.2v-.9h4.3v.9h-4.3ZM51,91.8h-1.1c-.4,0-.7,0-1-.2s-.5-.4-.7-.7-.2-.5-.2-.8v-6.7h1.1v7c0,.3.4.4.7.5s.7,0,1.1,0v.9h.1Z" fill="#c9c9c9"/>
              <path d="M54.8,92c-.6,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8,0-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8s-.8-.6-1.4-.6-1.2.2-1.5.6-.5,1-.5,1.8.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3s-1.1.5-1.7.5h.1ZM52.4,88.9v-.9h4.8v.9h-4.8Z" fill="#c9c9c9"/>
              <path d="M61.6,92c-.6,0-1.1,0-1.6-.4-.4-.3-.8-.7-1-1.2s-.4-1.1-.4-1.7,0-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1,0,1.5.4.7.7.9,1.2c.2.5.3,1.1.3,1.7s0,1.2-.3,1.7-.5.9-.9,1.2c-.4.3-.9.5-1.5.5h0ZM61.8,91c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.3,0-.9-.2-1.3c0-.4-.3-.7-.6-.9s-.6-.3-1-.3-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2,0,.9.2,1.2c0,.4.4.7.6.9.3.2.6.3,1.1.3v.2h0ZM63.7,91.8v-4.8h0v-3.9h1.1v8.6h-1.1Z" fill="#c9c9c9"/>
            </g>
            <g>
              <path d="M213.2,91.8v-8.6h4c.5,0,.9.2,1.2.5.3.2.6.6.7.9.2.4.2.8.2,1.3s0,.9-.2,1.3-.4.7-.7.9-.7.4-1.2.5h-2.9v3.2h-1.1ZM214.3,87.6h2.9c.3,0,.5-.2.7-.4.2-.2.3-.4.4-.6s.1-.5.1-.7,0-.5-.1-.7c0-.2-.2-.4-.4-.6s-.4-.3-.7-.4h-2.9s0,3.4,0,3.4Z" fill="#c9c9c9"/>
              <path d="M222.2,92c-.5,0-.9,0-1.2-.3-.3-.2-.6-.4-.7-.7s-.2-.6-.2-1,0-.6.2-.9.3-.5.5-.6c.2-.2.5-.3.8-.4.3,0,.7-.2,1-.2s.8,0,1.2-.2c.4,0,.8,0,1.1-.2l-.4.2c0-.6-.1-1-.3-1.3s-.7-.4-1.2-.4-.7,0-1,.3c-.3.2-.5.5-.6.9l-1-.3c.2-.6.5-1,.9-1.3s1-.5,1.8-.5,1.1,0,1.5.3c.4.2.7.5.9,1,0,.2.2.4.2.7v4.7h-.9v-1.6h.2c-.2.7-.6,1.1-1,1.3-.4.3-1,.4-1.6.4h-.2ZM222.4,91.1c.4,0,.7,0,1-.2.3,0,.5-.3.7-.5s.3-.5.3-.7v-1.3l.4.2h-2c-.3,0-.6,0-.8.2-.2,0-.3,0-.5.2-.2,0-.3.2-.4.3,0,0-.1.3-.1.5s0,.3.1.5c0,.2.2.3.4.4s.4.2.7.2h.2v.2Z" fill="#c9c9c9"/>
              <path d="M229.9,92c-.7,0-1.2,0-1.7-.4s-.8-.7-1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2c0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5h-.3Z" fill="#c9c9c9"/>
              <path d="M233.9,91.8v-8.6h1.1v5.3l2.7-3.1h1.4l-2.9,3.2,3.2,3.2h-1.5l-2.9-3.1v3.1h-1.1Z" fill="#c9c9c9"/>
              <path d="M240.1,84.2v-1.1h1.1v1.1h-1.1ZM240.1,91.8v-6.5h1.1v6.5h-1.1Z" fill="#c9c9c9"/>
              <path d="M243,91.8v-6.5h1v1.7h.1v4.7h-1.1ZM247.6,91.8v-4.1c0-.3-.1-.5-.3-.8-.1-.2-.3-.4-.5-.5s-.5-.2-.9-.2h-.7c-.2,0-.4.2-.6.4-.2.2-.3.4-.4.7,0,.3-.1.6-.1,1l-.7-.2c0-.6.1-1.2.3-1.6.2-.5.5-.8,1-1s.9-.4,1.4-.4.8,0,1.1.2.5.3.7.5.4.5.5.7c.1.3.2.5.2.8v4.4h-1.1.1Z" fill="#c9c9c9"/>
              <path d="M252.8,92c-.6,0-1.1,0-1.6-.4-.4-.3-.8-.7-1-1.2s-.4-1.1-.4-1.7.1-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1,0,1.5.4.7.7.9,1.2.3,1.1.3,1.7-.1,1.2-.3,1.7-.5.9-.9,1.2c-.4.3-.9.5-1.5.5h.1ZM252.9,94.9c-.3,0-.7,0-1-.2-.3,0-.6-.3-.9-.5s-.5-.5-.7-.8l1-.5c.2.3.4.5.7.7.3,0,.6.2.9.2s.8,0,1-.2c.3,0,.5-.4.6-.7.1-.3.2-.7.2-1.1v-1.8h.1v-4.7h.9v7.5c0,.5-.3.8-.5,1.1-.2.3-.6.5-1,.7-.4,0-.8.2-1.4.2h0ZM252.9,91c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.3,0-.9-.2-1.3c-.1-.4-.3-.7-.6-.9s-.6-.3-1-.3-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2,0,.9.2,1.2c.1.4.4.7.6.9.3.2.6.3,1.1.3v.2h-.1Z" fill="#c9c9c9"/>
            </g>
          </g>
          <line id="Line_65" x1="437.3" y1="39.1" x2="527.2" y2="39.1" fill="none" stroke="#8147ed"/>
          <line id="Line_60" x1="527.2" y1="39.1" x2="618.3" y2="39.1" fill="none" stroke="#d4d4d4"/>
          <g id="Group_550">
            <circle id="Ellipse_89" cx="423.5" cy="38.5" r="38.5" fill="#e4d5ff"/>
            <circle id="Ellipse_84" cx="423.5" cy="38.5" r="30.5" fill="#8147ed"/>
          </g>
          <g id="Group_551">
            <ellipse id="Ellipse_90" cx="621" cy="38.5" rx="39" ry="38.5" fill="#cffbff" fill-opacity="0"/>
            <circle id="Ellipse_85" cx="621.5" cy="38.5" r="30.5" fill="#f1f1f1"/>
          </g>
          <circle id="Ellipse_87" cx="527" cy="39" r="5" fill="#8147ed"/>
          <g id="thumbs_up">
            <g id="Group_676">
              <g id="Group_675">
                <path id="Path_672" d="M632.3,41.4c0-.8-.7-1.5-1.5-1.5.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5h-6.2c.7-1.7,1-3.5.9-5.3,0-1.2-1-2.3-2.3-2.3h-.5c-.3,0-.6.2-.7.6-.7,2.9-1.9,6.2-4.9,6.9v10.9l2.6.9c.8.3,1.6.4,2.4.4h7.1c.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5h1.5c.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5h0c.8,0,1.5-.7,1.5-1.5h.1Z" fill="#d9d9d9"/>
              </g>
            </g>
            <g id="Group_678">
              <g id="Group_677">
                <path id="Path_673" d="M613.4,35.4h-3c-.4,0-.8.3-.8.8h0v13.6c0,.4.3.8.8.8h3c1.3,0,2.3-1,2.3-2.3v-10.6c0-1.3-1-2.3-2.3-2.3ZM613.4,47.5c-.4,0-.8-.3-.8-.8s.3-.8.8-.8.8.3.8.8h0c0,.4-.3.8-.8.8Z" fill="#d9d9d9"/>
              </g>
            </g>
            <g id="Group_680">
              <g id="Group_679">
                <path id="Path_674" d="M620.1,28l-1.1-1.1c-.3-.3-.8-.3-1.1,0s-.3.8,0,1.1l1.1,1.1c.3.3.8.3,1.1,0s.3-.8,0-1.1h0Z" fill="#d9d9d9"/>
              </g>
            </g>
            <g id="Group_682">
              <g id="Group_681">
                <path id="Path_675" d="M628.6,27c-.3-.3-.8-.3-1.1,0h0l-1.1,1.1c-.3.3-.3.8,0,1.1s.8.3,1.1,0l1.1-1.1c.3-.3.3-.8,0-1.1h0Z" fill="#d9d9d9"/>
              </g>
            </g>
            <g id="Group_684">
              <g id="Group_683">
                <path id="Path_676" d="M623.3,24.7c-.4,0-.8.3-.8.7h0v1.6c0,.4.4.7.8.7s.7-.3.7-.7v-1.6c0-.4-.3-.8-.7-.8h0Z" fill="#d9d9d9"/>
              </g>
            </g>
          </g>
          <g id="Group_5032">
            <g id="Group_5030">
              <path id="Path_4200" d="M429.3,39.2c-3.1,0-5.7,2.5-5.7,5.7s2.5,5.7,5.7,5.7,5.7-2.5,5.7-5.7h0c0-3.1-2.5-5.7-5.7-5.7h0M428.2,47.7l-2.3-2.3.8-.8,1.5,1.5,3.7-3.7.8.8-4.5,4.5Z" fill="#fff"/>
              <path id="Path_4201" d="M421.9,44.8c0-4.1,3.3-7.4,7.4-7.4s1.9.2,2.8.5v-2.6c.6-.3.9-.9.9-1.6v-2.8c0-1-.9-1.8-1.9-1.9h-15.1c-1,0-1.8.9-1.9,1.9v2.8c0,.7.4,1.3.9,1.6v10.6c0,1,.9,1.8,1.9,1.9h5.5c-.4-1-.7-2-.7-3.1M416.1,30.9h15.1v2.8h-15.1v-2.8Z" fill="#fff"/>
            </g>
          </g>
          <g>
            <path d="M403.8,100.3v-10h4.9c.6,0,1,.3,1.4.6.4.3.7.7.8,1.1.2.4.3.9.3,1.5s-.2,1.5-.6,2.1-1,.9-1.8,1.1h-3.4v3.6h-1.6,0ZM405.3,95.4h3.1c.3,0,.5-.2.7-.4.2-.2.3-.4.4-.7s.1-.5.1-.7,0-.5-.1-.7c0-.2-.2-.5-.4-.6-.2-.2-.4-.3-.7-.4h-3.1v3.7-.2ZM409.7,100.3l-2-4.1,1.5-.4,2.2,4.5h-1.7Z"/>
            <path d="M416.2,100.6c-.7,0-1.4-.2-2-.5s-1-.8-1.3-1.4-.5-1.3-.5-2,.2-1.5.5-2.1.7-1.1,1.3-1.4c.6-.3,1.2-.5,1.9-.5s1.4.2,2,.5c.5.4.9.9,1.2,1.5.3.7.4,1.4.3,2.3h-1.5v-.5c0-.9-.2-1.5-.5-1.9s-.8-.6-1.5-.6-1.3.2-1.6.7c-.4.5-.5,1.1-.5,2s.2,1.5.5,1.9c.4.5.9.7,1.6.7s.8,0,1.2-.3c.3-.2.6-.5.8-.9l1.4.5c-.3.7-.7,1.2-1.3,1.6-.6.4-1.3.6-2,.6,0,0,0-.2,0-.2ZM413.6,97v-1.1h5.4v1.1h-5.4Z"/>
            <path d="M423.2,100.6c-.6,0-1,0-1.4-.3-.4-.2-.7-.5-.9-.8-.2-.3-.3-.7-.3-1.1s0-.7.2-1,.3-.5.6-.7c.3-.2.6-.4,1-.5.3,0,.7-.2,1.1-.3.4,0,.9,0,1.3-.2.5,0,.9,0,1.4-.2l-.5.3c0-.6-.1-1-.4-1.3s-.7-.4-1.3-.4-.8,0-1.1.3-.6.5-.7.9l-1.4-.4c.2-.6.5-1.2,1.1-1.5s1.2-.6,2.1-.6,1.3,0,1.8.3.9.6,1.1,1.1c.1.2.2.5.2.8v5.5h-1.3v-1.7l.3.2c-.3.6-.7,1-1.2,1.3s-1.1.4-1.8.4h0ZM423.4,99.4c.4,0,.8,0,1.1-.2.3,0,.5-.3.7-.6.2-.2.3-.5.4-.7,0-.2.1-.5.1-.8v-.7l.5.2c-.5,0-.9,0-1.3.2s-.7,0-1,.2c-.3,0-.6,0-.8.2-.2,0-.4.2-.5.2-.2,0-.3.2-.4.4,0,0-.1.3-.1.5s0,.4.1.5c0,.2.2.3.4.4h.8v.2Z"/>
            <path d="M432,100.6c-.7,0-1.3-.2-1.8-.5s-.9-.8-1.2-1.4c-.3-.6-.4-1.3-.4-2s.1-1.4.4-2,.7-1.1,1.2-1.4,1.1-.5,1.8-.5,1.3.2,1.8.5.9.8,1.1,1.4.4,1.3.4,2-.1,1.4-.4,2-.6,1.1-1.1,1.4-1.1.5-1.8.5ZM432.2,99.2c.5,0,.9,0,1.2-.3.3-.2.5-.5.7-.9.1-.4.2-.9.2-1.4s0-1-.2-1.4-.4-.7-.7-.9c-.3-.2-.7-.3-1.1-.3s-.9,0-1.2.4c-.3.2-.5.6-.7,1s-.2.8-.2,1.3,0,.9.2,1.3.4.7.7,1c.3.2.7.4,1.2.4v-.2h-.1ZM434.2,100.3v-5.4h-.2v-4.6h1.5v10h-1.3Z"/>
            <path d="M438.5,103.7l1.5-4v1.2l-3.3-8.1h1.5l2.5,6.2h-.4l2.3-6.2h1.5l-4.2,10.9h-1.4Z"/>
          </g>
          <g>
            <path d="M592.8,91.8v-8.6h4c.5,0,.9.2,1.2.5.3.2.6.6.7.9.2.4.2.8.2,1.3s0,.9-.2,1.3-.4.7-.7.9c-.3.2-.7.4-1.2.5h-2.9v3.2s-1.1,0-1.1,0ZM593.8,87.6h2.9c.3,0,.5-.2.7-.4s.3-.4.4-.6,0-.5,0-.7v-.7c0-.2-.2-.4-.4-.6s-.4-.3-.7-.4h-2.9s0,3.4,0,3.4Z" fill="#c9c9c9"/>
            <path d="M600.3,84.2v-1.1h1.1v1.1h-1.1ZM600.3,91.8v-6.5h1.1v6.5h-1.1Z" fill="#c9c9c9"/>
            <path d="M605.9,92c-.7,0-1.2,0-1.7-.4s-.8-.7-1-1.2-.4-1.1-.4-1.8,0-1.3.4-1.8c.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5.8.8,1,1.4l-1.1.3c0-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8,0-1.1.3-.5.5-.6.9-.2.8-.2,1.2c0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5h-.3,0Z" fill="#c9c9c9"/>
            <path d="M609.9,91.8v-8.6h1.1v5.3l2.7-3.1h1.4l-2.9,3.2,3.2,3.2h-1.5l-2.9-3.1v3.1h-1.1Z" fill="#c9c9c9"/>
            <path d="M618.7,92c-.6,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8,0-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8s-.8-.6-1.4-.6-1.2.2-1.5.6-.5,1-.5,1.8.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3s-1.1.5-1.7.5h.1ZM616.3,88.9v-.9h4.8v.9h-4.8Z" fill="#c9c9c9"/>
            <path d="M625.5,92c-.6,0-1.1,0-1.6-.4-.4-.3-.8-.7-1-1.2s-.4-1.1-.4-1.7,0-1.2.4-1.7c.2-.5.6-.9,1-1.2.4-.3.9-.4,1.5-.4s1.1,0,1.5.4.7.7.9,1.2.3,1.1.3,1.7,0,1.2-.3,1.7-.5.9-.9,1.2c-.4.3-.9.5-1.5.5h0ZM625.6,91c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.3,0-.9-.2-1.3-.3-.7-.6-.9c-.3-.2-.6-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2,0,.9.2,1.2.4.7.6.9.6.3,1.1.3v.2h0ZM627.5,91.8v-4.8h0v-3.9h1.1v8.6h-1.1Z" fill="#c9c9c9"/>
            <path d="M630.1,88.5v-.9h3.6v.9h-3.6Z" fill="#c9c9c9"/>
            <path d="M638.6,92c-.7,0-1.3,0-1.8-.4s-.9-.7-1.2-1.2-.4-1.1-.4-1.8v-5.5h1.1v5.4c0,.4,0,.8.2,1.1,0,.3.3.6.5.8s.5.3.8.4h1.6c.3,0,.5-.2.8-.4.2-.2.4-.4.5-.8,0-.3.2-.7.2-1.1v-5.4h1.1v5.5c0,.7,0,1.3-.4,1.8-.3.5-.7.9-1.2,1.2-.5.3-1.1.4-1.8.4h0Z" fill="#c9c9c9"/>
            <path d="M643.7,94.7v-9.4h1v4.8h0v4.6h-1.1,0ZM646.7,92c-.6,0-1.1-.2-1.5-.5s-.7-.7-.9-1.2-.3-1.1-.3-1.7,0-1.2.3-1.7.5-.9.9-1.2c.4-.3.9-.4,1.5-.4s1.1,0,1.6.4c.4.3.8.7,1,1.2s.3,1.1.3,1.7,0,1.2-.3,1.7-.6.9-1,1.2c-.4.3-1,.4-1.6.4h0ZM646.6,91c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.2,0-.9-.2-1.2c0-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8,0-1,.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.3,0,.9.2,1.3c0,.4.3.7.6.9.3.2.6.3,1.1.3v-.2h0Z" fill="#c9c9c9"/>
          </g>
        </g>
      </svg>
    );
  const statusReadyMobile = //Done
    (
      <svg id="statusReadyMobile_1_" data-name="statusReadyMobile (1)" xmlns="http://www.w3.org/2000/svg" width="480" height="103.6" viewBox="0 0 480 103.6">
  <g id="Group_4699" opacity="0.5">
    <line id="Line_64" x2="36.9" transform="translate(60.3 39.1)" fill="none"/>
    <line id="Line_59" x2="73.1" transform="translate(69.6 39.1)" fill="none" stroke="#2ec278" stroke-width="1"/>
    <line id="Line_63" x2="98.7" transform="translate(171.9 39.1)" fill="none" stroke="#ff9557" stroke-width="1"/>
    <circle id="Ellipse_86" cx="38.5" cy="38.5" r="38.5" opacity="0"/>
    <circle id="Ellipse_82" cx="30.5" cy="30.5" r="30.5" transform="translate(8 8)" fill="#2ec278"/>
    <g id="Group_552">
      <circle id="Ellipse_88" cx="38.5" cy="38.5" r="38.5" transform="translate(136)" opacity="0"/>
      <path id="Path_638" d="M174.1,8.2a30.9,30.9,0,1,1-30.9,30.9h0A30.856,30.856,0,0,1,174.1,8.2Z" fill="#ffc72e"/>
      <g id="inventory_2_black_24dp">
        <g id="Group_4910">
          <rect id="Rectangle_1309" width="26" height="26" transform="translate(161 26)" fill="none"/>
        </g>
        <g id="Group_4911">
          <path id="Path_3926" d="M182.3,28.6H165.7a2.182,2.182,0,0,0-2.1,2.1v3.1a2.234,2.234,0,0,0,1,1.8V47.4a2.258,2.258,0,0,0,2.1,2.1h14.6a2.258,2.258,0,0,0,2.1-2.1V35.6a2.234,2.234,0,0,0,1-1.8V30.7a2.182,2.182,0,0,0-2.1-2.1Zm-5.2,12.5h-6.2V39h6.2Zm5.2-7.3H165.7V30.7h16.6Z" fill="#fff"/>
        </g>
      </g>
    </g>
    <g id="verified-24px">
      <g id="Group_564">
        <rect id="Rectangle_235" width="28" height="28" transform="translate(25 26)" fill="none"/>
      </g>
      <g id="Group_565">
        <path id="Path_623" d="M51.5,39.7l-2.8-3.3.4-4.3-4.2-1-2.2-3.7-4,1.7-4-1.7-2.2,3.7-4.2.9.4,4.3-2.9,3.3,2.8,3.3-.4,4.3,4.2,1,2.2,3.7,4-1.7,4,1.7,2.2-3.7,4.2-1-.4-4.3,2.9-3.3h0Zm-15,5.5-4.4-4.4,1.7-1.7,2.7,2.7,6.8-6.9L45,36.6l-8.6,8.6h0Z" fill="#fff"/>
      </g>
    </g>
    <g id="Group_6700" data-name="Group 6700">
      <path id="Path_8587" data-name="Path 8587" d="M11.8,91.7l2.9-8.6h1.5l2.9,8.6H18l-2.7-8h.3l-2.7,8Zm1.4-2v-1h4.5v1H13.2Z" fill="#bfbfbf"/>
      <path id="Path_8588" data-name="Path 8588" d="M22.9,91.9a2.414,2.414,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,4.776,4.776,0,0,1-.4-1.8,3.425,3.425,0,0,1,.4-1.8,2.545,2.545,0,0,1,1.1-1.2,3.064,3.064,0,0,1,1.7-.4,3.453,3.453,0,0,1,1.8.5,2.434,2.434,0,0,1,1,1.4l-1.1.3a1.6,1.6,0,0,0-.7-.9,1.929,1.929,0,0,0-1-.3,1.548,1.548,0,0,0-1.1.3,1.8,1.8,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.86,2.86,0,0,0,.5,1.7,1.806,1.806,0,0,0,1.5.7c.7,0,.8,0,1.1-.3a2.1,2.1,0,0,0,.6-.8l1.1.3a2.981,2.981,0,0,1-1,1.4,3.1,3.1,0,0,1-1.7.5h-.2Z" fill="#bfbfbf"/>
      <path id="Path_8589" data-name="Path 8589" d="M29.4,91.9a2.414,2.414,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,4.776,4.776,0,0,1-.4-1.8,3.425,3.425,0,0,1,.4-1.8,2.545,2.545,0,0,1,1.1-1.2,3.064,3.064,0,0,1,1.7-.4,3.453,3.453,0,0,1,1.8.5,2.434,2.434,0,0,1,1,1.4l-1.1.3a1.6,1.6,0,0,0-.7-.9,1.929,1.929,0,0,0-1-.3,1.548,1.548,0,0,0-1.1.3,1.8,1.8,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.86,2.86,0,0,0,.5,1.7,1.806,1.806,0,0,0,1.5.7c.7,0,.8,0,1.1-.3a2.1,2.1,0,0,0,.6-.8l1.1.3a2.981,2.981,0,0,1-1,1.4,3.1,3.1,0,0,1-1.7.5h-.2Z" fill="#bfbfbf"/>
      <path id="Path_8590" data-name="Path 8590" d="M36,91.9a2.543,2.543,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,3.425,3.425,0,0,1-.4-1.8,2.689,2.689,0,0,1,.4-1.8,3.179,3.179,0,0,1,1.1-1.2,2.934,2.934,0,0,1,1.6-.4,3.371,3.371,0,0,1,1.7.5,2.209,2.209,0,0,1,1,1.3,6.013,6.013,0,0,1,.3,2H37.8v-.4a2.969,2.969,0,0,0-.5-1.8,1.678,1.678,0,0,0-1.4-.6,1.887,1.887,0,0,0-1.5.6,3.49,3.49,0,0,0,0,3.6,1.755,1.755,0,0,0,1.5.6c.7,0,.8,0,1.1-.3a1.779,1.779,0,0,0,.7-.8l1,.4a2.85,2.85,0,0,1-1.1,1.3,3.371,3.371,0,0,1-1.7.5H36Zm-2.4-3.1v-.9h4.8v.9Z" fill="#bfbfbf"/>
      <path id="Path_8591" data-name="Path 8591" d="M40.1,94.6V85.2h1V90h0v4.6H40Zm3-2.7a2.456,2.456,0,0,1-1.5-.5,2.73,2.73,0,0,1-.9-1.2,4.671,4.671,0,0,1-.3-1.7,3.233,3.233,0,0,1,.3-1.7,3.778,3.778,0,0,1,.9-1.2,2.41,2.41,0,0,1,1.5-.4,2.276,2.276,0,0,1,1.6.4,2.94,2.94,0,0,1,1,1.2,4.671,4.671,0,0,1,.3,1.7,3.233,3.233,0,0,1-.3,1.7,4,4,0,0,1-1,1.2,2.726,2.726,0,0,1-1.6.4h0Zm-.1-1a1.431,1.431,0,0,0,1.1-.3,1.8,1.8,0,0,0,.6-.9,5.019,5.019,0,0,0,.2-1.2,2.345,2.345,0,0,0-.2-1.2c0-.4-.4-.7-.6-.9a1.8,1.8,0,0,0-1.1-.3c-.5,0-.8,0-1,.3a1.456,1.456,0,0,0-.6.9,5.067,5.067,0,0,0-.2,1.3,2.745,2.745,0,0,0,.2,1.3,1.149,1.149,0,0,0,.6.9,1.8,1.8,0,0,0,1.1.3v-.2Z" fill="#bfbfbf"/>
      <path id="Path_8592" data-name="Path 8592" d="M46.6,86.1v-.9h4.3v.9ZM51,91.7H49.9a1.645,1.645,0,0,1-1-.2,2.336,2.336,0,0,1-.7-.7c0-.3-.2-.5-.2-.8V83.3h1.1v7c0,.3.4.4.7.5h1.1v.9Z" fill="#bfbfbf"/>
      <path id="Path_8593" data-name="Path 8593" d="M54.8,91.9a2.543,2.543,0,0,1-1.7-.4A4.146,4.146,0,0,1,52,90.3a3.425,3.425,0,0,1-.4-1.8,2.689,2.689,0,0,1,.4-1.8,3.179,3.179,0,0,1,1.1-1.2,2.934,2.934,0,0,1,1.6-.4,3.371,3.371,0,0,1,1.7.5,2.209,2.209,0,0,1,1,1.3,6.013,6.013,0,0,1,.3,2H56.6v-.4a2.969,2.969,0,0,0-.5-1.8,1.678,1.678,0,0,0-1.4-.6,1.887,1.887,0,0,0-1.5.6,3.49,3.49,0,0,0,0,3.6,1.755,1.755,0,0,0,1.5.6c.7,0,.8,0,1.1-.3a1.779,1.779,0,0,0,.7-.8l1,.4a2.85,2.85,0,0,1-1.1,1.3,3.371,3.371,0,0,1-1.7.5h.1Zm-2.4-3.1v-.9h4.8v.9Z" fill="#bfbfbf"/>
      <path id="Path_8594" data-name="Path 8594" d="M61.6,91.9a2.276,2.276,0,0,1-1.6-.4,2.94,2.94,0,0,1-1-1.2,4.711,4.711,0,0,1-.4-1.7,2.543,2.543,0,0,1,.4-1.7,2.94,2.94,0,0,1,1-1.2,2.41,2.41,0,0,1,1.5-.4,1.913,1.913,0,0,1,1.5.4,3.449,3.449,0,0,1,.9,1.2,4.671,4.671,0,0,1,.3,1.7,3.233,3.233,0,0,1-.3,1.7,3.778,3.778,0,0,1-.9,1.2,2.456,2.456,0,0,1-1.5.5h0Zm.2-1a1.431,1.431,0,0,0,1.1-.3,1.8,1.8,0,0,0,.6-.9,5.067,5.067,0,0,0,.2-1.3,2.745,2.745,0,0,0-.2-1.3,2.034,2.034,0,0,0-.6-.9,1.689,1.689,0,0,0-1-.3,1.431,1.431,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.345,2.345,0,0,0,.2,1.2c0,.4.4.7.6.9a1.609,1.609,0,0,0,1.1.3v.2Zm1.9.8V86.9h0V83h1.1v8.6h-1Z" fill="#bfbfbf"/>
    </g>
    <g id="Group_6701" data-name="Group 6701">
      <path id="Path_8595" data-name="Path 8595" d="M153.2,91.7V83.1h4a1.656,1.656,0,0,1,1.2.5,2.016,2.016,0,0,1,.7.9,4.439,4.439,0,0,1,.2,1.3,2.745,2.745,0,0,1-.2,1.3,2.178,2.178,0,0,1-.7.9,3.337,3.337,0,0,1-1.2.5h-2.9v3.2h-1.1Zm1.1-4.2h2.9a1.012,1.012,0,0,0,.7-.4,2.092,2.092,0,0,0,.4-.6,2.5,2.5,0,0,0,0-1.4c0-.2-.2-.4-.4-.6a1.612,1.612,0,0,0-.7-.4h-2.9v3.4Z" fill="#bfbfbf"/>
      <path id="Path_8596" data-name="Path 8596" d="M162.2,91.9a1.558,1.558,0,0,1-1.2-.3c-.3-.3-.6-.4-.7-.7a2.959,2.959,0,0,1-.2-1,1.344,1.344,0,0,1,.2-.9c.2-.3.3-.5.5-.6a2.01,2.01,0,0,1,.8-.4c.3,0,.7-.2,1-.2a2.744,2.744,0,0,0,1.2-.2,1.978,1.978,0,0,0,1.1-.2l-.4.2a2.269,2.269,0,0,0-.3-1.3,1.461,1.461,0,0,0-1.2-.4c-.5,0-.7,0-1,.3a1.456,1.456,0,0,0-.6.9l-1-.3a2.54,2.54,0,0,1,.9-1.3,2.969,2.969,0,0,1,1.8-.5c.8,0,1.1,0,1.5.3a2.189,2.189,0,0,1,.9,1,2.544,2.544,0,0,0,.2.7v4.7h-.9V90.1h.2a1.994,1.994,0,0,1-1,1.3,2.726,2.726,0,0,1-1.6.4h-.2Zm.2-.9a1.645,1.645,0,0,0,1-.2,4.233,4.233,0,0,0,.7-.5,1.08,1.08,0,0,0,.3-.7V88.3l.4.2h-2a1.077,1.077,0,0,0-.8.2.551.551,0,0,0-.5.2c-.2,0-.3.2-.4.3a2.034,2.034,0,0,0-.1.5.9.9,0,0,0,.1.5.781.781,0,0,0,.4.4,1.445,1.445,0,0,0,.7.2h.2Z" fill="#bfbfbf"/>
      <path id="Path_8597" data-name="Path 8597" d="M169.9,91.9a2.414,2.414,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,4.776,4.776,0,0,1-.4-1.8,3.425,3.425,0,0,1,.4-1.8,2.544,2.544,0,0,1,1.1-1.2,3.064,3.064,0,0,1,1.7-.4,3.453,3.453,0,0,1,1.8.5,2.434,2.434,0,0,1,1,1.4l-1.1.3a1.6,1.6,0,0,0-.7-.9,1.929,1.929,0,0,0-1-.3,1.548,1.548,0,0,0-1.1.3,1.8,1.8,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.86,2.86,0,0,0,.5,1.7,1.806,1.806,0,0,0,1.5.7c.7,0,.8,0,1.1-.3a2.1,2.1,0,0,0,.6-.8l1.1.3a2.981,2.981,0,0,1-1,1.4,3.1,3.1,0,0,1-1.7.5h-.2Z" fill="#bfbfbf"/>
      <path id="Path_8598" data-name="Path 8598" d="M173.9,91.7V83.1H175v5.3l2.7-3.1h1.4l-2.9,3.2,3.2,3.2h-1.5L175,88.6v3.1Z" fill="#bfbfbf"/>
      <path id="Path_8599" data-name="Path 8599" d="M180.1,84.1V83h1.1v1.1Zm0,7.6V85.2h1.1v6.5Z" fill="#bfbfbf"/>
      <path id="Path_8600" data-name="Path 8600" d="M183,91.7V85.2h1v1.7h.1v4.7H183Zm4.6,0V87.6c0-.3-.2-.5-.3-.8a1.205,1.205,0,0,0-.5-.5,2,2,0,0,0-.9-.2h-.7c-.2,0-.4.2-.6.4a1.612,1.612,0,0,0-.4.7c0,.3-.1.6-.1,1l-.7-.2a3.808,3.808,0,0,1,.3-1.6,1.651,1.651,0,0,1,1-1,3.708,3.708,0,0,1,1.4-.4,1.815,1.815,0,0,1,1.1.2,4.233,4.233,0,0,1,.7.5,3.039,3.039,0,0,1,.5.7,2.353,2.353,0,0,1,.2.8v4.4h-1Z" fill="#bfbfbf"/>
      <path id="Path_8601" data-name="Path 8601" d="M192.8,91.9a2.276,2.276,0,0,1-1.6-.4,2.94,2.94,0,0,1-1-1.2,4.711,4.711,0,0,1-.4-1.7,3.287,3.287,0,0,1,.4-1.7,2.94,2.94,0,0,1,1-1.2,2.41,2.41,0,0,1,1.5-.4,1.913,1.913,0,0,1,1.5.4,3.449,3.449,0,0,1,.9,1.2,4.967,4.967,0,0,1,0,3.4,2.73,2.73,0,0,1-.9,1.2,2.456,2.456,0,0,1-1.5.5h.1Zm.1,2.9a1.8,1.8,0,0,1-1-.2c-.3,0-.6-.3-.9-.5a2.7,2.7,0,0,1-.7-.8l1-.5a2.336,2.336,0,0,0,.7.7c.3,0,.6.2.9.2s.8,0,1-.2c.3,0,.5-.4.6-.7a3.6,3.6,0,0,0,.2-1.1V89.9h.1V85.2h.9v7.5a1.433,1.433,0,0,1-.5,1.1,3.592,3.592,0,0,1-1,.7c-.4,0-.8.2-1.4.2h0Zm0-3.9a1.431,1.431,0,0,0,1.1-.3,1.8,1.8,0,0,0,.6-.9,5.066,5.066,0,0,0,.2-1.3,2.745,2.745,0,0,0-.2-1.3,2.034,2.034,0,0,0-.6-.9,1.689,1.689,0,0,0-1-.3,1.431,1.431,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.345,2.345,0,0,0,.2,1.2,2.034,2.034,0,0,0,.6.9,1.609,1.609,0,0,0,1.1.3v.2Z" fill="#bfbfbf"/>
    </g>
  </g>
  <line id="Line_65" x2="59.9" transform="translate(317.3 39.1)" fill="none" stroke="#8147ed" stroke-width="1"/>
  <line id="Line_60" x2="61" transform="translate(377.2 39.1)" fill="none" stroke="#d4d4d4" stroke-width="1"/>
  <g id="Group_550">
    <circle id="Ellipse_89" cx="38.5" cy="38.5" r="38.5" transform="translate(265)" fill="#e4d5ff"/>
    <circle id="Ellipse_84" cx="30.5" cy="30.5" r="30.5" transform="translate(273 8)" fill="#8147ed"/>
  </g>
  <g id="Group_551">
    <ellipse id="Ellipse_90" cx="39" cy="38.5" rx="39" ry="38.5" transform="translate(402)" opacity="0"/>
    <circle id="Ellipse_85" cx="30.5" cy="30.5" r="30.5" transform="translate(411 8)" fill="#f1f1f1"/>
  </g>
  <circle id="Ellipse_87" cx="5" cy="5" r="5" transform="translate(372 34)" fill="#8147ed"/>
  <g id="thumbs_up">
    <g id="Group_676">
      <g id="Group_675">
        <path id="Path_672" d="M452.3,41.4a1.538,1.538,0,0,0-1.5-1.5,1.5,1.5,0,0,0,0-3h-6.2a12.111,12.111,0,0,0,.9-5.3,2.263,2.263,0,0,0-2.3-2.3h-.5a.858.858,0,0,0-.7.6c-.7,2.9-1.9,6.2-4.9,6.9V47.7l2.6.9a6.722,6.722,0,0,0,2.4.4h7.1a1.5,1.5,0,0,0,0-3h1.5a1.5,1.5,0,0,0,0-3h0a1.538,1.538,0,0,0,1.5-1.5h.1Z" fill="#d9d9d9"/>
      </g>
    </g>
    <g id="Group_678">
      <g id="Group_677">
        <path id="Path_673" d="M433.4,35.4h-3a.789.789,0,0,0-.8.8h0V49.8a.789.789,0,0,0,.8.8h3a2.308,2.308,0,0,0,2.3-2.2h0V37.8a2.308,2.308,0,0,0-2.2-2.3h0Zm0,12.1a.789.789,0,0,1-.8-.8h0a.8.8,0,1,1,1.6,0h0a.789.789,0,0,1-.8.8Z" fill="#d9d9d9"/>
      </g>
    </g>
    <g id="Group_680">
      <g id="Group_679">
        <path id="Path_674" d="M440,28l-1.1-1.1a.778.778,0,1,0-1.1,1.1h0l1.1,1.1A.778.778,0,0,0,440,28Z" fill="#d9d9d9"/>
      </g>
    </g>
    <g id="Group_682">
      <g id="Group_681">
        <path id="Path_675" d="M448.6,27a.785.785,0,0,0-1.1,0h0l-1.1,1.1a.778.778,0,0,0,1.1,1.1l1.1-1.1A.785.785,0,0,0,448.6,27Z" fill="#d9d9d9"/>
      </g>
    </g>
    <g id="Group_684">
      <g id="Group_683">
        <path id="Path_676" d="M443.3,24.7a.773.773,0,0,0-.8.7h0V27a.773.773,0,0,0,.8.7.684.684,0,0,0,.7-.7h0V25.4a.773.773,0,0,0-.7-.8h0Z" fill="#d9d9d9"/>
      </g>
    </g>
  </g>
  <g id="Group_5032">
    <g id="Group_8222">
      <g id="Group_5030">
        <path id="Path_4200" d="M309.3,39.2a5.7,5.7,0,1,0,5.7,5.7h0a5.763,5.763,0,0,0-5.7-5.7h0m-1.1,8.5-2.3-2.3.8-.8,1.5,1.5,3.7-3.7.8.8Z" fill="#fff"/>
        <path id="Path_4201" d="M301.9,44.8a7.383,7.383,0,0,1,7.4-7.4h0a8.675,8.675,0,0,1,2.8.5V35.3a1.7,1.7,0,0,0,.9-1.6V30.9a2.043,2.043,0,0,0-1.9-1.9H296a2.043,2.043,0,0,0-1.9,1.9v2.8a1.7,1.7,0,0,0,.9,1.6V45.9a2.043,2.043,0,0,0,1.9,1.9h5.5a8.245,8.245,0,0,1-.7-3.1m-5.6-13.8h15.1v2.8H296.1V30.9h0Z" fill="#fff"/>
      </g>
    </g>
  </g>
  <g id="Group_6702" data-name="Group 6702">
    <path id="Path_8602" data-name="Path 8602" d="M283.7,100.2V90.1h4.9a2.246,2.246,0,0,1,1.4.6,2.1,2.1,0,0,1,.8,1.1,5.666,5.666,0,0,1,.3,1.5,4.2,4.2,0,0,1-.6,2.1,2.8,2.8,0,0,1-1.8,1.1h-3.5v3.6h-1.5Zm1.5-5h3.2a1.012,1.012,0,0,0,.7-.4,1.612,1.612,0,0,0,.4-.7c0-.2.1-.5.1-.7a1.7,1.7,0,0,0-.1-.7,2.507,2.507,0,0,0-.4-.7,1.612,1.612,0,0,0-.7-.4h-3.2v3.7h0Zm4.4,5-2-4.1,1.5-.4,2.2,4.5Z"/>
    <path id="Path_8603" data-name="Path 8603" d="M296.2,100.4a4.228,4.228,0,0,1-2-.5,2.949,2.949,0,0,1-1.3-1.4,4.66,4.66,0,0,1,0-4.2,2.949,2.949,0,0,1,1.3-1.4,4.136,4.136,0,0,1,1.9-.5,4.552,4.552,0,0,1,2,.5,4.428,4.428,0,0,1,1.2,1.5,4.175,4.175,0,0,1,.3,2.3h-1.5v-.5a3.09,3.09,0,0,0-.5-1.9,1.755,1.755,0,0,0-1.5-.6,2.06,2.06,0,0,0-1.7.7,2.986,2.986,0,0,0-.6,2,2.546,2.546,0,0,0,.6,1.9,1.865,1.865,0,0,0,1.6.7c.7,0,.9,0,1.2-.3a2.361,2.361,0,0,0,.8-.9l1.4.5a3.435,3.435,0,0,1-1.3,1.6,3.606,3.606,0,0,1-2,.6h.1Zm-2.7-3.5V95.8h5.4v1.1Z"/>
    <path id="Path_8604" data-name="Path 8604" d="M303.2,100.4a2.092,2.092,0,0,1-1.4-.3,3.631,3.631,0,0,1-.9-.8,2.016,2.016,0,0,1-.3-1.1,1.644,1.644,0,0,1,.2-1,1.344,1.344,0,0,1,.6-.7,3.128,3.128,0,0,1,1-.5,6.149,6.149,0,0,0,1.1-.3,2.953,2.953,0,0,0,1.3-.2,3.4,3.4,0,0,0,1.4-.2l-.5.3a1.453,1.453,0,0,0-1.7-1.7c-.6,0-.8,0-1.1.3a1.6,1.6,0,0,0-.7.9l-1.4-.4a2.4,2.4,0,0,1,1.1-1.5,3.281,3.281,0,0,1,2.1-.6,3.078,3.078,0,0,1,1.8.3,1.981,1.981,0,0,1,1.1,1.1,1.884,1.884,0,0,1,.2.8v5.6h-1.3V98.7l.3.2a3.039,3.039,0,0,1-1.2,1.3,3.424,3.424,0,0,1-1.8.4v-.2Zm.2-1.2a1.978,1.978,0,0,0,1.1-.2c.3,0,.5-.3.7-.6a2.506,2.506,0,0,0,.4-.7c0-.2.1-.5.1-.8v-.7l.5.2a2.745,2.745,0,0,0-1.3.2,1.645,1.645,0,0,0-1,.2c-.3.2-.6,0-.8.2a.85.85,0,0,0-.5.3c-.2,0-.3.2-.4.4a.9.9,0,0,0-.1.5.749.749,0,0,0,.1.5c0,.2.2.3.4.4h.8Z"/>
    <path id="Path_8605" data-name="Path 8605" d="M312,100.4a3.453,3.453,0,0,1-1.8-.5,3.326,3.326,0,0,1-1.2-1.4,5.2,5.2,0,0,1,0-4,3.325,3.325,0,0,1,1.2-1.4,3.49,3.49,0,0,1,3.6,0,2.586,2.586,0,0,1,1.1,1.4,6.465,6.465,0,0,1,.4,2,4.477,4.477,0,0,1-.4,2,3.142,3.142,0,0,1-1.1,1.4A2.73,2.73,0,0,1,312,100.4Zm.2-1.3a1.558,1.558,0,0,0,1.2-.3,2.178,2.178,0,0,0,.7-.9,5.9,5.9,0,0,0,.2-1.4,3.178,3.178,0,0,0-.2-1.4,2.178,2.178,0,0,0-.7-.9,2.016,2.016,0,0,0-1.1-.3,1.374,1.374,0,0,0-1.2.4,6.07,6.07,0,0,0-.7,1,2.745,2.745,0,0,0-.2,1.3,3.178,3.178,0,0,0,.2,1.4,3.591,3.591,0,0,0,.7,1,2.144,2.144,0,0,0,1.2.4v-.3Zm2.1,1.1V94.8h-.2V90.2h1.5v10.1h-1.3Z"/>
    <path id="Path_8606" data-name="Path 8606" d="M318.6,103.6l1.5-4v1.2l-3.3-8.1h1.5l2.5,6.3h-.4l2.4-6.3h1.5l-4.2,10.9Z"/>
  </g>
  <g id="Group_6703" data-name="Group 6703">
    <path id="Path_8607" data-name="Path 8607" d="M412.8,91.7V83.1h4a1.656,1.656,0,0,1,1.2.5,2.016,2.016,0,0,1,.7.9,4.438,4.438,0,0,1,.2,1.3,2.745,2.745,0,0,1-.2,1.3,2.178,2.178,0,0,1-.7.9,3.337,3.337,0,0,1-1.2.5h-2.9v3.2h-1.1Zm1-4.2h2.9a1.012,1.012,0,0,0,.7-.4,2.092,2.092,0,0,0,.4-.6,2.5,2.5,0,0,0,0-1.4c0-.2-.2-.4-.4-.6a1.612,1.612,0,0,0-.7-.4h-2.9v3.4Z" fill="#bfbfbf"/>
    <path id="Path_8608" data-name="Path 8608" d="M420.3,84.1V83h1.1v1.1Zm0,7.6V85.2h1.1v6.5Z" fill="#bfbfbf"/>
    <path id="Path_8609" data-name="Path 8609" d="M425.9,91.9a2.414,2.414,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,4.776,4.776,0,0,1-.4-1.8,3.425,3.425,0,0,1,.4-1.8,2.545,2.545,0,0,1,1.1-1.2,3.064,3.064,0,0,1,1.7-.4,3.453,3.453,0,0,1,1.8.5,2.434,2.434,0,0,1,1,1.4l-1.1.3a1.6,1.6,0,0,0-.7-.9,1.929,1.929,0,0,0-1-.3,1.548,1.548,0,0,0-1.1.3,1.8,1.8,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.86,2.86,0,0,0,.5,1.7,1.806,1.806,0,0,0,1.5.7c.7,0,.8,0,1.1-.3a2.1,2.1,0,0,0,.6-.8l1.1.3a2.981,2.981,0,0,1-1,1.4,3.1,3.1,0,0,1-1.7.5h-.2Z" fill="#bfbfbf"/>
    <path id="Path_8610" data-name="Path 8610" d="M429.9,91.7V83.1H431v5.3l2.7-3.1h1.4l-2.9,3.2,3.2,3.2h-1.5L431,88.6v3.1h-1.1Z" fill="#bfbfbf"/>
    <path id="Path_8611" data-name="Path 8611" d="M438.7,91.9a2.543,2.543,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,4.25,4.25,0,0,1,0-3.6,3.179,3.179,0,0,1,1.1-1.2,2.934,2.934,0,0,1,1.6-.4,3.371,3.371,0,0,1,1.7.5,2.209,2.209,0,0,1,1,1.3,6.013,6.013,0,0,1,.3,2h-1.1v-.4a2.969,2.969,0,0,0-.5-1.8,1.678,1.678,0,0,0-1.4-.6,1.887,1.887,0,0,0-1.5.6,3.49,3.49,0,0,0,0,3.6,1.755,1.755,0,0,0,1.5.6c.7,0,.8,0,1.1-.3a1.779,1.779,0,0,0,.7-.8l1,.4a2.85,2.85,0,0,1-1.1,1.3,3.371,3.371,0,0,1-1.7.5h.1Zm-2.4-3.1v-.9h4.8v.9Z" fill="#bfbfbf"/>
    <path id="Path_8612" data-name="Path 8612" d="M445.5,91.9a2.276,2.276,0,0,1-1.6-.4,2.94,2.94,0,0,1-1-1.2,4.711,4.711,0,0,1-.4-1.7,3.287,3.287,0,0,1,.4-1.7,2.94,2.94,0,0,1,1-1.2,2.41,2.41,0,0,1,1.5-.4,1.913,1.913,0,0,1,1.5.4,3.449,3.449,0,0,1,.9,1.2,4.967,4.967,0,0,1,0,3.4,2.73,2.73,0,0,1-.9,1.2,2.456,2.456,0,0,1-1.5.5h.1Zm.1-1a1.431,1.431,0,0,0,1.1-.3,1.8,1.8,0,0,0,.6-.9,5.066,5.066,0,0,0,.2-1.3,2.745,2.745,0,0,0-.2-1.3,2.034,2.034,0,0,0-.6-.9,1.689,1.689,0,0,0-1-.3,1.431,1.431,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.345,2.345,0,0,0,.2,1.2,2.034,2.034,0,0,0,.6.9,1.609,1.609,0,0,0,1.1.3v.2Zm1.9.8V86.9h-.1V83h1.1v8.6h-1Z" fill="#bfbfbf"/>
    <path id="Path_8613" data-name="Path 8613" d="M450,88.4v-.9h3.6v.9Z" fill="#bfbfbf"/>
    <path id="Path_8614" data-name="Path 8614" d="M458.6,91.9a2.689,2.689,0,0,1-1.8-.4,4.561,4.561,0,0,1-1.2-1.2,3.425,3.425,0,0,1-.4-1.8V83h1.1v5.4a1.978,1.978,0,0,0,.2,1.1,5.583,5.583,0,0,0,.5.8,2.01,2.01,0,0,0,.8.4h1.6c.3,0,.5-.2.8-.4a1.578,1.578,0,0,0,.5-.8,3.6,3.6,0,0,0,.2-1.1V83H462v5.5a3.166,3.166,0,0,1-3.4,3.4Z" fill="#bfbfbf"/>
    <path id="Path_8615" data-name="Path 8615" d="M463.7,94.6V85.2h1V90h.1v4.6Zm3-2.7a2.456,2.456,0,0,1-1.5-.5,2.73,2.73,0,0,1-.9-1.2,4.967,4.967,0,0,1,0-3.4,2.73,2.73,0,0,1,.9-1.2,2.41,2.41,0,0,1,1.5-.4,2.276,2.276,0,0,1,1.6.4,2.94,2.94,0,0,1,1,1.2,4.967,4.967,0,0,1,0,3.4,2.94,2.94,0,0,1-1,1.2,2.726,2.726,0,0,1-1.6.4h0Zm-.2-1a1.431,1.431,0,0,0,1.1-.3,1.8,1.8,0,0,0,.6-.9,5.019,5.019,0,0,0,.2-1.2,2.345,2.345,0,0,0-.2-1.2,2.034,2.034,0,0,0-.6-.9,1.8,1.8,0,0,0-1.1-.3c-.5,0-.8,0-1,.3a1.456,1.456,0,0,0-.6.9,5.066,5.066,0,0,0-.2,1.3,2.745,2.745,0,0,0,.2,1.3,1.456,1.456,0,0,0,.6.9,1.8,1.8,0,0,0,1.1.3v-.2Z" fill="#bfbfbf"/>
  </g>
      </svg>
    );
  const statusCompleteDesktop = //Done
    (
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 665.4 107.2">

        <g id="d_s_completed">
          <g id="Group_4700" opacity=".5">
            <path id="Path_4180" d="M436.9,39.1h153.9" fill="none" stroke="#8147ed"/>
            <line id="Line_64" x1="60.2" y1="39.1" x2="157" y2="39.1" fill="none" stroke="#2ec278" stroke-opacity="0"/>
            <line id="Line_59" x1="69.5" y1="39.1" x2="202.5" y2="39.1" fill="none" stroke="#2ec278"/>
            <path id="Path_666" d="M231.6,39.1h170.4" fill="none" stroke="#ff9557"/>
            <path id="Path_665" d="M38.5,0c21.3,0,38.5,17.2,38.5,38.5s-17.2,38.5-38.5,38.5S0,59.8,0,38.5,17.2,0,38.5,0Z" fill="#ffd5d5" fill-opacity="0"/>
            <circle id="Ellipse_82" cx="38.5" cy="38.5" r="30.5" fill="#2ec278"/>
            <g id="Group_552">
              <circle id="Ellipse_88" cx="233.5" cy="38.5" r="38.5" fill="#acffd6" fill-opacity="0"/>
              <path id="Path_638" d="M233.4,8.2c16.7,0,30.3,13.6,30.3,30.3s-13.6,30.3-30.3,30.3-30.3-13.6-30.3-30.3,13.6-30.3,30.3-30.3h0Z" fill="#ffc72e"/>
              <g id="inventory_2_black_24dp">
                <g id="Group_4910">
                  <rect id="Rectangle_1309" x="221" y="26" width="26" height="26" fill="none"/>
                </g>
                <g id="Group_4911">
                  <path id="Path_3926" d="M242.3,28.6h-16.6c-1.1,0-2,.9-2.1,2.1v3.1c0,.7.4,1.4,1,1.8v11.8c0,1.1,1,2,2.1,2.1h14.6c1.1,0,2-1,2.1-2.1v-11.8c.6-.4,1-1,1-1.8v-3.1c0-1.1-.9-2-2.1-2.1ZM237.1,41.1h-6.2v-2.1h6.2v2.1ZM242.3,33.8h-16.6v-3.1h16.6v3.1Z" fill="#fff"/>
                </g>
              </g>
            </g>
            <g id="Group_550">
              <circle id="Ellipse_89" cx="423.5" cy="38.5" r="38.5" fill="#ffeaaf" fill-opacity="0"/>
              <circle id="Ellipse_84" cx="423.5" cy="38.5" r="30.5" fill="#8147ed"/>
              <g id="Group_5033">
                <g id="Group_5030">
                  <path id="Path_4200" d="M428.3,38.9c-3.1,0-5.7,2.5-5.7,5.7s2.5,5.7,5.7,5.7,5.7-2.5,5.7-5.7h0c0-3.1-2.5-5.7-5.7-5.7h0M427.2,47.4l-2.3-2.3.8-.8,1.5,1.5,3.7-3.7.8.8-4.5,4.5Z" fill="#fff"/>
                  <path id="Path_4201" d="M420.9,44.6c0-4.1,3.3-7.4,7.4-7.4s1.9.2,2.8.5v-2.6c.6-.3.9-.9.9-1.6v-2.8c0-1-.9-1.8-1.9-1.9h-15.1c-1,0-1.8.9-1.9,1.9v2.8c0,.7.4,1.3.9,1.6v10.6c0,1,.9,1.8,1.9,1.9h5.5c-.4-1-.7-2-.7-3.1M415.1,30.7h15.1v2.8h-15.1v-2.8Z" fill="#fff"/>
                </g>
              </g>
            </g>
            <g id="verified-24px">
              <g id="Group_564">
                <rect id="Rectangle_235" x="25" y="26" width="28" height="28" fill="none"/>
              </g>
              <g id="Group_565">
                <path id="Path_623" d="M51.5,39.6l-2.8-3.3.4-4.3-4.2-1-2.2-3.7-4,1.7-4-1.7-2.2,3.7-4.2.9.4,4.3-2.9,3.3,2.8,3.3-.4,4.3,4.2,1,2.2,3.7,4-1.7,4,1.7,2.2-3.7,4.2-1-.4-4.3,2.9-3.3h0ZM36.4,45.2l-4.4-4.4,1.7-1.7,2.7,2.7,6.8-6.8,1.7,1.7-8.5,8.6h0Z" fill="#fff"/>
              </g>
            </g>
            <g>
              <path d="M11.8,94.1l2.9-8.6h1.5l2.9,8.6h-1.1l-2.7-8h.3l-2.7,8h-1.1ZM13.2,92.1v-1h4.5v1h-4.5Z" fill="#c9c9c9"/>
              <path d="M22.9,94.3c-.7,0-1.2,0-1.7-.4s-.8-.7-1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2c0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5h-.3Z" fill="#c9c9c9"/>
              <path d="M29.4,94.3c-.7,0-1.2,0-1.7-.4s-.8-.7-1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2c0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5h-.3Z" fill="#c9c9c9"/>
              <path d="M36,94.3c-.6,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8,0-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8s-.8-.6-1.4-.6-1.2.2-1.5.6-.5,1-.5,1.8.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3s-1.1.5-1.7.5h.1ZM33.6,91.2v-.9h4.8v.9h-4.8Z" fill="#c9c9c9"/>
              <path d="M40.1,97v-9.4h1v4.8h0v4.6h-1.1ZM43.1,94.3c-.6,0-1.1-.2-1.5-.5s-.7-.7-.9-1.2-.3-1.1-.3-1.7,0-1.2.3-1.7.5-.9.9-1.2c.4-.3.9-.4,1.5-.4s1.1,0,1.6.4.8.7,1,1.2.3,1.1.3,1.7,0,1.2-.3,1.7-.6.9-1,1.2c-.4.3-1,.4-1.6.4h0ZM43,93.3c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.2,0-.9-.2-1.2c0-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8,0-1,.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.3,0,.9.2,1.3c0,.4.3.7.6.9.3.2.6.3,1.1.3v-.2Z" fill="#c9c9c9"/>
              <path d="M46.6,88.5v-.9h4.3v.9h-4.3ZM51,94.1c-.4,0-.8,0-1.1,0-.4,0-.7,0-1-.2s-.5-.4-.7-.7-.2-.5-.2-.8v-6.7h1.1v6.5c0,.2,0,.4,0,.5,0,.3.4.4.7.5s.7,0,1.1,0c0,0,0,.9,0,.9Z" fill="#c9c9c9"/>
              <path d="M54.8,94.3c-.6,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8,0-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8s-.8-.6-1.4-.6-1.2.2-1.5.6-.5,1-.5,1.8.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3s-1.1.5-1.7.5h.1ZM52.4,91.2v-.9h4.8v.9h-4.8Z" fill="#c9c9c9"/>
              <path d="M61.6,94.3c-.6,0-1.1,0-1.6-.4-.4-.3-.8-.7-1-1.2s-.4-1.1-.4-1.7,0-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1,0,1.5.4.7.7.9,1.2c.2.5.3,1.1.3,1.7s0,1.2-.3,1.7-.5.9-.9,1.2c-.4.3-.9.5-1.5.5h0ZM61.8,93.3c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.3,0-.9-.2-1.3c0-.4-.3-.7-.6-.9s-.6-.3-1-.3-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2,0,.9.2,1.2c0,.4.4.7.6.9.3.2.6.3,1.1.3v.2ZM63.7,94.1v-4.8h0v-3.9h1.1v8.6h-1Z" fill="#c9c9c9"/>
            </g>
            <g>
              <path d="M406.8,94.1v-8.6h4c.5,0,.9.2,1.2.5s.6.6.7.9c.2.4.2.8.2,1.3s-.2,1.3-.5,1.7c-.3.5-.9.8-1.5.9h-3.1v3.2h-1.1.1ZM407.9,89.9h2.9c.3,0,.5-.2.7-.4.2-.2.3-.4.4-.6s.1-.5.1-.7,0-.5-.1-.7-.2-.4-.4-.6c-.2-.2-.4-.3-.7-.4h-2.9s0,3.4,0,3.4ZM412,94.1l-1.7-3.5,1.1-.3,1.9,3.8h-1.3Z" fill="#c9c9c9"/>
              <path d="M417.3,94.3c-.6,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2c-.3-.5-.4-1.1-.4-1.8s.1-1.3.4-1.8.6-.9,1.1-1.2c.5-.3,1-.4,1.6-.4s1.2.2,1.7.4c.5.3.8.7,1,1.3.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8s-.8-.6-1.4-.6-1.2.2-1.5.6-.5,1-.5,1.8.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3s-1.1.5-1.7.5h.1ZM414.9,91.2v-.9h4.8v.9h-4.8Z" fill="#c9c9c9"/>
              <path d="M423.3,94.3c-.5,0-.9,0-1.2-.3s-.6-.4-.7-.7c-.2-.3-.2-.6-.2-1s0-.6.2-.9.3-.5.5-.6.5-.3.8-.4c.3,0,.7-.2,1-.2s.8,0,1.2-.2c.4,0,.8,0,1.1-.2l-.4.2c0-.6-.1-1-.3-1.3-.2-.3-.7-.4-1.2-.4s-.7,0-1,.3-.5.5-.6.9l-1-.3c.2-.6.5-1,.9-1.3s1-.5,1.8-.5,1.1,0,1.5.3c.4.2.7.5.9,1,0,.2.2.4.2.7v4.7h-.9v-1.6h.2c-.2.7-.6,1.1-1,1.3-.4.3-1,.4-1.6.4h-.2ZM423.5,93.4c.4,0,.7,0,1-.2.3,0,.5-.3.7-.5s.3-.5.3-.7v-1.3l.4.2c-.3,0-.7,0-1,0s-.7,0-1,0c-.3,0-.6,0-.8.2-.2,0-.3,0-.5.2s-.3.2-.4.3-.1.3-.1.5,0,.3.1.5.2.3.4.4c.2,0,.4.2.7.2h.2Z" fill="#c9c9c9"/>
              <path d="M430.8,94.3c-.6,0-1.1-.2-1.6-.4-.4-.3-.8-.7-1-1.2s-.4-1.1-.4-1.7.1-1.2.4-1.7c.2-.5.6-.9,1-1.2.4-.3.9-.4,1.5-.4s1.1,0,1.5.4.7.7.9,1.2c.2.5.3,1.1.3,1.8s-.1,1.2-.3,1.7c-.2.5-.5.9-.9,1.2s-.9.5-1.5.5v-.2ZM430.9,93.3c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.3,0-.9-.2-1.3c-.1-.4-.3-.7-.6-.9-.3-.2-.6-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2,0,.9.2,1.2c.1.4.4.7.6.9.3.2.6.3,1.1.3v.2ZM432.8,94.1v-4.8h-.1v-3.9h1.1v8.6h-1Z" fill="#c9c9c9"/>
              <path d="M436.3,97l1.3-3.4v1l-2.8-7h1.1l2.2,5.6h-.4l2.1-5.6h1.1l-3.5,9.4h-1.1Z" fill="#c9c9c9"/>
            </g>
            <g>
              <path d="M212.2,94.1v-8.6h4c.5,0,.9.2,1.2.5.3.2.6.6.7.9.2.4.2.8.2,1.3s0,.9-.2,1.3-.4.7-.7.9-.7.4-1.2.5h-2.9v3.2h-1.1,0ZM213.3,89.9h2.9c.3,0,.5-.2.7-.4.2-.2.3-.4.4-.6s.1-.5.1-.7,0-.5-.1-.7c0-.2-.2-.4-.4-.6s-.4-.3-.7-.4h-2.9s0,3.4,0,3.4Z" fill="#c9c9c9"/>
              <path d="M221.2,94.3c-.5,0-.9,0-1.2-.3-.3-.2-.6-.4-.7-.7s-.2-.6-.2-1,0-.6.2-.9.3-.5.5-.6c.2-.2.5-.3.8-.4.3,0,.7-.2,1-.2s.8,0,1.2-.2c.4,0,.8,0,1.1-.2l-.4.2c0-.6-.1-1-.3-1.3s-.7-.4-1.2-.4-.7,0-1,.3c-.3.2-.5.5-.6.9l-1-.3c.2-.6.5-1,.9-1.3s1-.5,1.8-.5,1.1,0,1.5.3c.4.2.7.5.9,1,0,.2.2.4.2.7v4.7h-.9v-1.6h.2c-.2.7-.6,1.1-1,1.3-.4.3-1,.4-1.6.4h-.2ZM221.4,93.4c.4,0,.7,0,1-.2.3,0,.5-.3.7-.5s.3-.5.3-.7v-1.3l.4.2c-.3,0-.7,0-1,0s-.7,0-1,0-.6,0-.8.2c-.2,0-.3,0-.5.2-.2,0-.3.2-.4.3,0,0-.1.3-.1.5s0,.3.1.5c0,.2.2.3.4.4s.4.2.7.2h.2Z" fill="#c9c9c9"/>
              <path d="M228.9,94.3c-.7,0-1.2,0-1.7-.4s-.8-.7-1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2c0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5h-.3Z" fill="#c9c9c9"/>
              <path d="M232.9,94.1v-8.6h1.1v5.3l2.7-3.1h1.4l-2.9,3.2,3.2,3.2h-1.5l-2.9-3.1v3.1h-1.1Z" fill="#c9c9c9"/>
              <path d="M239.1,86.5v-1.1h1.1v1.1h-1.1ZM239.1,94.1v-6.5h1.1v6.5h-1.1Z" fill="#c9c9c9"/>
              <path d="M242,94.1v-6.5h1v1.7h.1v4.7h-1.1ZM246.6,94.1v-4.1c0-.3-.1-.5-.3-.8-.1-.2-.3-.4-.5-.5s-.5-.2-.9-.2-.5,0-.7,0c-.2,0-.4.2-.6.4-.2.2-.3.4-.4.7,0,.3-.1.6-.1,1l-.7-.2c0-.6.1-1.2.3-1.6.2-.5.5-.8,1-1s.9-.4,1.4-.4.8,0,1.1.2.5.3.7.5.4.5.5.7c.1.3.2.5.2.8v4.4h-1.1.1Z" fill="#c9c9c9"/>
              <path d="M251.8,94.3c-.6,0-1.1,0-1.6-.4-.4-.3-.8-.7-1-1.2s-.4-1.1-.4-1.7.1-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1,0,1.5.4.7.7.9,1.2.3,1.1.3,1.7-.1,1.2-.3,1.7-.5.9-.9,1.2c-.4.3-.9.5-1.5.5h.1ZM251.9,97.2c-.3,0-.7,0-1-.2-.3,0-.6-.3-.9-.5s-.5-.5-.7-.8l1-.5c.2.3.4.5.7.7.3,0,.6.2.9.2s.8,0,1-.2c.3,0,.5-.4.6-.7.1-.3.2-.7.2-1.1v-1.8h.1v-4.7h.9v7.5c0,.5-.3.8-.5,1.1-.2.3-.6.5-1,.7-.4,0-.8.2-1.4.2h0ZM251.9,93.3c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.3,0-.9-.2-1.3c-.1-.4-.3-.7-.6-.9s-.6-.3-1-.3-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2,0,.9.2,1.2c.1.4.4.7.6.9.3.2.6.3,1.1.3v.2Z" fill="#c9c9c9"/>
            </g>
          </g>
          <line id="Line_60" x1="562.8" y1="39.1" x2="617.6" y2="39.1" fill="none" stroke="#d4d4d4" stroke-opacity="0"/>
          <g id="Group_551">
            <circle id="Ellipse_90" cx="620.5" cy="38.5" r="38.5" fill="#d1e2ff"/>
            <circle id="Ellipse_85" cx="620.5" cy="38.5" r="30.5" fill="#0a64f9"/>
          </g>
          <circle id="Ellipse_87" cx="582" cy="40" r="5" fill="#8147ed"/>
          <g id="thumbs_up">
            <g id="Group_555">
              <g id="Group_554">
                <path id="Path_618" d="M632.4,40.4c0-.9-.7-1.7-1.7-1.7.9,0,1.7-.7,1.7-1.7s-.7-1.7-1.7-1.7h-6.8c.7-1.9,1.1-3.9,1-5.9,0-1.4-1.1-2.5-2.5-2.5h-.5c-.4,0-.7.3-.8.6-.8,3.2-2.1,6.9-5.4,7.7v12.1l2.9,1c.9.3,1.7.4,2.6.4h7.8c.9,0,1.7-.7,1.7-1.7s-.7-1.7-1.7-1.7h1.7c.9,0,1.7-.7,1.7-1.7s-.7-1.7-1.7-1.7c.9,0,1.7-.8,1.7-1.7v.2Z" fill="#fff"/>
              </g>
            </g>
            <g id="Group_557">
              <g id="Group_556">
                <path id="Path_619" d="M611.6,33.7h-3.3c-.5,0-.8.4-.8.8h0v15c0,.5.4.8.8.8h3.3c1.4,0,2.5-1.1,2.5-2.5v-11.7c0-1.4-1.1-2.5-2.5-2.5h0ZM611.6,47.1c-.5,0-.8-.4-.8-.8s.4-.8.8-.8.8.4.8.8-.4.8-.8.8h0Z" fill="#fff"/>
              </g>
            </g>
            <g id="Group_559">
              <g id="Group_558">
                <path id="Path_620" d="M618.9,25.6l-1.2-1.2c-.3-.3-.8-.4-1.2,0-.3.3-.4.8,0,1.2h0l1.2,1.2c.3.3.9.3,1.2,0s.3-.8,0-1.1h0Z" fill="#fff"/>
              </g>
            </g>
            <g id="Group_561">
              <g id="Group_560">
                <path id="Path_621" d="M628.3,24.4c-.3-.3-.9-.3-1.2,0h0l-1.2,1.2c-.3.3-.4.8,0,1.2s.8.4,1.2,0h0l1.2-1.2c.3-.3.3-.9,0-1.2h0Z" fill="#fff"/>
              </g>
            </g>
            <g id="Group_563">
              <g id="Group_562">
                <path id="Path_622" d="M622.4,21.9c-.5,0-.8.4-.8.8h0v1.7c0,.5.4.8.9.8s.8-.4.8-.8v-1.7c0-.5-.4-.8-.8-.8h-.1Z" fill="#fff"/>
              </g>
            </g>
          </g>
          <g>
            <path d="M587.2,102.6v-10h4.9c.6,0,1,.3,1.4.6s.7.7.8,1.1c.2.4.3.9.3,1.5s0,1-.3,1.5c-.2.4-.5.8-.8,1.1-.4.3-.8.5-1.4.6h-3.4v3.6s-1.5,0-1.5,0ZM588.7,97.7h3.1c.3,0,.5-.2.7-.4.2-.2.3-.4.4-.7,0-.2,0-.5,0-.7s0-.5,0-.7c0-.2-.2-.5-.4-.6-.2-.2-.4-.3-.7-.4h-3.1v3.7-.2Z"/>
            <path d="M596.1,93.9v-1.4h1.5v1.4h-1.5ZM596.1,102.6v-7.5h1.5v7.5h-1.5Z"/>
            <path d="M602.8,102.9c-.8,0-1.4-.2-2-.5-.5-.3-1-.8-1.2-1.4-.3-.6-.4-1.3-.4-2s.2-1.5.4-2.1c.3-.6.7-1.1,1.3-1.4.5-.3,1.2-.5,2-.5s1.5.2,2.1.6,1,1,1.2,1.7l-1.4.4c0-.4-.4-.8-.7-1s-.7-.4-1.1-.4-.9,0-1.2.3c-.3.2-.5.5-.7.9,0,.4-.2.8-.2,1.3,0,.8.2,1.4.5,1.9.4.5.9.7,1.6.7s.8,0,1.1-.3.5-.5.7-.9l1.5.3c-.2.7-.7,1.3-1.2,1.7-.6.4-1.3.6-2.1.6h-.2Z"/>
            <path d="M607.5,102.6v-10h1.5v6.1l3-3.6h1.9l-3.1,3.8,3.4,3.8h-2l-3.1-3.6v3.6h-1.6Z"/>
            <path d="M618,102.9c-.7,0-1.4-.2-2-.5s-1-.8-1.3-1.4-.5-1.3-.5-2,.2-1.5.5-2.1.7-1.1,1.3-1.4,1.2-.5,1.9-.5,1.4.2,2,.5c.5.4.9.9,1.2,1.5.3.7.4,1.4.3,2.3h-1.5v-.5c0-.9-.2-1.5-.5-1.9s-.8-.6-1.5-.6-1.3.2-1.6.7c-.4.5-.5,1.1-.5,2s.2,1.5.5,1.9c.4.5.9.7,1.6.7s.8,0,1.2-.3c.3-.2.6-.5.8-.9l1.4.5c-.3.7-.7,1.2-1.3,1.6s-1.3.6-2,.6v-.2ZM615.4,99.3v-1.1h5.4v1.1h-5.4Z"/>
            <path d="M626,102.9c-.7,0-1.3-.2-1.8-.5s-.9-.8-1.2-1.4-.4-1.3-.4-2,0-1.4.4-2c.3-.6.7-1.1,1.2-1.4s1.1-.5,1.8-.5,1.3.2,1.8.5.9.8,1.1,1.4c.2.6.4,1.3.4,2s0,1.4-.4,2c-.3.6-.6,1.1-1.1,1.4-.5.4-1.1.5-1.8.5ZM626.2,101.5c.5,0,.9,0,1.2-.3.3-.2.5-.5.7-.9s.2-.9.2-1.4,0-1-.2-1.4-.4-.7-.7-.9c-.3-.2-.7-.3-1.1-.3s-.9,0-1.2.4c-.3.2-.5.6-.7,1s-.2.8-.2,1.3,0,.9.2,1.3.4.7.7,1c.3.2.7.4,1.2.4v-.2ZM628.2,102.6v-5.4h-.2v-4.6h1.5v10h-1.3Z"/>
            <path d="M631.3,98.8v-1.2h4.2v1.2h-4.2Z"/>
            <path d="M641.3,102.9c-.8,0-1.5-.2-2.1-.5s-1.1-.8-1.4-1.4c-.3-.6-.5-1.3-.5-2.1v-6.3h1.5v6.3c0,.4,0,.8.2,1.1s.3.6.6.8c.2.2.5.4.8.5s.6.2.9.2.6,0,.9-.2c.3,0,.6-.3.8-.5s.4-.5.6-.8.2-.7.2-1.1v-6.3h1.5v6.3c0,.8-.2,1.5-.5,2.1s-.8,1-1.4,1.4c-.6.3-1.3.5-2.1.5Z"/>
            <path d="M647.3,106v-10.9h1.3v5.4h.2v5.5h-1.5ZM650.8,102.9c-.7,0-1.3-.2-1.8-.5s-.9-.8-1.1-1.4c-.3-.6-.4-1.3-.4-2s0-1.4.4-2c.2-.6.6-1.1,1.1-1.4s1.1-.5,1.8-.5,1.3.2,1.8.5.9.8,1.2,1.4.4,1.3.4,2,0,1.4-.4,2c-.3.6-.7,1.1-1.2,1.4s-1.1.5-1.8.5ZM650.6,101.5c.5,0,.8,0,1.2-.4.3-.2.5-.6.7-1s.2-.9.2-1.3,0-.9-.2-1.3-.4-.7-.7-1c-.3-.2-.7-.4-1.2-.4s-.8,0-1.1.3c-.3.2-.5.5-.7.9s-.2.9-.2,1.4,0,1,.2,1.4c0,.4.4.7.7.9.3.2.7.3,1.2.3v.2Z"/>
          </g>
        </g>
      </svg>
    );

  const statusCompleteMobile = //Done
    (
      <svg id="statusCompleteMobile_1_" data-name="statusCompleteMobile (1)" xmlns="http://www.w3.org/2000/svg" width="479" height="105.9" viewBox="0 0 479 105.9">
  <g id="Group_4700" opacity="0.5">
    <path id="Path_4180" d="M316.9,39.1h93.9" fill="none" stroke="#8147ed" stroke-width="1"/>
    <line id="Line_64" x2="96.8" transform="translate(60.2 39.1)" fill="none"/>
    <line id="Line_59" x2="73" transform="translate(69.5 39.1)" fill="none" stroke="#2ec278" stroke-width="1"/>
    <path id="Path_666" d="M171.6,39.1H282" fill="none" stroke="#ff9557" stroke-width="1"/>
    <path id="Path_665" d="M38.5,0a38.45,38.45,0,1,1-.1,0h0Z" opacity="0"/>
    <circle id="Ellipse_82" cx="30.5" cy="30.5" r="30.5" transform="translate(8 8)" fill="#2ec278"/>
    <g id="Group_552">
      <circle id="Ellipse_88" cx="38.5" cy="38.5" r="38.5" transform="translate(135)" opacity="0"/>
      <path id="Path_638" d="M173.4,8.2a30.3,30.3,0,1,1-30.3,30.3A30.344,30.344,0,0,1,173.4,8.2h0Z" fill="#ffc72e"/>
      <g id="inventory_2_black_24dp">
        <g id="Group_4910">
          <rect id="Rectangle_1309" width="26" height="26" transform="translate(161 26)" fill="none"/>
        </g>
        <g id="Group_4911">
          <path id="Path_3926" d="M182.3,28.6H165.7a2.182,2.182,0,0,0-2.1,2.1v3.1a2.234,2.234,0,0,0,1,1.8V47.4a2.258,2.258,0,0,0,2.1,2.1h14.6a2.258,2.258,0,0,0,2.1-2.1V35.6a2.234,2.234,0,0,0,1-1.8V30.7a2.182,2.182,0,0,0-2.1-2.1Zm-5.2,12.5h-6.2V39h6.2Zm5.2-7.3H165.7V30.7h16.6Z" fill="#fff"/>
        </g>
      </g>
    </g>
    <g id="Group_550">
      <circle id="Ellipse_89" cx="38.5" cy="38.5" r="38.5" transform="translate(265)" opacity="0"/>
      <circle id="Ellipse_84" cx="30.5" cy="30.5" r="30.5" transform="translate(273 8)" fill="#8147ed"/>
      <g id="Group_5033">
        <g id="Group_8220">
          <g id="Group_5030">
            <path id="Path_4200" d="M308.3,38.9a5.7,5.7,0,1,0,5.7,5.7h0a5.763,5.763,0,0,0-5.7-5.7h0m-1.1,8.5-2.3-2.3.8-.8,1.5,1.5,3.7-3.7.8.8Z" fill="#fff"/>
            <path id="Path_4201" d="M300.9,44.6a7.383,7.383,0,0,1,7.4-7.4h0a8.675,8.675,0,0,1,2.8.5V35.1a1.7,1.7,0,0,0,.9-1.6V30.7a2.043,2.043,0,0,0-1.9-1.9H295a2.043,2.043,0,0,0-1.9,1.9v2.8a1.7,1.7,0,0,0,.9,1.6V45.7a2.043,2.043,0,0,0,1.9,1.9h5.5a8.245,8.245,0,0,1-.7-3.1m-5.6-13.8h15.1v2.8H295.1Z" fill="#fff"/>
          </g>
        </g>
      </g>
    </g>
    <g id="verified-24px">
      <g id="Group_564">
        <rect id="Rectangle_235" width="28" height="28" transform="translate(25 26)" fill="none"/>
      </g>
      <g id="Group_565">
        <path id="Path_623" d="M51.5,39.6l-2.8-3.3.4-4.3-4.2-1-2.2-3.7-4,1.7-4-1.7L32.5,31l-4.2.9.4,4.3-2.9,3.3,2.8,3.3-.4,4.3,4.2,1,2.2,3.7,4-1.7,4,1.7,2.2-3.7,4.2-1-.4-4.3,2.9-3.3h0ZM36.4,45.2,32,40.8l1.7-1.7,2.7,2.7L43.2,35l1.7,1.7-8.5,8.6h0Z" fill="#fff"/>
      </g>
    </g>
    <g id="Group_6692" data-name="Group 6692">
      <path id="Path_8529" data-name="Path 8529" d="M286.8,94V85.4h4a1.656,1.656,0,0,1,1.2.5,2.016,2.016,0,0,1,.7.9,4.438,4.438,0,0,1,.2,1.3,3.371,3.371,0,0,1-.5,1.7,2.551,2.551,0,0,1-1.5.9h-3.1v3.2h-1Zm1.1-4.2h2.9a1.012,1.012,0,0,0,.7-.4,2.092,2.092,0,0,0,.4-.6,2.5,2.5,0,0,0,0-1.4c0-.2-.2-.4-.4-.6a1.612,1.612,0,0,0-.7-.4h-2.9v3.4ZM292,94l-1.7-3.5,1.1-.3,1.9,3.8Z" fill="#bfbfbf"/>
      <path id="Path_8530" data-name="Path 8530" d="M297.3,94.2a2.543,2.543,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,4.25,4.25,0,0,1,0-3.6,3.179,3.179,0,0,1,1.1-1.2,2.934,2.934,0,0,1,1.6-.4,3.371,3.371,0,0,1,1.7.5,2.209,2.209,0,0,1,1,1.3,6.013,6.013,0,0,1,.3,2h-1.1v-.4a2.969,2.969,0,0,0-.5-1.8,1.678,1.678,0,0,0-1.4-.6,1.887,1.887,0,0,0-1.5.6,3.49,3.49,0,0,0,0,3.6,1.755,1.755,0,0,0,1.5.6c.7,0,.8,0,1.1-.3a1.779,1.779,0,0,0,.7-.8l1,.4a2.85,2.85,0,0,1-1.1,1.3,3.371,3.371,0,0,1-1.7.5h.1Zm-2.4-3.1v-.9h4.8v.9Z" fill="#bfbfbf"/>
      <path id="Path_8531" data-name="Path 8531" d="M303.3,94.2a1.558,1.558,0,0,1-1.2-.3c-.3-.3-.6-.4-.7-.7a2.958,2.958,0,0,1-.2-1,1.344,1.344,0,0,1,.2-.9c.2-.3.3-.5.5-.6a2.01,2.01,0,0,1,.8-.4c.3,0,.7-.2,1-.2a2.745,2.745,0,0,0,1.2-.2,1.978,1.978,0,0,0,1.1-.2l-.4.2a2.269,2.269,0,0,0-.3-1.3,1.461,1.461,0,0,0-1.2-.4c-.5,0-.7,0-1,.3a1.456,1.456,0,0,0-.6.9l-1-.3a2.54,2.54,0,0,1,.9-1.3,2.969,2.969,0,0,1,1.8-.5c.8,0,1.1,0,1.5.3a2.189,2.189,0,0,1,.9,1,2.544,2.544,0,0,0,.2.7V94h-.9V92.4h.2a1.994,1.994,0,0,1-1,1.3,2.726,2.726,0,0,1-1.6.4h-.2Zm.2-.9a1.645,1.645,0,0,0,1-.2,4.235,4.235,0,0,0,.7-.5,1.08,1.08,0,0,0,.3-.7V90.6l.4.2h-2a1.077,1.077,0,0,0-.8.2.551.551,0,0,0-.5.2c-.2,0-.3.2-.4.3a2.034,2.034,0,0,0-.1.5.9.9,0,0,0,.1.5.781.781,0,0,0,.4.4c.2,0,.4.2.7.2h.2Z" fill="#bfbfbf"/>
      <path id="Path_8532" data-name="Path 8532" d="M310.8,94.2a2.276,2.276,0,0,1-1.6-.4,2.94,2.94,0,0,1-1-1.2,4.711,4.711,0,0,1-.4-1.7,3.287,3.287,0,0,1,.4-1.7,2.94,2.94,0,0,1,1-1.2,2.41,2.41,0,0,1,1.5-.4,1.913,1.913,0,0,1,1.5.4,3.449,3.449,0,0,1,.9,1.2,4.967,4.967,0,0,1,0,3.4,2.73,2.73,0,0,1-.9,1.2,2.456,2.456,0,0,1-1.5.5h.1Zm.1-1a1.431,1.431,0,0,0,1.1-.3,1.8,1.8,0,0,0,.6-.9,5.066,5.066,0,0,0,.2-1.3,2.745,2.745,0,0,0-.2-1.3,2.034,2.034,0,0,0-.6-.9,1.689,1.689,0,0,0-1-.3,1.431,1.431,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.345,2.345,0,0,0,.2,1.2,2.034,2.034,0,0,0,.6.9,1.609,1.609,0,0,0,1.1.3v.2Zm1.9.8V89.2h-.1V85.3h1.1v8.6h-1Z" fill="#bfbfbf"/>
      <path id="Path_8533" data-name="Path 8533" d="M316.3,96.9l1.3-3.4v1l-2.8-7h1.1l2.2,5.6h-.4l2.1-5.6h1.1l-3.5,9.4h-1.1Z" fill="#bfbfbf"/>
    </g>
    <g id="Group_6693" data-name="Group 6693">
      <path id="Path_8534" data-name="Path 8534" d="M152.2,94V85.4h4a1.656,1.656,0,0,1,1.2.5,2.016,2.016,0,0,1,.7.9,4.439,4.439,0,0,1,.2,1.3,2.745,2.745,0,0,1-.2,1.3,2.178,2.178,0,0,1-.7.9,3.337,3.337,0,0,1-1.2.5h-2.9V94h-1.1Zm1.1-4.2h2.9a1.012,1.012,0,0,0,.7-.4,2.092,2.092,0,0,0,.4-.6,2.5,2.5,0,0,0,0-1.4c0-.2-.2-.4-.4-.6a1.612,1.612,0,0,0-.7-.4h-2.9v3.4Z" fill="#bfbfbf"/>
      <path id="Path_8535" data-name="Path 8535" d="M161.2,94.2a1.558,1.558,0,0,1-1.2-.3c-.3-.3-.6-.4-.7-.7a2.959,2.959,0,0,1-.2-1,1.344,1.344,0,0,1,.2-.9c.2-.3.3-.5.5-.6a2.01,2.01,0,0,1,.8-.4c.3,0,.7-.2,1-.2a2.744,2.744,0,0,0,1.2-.2,1.978,1.978,0,0,0,1.1-.2l-.4.2a2.269,2.269,0,0,0-.3-1.3,1.461,1.461,0,0,0-1.2-.4c-.5,0-.7,0-1,.3a1.456,1.456,0,0,0-.6.9l-1-.3a2.54,2.54,0,0,1,.9-1.3,2.969,2.969,0,0,1,1.8-.5c.8,0,1.1,0,1.5.3a2.189,2.189,0,0,1,.9,1,2.544,2.544,0,0,0,.2.7V94h-.9V92.4h.2a1.994,1.994,0,0,1-1,1.3,2.726,2.726,0,0,1-1.6.4h-.2Zm.2-.9a1.645,1.645,0,0,0,1-.2,4.233,4.233,0,0,0,.7-.5,1.08,1.08,0,0,0,.3-.7V90.6l.4.2h-2a1.077,1.077,0,0,0-.8.2.551.551,0,0,0-.5.2c-.2,0-.3.2-.4.3a2.034,2.034,0,0,0-.1.5.9.9,0,0,0,.1.5.781.781,0,0,0,.4.4,1.445,1.445,0,0,0,.7.2h.2Z" fill="#bfbfbf"/>
      <path id="Path_8536" data-name="Path 8536" d="M168.9,94.2a2.414,2.414,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,4.776,4.776,0,0,1-.4-1.8,3.425,3.425,0,0,1,.4-1.8,2.544,2.544,0,0,1,1.1-1.2,3.064,3.064,0,0,1,1.7-.4,3.453,3.453,0,0,1,1.8.5,2.434,2.434,0,0,1,1,1.4l-1.1.3a1.6,1.6,0,0,0-.7-.9,1.929,1.929,0,0,0-1-.3,1.548,1.548,0,0,0-1.1.3,1.8,1.8,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.86,2.86,0,0,0,.5,1.7,1.806,1.806,0,0,0,1.5.7c.7,0,.8,0,1.1-.3a2.1,2.1,0,0,0,.6-.8l1.1.3a2.981,2.981,0,0,1-1,1.4,3.1,3.1,0,0,1-1.7.5h-.2Z" fill="#bfbfbf"/>
      <path id="Path_8537" data-name="Path 8537" d="M172.9,94V85.4H174v5.3l2.7-3.1h1.4l-2.9,3.2,3.2,3.2h-1.5L174,90.9V94Z" fill="#bfbfbf"/>
      <path id="Path_8538" data-name="Path 8538" d="M179.1,86.4V85.3h1.1v1.1Zm0,7.6V87.5h1.1V94Z" fill="#bfbfbf"/>
      <path id="Path_8539" data-name="Path 8539" d="M182,94V87.5h1v1.7h.1v4.7H182Zm4.6,0V89.9c0-.3-.2-.5-.3-.8a1.205,1.205,0,0,0-.5-.5,2,2,0,0,0-.9-.2h-.7c-.2,0-.4.2-.6.4a1.612,1.612,0,0,0-.4.7c0,.3-.1.6-.1,1l-.7-.2a3.808,3.808,0,0,1,.3-1.6,1.651,1.651,0,0,1,1-1,3.708,3.708,0,0,1,1.4-.4,1.815,1.815,0,0,1,1.1.2,4.233,4.233,0,0,1,.7.5,3.039,3.039,0,0,1,.5.7,2.353,2.353,0,0,1,.2.8v4.4h-1Z" fill="#bfbfbf"/>
      <path id="Path_8540" data-name="Path 8540" d="M191.8,94.2a2.276,2.276,0,0,1-1.6-.4,2.94,2.94,0,0,1-1-1.2,4.711,4.711,0,0,1-.4-1.7,3.287,3.287,0,0,1,.4-1.7,2.94,2.94,0,0,1,1-1.2,2.41,2.41,0,0,1,1.5-.4,1.913,1.913,0,0,1,1.5.4,3.449,3.449,0,0,1,.9,1.2,4.967,4.967,0,0,1,0,3.4,2.73,2.73,0,0,1-.9,1.2,2.456,2.456,0,0,1-1.5.5h.1Zm.1,2.9a1.8,1.8,0,0,1-1-.2c-.3,0-.6-.3-.9-.5a2.7,2.7,0,0,1-.7-.8l1-.5a2.336,2.336,0,0,0,.7.7c.3,0,.6.2.9.2s.8,0,1-.2c.3,0,.5-.4.6-.7a3.6,3.6,0,0,0,.2-1.1V92.2h.1V87.5h.9V95a1.433,1.433,0,0,1-.5,1.1,3.592,3.592,0,0,1-1,.7c-.4,0-.8.2-1.4.2h0Zm0-3.9a1.431,1.431,0,0,0,1.1-.3,1.8,1.8,0,0,0,.6-.9,5.066,5.066,0,0,0,.2-1.3,2.745,2.745,0,0,0-.2-1.3,2.034,2.034,0,0,0-.6-.9,1.689,1.689,0,0,0-1-.3,1.431,1.431,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.345,2.345,0,0,0,.2,1.2,2.034,2.034,0,0,0,.6.9,1.609,1.609,0,0,0,1.1.3v.2Z" fill="#bfbfbf"/>
    </g>
    <g id="Group_6694" data-name="Group 6694">
      <path id="Path_8541" data-name="Path 8541" d="M11.8,94l2.9-8.6h1.5L19.1,94H18l-2.7-8h.3l-2.7,8Zm1.4-2V91h4.5v1H13.2Z" fill="#bfbfbf"/>
      <path id="Path_8542" data-name="Path 8542" d="M22.9,94.2a2.414,2.414,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,4.776,4.776,0,0,1-.4-1.8,3.425,3.425,0,0,1,.4-1.8,2.545,2.545,0,0,1,1.1-1.2,3.064,3.064,0,0,1,1.7-.4,3.453,3.453,0,0,1,1.8.5,2.434,2.434,0,0,1,1,1.4l-1.1.3a1.6,1.6,0,0,0-.7-.9,1.929,1.929,0,0,0-1-.3,1.548,1.548,0,0,0-1.1.3,1.8,1.8,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.86,2.86,0,0,0,.5,1.7,1.806,1.806,0,0,0,1.5.7c.7,0,.8,0,1.1-.3a2.1,2.1,0,0,0,.6-.8l1.1.3a2.981,2.981,0,0,1-1,1.4,3.1,3.1,0,0,1-1.7.5h-.2Z" fill="#bfbfbf"/>
      <path id="Path_8543" data-name="Path 8543" d="M29.4,94.2a2.414,2.414,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,4.776,4.776,0,0,1-.4-1.8,3.425,3.425,0,0,1,.4-1.8,2.545,2.545,0,0,1,1.1-1.2,3.064,3.064,0,0,1,1.7-.4,3.453,3.453,0,0,1,1.8.5,2.434,2.434,0,0,1,1,1.4l-1.1.3a1.6,1.6,0,0,0-.7-.9,1.929,1.929,0,0,0-1-.3,1.548,1.548,0,0,0-1.1.3,1.8,1.8,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.86,2.86,0,0,0,.5,1.7,1.806,1.806,0,0,0,1.5.7c.7,0,.8,0,1.1-.3a2.1,2.1,0,0,0,.6-.8l1.1.3a2.981,2.981,0,0,1-1,1.4,3.1,3.1,0,0,1-1.7.5h-.2Z" fill="#bfbfbf"/>
      <path id="Path_8544" data-name="Path 8544" d="M36,94.2a2.543,2.543,0,0,1-1.7-.4,4.146,4.146,0,0,1-1.1-1.2,3.425,3.425,0,0,1-.4-1.8,2.689,2.689,0,0,1,.4-1.8,3.179,3.179,0,0,1,1.1-1.2,2.934,2.934,0,0,1,1.6-.4,3.371,3.371,0,0,1,1.7.5,2.209,2.209,0,0,1,1,1.3,6.013,6.013,0,0,1,.3,2H37.8v-.4a2.969,2.969,0,0,0-.5-1.8,1.678,1.678,0,0,0-1.4-.6,1.887,1.887,0,0,0-1.5.6,3.49,3.49,0,0,0,0,3.6,1.755,1.755,0,0,0,1.5.6c.7,0,.8,0,1.1-.3a1.779,1.779,0,0,0,.7-.8l1,.4a2.85,2.85,0,0,1-1.1,1.3,3.371,3.371,0,0,1-1.7.5H36Zm-2.4-3.1v-.9h4.8v.9Z" fill="#bfbfbf"/>
      <path id="Path_8545" data-name="Path 8545" d="M40.1,96.9V87.5h1v4.8h0v4.6H40Zm3-2.7a2.456,2.456,0,0,1-1.5-.5,2.73,2.73,0,0,1-.9-1.2,4.671,4.671,0,0,1-.3-1.7,3.233,3.233,0,0,1,.3-1.7,3.778,3.778,0,0,1,.9-1.2,2.41,2.41,0,0,1,1.5-.4,2.276,2.276,0,0,1,1.6.4,2.94,2.94,0,0,1,1,1.2,4.671,4.671,0,0,1,.3,1.7,3.233,3.233,0,0,1-.3,1.7,4,4,0,0,1-1,1.2,2.726,2.726,0,0,1-1.6.4h0Zm-.1-1a1.431,1.431,0,0,0,1.1-.3,1.8,1.8,0,0,0,.6-.9,5.019,5.019,0,0,0,.2-1.2,2.345,2.345,0,0,0-.2-1.2c0-.4-.4-.7-.6-.9a1.8,1.8,0,0,0-1.1-.3c-.5,0-.8,0-1,.3a1.456,1.456,0,0,0-.6.9,5.067,5.067,0,0,0-.2,1.3,2.745,2.745,0,0,0,.2,1.3,1.149,1.149,0,0,0,.6.9,1.8,1.8,0,0,0,1.1.3v-.2Z" fill="#bfbfbf"/>
      <path id="Path_8546" data-name="Path 8546" d="M46.6,88.4v-.9h4.3v.9ZM51,94H49.9a1.645,1.645,0,0,1-1-.2,2.336,2.336,0,0,1-.7-.7c0-.3-.2-.5-.2-.8V85.6h1.1v7c0,.3.4.4.7.5h1.1V94Z" fill="#bfbfbf"/>
      <path id="Path_8547" data-name="Path 8547" d="M54.8,94.2a2.543,2.543,0,0,1-1.7-.4A4.146,4.146,0,0,1,52,92.6a3.425,3.425,0,0,1-.4-1.8A2.689,2.689,0,0,1,52,89a3.179,3.179,0,0,1,1.1-1.2,2.934,2.934,0,0,1,1.6-.4,3.371,3.371,0,0,1,1.7.5,2.209,2.209,0,0,1,1,1.3,6.013,6.013,0,0,1,.3,2H56.6v-.4a2.969,2.969,0,0,0-.5-1.8,1.678,1.678,0,0,0-1.4-.6,1.887,1.887,0,0,0-1.5.6,3.49,3.49,0,0,0,0,3.6,1.755,1.755,0,0,0,1.5.6c.7,0,.8,0,1.1-.3a1.779,1.779,0,0,0,.7-.8l1,.4a2.85,2.85,0,0,1-1.1,1.3,3.371,3.371,0,0,1-1.7.5h.1Zm-2.4-3.1v-.9h4.8v.9Z" fill="#bfbfbf"/>
      <path id="Path_8548" data-name="Path 8548" d="M61.6,94.2a2.276,2.276,0,0,1-1.6-.4,2.94,2.94,0,0,1-1-1.2,4.711,4.711,0,0,1-.4-1.7,2.543,2.543,0,0,1,.4-1.7A2.94,2.94,0,0,1,60,88a2.41,2.41,0,0,1,1.5-.4A1.913,1.913,0,0,1,63,88a3.449,3.449,0,0,1,.9,1.2,4.671,4.671,0,0,1,.3,1.7,3.233,3.233,0,0,1-.3,1.7,3.778,3.778,0,0,1-.9,1.2,2.456,2.456,0,0,1-1.5.5h0Zm.2-1a1.431,1.431,0,0,0,1.1-.3,1.8,1.8,0,0,0,.6-.9,5.067,5.067,0,0,0,.2-1.3,2.745,2.745,0,0,0-.2-1.3,2.034,2.034,0,0,0-.6-.9,1.689,1.689,0,0,0-1-.3,1.431,1.431,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.345,2.345,0,0,0,.2,1.2c0,.4.4.7.6.9a1.609,1.609,0,0,0,1.1.3v.2Zm1.9.8V89.2h0V85.3h1.1v8.6h-1Z" fill="#bfbfbf"/>
    </g>
  </g>
  <line id="Line_60" x2="54.8" transform="translate(382.8 39.1)" fill="none"/>
  <g id="Group_551">
    <circle id="Ellipse_90" cx="38.5" cy="38.5" r="38.5" transform="translate(402)" fill="#d1e2ff"/>
    <circle id="Ellipse_85" cx="30.5" cy="30.5" r="30.5" transform="translate(410 8)" fill="#0a64f9"/>
  </g>
  <circle id="Ellipse_87" cx="5" cy="5" r="5" transform="translate(397 35)" fill="#8147ed"/>
  <g id="thumbs_up">
    <g id="Group_555">
      <g id="Group_554">
        <path id="Path_618" d="M452.4,40.4a1.685,1.685,0,0,0-1.7-1.7h0a1.685,1.685,0,0,0,1.7-1.7h0a1.666,1.666,0,0,0-1.6-1.7h-6.9a14.97,14.97,0,0,0,1-5.9,2.476,2.476,0,0,0-2.5-2.5h-.5a.871.871,0,0,0-.8.6c-.8,3.2-2.1,6.9-5.4,7.7V47.3l2.9,1a7.457,7.457,0,0,0,2.6.4H449a1.7,1.7,0,1,0,0-3.4h1.7a1.685,1.685,0,0,0,1.7-1.7h0a1.666,1.666,0,0,0-1.6-1.7h-.1a1.752,1.752,0,0,0,1.7-1.7v.2h0Z" fill="#fff"/>
      </g>
    </g>
    <g id="Group_557">
      <g id="Group_556">
        <path id="Path_619" d="M431.6,33.7h-3.3a.789.789,0,0,0-.8.8h0v15a.789.789,0,0,0,.8.8h3.3a2.476,2.476,0,0,0,2.5-2.5h0V36.1a2.476,2.476,0,0,0-2.5-2.5h0Zm0,13.4a.8.8,0,1,1,.8-.8A.86.86,0,0,1,431.6,47.1Z" fill="#fff"/>
      </g>
    </g>
    <g id="Group_559">
      <g id="Group_558">
        <path id="Path_620" d="M438.9,25.6l-1.2-1.2a.849.849,0,0,0-1.2,1.2h0l1.2,1.2a.913.913,0,0,0,1.2,0,.785.785,0,0,0,0-1.1h0Z" fill="#fff"/>
      </g>
    </g>
    <g id="Group_561">
      <g id="Group_560">
        <path id="Path_621" d="M448.3,24.4a.913.913,0,0,0-1.2,0h0l-1.2,1.2a.815.815,0,0,0,0,1.2h0a.775.775,0,0,0,1,.2s.1,0,.2-.2h0l1.2-1.2A.913.913,0,0,0,448.3,24.4Z" fill="#fff"/>
      </g>
    </g>
    <g id="Group_563">
      <g id="Group_562">
        <path id="Path_622" d="M442.4,21.9a.789.789,0,0,0-.8.8h0v1.7a.789.789,0,0,0,.8.8h0a.86.86,0,0,0,.8-.8V22.7a.789.789,0,0,0-.8-.8h0Z" fill="#fff"/>
      </g>
    </g>
  </g>
  <g id="Group_6695" data-name="Group 6695">
    <path id="Path_8549" data-name="Path 8549" d="M407,102.5V92.4h4.9a2.246,2.246,0,0,1,1.4.6,2.1,2.1,0,0,1,.8,1.1,5.666,5.666,0,0,1,.3,1.5,2.544,2.544,0,0,1-.3,1.5,4.441,4.441,0,0,1-.8,1.1,2.594,2.594,0,0,1-1.4.6h-3.4v3.6H407Zm1.5-5h3.2a1.012,1.012,0,0,0,.7-.4,1.612,1.612,0,0,0,.4-.7c0-.2.1-.5.1-.7a1.7,1.7,0,0,0-.1-.7,2.507,2.507,0,0,0-.4-.7,1.612,1.612,0,0,0-.7-.4h-3.2v3.7h0Z"/>
    <path id="Path_8550" data-name="Path 8550" d="M416,93.7V92.3h1.5v1.4Zm0,8.8V94.9h1.5v7.6Z"/>
    <path id="Path_8551" data-name="Path 8551" d="M422.7,102.7a4.228,4.228,0,0,1-2-.5,2.758,2.758,0,0,1-1.2-1.4,4.638,4.638,0,0,1-.4-2.1,6.537,6.537,0,0,1,.4-2.1,2.539,2.539,0,0,1,1.3-1.4,3.939,3.939,0,0,1,2-.5,3.7,3.7,0,0,1,2.1.6,2.982,2.982,0,0,1,1.2,1.7l-1.5.4a1.834,1.834,0,0,0-.7-1,2.1,2.1,0,0,0-1.1-.4,1.676,1.676,0,0,0-1.2.3,2.178,2.178,0,0,0-.7.9,2.745,2.745,0,0,0-.2,1.3,3.557,3.557,0,0,0,.5,1.9,1.753,1.753,0,0,0,1.6.7c.7,0,.9,0,1.1-.3a2.178,2.178,0,0,0,.7-.9l1.5.3a3.528,3.528,0,0,1-1.2,1.7,3.7,3.7,0,0,1-2.1.6v.2Z"/>
    <path id="Path_8552" data-name="Path 8552" d="M427.5,102.5V92.4H429v6.2l3-3.6h1.9l-3.1,3.8,3.5,3.8h-2L429.1,99v3.6h-1.6Z"/>
    <path id="Path_8553" data-name="Path 8553" d="M438,102.7a4.228,4.228,0,0,1-2-.5,2.949,2.949,0,0,1-1.3-1.4,4.66,4.66,0,0,1,0-4.2,2.949,2.949,0,0,1,1.3-1.4,4.136,4.136,0,0,1,1.9-.5,4.552,4.552,0,0,1,2,.5,4.428,4.428,0,0,1,1.2,1.5,4.175,4.175,0,0,1,.3,2.3h-1.5v-.5a3.09,3.09,0,0,0-.5-1.9,1.755,1.755,0,0,0-1.5-.6,2.06,2.06,0,0,0-1.7.7,2.986,2.986,0,0,0-.6,2,2.546,2.546,0,0,0,.6,1.9,1.865,1.865,0,0,0,1.6.7c.7,0,.9,0,1.2-.3a2.361,2.361,0,0,0,.8-.9l1.4.5a3.435,3.435,0,0,1-1.3,1.6,3.606,3.606,0,0,1-2,.6h.1Zm-2.7-3.5V98.1h5.4v1.1Z"/>
    <path id="Path_8554" data-name="Path 8554" d="M446,102.7a3.453,3.453,0,0,1-1.8-.5,3.326,3.326,0,0,1-1.2-1.4,5.2,5.2,0,0,1,0-4,3.325,3.325,0,0,1,1.2-1.4,3.49,3.49,0,0,1,3.6,0,2.586,2.586,0,0,1,1.1,1.4,6.465,6.465,0,0,1,.4,2,4.477,4.477,0,0,1-.4,2,3.142,3.142,0,0,1-1.1,1.4A2.73,2.73,0,0,1,446,102.7Zm.2-1.3a1.558,1.558,0,0,0,1.2-.3,2.178,2.178,0,0,0,.7-.9,5.9,5.9,0,0,0,.2-1.4,3.178,3.178,0,0,0-.2-1.4,2.178,2.178,0,0,0-.7-.9,2.016,2.016,0,0,0-1.1-.3,1.374,1.374,0,0,0-1.2.4,6.07,6.07,0,0,0-.7,1,2.745,2.745,0,0,0-.2,1.3,3.178,3.178,0,0,0,.2,1.4,3.591,3.591,0,0,0,.7,1,2.144,2.144,0,0,0,1.2.4v-.3Zm2.1,1.1V97.1h-.2V92.5h1.5v10.1h-1.3Z"/>
    <path id="Path_8555" data-name="Path 8555" d="M451.4,98.7V97.5h4.2v1.2Z"/>
    <path id="Path_8556" data-name="Path 8556" d="M461.4,102.7a4.648,4.648,0,0,1-2.1-.5,3.159,3.159,0,0,1-1.4-1.4,4.649,4.649,0,0,1-.5-2.1V92.3h1.5v6.3a1.978,1.978,0,0,0,.2,1.1,1.555,1.555,0,0,0,.6.8,2.178,2.178,0,0,0,.8.5,2.92,2.92,0,0,0,.9.2,1.486,1.486,0,0,0,.9-.2c.3,0,.6-.3.8-.5a5.547,5.547,0,0,0,.6-.8,3.6,3.6,0,0,0,.2-1.1V92.3h1.5v6.4a4.649,4.649,0,0,1-.5,2.1,3.759,3.759,0,0,1-1.4,1.4A4.648,4.648,0,0,1,461.4,102.7Z"/>
    <path id="Path_8557" data-name="Path 8557" d="M467.4,105.9V95h1.3v5.4h.2v5.5Zm3.6-3.2a3.453,3.453,0,0,1-1.8-.5,2.586,2.586,0,0,1-1.1-1.4,6.465,6.465,0,0,1-.4-2,4.477,4.477,0,0,1,.4-2,2.586,2.586,0,0,1,1.1-1.4,3.49,3.49,0,0,1,3.6,0,3.326,3.326,0,0,1,1.2,1.4,5.2,5.2,0,0,1,0,4,3.325,3.325,0,0,1-1.2,1.4A2.73,2.73,0,0,1,471,102.7Zm-.2-1.3c.5,0,.9,0,1.2-.4a2.553,2.553,0,0,0,.7-1,3.178,3.178,0,0,0,.2-1.4,2.745,2.745,0,0,0-.2-1.3,3.591,3.591,0,0,0-.7-1,1.594,1.594,0,0,0-1.2-.4c-.5,0-.8,0-1.1.3a2.178,2.178,0,0,0-.7.9,5,5,0,0,0,0,2.8,1.6,1.6,0,0,0,.7.9,2.133,2.133,0,0,0,1.2.3v.3Z"/>
  </g>
      </svg>
    );
  const statusCancelledDesktop = //Done
    (
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 661.1 103.1">

        <g id="d_s_canelled">
          <g id="Group_4698" opacity=".6">
            <line id="Line_65" x1="436.9" y1="39.1" x2="590.8" y2="39.1" fill="none" stroke="#bfbfbf"/>
            <line id="Line_64" x1="60.2" y1="39.1" x2="157" y2="39.1" fill="none" stroke="#2ec278" stroke-opacity="0"/>
            <line id="Line_59" x1="69.5" y1="39.1" x2="202.5" y2="39.1" fill="none" stroke="#bfbfbf"/>
            <path id="Path_666" d="M231.6,39.1h170.4" fill="none" stroke="#bfbfbf"/>
            <path id="Path_665" d="M38.5,0c21.3,0,38.5,17.2,38.5,38.5s-17.2,38.5-38.5,38.5S0,59.8,0,38.5,17.2,0,38.5,0Z" fill="#ffd5d5" fill-opacity="0"/>
            <circle id="Ellipse_82" cx="38.5" cy="38.5" r="30.5" fill="#2ec278"/>
            <g id="Group_552">
              <circle id="Ellipse_88" cx="233.5" cy="38.5" r="38.5" fill="#acffd6" fill-opacity="0"/>
              <path id="Path_638" d="M233.4,8.2c16.7,0,30.3,13.6,30.3,30.3s-13.6,30.3-30.3,30.3-30.3-13.6-30.3-30.3c0-16.7,13.6-30.3,30.3-30.3h0Z" fill="#f1f1f1"/>
            </g>
            <g id="Group_550">
              <circle id="Ellipse_89" cx="423.5" cy="38.5" r="38.5" fill="#ffeaaf" fill-opacity="0"/>
              <circle id="Ellipse_84" cx="423.5" cy="38.5" r="30.5" fill="#f1f1f1"/>
            </g>
            <g id="verified-24px">
              <g id="Group_564">
                <rect id="Rectangle_235" x="25" y="26" width="28" height="28" fill="none"/>
              </g>
              <g id="Group_565">
                <path id="Path_623" d="M51.5,39.6l-2.8-3.3.4-4.3-4.2-1-2.2-3.7-4,1.7-4-1.7-2.2,3.7-4.2.9.4,4.3-2.9,3.3,2.8,3.3-.4,4.3,4.2,1,2.2,3.7,4-1.7,4,1.7,2.2-3.7,4.2-1-.4-4.3,2.9-3.3ZM36.4,45.2l-4.4-4.4,1.7-1.7,2.7,2.7,6.8-6.8,1.7,1.7-8.5,8.6Z" fill="#fff"/>
              </g>
            </g>
            <g id="inventory_2_black_24dp">
              <g id="Group_4910">
                <rect id="Rectangle_1309" x="221" y="27" width="26" height="26" fill="none"/>
              </g>
              <g id="Group_4911">
                <path id="Path_3926" d="M242.3,29.6h-16.6c-1.1,0-2,.9-2.1,2.1v3.1c0,.7.4,1.4,1,1.8v11.8c0,1.1,1,2,2.1,2.1h14.6c1.1,0,2-1,2.1-2.1v-11.8c.6-.4,1-1,1-1.8v-3.1c0-1.1-.9-2-2.1-2.1ZM237.1,42.1h-6.2v-2.1h6.2v2.1ZM242.3,34.8h-16.6v-3.1h16.6v3.1Z" fill="#ddd"/>
              </g>
            </g>
            <g id="Group_5034">
              <g id="Group_5030">
                <path id="Path_4200" d="M429.3,39.5c-3.1,0-5.7,2.5-5.7,5.7,0,3.1,2.5,5.7,5.7,5.7,3.1,0,5.7-2.5,5.7-5.7,0,0,0,0,0,0,0-3.1-2.5-5.7-5.7-5.7h0M428.2,48l-2.3-2.3.8-.8,1.5,1.5,3.7-3.7.8.8-4.5,4.5Z" fill="#dfdfdf"/>
                <path id="Path_4201" d="M421.9,45.1c0-4.1,3.3-7.4,7.4-7.4.9,0,1.9.2,2.8.5v-2.6c.6-.3.9-.9.9-1.6v-2.8c0-1-.9-1.8-1.9-1.9h-15.1c-1,0-1.8.9-1.9,1.9v2.8c0,.7.4,1.3.9,1.6v10.6c0,1,.9,1.8,1.9,1.9h5.5c-.4-1-.7-2-.7-3.1M416.1,31.2h15.1v2.8h-15.1v-2.8Z" fill="#dfdfdf"/>
              </g>
            </g>
            <g>
              <path d="M12,90.2l2.9-8.6h1.5l2.9,8.6h-1.1l-2.7-8h.3l-2.7,8h-1.1ZM13.4,88.2v-1h4.5v1h-4.5Z" fill="#c9c9c9"/>
              <path d="M23.1,90.4c-.7,0-1.2,0-1.7-.4s-.8-.7-1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2c0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5h-.3,0Z" fill="#c9c9c9"/>
              <path d="M29.6,90.4c-.7,0-1.2,0-1.7-.4s-.8-.7-1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2c0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5h-.3,0Z" fill="#c9c9c9"/>
              <path d="M36.2,90.4c-.6,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8,0-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8s-.8-.6-1.4-.6-1.2.2-1.5.6-.5,1-.5,1.8.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3s-1.1.5-1.7.5h.1ZM33.8,87.3v-.9h4.8v.9h-4.8Z" fill="#c9c9c9"/>
              <path d="M40.3,93.1v-9.4h1v4.8h0v4.6h-1.1,0ZM43.3,90.4c-.6,0-1.1-.2-1.5-.5s-.7-.7-.9-1.2-.3-1.1-.3-1.7,0-1.2.3-1.7.5-.9.9-1.2c.4-.3.9-.4,1.5-.4s1.1,0,1.6.4.8.7,1,1.2.3,1.1.3,1.7,0,1.2-.3,1.7-.6.9-1,1.2c-.4.3-1,.4-1.6.4h0ZM43.2,89.4c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.2,0-.9-.2-1.2c0-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8,0-1,.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.3,0,.9.2,1.3c0,.4.3.7.6.9.3.2.6.3,1.1.3v-.2h0Z" fill="#c9c9c9"/>
              <path d="M46.8,84.6v-.9h4.3v.9h-4.3ZM51.2,90.2h-1.1c-.4,0-.7,0-1-.2s-.5-.4-.7-.7-.2-.5-.2-.8v-6.7h1.1v7c0,.3.4.4.7.5s.7,0,1.1,0v.9h.1Z" fill="#c9c9c9"/>
              <path d="M55,90.4c-.6,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8,0-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8s-.8-.6-1.4-.6-1.2.2-1.5.6-.5,1-.5,1.8.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3s-1.1.5-1.7.5h.1ZM52.6,87.3v-.9h4.8v.9h-4.8Z" fill="#c9c9c9"/>
              <path d="M61.8,90.4c-.6,0-1.1,0-1.6-.4-.4-.3-.8-.7-1-1.2s-.4-1.1-.4-1.7,0-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1,0,1.5.4.7.7.9,1.2c.2.5.3,1.1.3,1.7s0,1.2-.3,1.7-.5.9-.9,1.2c-.4.3-.9.5-1.5.5h0ZM62,89.4c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.3,0-.9-.2-1.3c0-.4-.3-.7-.6-.9s-.6-.3-1-.3-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2,0,.9.2,1.2c0,.4.4.7.6.9.3.2.6.3,1.1.3v.2h0ZM63.9,90.2v-4.8h0v-3.9h1.1v8.6h-1.1Z" fill="#c9c9c9"/>
            </g>
            <g>
              <path d="M406.5,90.2v-8.6h4c.5,0,.9.2,1.2.5s.6.6.7.9c.2.4.2.8.2,1.3s-.2,1.3-.5,1.7c-.3.5-.9.8-1.5.9h-3.1v3.2h-1.1.1,0ZM407.6,86h2.9c.3,0,.5-.2.7-.4.2-.2.3-.4.4-.6s.1-.5.1-.7,0-.5-.1-.7-.2-.4-.4-.6c-.2-.2-.4-.3-.7-.4h-2.9s0,3.4,0,3.4ZM411.7,90.2l-1.7-3.5,1.1-.3,1.9,3.8h-1.3Z" fill="#c9c9c9"/>
              <path d="M417,90.4c-.6,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2c-.3-.5-.4-1.1-.4-1.8s.1-1.3.4-1.8.6-.9,1.1-1.2c.5-.3,1-.4,1.6-.4s1.2.2,1.7.4c.5.3.8.7,1,1.3.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8s-.8-.6-1.4-.6-1.2.2-1.5.6-.5,1-.5,1.8.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3s-1.1.5-1.7.5h.1ZM414.6,87.3v-.9h4.8v.9h-4.8Z" fill="#c9c9c9"/>
              <path d="M423,90.4c-.5,0-.9,0-1.2-.3s-.6-.4-.7-.7c-.2-.3-.2-.6-.2-1s0-.6.2-.9.3-.5.5-.6.5-.3.8-.4c.3,0,.7-.2,1-.2s.8,0,1.2-.2c.4,0,.8,0,1.1-.2l-.4.2c0-.6-.1-1-.3-1.3-.2-.3-.7-.4-1.2-.4s-.7,0-1,.3-.5.5-.6.9l-1-.3c.2-.6.5-1,.9-1.3s1-.5,1.8-.5,1.1,0,1.5.3c.4.2.7.5.9,1,0,.2.2.4.2.7v4.7h-.9v-1.6h.2c-.2.7-.6,1.1-1,1.3-.4.3-1,.4-1.6.4h-.2,0ZM423.2,89.5c.4,0,.7,0,1-.2.3,0,.5-.3.7-.5s.3-.5.3-.7v-1.3l.4.2h-2c-.3,0-.6,0-.8.2-.2,0-.3,0-.5.2s-.3.2-.4.3-.1.3-.1.5,0,.3.1.5.2.3.4.4c.2,0,.4.2.7.2h.2v.2Z" fill="#c9c9c9"/>
              <path d="M430.5,90.4c-.6,0-1.1-.2-1.6-.4-.4-.3-.8-.7-1-1.2s-.4-1.1-.4-1.7.1-1.2.4-1.7c.2-.5.6-.9,1-1.2.4-.3.9-.4,1.5-.4s1.1,0,1.5.4.7.7.9,1.2c.2.5.3,1.1.3,1.8s-.1,1.2-.3,1.7c-.2.5-.5.9-.9,1.2s-.9.5-1.5.5v-.2h.1ZM430.6,89.4c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.3,0-.9-.2-1.3c-.1-.4-.3-.7-.6-.9-.3-.2-.6-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2,0,.9.2,1.2c.1.4.4.7.6.9.3.2.6.3,1.1.3v.2h-.1ZM432.5,90.2v-4.8h-.1v-3.9h1.1v8.6h-1Z" fill="#c9c9c9"/>
              <path d="M436,93.1l1.3-3.4v1l-2.8-7h1.1l2.2,5.6h-.4l2.1-5.6h1.1l-3.5,9.4h-1.1Z" fill="#c9c9c9"/>
            </g>
            <g>
              <path d="M212.3,90v-8.6h4c.5,0,.9.2,1.2.5.3.2.6.6.7.9.2.4.2.8.2,1.3s0,.9-.2,1.3-.4.7-.7.9-.7.4-1.2.5h-2.9v3.2h-1.1ZM213.4,85.8h2.9c.3,0,.5-.2.7-.4.2-.2.3-.4.4-.6s.1-.5.1-.7,0-.5-.1-.7c0-.2-.2-.4-.4-.6s-.4-.3-.7-.4h-2.9s0,3.4,0,3.4Z" fill="#c9c9c9"/>
              <path d="M221.3,90.2c-.5,0-.9,0-1.2-.3-.3-.2-.6-.4-.7-.7s-.2-.6-.2-1,0-.6.2-.9.3-.5.5-.6c.2-.2.5-.3.8-.4.3,0,.7-.2,1-.2s.8,0,1.2-.2c.4,0,.8,0,1.1-.2l-.4.2c0-.6-.1-1-.3-1.3s-.7-.4-1.2-.4-.7,0-1,.3c-.3.2-.5.5-.6.9l-1-.3c.2-.6.5-1,.9-1.3s1-.5,1.8-.5,1.1,0,1.5.3c.4.2.7.5.9,1,0,.2.2.4.2.7v4.7h-.9v-1.6h.2c-.2.7-.6,1.1-1,1.3-.4.3-1,.4-1.6.4h-.2ZM221.5,89.3c.4,0,.7,0,1-.2.3,0,.5-.3.7-.5s.3-.5.3-.7v-1.3l.4.2h-2c-.3,0-.6,0-.8.2-.2,0-.3,0-.5.2-.2,0-.3.2-.4.3,0,0-.1.3-.1.5s0,.3.1.5c0,.2.2.3.4.4s.4.2.7.2h.2v.2Z" fill="#c9c9c9"/>
              <path d="M229,90.2c-.7,0-1.2,0-1.7-.4s-.8-.7-1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2c0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5h-.3Z" fill="#c9c9c9"/>
              <path d="M233,90v-8.6h1.1v5.3l2.7-3.1h1.4l-2.9,3.2,3.2,3.2h-1.5l-2.9-3.1v3.1h-1.1Z" fill="#c9c9c9"/>
              <path d="M239.2,82.4v-1.1h1.1v1.1h-1.1ZM239.2,90v-6.5h1.1v6.5h-1.1Z" fill="#c9c9c9"/>
              <path d="M242.1,90v-6.5h1v1.7h.1v4.7h-1.1ZM246.7,90v-4.1c0-.3-.1-.5-.3-.8-.1-.2-.3-.4-.5-.5s-.5-.2-.9-.2h-.7c-.2,0-.4.2-.6.4-.2.2-.3.4-.4.7,0,.3-.1.6-.1,1l-.7-.2c0-.6.1-1.2.3-1.6.2-.5.5-.8,1-1s.9-.4,1.4-.4.8,0,1.1.2.5.3.7.5.4.5.5.7c.1.3.2.5.2.8v4.4h-1.1.1Z" fill="#c9c9c9"/>
              <path d="M251.9,90.2c-.6,0-1.1,0-1.6-.4-.4-.3-.8-.7-1-1.2s-.4-1.1-.4-1.7.1-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1,0,1.5.4.7.7.9,1.2.3,1.1.3,1.7-.1,1.2-.3,1.7-.5.9-.9,1.2c-.4.3-.9.5-1.5.5h.1ZM252,93.1c-.3,0-.7,0-1-.2-.3,0-.6-.3-.9-.5s-.5-.5-.7-.8l1-.5c.2.3.4.5.7.7.3,0,.6.2.9.2s.8,0,1-.2c.3,0,.5-.4.6-.7.1-.3.2-.7.2-1.1v-1.8h.1v-4.7h.9v7.5c0,.5-.3.8-.5,1.1-.2.3-.6.5-1,.7-.4,0-.8.2-1.4.2h0ZM252,89.2c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.3,0-.9-.2-1.3c-.1-.4-.3-.7-.6-.9s-.6-.3-1-.3-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2,0,.9.2,1.2c.1.4.4.7.6.9.3.2.6.3,1.1.3v.2h-.1Z" fill="#c9c9c9"/>
            </g>
          </g>
          <line id="Line_60" x1="562.8" y1="39.1" x2="617.6" y2="39.1" fill="none" stroke="#d4d4d4" stroke-opacity="0"/>
          <g id="Group_551">
            <circle id="Ellipse_90" cx="620.5" cy="38.5" r="38.5" fill="#ffd5d5"/>
            <circle id="Ellipse_85" cx="620.5" cy="38.5" r="30.5" fill="#f55353"/>
          </g>
          <circle id="Ellipse_87" cx="582" cy="40" r="5" fill="#bfbfbf"/>
          <g id="Group_4697">
            <path id="Path_3791" d="M612.7,30l16.6,16.6" fill="none" stroke="#fff"/>
            <path id="Path_3792" d="M629.3,30l-16.6,16.6" fill="none" stroke="#fff"/>
          </g>
          <g>
            <path d="M592.1,98.8c-1,0-1.8-.2-2.5-.7-.7-.4-1.2-1-1.6-1.8-.4-.8-.6-1.7-.6-2.7s.2-1.9.6-2.7c.4-.8.9-1.4,1.6-1.8.7-.4,1.6-.7,2.5-.7s2.1.3,2.9.9,1.3,1.3,1.6,2.3l-1.7.5c-.2-.6-.5-1.2-1-1.5-.5-.4-1.1-.5-1.8-.5s-1.2.1-1.7.4c-.4.3-.8.7-1,1.3s-.3,1.2-.3,1.9c0,.7.1,1.4.3,1.9s.6,1,1,1.3c.4.3,1,.4,1.7.4s1.3-.2,1.8-.5.8-.9,1-1.5l1.7.5c-.3,1-.8,1.7-1.6,2.3s-1.7.9-2.9.9Z"/>
            <path d="M600.2,98.8c-.5,0-1-.1-1.4-.3-.4-.2-.7-.5-.8-.8-.2-.3-.3-.7-.3-1.1s0-.7.2-1c.1-.3.3-.5.6-.7s.6-.4,1-.5c.3,0,.6-.2,1-.3.4,0,.8-.2,1.3-.2.5,0,1-.1,1.5-.2l-.6.3c0-.5-.1-.9-.3-1.1-.2-.2-.6-.4-1.2-.4s-.7,0-1,.2c-.3.2-.5.4-.7.8l-1.5-.5c.2-.6.5-1.1,1-1.5.5-.4,1.2-.6,2.1-.6s1.2.1,1.7.3c.5.2.9.6,1.1,1.1.1.3.2.5.2.8,0,.3,0,.6,0,.9v4.6h-1.5v-1.6l.2.3c-.3.5-.7.9-1.2,1.2s-1,.4-1.7.4ZM600.5,97.5c.4,0,.7,0,1-.2.3-.1.5-.3.6-.5.2-.2.3-.4.3-.5,0-.2.1-.5.1-.7,0-.3,0-.5,0-.7l.5.2c-.5,0-.9.1-1.3.2-.4,0-.7.1-.9.2-.3,0-.5.1-.7.2-.2,0-.4.1-.5.2s-.2.2-.3.3c0,.1-.1.3-.1.4s0,.3.1.5c0,.1.2.3.4.3.2,0,.4.1.7.1Z"/>
            <path d="M606,98.6v-7.4h1.5v2.3h.2v5.1h-1.7ZM611.2,98.6v-3.6c0-.2,0-.5,0-.8,0-.3-.1-.6-.2-.8-.1-.3-.3-.5-.5-.7s-.5-.3-.9-.3-.4,0-.6.1c-.2,0-.4.2-.6.4-.2.2-.3.4-.4.7-.1.3-.2.7-.2,1.1l-1-.4c0-.6.1-1.2.4-1.7.2-.5.6-.9,1.1-1.2.5-.3,1.1-.5,1.8-.5s1,0,1.4.3c.4.2.6.4.9.7s.4.6.5.9c.1.3.2.6.2.9,0,.3,0,.5,0,.7v4.2h-1.7Z"/>
            <path d="M617.7,98.8c-.8,0-1.4-.2-2-.5-.5-.3-1-.8-1.2-1.4-.3-.6-.4-1.3-.4-2,0-.8.2-1.4.5-2,.3-.6.7-1.1,1.3-1.4.5-.3,1.2-.5,1.9-.5s1.6.2,2.1.6c.6.4,1,1,1.1,1.7l-1.7.4c-.1-.4-.3-.7-.6-.9-.3-.2-.6-.3-1-.3s-.8.1-1.1.3-.5.5-.6.8c-.1.4-.2.8-.2,1.2,0,.7.2,1.3.5,1.7.3.4.8.7,1.4.7s.8-.1,1-.3c.3-.2.4-.5.6-.9l1.7.4c-.2.8-.6,1.3-1.2,1.7-.6.4-1.3.6-2.1.6Z"/>
            <path d="M625.6,98.8c-.8,0-1.4-.2-2-.5-.6-.3-1-.8-1.3-1.4-.3-.6-.5-1.2-.5-2s.2-1.5.5-2.1c.3-.6.7-1.1,1.3-1.4.6-.3,1.2-.5,1.9-.5s1.4.2,2,.5c.5.4.9.9,1.2,1.5s.4,1.4.3,2.3h-1.6v-.6c0-.8-.1-1.4-.4-1.8-.3-.4-.7-.6-1.3-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.3.5,1.7c.3.4.8.6,1.4.6s.8,0,1.1-.3c.3-.2.5-.4.7-.8l1.6.5c-.3.7-.7,1.2-1.3,1.6-.6.4-1.2.6-2,.6ZM623,95.4v-1.3h5.1v1.3h-5.1Z"/>
            <path d="M630.6,98.6v-10.1h1.7v10.1h-1.7Z"/>
            <path d="M634.5,98.6v-10.1h1.7v10.1h-1.7Z"/>
            <path d="M641.6,98.8c-.8,0-1.4-.2-2-.5-.6-.3-1-.8-1.3-1.4-.3-.6-.5-1.2-.5-2s.2-1.5.5-2.1c.3-.6.7-1.1,1.3-1.4.6-.3,1.2-.5,1.9-.5s1.4.2,2,.5c.5.4.9.9,1.2,1.5s.4,1.4.3,2.3h-1.6v-.6c0-.8-.1-1.4-.4-1.8-.3-.4-.7-.6-1.3-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.3.5,1.7c.3.4.8.6,1.4.6s.8,0,1.1-.3c.3-.2.5-.4.7-.8l1.6.5c-.3.7-.7,1.2-1.3,1.6-.6.4-1.2.6-2,.6ZM639,95.4v-1.3h5.1v1.3h-5.1Z"/>
            <path d="M649.5,98.8c-.7,0-1.3-.2-1.8-.5-.5-.3-.9-.8-1.2-1.4-.3-.6-.4-1.3-.4-2s.1-1.4.4-2,.7-1.1,1.2-1.4,1.1-.5,1.8-.5,1.3.2,1.8.5.8.8,1.1,1.4c.2.6.4,1.3.4,2s-.1,1.4-.4,2c-.2.6-.6,1.1-1.1,1.4-.5.3-1.1.5-1.8.5ZM649.8,97.4c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.3,0-.9-.2-1.3c-.1-.4-.3-.6-.6-.9-.3-.2-.6-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2s0,.9.2,1.2c.1.4.3.7.6.9s.6.3,1.1.3ZM651.6,98.6v-5.2h-.2v-4.7h1.7v9.9h-1.5Z"/>
          </g>
        </g>
      </svg>
    );
  const statusCancelledMobile = // Done
    (
      <svg id="statusCancelledMobile" xmlns="http://www.w3.org/2000/svg" width="477" height="99" viewBox="0 0 477 99">
      <g id="Group_4698" opacity="0.6">
        <line id="Line_65" x2="91.9" transform="translate(316.9 39.1)" fill="none" stroke="#bfbfbf" stroke-width="1"/>
        <line id="Line_64" x2="36.8" transform="translate(60.2 39.1)" fill="none"/>
        <line id="Line_59" x2="73" transform="translate(69.5 39.1)" fill="none" stroke="#bfbfbf" stroke-width="1"/>
        <path id="Path_666" d="M171.6,39.1H282" fill="none" stroke="#bfbfbf" stroke-width="1"/>
        <path id="Path_665" d="M38.5,0a38.45,38.45,0,1,1-.1,0h0Z" opacity="0"/>
        <circle id="Ellipse_82" cx="30.5" cy="30.5" r="30.5" transform="translate(8 8)" fill="#2ec278"/>
        <g id="Group_552">
          <circle id="Ellipse_88" cx="38.5" cy="38.5" r="38.5" transform="translate(135)" opacity="0"/>
          <path id="Path_638" d="M173.4,8.2a30.3,30.3,0,1,1-30.3,30.3A30.344,30.344,0,0,1,173.4,8.2h0Z" fill="#f1f1f1"/>
        </g>
        <g id="Group_550">
          <circle id="Ellipse_89" cx="38.5" cy="38.5" r="38.5" transform="translate(265)" opacity="0"/>
          <circle id="Ellipse_84" cx="30.5" cy="30.5" r="30.5" transform="translate(273 8)" fill="#f1f1f1"/>
        </g>
        <g id="verified-24px">
          <g id="Group_564">
            <rect id="Rectangle_235" width="28" height="28" transform="translate(25 26)" fill="none"/>
          </g>
          <g id="Group_565">
            <path id="Path_623" d="M51.5,39.6l-2.8-3.3.4-4.3-4.2-1-2.2-3.7-4,1.7-4-1.7L32.5,31l-4.2.9.4,4.3-2.9,3.3,2.8,3.3-.4,4.3,4.2,1,2.2,3.7,4-1.7,4,1.7,2.2-3.7,4.2-1-.4-4.3,2.9-3.3h0ZM36.4,45.2,32,40.8l1.7-1.7,2.7,2.7L43.2,35l1.7,1.7-8.5,8.6h0Z" fill="#fff"/>
          </g>
        </g>
        <g id="inventory_2_black_24dp">
          <g id="Group_4910">
            <rect id="Rectangle_1309" width="26" height="26" transform="translate(161 27)" fill="none"/>
          </g>
          <g id="Group_4911">
            <path id="Path_3926" d="M182.3,29.6H165.7a2.182,2.182,0,0,0-2.1,2.1v3.1a2.234,2.234,0,0,0,1,1.8V48.4a2.258,2.258,0,0,0,2.1,2.1h14.6a2.258,2.258,0,0,0,2.1-2.1V36.6a2.234,2.234,0,0,0,1-1.8V31.7a2.182,2.182,0,0,0-2.1-2.1Zm-5.2,12.5h-6.2V40h6.2Zm5.2-7.3H165.7V31.7h16.6Z" fill="#ddd"/>
          </g>
        </g>
        <g id="Group_5034">
          <g id="Group_8219">
            <g id="Group_5030">
              <path id="Path_4200" d="M309.3,39.5a5.7,5.7,0,1,0,5.7,5.7h0a5.763,5.763,0,0,0-5.7-5.7h0M308.2,48l-2.3-2.3.8-.8,1.5,1.5,3.7-3.7.8.8Z" fill="#dfdfdf"/>
              <path id="Path_4201" d="M301.9,45.1a7.383,7.383,0,0,1,7.4-7.4h0a8.675,8.675,0,0,1,2.8.5V35.6A1.7,1.7,0,0,0,313,34V31.2a2.043,2.043,0,0,0-1.9-1.9H296a2.043,2.043,0,0,0-1.9,1.9V34a1.7,1.7,0,0,0,.9,1.6V46.2a2.043,2.043,0,0,0,1.9,1.9h5.5a8.245,8.245,0,0,1-.7-3.1m-5.6-13.8h15.1V34H296.1Z" fill="#dfdfdf"/>
            </g>
          </g>
        </g>
        <g id="Group_6688" data-name="Group 6688">
          <path id="Path_8500" data-name="Path 8500" d="M11.8,90.1l2.9-8.6h1.5l2.9,8.6H18l-2.7-8h.3l-2.7,8Zm1.4-2v-1h4.5v1H13.2Z" fill="#bfbfbf"/>
          <path id="Path_8501" data-name="Path 8501" d="M22.9,90.3a3.064,3.064,0,0,1-1.7-.4,3.179,3.179,0,0,1-1.1-1.2,4.776,4.776,0,0,1-.4-1.8,3.425,3.425,0,0,1,.4-1.8,2.545,2.545,0,0,1,1.1-1.2,3.064,3.064,0,0,1,1.7-.4,3.453,3.453,0,0,1,1.8.5,2.434,2.434,0,0,1,1,1.4l-1.1.3a1.6,1.6,0,0,0-.7-.9,1.929,1.929,0,0,0-1-.3,2.279,2.279,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.86,2.86,0,0,0,.5,1.7,1.806,1.806,0,0,0,1.5.7c.7,0,.8,0,1.1-.3a2.1,2.1,0,0,0,.6-.8l1.1.3a2.981,2.981,0,0,1-1,1.4,3.1,3.1,0,0,1-1.7.5Z" fill="#bfbfbf"/>
          <path id="Path_8502" data-name="Path 8502" d="M29.4,90.3a3.064,3.064,0,0,1-1.7-.4,3.179,3.179,0,0,1-1.1-1.2,4.776,4.776,0,0,1-.4-1.8,3.425,3.425,0,0,1,.4-1.8,2.545,2.545,0,0,1,1.1-1.2,3.064,3.064,0,0,1,1.7-.4,3.453,3.453,0,0,1,1.8.5,2.434,2.434,0,0,1,1,1.4l-1.1.3a1.6,1.6,0,0,0-.7-.9,1.929,1.929,0,0,0-1-.3,2.279,2.279,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.86,2.86,0,0,0,.5,1.7,1.806,1.806,0,0,0,1.5.7c.7,0,.8,0,1.1-.3a2.1,2.1,0,0,0,.6-.8l1.1.3a2.981,2.981,0,0,1-1,1.4,3.1,3.1,0,0,1-1.7.5Z" fill="#bfbfbf"/>
          <path id="Path_8503" data-name="Path 8503" d="M36,90.3a3.287,3.287,0,0,1-1.7-.4,3.179,3.179,0,0,1-1.1-1.2,4.25,4.25,0,0,1,0-3.6,3.179,3.179,0,0,1,1.1-1.2,2.934,2.934,0,0,1,1.6-.4,3.371,3.371,0,0,1,1.7.5,2.209,2.209,0,0,1,1,1.3,6.013,6.013,0,0,1,.3,2H37.8v-.4a2.969,2.969,0,0,0-.5-1.8,1.678,1.678,0,0,0-1.4-.6,1.887,1.887,0,0,0-1.5.6,3.49,3.49,0,0,0,0,3.6,1.755,1.755,0,0,0,1.5.6c.7,0,.8,0,1.1-.3a1.779,1.779,0,0,0,.7-.8l1,.4a2.85,2.85,0,0,1-1.1,1.3,3.371,3.371,0,0,1-1.7.5Zm-2.4-3.1v-.9h4.8v.9Z" fill="#bfbfbf"/>
          <path id="Path_8504" data-name="Path 8504" d="M40.1,93V83.6h1v4.8h.1V93Zm3-2.7a2.456,2.456,0,0,1-1.5-.5,2.73,2.73,0,0,1-.9-1.2,4.967,4.967,0,0,1,0-3.4,2.73,2.73,0,0,1,.9-1.2,2.41,2.41,0,0,1,1.5-.4,2.587,2.587,0,0,1,2.6,1.6,4.967,4.967,0,0,1,0,3.4,2.94,2.94,0,0,1-1,1.2,2.726,2.726,0,0,1-1.6.4Zm-.1-1a2.016,2.016,0,0,0,1.1-.3,1.456,1.456,0,0,0,.6-.9,5.019,5.019,0,0,0,.2-1.2,2.345,2.345,0,0,0-.2-1.2,2.034,2.034,0,0,0-.6-.9,1.8,1.8,0,0,0-1.1-.3,1.327,1.327,0,0,0-1,.3,1.456,1.456,0,0,0-.6.9,5.067,5.067,0,0,0-.2,1.3,2.745,2.745,0,0,0,.2,1.3,1.456,1.456,0,0,0,.6.9,1.8,1.8,0,0,0,1.1.3Z" fill="#bfbfbf"/>
          <path id="Path_8505" data-name="Path 8505" d="M46.6,84.5v-.9h4.3v.9ZM51,90.1a4.034,4.034,0,0,1-1.1,0,1.645,1.645,0,0,1-1-.2,1.7,1.7,0,0,1-.9-1.5V81.7h1.1v6.5a.749.749,0,0,0,.1.5c.1.3.4.4.7.5H51v.9Z" fill="#bfbfbf"/>
          <path id="Path_8506" data-name="Path 8506" d="M54.8,90.3a3.287,3.287,0,0,1-1.7-.4A3.179,3.179,0,0,1,52,88.7a4.25,4.25,0,0,1,0-3.6,3.179,3.179,0,0,1,1.1-1.2,2.934,2.934,0,0,1,1.6-.4,3.371,3.371,0,0,1,1.7.5,2.209,2.209,0,0,1,1,1.3,6.013,6.013,0,0,1,.3,2H56.6v-.4a2.969,2.969,0,0,0-.5-1.8,1.678,1.678,0,0,0-1.4-.6,1.887,1.887,0,0,0-1.5.6,3.49,3.49,0,0,0,0,3.6,1.755,1.755,0,0,0,1.5.6c.7,0,.8,0,1.1-.3a1.779,1.779,0,0,0,.7-.8l1,.4a2.85,2.85,0,0,1-1.1,1.3,3.371,3.371,0,0,1-1.7.5Zm-2.4-3.1v-.9h4.8v.9Z" fill="#bfbfbf"/>
          <path id="Path_8507" data-name="Path 8507" d="M61.6,90.3A2.587,2.587,0,0,1,59,88.7a4.711,4.711,0,0,1-.4-1.7,3.287,3.287,0,0,1,.4-1.7,2.94,2.94,0,0,1,1-1.2,3.012,3.012,0,0,1,3,0,2.73,2.73,0,0,1,.9,1.2,4.967,4.967,0,0,1,0,3.4,2.73,2.73,0,0,1-.9,1.2,2.456,2.456,0,0,1-1.5.5Zm.2-1a2.016,2.016,0,0,0,1.1-.3,1.456,1.456,0,0,0,.6-.9,5.067,5.067,0,0,0,.2-1.3,2.745,2.745,0,0,0-.2-1.3,2.034,2.034,0,0,0-.6-.9,1.689,1.689,0,0,0-1-.3,2.016,2.016,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.345,2.345,0,0,0,.2,1.2,2.034,2.034,0,0,0,.6.9,1.609,1.609,0,0,0,1.1.3Zm1.9.8V85.3h-.1V81.4h1.1V90h-1Z" fill="#bfbfbf"/>
        </g>
        <g id="Group_6689" data-name="Group 6689">
          <path id="Path_8508" data-name="Path 8508" d="M152.2,90.1V81.5h4a1.656,1.656,0,0,1,1.2.5,2.016,2.016,0,0,1,.7.9,4.439,4.439,0,0,1,.2,1.3,2.745,2.745,0,0,1-.2,1.3,2.178,2.178,0,0,1-.7.9,3.337,3.337,0,0,1-1.2.5h-2.9v3.2h-1.1Zm1.1-4.2h2.9a1.012,1.012,0,0,0,.7-.4,2.092,2.092,0,0,0,.4-.6,2.5,2.5,0,0,0,0-1.4c0-.2-.2-.4-.4-.6a1.612,1.612,0,0,0-.7-.4h-2.9v3.4Z" fill="#bfbfbf"/>
          <path id="Path_8509" data-name="Path 8509" d="M161.2,90.3A1.558,1.558,0,0,1,160,90c-.3-.3-.6-.4-.7-.7a2.959,2.959,0,0,1-.2-1,1.344,1.344,0,0,1,.2-.9c.2-.3.3-.5.5-.6a2.01,2.01,0,0,1,.8-.4c.3,0,.7-.2,1-.2a5.019,5.019,0,0,0,1.2-.2,3.6,3.6,0,0,0,1.1-.2l-.4.2a2.269,2.269,0,0,0-.3-1.3,1.461,1.461,0,0,0-1.2-.4c-.5,0-.7,0-1,.3a1.456,1.456,0,0,0-.6.9l-1-.3a2.54,2.54,0,0,1,.9-1.3,2.969,2.969,0,0,1,1.8-.5,2.81,2.81,0,0,1,1.5.3,1.789,1.789,0,0,1,.9,1,2.544,2.544,0,0,0,.2.7v4.7h-.9V88.5h.2a1.994,1.994,0,0,1-1,1.3,2.726,2.726,0,0,1-1.6.4Zm.2-.9a1.645,1.645,0,0,0,1-.2,4.233,4.233,0,0,0,.7-.5,1.08,1.08,0,0,0,.3-.7V86.7l.4.2c-.3,0-.7.1-1,.1a3.4,3.4,0,0,0-1,.1,1.884,1.884,0,0,0-.8.2c-.2,0-.3.1-.5.2-.2,0-.3.2-.4.3a4.331,4.331,0,0,0-.1.5.9.9,0,0,0,.1.5.781.781,0,0,0,.4.4,1.445,1.445,0,0,0,.7.2Z" fill="#bfbfbf"/>
          <path id="Path_8510" data-name="Path 8510" d="M168.9,90.3a3.064,3.064,0,0,1-1.7-.4,3.179,3.179,0,0,1-1.1-1.2,4.776,4.776,0,0,1-.4-1.8,3.425,3.425,0,0,1,.4-1.8,2.544,2.544,0,0,1,1.1-1.2,3.064,3.064,0,0,1,1.7-.4,3.453,3.453,0,0,1,1.8.5,2.434,2.434,0,0,1,1,1.4l-1.1.3a1.6,1.6,0,0,0-.7-.9,1.929,1.929,0,0,0-1-.3,2.279,2.279,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.86,2.86,0,0,0,.5,1.7,1.806,1.806,0,0,0,1.5.7c.7,0,.8,0,1.1-.3a2.1,2.1,0,0,0,.6-.8l1.1.3a2.981,2.981,0,0,1-1,1.4,3.1,3.1,0,0,1-1.7.5Z" fill="#bfbfbf"/>
          <path id="Path_8511" data-name="Path 8511" d="M172.9,90.1V81.5H174v5.3l2.7-3.1h1.4l-2.9,3.2,3.2,3.2h-1.5L174,87v3.1Z" fill="#bfbfbf"/>
          <path id="Path_8512" data-name="Path 8512" d="M179.1,82.5V81.4h1.1v1.1Zm0,7.6V83.6h1.1v6.5Z" fill="#bfbfbf"/>
          <path id="Path_8513" data-name="Path 8513" d="M182,90.1V83.6h1v1.7h.1V90H182Zm4.6,0V86c0-.3-.2-.5-.3-.8a1.205,1.205,0,0,0-.5-.5,2,2,0,0,0-.9-.2,1.285,1.285,0,0,0-.7.1,2.092,2.092,0,0,0-.6.4,1.612,1.612,0,0,0-.4.7c0,.3-.1.6-.1,1l-.7-.2a3.808,3.808,0,0,1,.3-1.6,1.651,1.651,0,0,1,1-1,3.708,3.708,0,0,1,1.4-.4,1.815,1.815,0,0,1,1.1.2,4.233,4.233,0,0,1,.7.5,3.039,3.039,0,0,1,.5.7,2.353,2.353,0,0,1,.2.8v4.4h-1Z" fill="#bfbfbf"/>
          <path id="Path_8514" data-name="Path 8514" d="M191.8,90.3a2.587,2.587,0,0,1-2.6-1.6,4.711,4.711,0,0,1-.4-1.7,3.287,3.287,0,0,1,.4-1.7,2.94,2.94,0,0,1,1-1.2,3.012,3.012,0,0,1,3,0,2.73,2.73,0,0,1,.9,1.2,4.967,4.967,0,0,1,0,3.4,2.73,2.73,0,0,1-.9,1.2,2.456,2.456,0,0,1-1.5.5Zm.1,2.9a1.8,1.8,0,0,1-1-.2,4.053,4.053,0,0,1-.9-.5,2.7,2.7,0,0,1-.7-.8l1-.5a2.336,2.336,0,0,0,.7.7,2.92,2.92,0,0,0,.9.2c.3,0,.8,0,1-.2a1.057,1.057,0,0,0,.6-.7,3.6,3.6,0,0,0,.2-1.1V88.3h.1V83.6h.9v7.5a1.433,1.433,0,0,1-.5,1.1,3.592,3.592,0,0,1-1,.7,5.212,5.212,0,0,1-1.4.2Zm0-3.9A2.016,2.016,0,0,0,193,89a1.456,1.456,0,0,0,.6-.9,5.066,5.066,0,0,0,.2-1.3,2.745,2.745,0,0,0-.2-1.3,2.034,2.034,0,0,0-.6-.9,1.689,1.689,0,0,0-1-.3,2.016,2.016,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.345,2.345,0,0,0,.2,1.2,2.034,2.034,0,0,0,.6.9,1.609,1.609,0,0,0,1.1.3Z" fill="#bfbfbf"/>
        </g>
        <g id="Group_6690" data-name="Group 6690">
          <path id="Path_8515" data-name="Path 8515" d="M286.8,90.1V81.5h4a1.656,1.656,0,0,1,1.2.5,2.016,2.016,0,0,1,.7.9,4.438,4.438,0,0,1,.2,1.3,2.86,2.86,0,0,1-.5,1.7,2.551,2.551,0,0,1-1.5.9h-3.1V90h-1.1Zm1.1-4.2h2.9a1.012,1.012,0,0,0,.7-.4,2.092,2.092,0,0,0,.4-.6,2.5,2.5,0,0,0,0-1.4c0-.2-.2-.4-.4-.6a1.612,1.612,0,0,0-.7-.4h-2.9v3.4Zm4.1,4.2-1.7-3.5,1.1-.3,1.9,3.8h-1.2Z" fill="#bfbfbf"/>
          <path id="Path_8516" data-name="Path 8516" d="M297.3,90.3a3.287,3.287,0,0,1-1.7-.4,3.179,3.179,0,0,1-1.1-1.2,4.25,4.25,0,0,1,0-3.6,3.179,3.179,0,0,1,1.1-1.2,2.934,2.934,0,0,1,1.6-.4,3.371,3.371,0,0,1,1.7.5,2.209,2.209,0,0,1,1,1.3,6.013,6.013,0,0,1,.3,2h-1.1v-.4a2.969,2.969,0,0,0-.5-1.8,1.678,1.678,0,0,0-1.4-.6,1.887,1.887,0,0,0-1.5.6,3.49,3.49,0,0,0,0,3.6,1.755,1.755,0,0,0,1.5.6c.7,0,.8,0,1.1-.3a1.779,1.779,0,0,0,.7-.8l1,.4a2.85,2.85,0,0,1-1.1,1.3,3.371,3.371,0,0,1-1.7.5Zm-2.4-3.1v-.9h4.8v.9Z" fill="#bfbfbf"/>
          <path id="Path_8517" data-name="Path 8517" d="M303.3,90.3a1.558,1.558,0,0,1-1.2-.3c-.3-.3-.6-.4-.7-.7a2.958,2.958,0,0,1-.2-1,1.344,1.344,0,0,1,.2-.9c.2-.3.3-.5.5-.6a2.01,2.01,0,0,1,.8-.4c.3,0,.7-.2,1-.2a5.02,5.02,0,0,0,1.2-.2,3.6,3.6,0,0,0,1.1-.2l-.4.2a2.269,2.269,0,0,0-.3-1.3,1.461,1.461,0,0,0-1.2-.4c-.5,0-.7,0-1,.3a1.456,1.456,0,0,0-.6.9l-1-.3a2.54,2.54,0,0,1,.9-1.3,2.969,2.969,0,0,1,1.8-.5,2.81,2.81,0,0,1,1.5.3,1.789,1.789,0,0,1,.9,1,2.544,2.544,0,0,0,.2.7v4.7h-.9V88.5h.2a1.994,1.994,0,0,1-1,1.3,2.726,2.726,0,0,1-1.6.4Zm.2-.9a1.645,1.645,0,0,0,1-.2,4.235,4.235,0,0,0,.7-.5,1.08,1.08,0,0,0,.3-.7V86.7l.4.2c-.3,0-.7.1-1,.1a3.4,3.4,0,0,0-1,.1,1.884,1.884,0,0,0-.8.2c-.2,0-.3.1-.5.2-.2,0-.3.2-.4.3a4.331,4.331,0,0,0-.1.5.9.9,0,0,0,.1.5.781.781,0,0,0,.4.4,1.445,1.445,0,0,0,.7.2Z" fill="#bfbfbf"/>
          <path id="Path_8518" data-name="Path 8518" d="M310.8,90.3a2.587,2.587,0,0,1-2.6-1.6,4.711,4.711,0,0,1-.4-1.7,3.287,3.287,0,0,1,.4-1.7,2.94,2.94,0,0,1,1-1.2,3.012,3.012,0,0,1,3,0,2.73,2.73,0,0,1,.9,1.2,4.967,4.967,0,0,1,0,3.4,2.73,2.73,0,0,1-.9,1.2,2.456,2.456,0,0,1-1.5.5Zm.1-1A2.016,2.016,0,0,0,312,89a1.456,1.456,0,0,0,.6-.9,5.066,5.066,0,0,0,.2-1.3,2.745,2.745,0,0,0-.2-1.3,2.034,2.034,0,0,0-.6-.9,1.689,1.689,0,0,0-1-.3,2.016,2.016,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.345,2.345,0,0,0,.2,1.2,2.034,2.034,0,0,0,.6.9,1.609,1.609,0,0,0,1.1.3Zm1.9.8V85.3h-.1V81.4h1.1V90h-1Z" fill="#bfbfbf"/>
          <path id="Path_8519" data-name="Path 8519" d="M316.3,93l1.3-3.4v1l-2.8-7h1.1l2.2,5.6h-.4l2.1-5.6h1.1L317.4,93h-1.1Z" fill="#bfbfbf"/>
        </g>
      </g>
      <line id="Line_60" x2="52.8" transform="translate(382.8 39.1)" fill="none"/>
      <g id="Group_551">
        <circle id="Ellipse_90" cx="38.5" cy="38.5" r="38.5" transform="translate(400)" fill="#ffd5d5"/>
        <circle id="Ellipse_85" cx="30.5" cy="30.5" r="30.5" transform="translate(408 8)" fill="#f55353"/>
      </g>
      <circle id="Ellipse_87" cx="5" cy="5" r="5" transform="translate(395 35)" fill="#bfbfbf"/>
      <g id="Group_4697">
        <path id="Path_3791" d="M430.7,30l16.6,16.6" fill="none" stroke="#fff" stroke-width="1"/>
        <path id="Path_3792" d="M447.3,30,430.7,46.6" fill="none" stroke="#fff" stroke-width="1"/>
      </g>
      <g id="Group_6691" data-name="Group 6691">
        <path id="Path_8520" data-name="Path 8520" d="M409.6,98.8a4.406,4.406,0,0,1-2.6-.7,4.9,4.9,0,0,1-1.7-1.8,5.954,5.954,0,0,1-.6-2.7,5.666,5.666,0,0,1,.6-2.7,3.719,3.719,0,0,1,1.7-1.8,5.279,5.279,0,0,1,2.6-.7,4.979,4.979,0,0,1,2.9.9,4.607,4.607,0,0,1,1.6,2.3l-1.7.5a2.376,2.376,0,0,0-1-1.5,2.792,2.792,0,0,0-1.8-.6,3.1,3.1,0,0,0-1.7.5,2.209,2.209,0,0,0-1,1.3,6.167,6.167,0,0,0,0,3.8,3.249,3.249,0,0,0,1,1.3,3.1,3.1,0,0,0,1.7.5,2.609,2.609,0,0,0,1.8-.6,3.072,3.072,0,0,0,1-1.5l1.7.5a4.368,4.368,0,0,1-1.6,2.3,4.563,4.563,0,0,1-2.9.9Z"/>
        <path id="Path_8521" data-name="Path 8521" d="M417.8,98.8a3.194,3.194,0,0,1-1.4-.3,2.361,2.361,0,0,1-.9-.8,2.016,2.016,0,0,1-.3-1.1,1.644,1.644,0,0,1,.2-1,2.1,2.1,0,0,1,.6-.8,3.128,3.128,0,0,1,1-.5,6.149,6.149,0,0,0,1.1-.3c.4,0,.9-.2,1.3-.2a8.875,8.875,0,0,0,1.5-.2l-.6.3a1.594,1.594,0,0,0-.4-1.2,1.691,1.691,0,0,0-1.2-.4c-.6,0-.7,0-1,.2a1.853,1.853,0,0,0-.7.8l-1.5-.5a2.4,2.4,0,0,1,1.1-1.5,3.281,3.281,0,0,1,2.1-.6,4.178,4.178,0,0,1,1.8.3,1.981,1.981,0,0,1,1.1,1.1,2.353,2.353,0,0,1,.2.8v5.5h-1.5V96.8l.2.3a3.447,3.447,0,0,1-1.2,1.2,3.064,3.064,0,0,1-1.7.4Zm.4-1.3a1.645,1.645,0,0,0,1-.2c.3-.2.5-.3.6-.5s.3-.4.3-.6a1.854,1.854,0,0,1,.2-.7v-.7l.5.2a5.066,5.066,0,0,0-1.3.2,2,2,0,0,0-.9.2,1.445,1.445,0,0,0-.7.2c-.2,0-.4.2-.5.2s-.2.2-.3.3c0,.1-.1.3-.1.4s0,.4.1.5a.446.446,0,0,0,.4.3c.2,0,.4.1.7.1Z"/>
        <path id="Path_8522" data-name="Path 8522" d="M423.8,98.6V91h1.5v2.3h.2v5.2h-1.7Zm5.2,0V94.2a1.884,1.884,0,0,0-.2-.8,3.038,3.038,0,0,0-.5-.7,1.493,1.493,0,0,0-1-.3c-.4,0-.4,0-.6.1a2.092,2.092,0,0,0-.6.4,1.612,1.612,0,0,0-.4.7,3.6,3.6,0,0,0-.2,1.1l-1-.4a3.922,3.922,0,0,1,.4-1.8,2.545,2.545,0,0,1,1.1-1.2,3.453,3.453,0,0,1,1.8-.5,1.959,1.959,0,0,1,1.4.3c.4.3.7.4.9.7a4.053,4.053,0,0,1,.5.9,2.92,2.92,0,0,1,.2.9v4.9h-1.7Z"/>
        <path id="Path_8523" data-name="Path 8523" d="M435.7,98.8a4.228,4.228,0,0,1-2-.5,3.531,3.531,0,0,1-1.3-1.4,4.477,4.477,0,0,1-.4-2,4.649,4.649,0,0,1,.5-2.1,2.949,2.949,0,0,1,1.3-1.4,4.228,4.228,0,0,1,2-.5,4.032,4.032,0,0,1,2.2.6,2.913,2.913,0,0,1,1.2,1.8l-1.7.5a1.272,1.272,0,0,0-.7-.9,1.93,1.93,0,0,0-1-.3,2.016,2.016,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.86,2.86,0,0,0,.5,1.7,1.75,1.75,0,0,0,1.4.7,1.609,1.609,0,0,0,1.1-.3,1.456,1.456,0,0,0,.6-.9l1.7.4a2.913,2.913,0,0,1-1.2,1.8,3.7,3.7,0,0,1-2.1.6Z"/>
        <path id="Path_8524" data-name="Path 8524" d="M443.7,98.8a4.228,4.228,0,0,1-2-.5,3.759,3.759,0,0,1-1.4-1.4,4.552,4.552,0,0,1-.5-2,5.852,5.852,0,0,1,.5-2.2,3.531,3.531,0,0,1,1.3-1.4,4.228,4.228,0,0,1,2-.5,3.939,3.939,0,0,1,2,.5,3.252,3.252,0,0,1,1.2,1.6,6.092,6.092,0,0,1,.3,2.4h-1.7v-.6a3.007,3.007,0,0,0-.4-1.8,1.486,1.486,0,0,0-1.3-.6,1.887,1.887,0,0,0-1.5.6,3.312,3.312,0,0,0-.5,1.9,2.969,2.969,0,0,0,.5,1.8,1.755,1.755,0,0,0,1.5.6c.7,0,.8,0,1.1-.3a5.008,5.008,0,0,0,.7-.8l1.7.5a3.435,3.435,0,0,1-1.3,1.6,3.606,3.606,0,0,1-2,.6Zm-2.6-3.5V94h5.2v1.3Z"/>
        <path id="Path_8525" data-name="Path 8525" d="M448.8,98.6V88.3h1.7V98.6Z"/>
        <path id="Path_8526" data-name="Path 8526" d="M452.7,98.6V88.3h1.7V98.6Z"/>
        <path id="Path_8527" data-name="Path 8527" d="M460,98.8a4.228,4.228,0,0,1-2-.5,3.759,3.759,0,0,1-1.4-1.4,4.552,4.552,0,0,1-.5-2,5.852,5.852,0,0,1,.5-2.2,3.531,3.531,0,0,1,1.3-1.4,4.228,4.228,0,0,1,2-.5,3.939,3.939,0,0,1,2,.5,3.252,3.252,0,0,1,1.2,1.6,6.092,6.092,0,0,1,.3,2.4h-1.7v-.6a3.007,3.007,0,0,0-.4-1.8,1.486,1.486,0,0,0-1.3-.6,1.887,1.887,0,0,0-1.5.6,3.312,3.312,0,0,0-.5,1.9,2.969,2.969,0,0,0,.5,1.8,1.755,1.755,0,0,0,1.5.6c.7,0,.8,0,1.1-.3a5.008,5.008,0,0,0,.7-.8l1.7.5a3.435,3.435,0,0,1-1.3,1.6,3.606,3.606,0,0,1-2,.6Zm-2.6-3.5V94h5.2v1.3Z"/>
        <path id="Path_8528" data-name="Path 8528" d="M468,98.8a3.453,3.453,0,0,1-1.8-.5,3.326,3.326,0,0,1-1.2-1.4,5.2,5.2,0,0,1,0-4,3.325,3.325,0,0,1,1.2-1.4,3.557,3.557,0,0,1,1.9-.5,3.2,3.2,0,0,1,1.8.5,2.586,2.586,0,0,1,1.1,1.4,5.2,5.2,0,0,1,0,4,3.142,3.142,0,0,1-1.1,1.4A2.851,2.851,0,0,1,468,98.8Zm.2-1.5a1.8,1.8,0,0,0,1.1-.3,1.456,1.456,0,0,0,.6-.9,5.066,5.066,0,0,0,.2-1.3,2.745,2.745,0,0,0-.2-1.3,1.456,1.456,0,0,0-.6-.9,1.689,1.689,0,0,0-1-.3,2.016,2.016,0,0,0-1.1.3,2.178,2.178,0,0,0-.7.9,5.019,5.019,0,0,0-.2,1.2,2.953,2.953,0,0,0,.2,1.3,2.034,2.034,0,0,0,.6.9,1.8,1.8,0,0,0,1.1.3Zm1.9,1.3V93.3h-.2V88.5h1.7V98.6h-1.5Z"/>
      </g>
       </svg>
    );

  const statusRefundedDesktop = //Done
    (
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 659 103.1">

        <g id="d_s_refund">
          <line id="Line_60" x1="562.8" y1="39.1" x2="617.6" y2="39.1" fill="none" stroke="#d4d4d4" stroke-opacity="0"/>
          <g id="Group_4698" opacity=".6">
            <line id="Line_65" x1="436.9" y1="39.1" x2="590.8" y2="39.1" fill="none" stroke="#bfbfbf"/>
            <line id="Line_64" x1="60.2" y1="39.1" x2="157" y2="39.1" fill="none" stroke="#2ec278" stroke-opacity="0"/>
            <line id="Line_59" x1="69.5" y1="39.1" x2="202.5" y2="39.1" fill="none" stroke="#bfbfbf"/>
            <path id="Path_666" d="M231.6,39.1h170.4" fill="none" stroke="#bfbfbf"/>
            <path id="Path_665" d="M38.5,0c21.3,0,38.5,17.2,38.5,38.5s-17.2,38.5-38.5,38.5S0,59.8,0,38.5,17.2,0,38.5,0Z" fill="#ffd5d5" fill-opacity="0"/>
            <circle id="Ellipse_82" cx="38.5" cy="38.5" r="30.5" fill="#2ec278"/>
            <g id="Group_552">
              <circle id="Ellipse_88" cx="233.5" cy="38.5" r="38.5" fill="#acffd6" fill-opacity="0"/>
              <path id="Path_638" d="M233.4,8.2c16.7,0,30.3,13.6,30.3,30.3s-13.6,30.3-30.3,30.3-30.3-13.6-30.3-30.3c0-16.7,13.6-30.3,30.3-30.3h0Z" fill="#f1f1f1"/>
            </g>
            <g id="Group_550">
              <circle id="Ellipse_89" cx="423.5" cy="38.5" r="38.5" fill="#ffeaaf" fill-opacity="0"/>
              <circle id="Ellipse_84" cx="423.5" cy="38.5" r="30.5" fill="#f1f1f1"/>
            </g>
            <g id="verified-24px">
              <g id="Group_564">
                <rect id="Rectangle_235" x="25" y="26" width="28" height="28" fill="none"/>
              </g>
              <g id="Group_565">
                <path id="Path_623" d="M51.5,39.6l-2.8-3.3.4-4.3-4.2-1-2.2-3.7-4,1.7-4-1.7-2.2,3.7-4.2.9.4,4.3-2.9,3.3,2.8,3.3-.4,4.3,4.2,1,2.2,3.7,4-1.7,4,1.7,2.2-3.7,4.2-1-.4-4.3,2.9-3.3ZM36.4,45.2l-4.4-4.4,1.7-1.7,2.7,2.7,6.8-6.8,1.7,1.7-8.5,8.6Z" fill="#fff"/>
              </g>
            </g>
            <g id="inventory_2_black_24dp">
              <g id="Group_4910">
                <rect id="Rectangle_1309" x="221" y="27" width="26" height="26" fill="none"/>
              </g>
              <g id="Group_4911">
                <path id="Path_3926" d="M242.3,29.6h-16.6c-1.1,0-2,.9-2.1,2.1v3.1c0,.7.4,1.4,1,1.8v11.8c0,1.1,1,2,2.1,2.1h14.6c1.1,0,2-1,2.1-2.1v-11.8c.6-.4,1-1,1-1.8v-3.1c0-1.1-.9-2-2.1-2.1ZM237.1,42.1h-6.2v-2.1h6.2v2.1ZM242.3,34.8h-16.6v-3.1h16.6v3.1Z" fill="#ddd"/>
              </g>
            </g>
            <g id="Group_5034">
              <g id="Group_5030">
                <path id="Path_4200" d="M429.3,39.5c-3.1,0-5.7,2.5-5.7,5.7,0,3.1,2.5,5.7,5.7,5.7,3.1,0,5.7-2.5,5.7-5.7,0,0,0,0,0,0,0-3.1-2.5-5.7-5.7-5.7h0M428.2,48l-2.3-2.3.8-.8,1.5,1.5,3.7-3.7.8.8-4.5,4.5Z" fill="#dfdfdf"/>
                <path id="Path_4201" d="M421.9,45.1c0-4.1,3.3-7.4,7.4-7.4.9,0,1.9.2,2.8.5v-2.6c.6-.3.9-.9.9-1.6v-2.8c0-1-.9-1.8-1.9-1.9h-15.1c-1,0-1.8.9-1.9,1.9v2.8c0,.7.4,1.3.9,1.6v10.6c0,1,.9,1.8,1.9,1.9h5.5c-.4-1-.7-2-.7-3.1M416.1,31.2h15.1v2.8h-15.1v-2.8Z" fill="#dfdfdf"/>
              </g>
            </g>
            <g>
              <path d="M11.8,94.1l2.9-8.6h1.5l2.9,8.6h-1.1l-2.7-8h.3l-2.7,8h-1.1ZM13.2,92.1v-1h4.5v1h-4.5Z" fill="#c9c9c9"/>
              <path d="M22.9,94.3c-.7,0-1.2,0-1.7-.4s-.8-.7-1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2c0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5h-.3,0Z" fill="#c9c9c9"/>
              <path d="M29.4,94.3c-.7,0-1.2,0-1.7-.4s-.8-.7-1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2c0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5h-.3,0Z" fill="#c9c9c9"/>
              <path d="M36,94.3c-.6,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8,0-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8s-.8-.6-1.4-.6-1.2.2-1.5.6-.5,1-.5,1.8.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3s-1.1.5-1.7.5h.1ZM33.6,91.2v-.9h4.8v.9h-4.8Z" fill="#c9c9c9"/>
              <path d="M40.1,97v-9.4h1v4.8h0v4.6h-1.1,0ZM43.1,94.3c-.6,0-1.1-.2-1.5-.5s-.7-.7-.9-1.2-.3-1.1-.3-1.7,0-1.2.3-1.7.5-.9.9-1.2c.4-.3.9-.4,1.5-.4s1.1,0,1.6.4.8.7,1,1.2.3,1.1.3,1.7,0,1.2-.3,1.7-.6.9-1,1.2c-.4.3-1,.4-1.6.4h0ZM43,93.3c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.2,0-.9-.2-1.2c0-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8,0-1,.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.3,0,.9.2,1.3c0,.4.3.7.6.9.3.2.6.3,1.1.3v-.2h0Z" fill="#c9c9c9"/>
              <path d="M46.6,88.5v-.9h4.3v.9h-4.3ZM51,94.1h-1.1c-.4,0-.7,0-1-.2s-.5-.4-.7-.7-.2-.5-.2-.8v-6.7h1.1v7c0,.3.4.4.7.5s.7,0,1.1,0v.9h.1Z" fill="#c9c9c9"/>
              <path d="M54.8,94.3c-.6,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8,0-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8s-.8-.6-1.4-.6-1.2.2-1.5.6-.5,1-.5,1.8.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3s-1.1.5-1.7.5h.1ZM52.4,91.2v-.9h4.8v.9h-4.8Z" fill="#c9c9c9"/>
              <path d="M61.6,94.3c-.6,0-1.1,0-1.6-.4-.4-.3-.8-.7-1-1.2s-.4-1.1-.4-1.7,0-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1,0,1.5.4.7.7.9,1.2c.2.5.3,1.1.3,1.7s0,1.2-.3,1.7-.5.9-.9,1.2c-.4.3-.9.5-1.5.5h0ZM61.8,93.3c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.3,0-.9-.2-1.3c0-.4-.3-.7-.6-.9s-.6-.3-1-.3-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2,0,.9.2,1.2c0,.4.4.7.6.9.3.2.6.3,1.1.3v.2h0ZM63.7,94.1v-4.8h0v-3.9h1.1v8.6h-1.1Z" fill="#c9c9c9"/>
            </g>
            <g>
              <path d="M406.8,94.1v-8.6h4c.5,0,.9.2,1.2.5s.6.6.7.9c.2.4.2.8.2,1.3s-.2,1.3-.5,1.7c-.3.5-.9.8-1.5.9h-3.1v3.2h-1.1.1,0ZM407.9,89.9h2.9c.3,0,.5-.2.7-.4.2-.2.3-.4.4-.6s.1-.5.1-.7,0-.5-.1-.7-.2-.4-.4-.6c-.2-.2-.4-.3-.7-.4h-2.9s0,3.4,0,3.4ZM412,94.1l-1.7-3.5,1.1-.3,1.9,3.8h-1.3Z" fill="#c9c9c9"/>
              <path d="M417.3,94.3c-.6,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2c-.3-.5-.4-1.1-.4-1.8s.1-1.3.4-1.8.6-.9,1.1-1.2c.5-.3,1-.4,1.6-.4s1.2.2,1.7.4c.5.3.8.7,1,1.3.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8s-.8-.6-1.4-.6-1.2.2-1.5.6-.5,1-.5,1.8.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3s-1.1.5-1.7.5h.1ZM414.9,91.2v-.9h4.8v.9h-4.8Z" fill="#c9c9c9"/>
              <path d="M423.3,94.3c-.5,0-.9,0-1.2-.3s-.6-.4-.7-.7c-.2-.3-.2-.6-.2-1s0-.6.2-.9.3-.5.5-.6.5-.3.8-.4c.3,0,.7-.2,1-.2s.8,0,1.2-.2c.4,0,.8,0,1.1-.2l-.4.2c0-.6-.1-1-.3-1.3-.2-.3-.7-.4-1.2-.4s-.7,0-1,.3-.5.5-.6.9l-1-.3c.2-.6.5-1,.9-1.3s1-.5,1.8-.5,1.1,0,1.5.3c.4.2.7.5.9,1,0,.2.2.4.2.7v4.7h-.9v-1.6h.2c-.2.7-.6,1.1-1,1.3-.4.3-1,.4-1.6.4h-.2,0ZM423.5,93.4c.4,0,.7,0,1-.2.3,0,.5-.3.7-.5s.3-.5.3-.7v-1.3l.4.2h-2c-.3,0-.6,0-.8.2-.2,0-.3,0-.5.2s-.3.2-.4.3-.1.3-.1.5,0,.3.1.5.2.3.4.4c.2,0,.4.2.7.2h.2v.2Z" fill="#c9c9c9"/>
              <path d="M430.8,94.3c-.6,0-1.1-.2-1.6-.4-.4-.3-.8-.7-1-1.2s-.4-1.1-.4-1.7.1-1.2.4-1.7c.2-.5.6-.9,1-1.2.4-.3.9-.4,1.5-.4s1.1,0,1.5.4.7.7.9,1.2c.2.5.3,1.1.3,1.8s-.1,1.2-.3,1.7c-.2.5-.5.9-.9,1.2s-.9.5-1.5.5v-.2h.1ZM430.9,93.3c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.3,0-.9-.2-1.3c-.1-.4-.3-.7-.6-.9-.3-.2-.6-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2,0,.9.2,1.2c.1.4.4.7.6.9.3.2.6.3,1.1.3v.2h-.1ZM432.8,94.1v-4.8h-.1v-3.9h1.1v8.6h-1Z" fill="#c9c9c9"/>
              <path d="M436.3,97l1.3-3.4v1l-2.8-7h1.1l2.2,5.6h-.4l2.1-5.6h1.1l-3.5,9.4h-1.1Z" fill="#c9c9c9"/>
            </g>
            <g>
              <path d="M212.2,94.1v-8.6h4c.5,0,.9.2,1.2.5.3.2.6.6.7.9.2.4.2.8.2,1.3s0,.9-.2,1.3-.4.7-.7.9-.7.4-1.2.5h-2.9v3.2h-1.1ZM213.3,89.9h2.9c.3,0,.5-.2.7-.4.2-.2.3-.4.4-.6s.1-.5.1-.7,0-.5-.1-.7c0-.2-.2-.4-.4-.6s-.4-.3-.7-.4h-2.9s0,3.4,0,3.4Z" fill="#c9c9c9"/>
              <path d="M221.2,94.3c-.5,0-.9,0-1.2-.3-.3-.2-.6-.4-.7-.7s-.2-.6-.2-1,0-.6.2-.9.3-.5.5-.6c.2-.2.5-.3.8-.4.3,0,.7-.2,1-.2s.8,0,1.2-.2c.4,0,.8,0,1.1-.2l-.4.2c0-.6-.1-1-.3-1.3s-.7-.4-1.2-.4-.7,0-1,.3c-.3.2-.5.5-.6.9l-1-.3c.2-.6.5-1,.9-1.3s1-.5,1.8-.5,1.1,0,1.5.3c.4.2.7.5.9,1,0,.2.2.4.2.7v4.7h-.9v-1.6h.2c-.2.7-.6,1.1-1,1.3-.4.3-1,.4-1.6.4h-.2ZM221.4,93.4c.4,0,.7,0,1-.2.3,0,.5-.3.7-.5s.3-.5.3-.7v-1.3l.4.2h-2c-.3,0-.6,0-.8.2-.2,0-.3,0-.5.2-.2,0-.3.2-.4.3,0,0-.1.3-.1.5s0,.3.1.5c0,.2.2.3.4.4s.4.2.7.2h.2v.2Z" fill="#c9c9c9"/>
              <path d="M228.9,94.3c-.7,0-1.2,0-1.7-.4s-.8-.7-1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2c0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5h-.3Z" fill="#c9c9c9"/>
              <path d="M232.9,94.1v-8.6h1.1v5.3l2.7-3.1h1.4l-2.9,3.2,3.2,3.2h-1.5l-2.9-3.1v3.1h-1.1Z" fill="#c9c9c9"/>
              <path d="M239.1,86.5v-1.1h1.1v1.1h-1.1ZM239.1,94.1v-6.5h1.1v6.5h-1.1Z" fill="#c9c9c9"/>
              <path d="M242,94.1v-6.5h1v1.7h.1v4.7h-1.1ZM246.6,94.1v-4.1c0-.3-.1-.5-.3-.8-.1-.2-.3-.4-.5-.5s-.5-.2-.9-.2h-.7c-.2,0-.4.2-.6.4-.2.2-.3.4-.4.7,0,.3-.1.6-.1,1l-.7-.2c0-.6.1-1.2.3-1.6.2-.5.5-.8,1-1s.9-.4,1.4-.4.8,0,1.1.2.5.3.7.5.4.5.5.7c.1.3.2.5.2.8v4.4h-1.1.1Z" fill="#c9c9c9"/>
              <path d="M251.8,94.3c-.6,0-1.1,0-1.6-.4-.4-.3-.8-.7-1-1.2s-.4-1.1-.4-1.7.1-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1,0,1.5.4.7.7.9,1.2.3,1.1.3,1.7-.1,1.2-.3,1.7-.5.9-.9,1.2c-.4.3-.9.5-1.5.5h.1ZM251.9,97.2c-.3,0-.7,0-1-.2-.3,0-.6-.3-.9-.5s-.5-.5-.7-.8l1-.5c.2.3.4.5.7.7.3,0,.6.2.9.2s.8,0,1-.2c.3,0,.5-.4.6-.7.1-.3.2-.7.2-1.1v-1.8h.1v-4.7h.9v7.5c0,.5-.3.8-.5,1.1-.2.3-.6.5-1,.7-.4,0-.8.2-1.4.2h0ZM251.9,93.3c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.3,0-.9-.2-1.3c-.1-.4-.3-.7-.6-.9s-.6-.3-1-.3-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2,0,.9.2,1.2c.1.4.4.7.6.9.3.2.6.3,1.1.3v.2h-.1Z" fill="#c9c9c9"/>
            </g>
          </g>
          <g id="Group_5853">
            <g id="Group_551">
              <path id="Path_7003" d="M620.5,0c21.3,0,38.5,17.2,38.5,38.5s-17.2,38.5-38.5,38.5-38.5-17.2-38.5-38.5S599.2,0,620.5,0Z" fill="#ffdad2"/>
              <circle id="Ellipse_85" cx="620.5" cy="38.5" r="30.5" fill="#ff775b"/>
            </g>
            <g id="refund">
              <g id="Group_5852">
                <g id="Group_5851">
                  <path id="Path_7004" d="M627.8,30l-1.3,1.7c4.4,3.4,5.2,9.7,1.8,14.1-3.4,4.4-9.7,5.2-14.1,1.8-4.4-3.4-5.2-9.7-1.8-14.1,2-2.6,5.2-4.1,8.5-3.9l-1.9,2,1.4,1.3,3-3.1h0l1.3-1.4-1.4-1.3h0l-3.1-3-1.3,1.4,2,1.9c-6.7-.3-12.5,4.9-12.8,11.7-.3,6.2,4.2,11.7,10.3,12.6.6.1,1.3.2,1.9.2,6.8,0,12.2-5.5,12.2-12.3,0-3.8-1.8-7.3-4.7-9.6Z" fill="#fff"/>
                  <path id="Path_7005" d="M621.5,45.9h0v-1.3c1.6,0,2.9-1.3,2.9-2.9,0-2.4-2.3-2.7-3.9-2.9-1.1-.1-1.9-.3-1.9-.9s1.2-1,1.8-1c.7,0,1.5.3,1.9.9h0c0,.1,1.7-.6,1.7-.6v-.2c-.6-1-1.5-1.7-2.6-1.8v-1.1h-1.9v1.1c-1.8.3-2.9,1.3-2.9,2.7,0,2.3,2.1,2.6,3.7,2.7,1.4.2,2,.5,2,1.1,0,1.1-1.5,1.2-2,1.2-.9,0-1.8-.4-2.3-1.2v-.2c0,0-1.9.8-1.9.8v.2c.7,1.3,1.9,2.1,3.3,2.2v1.2h1.9Z" fill="#fff"/>
                </g>
              </g>
            </g>
          </g>
          <circle id="Ellipse_87" cx="582" cy="40" r="5" fill="#bfbfbf"/>
          <g>
            <path d="M589,98.6v-9.9h4.1c0,0,.2,0,.4,0s.3,0,.4,0c.6,0,1,.3,1.4.6.4.3.7.7.8,1.1.2.4.3.9.3,1.5,0,.8-.2,1.5-.6,2.1-.4.6-1,.9-1.9,1.1h-.7c0,0-2.6,0-2.6,0v3.5h-1.7ZM590.6,93.6h2.4c0,0,.2,0,.3,0,.1,0,.2,0,.3,0,.3,0,.5-.2.7-.4.2-.2.3-.4.3-.6,0-.2,0-.4,0-.6s0-.4,0-.7-.2-.4-.3-.6c-.2-.2-.4-.3-.7-.4,0,0-.2,0-.3,0-.1,0-.2,0-.3,0h-2.4v3.3ZM594.7,98.6l-2-4,1.7-.4,2.1,4.4h-1.9Z"/>
            <path d="M601.5,98.8c-.8,0-1.4-.2-2-.5-.6-.3-1-.8-1.3-1.4-.3-.6-.5-1.2-.5-2s.2-1.5.5-2.1c.3-.6.7-1.1,1.3-1.4.6-.3,1.2-.5,1.9-.5s1.4.2,2,.5c.5.4.9.9,1.2,1.5s.4,1.4.3,2.3h-1.6v-.6c0-.8-.1-1.4-.4-1.8-.3-.4-.7-.6-1.3-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.3.5,1.7c.3.4.8.6,1.4.6s.8,0,1.1-.3c.3-.2.5-.4.7-.8l1.6.5c-.3.7-.7,1.2-1.3,1.6-.6.4-1.2.6-2,.6ZM598.9,95.4v-1.3h5.1v1.3h-5.1Z"/>
            <path d="M605.8,92.5v-1.3h4.7v1.3h-4.7ZM607,98.6v-7.6c0-.2,0-.4,0-.6,0-.2,0-.5.1-.7s.2-.4.4-.6c.2-.2.4-.4.7-.5.2,0,.5-.1.7-.2.2,0,.4,0,.6,0h.9v1.3h-.9c-.3,0-.6,0-.7.2-.2.2-.2.4-.2.6v7.9h-1.7Z"/>
            <path d="M614.3,98.8c-.5,0-1,0-1.4-.3-.4-.2-.6-.4-.9-.7-.2-.3-.4-.6-.5-.9-.1-.3-.2-.6-.2-.9,0-.3,0-.5,0-.7v-4.2h1.7v3.6c0,.2,0,.5,0,.8s.1.6.2.8c.1.3.3.5.5.7.2.2.5.3.9.3s.4,0,.6-.1c.2,0,.4-.2.6-.4.2-.2.3-.4.4-.7.1-.3.2-.7.2-1.1l1,.4c0,.6-.1,1.2-.4,1.7-.2.5-.6.9-1.1,1.2-.5.3-1.1.5-1.8.5ZM616.8,98.6v-2.3h-.2v-5.1h1.7v7.4h-1.5Z"/>
            <path d="M620.2,98.6v-7.4h1.5v2.3h.2v5.1h-1.7ZM625.3,98.6v-3.6c0-.2,0-.5,0-.8,0-.3-.1-.6-.2-.8-.1-.3-.3-.5-.5-.7s-.5-.3-.9-.3-.4,0-.6.1c-.2,0-.4.2-.6.4-.2.2-.3.4-.4.7-.1.3-.2.7-.2,1.1l-1-.4c0-.6.1-1.2.4-1.7.2-.5.6-.9,1.1-1.2.5-.3,1.1-.5,1.8-.5s1,0,1.4.3c.4.2.6.4.9.7s.4.6.5.9c.1.3.2.6.2.9,0,.3,0,.5,0,.7v4.2h-1.7Z"/>
            <path d="M631.6,98.8c-.7,0-1.3-.2-1.8-.5-.5-.3-.9-.8-1.2-1.4-.3-.6-.4-1.3-.4-2s.1-1.4.4-2,.7-1.1,1.2-1.4,1.1-.5,1.8-.5,1.3.2,1.8.5.8.8,1.1,1.4c.2.6.4,1.3.4,2s-.1,1.4-.4,2c-.2.6-.6,1.1-1.1,1.4-.5.3-1.1.5-1.8.5ZM631.9,97.4c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.3,0-.9-.2-1.3c-.1-.4-.3-.6-.6-.9-.3-.2-.6-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2s0,.9.2,1.2c.1.4.3.7.6.9s.6.3,1.1.3ZM633.8,98.6v-5.2h-.2v-4.7h1.7v9.9h-1.5Z"/>
            <path d="M640.5,98.8c-.8,0-1.4-.2-2-.5-.6-.3-1-.8-1.3-1.4-.3-.6-.5-1.2-.5-2s.2-1.5.5-2.1c.3-.6.7-1.1,1.3-1.4.6-.3,1.2-.5,1.9-.5s1.4.2,2,.5c.5.4.9.9,1.2,1.5s.4,1.4.3,2.3h-1.6v-.6c0-.8-.1-1.4-.4-1.8-.3-.4-.7-.6-1.3-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.3.5,1.7c.3.4.8.6,1.4.6s.8,0,1.1-.3c.3-.2.5-.4.7-.8l1.6.5c-.3.7-.7,1.2-1.3,1.6-.6.4-1.2.6-2,.6ZM638,95.4v-1.3h5.1v1.3h-5.1Z"/>
            <path d="M648.4,98.8c-.7,0-1.3-.2-1.8-.5-.5-.3-.9-.8-1.2-1.4-.3-.6-.4-1.3-.4-2s.1-1.4.4-2,.7-1.1,1.2-1.4,1.1-.5,1.8-.5,1.3.2,1.8.5.8.8,1.1,1.4c.2.6.4,1.3.4,2s-.1,1.4-.4,2c-.2.6-.6,1.1-1.1,1.4-.5.3-1.1.5-1.8.5ZM648.7,97.4c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.3,0-.9-.2-1.3c-.1-.4-.3-.6-.6-.9-.3-.2-.6-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2s0,.9.2,1.2c.1.4.3.7.6.9s.6.3,1.1.3ZM650.5,98.6v-5.2h-.2v-4.7h1.7v9.9h-1.5Z"/>
          </g>
        </g>
      </svg>
    );

  const statusRefundedMobile = // Done
    (
      <svg id="statusRefundedMobile" xmlns="http://www.w3.org/2000/svg" width="477" height="93.9" viewBox="0 0 477 93.9">
      <g id="Group_4698" opacity="0.6">
        <line id="Line_65" x2="91.9" transform="translate(316.9 39.1)" fill="none" stroke="#bfbfbf" stroke-width="1"/>
        <line id="Line_64" x2="36.8" transform="translate(60.2 39.1)" fill="none"/>
        <line id="Line_59" x2="73" transform="translate(69.5 39.1)" fill="none" stroke="#bfbfbf" stroke-width="1"/>
        <path id="Path_666" d="M171.6,39.1H282" fill="none" stroke="#bfbfbf" stroke-width="1"/>
        <path id="Path_665" d="M38.5,0a38.45,38.45,0,1,1-.1,0h0Z" opacity="0"/>
        <circle id="Ellipse_82" cx="30.5" cy="30.5" r="30.5" transform="translate(8 8)" fill="#2ec278"/>
        <g id="Group_552">
          <circle id="Ellipse_88" cx="38.5" cy="38.5" r="38.5" transform="translate(135)" opacity="0"/>
          <path id="Path_638" d="M173.4,8.2a30.3,30.3,0,1,1-30.3,30.3A30.344,30.344,0,0,1,173.4,8.2h0Z" fill="#f1f1f1"/>
        </g>
        <g id="Group_550">
          <circle id="Ellipse_89" cx="38.5" cy="38.5" r="38.5" transform="translate(265)" opacity="0"/>
          <circle id="Ellipse_84" cx="30.5" cy="30.5" r="30.5" transform="translate(273 8)" fill="#f1f1f1"/>
        </g>
        <g id="verified-24px">
          <g id="Group_564">
            <rect id="Rectangle_235" width="28" height="28" transform="translate(25 26)" fill="none"/>
          </g>
          <g id="Group_565">
            <path id="Path_623" d="M51.5,39.6l-2.8-3.3.4-4.3-4.2-1-2.2-3.7-4,1.7-4-1.7L32.5,31l-4.2.9.4,4.3-2.9,3.3,2.8,3.3-.4,4.3,4.2,1,2.2,3.7,4-1.7,4,1.7,2.2-3.7,4.2-1-.4-4.3,2.9-3.3h0ZM36.4,45.2,32,40.8l1.7-1.7,2.7,2.7L43.2,35l1.7,1.7-8.5,8.6h0Z" fill="#fff"/>
          </g>
        </g>
        <g id="inventory_2_black_24dp">
          <g id="Group_4910">
            <rect id="Rectangle_1309" width="26" height="26" transform="translate(161 27)" fill="none"/>
          </g>
          <g id="Group_4911">
            <path id="Path_3926" d="M182.3,29.6H165.7a2.182,2.182,0,0,0-2.1,2.1v3.1a2.234,2.234,0,0,0,1,1.8V48.4a2.258,2.258,0,0,0,2.1,2.1h14.6a2.258,2.258,0,0,0,2.1-2.1V36.6a2.234,2.234,0,0,0,1-1.8V31.7a2.182,2.182,0,0,0-2.1-2.1Zm-5.2,12.5h-6.2V40h6.2Zm5.2-7.3H165.7V31.7h16.6Z" fill="#ddd"/>
          </g>
        </g>
        <g id="Group_5034">
          <g id="Group_8219">
            <g id="Group_5030">
              <path id="Path_4200" d="M309.3,39.5a5.7,5.7,0,1,0,5.7,5.7h0a5.763,5.763,0,0,0-5.7-5.7h0M308.2,48l-2.3-2.3.8-.8,1.5,1.5,3.7-3.7.8.8Z" fill="#dfdfdf"/>
              <path id="Path_4201" d="M301.9,45.1a7.383,7.383,0,0,1,7.4-7.4h0a8.675,8.675,0,0,1,2.8.5V35.6A1.7,1.7,0,0,0,313,34V31.2a2.043,2.043,0,0,0-1.9-1.9H296a2.043,2.043,0,0,0-1.9,1.9V34a1.7,1.7,0,0,0,.9,1.6V46.2a2.043,2.043,0,0,0,1.9,1.9h5.5a8.245,8.245,0,0,1-.7-3.1m-5.6-13.8h15.1V34H296.1Z" fill="#dfdfdf"/>
            </g>
          </g>
        </g>
        <g id="Group_6688" data-name="Group 6688">
          <path id="Path_8500" data-name="Path 8500" d="M11.8,90.1l2.9-8.6h1.5l2.9,8.6H18l-2.7-8h.3l-2.7,8Zm1.4-2v-1h4.5v1H13.2Z" fill="#bfbfbf"/>
          <path id="Path_8501" data-name="Path 8501" d="M22.9,90.3a3.064,3.064,0,0,1-1.7-.4,3.179,3.179,0,0,1-1.1-1.2,4.776,4.776,0,0,1-.4-1.8,3.425,3.425,0,0,1,.4-1.8,2.545,2.545,0,0,1,1.1-1.2,3.064,3.064,0,0,1,1.7-.4,3.453,3.453,0,0,1,1.8.5,2.434,2.434,0,0,1,1,1.4l-1.1.3a1.6,1.6,0,0,0-.7-.9,1.929,1.929,0,0,0-1-.3,2.279,2.279,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.86,2.86,0,0,0,.5,1.7,1.806,1.806,0,0,0,1.5.7c.7,0,.8,0,1.1-.3a2.1,2.1,0,0,0,.6-.8l1.1.3a2.981,2.981,0,0,1-1,1.4,3.1,3.1,0,0,1-1.7.5Z" fill="#bfbfbf"/>
          <path id="Path_8502" data-name="Path 8502" d="M29.4,90.3a3.064,3.064,0,0,1-1.7-.4,3.179,3.179,0,0,1-1.1-1.2,4.776,4.776,0,0,1-.4-1.8,3.425,3.425,0,0,1,.4-1.8,2.545,2.545,0,0,1,1.1-1.2,3.064,3.064,0,0,1,1.7-.4,3.453,3.453,0,0,1,1.8.5,2.434,2.434,0,0,1,1,1.4l-1.1.3a1.6,1.6,0,0,0-.7-.9,1.929,1.929,0,0,0-1-.3,2.279,2.279,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.86,2.86,0,0,0,.5,1.7,1.806,1.806,0,0,0,1.5.7c.7,0,.8,0,1.1-.3a2.1,2.1,0,0,0,.6-.8l1.1.3a2.981,2.981,0,0,1-1,1.4,3.1,3.1,0,0,1-1.7.5Z" fill="#bfbfbf"/>
          <path id="Path_8503" data-name="Path 8503" d="M36,90.3a3.287,3.287,0,0,1-1.7-.4,3.179,3.179,0,0,1-1.1-1.2,4.25,4.25,0,0,1,0-3.6,3.179,3.179,0,0,1,1.1-1.2,2.934,2.934,0,0,1,1.6-.4,3.371,3.371,0,0,1,1.7.5,2.209,2.209,0,0,1,1,1.3,6.013,6.013,0,0,1,.3,2H37.8v-.4a2.969,2.969,0,0,0-.5-1.8,1.678,1.678,0,0,0-1.4-.6,1.887,1.887,0,0,0-1.5.6,3.49,3.49,0,0,0,0,3.6,1.755,1.755,0,0,0,1.5.6c.7,0,.8,0,1.1-.3a1.779,1.779,0,0,0,.7-.8l1,.4a2.85,2.85,0,0,1-1.1,1.3,3.371,3.371,0,0,1-1.7.5Zm-2.4-3.1v-.9h4.8v.9Z" fill="#bfbfbf"/>
          <path id="Path_8504" data-name="Path 8504" d="M40.1,93V83.6h1v4.8h.1V93Zm3-2.7a2.456,2.456,0,0,1-1.5-.5,2.73,2.73,0,0,1-.9-1.2,4.967,4.967,0,0,1,0-3.4,2.73,2.73,0,0,1,.9-1.2,2.41,2.41,0,0,1,1.5-.4,2.587,2.587,0,0,1,2.6,1.6,4.967,4.967,0,0,1,0,3.4,2.94,2.94,0,0,1-1,1.2,2.726,2.726,0,0,1-1.6.4Zm-.1-1a2.016,2.016,0,0,0,1.1-.3,1.456,1.456,0,0,0,.6-.9,5.019,5.019,0,0,0,.2-1.2,2.345,2.345,0,0,0-.2-1.2,2.034,2.034,0,0,0-.6-.9,1.8,1.8,0,0,0-1.1-.3,1.327,1.327,0,0,0-1,.3,1.456,1.456,0,0,0-.6.9,5.067,5.067,0,0,0-.2,1.3,2.745,2.745,0,0,0,.2,1.3,1.456,1.456,0,0,0,.6.9,1.8,1.8,0,0,0,1.1.3Z" fill="#bfbfbf"/>
          <path id="Path_8505" data-name="Path 8505" d="M46.6,84.5v-.9h4.3v.9ZM51,90.1a4.034,4.034,0,0,1-1.1,0,1.645,1.645,0,0,1-1-.2,1.7,1.7,0,0,1-.9-1.5V81.7h1.1v6.5a.749.749,0,0,0,.1.5c.1.3.4.4.7.5H51v.9Z" fill="#bfbfbf"/>
          <path id="Path_8506" data-name="Path 8506" d="M54.8,90.3a3.287,3.287,0,0,1-1.7-.4A3.179,3.179,0,0,1,52,88.7a4.25,4.25,0,0,1,0-3.6,3.179,3.179,0,0,1,1.1-1.2,2.934,2.934,0,0,1,1.6-.4,3.371,3.371,0,0,1,1.7.5,2.209,2.209,0,0,1,1,1.3,6.013,6.013,0,0,1,.3,2H56.6v-.4a2.969,2.969,0,0,0-.5-1.8,1.678,1.678,0,0,0-1.4-.6,1.887,1.887,0,0,0-1.5.6,3.49,3.49,0,0,0,0,3.6,1.755,1.755,0,0,0,1.5.6c.7,0,.8,0,1.1-.3a1.779,1.779,0,0,0,.7-.8l1,.4a2.85,2.85,0,0,1-1.1,1.3,3.371,3.371,0,0,1-1.7.5Zm-2.4-3.1v-.9h4.8v.9Z" fill="#bfbfbf"/>
          <path id="Path_8507" data-name="Path 8507" d="M61.6,90.3A2.587,2.587,0,0,1,59,88.7a4.711,4.711,0,0,1-.4-1.7,3.287,3.287,0,0,1,.4-1.7,2.94,2.94,0,0,1,1-1.2,3.012,3.012,0,0,1,3,0,2.73,2.73,0,0,1,.9,1.2,4.967,4.967,0,0,1,0,3.4,2.73,2.73,0,0,1-.9,1.2,2.456,2.456,0,0,1-1.5.5Zm.2-1a2.016,2.016,0,0,0,1.1-.3,1.456,1.456,0,0,0,.6-.9,5.067,5.067,0,0,0,.2-1.3,2.745,2.745,0,0,0-.2-1.3,2.034,2.034,0,0,0-.6-.9,1.689,1.689,0,0,0-1-.3,2.016,2.016,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.345,2.345,0,0,0,.2,1.2,2.034,2.034,0,0,0,.6.9,1.609,1.609,0,0,0,1.1.3Zm1.9.8V85.3h-.1V81.4h1.1V90h-1Z" fill="#bfbfbf"/>
        </g>
        <g id="Group_6689" data-name="Group 6689">
          <path id="Path_8508" data-name="Path 8508" d="M152.2,90.1V81.5h4a1.656,1.656,0,0,1,1.2.5,2.016,2.016,0,0,1,.7.9,4.439,4.439,0,0,1,.2,1.3,2.745,2.745,0,0,1-.2,1.3,2.178,2.178,0,0,1-.7.9,3.337,3.337,0,0,1-1.2.5h-2.9v3.2h-1.1Zm1.1-4.2h2.9a1.012,1.012,0,0,0,.7-.4,2.092,2.092,0,0,0,.4-.6,2.5,2.5,0,0,0,0-1.4c0-.2-.2-.4-.4-.6a1.612,1.612,0,0,0-.7-.4h-2.9v3.4Z" fill="#bfbfbf"/>
          <path id="Path_8509" data-name="Path 8509" d="M161.2,90.3A1.558,1.558,0,0,1,160,90c-.3-.3-.6-.4-.7-.7a2.959,2.959,0,0,1-.2-1,1.344,1.344,0,0,1,.2-.9c.2-.3.3-.5.5-.6a2.01,2.01,0,0,1,.8-.4c.3,0,.7-.2,1-.2a5.019,5.019,0,0,0,1.2-.2,3.6,3.6,0,0,0,1.1-.2l-.4.2a2.269,2.269,0,0,0-.3-1.3,1.461,1.461,0,0,0-1.2-.4c-.5,0-.7,0-1,.3a1.456,1.456,0,0,0-.6.9l-1-.3a2.54,2.54,0,0,1,.9-1.3,2.969,2.969,0,0,1,1.8-.5,2.81,2.81,0,0,1,1.5.3,1.789,1.789,0,0,1,.9,1,2.544,2.544,0,0,0,.2.7v4.7h-.9V88.5h.2a1.994,1.994,0,0,1-1,1.3,2.726,2.726,0,0,1-1.6.4Zm.2-.9a1.645,1.645,0,0,0,1-.2,4.233,4.233,0,0,0,.7-.5,1.08,1.08,0,0,0,.3-.7V86.7l.4.2c-.3,0-.7.1-1,.1a3.4,3.4,0,0,0-1,.1,1.884,1.884,0,0,0-.8.2c-.2,0-.3.1-.5.2-.2,0-.3.2-.4.3a4.331,4.331,0,0,0-.1.5.9.9,0,0,0,.1.5.781.781,0,0,0,.4.4,1.445,1.445,0,0,0,.7.2Z" fill="#bfbfbf"/>
          <path id="Path_8510" data-name="Path 8510" d="M168.9,90.3a3.064,3.064,0,0,1-1.7-.4,3.179,3.179,0,0,1-1.1-1.2,4.776,4.776,0,0,1-.4-1.8,3.425,3.425,0,0,1,.4-1.8,2.544,2.544,0,0,1,1.1-1.2,3.064,3.064,0,0,1,1.7-.4,3.453,3.453,0,0,1,1.8.5,2.434,2.434,0,0,1,1,1.4l-1.1.3a1.6,1.6,0,0,0-.7-.9,1.929,1.929,0,0,0-1-.3,2.279,2.279,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.86,2.86,0,0,0,.5,1.7,1.806,1.806,0,0,0,1.5.7c.7,0,.8,0,1.1-.3a2.1,2.1,0,0,0,.6-.8l1.1.3a2.981,2.981,0,0,1-1,1.4,3.1,3.1,0,0,1-1.7.5Z" fill="#bfbfbf"/>
          <path id="Path_8511" data-name="Path 8511" d="M172.9,90.1V81.5H174v5.3l2.7-3.1h1.4l-2.9,3.2,3.2,3.2h-1.5L174,87v3.1Z" fill="#bfbfbf"/>
          <path id="Path_8512" data-name="Path 8512" d="M179.1,82.5V81.4h1.1v1.1Zm0,7.6V83.6h1.1v6.5Z" fill="#bfbfbf"/>
          <path id="Path_8513" data-name="Path 8513" d="M182,90.1V83.6h1v1.7h.1V90H182Zm4.6,0V86c0-.3-.2-.5-.3-.8a1.205,1.205,0,0,0-.5-.5,2,2,0,0,0-.9-.2,1.285,1.285,0,0,0-.7.1,2.092,2.092,0,0,0-.6.4,1.612,1.612,0,0,0-.4.7c0,.3-.1.6-.1,1l-.7-.2a3.808,3.808,0,0,1,.3-1.6,1.651,1.651,0,0,1,1-1,3.708,3.708,0,0,1,1.4-.4,1.815,1.815,0,0,1,1.1.2,4.233,4.233,0,0,1,.7.5,3.039,3.039,0,0,1,.5.7,2.353,2.353,0,0,1,.2.8v4.4h-1Z" fill="#bfbfbf"/>
          <path id="Path_8514" data-name="Path 8514" d="M191.8,90.3a2.587,2.587,0,0,1-2.6-1.6,4.711,4.711,0,0,1-.4-1.7,3.287,3.287,0,0,1,.4-1.7,2.94,2.94,0,0,1,1-1.2,3.012,3.012,0,0,1,3,0,2.73,2.73,0,0,1,.9,1.2,4.967,4.967,0,0,1,0,3.4,2.73,2.73,0,0,1-.9,1.2,2.456,2.456,0,0,1-1.5.5Zm.1,2.9a1.8,1.8,0,0,1-1-.2,4.053,4.053,0,0,1-.9-.5,2.7,2.7,0,0,1-.7-.8l1-.5a2.336,2.336,0,0,0,.7.7,2.92,2.92,0,0,0,.9.2c.3,0,.8,0,1-.2a1.057,1.057,0,0,0,.6-.7,3.6,3.6,0,0,0,.2-1.1V88.3h.1V83.6h.9v7.5a1.433,1.433,0,0,1-.5,1.1,3.592,3.592,0,0,1-1,.7,5.212,5.212,0,0,1-1.4.2Zm0-3.9A2.016,2.016,0,0,0,193,89a1.456,1.456,0,0,0,.6-.9,5.066,5.066,0,0,0,.2-1.3,2.745,2.745,0,0,0-.2-1.3,2.034,2.034,0,0,0-.6-.9,1.689,1.689,0,0,0-1-.3,2.016,2.016,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.345,2.345,0,0,0,.2,1.2,2.034,2.034,0,0,0,.6.9,1.609,1.609,0,0,0,1.1.3Z" fill="#bfbfbf"/>
        </g>
        <g id="Group_6690" data-name="Group 6690">
          <path id="Path_8515" data-name="Path 8515" d="M286.8,90.1V81.5h4a1.656,1.656,0,0,1,1.2.5,2.016,2.016,0,0,1,.7.9,4.438,4.438,0,0,1,.2,1.3,2.86,2.86,0,0,1-.5,1.7,2.551,2.551,0,0,1-1.5.9h-3.1V90h-1.1Zm1.1-4.2h2.9a1.012,1.012,0,0,0,.7-.4,2.092,2.092,0,0,0,.4-.6,2.5,2.5,0,0,0,0-1.4c0-.2-.2-.4-.4-.6a1.612,1.612,0,0,0-.7-.4h-2.9v3.4Zm4.1,4.2-1.7-3.5,1.1-.3,1.9,3.8h-1.2Z" fill="#bfbfbf"/>
          <path id="Path_8516" data-name="Path 8516" d="M297.3,90.3a3.287,3.287,0,0,1-1.7-.4,3.179,3.179,0,0,1-1.1-1.2,4.25,4.25,0,0,1,0-3.6,3.179,3.179,0,0,1,1.1-1.2,2.934,2.934,0,0,1,1.6-.4,3.371,3.371,0,0,1,1.7.5,2.209,2.209,0,0,1,1,1.3,6.013,6.013,0,0,1,.3,2h-1.1v-.4a2.969,2.969,0,0,0-.5-1.8,1.678,1.678,0,0,0-1.4-.6,1.887,1.887,0,0,0-1.5.6,3.49,3.49,0,0,0,0,3.6,1.755,1.755,0,0,0,1.5.6c.7,0,.8,0,1.1-.3a1.779,1.779,0,0,0,.7-.8l1,.4a2.85,2.85,0,0,1-1.1,1.3,3.371,3.371,0,0,1-1.7.5Zm-2.4-3.1v-.9h4.8v.9Z" fill="#bfbfbf"/>
          <path id="Path_8517" data-name="Path 8517" d="M303.3,90.3a1.558,1.558,0,0,1-1.2-.3c-.3-.3-.6-.4-.7-.7a2.958,2.958,0,0,1-.2-1,1.344,1.344,0,0,1,.2-.9c.2-.3.3-.5.5-.6a2.01,2.01,0,0,1,.8-.4c.3,0,.7-.2,1-.2a5.02,5.02,0,0,0,1.2-.2,3.6,3.6,0,0,0,1.1-.2l-.4.2a2.269,2.269,0,0,0-.3-1.3,1.461,1.461,0,0,0-1.2-.4c-.5,0-.7,0-1,.3a1.456,1.456,0,0,0-.6.9l-1-.3a2.54,2.54,0,0,1,.9-1.3,2.969,2.969,0,0,1,1.8-.5,2.81,2.81,0,0,1,1.5.3,1.789,1.789,0,0,1,.9,1,2.544,2.544,0,0,0,.2.7v4.7h-.9V88.5h.2a1.994,1.994,0,0,1-1,1.3,2.726,2.726,0,0,1-1.6.4Zm.2-.9a1.645,1.645,0,0,0,1-.2,4.235,4.235,0,0,0,.7-.5,1.08,1.08,0,0,0,.3-.7V86.7l.4.2c-.3,0-.7.1-1,.1a3.4,3.4,0,0,0-1,.1,1.884,1.884,0,0,0-.8.2c-.2,0-.3.1-.5.2-.2,0-.3.2-.4.3a4.331,4.331,0,0,0-.1.5.9.9,0,0,0,.1.5.781.781,0,0,0,.4.4,1.445,1.445,0,0,0,.7.2Z" fill="#bfbfbf"/>
          <path id="Path_8518" data-name="Path 8518" d="M310.8,90.3a2.587,2.587,0,0,1-2.6-1.6,4.711,4.711,0,0,1-.4-1.7,3.287,3.287,0,0,1,.4-1.7,2.94,2.94,0,0,1,1-1.2,3.012,3.012,0,0,1,3,0,2.73,2.73,0,0,1,.9,1.2,4.967,4.967,0,0,1,0,3.4,2.73,2.73,0,0,1-.9,1.2,2.456,2.456,0,0,1-1.5.5Zm.1-1A2.016,2.016,0,0,0,312,89a1.456,1.456,0,0,0,.6-.9,5.066,5.066,0,0,0,.2-1.3,2.745,2.745,0,0,0-.2-1.3,2.034,2.034,0,0,0-.6-.9,1.689,1.689,0,0,0-1-.3,2.016,2.016,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.019,5.019,0,0,0-.2,1.2,2.345,2.345,0,0,0,.2,1.2,2.034,2.034,0,0,0,.6.9,1.609,1.609,0,0,0,1.1.3Zm1.9.8V85.3h-.1V81.4h1.1V90h-1Z" fill="#bfbfbf"/>
          <path id="Path_8519" data-name="Path 8519" d="M316.3,93l1.3-3.4v1l-2.8-7h1.1l2.2,5.6h-.4l2.1-5.6h1.1L317.4,93h-1.1Z" fill="#bfbfbf"/>
        </g>
      </g>
      <line id="Line_60" x2="52.8" transform="translate(382.8 39.1)" fill="none"/>
      <g id="Group_551">
        <circle id="Ellipse_90" cx="38.5" cy="38.5" r="38.5" transform="translate(400)" fill="#ffdad2"/>
        <circle id="Ellipse_85" cx="30.5" cy="30.5" r="30.5" transform="translate(408 8)" fill="#ff775b"/>
      </g>
      <circle id="Ellipse_87" cx="5" cy="5" r="5" transform="translate(395 35)" fill="#bfbfbf"/>
      <g id="Group_6714" data-name="Group 6714" transform="translate(-183 -5)">
        <path id="Path_8664" data-name="Path 8664" d="M589,98.6V88.7h4.9a2.246,2.246,0,0,1,1.4.6,2.1,2.1,0,0,1,.8,1.1,3.339,3.339,0,0,1,.3,1.5,4.2,4.2,0,0,1-.6,2.1,2.789,2.789,0,0,1-1.9,1.1h-3.3v3.5h-1.7Zm1.6-5h3a1.012,1.012,0,0,0,.7-.4.864.864,0,0,0,.3-.6V91.3c0-.3-.2-.4-.3-.6a1.612,1.612,0,0,0-.7-.4h-3Zm4.1,5-2-4,1.7-.4,2.1,4.4h-1.8Z"/>
        <path id="Path_8665" data-name="Path 8665" d="M601.5,98.8a4.228,4.228,0,0,1-2-.5,2.949,2.949,0,0,1-1.3-1.4,4.228,4.228,0,0,1-.5-2,4.649,4.649,0,0,1,.5-2.1,2.949,2.949,0,0,1,1.3-1.4,4.136,4.136,0,0,1,1.9-.5,4.552,4.552,0,0,1,2,.5,4.427,4.427,0,0,1,1.2,1.5,4.175,4.175,0,0,1,.3,2.3h-1.6v-.6c0-.8,0-1.4-.4-1.8a1.486,1.486,0,0,0-1.3-.6,1.887,1.887,0,0,0-1.5.6,2.969,2.969,0,0,0-.5,1.8,2.648,2.648,0,0,0,.5,1.7,1.678,1.678,0,0,0,1.4.6c.6,0,.8,0,1.1-.3a1.853,1.853,0,0,0,.7-.8l1.6.5a3.435,3.435,0,0,1-1.3,1.6,3.379,3.379,0,0,1-2,.6v.3Zm-2.6-3.4V94.1H604v1.3Z"/>
        <path id="Path_8666" data-name="Path 8666" d="M605.8,92.5V91.2h4.7v1.3Zm1.2,6.1V89.7c0-.2.2-.4.4-.6a1.794,1.794,0,0,1,.7-.5.952.952,0,0,0,.7-.2h1.5v1.3h-.9c-.3,0-.6,0-.7.2a.735.735,0,0,0-.2.6v7.9h-1.7v.2Z"/>
        <path id="Path_8667" data-name="Path 8667" d="M614.3,98.8a2.233,2.233,0,0,1-1.4-.3,3.114,3.114,0,0,1-.9-.7,4.052,4.052,0,0,1-.5-.9,2.921,2.921,0,0,1-.2-.9V91.1H613v4.4a1.077,1.077,0,0,0,.2.8c0,.3.3.5.5.7a1.222,1.222,0,0,0,.9.3h.6c.2,0,.4-.2.6-.4a1.612,1.612,0,0,0,.4-.7c0-.3.2-.7.2-1.1l1,.4a3.339,3.339,0,0,1-1.5,2.9,3.453,3.453,0,0,1-1.8.5h.2Zm2.5-.2V96.3h-.2V91.2h1.7v7.4Z"/>
        <path id="Path_8668" data-name="Path 8668" d="M620.2,98.6V91.2h1.5v2.3h.2v5.1Zm5.1,0V94.2a1.077,1.077,0,0,0-.2-.8c0-.3-.3-.5-.5-.7a1.222,1.222,0,0,0-.9-.3h-.6c-.2,0-.4.2-.6.4a1.612,1.612,0,0,0-.4.7c0,.3-.2.7-.2,1.1l-1-.4a3.339,3.339,0,0,1,1.5-2.9,3.453,3.453,0,0,1,1.8-.5,1.959,1.959,0,0,1,1.4.3,3.114,3.114,0,0,1,.9.7,2.043,2.043,0,0,1,.5.9,2.921,2.921,0,0,1,.2.9v4.9h-1.9Z"/>
        <path id="Path_8669" data-name="Path 8669" d="M631.6,98.8a3.453,3.453,0,0,1-1.8-.5,3.325,3.325,0,0,1-1.2-1.4,4.477,4.477,0,0,1-.4-2,3.444,3.444,0,0,1,.4-2,4.213,4.213,0,0,1,1.2-1.4,3.49,3.49,0,0,1,3.6,0,3.142,3.142,0,0,1,1.1,1.4,6.465,6.465,0,0,1,.4,2,3.444,3.444,0,0,1-.4,2,2.586,2.586,0,0,1-1.1,1.4A3.453,3.453,0,0,1,631.6,98.8Zm.3-1.4a1.431,1.431,0,0,0,1.1-.3,1.456,1.456,0,0,0,.6-.9,5.068,5.068,0,0,0,.2-1.3,2.745,2.745,0,0,0-.2-1.3c0-.4-.3-.6-.6-.9a1.689,1.689,0,0,0-1-.3,1.431,1.431,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.021,5.021,0,0,0-.2,1.2,2.345,2.345,0,0,0,.2,1.2,1.149,1.149,0,0,0,.6.9,1.8,1.8,0,0,0,1.1.3v.2Zm1.9,1.2V93.4h-.2V88.7h1.7v9.9h-1.5Z"/>
        <path id="Path_8670" data-name="Path 8670" d="M640.5,98.8a4.228,4.228,0,0,1-2-.5,2.949,2.949,0,0,1-1.3-1.4,4.228,4.228,0,0,1-.5-2,4.649,4.649,0,0,1,.5-2.1,2.949,2.949,0,0,1,1.3-1.4,4.136,4.136,0,0,1,1.9-.5,4.552,4.552,0,0,1,2,.5,4.427,4.427,0,0,1,1.2,1.5,4.175,4.175,0,0,1,.3,2.3h-1.6v-.6c0-.8,0-1.4-.4-1.8a1.486,1.486,0,0,0-1.3-.6,1.887,1.887,0,0,0-1.5.6,2.969,2.969,0,0,0-.5,1.8,2.648,2.648,0,0,0,.5,1.7,1.678,1.678,0,0,0,1.4.6c.6,0,.8,0,1.1-.3a1.853,1.853,0,0,0,.7-.8l1.6.5a3.435,3.435,0,0,1-1.3,1.6,3.379,3.379,0,0,1-2,.6v.3ZM638,95.4V94.1h5.1v1.3Z"/>
        <path id="Path_8671" data-name="Path 8671" d="M648.4,98.8a3.453,3.453,0,0,1-1.8-.5,3.325,3.325,0,0,1-1.2-1.4,4.477,4.477,0,0,1-.4-2,3.444,3.444,0,0,1,.4-2,4.213,4.213,0,0,1,1.2-1.4,3.49,3.49,0,0,1,3.6,0,3.142,3.142,0,0,1,1.1,1.4,6.465,6.465,0,0,1,.4,2,3.444,3.444,0,0,1-.4,2,2.586,2.586,0,0,1-1.1,1.4A3.453,3.453,0,0,1,648.4,98.8Zm.3-1.4a1.431,1.431,0,0,0,1.1-.3,1.456,1.456,0,0,0,.6-.9,5.068,5.068,0,0,0,.2-1.3,2.745,2.745,0,0,0-.2-1.3c0-.4-.3-.6-.6-.9a1.689,1.689,0,0,0-1-.3,1.431,1.431,0,0,0-1.1.3,1.456,1.456,0,0,0-.6.9,5.021,5.021,0,0,0-.2,1.2,2.345,2.345,0,0,0,.2,1.2,1.149,1.149,0,0,0,.6.9,1.8,1.8,0,0,0,1.1.3v.2Zm1.8,1.2V93.4h-.2V88.7H652v9.9h-1.5Z"/>
      </g>
      <g id="refund" transform="translate(-181.793 -1)">
        <g id="Group_5852">
          <g id="Group_5851">
            <path id="Path_7004" d="M627.8,30l-1.3,1.7a10.016,10.016,0,1,1-5.6-2.1l-1.9,2,1.4,1.3,3-3.1h0l1.3-1.4-1.4-1.3h0l-3.1-3-1.3,1.4,2,1.9a12.233,12.233,0,0,0-2.5,24.3c.6,0,1.3.2,1.9.2a12.206,12.206,0,0,0,12.2-12.3c0-6.8-1.8-7.3-4.7-9.6h0Z" fill="#fff"/>
            <path id="Path_7005" d="M621.5,45.9h0V44.6a2.9,2.9,0,0,0,2.9-2.9c0-2.4-2.3-2.7-3.9-2.9-1.1,0-1.9-.3-1.9-.9s1.2-1,1.8-1a2.463,2.463,0,0,1,1.9.9h0l1.7-.6V37a3.444,3.444,0,0,0-2.6-1.8V34.1h-1.9v1.1c-1.8.3-2.9,1.3-2.9,2.7,0,2.3,2.1,2.6,3.7,2.7,1.4.2,2,.5,2,1.1,0,1.1-1.5,1.2-2,1.2a2.676,2.676,0,0,1-2.3-1.2v-.2l-1.9.8v.2a4.04,4.04,0,0,0,3.3,2.2v1.2h2.1Z" fill="#fff"/>
          </g>
        </g>
      </g>
      </svg>
    );
  const statusShippedDesktop = // Done
    (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // width="660"
        // height="104.9"
        viewBox="0 0 660 104.9"
      >
        <g
          id="d_s_shipped_1"
          data-name="d_s_shipped 1"
          transform="translate(-6259 -1849)"
        >
          <g
            id="Group_4699"
            data-name="Group 4699"
            transform="translate(-13081 -1397)"
            opacity="0.53"
          >
            <line
              id="Line_64"
              data-name="Line 64"
              x2="96.886"
              transform="translate(19400.283 3285.104)"
              fill="none"
              stroke="#2ec278"
              strokeWidth="1"
              opacity="0"
            />
            <line
              id="Line_59"
              data-name="Line 59"
              x2="133.072"
              transform="translate(19409.621 3285.104)"
              fill="none"
              stroke="#2ec278"
              strokeWidth="1"
            />
            <line
              id="Line_63"
              data-name="Line 63"
              x2="158.753"
              transform="translate(19571.877 3285.104)"
              fill="none"
              stroke="#ff9557"
              strokeWidth="1"
            />
            <circle
              id="Ellipse_86"
              data-name="Ellipse 86"
              cx="38.5"
              cy="38.5"
              r="38.5"
              transform="translate(19340 3246)"
              fill="#ffd5d5"
              opacity="0"
            />
            <circle
              id="Ellipse_82"
              data-name="Ellipse 82"
              cx="30.5"
              cy="30.5"
              r="30.5"
              transform="translate(19348 3254)"
              fill="#2ec278"
            />
            <g
              id="Group_552"
              data-name="Group 552"
              transform="translate(19535.553 3246)"
            >
              <circle
                id="Ellipse_88"
                data-name="Ellipse 88"
                cx="38.5"
                cy="38.5"
                r="38.5"
                transform="translate(0.447 0)"
                fill="#acffd6"
                opacity="0"
              />
              <path
                id="Path_638"
                data-name="Path 638"
                d="M30.85,0A30.85,30.85,0,1,1,0,30.85,30.85,30.85,0,0,1,30.85,0Z"
                transform="translate(7.725 8.171)"
                fill="#ffc72e"
              />
              <g
                id="inventory_2_black_24dp_1_"
                data-name="inventory_2_black_24dp (1)"
                transform="translate(25.447 26)"
              >
                <g id="Group_4910" data-name="Group 4910">
                  <rect
                    id="Rectangle_1309"
                    data-name="Rectangle 1309"
                    width="26"
                    height="26"
                    transform="translate(0 0)"
                    fill="none"
                  />
                </g>
                <g
                  id="Group_4911"
                  data-name="Group 4911"
                  transform="translate(2.6 2.6)"
                >
                  <path
                    id="Path_3926"
                    data-name="Path 3926"
                    d="M20.72,2H4.08A2.157,2.157,0,0,0,2,4.08V7.21A2.1,2.1,0,0,0,3.04,8.968V20.72A2.235,2.235,0,0,0,5.12,22.8H19.68a2.235,2.235,0,0,0,2.08-2.08V8.968A2.1,2.1,0,0,0,22.8,7.21V4.08A2.157,2.157,0,0,0,20.72,2Zm-5.2,12.48H9.28V12.4h6.24Zm5.2-7.28H4.08V4.08H20.72Z"
                    transform="translate(-2 -2)"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_6941"
              data-name="Path 6941"
              d="M7.085,0H8.333L5.07-9.009H3.705L.429,0H1.677L2.4-2.015H6.357ZM6.019-2.977H2.743L4.381-7.553Zm3.4-.6A3.393,3.393,0,0,0,12.889.117a3.169,3.169,0,0,0,3.289-2.405H14.9a1.911,1.911,0,0,1-2.015,1.4c-1.3,0-2.262-.923-2.262-2.691,0-1.742.962-2.665,2.262-2.665a1.941,1.941,0,0,1,2.015,1.4h1.274a3.1,3.1,0,0,0-3.289-2.405A3.385,3.385,0,0,0,9.418-3.575Zm7.988,0A3.393,3.393,0,0,0,20.878.117a3.169,3.169,0,0,0,3.289-2.405H22.893a1.911,1.911,0,0,1-2.015,1.4c-1.3,0-2.262-.923-2.262-2.691,0-1.742.962-2.665,2.262-2.665a1.941,1.941,0,0,1,2.015,1.4h1.274a3.1,3.1,0,0,0-3.289-2.405A3.385,3.385,0,0,0,17.407-3.575ZM28.867-6.24a2.09,2.09,0,0,1,2.249,2.158h-4.5A2.206,2.206,0,0,1,28.867-6.24ZM32.194-2.2H30.92a1.958,1.958,0,0,1-2,1.313A2.239,2.239,0,0,1,26.6-3.12H32.3a6.412,6.412,0,0,0,.039-.715,3.266,3.266,0,0,0-3.419-3.406A3.39,3.39,0,0,0,25.4-3.575,3.432,3.432,0,0,0,28.919.117,3.2,3.2,0,0,0,32.194-2.2Zm2.984-3.614V-7.124H33.995V3.38h1.183V-1.313A3.082,3.082,0,0,0,37.843.117a3.428,3.428,0,0,0,3.38-3.705,3.376,3.376,0,0,0-3.38-3.653A3.009,3.009,0,0,0,35.178-5.811Zm4.836,2.223A2.442,2.442,0,0,1,37.6-.923a2.445,2.445,0,0,1-2.418-2.652A2.448,2.448,0,0,1,37.6-6.214,2.408,2.408,0,0,1,40.014-3.588ZM43.141-1.95c0,1.43.715,1.95,1.976,1.95h1.066V-1h-.871c-.728,0-.988-.247-.988-.949v-4.2h1.859v-.975H44.324V-8.918H43.141v1.794h-.923v.975h.923Zm7.6-4.29a2.09,2.09,0,0,1,2.249,2.158h-4.5A2.206,2.206,0,0,1,50.739-6.24ZM54.067-2.2H52.793a1.958,1.958,0,0,1-2,1.313A2.239,2.239,0,0,1,48.477-3.12h5.694a6.412,6.412,0,0,0,.039-.715,3.266,3.266,0,0,0-3.419-3.406,3.39,3.39,0,0,0-3.523,3.666A3.432,3.432,0,0,0,50.791.117,3.2,3.2,0,0,0,54.067-2.2Zm1.358-1.391A3.428,3.428,0,0,0,58.806.117a2.926,2.926,0,0,0,2.652-1.456V0h1.2V-9.62h-1.2v3.77a3.107,3.107,0,0,0-2.639-1.391A3.379,3.379,0,0,0,55.425-3.588Zm6.032.013A2.438,2.438,0,0,1,59.04-.923a2.44,2.44,0,0,1-2.4-2.665,2.4,2.4,0,0,1,2.4-2.626A2.441,2.441,0,0,1,61.458-3.575Z"
              transform="translate(19348 3340)"
              fill="#c9c9c9"
            />
            <path
              id="Path_6942"
              data-name="Path 6942"
              d="M-23.712-4.758V-8.086h1.742c1.352,0,1.924.624,1.924,1.677,0,1.014-.572,1.651-1.924,1.651Zm4.875-1.651c0-1.482-1-2.652-3.133-2.652H-24.9V0h1.183V-3.783h1.742C-19.721-3.783-18.837-5.031-18.837-6.409Zm1.125,2.821A3.426,3.426,0,0,0-14.346.117a2.975,2.975,0,0,0,2.665-1.443V0h1.2V-7.124h-1.2v1.3a2.956,2.956,0,0,0-2.652-1.417A3.376,3.376,0,0,0-17.713-3.588Zm6.032.013A2.438,2.438,0,0,1-14.1-.923,2.44,2.44,0,0,1-16.5-3.588,2.4,2.4,0,0,1-14.1-6.214,2.441,2.441,0,0,1-11.681-3.575Zm2.853,0A3.393,3.393,0,0,0-5.356.117,3.169,3.169,0,0,0-2.067-2.288H-3.341a1.911,1.911,0,0,1-2.015,1.4c-1.3,0-2.262-.923-2.262-2.691,0-1.742.962-2.665,2.262-2.665a1.941,1.941,0,0,1,2.015,1.4h1.274A3.1,3.1,0,0,0-5.356-7.241,3.385,3.385,0,0,0-8.827-3.575ZM-.4,0H.786V-3.146L3.581,0H5.193L1.826-3.575,5.18-7.124H3.529L.786-3.965V-9.62H-.4ZM6.4,0H7.579V-7.124H6.4Zm.611-8.281a.788.788,0,0,0,.78-.806.788.788,0,0,0-.78-.806.794.794,0,0,0-.806.806A.794.794,0,0,0,7.007-8.281ZM14.9,0h1.17V-4.2a2.764,2.764,0,0,0-2.912-3.055A2.632,2.632,0,0,0,10.875-6.11V-7.124H9.692V0h1.183V-3.939c0-1.534.832-2.288,2.041-2.288s1.989.741,1.989,2.2Zm2.762-3.588A3.428,3.428,0,0,0,21.047.117,2.977,2.977,0,0,0,23.7-1.339v1.5a2.114,2.114,0,0,1-2.21,2.327A2.212,2.212,0,0,1,19.214,1.04h-1.17c.234,1.521,1.573,2.47,3.445,2.47A3.194,3.194,0,0,0,24.9.156v-7.28H23.7v1.3a2.936,2.936,0,0,0-2.652-1.417A3.376,3.376,0,0,0,17.667-3.588Zm6.032.013A2.438,2.438,0,0,1,21.281-.923a2.44,2.44,0,0,1-2.405-2.665,2.4,2.4,0,0,1,2.405-2.626A2.441,2.441,0,0,1,23.7-3.575Z"
              transform="translate(19576 3340)"
              fill="#c9c9c9"
            />
            <g id="verified-24px" transform="translate(19364.863 3271.862)">
              <g
                id="Group_564"
                data-name="Group 564"
                transform="translate(0 0)"
              >
                <rect
                  id="Rectangle_235"
                  data-name="Rectangle 235"
                  width="28"
                  height="28"
                  transform="translate(0.137 0.138)"
                  fill="none"
                />
              </g>
              <g
                id="Group_565"
                data-name="Group 565"
                transform="translate(0.985 1.569)"
              >
                <path
                  id="Path_623"
                  data-name="Path 623"
                  d="M26.681,13.757,23.832,10.5l.4-4.307-4.214-.957L17.809,1.5,13.84,3.2,9.871,1.5,7.665,5.224l-4.214.946.4,4.319L1,13.757l2.848,3.257-.4,4.319,4.214.957,2.206,3.724L13.84,24.3,17.809,26l2.206-3.724,4.214-.957-.4-4.307Zm-15.07,5.51L7.175,14.819,8.9,13.091l2.708,2.72,6.829-6.852,1.728,1.728Z"
                  transform="translate(-1 -1.5)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <line
            id="Line_65"
            data-name="Line 65"
            x2="89.882"
            transform="translate(6696.32 1888.104)"
            fill="none"
            stroke="#40bac4"
            strokeWidth="1"
          />
          <line
            id="Line_60"
            data-name="Line 60"
            x2="91.049"
            transform="translate(6786.203 1888.104)"
            fill="none"
            stroke="#d4d4d4"
            strokeWidth="1"
          />
          <g
            id="Group_550"
            data-name="Group 550"
            transform="translate(6643.863 1849)"
          >
            <circle
              id="Ellipse_89"
              data-name="Ellipse 89"
              cx="38.5"
              cy="38.5"
              r="38.5"
              transform="translate(0.137 0)"
              fill="#cffbff"
            />
            <circle
              id="Ellipse_84"
              data-name="Ellipse 84"
              cx="30.5"
              cy="30.5"
              r="30.5"
              transform="translate(8.137 8)"
              fill="#40bac4"
            />
          </g>
          <g
            id="Group_551"
            data-name="Group 551"
            transform="translate(6841.449 1849)"
          >
            <ellipse
              id="Ellipse_90"
              data-name="Ellipse 90"
              cx="39"
              cy="38.5"
              rx="39"
              ry="38.5"
              transform="translate(-0.449 0)"
              fill="#cffbff"
              opacity="0"
            />
            <circle
              id="Ellipse_85"
              data-name="Ellipse 85"
              cx="30.5"
              cy="30.5"
              r="30.5"
              transform="translate(8.551 8)"
              fill="#f1f1f1"
            />
          </g>
          <path
            id="Path_6943"
            data-name="Path 6943"
            d="M-23.295-2.85c0-3.87-5.64-2.19-5.64-4.89,0-1.125.885-1.71,2.01-1.68A1.894,1.894,0,0,1-24.9-7.905h1.5c-.165-1.59-1.47-2.685-3.465-2.685-2.07,0-3.465,1.17-3.465,2.895,0,3.885,5.655,2.085,5.655,4.905,0,.99-.78,1.74-2.1,1.74a1.937,1.937,0,0,1-2.16-1.74H-30.39c.03,1.725,1.515,2.895,3.615,2.895C-24.51.105-23.295-1.365-23.295-2.85ZM-21.172,0h1.365V-4.545c0-1.77.96-2.64,2.355-2.64s2.3.855,2.3,2.535V0h1.35V-4.845A3.171,3.171,0,0,0-17.092-8.37a3.093,3.093,0,0,0-2.715,1.335V-11.1h-1.365Zm9.712,0h1.365V-8.22H-11.46Zm.705-9.555a.91.91,0,0,0,.9-.93.91.91,0,0,0-.9-.93.916.916,0,0,0-.93.93A.916.916,0,0,0-10.755-9.555Zm4.462,2.85V-8.22H-7.657V3.9h1.365V-1.515A3.556,3.556,0,0,0-3.217.135,3.956,3.956,0,0,0,.682-4.14a3.9,3.9,0,0,0-3.9-4.215A3.472,3.472,0,0,0-6.292-6.705ZM-.712-4.14A2.818,2.818,0,0,1-3.5-1.065a2.821,2.821,0,0,1-2.79-3.06A2.825,2.825,0,0,1-3.5-7.17,2.778,2.778,0,0,1-.712-4.14ZM3.96-6.705V-8.22H2.595V3.9H3.96V-1.515A3.556,3.556,0,0,0,7.035.135a3.956,3.956,0,0,0,3.9-4.275,3.9,3.9,0,0,0-3.9-4.215A3.472,3.472,0,0,0,3.96-6.705ZM9.54-4.14A2.818,2.818,0,0,1,6.75-1.065a2.821,2.821,0,0,1-2.79-3.06A2.825,2.825,0,0,1,6.75-7.17,2.778,2.778,0,0,1,9.54-4.14Zm6.8-3.06a2.412,2.412,0,0,1,2.6,2.49h-5.19A2.546,2.546,0,0,1,16.342-7.2Zm3.84,4.665h-1.47A2.259,2.259,0,0,1,16.4-1.02a2.584,2.584,0,0,1-2.67-2.58H20.3a7.4,7.4,0,0,0,.045-.825A3.768,3.768,0,0,0,16.4-8.355a3.911,3.911,0,0,0-4.065,4.23A3.96,3.96,0,0,0,16.4.135,3.7,3.7,0,0,0,20.182-2.535ZM21.75-4.14A3.956,3.956,0,0,0,25.65.135a3.376,3.376,0,0,0,3.06-1.68V0h1.38V-11.1H28.71v4.35a3.585,3.585,0,0,0-3.045-1.605A3.9,3.9,0,0,0,21.75-4.14Zm6.96.015a2.813,2.813,0,0,1-2.79,3.06A2.815,2.815,0,0,1,23.145-4.14,2.775,2.775,0,0,1,25.92-7.17,2.816,2.816,0,0,1,28.71-4.125Z"
            transform="translate(6683 1950)"
          />
          <path
            id="Path_6944"
            data-name="Path 6944"
            d="M-26.917-9.061h-2.821V0h2.821c2.977,0,4.81-1.7,4.81-4.511S-23.94-9.061-26.917-9.061ZM-28.555-.975V-8.1h1.638a3.268,3.268,0,0,1,3.6,3.588c0,2.21-1.235,3.536-3.6,3.536ZM-17.42-6.24a2.09,2.09,0,0,1,2.249,2.158h-4.5A2.206,2.206,0,0,1-17.42-6.24ZM-14.092-2.2h-1.274a1.958,1.958,0,0,1-2,1.313A2.239,2.239,0,0,1-19.682-3.12h5.694a6.412,6.412,0,0,0,.039-.715,3.266,3.266,0,0,0-3.419-3.406,3.39,3.39,0,0,0-3.523,3.666A3.432,3.432,0,0,0-17.368.117,3.2,3.2,0,0,0-14.092-2.2Zm1.8,2.2h1.183V-9.62h-1.183ZM-9,0h1.183V-7.124H-9Zm.611-8.281a.788.788,0,0,0,.78-.806.788.788,0,0,0-.78-.806.794.794,0,0,0-.806.806A.794.794,0,0,0-8.385-8.281Zm1.839,1.157L-3.751,0h1.378l2.8-7.124H-.839l-2.21,6.032L-5.272-7.124ZM4.719-6.24A2.09,2.09,0,0,1,6.968-4.082H2.47A2.206,2.206,0,0,1,4.719-6.24ZM8.047-2.2H6.773a1.958,1.958,0,0,1-2,1.313A2.239,2.239,0,0,1,2.457-3.12H8.151a6.412,6.412,0,0,0,.039-.715A3.266,3.266,0,0,0,4.771-7.241,3.39,3.39,0,0,0,1.248-3.575,3.432,3.432,0,0,0,4.771.117,3.2,3.2,0,0,0,8.047-2.2ZM11.03-3.874c0-1.651.858-2.158,1.989-2.158h.312V-7.254a2.4,2.4,0,0,0-2.3,1.287V-7.124H9.847V0H11.03ZM17.823-6.24a2.09,2.09,0,0,1,2.249,2.158h-4.5A2.206,2.206,0,0,1,17.823-6.24ZM21.151-2.2H19.877a1.958,1.958,0,0,1-2,1.313A2.239,2.239,0,0,1,15.561-3.12h5.694a6.412,6.412,0,0,0,.039-.715,3.266,3.266,0,0,0-3.419-3.406,3.39,3.39,0,0,0-3.523,3.666A3.432,3.432,0,0,0,17.875.117,3.2,3.2,0,0,0,21.151-2.2Zm1.359-1.391A3.428,3.428,0,0,0,25.889.117a2.926,2.926,0,0,0,2.652-1.456V0h1.2V-9.62h-1.2v3.77A3.107,3.107,0,0,0,25.9-7.241,3.379,3.379,0,0,0,22.509-3.588Zm6.032.013A2.438,2.438,0,0,1,26.123-.923a2.44,2.44,0,0,1-2.405-2.665,2.4,2.4,0,0,1,2.405-2.626A2.441,2.441,0,0,1,28.542-3.575Z"
            transform="translate(6879 1942)"
            fill="#c9c9c9"
          />
          <circle
            id="Ellipse_87"
            data-name="Ellipse 87"
            cx="5"
            cy="5"
            r="5"
            transform="translate(6781 1883)"
            fill="#40bac4"
          />
          <g id="local_shipping_black_24dp" transform="translate(6671 1875)">
            <path
              id="Path_3927"
              data-name="Path 3927"
              d="M0,0H26V26H0Z"
              fill="none"
            />
            <path
              id="Path_3928"
              data-name="Path 3928"
              d="M21.256,7.955h-3.2V4H3.132A2.065,2.065,0,0,0,1,5.977V16.852H3.132a3.085,3.085,0,0,0,3.2,2.966,3.085,3.085,0,0,0,3.2-2.966h6.4a3.085,3.085,0,0,0,3.2,2.966,3.085,3.085,0,0,0,3.2-2.966h2.132V11.909ZM6.331,18.335a1.487,1.487,0,1,1,0-2.966,1.487,1.487,0,1,1,0,2.966Zm14.393-8.9,2.09,2.472H18.058V9.438Zm-1.6,8.9a1.487,1.487,0,1,1,1.6-1.483A1.542,1.542,0,0,1,19.124,18.335Z"
              transform="translate(0.273 1.091)"
              fill="#fff"
            />
          </g>
          <g
            id="thumbs_up"
            data-name="thumbs up"
            transform="translate(6868.667 1873.71)"
          >
            <g
              id="Group_676"
              data-name="Group 676"
              transform="translate(7.556 4.584)"
            >
              <g id="Group_675" data-name="Group 675">
                <path
                  id="Path_672"
                  data-name="Path 672"
                  d="M196.111,103.139a1.516,1.516,0,0,0-1.511-1.511,1.511,1.511,0,0,0,0-3.022h-6.2a13.176,13.176,0,0,0,.907-5.339A2.273,2.273,0,0,0,187.044,91h-.453a.781.781,0,0,0-.725.574c-.748,2.91-1.916,6.25-4.866,6.927v10.934l2.614.868a7.533,7.533,0,0,0,2.388.393h7.087a1.511,1.511,0,1,0,0-3.022H194.6a1.511,1.511,0,0,0,0-3.022A1.516,1.516,0,0,0,196.111,103.139Z"
                  transform="translate(-181 -91)"
                  fill="#d9d9d9"
                />
              </g>
            </g>
            <g
              id="Group_678"
              data-name="Group 678"
              transform="translate(0 10.679)"
            >
              <g id="Group_677" data-name="Group 677">
                <path
                  id="Path_673"
                  data-name="Path 673"
                  d="M34.778,212H31.756a.755.755,0,0,0-.756.756v13.6a.755.755,0,0,0,.756.756h3.022a2.269,2.269,0,0,0,2.267-2.267V214.267A2.269,2.269,0,0,0,34.778,212Zm0,12.089a.756.756,0,1,1,.756-.756A.756.756,0,0,1,34.778,224.089Z"
                  transform="translate(-31 -212)"
                  fill="#d9d9d9"
                />
              </g>
            </g>
            <g
              id="Group_680"
              data-name="Group 680"
              transform="translate(8.036 2.042)"
            >
              <g id="Group_679" data-name="Group 679">
                <path
                  id="Path_674"
                  data-name="Path 674"
                  d="M192.895,41.827l-1.068-1.068a.755.755,0,0,0-1.068,1.068l1.068,1.068a.755.755,0,1,0,1.068-1.068Z"
                  transform="translate(-190.537 -40.537)"
                  fill="#d9d9d9"
                />
              </g>
            </g>
            <g
              id="Group_682"
              data-name="Group 682"
              transform="translate(16.585 2.042)"
            >
              <g id="Group_681" data-name="Group 681">
                <path
                  id="Path_675"
                  data-name="Path 675"
                  d="M362.611,40.758a.755.755,0,0,0-1.068,0l-1.068,1.068a.755.755,0,1,0,1.068,1.068l1.068-1.068A.755.755,0,0,0,362.611,40.758Z"
                  transform="translate(-360.253 -40.537)"
                  fill="#d9d9d9"
                />
              </g>
            </g>
            <g
              id="Group_684"
              data-name="Group 684"
              transform="translate(12.845)"
            >
              <g id="Group_683" data-name="Group 683">
                <path
                  id="Path_676"
                  data-name="Path 676"
                  d="M286.756,0A.748.748,0,0,0,286,.756V2.317a.756.756,0,0,0,1.511,0V.756A.748.748,0,0,0,286.756,0Z"
                  transform="translate(-286)"
                  fill="#d9d9d9"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );

  const statusShippedMobile = // Done
    (
      <svg
        id="d_s_shipped_1"
        data-name="d s shipped 1"
        xmlns="http://www.w3.org/2000/svg"
        // width="480"
        // height="104.9"
        viewBox="0 0 480 104.9"
      >
        <g id="Group_4699" data-name="Group 4699" opacity="0.5">
          <line
            id="Line_64"
            data-name="Line 64"
            x2="36.9"
            transform="translate(60.3 39.1)"
            fill="none"
            stroke="rgba(46,194,120,0)"
            strokeWidth="1"
          />
          <line
            id="Line_59"
            data-name="Line 59"
            x2="73.1"
            transform="translate(69.6 39.1)"
            fill="none"
            stroke="#2ec278"
            strokeWidth="1"
          />
          <line
            id="Line_63"
            data-name="Line 63"
            x2="98.7"
            transform="translate(171.9 39.1)"
            fill="none"
            stroke="#ff9557"
            strokeWidth="1"
          />
          <circle
            id="Ellipse_86"
            data-name="Ellipse 86"
            cx="38.5"
            cy="38.5"
            r="38.5"
            fill="rgba(255,213,213,0)"
          />
          <circle
            id="Ellipse_82"
            data-name="Ellipse 82"
            cx="30.5"
            cy="30.5"
            r="30.5"
            transform="translate(8 8)"
            fill="#2ec278"
          />
          <g id="Group_552" data-name="Group 552">
            <circle
              id="Ellipse_88"
              data-name="Ellipse 88"
              cx="38.5"
              cy="38.5"
              r="38.5"
              transform="translate(136)"
              fill="rgba(172,255,214,0)"
            />
            <path
              id="Path_638"
              data-name="Path 638"
              d="M174.1,8.2a30.9,30.9,0,1,1-30.9,30.9h0A30.92,30.92,0,0,1,174.1,8.2Z"
              fill="#ffc72e"
            />
            <g id="inventory_2_black_24dp" data-name="inventory 2 black 24dp">
              <g id="Group_4910" data-name="Group 4910">
                <rect
                  id="Rectangle_1309"
                  data-name="Rectangle 1309"
                  width="26"
                  height="26"
                  transform="translate(161 26)"
                  fill="none"
                />
              </g>
              <g id="Group_4911" data-name="Group 4911">
                <path
                  id="Path_3926"
                  data-name="Path 3926"
                  d="M182.3,28.6H165.7a2.18,2.18,0,0,0-2.1,2.1v3.1a2.234,2.234,0,0,0,1,1.8V47.4a2.258,2.258,0,0,0,2.1,2.1h14.6a2.258,2.258,0,0,0,2.1-2.1V35.6a2.1,2.1,0,0,0,1-1.8V30.7a2.18,2.18,0,0,0-2.1-2.1Zm-5.2,12.5h-6.2V39h6.2Zm5.2-7.3H165.7V30.7h16.6Z"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <path
            id="Path_6941"
            data-name="Path 6941"
            d="M15.1,94h1.2L13,85H11.6L8.3,94H9.5l.7-2h4l.7,2ZM14,91H10.7l1.6-4.6L13.9,91Zm3.4-.6a3.381,3.381,0,0,0,3.1,3.7h.4a3.223,3.223,0,0,0,3.3-2.4H22.9a1.856,1.856,0,0,1-2,1.4c-1.3,0-2.3-.9-2.3-2.7s1-2.7,2.3-2.7a1.958,1.958,0,0,1,2,1.4h1.3a3.081,3.081,0,0,0-3.3-2.4A3.438,3.438,0,0,0,17.4,90v.4h0Zm8,0a3.381,3.381,0,0,0,3.1,3.7h.4a3.223,3.223,0,0,0,3.3-2.4H30.9a1.856,1.856,0,0,1-2,1.4c-1.3,0-2.3-.9-2.3-2.7s1-2.7,2.3-2.7a1.958,1.958,0,0,1,2,1.4h1.3a3.081,3.081,0,0,0-3.3-2.4A3.438,3.438,0,0,0,25.4,90v.4h0Zm11.5-2.6a2.042,2.042,0,0,1,2.2,1.9v.2H34.6a2.242,2.242,0,0,1,2.3-2.2Zm3.3,4H38.9a1.956,1.956,0,0,1-2,1.3,2.241,2.241,0,0,1-2.3-2.2h5.7v-.7a3.315,3.315,0,0,0-3.1-3.4h-.3A3.359,3.359,0,0,0,33.4,90v.4a3.531,3.531,0,0,0,3.2,3.7H37a3.367,3.367,0,0,0,3.3-2.3Zm3-3.6V86.9H42V97.4h1.2V92.7a2.968,2.968,0,0,0,2.7,1.4,3.458,3.458,0,0,0,3.4-3.5v-.2a3.239,3.239,0,0,0-3.1-3.6h-.3a3.189,3.189,0,0,0-2.7,1.4h0ZM48,90.4a2.605,2.605,0,0,1-2.2,2.7h-.2a2.393,2.393,0,0,1-2.4-2.5v-.2a2.443,2.443,0,0,1,2.2-2.6h.2A2.433,2.433,0,0,1,48,90.2v.2ZM51.1,92a1.754,1.754,0,0,0,2,2h1.1V93h-.9c-.7,0-1-.2-1-.9V87.9h1.9v-1H52.3V85.1H51.1v1.8h-.9v1h.9v4.2Zm7.6-4.2a2.042,2.042,0,0,1,2.2,1.9v.2H56.4a2.22,2.22,0,0,1,2.2-2.2h0Zm3.4,4H60.8a1.956,1.956,0,0,1-2,1.3,2.241,2.241,0,0,1-2.3-2.2h5.7v-.7a3.315,3.315,0,0,0-3.1-3.4h-.3A3.359,3.359,0,0,0,55.3,90v.4a3.531,3.531,0,0,0,3.2,3.7h.4a3.367,3.367,0,0,0,3.3-2.3Zm1.3-1.4a3.381,3.381,0,0,0,3.1,3.7h.2a3.056,3.056,0,0,0,2.7-1.5v1.3h1.2V84.3H69.4v3.8a3.143,3.143,0,0,0-2.6-1.4,3.372,3.372,0,0,0-3.4,3.4v.3h0Zm6.1,0A2.443,2.443,0,0,1,67.3,93h-.2a2.456,2.456,0,0,1-2.4-2.5v-.2a2.374,2.374,0,0,1,2.2-2.6h.2a2.456,2.456,0,0,1,2.4,2.5v.2Z"
            fill="#c9c9c9"
          />
          <path
            id="Path_6942"
            data-name="Path 6942"
            d="M152.3,89.2V85.9H154c1.4,0,1.9.6,1.9,1.7s-.6,1.7-1.9,1.7h-1.7Zm4.9-1.6c0-1.5-1-2.7-3.1-2.7h-2.9V94h1.2V90.2h1.7C156.4,90.2,157.2,89,157.2,87.6Zm1.1,2.8a3.381,3.381,0,0,0,3.1,3.7h.2a3.189,3.189,0,0,0,2.7-1.4V94h1.2V86.9h-1.2v1.3a2.968,2.968,0,0,0-2.7-1.4,3.372,3.372,0,0,0-3.4,3.4v.3h0Zm6,0a2.443,2.443,0,0,1-2.2,2.6h-.2a2.456,2.456,0,0,1-2.4-2.5v-.2a2.374,2.374,0,0,1,2.2-2.6h.2a2.456,2.456,0,0,1,2.4,2.5v.2Zm2.9,0a3.381,3.381,0,0,0,3.1,3.7h.4a3.223,3.223,0,0,0,3.3-2.4h-1.3a1.856,1.856,0,0,1-2,1.4c-1.3,0-2.3-.9-2.3-2.7s1-2.7,2.3-2.7a1.958,1.958,0,0,1,2,1.4H174a3.081,3.081,0,0,0-3.3-2.4,3.438,3.438,0,0,0-3.5,3.3v.4Zm8.4,3.6h1.2V90.9l2.8,3.1h1.6l-3.4-3.6,3.4-3.5h-1.7l-2.7,3.2V84.4h-1.2V94Zm6.8,0h1.2V86.9h-1.2Zm.6-8.3a.86.86,0,0,0,.8-.8h0a.789.789,0,0,0-.8-.8h0a.789.789,0,0,0-.8.8h0a.789.789,0,0,0,.8.8Zm7.9,8.3h1.2V89.8a2.657,2.657,0,0,0-2.5-3h-.5a2.845,2.845,0,0,0-2.3,1.1v-1h-1.2V94h1.2V90.1c0-1.5.8-2.3,2-2.3s2,.7,2,2.2v4Zm2.8-3.6a3.451,3.451,0,0,0,3.1,3.7h.2a3.056,3.056,0,0,0,2.7-1.5v1.5a2.061,2.061,0,0,1-1.9,2.3h-.3a2.438,2.438,0,0,1-2.3-1.4H194a3.489,3.489,0,0,0,6.8-.5V86.8h-1.2v1.3a2.968,2.968,0,0,0-2.7-1.4,3.372,3.372,0,0,0-3.4,3.4v.3h.2Zm6,0a2.443,2.443,0,0,1-2.2,2.6h-.2a2.456,2.456,0,0,1-2.4-2.5v-.2a2.374,2.374,0,0,1,2.2-2.6h.2a2.456,2.456,0,0,1,2.4,2.5v.2Z"
            fill="#c9c9c9"
          />
          <g id="verified-24px">
            <g id="Group_564" data-name="Group 564">
              <rect
                id="Rectangle_235"
                data-name="Rectangle 235"
                width="28"
                height="28"
                transform="translate(25 26)"
                fill="none"
              />
            </g>
            <g id="Group_565" data-name="Group 565">
              <path
                id="Path_623"
                data-name="Path 623"
                d="M51.5,39.7l-2.8-3.3.4-4.3-4.2-1-2.2-3.7-4,1.7-4-1.7-2.2,3.7-4.2.9.4,4.3-2.9,3.3,2.8,3.3-.4,4.3,4.2,1,2.2,3.7,4-1.7,4,1.7,2.2-3.7,4.2-1-.4-4.3,2.9-3.3Zm-15,5.5-4.4-4.4,1.7-1.7,2.7,2.7,6.8-6.9L45,36.6l-8.6,8.6Z"
                fill="#fff"
              />
            </g>
          </g>
        </g>
        <line
          id="Line_65"
          data-name="Line 65"
          x2="59.9"
          transform="translate(317.3 39.1)"
          fill="none"
          stroke="#40bac4"
          strokeWidth="1"
        />
        <line
          id="Line_60"
          data-name="Line 60"
          x2="61"
          transform="translate(377.2 39.1)"
          fill="none"
          stroke="#d4d4d4"
          strokeWidth="1"
        />
        <g id="Group_550" data-name="Group 550">
          <circle
            id="Ellipse_89"
            data-name="Ellipse 89"
            cx="38.5"
            cy="38.5"
            r="38.5"
            transform="translate(265)"
            fill="#cffbff"
          />
          <circle
            id="Ellipse_84"
            data-name="Ellipse 84"
            cx="30.5"
            cy="30.5"
            r="30.5"
            transform="translate(273 8)"
            fill="#40bac4"
          />
        </g>
        <g id="Group_551" data-name="Group 551">
          <ellipse
            id="Ellipse_90"
            data-name="Ellipse 90"
            cx="39"
            cy="38.5"
            rx="39"
            ry="38.5"
            transform="translate(402)"
            fill="rgba(207,251,255,0)"
          />
          <circle
            id="Ellipse_85"
            data-name="Ellipse 85"
            cx="30.5"
            cy="30.5"
            r="30.5"
            transform="translate(411 8)"
            fill="#f1f1f1"
          />
        </g>
        <path
          id="Path_6943"
          data-name="Path 6943"
          d="M280.7,98.2c0-3.9-5.6-2.2-5.6-4.9s.9-1.7,2-1.7a1.96,1.96,0,0,1,2,1.5h1.5c-.2-1.6-1.5-2.7-3.5-2.7s-3.5,1.2-3.5,2.9c0,3.9,5.7,2.1,5.7,4.9s-.8,1.7-2.1,1.7a2,2,0,0,1-2.2-1.7h-1.5c0,1.7,1.5,2.9,3.6,2.9,2.3,0,3.5-1.5,3.5-3Zm2.1,2.8h1.4V96.5c0-1.8,1-2.6,2.4-2.6a2.213,2.213,0,0,1,2.3,2.5v4.7h1.4V96.3a3.22,3.22,0,0,0-2.8-3.5H287a3.1,3.1,0,0,0-2.7,1.3V90h-1.4v11.1Zm9.7,0h1.4V92.8h-1.4Zm.7-9.6a.9.9,0,0,0,.9-.9h0a.9.9,0,0,0-.9-.9h0a.9.9,0,0,0-.9.9h0a.9.9,0,0,0,.9.9Zm4.5,2.9V92.8h-1.4v12.1h1.4V99.5a3.473,3.473,0,0,0,3.1,1.6,3.927,3.927,0,0,0,3.9-4v-.3a3.908,3.908,0,0,0-3.6-4.2h-.3a3.838,3.838,0,0,0-3.1,1.6h0Zm5.6,2.6a2.951,2.951,0,0,1-2.5,3.1h-.2a2.8,2.8,0,0,1-2.8-2.8V97a2.742,2.742,0,0,1,2.6-3h.2a2.8,2.8,0,0,1,2.8,2.8v.3Zm4.7-2.6V92.8h-1.4v12.1H308V99.5a3.473,3.473,0,0,0,3.1,1.6,3.927,3.927,0,0,0,3.9-4v-.3a3.908,3.908,0,0,0-3.6-4.2h-.3a3.838,3.838,0,0,0-3.1,1.6Zm5.5,2.6A2.951,2.951,0,0,1,311,100h-.2a2.8,2.8,0,0,1-2.8-2.8V97a2.742,2.742,0,0,1,2.6-3h.2a2.8,2.8,0,0,1,2.8,2.8v.3Zm6.8-3.1a2.443,2.443,0,0,1,2.6,2.2v.3h-5.2a2.562,2.562,0,0,1,2.6-2.5Zm3.9,4.7h-1.5a2.172,2.172,0,0,1-2.3,1.5,2.721,2.721,0,0,1-2.7-2.5h6.6v-.8a3.782,3.782,0,0,0-3.6-3.9h-.3a3.983,3.983,0,0,0-4.1,3.7V97a4.011,4.011,0,0,0,3.6,4.3h.4a3.755,3.755,0,0,0,3.8-2.7h0Zm1.5-1.6a3.941,3.941,0,0,0,3.6,4.3h.3a3.56,3.56,0,0,0,3.1-1.7V101h1.4V89.9h-1.4v4.3a3.657,3.657,0,0,0-3-1.6,3.842,3.842,0,0,0-3.9,3.9v.3Zm7,0a2.8,2.8,0,0,1-2.6,3h-.2a2.8,2.8,0,0,1-2.8-2.8v-.2a2.79,2.79,0,0,1,2.5-3h.3a2.8,2.8,0,0,1,2.8,2.8v.2Z"
        />
        <path
          id="Path_6944"
          data-name="Path 6944"
          d="M413.1,83.9h-2.8V93h2.8c3,0,4.8-1.7,4.8-4.5s-1.8-4.5-4.8-4.5ZM411.4,92V84.9H413a3.261,3.261,0,0,1,3.6,2.9v.7c0,2.2-1.2,3.5-3.6,3.5Zm11.2-5.2a2.042,2.042,0,0,1,2.2,1.9v.2h-4.5a2.242,2.242,0,0,1,2.3-2.2Zm3.3,4h-1.3a1.956,1.956,0,0,1-2,1.3,2.24,2.24,0,0,1-2.3-2.2H426v-.7a3.315,3.315,0,0,0-3.1-3.4h-.3a3.359,3.359,0,0,0-3.5,3.2v.4a3.531,3.531,0,0,0,3.2,3.7h.4a3.367,3.367,0,0,0,3.3-2.3Zm1.8,2.2h1.2V83.4h-1.2Zm3.3,0h1.2V85.9H431Zm.6-8.3a.86.86,0,0,0,.8-.8h0a.789.789,0,0,0-.8-.8h0a.789.789,0,0,0-.8.8h0a.789.789,0,0,0,.8.8Zm1.8,1.2,2.8,7.1h1.4l2.8-7.1h-1.3l-2.2,6-2.2-6h-1.3Zm11.3.9a2.042,2.042,0,0,1,2.2,1.9v.2h-4.5a2.22,2.22,0,0,1,2.2-2.2Zm3.3,4h-1.3a1.956,1.956,0,0,1-2,1.3,2.24,2.24,0,0,1-2.3-2.2h5.7v-.7a3.315,3.315,0,0,0-3.1-3.4h-.3a3.359,3.359,0,0,0-3.5,3.2v.4a3.531,3.531,0,0,0,3.2,3.7h.4a3.367,3.367,0,0,0,3.3-2.3Zm3-1.7c0-1.7.9-2.2,2-2.2h.3V85.7A2.358,2.358,0,0,0,451,87V85.8h-1.2v7.1H451V89Zm6.8-2.3a2.042,2.042,0,0,1,2.2,1.9v.2h-4.5a2.242,2.242,0,0,1,2.3-2.2Zm3.3,4h-1.3a1.956,1.956,0,0,1-2,1.3,2.24,2.24,0,0,1-2.3-2.2h5.7v-.7a3.315,3.315,0,0,0-3.1-3.4h-.3a3.359,3.359,0,0,0-3.5,3.2v.4a3.531,3.531,0,0,0,3.2,3.7h.4a3.367,3.367,0,0,0,3.3-2.3Zm1.4-1.4a3.381,3.381,0,0,0,3.1,3.7h.2a3.056,3.056,0,0,0,2.7-1.5v1.3h1.2V83.3h-1.2v3.8a3.143,3.143,0,0,0-2.6-1.4,3.372,3.372,0,0,0-3.4,3.4v.3h0Zm6,0a2.443,2.443,0,0,1-2.2,2.6h-.2a2.456,2.456,0,0,1-2.4-2.5v-.2a2.374,2.374,0,0,1,2.2-2.6h.2a2.456,2.456,0,0,1,2.4,2.5v.2Z"
          fill="#c9c9c9"
        />
        <circle
          id="Ellipse_87"
          data-name="Ellipse 87"
          cx="5"
          cy="5"
          r="5"
          transform="translate(372 34)"
          fill="#40bac4"
        />
        <g id="local_shipping_black_24dp" data-name="local shipping black 24dp">
          <path
            id="Path_3927"
            data-name="Path 3927"
            d="M292,26h26V52H292Z"
            fill="none"
          />
          <path
            id="Path_3928"
            data-name="Path 3928"
            d="M313.5,35h-3.2V31H295.4a2.027,2.027,0,0,0-2.1,2V43.9h2.1a3.119,3.119,0,0,0,3.2,3h0a3.185,3.185,0,0,0,3.2-3h6.4a3.119,3.119,0,0,0,3.2,3h0a3.185,3.185,0,0,0,3.2-3h2.1V39l-3.2-4ZM298.6,45.4a1.5,1.5,0,0,1-.2-3h.2a1.5,1.5,0,0,1,.2,3ZM313,36.5l2.1,2.5h-4.8V36.5H313Zm-1.6,8.9a1.5,1.5,0,0,1,.2-3,1.453,1.453,0,0,1,1.4,1.5,1.5,1.5,0,0,1-1.6,1.5Z"
            fill="#fff"
          />
        </g>
        <g id="thumbs_up" data-name="thumbs up">
          <g id="Group_676" data-name="Group 676">
            <g id="Group_675" data-name="Group 675">
              <path
                id="Path_672"
                data-name="Path 672"
                d="M452.3,41.4a1.538,1.538,0,0,0-1.5-1.5,1.5,1.5,0,0,0,0-3h-6.2a12.111,12.111,0,0,0,.9-5.3,2.326,2.326,0,0,0-2.3-2.3h-.5a.73.73,0,0,0-.7.6c-.7,2.9-1.9,6.2-4.9,6.9V47.7l2.6.9a6.722,6.722,0,0,0,2.4.4h7.1a1.5,1.5,0,0,0,0-3h1.5a1.5,1.5,0,0,0,0-3h0a1.538,1.538,0,0,0,1.5-1.5Z"
                fill="#d9d9d9"
              />
            </g>
          </g>
          <g id="Group_678" data-name="Group 678">
            <g id="Group_677" data-name="Group 677">
              <path
                id="Path_673"
                data-name="Path 673"
                d="M433.4,35.4h-3a.789.789,0,0,0-.8.8h0V49.8a.789.789,0,0,0,.8.8h3a2.263,2.263,0,0,0,2.3-2.3V37.7a2.263,2.263,0,0,0-2.3-2.3Zm0,12.1a.789.789,0,0,1-.8-.8.8.8,0,1,1,1.6,0h0A.789.789,0,0,1,433.4,47.5Z"
                fill="#d9d9d9"
              />
            </g>
          </g>
          <g id="Group_680" data-name="Group 680">
            <g id="Group_679" data-name="Group 679">
              <path
                id="Path_674"
                data-name="Path 674"
                d="M440,28l-1.1-1.1a.778.778,0,1,0-1.1,1.1l1.1,1.1A.778.778,0,0,0,440,28Z"
                fill="#d9d9d9"
              />
            </g>
          </g>
          <g id="Group_682" data-name="Group 682">
            <g id="Group_681" data-name="Group 681">
              <path
                id="Path_675"
                data-name="Path 675"
                d="M448.6,27a.785.785,0,0,0-1.1,0h0l-1.1,1.1a.778.778,0,0,0,1.1,1.1l1.1-1.1a.785.785,0,0,0,0-1.1Z"
                fill="#d9d9d9"
              />
            </g>
          </g>
          <g id="Group_684" data-name="Group 684">
            <g id="Group_683" data-name="Group 683">
              <path
                id="Path_676"
                data-name="Path 676"
                d="M443.3,24.7a.773.773,0,0,0-.8.7h0V27a.773.773,0,0,0,.8.7.684.684,0,0,0,.7-.7V25.4a.773.773,0,0,0-.7-.8h0Z"
                fill="#d9d9d9"
              />
            </g>
          </g>
        </g>
      </svg>
    );

  const deliveryAccept = (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 660 100.9">

      <g id="d_s_accpted">
        <line id="Line_83" x1="60.3" y1="39.1" x2="157.2" y2="39.1" fill="none" stroke="#2ec278" stroke-opacity="0"/>
        <path id="Path_667" d="M62.7,39.1h78" fill="none" stroke="#2ec278"/>
        <line id="Line_85" x1="140.5" y1="39.1" x2="618.2" y2="39.1" fill="none" stroke="#d4d4d4"/>
        <circle id="Ellipse_96" cx="38.5" cy="38.5" r="38.5" fill="#d0f3e2"/>
        <ellipse id="Ellipse_97" cx="39" cy="38.5" rx="30" ry="30.5" fill="#2ec278"/>
        <g id="Group_671">
          <circle id="Ellipse_98" cx="233.5" cy="38.5" r="38.5" fill="#acffd6" fill-opacity="0"/>
          <path id="Path_668" d="M233.6,8.2c16.8,0,30.3,13.6,30.3,30.3s-13.6,30.3-30.3,30.3-30.3-13.6-30.3-30.3h0c0-16.8,13.6-30.3,30.3-30.3Z" fill="#f1f1f1"/>
        </g>
        <g id="Group_672">
          <ellipse id="Ellipse_99" cx="424" cy="38.5" rx="38" ry="38.5" fill="#ffeaaf" fill-opacity="0"/>
          <circle id="Ellipse_100" cx="424" cy="39" r="31" fill="#f1f1f1"/>
        </g>
        <g id="Group_673">
          <ellipse id="Ellipse_101" cx="621" cy="38.5" rx="39" ry="38.5" fill="#cffbff" fill-opacity="0"/>
          <ellipse id="Ellipse_102" cx="621" cy="38.5" rx="30" ry="30.5" fill="#f1f1f1"/>
        </g>
        <g id="thumbs_up">
          <g id="Group_676">
            <g id="Group_675">
              <path id="Path_672" d="M632.3,42.3c0-.8-.7-1.5-1.5-1.5.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5h-6.2c.7-1.7,1-3.5.9-5.3,0-1.2-1-2.3-2.3-2.3h-.5c-.3,0-.6.2-.7.6-.7,2.9-1.9,6.2-4.9,6.9v10.9l2.6.9c.8.3,1.6.4,2.4.4h7.1c.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5h1.5c.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5h0c.8,0,1.5-.7,1.5-1.5Z" fill="#d9d9d9"/>
            </g>
          </g>
          <g id="Group_678">
            <g id="Group_677">
              <path id="Path_673" d="M613.4,36.3h-3c-.4,0-.8.3-.8.8h0v13.6c0,.4.3.8.8.8h3c1.3,0,2.3-1,2.3-2.3v-10.6c0-1.3-1-2.3-2.3-2.3ZM613.4,48.4c-.4,0-.8-.3-.8-.8s.3-.8.8-.8.8.3.8.8h0c0,.4-.3.8-.8.8Z" fill="#d9d9d9"/>
            </g>
          </g>
          <g id="Group_680">
            <g id="Group_679">
              <path id="Path_674" d="M620.1,28.9l-1.1-1.1c-.3-.3-.8-.3-1.1,0s-.3.8,0,1.1l1.1,1.1c.3.3.8.3,1.1,0s.3-.8,0-1.1h0Z" fill="#d9d9d9"/>
            </g>
          </g>
          <g id="Group_682">
            <g id="Group_681">
              <path id="Path_675" d="M628.6,27.9c-.3-.3-.8-.3-1.1,0h0s-1.1,1.1-1.1,1.1c-.3.3-.3.8,0,1.1s.8.3,1.1,0l1.1-1.1c.3-.3.3-.8,0-1.1,0,0,0,0,0,0Z" fill="#d9d9d9"/>
            </g>
          </g>
          <g id="Group_684">
            <g id="Group_683">
              <path id="Path_676" d="M623.3,25.6c-.4,0-.8.3-.8.7,0,0,0,0,0,0v1.6c0,.4.4.7.8.7.4,0,.7-.3.7-.7v-1.6c0-.4-.3-.8-.7-.8,0,0,0,0,0,0Z" fill="#d9d9d9"/>
            </g>
          </g>
        </g>
        <g id="verified-24px">
          <g id="Group_685">
            <rect id="Rectangle_309" x="24" y="25" width="28" height="28" fill="none"/>
          </g>
          <g id="Group_686">
            <path id="Path_677" d="M51.5,39.7l-2.8-3.3.4-4.3-4.2-1-2.2-3.7-4,1.7-4-1.7-2.2,3.7-4.2.9.4,4.3-2.9,3.3,2.8,3.3-.4,4.3,4.2,1,2.2,3.7,4-1.7,4,1.7,2.2-3.7,4.2-1-.4-4.3,2.9-3.3ZM36.5,45.2l-4.4-4.4,1.7-1.7,2.7,2.7,6.8-6.9,1.7,1.7-8.6,8.6Z" fill="#fff"/>
          </g>
        </g>
        <circle id="Ellipse_103" cx="141" cy="39" r="5" fill="#2ec278"/>
        <g id="inventory_2_black_24dp">
          <g id="Group_4910">
            <rect id="Rectangle_1309" x="221" y="27" width="26" height="26" fill="none"/>
          </g>
          <g id="Group_4911">
            <path id="Path_3926" d="M242.3,29.6h-16.6c-1.1,0-2,.9-2.1,2.1v3.1c0,.7.4,1.4,1,1.8v11.8c0,1.1,1,2,2.1,2.1h14.6c1.1,0,2-1,2.1-2.1v-11.8c.6-.4,1-1,1-1.8v-3.1c0-1.1-.9-2-2.1-2.1ZM237.1,42.1h-6.2v-2.1h6.2v2.1ZM242.3,34.8h-16.6v-3.1h16.6v3.1Z" fill="#d9d9d9"/>
          </g>
        </g>
        <g id="local_shipping_black_24dp">
          <path id="Path_3927" d="M412,27h26v26h-26v-26Z" fill="none"/>
          <path id="Path_3928" d="M433.5,36h-3.2v-4h-14.9c-1.1,0-2.1.8-2.1,2v10.9h2.1c0,1.7,1.5,3,3.2,3h0c1.7,0,3.1-1.3,3.2-3,0,0,0,0,0,0h6.4c0,1.7,1.5,3,3.2,3,0,0,0,0,0,0,1.7,0,3.1-1.3,3.2-3,0,0,0,0,0,0h2.1v-4.9l-3.2-4ZM418.6,46.4c-.8,0-1.5-.6-1.6-1.4,0-.8.6-1.5,1.4-1.6,0,0,.1,0,.2,0,.8,0,1.5.6,1.6,1.4,0,.8-.6,1.5-1.4,1.6,0,0-.1,0-.2,0ZM433,37.5l2.1,2.5h-4.8v-2.5h2.7ZM431.4,46.4c-.8,0-1.4-.8-1.4-1.6s.8-1.4,1.6-1.4c.8,0,1.4.7,1.4,1.5,0,.9-.7,1.5-1.6,1.5,0,0,0,0,0,0Z" fill="#d9d9d9"/>
        </g>
        <g>
          <path d="M404.7,88c-.6,0-1.2-.1-1.7-.3-.5-.2-.9-.5-1.2-.9-.3-.4-.5-.8-.6-1.4l1.1-.2c.1.5.4,1,.9,1.3.4.3,1,.5,1.6.5s.7,0,1-.2c.3-.1.6-.3.7-.5.2-.2.3-.5.3-.8s0-.4-.1-.5c0-.1-.2-.3-.3-.4-.1,0-.2-.2-.4-.2-.1,0-.3-.1-.5-.2l-2.2-.7c-.2,0-.5-.2-.7-.3-.2-.1-.4-.2-.6-.4s-.3-.4-.4-.6c-.1-.2-.2-.5-.2-.8,0-.5.1-.9.4-1.3.3-.4.6-.6,1.1-.8.5-.2,1-.3,1.5-.3.6,0,1.1.1,1.6.3s.8.5,1.1.9c.3.4.5.8.6,1.3l-1.1.2c0-.3-.2-.7-.4-.9-.2-.3-.5-.5-.8-.6s-.6-.2-1-.2c-.4,0-.7,0-1,.2s-.5.3-.7.5c-.2.2-.3.4-.3.7s0,.5.2.6.3.3.6.4c.2,0,.4.2.7.2l1.6.5c.2,0,.4.1.7.2s.5.2.7.4c.2.2.4.4.6.7.2.3.2.6.2,1s0,.8-.2,1.1-.4.6-.7.8c-.3.2-.6.4-1,.5-.4.1-.8.2-1.3.2Z" fill="#bfbfbf"/>
          <path d="M409.1,87.8v-8.6h1v4.8h.1v3.9h-1.1ZM413.8,87.8v-3.2c0-.3,0-.6,0-.9,0-.3-.2-.5-.3-.8-.1-.2-.3-.4-.5-.5-.2-.1-.5-.2-.9-.2s-.5,0-.7.1-.4.2-.6.4c-.2.2-.3.4-.4.7,0,.3-.1.6-.1,1l-.7-.2c0-.6.1-1.2.3-1.6s.5-.8,1-1,.9-.4,1.4-.4.8,0,1.1.2.5.3.7.5.4.5.5.7c.1.3.2.5.2.8,0,.3,0,.5,0,.8v3.6h-1.1Z" fill="#bfbfbf"/>
          <path d="M416.5,80.2v-1.1h1.1v1.1h-1.1ZM416.5,87.8v-6.5h1.1v6.5h-1.1Z" fill="#bfbfbf"/>
          <path d="M419.4,90.7v-9.4h1v4.8h.1v4.6h-1.1ZM422.4,88c-.6,0-1.1-.2-1.5-.5-.4-.3-.7-.7-.9-1.2s-.3-1.1-.3-1.7.1-1.2.3-1.7.5-.9.9-1.2.9-.4,1.5-.4,1.1.1,1.6.4c.4.3.8.7,1,1.2.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.6.9-1,1.2-.4.3-1,.4-1.6.4ZM422.2,87c.4,0,.8-.1,1.1-.3s.5-.5.6-.9c.1-.4.2-.8.2-1.2s0-.9-.2-1.2c-.1-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8.1-1,.3-.5.5-.6.9-.2.8-.2,1.3,0,.9.2,1.3c.1.4.3.7.6.9s.6.3,1.1.3Z" fill="#bfbfbf"/>
          <path d="M426.6,90.7v-9.4h1v4.8h.1v4.6h-1.1ZM429.6,88c-.6,0-1.1-.2-1.5-.5-.4-.3-.7-.7-.9-1.2s-.3-1.1-.3-1.7.1-1.2.3-1.7.5-.9.9-1.2c.4-.3.9-.4,1.5-.4s1.1.1,1.6.4c.4.3.8.7,1,1.2.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.6.9-1,1.2-.4.3-1,.4-1.6.4ZM429.4,87c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9.1-.4.2-.8.2-1.2s0-.9-.2-1.2c-.1-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8.1-1,.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.3s0,.9.2,1.3c.1.4.3.7.6.9.3.2.6.3,1.1.3Z" fill="#bfbfbf"/>
          <path d="M436.6,88c-.6,0-1.2-.1-1.7-.4-.5-.3-.8-.7-1.1-1.2-.3-.5-.4-1.1-.4-1.8s.1-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8-.3-.4-.8-.6-1.4-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3-.5.3-1.1.5-1.7.5ZM434.2,84.9v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
          <path d="M443.4,88c-.6,0-1.1-.1-1.6-.4-.4-.3-.8-.7-1-1.2-.2-.5-.4-1.1-.4-1.7s.1-1.2.4-1.7c.2-.5.6-.9,1-1.2.4-.3.9-.4,1.5-.4s1.1.1,1.5.4.7.7.9,1.2c.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.5.9-.9,1.2-.4.3-.9.5-1.5.5ZM443.6,87c.4,0,.8-.1,1.1-.3s.5-.5.6-.9c.1-.4.2-.8.2-1.3s0-.9-.2-1.3c-.1-.4-.3-.7-.6-.9-.3-.2-.6-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2s0,.9.2,1.2c.1.4.4.7.6.9.3.2.6.3,1.1.3ZM445.5,87.8v-4.8h-.1v-3.9h1.1v8.6h-1Z" fill="#bfbfbf"/>
        </g>
        <g>
          <path d="M212.2,87.8v-8.6h3.4c0,0,.2,0,.3,0,.1,0,.2,0,.3,0,.5,0,.9.2,1.2.5.3.2.6.6.7.9.2.4.2.8.2,1.3s0,.9-.2,1.3c-.2.4-.4.7-.7.9s-.7.4-1.2.5c-.1,0-.2,0-.3,0-.1,0-.2,0-.3,0h-2.3v3.2h-1.1ZM213.3,83.6h2.3c0,0,.2,0,.3,0s.2,0,.3,0c.3,0,.5-.2.7-.4.2-.2.3-.4.4-.6s.1-.5.1-.7,0-.5-.1-.7c0-.2-.2-.4-.4-.6-.2-.2-.4-.3-.7-.4,0,0-.2,0-.3,0,0,0-.2,0-.3,0h-2.3v3.4Z" fill="#bfbfbf"/>
          <path d="M221.2,88c-.5,0-.9,0-1.2-.3-.3-.2-.6-.4-.7-.7s-.2-.6-.2-1,0-.6.2-.9.3-.5.5-.6c.2-.2.5-.3.8-.4.3,0,.7-.2,1-.2.4,0,.8-.1,1.2-.2.4,0,.8-.1,1.1-.2l-.4.2c0-.6-.1-1-.3-1.3-.2-.3-.7-.4-1.2-.4s-.7,0-1,.3c-.3.2-.5.5-.6.9l-1-.3c.2-.6.5-1,.9-1.3.4-.3,1-.5,1.8-.5s1.1.1,1.5.3c.4.2.7.5.9,1,0,.2.2.4.2.7,0,.2,0,.5,0,.7v4h-.9v-1.6h.2c-.2.7-.6,1.1-1,1.3-.4.3-1,.4-1.6.4ZM221.4,87.1c.4,0,.7,0,1-.2.3-.1.5-.3.7-.5.2-.2.3-.5.3-.7,0-.2,0-.4,0-.7,0-.2,0-.4,0-.6l.4.2c-.3,0-.7.1-1,.1s-.7,0-1,.1-.6.1-.8.2c-.2,0-.3.1-.5.2-.2,0-.3.2-.4.3,0,.1-.1.3-.1.5s0,.3.1.5c0,.2.2.3.4.4.2.1.4.2.7.2Z" fill="#bfbfbf"/>
          <path d="M228.9,88c-.7,0-1.2-.1-1.7-.4s-.8-.7-1-1.2c-.2-.5-.4-1.1-.4-1.8,0-.7.1-1.3.4-1.8.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5c.5.3.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2,0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5Z" fill="#bfbfbf"/>
          <path d="M232.9,87.8v-8.6h1.1v5.3l2.7-3.1h1.4l-2.9,3.2,3.2,3.2h-1.5l-2.9-3.1v3.1h-1.1Z" fill="#bfbfbf"/>
          <path d="M239.1,80.2v-1.1h1.1v1.1h-1.1ZM239.1,87.8v-6.5h1.1v6.5h-1.1Z" fill="#bfbfbf"/>
          <path d="M242,87.8v-6.5h1v1.7h.1v4.7h-1.1ZM246.6,87.8v-3.2c0-.3,0-.6,0-.9,0-.3-.1-.5-.3-.8-.1-.2-.3-.4-.5-.5-.2-.1-.5-.2-.9-.2s-.5,0-.7.1c-.2,0-.4.2-.6.4-.2.2-.3.4-.4.7,0,.3-.1.6-.1,1l-.7-.2c0-.6.1-1.2.3-1.6.2-.5.5-.8,1-1s.9-.4,1.4-.4.8,0,1.1.2.5.3.7.5c.2.2.4.5.5.7.1.3.2.5.2.8,0,.3,0,.5,0,.8v3.6h-1.1Z" fill="#bfbfbf"/>
          <path d="M251.8,88c-.6,0-1.1-.1-1.6-.4-.4-.3-.8-.7-1-1.2-.2-.5-.4-1.1-.4-1.7s.1-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1.1,1.5.4.7.7.9,1.2c.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.5.9-.9,1.2-.4.3-.9.5-1.5.5ZM251.9,90.9c-.3,0-.7,0-1-.2-.3-.1-.6-.3-.9-.5s-.5-.5-.7-.8l1-.5c.2.3.4.5.7.7.3.1.6.2.9.2s.8,0,1-.2c.3-.1.5-.4.6-.7.1-.3.2-.7.2-1.1v-1.8h.1v-4.7h.9v6.5c0,.2,0,.4,0,.5,0,.2,0,.3,0,.5,0,.5-.3.8-.5,1.1-.2.3-.6.5-1,.7-.4.1-.8.2-1.4.2ZM251.9,87c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9.1-.4.2-.8.2-1.3s0-.9-.2-1.3c-.1-.4-.3-.7-.6-.9s-.6-.3-1-.3-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2s0,.9.2,1.2c.1.4.4.7.6.9.3.2.6.3,1.1.3Z" fill="#bfbfbf"/>
        </g>
        <g>
          <path d="M595.3,87.8v-8.6h2.7c0,0,.2,0,.5,0s.4,0,.6,0c.7,0,1.2.3,1.7.7.5.4.8.9,1,1.5.2.6.3,1.3.3,2s-.1,1.4-.3,2c-.2.6-.6,1.1-1,1.5-.4.4-1,.6-1.7.7-.2,0-.4,0-.6,0s-.4,0-.5,0h-2.7ZM596.4,86.8h1.6c.2,0,.3,0,.5,0,.2,0,.4,0,.5,0,.5,0,.9-.3,1.1-.6.3-.3.5-.7.6-1.2s.2-1,.2-1.5,0-1-.2-1.5c-.1-.5-.4-.8-.6-1.1-.3-.3-.7-.5-1.1-.6-.1,0-.3,0-.5,0-.2,0-.4,0-.5,0h-1.6v6.6Z" fill="#bfbfbf"/>
          <path d="M606.3,88c-.6,0-1.2-.1-1.7-.4-.5-.3-.8-.7-1.1-1.2-.3-.5-.4-1.1-.4-1.8s.1-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8-.3-.4-.8-.6-1.4-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3-.5.3-1.1.5-1.7.5ZM603.9,84.9v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
          <path d="M610.7,87.8v-8.8h1.1v8.8h-1.1Z" fill="#bfbfbf"/>
          <path d="M613.7,80.2v-1.1h1.1v1.1h-1.1ZM613.7,87.8v-6.5h1.1v6.5h-1.1Z" fill="#bfbfbf"/>
          <path d="M618.3,87.8l-2.4-6.5h1.1l1.8,5.1,1.8-5.1h1.1l-2.4,6.5h-1.1Z" fill="#bfbfbf"/>
          <path d="M625.4,88c-.6,0-1.2-.1-1.7-.4-.5-.3-.8-.7-1.1-1.2-.3-.5-.4-1.1-.4-1.8s.1-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8-.3-.4-.8-.6-1.4-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.4.5,1.8c.3.4.8.6,1.5.6s.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3-.5.3-1.1.5-1.7.5ZM623,84.9v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
          <path d="M629.7,87.8v-6.5h1v1.6l-.2-.2c0-.2.2-.4.3-.6s.3-.3.4-.4c.2-.1.4-.2.6-.3.2,0,.4-.1.6-.1.2,0,.4,0,.6,0v1c-.2,0-.5,0-.7,0-.3,0-.5.1-.7.3-.2.2-.4.3-.5.5-.1.2-.2.4-.2.7s0,.5,0,.8v3.3h-1.1Z" fill="#bfbfbf"/>
          <path d="M636.9,88c-.6,0-1.2-.1-1.7-.4-.5-.3-.8-.7-1.1-1.2-.3-.5-.4-1.1-.4-1.8s.1-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8-.3-.4-.8-.6-1.4-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3-.5.3-1.1.5-1.7.5ZM634.5,84.9v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
          <path d="M643.7,88c-.6,0-1.1-.1-1.6-.4-.4-.3-.8-.7-1-1.2-.2-.5-.4-1.1-.4-1.7s.1-1.2.4-1.7c.2-.5.6-.9,1-1.2.4-.3.9-.4,1.5-.4s1.1.1,1.5.4.7.7.9,1.2c.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.5.9-.9,1.2-.4.3-.9.5-1.5.5ZM643.8,87c.4,0,.8-.1,1.1-.3s.5-.5.6-.9c.1-.4.2-.8.2-1.3s0-.9-.2-1.3c-.1-.4-.3-.7-.6-.9-.3-.2-.6-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2s0,.9.2,1.2c.1.4.4.7.6.9.3.2.6.3,1.1.3ZM645.7,87.8v-4.8h-.1v-3.9h1.1v8.6h-1Z" fill="#bfbfbf"/>
        </g>
        <g>
          <path d="M7.3,96.3l3.3-10.1h2.1l3.3,10.1h-1.5l-3-9.2h.4l-3,9.2h-1.5ZM9,94v-1.4h5.3v1.4h-5.3Z"/>
          <path d="M20.5,96.5c-.8,0-1.4-.2-2-.5-.5-.3-1-.8-1.2-1.4-.3-.6-.4-1.3-.4-2.1,0-.8.2-1.5.4-2.1s.7-1.1,1.3-1.4,1.2-.5,2-.5,1.5.2,2.1.6c.6.4,1,1,1.2,1.7l-1.5.4c-.1-.4-.4-.8-.7-1-.3-.2-.7-.4-1.1-.4s-.9.1-1.2.3c-.3.2-.5.5-.7.9s-.2.8-.2,1.3c0,.8.2,1.4.5,1.9.4.5.9.7,1.6.7s.9-.1,1.1-.3c.3-.2.5-.5.7-.9l1.5.4c-.2.7-.7,1.3-1.2,1.7-.6.4-1.3.6-2.1.6Z"/>
          <path d="M28.3,96.5c-.8,0-1.4-.2-2-.5-.5-.3-1-.8-1.2-1.4-.3-.6-.4-1.3-.4-2.1,0-.8.2-1.5.4-2.1s.7-1.1,1.3-1.4,1.2-.5,2-.5,1.5.2,2.1.6c.6.4,1,1,1.2,1.7l-1.5.4c-.1-.4-.4-.8-.7-1-.3-.2-.7-.4-1.1-.4s-.9.1-1.2.3c-.3.2-.5.5-.7.9s-.2.8-.2,1.3c0,.8.2,1.4.5,1.9.4.5.9.7,1.6.7s.9-.1,1.1-.3c.3-.2.5-.5.7-.9l1.5.4c-.2.7-.7,1.3-1.2,1.7-.6.4-1.3.6-2.1.6Z"/>
          <path d="M36.1,96.5c-.8,0-1.4-.2-2-.5-.6-.3-1-.8-1.3-1.4-.3-.6-.5-1.3-.5-2.1s.2-1.5.5-2.2c.3-.6.7-1.1,1.3-1.4.6-.3,1.2-.5,1.9-.5s1.4.2,2,.5c.5.4.9.9,1.2,1.5.3.7.4,1.4.3,2.3h-1.5v-.5c0-.9-.2-1.5-.5-1.9-.3-.4-.8-.6-1.5-.6s-1.3.2-1.7.7c-.4.5-.6,1.1-.6,2s.2,1.5.6,1.9c.4.5.9.7,1.6.7s.9-.1,1.2-.3c.3-.2.6-.5.8-.9l1.4.5c-.3.7-.7,1.2-1.3,1.6-.6.4-1.3.6-2,.6ZM33.4,93v-1.1h5.4v1.1h-5.4Z"/>
          <path d="M40.9,99.7v-10.9h1.3v5.4h.2v5.5h-1.5ZM44.5,96.5c-.7,0-1.3-.2-1.8-.5-.5-.4-.9-.8-1.1-1.4-.3-.6-.4-1.3-.4-2s.1-1.4.4-2c.2-.6.6-1.1,1.1-1.4.5-.3,1.1-.5,1.8-.5s1.3.2,1.8.5c.5.3.9.8,1.2,1.4s.4,1.3.4,2-.1,1.4-.4,2c-.3.6-.7,1.1-1.2,1.4-.5.4-1.1.5-1.8.5ZM44.3,95.2c.5,0,.9-.1,1.2-.4s.5-.6.7-1,.2-.9.2-1.4,0-.9-.2-1.3c-.2-.4-.4-.7-.7-1-.3-.2-.7-.4-1.2-.4s-.8.1-1.1.3c-.3.2-.5.5-.7.9-.1.4-.2.9-.2,1.4s0,1,.2,1.4c.1.4.4.7.7.9.3.2.7.3,1.2.3Z"/>
          <path d="M48.6,89.9v-1.2h5.2v1.2h-5.2ZM53.8,96.3c-.5,0-.9.1-1.4.1s-.9-.1-1.2-.3c-.4-.2-.6-.4-.8-.8-.2-.3-.3-.6-.3-1,0-.3,0-.7,0-1.1v-6.7h1.5v6.6c0,.3,0,.6,0,.8,0,.2,0,.4.1.6.2.3.4.5.8.5.4,0,.8,0,1.3,0v1.2Z"/>
          <path d="M58.4,96.5c-.8,0-1.4-.2-2-.5-.6-.3-1-.8-1.3-1.4-.3-.6-.5-1.3-.5-2.1s.2-1.5.5-2.2c.3-.6.7-1.1,1.3-1.4.6-.3,1.2-.5,1.9-.5s1.4.2,2,.5c.5.4.9.9,1.2,1.5.3.7.4,1.4.3,2.3h-1.5v-.5c0-.9-.2-1.5-.5-1.9-.3-.4-.8-.6-1.5-.6s-1.3.2-1.7.7c-.4.5-.6,1.1-.6,2s.2,1.5.6,1.9c.4.5.9.7,1.6.7s.9-.1,1.2-.3c.3-.2.6-.5.8-.9l1.4.5c-.3.7-.7,1.2-1.3,1.6-.6.4-1.3.6-2,.6ZM55.7,93v-1.1h5.4v1.1h-5.4Z"/>
          <path d="M66.4,96.5c-.7,0-1.3-.2-1.8-.5s-.9-.8-1.2-1.4c-.3-.6-.4-1.3-.4-2s.1-1.4.4-2c.3-.6.7-1.1,1.2-1.4.5-.3,1.1-.5,1.8-.5s1.3.2,1.8.5c.5.3.9.8,1.1,1.4.2.6.4,1.3.4,2s-.1,1.4-.4,2c-.3.6-.6,1.1-1.1,1.4-.5.4-1.1.5-1.8.5ZM66.6,95.2c.5,0,.9-.1,1.2-.3.3-.2.5-.5.7-.9.1-.4.2-.9.2-1.4s0-1-.2-1.4c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1.1-.3s-.9.1-1.2.4c-.3.2-.5.6-.7,1-.2.4-.2.9-.2,1.3s0,1,.2,1.4.4.7.7,1,.7.4,1.2.4ZM68.7,96.3v-5.4h-.2v-4.6h1.5v10.1h-1.3Z"/>
        </g>
      </g>
    </svg>
  );

  const deliveryPacking = (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 660 105.1">

      <g id="d_s_packed_1">
        <g id="Group_4701" opacity=".5">
          <line id="Line_64" x1="60.3" y1="39.1" x2="157.2" y2="39.1" fill="none" stroke="#2ec278" stroke-opacity="0"/>
          <path id="Path_664" d="M62.7,39.1h140" fill="none" stroke="#2ec278"/>
          <circle id="Ellipse_86" cx="38.5" cy="38.5" r="38.5" fill="#ffd5d5" fill-opacity="0"/>
          <ellipse id="Ellipse_82" cx="39" cy="38.5" rx="30" ry="30.5" fill="#2ec278"/>
          <g id="verified-24px">
            <g id="Group_564">
              <rect id="Rectangle_235" x="25" y="25" width="28" height="28" fill="none"/>
            </g>
            <g id="Group_565">
              <path id="Path_623" d="M52.5,39.7l-2.8-3.3.4-4.3-4.2-1-2.2-3.7-4,1.7-4-1.7-2.2,3.7-4.2.9.4,4.3-2.9,3.3,2.8,3.3-.4,4.3,4.2,1,2.2,3.7,4-1.7,4,1.7,2.2-3.7,4.2-1-.4-4.3,2.9-3.3h0ZM37.5,45.2l-4.4-4.4,1.7-1.7,2.7,2.7,6.8-6.9,1.7,1.7-8.6,8.6h0Z" fill="#fff"/>
            </g>
          </g>
          <g>
            <path d="M12.3,92l2.9-8.6h1.5l2.9,8.6h-1.1l-2.7-8h.3l-2.7,8h-1.1ZM13.7,90v-1h4.5v1h-4.5Z" fill="#bfbfbf"/>
            <path d="M23.4,92.2c-.7,0-1.2,0-1.7-.4s-.8-.7-1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2c0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5h-.3Z" fill="#bfbfbf"/>
            <path d="M29.9,92.2c-.7,0-1.2,0-1.7-.4s-.8-.7-1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2c0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5h-.3Z" fill="#bfbfbf"/>
            <path d="M36.5,92.2c-.6,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8,0-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8s-.8-.6-1.4-.6-1.2.2-1.5.6-.5,1-.5,1.8.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3s-1.1.5-1.7.5h.1ZM34.1,89v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
            <path d="M40.6,94.9v-9.4h1v4.8h0v4.6h-1.1ZM43.6,92.2c-.6,0-1.1-.2-1.5-.5s-.7-.7-.9-1.2-.3-1.1-.3-1.7,0-1.2.3-1.7.5-.9.9-1.2c.4-.3.9-.4,1.5-.4s1.1,0,1.6.4.8.7,1,1.2.3,1.1.3,1.7,0,1.2-.3,1.7-.6.9-1,1.2c-.4.3-1,.4-1.6.4h0ZM43.5,91.2c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.2,0-.9-.2-1.2c0-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8,0-1,.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.3,0,.9.2,1.3c0,.4.3.7.6.9.3.2.6.3,1.1.3v-.2Z" fill="#bfbfbf"/>
            <path d="M47.1,86.4v-.9h4.3v.9h-4.3ZM51.5,92c-.4,0-.8,0-1.1,0-.4,0-.7,0-1-.2s-.5-.4-.7-.7-.2-.5-.2-.8v-6.7h1.1v6.5c0,.2,0,.4,0,.5,0,.3.4.4.7.5s.7,0,1.1,0c0,0,0,.9,0,.9Z" fill="#bfbfbf"/>
            <path d="M55.3,92.2c-.6,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8,0-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8s-.8-.6-1.4-.6-1.2.2-1.5.6-.5,1-.5,1.8.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3s-1.1.5-1.7.5h.1ZM52.9,89v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
            <path d="M62.1,92.2c-.6,0-1.1,0-1.6-.4-.4-.3-.8-.7-1-1.2s-.4-1.1-.4-1.7,0-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1,0,1.5.4.7.7.9,1.2c.2.5.3,1.1.3,1.7s0,1.2-.3,1.7c-.2.5-.5.9-.9,1.2-.4.3-.9.5-1.5.5h0ZM62.3,91.2c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9.1-.4.2-.8.2-1.3s0-.9-.2-1.3c0-.4-.3-.7-.6-.9s-.6-.3-1-.3-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2,0,.9.2,1.2c0,.4.4.7.6.9.3.2.6.3,1.1.3v.2ZM64.2,92v-4.8h0v-3.9h1.1v8.6h-1Z" fill="#bfbfbf"/>
          </g>
        </g>
        <line id="Line_63" x1="231.9" y1="39.1" x2="328.7" y2="39.1" fill="none" stroke="#ffc72e"/>
        <line id="Line_60" x1="333.4" y1="39.1" x2="618.2" y2="39.1" fill="none" stroke="#d4d4d4"/>
        <g id="Group_552">
          <circle id="Ellipse_88" cx="233.5" cy="38.5" r="38.5" fill="#ffeaaf"/>
          <path id="Path_638" d="M233.6,8.2c16.8,0,30.3,13.6,30.3,30.3s-13.6,30.3-30.3,30.3-30.3-13.6-30.3-30.3h0c0-16.8,13.6-30.3,30.3-30.3h0Z" fill="#ffc72e"/>
        </g>
        <g id="Group_550">
          <ellipse id="Ellipse_89" cx="424" cy="38.5" rx="38" ry="38.5" fill="#ffeaaf" fill-opacity="0"/>
          <ellipse id="Ellipse_84" cx="424" cy="38.5" rx="31" ry="30.5" fill="#f1f1f1"/>
        </g>
        <g id="Group_551">
          <ellipse id="Ellipse_90" cx="621" cy="38.5" rx="39" ry="38.5" fill="#cffbff" fill-opacity="0"/>
          <ellipse id="Ellipse_85" cx="621" cy="38.5" rx="30" ry="30.5" fill="#f1f1f1"/>
        </g>
        <circle id="Ellipse_87" cx="334" cy="39" r="5" fill="#ffc72e"/>
        <g id="thumbs_up">
          <g id="Group_676">
            <g id="Group_675">
              <path id="Path_672" d="M632.3,39.4c0-.8-.7-1.5-1.5-1.5.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5h-6.2c.7-1.7,1-3.5.9-5.3,0-1.2-1-2.3-2.3-2.3h-.5c-.3,0-.6.2-.7.6-.7,2.9-1.9,6.2-4.9,6.9v10.9l2.6.9c.8.3,1.6.4,2.4.4h7.1c.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5h1.5c.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5h0c.8,0,1.5-.7,1.5-1.5h.1Z" fill="#d9d9d9"/>
            </g>
          </g>
          <g id="Group_678">
            <g id="Group_677">
              <path id="Path_673" d="M613.4,33.4h-3c-.4,0-.8.3-.8.8h0v13.6c0,.4.3.8.8.8h3c1.3,0,2.3-1,2.3-2.3v-10.6c0-1.3-1-2.3-2.3-2.3ZM613.4,45.5c-.4,0-.8-.3-.8-.8s.3-.8.8-.8.8.3.8.8h0c0,.4-.3.8-.8.8Z" fill="#d9d9d9"/>
            </g>
          </g>
          <g id="Group_680">
            <g id="Group_679">
              <path id="Path_674" d="M620.1,26l-1.1-1.1c-.3-.3-.8-.3-1.1,0s-.3.8,0,1.1l1.1,1.1c.3.3.8.3,1.1,0s.3-.8,0-1.1h0Z" fill="#d9d9d9"/>
            </g>
          </g>
          <g id="Group_682">
            <g id="Group_681">
              <path id="Path_675" d="M628.6,25c-.3-.3-.8-.3-1.1,0h0l-1.1,1.1c-.3.3-.3.8,0,1.1s.8.3,1.1,0l1.1-1.1c.3-.3.3-.8,0-1.1h0Z" fill="#d9d9d9"/>
            </g>
          </g>
          <g id="Group_684">
            <g id="Group_683">
              <path id="Path_676" d="M623.3,22.7c-.4,0-.8.3-.8.7h0v1.6c0,.4.4.7.8.7s.7-.3.7-.7v-1.6c0-.4-.3-.8-.7-.8h0Z" fill="#d9d9d9"/>
            </g>
          </g>
        </g>
        <g id="inventory_2_black_24dp">
          <g id="Group_4910">
            <rect id="Rectangle_1309" x="221" y="26" width="26" height="26" fill="none"/>
          </g>
          <g id="Group_4911">
            <path id="Path_3926" d="M242.3,28.6h-16.6c-1.1,0-2,.9-2.1,2.1v3.1c0,.7.4,1.4,1,1.8v11.8c0,1.1,1,2,2.1,2.1h14.6c1.1,0,2-1,2.1-2.1v-11.8c.6-.4,1-1,1-1.8v-3.1c0-1.1-.9-2-2.1-2.1ZM237.1,41.1h-6.2v-2.1h6.2v2.1ZM242.3,33.8h-16.6v-3.1h16.6v3.1Z" fill="#fff"/>
          </g>
        </g>
        <g id="local_shipping_black_24dp">
          <path id="Path_3927" d="M412,26h26v26h-26v-26Z" fill="none"/>
          <path id="Path_3928" d="M433.5,35h-3.2v-4h-14.9c-1.1,0-2.1.8-2.1,2v10.9h2.1c0,1.7,1.5,3,3.2,3h0c1.7,0,3.1-1.3,3.2-3h6.4c0,1.7,1.5,3,3.2,3h0c1.7,0,3.1-1.3,3.2-3h2.1v-4.9l-3.2-4h0ZM418.6,45.4c-.8,0-1.5-.6-1.6-1.4,0-.8.6-1.5,1.4-1.6h.2c.8,0,1.5.6,1.6,1.4,0,.8-.6,1.5-1.4,1.6h-.2ZM433,36.5l2.1,2.5h-4.8v-2.5h2.7,0ZM431.4,45.4c-.8,0-1.4-.8-1.4-1.6s.8-1.4,1.6-1.4,1.4.7,1.4,1.5-.7,1.5-1.6,1.5h0Z" fill="#d9d9d9"/>
        </g>
        <g>
          <path d="M404.7,92.2c-.6,0-1.2,0-1.7-.3s-.9-.5-1.2-.9c-.3-.4-.5-.8-.6-1.4l1.1-.2c.1.5.4,1,.9,1.3.4.3,1,.5,1.6.5s.7,0,1-.2.6-.3.7-.5c.2-.2.3-.5.3-.8s0-.4-.1-.5c0,0-.2-.3-.3-.4-.1,0-.2-.2-.4-.2s-.3,0-.4-.2l-2.2-.7c-.2,0-.5-.2-.7-.3s-.4-.2-.6-.4c-.2-.2-.3-.4-.4-.6s-.2-.5-.2-.8c0-.5.1-.9.4-1.3s.6-.6,1.1-.8c.5-.2,1-.3,1.5-.3s1.1,0,1.6.3.8.5,1.1.9.5.8.6,1.3l-1.1.2c0-.3-.2-.7-.4-.9s-.5-.5-.8-.6-.6-.2-1-.2-.7,0-1,.2-.5.3-.7.5-.3.4-.3.7,0,.5.2.6c.2.2.3.3.6.4s.4.2.7.2l1.6.5c.2,0,.4,0,.7.2s.5.2.7.4c.2.2.4.4.6.7.2.3.2.6.2,1s0,.8-.2,1.1-.4.6-.7.8c-.3.2-.6.4-1,.5s-.8.2-1.3.2h0Z" fill="#bfbfbf"/>
          <path d="M409.1,92v-8.6h1v4.8h.1v3.9h-1.1ZM413.8,92v-4.1c0-.3-.1-.5-.3-.8-.1-.2-.3-.4-.5-.5s-.5-.2-.9-.2-.5,0-.7,0c-.2,0-.4.2-.6.4s-.3.4-.4.7c0,.3-.1.6-.1,1l-.7-.2c0-.6.1-1.2.3-1.6.2-.5.5-.8,1-1s.9-.4,1.4-.4.8,0,1.1.2.5.3.7.5.3.5.5.7c.1.3.2.5.2.8v4.4h-1.1.1Z" fill="#bfbfbf"/>
          <path d="M416.5,84.4v-1.1h1.1v1.1h-1.1ZM416.5,92v-6.5h1.1v6.5h-1.1Z" fill="#bfbfbf"/>
          <path d="M419.4,94.9v-9.4h1v4.8h.1v4.6h-1.1ZM422.4,92.2c-.6,0-1.1-.2-1.5-.5s-.7-.7-.9-1.2-.3-1.1-.3-1.7.1-1.2.3-1.7.5-.9.9-1.2c.4-.3.9-.4,1.5-.4s1.1,0,1.6.4c.4.3.8.7,1,1.2s.3,1.1.3,1.7-.1,1.2-.3,1.7c-.2.5-.6.9-1,1.2-.4.3-1,.4-1.6.4h0ZM422.2,91.2c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.2,0-.9-.2-1.2c-.1-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8,0-1,.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.3,0,.9.2,1.3c.1.4.3.7.6.9.3.2.6.3,1.1.3v-.2Z" fill="#bfbfbf"/>
          <path d="M426.6,94.9v-9.4h1v4.8h.1v4.6h-1.1ZM429.6,92.2c-.6,0-1.1-.2-1.5-.5s-.7-.7-.9-1.2-.3-1.1-.3-1.7.1-1.2.3-1.7.5-.9.9-1.2.9-.4,1.5-.4,1.1,0,1.6.4c.4.3.8.7,1,1.2s.3,1.1.3,1.7-.1,1.2-.3,1.7c-.2.5-.6.9-1,1.2-.4.3-1,.4-1.6.4h0ZM429.4,91.2c.4,0,.8,0,1.1-.3s.5-.5.6-.9.2-.8.2-1.2,0-.9-.2-1.2c-.1-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8,0-1,.3-.5.5-.6.9-.2.8-.2,1.3,0,.9.2,1.3c.1.4.3.7.6.9s.6.3,1.1.3v-.2Z" fill="#bfbfbf"/>
          <path d="M436.6,92.2c-.6,0-1.2,0-1.7-.4s-.8-.7-1.1-1.2c-.3-.5-.4-1.1-.4-1.8s.1-1.3.4-1.8.6-.9,1.1-1.2c.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8s-.8-.6-1.4-.6-1.2.2-1.5.6-.5,1-.5,1.8.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3s-1.1.5-1.7.5h.1ZM434.2,89v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
          <path d="M443.4,92.2c-.6,0-1.1,0-1.6-.4-.4-.3-.8-.7-1-1.2s-.4-1.1-.4-1.7.1-1.2.4-1.7c.2-.5.6-.9,1-1.2.4-.3.9-.4,1.5-.4s1.1,0,1.5.4.7.7.9,1.2.3,1.1.3,1.7-.1,1.2-.3,1.7-.5.9-.9,1.2c-.4.3-.9.5-1.5.5h.1ZM443.6,91.2c.4,0,.8,0,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.3,0-.9-.2-1.3-.3-.7-.6-.9c-.3-.2-.6-.3-1-.3s-.8,0-1.1.3c-.3.2-.5.5-.6.9s-.2.8-.2,1.2,0,.9.2,1.2.4.7.6.9.6.3,1.1.3v.2ZM445.5,92v-4.8h-.1v-3.9h1.1v8.6h-1Z" fill="#bfbfbf"/>
        </g>
        <g>
          <path d="M208.1,100.5v-10.1h4.9c.6,0,1,.3,1.4.6.4.3.7.7.8,1.1.2.4.3.9.3,1.5s0,1-.3,1.5c-.2.4-.5.8-.8,1.1-.4.3-.8.5-1.4.6h-3.4v3.6h-1.5ZM209.6,95.5h3.2c.3,0,.5-.2.7-.4.2-.2.3-.4.4-.7,0-.2.1-.5.1-.7s0-.5-.1-.7-.2-.5-.4-.7-.4-.3-.7-.4h-3.2v3.7h0Z"/>
          <path d="M218.8,100.7c-.6,0-1,0-1.4-.3-.4-.2-.7-.5-.9-.8s-.3-.7-.3-1.1,0-.7.2-1c.1-.3.3-.5.6-.7s.6-.4,1-.5c.3,0,.7-.2,1.1-.3.4,0,.9,0,1.3-.2s.9,0,1.4-.2l-.5.3c0-.6-.1-1-.4-1.3-.3-.3-.7-.4-1.3-.4s-.8,0-1.1.3-.6.5-.7.9l-1.4-.4c.2-.6.5-1.2,1.1-1.5.5-.4,1.2-.6,2.1-.6s1.3,0,1.8.3c.5.2.9.6,1.1,1.1.1.2.2.5.2.8v5.6h-1.3v-1.7l.3.2c-.3.6-.7,1-1.2,1.3s-1.1.4-1.8.4v-.2ZM219.1,99.5c.4,0,.8,0,1.1-.2.3,0,.5-.3.7-.6.2-.2.3-.5.4-.7,0-.2.1-.5.1-.8v-.7l.5.2c-.5,0-.9,0-1.3.2-.4,0-.7,0-1,.2s-.6,0-.8.2c-.2,0-.4.2-.5.3-.2,0-.3.2-.4.4,0,0-.1.3-.1.5s0,.4.1.5.2.3.4.4c.2,0,.5,0,.8,0Z"/>
          <path d="M227.9,100.7c-.8,0-1.4-.2-2-.5-.5-.3-1-.8-1.2-1.4-.3-.6-.4-1.3-.4-2.1s.2-1.5.4-2.1.7-1.1,1.3-1.4,1.2-.5,2-.5,1.5.2,2.1.6,1,1,1.2,1.7l-1.5.4c-.1-.4-.4-.8-.7-1s-.7-.4-1.1-.4-.9,0-1.2.3c-.3.2-.5.5-.7.9s-.2.8-.2,1.3c0,.8.2,1.4.5,1.9.4.5.9.7,1.6.7s.9,0,1.1-.3c.3-.2.5-.5.7-.9l1.5.4c-.2.7-.7,1.3-1.2,1.7-.6.4-1.3.6-2.1.6h-.1Z"/>
          <path d="M232.6,100.5v-10.1h1.5v6.2l3-3.6h1.9l-3.1,3.8,3.5,3.8h-2l-3.2-3.6v3.6h-1.6Z"/>
          <path d="M240.1,91.7v-1.4h1.5v1.4h-1.5ZM240.1,100.5v-7.6h1.5v7.6h-1.5Z"/>
          <path d="M243.7,100.5v-7.6h1.3v2.2h.2v5.4h-1.5ZM249,100.5v-4.6c0-.3-.1-.6-.3-.9-.1-.3-.3-.5-.6-.6-.2-.2-.6-.2-1-.2s-.5,0-.7,0c-.2,0-.4.2-.6.4-.2.2-.3.4-.4.8-.1.3-.2.7-.2,1.1l-.9-.3c0-.7.1-1.3.4-1.8.3-.5.6-.9,1.1-1.2.5-.3,1.1-.4,1.7-.4s1,0,1.3.3c.4.2.6.4.9.7.2.3.4.6.5.9s.2.6.2.9v5h-1.5v-.2Z"/>
          <path d="M255.3,100.7c-.7,0-1.3-.2-1.8-.5s-.9-.8-1.2-1.4-.4-1.3-.4-2,.1-1.4.4-2c.3-.6.7-1.1,1.2-1.4s1.1-.5,1.8-.5,1.3.2,1.8.5.9.8,1.1,1.4.4,1.3.4,2-.1,1.4-.4,2-.6,1.1-1.1,1.4c-.5.4-1.1.5-1.8.5ZM255.4,104c-.4,0-.8,0-1.2-.2-.4,0-.7-.3-1-.6s-.6-.5-.8-.9l1.4-.7c.2.3.4.6.7.7s.6.2,1,.2.8,0,1.1-.2c.3,0,.5-.4.7-.7.2-.3.2-.7.2-1.1v-2.1h.2v-5.6h1.3v8.9c-.1.5-.3.9-.6,1.3-.3.3-.7.6-1.1.7-.5.2-1,.2-1.6.2h-.3ZM255.5,99.4c.5,0,.9,0,1.2-.3.3-.2.5-.5.7-.9.1-.4.2-.9.2-1.4s0-1-.2-1.4c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1.1-.3s-.9,0-1.2.4c-.3.2-.5.6-.7,1s-.2.9-.2,1.3,0,1,.2,1.4.4.7.7,1,.7.4,1.2.4v-.3Z"/>
        </g>
        <g>
          <path d="M595.3,92v-8.6h2.7c0,0,.2,0,.5,0s.4,0,.6,0c.7,0,1.2.3,1.7.7.4.4.8.9,1,1.5.2.6.3,1.3.3,2s-.1,1.4-.3,2c-.2.6-.6,1.1-1,1.5-.5.4-1,.6-1.7.7-.2,0-.4,0-.6,0s-.4,0-.5,0h-2.7ZM596.4,91h1.6c.2,0,.3,0,.5,0,.2,0,.4,0,.5,0,.5,0,.9-.3,1.1-.6.3-.3.5-.7.6-1.2s.2-1,.2-1.5,0-1-.2-1.5-.4-.8-.6-1.1c-.3-.3-.7-.5-1.1-.6-.1,0-.3,0-.5,0-.2,0-.4,0-.5,0h-1.6v6.6Z" fill="#bfbfbf"/>
          <path d="M606.3,92.2c-.6,0-1.2-.1-1.7-.4-.5-.3-.8-.7-1.1-1.2s-.4-1.1-.4-1.8.1-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8-.3-.4-.8-.6-1.4-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3-.5.3-1.1.5-1.7.5ZM603.9,89.1v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
          <path d="M610.7,92v-8.8h1.1v8.8h-1.1Z" fill="#bfbfbf"/>
          <path d="M613.7,84.4v-1.1h1.1v1.1h-1.1ZM613.7,92v-6.5h1.1v6.5h-1.1Z" fill="#bfbfbf"/>
          <path d="M618.3,92l-2.4-6.5h1.1l1.8,5.1,1.8-5.1h1.1l-2.4,6.5h-1.1Z" fill="#bfbfbf"/>
          <path d="M625.4,92.2c-.6,0-1.2-.1-1.7-.4-.5-.3-.8-.7-1.1-1.2s-.4-1.1-.4-1.8.1-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8-.3-.4-.8-.6-1.4-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3-.5.3-1.1.5-1.7.5ZM623,89.1v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
          <path d="M629.7,92v-6.5h1v1.6l-.2-.2c0-.2.2-.4.3-.6s.3-.3.4-.4c.2-.1.4-.2.6-.3.2,0,.4-.1.6-.1.2,0,.4,0,.6,0v1c-.2,0-.5,0-.7,0-.3,0-.5.1-.7.3-.2.2-.4.3-.5.5-.1.2-.2.4-.2.7,0,.2,0,.5,0,.8v3.3h-1.1Z" fill="#bfbfbf"/>
          <path d="M636.9,92.2c-.6,0-1.2-.1-1.7-.4-.5-.3-.8-.7-1.1-1.2s-.4-1.1-.4-1.8.1-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8-.3-.4-.8-.6-1.4-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3-.5.3-1.1.5-1.7.5ZM634.5,89.1v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
          <path d="M643.7,92.2c-.6,0-1.1-.1-1.6-.4-.4-.3-.8-.7-1-1.2-.2-.5-.4-1.1-.4-1.7s.1-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1.1,1.5.4.7.7.9,1.2.3,1.1.3,1.7-.1,1.2-.3,1.7-.5.9-.9,1.2c-.4.3-.9.5-1.5.5ZM643.8,91.2c.4,0,.8-.1,1.1-.3s.5-.5.6-.9c.1-.4.2-.8.2-1.3s0-.9-.2-1.3-.3-.7-.6-.9c-.3-.2-.6-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2s0,.9.2,1.2c.1.4.4.7.6.9s.6.3,1.1.3ZM645.7,92v-4.8h-.1v-3.9h1.1v8.6h-1Z" fill="#bfbfbf"/>
        </g>
      </g>
    </svg>
  );
  const deliveryShipped = (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 660 104.9">

      <g id="d_s_shipped_1">
        <g id="Group_4699" opacity=".5">
          <line id="Line_64" x1="60.3" y1="39.1" x2="157.2" y2="39.1" fill="none" stroke="#2ec278" stroke-opacity="0"/>
          <line id="Line_59" x1="69.6" y1="39.1" x2="202.7" y2="39.1" fill="none" stroke="#2ec278"/>
          <line id="Line_63" x1="231.9" y1="39.1" x2="390.6" y2="39.1" fill="none" stroke="#ff9557"/>
          <circle id="Ellipse_86" cx="38.5" cy="38.5" r="38.5" fill="#ffd5d5" fill-opacity="0"/>
          <circle id="Ellipse_82" cx="38.5" cy="38.5" r="30.5" fill="#2ec278"/>
          <g id="Group_552">
            <circle id="Ellipse_88" cx="234.5" cy="38.5" r="38.5" fill="#acffd6" fill-opacity="0"/>
            <path id="Path_638" d="M234.1,8.2c17,0,30.9,13.8,30.9,30.9s-13.8,30.9-30.9,30.9-30.9-13.8-30.9-30.9h0c0-17,13.8-30.9,30.9-30.9Z" fill="#ffc72e"/>
            <g id="inventory_2_black_24dp">
              <g id="Group_4910">
                <rect id="Rectangle_1309" x="221" y="26" width="26" height="26" fill="none"/>
              </g>
              <g id="Group_4911">
                <path id="Path_3926" d="M242.3,28.6h-16.6c-1.1,0-2,.9-2.1,2.1v3.1c0,.7.4,1.4,1,1.8v11.8c0,1.1,1,2,2.1,2.1h14.6c1.1,0,2-1,2.1-2.1v-11.8c.6-.4,1-1,1-1.8v-3.1c0-1.1-.9-2-2.1-2.1ZM237.1,41.1h-6.2v-2.1h6.2v2.1ZM242.3,33.8h-16.6v-3.1h16.6v3.1Z" fill="#fff"/>
              </g>
            </g>
          </g>
          <g id="verified-24px">
            <g id="Group_564">
              <rect id="Rectangle_235" x="25" y="26" width="28" height="28" fill="none"/>
            </g>
            <g id="Group_565">
              <path id="Path_623" d="M51.5,39.7l-2.8-3.3.4-4.3-4.2-1-2.2-3.7-4,1.7-4-1.7-2.2,3.7-4.2.9.4,4.3-2.9,3.3,2.8,3.3-.4,4.3,4.2,1,2.2,3.7,4-1.7,4,1.7,2.2-3.7,4.2-1-.4-4.3,2.9-3.3ZM36.5,45.2l-4.4-4.4,1.7-1.7,2.7,2.7,6.8-6.9,1.7,1.7-8.6,8.6Z" fill="#fff"/>
            </g>
          </g>
          <g>
            <path d="M213.2,91.8v-8.6h3.4c0,0,.2,0,.3,0,.1,0,.2,0,.3,0,.5,0,.9.2,1.2.5.3.2.6.6.7.9.2.4.2.8.2,1.3s0,.9-.2,1.3c-.2.4-.4.7-.7.9s-.7.4-1.2.5c-.1,0-.2,0-.3,0-.1,0-.2,0-.3,0h-2.3v3.2h-1.1ZM214.3,87.6h2.3c0,0,.2,0,.3,0s.2,0,.3,0c.3,0,.5-.2.7-.4.2-.2.3-.4.4-.6s.1-.5.1-.7,0-.5-.1-.7c0-.2-.2-.4-.4-.6-.2-.2-.4-.3-.7-.4,0,0-.2,0-.3,0,0,0-.2,0-.3,0h-2.3v3.4Z" fill="#bfbfbf"/>
            <path d="M222.2,92c-.5,0-.9,0-1.2-.3-.3-.2-.6-.4-.7-.7s-.2-.6-.2-1,0-.6.2-.9.3-.5.5-.6c.2-.2.5-.3.8-.4.3,0,.7-.2,1-.2.4,0,.8-.1,1.2-.2.4,0,.8-.1,1.1-.2l-.4.2c0-.6-.1-1-.3-1.3-.2-.3-.7-.4-1.2-.4s-.7,0-1,.3c-.3.2-.5.5-.6.9l-1-.3c.2-.6.5-1,.9-1.3.4-.3,1-.5,1.8-.5s1.1.1,1.5.3c.4.2.7.5.9,1,0,.2.2.4.2.7,0,.2,0,.5,0,.7v4h-.9v-1.6h.2c-.2.7-.6,1.1-1,1.3-.4.3-1,.4-1.6.4ZM222.4,91.1c.4,0,.7,0,1-.2.3-.1.5-.3.7-.5.2-.2.3-.5.3-.7,0-.2,0-.4,0-.7,0-.2,0-.4,0-.6l.4.2c-.3,0-.7.1-1,.1s-.7,0-1,.1-.6.1-.8.2c-.2,0-.3.1-.5.2-.2,0-.3.2-.4.3,0,.1-.1.3-.1.5s0,.3.1.5c0,.2.2.3.4.4.2.1.4.2.7.2Z" fill="#bfbfbf"/>
            <path d="M229.9,92c-.7,0-1.2-.1-1.7-.4s-.8-.7-1-1.2c-.2-.5-.4-1.1-.4-1.8,0-.7.1-1.3.4-1.8.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5c.5.3.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2,0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5Z" fill="#bfbfbf"/>
            <path d="M233.9,91.8v-8.6h1.1v5.3l2.7-3.1h1.4l-2.9,3.2,3.2,3.2h-1.5l-2.9-3.1v3.1h-1.1Z" fill="#bfbfbf"/>
            <path d="M240.1,84.2v-1.1h1.1v1.1h-1.1ZM240.1,91.8v-6.5h1.1v6.5h-1.1Z" fill="#bfbfbf"/>
            <path d="M243,91.8v-6.5h1v1.7h.1v4.7h-1.1ZM247.6,91.8v-3.2c0-.3,0-.6,0-.9,0-.3-.1-.5-.3-.8-.1-.2-.3-.4-.5-.5-.2-.1-.5-.2-.9-.2s-.5,0-.7.1c-.2,0-.4.2-.6.4-.2.2-.3.4-.4.7,0,.3-.1.6-.1,1l-.7-.2c0-.6.1-1.2.3-1.6.2-.5.5-.8,1-1s.9-.4,1.4-.4.8,0,1.1.2.5.3.7.5c.2.2.4.5.5.7.1.3.2.5.2.8,0,.3,0,.5,0,.8v3.6h-1.1Z" fill="#bfbfbf"/>
            <path d="M252.8,92c-.6,0-1.1-.1-1.6-.4-.4-.3-.8-.7-1-1.2-.2-.5-.4-1.1-.4-1.7s.1-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1.1,1.5.4.7.7.9,1.2c.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.5.9-.9,1.2-.4.3-.9.5-1.5.5ZM252.9,94.9c-.3,0-.7,0-1-.2-.3-.1-.6-.3-.9-.5s-.5-.5-.7-.8l1-.5c.2.3.4.5.7.7.3.1.6.2.9.2s.8,0,1-.2c.3-.1.5-.4.6-.7.1-.3.2-.7.2-1.1v-1.8h.1v-4.7h.9v6.5c0,.2,0,.4,0,.5,0,.2,0,.3,0,.5,0,.5-.3.8-.5,1.1-.2.3-.6.5-1,.7-.4.1-.8.2-1.4.2ZM252.9,91c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9.1-.4.2-.8.2-1.3s0-.9-.2-1.3c-.1-.4-.3-.7-.6-.9s-.6-.3-1-.3-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2s0,.9.2,1.2c.1.4.4.7.6.9.3.2.6.3,1.1.3Z" fill="#bfbfbf"/>
          </g>
          <g>
            <path d="M11.8,91.8l2.9-8.6h1.5l2.9,8.6h-1.1l-2.7-8h.3l-2.7,8h-1.1ZM13.2,89.8v-1h4.5v1h-4.5Z" fill="#bfbfbf"/>
            <path d="M22.9,92c-.7,0-1.2-.1-1.7-.4s-.8-.7-1-1.2c-.2-.5-.4-1.1-.4-1.8,0-.7.1-1.3.4-1.8.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5c.5.3.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2,0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5Z" fill="#bfbfbf"/>
            <path d="M29.4,92c-.7,0-1.2-.1-1.7-.4s-.8-.7-1-1.2c-.2-.5-.4-1.1-.4-1.8,0-.7.1-1.3.4-1.8.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5c.5.3.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2,0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5Z" fill="#bfbfbf"/>
            <path d="M36,92c-.6,0-1.2-.1-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8-.3-.4-.8-.6-1.4-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.4.5,1.8c.3.4.8.6,1.5.6s.8,0,1.1-.3.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3-.5.3-1.1.5-1.7.5ZM33.6,88.9v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
            <path d="M40.1,94.7v-9.4h1v4.8h.1v4.6h-1.1ZM43.1,92c-.6,0-1.1-.2-1.5-.5-.4-.3-.7-.7-.9-1.2s-.3-1.1-.3-1.7.1-1.2.3-1.7.5-.9.9-1.2c.4-.3.9-.4,1.5-.4s1.1.1,1.6.4.8.7,1,1.2c.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.6.9-1,1.2-.4.3-1,.4-1.6.4ZM43,91c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.2,0-.9-.2-1.2c-.1-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8.1-1,.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.3s0,.9.2,1.3c.1.4.3.7.6.9.3.2.6.3,1.1.3Z" fill="#bfbfbf"/>
            <path d="M46.6,86.2v-.9h4.3v.9h-4.3ZM51,91.8c-.4,0-.8.1-1.1,0-.4,0-.7,0-1-.2s-.5-.4-.7-.7-.2-.5-.2-.8c0-.3,0-.6,0-.9v-5.8h1.1v5.8c0,.3,0,.5,0,.7,0,.2,0,.4.1.5.1.3.4.4.7.5s.7,0,1.1,0v.9Z" fill="#bfbfbf"/>
            <path d="M54.8,92c-.6,0-1.2-.1-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8-.3-.4-.8-.6-1.4-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.4.5,1.8c.3.4.8.6,1.5.6s.8,0,1.1-.3.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3-.5.3-1.1.5-1.7.5ZM52.4,88.9v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
            <path d="M61.6,92c-.6,0-1.1-.1-1.6-.4-.4-.3-.8-.7-1-1.2-.2-.5-.4-1.1-.4-1.7s.1-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1.1,1.5.4.7.7.9,1.2c.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.5.9-.9,1.2-.4.3-.9.5-1.5.5ZM61.8,91c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9.1-.4.2-.8.2-1.3s0-.9-.2-1.3c-.1-.4-.3-.7-.6-.9s-.6-.3-1-.3-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2s0,.9.2,1.2c.1.4.4.7.6.9.3.2.6.3,1.1.3ZM63.7,91.8v-4.8h-.1v-3.9h1.1v8.6h-1Z" fill="#bfbfbf"/>
          </g>
        </g>
        <line id="Line_65" x1="437.3" y1="39.1" x2="527.2" y2="39.1" fill="none" stroke="#40bac4"/>
        <line id="Line_60" x1="527.2" y1="39.1" x2="618.3" y2="39.1" fill="none" stroke="#d4d4d4"/>
        <g id="Group_550">
          <circle id="Ellipse_89" cx="423.5" cy="38.5" r="38.5" fill="#cffbff"/>
          <circle id="Ellipse_84" cx="423.5" cy="38.5" r="30.5" fill="#40bac4"/>
        </g>
        <g id="Group_551">
          <ellipse id="Ellipse_90" cx="621" cy="38.5" rx="39" ry="38.5" fill="#cffbff" fill-opacity="0"/>
          <circle id="Ellipse_85" cx="621.5" cy="38.5" r="30.5" fill="#f1f1f1"/>
        </g>
        <circle id="Ellipse_87" cx="527" cy="39" r="5" fill="#40bac4"/>
        <g id="local_shipping_black_24dp">
          <path id="Path_3927" d="M412,26h26v26h-26v-26Z" fill="none"/>
          <path id="Path_3928" d="M433.5,35h-3.2v-4h-14.9c-1.1,0-2.1.8-2.1,2v10.9h2.1c0,1.7,1.5,3,3.2,3h0c1.7,0,3.1-1.3,3.2-3,0,0,0,0,0,0h6.4c0,1.7,1.5,3,3.2,3,0,0,0,0,0,0,1.7,0,3.1-1.3,3.2-3,0,0,0,0,0,0h2.1v-4.9l-3.2-4ZM418.6,45.4c-.8,0-1.5-.6-1.6-1.4,0-.8.6-1.5,1.4-1.6,0,0,.1,0,.2,0,.8,0,1.5.6,1.6,1.4,0,.8-.6,1.5-1.4,1.6,0,0-.1,0-.2,0ZM433,36.5l2.1,2.5h-4.8v-2.5h2.7ZM431.4,45.4c-.8,0-1.4-.8-1.4-1.6s.8-1.4,1.6-1.4c.8,0,1.4.7,1.4,1.5,0,.9-.7,1.5-1.6,1.5,0,0,0,0,0,0Z" fill="#fff"/>
        </g>
        <g id="thumbs_up">
          <g id="Group_676">
            <g id="Group_675">
              <path id="Path_672" d="M632.3,41.4c0-.8-.7-1.5-1.5-1.5.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5h-6.2c.7-1.7,1-3.5.9-5.3,0-1.2-1-2.3-2.3-2.3h-.5c-.3,0-.6.2-.7.6-.7,2.9-1.9,6.2-4.9,6.9v10.9l2.6.9c.8.3,1.6.4,2.4.4h7.1c.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5h1.5c.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5h0c.8,0,1.5-.7,1.5-1.5Z" fill="#d9d9d9"/>
            </g>
          </g>
          <g id="Group_678">
            <g id="Group_677">
              <path id="Path_673" d="M613.4,35.4h-3c-.4,0-.8.3-.8.8h0v13.6c0,.4.3.8.8.8h3c1.3,0,2.3-1,2.3-2.3v-10.6c0-1.3-1-2.3-2.3-2.3ZM613.4,47.5c-.4,0-.8-.3-.8-.8s.3-.8.8-.8.8.3.8.8h0c0,.4-.3.8-.8.8Z" fill="#d9d9d9"/>
            </g>
          </g>
          <g id="Group_680">
            <g id="Group_679">
              <path id="Path_674" d="M620.1,28l-1.1-1.1c-.3-.3-.8-.3-1.1,0s-.3.8,0,1.1l1.1,1.1c.3.3.8.3,1.1,0s.3-.8,0-1.1h0Z" fill="#d9d9d9"/>
            </g>
          </g>
          <g id="Group_682">
            <g id="Group_681">
              <path id="Path_675" d="M628.6,27c-.3-.3-.8-.3-1.1,0h0s-1.1,1.1-1.1,1.1c-.3.3-.3.8,0,1.1s.8.3,1.1,0l1.1-1.1c.3-.3.3-.8,0-1.1,0,0,0,0,0,0Z" fill="#d9d9d9"/>
            </g>
          </g>
          <g id="Group_684">
            <g id="Group_683">
              <path id="Path_676" d="M623.3,24.7c-.4,0-.8.3-.8.7,0,0,0,0,0,0v1.6c0,.4.4.7.8.7.4,0,.7-.3.7-.7v-1.6c0-.4-.3-.8-.7-.8,0,0,0,0,0,0Z" fill="#d9d9d9"/>
            </g>
          </g>
        </g>
        <g>
          <path d="M595.3,91.8v-8.6h2.7c0,0,.2,0,.5,0s.4,0,.6,0c.7,0,1.2.3,1.7.7.5.4.8.9,1,1.5.2.6.3,1.3.3,2s-.1,1.4-.3,2c-.2.6-.6,1.1-1,1.5-.4.4-1,.6-1.7.7-.2,0-.4,0-.6,0s-.4,0-.5,0h-2.7ZM596.4,90.8h1.6c.2,0,.3,0,.5,0,.2,0,.4,0,.5,0,.5,0,.9-.3,1.1-.6.3-.3.5-.7.6-1.2s.2-1,.2-1.5,0-1-.2-1.5c-.1-.5-.4-.8-.6-1.1-.3-.3-.7-.5-1.1-.6-.1,0-.3,0-.5,0-.2,0-.4,0-.5,0h-1.6v6.6Z" fill="#bfbfbf"/>
          <path d="M606.3,92c-.6,0-1.2-.1-1.7-.4-.5-.3-.8-.7-1.1-1.2-.3-.5-.4-1.1-.4-1.8s.1-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8-.3-.4-.8-.6-1.4-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3-.5.3-1.1.5-1.7.5ZM603.9,88.9v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
          <path d="M610.7,91.8v-8.8h1.1v8.8h-1.1Z" fill="#bfbfbf"/>
          <path d="M613.7,84.2v-1.1h1.1v1.1h-1.1ZM613.7,91.8v-6.5h1.1v6.5h-1.1Z" fill="#bfbfbf"/>
          <path d="M618.3,91.8l-2.4-6.5h1.1l1.8,5.1,1.8-5.1h1.1l-2.4,6.5h-1.1Z" fill="#bfbfbf"/>
          <path d="M625.4,92c-.6,0-1.2-.1-1.7-.4-.5-.3-.8-.7-1.1-1.2-.3-.5-.4-1.1-.4-1.8s.1-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8-.3-.4-.8-.6-1.4-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.4.5,1.8c.3.4.8.6,1.5.6s.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3-.5.3-1.1.5-1.7.5ZM623,88.9v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
          <path d="M629.7,91.8v-6.5h1v1.6l-.2-.2c0-.2.2-.4.3-.6s.3-.3.4-.4c.2-.1.4-.2.6-.3.2,0,.4-.1.6-.1.2,0,.4,0,.6,0v1c-.2,0-.5,0-.7,0-.3,0-.5.1-.7.3-.2.2-.4.3-.5.5-.1.2-.2.4-.2.7s0,.5,0,.8v3.3h-1.1Z" fill="#bfbfbf"/>
          <path d="M636.9,92c-.6,0-1.2-.1-1.7-.4-.5-.3-.8-.7-1.1-1.2-.3-.5-.4-1.1-.4-1.8s.1-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8-.3-.4-.8-.6-1.4-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.4.5,1.8.8.6,1.5.6.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3-.5.3-1.1.5-1.7.5ZM634.5,88.9v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
          <path d="M643.7,92c-.6,0-1.1-.1-1.6-.4-.4-.3-.8-.7-1-1.2-.2-.5-.4-1.1-.4-1.7s.1-1.2.4-1.7c.2-.5.6-.9,1-1.2.4-.3.9-.4,1.5-.4s1.1.1,1.5.4.7.7.9,1.2c.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.5.9-.9,1.2-.4.3-.9.5-1.5.5ZM643.8,91c.4,0,.8-.1,1.1-.3s.5-.5.6-.9c.1-.4.2-.8.2-1.3s0-.9-.2-1.3c-.1-.4-.3-.7-.6-.9-.3-.2-.6-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2s0,.9.2,1.2c.1.4.4.7.6.9.3.2.6.3,1.1.3ZM645.7,91.8v-4.8h-.1v-3.9h1.1v8.6h-1Z" fill="#bfbfbf"/>
        </g>
        <g>
          <path d="M400.5,100.5c-.7,0-1.4-.1-2-.4s-1.1-.6-1.4-1.1c-.4-.5-.6-1-.7-1.7l1.5-.2c.2.6.5,1.1,1,1.4.5.3,1.1.5,1.7.5s.8,0,1.1-.2c.3-.1.6-.3.8-.6.2-.2.3-.5.3-.9s0-.3,0-.5c0-.1-.1-.3-.3-.4-.1-.1-.2-.2-.4-.3s-.3-.1-.5-.2l-2.6-.8c-.3,0-.5-.2-.8-.3-.3-.1-.5-.3-.7-.5-.2-.2-.4-.4-.5-.7-.1-.3-.2-.6-.2-1,0-.6.2-1.1.5-1.6.3-.4.8-.8,1.3-1,.5-.2,1.2-.3,1.8-.3.7,0,1.3.1,1.8.4.5.2,1,.6,1.3,1,.4.4.6,1,.7,1.6l-1.6.3c0-.4-.2-.7-.4-1-.2-.3-.5-.5-.8-.6-.3-.1-.7-.2-1.1-.2-.4,0-.7,0-1,.2-.3.1-.6.3-.7.5s-.3.5-.3.8,0,.5.2.7c.2.2.4.3.6.4.2.1.5.2.7.2l1.9.5c.2,0,.5.2.8.3.3.1.6.3.9.5s.5.5.7.8c.2.3.3.7.3,1.2s-.1,1-.3,1.3c-.2.4-.5.7-.8,1s-.8.5-1.2.6c-.5.1-1,.2-1.5.2Z"/>
          <path d="M405.8,100.3v-10.1h1.3v5.4h.2v4.6h-1.5ZM411.2,100.3v-3.7c0-.3,0-.6,0-.9s-.1-.6-.3-.9c-.1-.3-.3-.5-.6-.6-.2-.2-.6-.2-1-.2s-.5,0-.7.1c-.2,0-.4.2-.6.4-.2.2-.3.4-.4.8-.1.3-.2.7-.2,1.1l-.9-.3c0-.7.1-1.3.4-1.8.3-.5.6-.9,1.1-1.2.5-.3,1.1-.4,1.7-.4s1,0,1.3.3c.4.2.6.4.9.7s.4.6.5.9c.1.3.2.6.2.9,0,.3,0,.6,0,.8v4.2h-1.5Z"/>
          <path d="M414.6,91.5v-1.4h1.5v1.4h-1.5ZM414.6,100.3v-7.6h1.5v7.6h-1.5Z"/>
          <path d="M418.2,103.7v-10.9h1.3v5.4h.2v5.5h-1.5ZM421.7,100.5c-.7,0-1.3-.2-1.8-.5-.5-.4-.9-.8-1.1-1.4-.3-.6-.4-1.3-.4-2s.1-1.4.4-2c.2-.6.6-1.1,1.1-1.4.5-.3,1.1-.5,1.8-.5s1.3.2,1.8.5c.5.3.9.8,1.2,1.4s.4,1.3.4,2-.1,1.4-.4,2-.7,1.1-1.2,1.4c-.5.4-1.1.5-1.8.5ZM421.5,99.2c.5,0,.9-.1,1.2-.4.3-.2.5-.6.7-1,.2-.4.2-.9.2-1.4s0-.9-.2-1.3c-.2-.4-.4-.7-.7-1-.3-.2-.7-.4-1.2-.4s-.8.1-1.1.3c-.3.2-.5.5-.7.9-.1.4-.2.9-.2,1.4s0,1,.2,1.4c.1.4.4.7.7.9.3.2.7.3,1.2.3Z"/>
          <path d="M426.7,103.7v-10.9h1.3v5.4h.2v5.5h-1.5ZM430.3,100.5c-.7,0-1.3-.2-1.8-.5-.5-.4-.9-.8-1.1-1.4-.3-.6-.4-1.3-.4-2s.1-1.4.4-2c.2-.6.6-1.1,1.1-1.4.5-.3,1.1-.5,1.8-.5s1.3.2,1.8.5c.5.3.9.8,1.2,1.4s.4,1.3.4,2-.1,1.4-.4,2-.7,1.1-1.2,1.4c-.5.4-1.1.5-1.8.5ZM430.1,99.2c.5,0,.9-.1,1.2-.4.3-.2.5-.6.7-1,.2-.4.2-.9.2-1.4s0-.9-.2-1.3c-.2-.4-.4-.7-.7-1-.3-.2-.7-.4-1.2-.4s-.8.1-1.1.3c-.3.2-.5.5-.7.9-.1.4-.2.9-.2,1.4s0,1,.2,1.4c.1.4.4.7.7.9.3.2.7.3,1.2.3Z"/>
          <path d="M438.6,100.5c-.8,0-1.4-.2-2-.5-.6-.3-1-.8-1.3-1.4-.3-.6-.5-1.3-.5-2.1s.2-1.5.5-2.2c.3-.6.7-1.1,1.3-1.4.6-.3,1.2-.5,1.9-.5s1.4.2,2,.5.9.9,1.2,1.5c.3.7.4,1.4.3,2.3h-1.5v-.5c0-.9-.2-1.5-.5-1.9-.3-.4-.8-.6-1.5-.6s-1.3.2-1.7.7c-.4.5-.6,1.1-.6,2s.2,1.5.6,1.9c.4.5.9.7,1.6.7s.9-.1,1.2-.3c.3-.2.6-.5.8-.9l1.4.5c-.3.7-.7,1.2-1.3,1.6-.6.4-1.3.6-2,.6ZM435.9,97v-1.1h5.4v1.1h-5.4Z"/>
          <path d="M446.6,100.5c-.7,0-1.3-.2-1.8-.5s-.9-.8-1.2-1.4c-.3-.6-.4-1.3-.4-2s.1-1.4.4-2c.3-.6.7-1.1,1.2-1.4.5-.3,1.1-.5,1.8-.5s1.3.2,1.8.5c.5.3.9.8,1.1,1.4.2.6.4,1.3.4,2s-.1,1.4-.4,2c-.3.6-.6,1.1-1.1,1.4-.5.4-1.1.5-1.8.5ZM446.8,99.2c.5,0,.9-.1,1.2-.3.3-.2.5-.5.7-.9.1-.4.2-.9.2-1.4s0-1-.2-1.4c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1.1-.3s-.9.1-1.2.4-.5.6-.7,1c-.2.4-.2.9-.2,1.3s0,1,.2,1.4c.2.4.4.7.7,1,.3.2.7.4,1.2.4ZM448.9,100.3v-5.4h-.2v-4.6h1.5v10.1h-1.3Z"/>
        </g>
      </g>
    </svg>
  );

  const deliveryCompleted = (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 659 103.1">

      <g id="d_s_delivered_1">
        <g id="Group_4700" opacity=".5">
          <path id="Path_4180" d="M436.9,39.1h153.9" fill="none" stroke="#40bac4"/>
          <line id="Line_64" x1="60.2" y1="39.1" x2="157" y2="39.1" fill="none" stroke="#2ec278" stroke-opacity="0"/>
          <line id="Line_59" x1="69.5" y1="39.1" x2="202.5" y2="39.1" fill="none" stroke="#2ec278"/>
          <path id="Path_666" d="M231.6,39.1h170.4" fill="none" stroke="#ff9557"/>
          <path id="Path_665" d="M38.5,0c21.3,0,38.5,17.2,38.5,38.5s-17.2,38.5-38.5,38.5S0,59.8,0,38.5,17.2,0,38.5,0Z" fill="#ffd5d5" fill-opacity="0"/>
          <circle id="Ellipse_82" cx="38.5" cy="38.5" r="30.5" fill="#2ec278"/>
          <g id="Group_552">
            <circle id="Ellipse_88" cx="233.5" cy="38.5" r="38.5" fill="#acffd6" fill-opacity="0"/>
            <path id="Path_638" d="M233.4,8.2c16.7,0,30.3,13.6,30.3,30.3s-13.6,30.3-30.3,30.3-30.3-13.6-30.3-30.3c0-16.7,13.6-30.3,30.3-30.3h0Z" fill="#ffc72e"/>
            <g id="inventory_2_black_24dp">
              <g id="Group_4910">
                <rect id="Rectangle_1309" x="221" y="26" width="26" height="26" fill="none"/>
              </g>
              <g id="Group_4911">
                <path id="Path_3926" d="M242.3,28.6h-16.6c-1.1,0-2,.9-2.1,2.1v3.1c0,.7.4,1.4,1,1.8v11.8c0,1.1,1,2,2.1,2.1h14.6c1.1,0,2-1,2.1-2.1v-11.8c.6-.4,1-1,1-1.8v-3.1c0-1.1-.9-2-2.1-2.1ZM237.1,41.1h-6.2v-2.1h6.2v2.1ZM242.3,33.8h-16.6v-3.1h16.6v3.1Z" fill="#fff"/>
              </g>
            </g>
          </g>
          <g id="Group_550">
            <circle id="Ellipse_89" cx="423.5" cy="38.5" r="38.5" fill="#ffeaaf" fill-opacity="0"/>
            <circle id="Ellipse_84" cx="423.5" cy="38.5" r="30.5" fill="#40bac4"/>
            <g id="local_shipping_black_24dp">
              <path id="Path_3927" d="M411,26h26v26h-26v-26Z" fill="none"/>
              <path id="Path_3928" d="M432.5,35h-3.2v-4h-14.9c-1.1,0-2.1.8-2.1,2v10.9h2.1c0,1.7,1.5,3,3.2,3h0c1.7,0,3.1-1.3,3.2-3,0,0,0,0,0,0h6.4c0,1.7,1.5,3,3.2,3,0,0,0,0,0,0,1.7,0,3.1-1.3,3.2-3,0,0,0,0,0,0h2.1v-4.9l-3.2-4ZM417.6,45.4c-.8,0-1.5-.6-1.6-1.4,0-.8.6-1.5,1.4-1.6,0,0,.1,0,.2,0,.8,0,1.5.6,1.6,1.4,0,.8-.6,1.5-1.4,1.6,0,0-.1,0-.2,0ZM432,36.5l2.1,2.5h-4.8v-2.5h2.7ZM430.4,45.4c-.8,0-1.4-.8-1.4-1.6s.8-1.4,1.6-1.4c.8,0,1.4.7,1.4,1.5,0,.9-.7,1.5-1.6,1.5,0,0,0,0,0,0Z" fill="#fff"/>
            </g>
          </g>
          <g id="verified-24px">
            <g id="Group_564">
              <rect id="Rectangle_235" x="25" y="26" width="28" height="28" fill="none"/>
            </g>
            <g id="Group_565">
              <path id="Path_623" d="M51.5,39.6l-2.8-3.3.4-4.3-4.2-1-2.2-3.7-4,1.7-4-1.7-2.2,3.7-4.2.9.4,4.3-2.9,3.3,2.8,3.3-.4,4.3,4.2,1,2.2,3.7,4-1.7,4,1.7,2.2-3.7,4.2-1-.4-4.3,2.9-3.3ZM36.4,45.2l-4.4-4.4,1.7-1.7,2.7,2.7,6.8-6.8,1.7,1.7-8.5,8.6Z" fill="#fff"/>
            </g>
          </g>
          <g>
            <path d="M11.8,90.1l2.9-8.6h1.5l2.9,8.6h-1.1l-2.7-8h.3l-2.7,8h-1.1ZM13.2,88.1v-1h4.5v1h-4.5Z" fill="#bfbfbf"/>
            <path d="M22.9,90.3c-.7,0-1.2-.1-1.7-.4s-.8-.7-1-1.2c-.2-.5-.4-1.1-.4-1.8,0-.7.1-1.3.4-1.8.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5c.5.3.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2,0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5Z" fill="#bfbfbf"/>
            <path d="M29.4,90.3c-.7,0-1.2-.1-1.7-.4s-.8-.7-1-1.2c-.2-.5-.4-1.1-.4-1.8,0-.7.1-1.3.4-1.8.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5c.5.3.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2,0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5Z" fill="#bfbfbf"/>
            <path d="M36,90.3c-.6,0-1.2-.1-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8-.3-.4-.8-.6-1.4-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.4.5,1.8c.3.4.8.6,1.5.6s.8,0,1.1-.3.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3-.5.3-1.1.5-1.7.5ZM33.6,87.1v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
            <path d="M40.1,93v-9.4h1v4.8h.1v4.6h-1.1ZM43.1,90.3c-.6,0-1.1-.2-1.5-.5-.4-.3-.7-.7-.9-1.2s-.3-1.1-.3-1.7.1-1.2.3-1.7.5-.9.9-1.2c.4-.3.9-.4,1.5-.4s1.1.1,1.6.4.8.7,1,1.2c.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.6.9-1,1.2-.4.3-1,.4-1.6.4ZM43,89.3c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.2,0-.9-.2-1.2c-.1-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8.1-1,.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.3s0,.9.2,1.3c.1.4.3.7.6.9.3.2.6.3,1.1.3Z" fill="#bfbfbf"/>
            <path d="M46.6,84.5v-.9h4.3v.9h-4.3ZM51,90.1c-.4,0-.8.1-1.1,0-.4,0-.7,0-1-.2s-.5-.4-.7-.7-.2-.5-.2-.8c0-.3,0-.6,0-.9v-5.8h1.1v5.8c0,.3,0,.5,0,.7,0,.2,0,.4.1.5.1.3.4.4.7.5s.7,0,1.1,0v.9Z" fill="#bfbfbf"/>
            <path d="M54.8,90.3c-.6,0-1.2-.1-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8-.3-.4-.8-.6-1.4-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.4.5,1.8c.3.4.8.6,1.5.6s.8,0,1.1-.3.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3-.5.3-1.1.5-1.7.5ZM52.4,87.1v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
            <path d="M61.6,90.3c-.6,0-1.1-.1-1.6-.4-.4-.3-.8-.7-1-1.2-.2-.5-.4-1.1-.4-1.7s.1-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1.1,1.5.4.7.7.9,1.2c.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.5.9-.9,1.2-.4.3-.9.5-1.5.5ZM61.8,89.3c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9.1-.4.2-.8.2-1.3s0-.9-.2-1.3c-.1-.4-.3-.7-.6-.9s-.6-.3-1-.3-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2s0,.9.2,1.2c.1.4.4.7.6.9.3.2.6.3,1.1.3ZM63.7,90.1v-4.8h-.1v-3.9h1.1v8.6h-1Z" fill="#bfbfbf"/>
          </g>
          <g>
            <path d="M404.2,90.3c-.6,0-1.2-.1-1.7-.3s-.9-.5-1.2-.9c-.3-.4-.5-.8-.6-1.4l1.1-.2c.1.5.4,1,.9,1.3.4.3,1,.5,1.6.5s.7,0,1-.2.6-.3.7-.5c.2-.2.3-.5.3-.8s0-.4-.1-.5c0-.1-.2-.3-.3-.4-.1,0-.2-.2-.4-.2-.1,0-.3-.1-.4-.2l-2.2-.7c-.2,0-.5-.2-.7-.3s-.4-.2-.6-.4c-.2-.2-.3-.4-.4-.6-.1-.2-.2-.5-.2-.8,0-.5.1-.9.4-1.3.3-.4.6-.6,1.1-.8.5-.2,1-.3,1.5-.3.6,0,1.1.1,1.6.3s.8.5,1.1.9.5.8.6,1.3l-1.1.2c0-.3-.2-.7-.4-.9s-.5-.5-.8-.6c-.3-.1-.6-.2-1-.2-.4,0-.7,0-1,.2s-.5.3-.7.5-.3.4-.3.7,0,.5.2.6c.2.2.3.3.6.4s.4.2.7.2l1.6.5c.2,0,.4.1.7.2s.5.2.7.4c.2.2.4.4.6.7.2.3.2.6.2,1s0,.8-.2,1.1-.4.6-.7.8c-.3.2-.6.4-1,.5-.4.1-.8.2-1.3.2Z" fill="#bfbfbf"/>
            <path d="M408.6,90.1v-8.6h1v4.8h.1v3.9h-1.1ZM413.3,90.1v-3.2c0-.3,0-.6,0-.9s-.1-.5-.3-.8c-.1-.2-.3-.4-.5-.5-.2-.1-.5-.2-.9-.2s-.5,0-.7.1c-.2,0-.4.2-.6.4s-.3.4-.4.7c0,.3-.1.6-.1,1l-.7-.2c0-.6.1-1.2.3-1.6.2-.5.5-.8,1-1s.9-.4,1.4-.4.8,0,1.1.2.5.3.7.5c.2.2.3.5.5.7.1.3.2.5.2.8,0,.3,0,.5,0,.8v3.6h-1.1Z" fill="#bfbfbf"/>
            <path d="M416,82.5v-1.1h1.1v1.1h-1.1ZM416,90.1v-6.5h1.1v6.5h-1.1Z" fill="#bfbfbf"/>
            <path d="M418.9,93v-9.4h1v4.8h.1v4.6h-1.1ZM421.9,90.3c-.6,0-1.1-.2-1.5-.5-.4-.3-.7-.7-.9-1.2s-.3-1.1-.3-1.7.1-1.2.3-1.7.5-.9.9-1.2c.4-.3.9-.4,1.5-.4s1.1.1,1.6.4c.4.3.8.7,1,1.2.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.6.9-1,1.2-.4.3-1,.4-1.6.4ZM421.7,89.3c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9.1-.4.2-.8.2-1.2s0-.9-.2-1.2c-.1-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8.1-1,.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.3s0,.9.2,1.3c.1.4.3.7.6.9.3.2.6.3,1.1.3Z" fill="#bfbfbf"/>
            <path d="M426.1,93v-9.4h1v4.8h.1v4.6h-1.1ZM429.1,90.3c-.6,0-1.1-.2-1.5-.5-.4-.3-.7-.7-.9-1.2s-.3-1.1-.3-1.7.1-1.2.3-1.7.5-.9.9-1.2.9-.4,1.5-.4,1.1.1,1.6.4c.4.3.8.7,1,1.2.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.6.9-1,1.2-.4.3-1,.4-1.6.4ZM428.9,89.3c.4,0,.8-.1,1.1-.3s.5-.5.6-.9c.1-.4.2-.8.2-1.2s0-.9-.2-1.2c-.1-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8.1-1,.3-.5.5-.6.9-.2.8-.2,1.3,0,.9.2,1.3c.1.4.3.7.6.9s.6.3,1.1.3Z" fill="#bfbfbf"/>
            <path d="M436.1,90.3c-.6,0-1.2-.1-1.7-.4-.5-.3-.8-.7-1.1-1.2-.3-.5-.4-1.1-.4-1.8s.1-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8-.3-.4-.8-.6-1.4-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.4.5,1.8c.3.4.8.6,1.5.6s.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3-.5.3-1.1.5-1.7.5ZM433.7,87.1v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
            <path d="M442.9,90.3c-.6,0-1.1-.1-1.6-.4-.4-.3-.8-.7-1-1.2-.2-.5-.4-1.1-.4-1.7s.1-1.2.4-1.7c.2-.5.6-.9,1-1.2.4-.3.9-.4,1.5-.4s1.1.1,1.5.4.7.7.9,1.2.3,1.1.3,1.7-.1,1.2-.3,1.7-.5.9-.9,1.2c-.4.3-.9.5-1.5.5ZM443.1,89.3c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9.1-.4.2-.8.2-1.3s0-.9-.2-1.3-.3-.7-.6-.9c-.3-.2-.6-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2s0,.9.2,1.2.4.7.6.9.6.3,1.1.3ZM445,90.1v-4.8h-.1v-3.9h1.1v8.6h-1Z" fill="#bfbfbf"/>
          </g>
          <g>
            <path d="M212.2,90.1v-8.6h3.4c0,0,.2,0,.3,0,.1,0,.2,0,.3,0,.5,0,.9.2,1.2.5.3.2.6.6.7.9.2.4.2.8.2,1.3s0,.9-.2,1.3c-.2.4-.4.7-.7.9s-.7.4-1.2.5c-.1,0-.2,0-.3,0-.1,0-.2,0-.3,0h-2.3v3.2h-1.1ZM213.3,85.9h2.3c0,0,.2,0,.3,0s.2,0,.3,0c.3,0,.5-.2.7-.4.2-.2.3-.4.4-.6s.1-.5.1-.7,0-.5-.1-.7c0-.2-.2-.4-.4-.6-.2-.2-.4-.3-.7-.4,0,0-.2,0-.3,0,0,0-.2,0-.3,0h-2.3v3.4Z" fill="#bfbfbf"/>
            <path d="M221.2,90.3c-.5,0-.9,0-1.2-.3-.3-.2-.6-.4-.7-.7s-.2-.6-.2-1,0-.6.2-.9.3-.5.5-.6c.2-.2.5-.3.8-.4.3,0,.7-.2,1-.2.4,0,.8-.1,1.2-.2.4,0,.8-.1,1.1-.2l-.4.2c0-.6-.1-1-.3-1.3-.2-.3-.7-.4-1.2-.4s-.7,0-1,.3c-.3.2-.5.5-.6.9l-1-.3c.2-.6.5-1,.9-1.3.4-.3,1-.5,1.8-.5s1.1.1,1.5.3c.4.2.7.5.9,1,0,.2.2.4.2.7,0,.2,0,.5,0,.7v4h-.9v-1.6h.2c-.2.7-.6,1.1-1,1.3-.4.3-1,.4-1.6.4ZM221.4,89.4c.4,0,.7,0,1-.2.3-.1.5-.3.7-.5.2-.2.3-.5.3-.7,0-.2,0-.4,0-.7,0-.2,0-.4,0-.6l.4.2c-.3,0-.7.1-1,.1s-.7,0-1,.1-.6.1-.8.2c-.2,0-.3.1-.5.2-.2,0-.3.2-.4.3,0,.1-.1.3-.1.5s0,.3.1.5c0,.2.2.3.4.4.2.1.4.2.7.2Z" fill="#bfbfbf"/>
            <path d="M228.9,90.3c-.7,0-1.2-.1-1.7-.4s-.8-.7-1-1.2c-.2-.5-.4-1.1-.4-1.8,0-.7.1-1.3.4-1.8.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5c.5.3.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2,0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5Z" fill="#bfbfbf"/>
            <path d="M232.9,90.1v-8.6h1.1v5.3l2.7-3.1h1.4l-2.9,3.2,3.2,3.2h-1.5l-2.9-3.1v3.1h-1.1Z" fill="#bfbfbf"/>
            <path d="M239.1,82.5v-1.1h1.1v1.1h-1.1ZM239.1,90.1v-6.5h1.1v6.5h-1.1Z" fill="#bfbfbf"/>
            <path d="M242,90.1v-6.5h1v1.7h.1v4.7h-1.1ZM246.6,90.1v-3.2c0-.3,0-.6,0-.9,0-.3-.1-.5-.3-.8-.1-.2-.3-.4-.5-.5-.2-.1-.5-.2-.9-.2s-.5,0-.7.1c-.2,0-.4.2-.6.4-.2.2-.3.4-.4.7,0,.3-.1.6-.1,1l-.7-.2c0-.6.1-1.2.3-1.6.2-.5.5-.8,1-1s.9-.4,1.4-.4.8,0,1.1.2.5.3.7.5c.2.2.4.5.5.7.1.3.2.5.2.8,0,.3,0,.5,0,.8v3.6h-1.1Z" fill="#bfbfbf"/>
            <path d="M251.8,90.3c-.6,0-1.1-.1-1.6-.4-.4-.3-.8-.7-1-1.2-.2-.5-.4-1.1-.4-1.7s.1-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1.1,1.5.4.7.7.9,1.2c.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.5.9-.9,1.2-.4.3-.9.5-1.5.5ZM251.9,93.1c-.3,0-.7,0-1-.2-.3-.1-.6-.3-.9-.5s-.5-.5-.7-.8l1-.5c.2.3.4.5.7.7.3.1.6.2.9.2s.8,0,1-.2c.3-.1.5-.4.6-.7.1-.3.2-.7.2-1.1v-1.8h.1v-4.7h.9v6.5c0,.2,0,.4,0,.5,0,.2,0,.3,0,.5,0,.5-.3.8-.5,1.1-.2.3-.6.5-1,.7-.4.1-.8.2-1.4.2ZM251.9,89.3c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9.1-.4.2-.8.2-1.3s0-.9-.2-1.3c-.1-.4-.3-.7-.6-.9s-.6-.3-1-.3-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2s0,.9.2,1.2c.1.4.4.7.6.9.3.2.6.3,1.1.3Z" fill="#bfbfbf"/>
          </g>
        </g>
        <line id="Line_60" x1="562.8" y1="39.1" x2="617.6" y2="39.1" fill="none" stroke="#d4d4d4" stroke-opacity="0"/>
        <g id="Group_551">
          <circle id="Ellipse_90" cx="620.5" cy="38.5" r="38.5" fill="#d1e2ff"/>
          <circle id="Ellipse_85" cx="620.5" cy="38.5" r="30.5" fill="#0a64f9"/>
        </g>
        <circle id="Ellipse_87" cx="582" cy="40" r="5" fill="#40bac4"/>
        <g id="thumbs_up">
          <g id="Group_555">
            <g id="Group_554">
              <path id="Path_618" d="M632.4,40.4c0-.9-.7-1.7-1.7-1.7.9,0,1.7-.7,1.7-1.7s-.7-1.7-1.7-1.7h-6.8c.7-1.9,1.1-3.9,1-5.9,0-1.4-1.1-2.5-2.5-2.5h-.5c-.4,0-.7.3-.8.6-.8,3.2-2.1,6.9-5.4,7.7v12.1l2.9,1c.9.3,1.7.4,2.6.4h7.8c.9,0,1.7-.7,1.7-1.7,0-.9-.7-1.7-1.7-1.7h0,1.7c.9,0,1.7-.7,1.7-1.7s-.7-1.7-1.7-1.7c.9,0,1.7-.8,1.7-1.7Z" fill="#fff"/>
            </g>
          </g>
          <g id="Group_557">
            <g id="Group_556">
              <path id="Path_619" d="M611.6,33.7h-3.3c-.5,0-.8.4-.8.8h0v15c0,.5.4.8.8.8h3.3c1.4,0,2.5-1.1,2.5-2.5v-11.7c0-1.4-1.1-2.5-2.5-2.5ZM611.6,47.1c-.5,0-.8-.4-.8-.8,0-.5.4-.8.8-.8s.8.4.8.8-.4.8-.8.8h0Z" fill="#fff"/>
            </g>
          </g>
          <g id="Group_559">
            <g id="Group_558">
              <path id="Path_620" d="M618.9,25.6l-1.2-1.2c-.3-.3-.8-.4-1.2,0-.3.3-.4.8,0,1.2,0,0,0,0,0,0l1.2,1.2c.3.3.9.3,1.2,0,.3-.3.3-.8,0-1.1Z" fill="#fff"/>
            </g>
          </g>
          <g id="Group_561">
            <g id="Group_560">
              <path id="Path_621" d="M628.3,24.4c-.3-.3-.9-.3-1.2,0h0s-1.2,1.2-1.2,1.2c-.3.3-.4.8,0,1.2s.8.4,1.2,0c0,0,0,0,0,0l1.2-1.2c.3-.3.3-.9,0-1.2,0,0,0,0,0,0Z" fill="#fff"/>
            </g>
          </g>
          <g id="Group_563">
            <g id="Group_562">
              <path id="Path_622" d="M622.4,21.9c-.5,0-.8.4-.8.8,0,0,0,0,0,0v1.7c0,.5.4.8.9.8.4,0,.8-.4.8-.8v-1.7c0-.5-.4-.8-.8-.8,0,0,0,0,0,0Z" fill="#fff"/>
            </g>
          </g>
        </g>
        <g>
          <path d="M589.8,98.6v-10.1h3.2c0,0,.3,0,.5,0,.3,0,.5,0,.7,0,.8,0,1.5.4,2,.8.5.5.9,1.1,1.2,1.8.3.7.4,1.5.4,2.4s-.1,1.6-.4,2.4c-.3.7-.7,1.3-1.2,1.8-.5.5-1.2.7-2,.8-.2,0-.5,0-.7,0-.3,0-.4,0-.5,0h-3.2ZM591.3,97.2h1.7c.2,0,.3,0,.6,0s.4,0,.6,0c.5,0,.9-.3,1.3-.7.3-.3.6-.8.7-1.3.1-.5.2-1.1.2-1.6s0-1.1-.2-1.7-.4-.9-.7-1.3c-.3-.3-.7-.6-1.2-.7-.2,0-.4,0-.6,0-.2,0-.4,0-.6,0h-1.7v7.3Z"/>
          <path d="M602.9,98.8c-.8,0-1.4-.2-2-.5-.6-.3-1-.8-1.3-1.4-.3-.6-.5-1.3-.5-2.1s.2-1.5.5-2.2c.3-.6.7-1.1,1.3-1.4.6-.3,1.2-.5,1.9-.5s1.4.2,2,.5.9.9,1.2,1.5c.3.7.4,1.4.3,2.3h-1.5v-.5c0-.9-.2-1.5-.5-1.9-.3-.4-.8-.6-1.5-.6s-1.3.2-1.7.7c-.4.5-.6,1.1-.6,2s.2,1.5.6,1.9c.4.5.9.7,1.6.7s.9-.1,1.2-.3c.3-.2.6-.5.8-.9l1.4.5c-.3.7-.7,1.2-1.3,1.6-.6.4-1.3.6-2,.6ZM600.2,95.2v-1.1h5.4v1.1h-5.4Z"/>
          <path d="M608,98.6v-10.3h1.5v10.3h-1.5Z"/>
          <path d="M611.7,89.8v-1.4h1.5v1.4h-1.5ZM611.7,98.6v-7.6h1.5v7.6h-1.5Z"/>
          <path d="M617.4,98.6l-2.7-7.6h1.5l2,5.7,2-5.7h1.5l-2.7,7.6h-1.5Z"/>
          <path d="M625.9,98.8c-.8,0-1.4-.2-2-.5-.6-.3-1-.8-1.3-1.4-.3-.6-.5-1.3-.5-2.1s.2-1.5.5-2.2c.3-.6.7-1.1,1.3-1.4.6-.3,1.2-.5,1.9-.5s1.4.2,2,.5.9.9,1.2,1.5c.3.7.4,1.4.3,2.3h-1.5v-.5c0-.9-.2-1.5-.5-1.9-.3-.4-.8-.6-1.5-.6s-1.3.2-1.7.7c-.4.5-.6,1.1-.6,2s.2,1.5.6,1.9c.4.5.9.7,1.6.7s.9-.1,1.2-.3c.3-.2.6-.5.8-.9l1.4.5c-.3.7-.7,1.2-1.3,1.6-.6.4-1.3.6-2,.6ZM623.2,95.2v-1.1h5.4v1.1h-5.4Z"/>
          <path d="M630.9,98.6v-7.6h1.3v1.8l-.2-.2c0-.2.2-.5.4-.7.2-.2.3-.4.5-.5.2-.1.4-.2.6-.3.2,0,.5-.1.7-.1.2,0,.5,0,.7,0v1.4c-.2,0-.5,0-.8,0-.3,0-.6.1-.8.3-.2.2-.4.3-.6.6-.1.2-.2.5-.3.7,0,.3,0,.6,0,.9v3.8h-1.5Z"/>
          <path d="M639.6,98.8c-.8,0-1.4-.2-2-.5-.6-.3-1-.8-1.3-1.4-.3-.6-.5-1.3-.5-2.1s.2-1.5.5-2.2c.3-.6.7-1.1,1.3-1.4.6-.3,1.2-.5,1.9-.5s1.4.2,2,.5.9.9,1.2,1.5c.3.7.4,1.4.3,2.3h-1.5v-.5c0-.9-.2-1.5-.5-1.9-.3-.4-.8-.6-1.5-.6s-1.3.2-1.7.7c-.4.5-.6,1.1-.6,2s.2,1.5.6,1.9c.4.5.9.7,1.6.7s.9-.1,1.2-.3c.3-.2.6-.5.8-.9l1.4.5c-.3.7-.7,1.2-1.3,1.6-.6.4-1.3.6-2,.6ZM636.9,95.2v-1.1h5.4v1.1h-5.4Z"/>
          <path d="M647.6,98.8c-.7,0-1.3-.2-1.8-.5s-.9-.8-1.2-1.4c-.3-.6-.4-1.3-.4-2s.1-1.4.4-2c.3-.6.7-1.1,1.2-1.4.5-.3,1.1-.5,1.8-.5s1.3.2,1.8.5c.5.3.9.8,1.1,1.4.2.6.4,1.3.4,2s-.1,1.4-.4,2c-.3.6-.6,1.1-1.1,1.4-.5.4-1.1.5-1.8.5ZM647.8,97.5c.5,0,.9-.1,1.2-.3.3-.2.5-.5.7-.9.1-.4.2-.9.2-1.4s0-1-.2-1.4c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1.1-.3s-.9.1-1.2.4-.5.6-.7,1c-.2.4-.2.9-.2,1.3s0,1,.2,1.4c.2.4.4.7.7,1,.3.2.7.4,1.2.4ZM649.8,98.6v-5.4h-.2v-4.6h1.5v10.1h-1.3Z"/>
        </g>
      </g>
    </svg>
  );

  const deliveryCanceled = (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 661.1 103.1">

      <g id="p_s_canelled">
        <g id="Group_4698" opacity=".6">
          <line id="Line_65" x1="436.9" y1="39.1" x2="590.8" y2="39.1" fill="none" stroke="#bfbfbf"/>
          <line id="Line_64" x1="60.2" y1="39.1" x2="157" y2="39.1" fill="none" stroke="#2ec278" stroke-opacity="0"/>
          <line id="Line_59" x1="69.5" y1="39.1" x2="202.5" y2="39.1" fill="none" stroke="#bfbfbf"/>
          <path id="Path_666" d="M231.6,39.1h170.4" fill="none" stroke="#bfbfbf"/>
          <path id="Path_665" d="M38.5,0c21.3,0,38.5,17.2,38.5,38.5s-17.2,38.5-38.5,38.5S0,59.8,0,38.5,17.2,0,38.5,0Z" fill="#ffd5d5" fill-opacity="0"/>
          <circle id="Ellipse_82" cx="38.5" cy="38.5" r="30.5" fill="#2ec278"/>
          <g id="Group_552">
            <circle id="Ellipse_88" cx="233.5" cy="38.5" r="38.5" fill="#acffd6" fill-opacity="0"/>
            <path id="Path_638" d="M233.4,8.2c16.7,0,30.3,13.6,30.3,30.3s-13.6,30.3-30.3,30.3-30.3-13.6-30.3-30.3c0-16.7,13.6-30.3,30.3-30.3h0Z" fill="#f1f1f1"/>
          </g>
          <g id="Group_550">
            <circle id="Ellipse_89" cx="423.5" cy="38.5" r="38.5" fill="#ffeaaf" fill-opacity="0"/>
            <circle id="Ellipse_84" cx="423.5" cy="38.5" r="30.5" fill="#f1f1f1"/>
          </g>
          <g id="verified-24px">
            <g id="Group_564">
              <rect id="Rectangle_235" x="25" y="26" width="28" height="28" fill="none"/>
            </g>
            <g id="Group_565">
              <path id="Path_623" d="M51.5,39.6l-2.8-3.3.4-4.3-4.2-1-2.2-3.7-4,1.7-4-1.7-2.2,3.7-4.2.9.4,4.3-2.9,3.3,2.8,3.3-.4,4.3,4.2,1,2.2,3.7,4-1.7,4,1.7,2.2-3.7,4.2-1-.4-4.3,2.9-3.3ZM36.4,45.2l-4.4-4.4,1.7-1.7,2.7,2.7,6.8-6.8,1.7,1.7-8.5,8.6Z" fill="#fff"/>
            </g>
          </g>
          <g id="local_shipping_black_24dp">
            <path id="Path_3927" d="M412,27h26v26h-26v-26Z" fill="none"/>
            <path id="Path_3928" d="M433.5,36h-3.2v-4h-14.9c-1.1,0-2.1.8-2.1,2v10.9h2.1c0,1.7,1.5,3,3.2,3h0c1.7,0,3.1-1.3,3.2-3,0,0,0,0,0,0h6.4c0,1.7,1.5,3,3.2,3,0,0,0,0,0,0,1.7,0,3.1-1.3,3.2-3,0,0,0,0,0,0h2.1v-4.9l-3.2-4ZM418.6,46.4c-.8,0-1.5-.6-1.6-1.4,0-.8.6-1.5,1.4-1.6,0,0,.1,0,.2,0,.8,0,1.5.6,1.6,1.4,0,.8-.6,1.5-1.4,1.6,0,0-.1,0-.2,0ZM433,37.5l2.1,2.5h-4.8v-2.5h2.7ZM431.4,46.4c-.8,0-1.4-.8-1.4-1.6s.8-1.4,1.6-1.4c.8,0,1.4.7,1.4,1.5,0,.9-.7,1.5-1.6,1.5,0,0,0,0,0,0Z" fill="#d9d9d9"/>
          </g>
          <g id="inventory_2_black_24dp">
            <g id="Group_4910">
              <rect id="Rectangle_1309" x="221" y="27" width="26" height="26" fill="none"/>
            </g>
            <g id="Group_4911">
              <path id="Path_3926" d="M242.3,29.6h-16.6c-1.1,0-2,.9-2.1,2.1v3.1c0,.7.4,1.4,1,1.8v11.8c0,1.1,1,2,2.1,2.1h14.6c1.1,0,2-1,2.1-2.1v-11.8c.6-.4,1-1,1-1.8v-3.1c0-1.1-.9-2-2.1-2.1ZM237.1,42.1h-6.2v-2.1h6.2v2.1ZM242.3,34.8h-16.6v-3.1h16.6v3.1Z" fill="#ddd"/>
            </g>
          </g>
          <g>
            <path d="M11.8,90.1l2.9-8.6h1.5l2.9,8.6h-1.1l-2.7-8h.3l-2.7,8h-1.1ZM13.2,88.1v-1h4.5v1h-4.5Z" fill="#bfbfbf"/>
            <path d="M22.9,90.3c-.7,0-1.2-.1-1.7-.4s-.8-.7-1-1.2c-.2-.5-.4-1.1-.4-1.8,0-.7.1-1.3.4-1.8.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5c.5.3.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2,0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5Z" fill="#bfbfbf"/>
            <path d="M29.4,90.3c-.7,0-1.2-.1-1.7-.4s-.8-.7-1-1.2c-.2-.5-.4-1.1-.4-1.8,0-.7.1-1.3.4-1.8.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5c.5.3.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2,0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5Z" fill="#bfbfbf"/>
            <path d="M36,90.3c-.6,0-1.2-.1-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8-.3-.4-.8-.6-1.4-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.4.5,1.8c.3.4.8.6,1.5.6s.8,0,1.1-.3.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3-.5.3-1.1.5-1.7.5ZM33.6,87.1v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
            <path d="M40.1,93v-9.4h1v4.8h.1v4.6h-1.1ZM43.1,90.3c-.6,0-1.1-.2-1.5-.5-.4-.3-.7-.7-.9-1.2s-.3-1.1-.3-1.7.1-1.2.3-1.7.5-.9.9-1.2c.4-.3.9-.4,1.5-.4s1.1.1,1.6.4.8.7,1,1.2c.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.6.9-1,1.2-.4.3-1,.4-1.6.4ZM43,89.3c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.2,0-.9-.2-1.2c-.1-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8.1-1,.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.3s0,.9.2,1.3c.1.4.3.7.6.9.3.2.6.3,1.1.3Z" fill="#bfbfbf"/>
            <path d="M46.6,84.5v-.9h4.3v.9h-4.3ZM51,90.1c-.4,0-.8.1-1.1,0-.4,0-.7,0-1-.2s-.5-.4-.7-.7-.2-.5-.2-.8c0-.3,0-.6,0-.9v-5.8h1.1v5.8c0,.3,0,.5,0,.7,0,.2,0,.4.1.5.1.3.4.4.7.5s.7,0,1.1,0v.9Z" fill="#bfbfbf"/>
            <path d="M54.8,90.3c-.6,0-1.2-.1-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8-.3-.4-.8-.6-1.4-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.4.5,1.8c.3.4.8.6,1.5.6s.8,0,1.1-.3.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3-.5.3-1.1.5-1.7.5ZM52.4,87.1v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
            <path d="M61.6,90.3c-.6,0-1.1-.1-1.6-.4-.4-.3-.8-.7-1-1.2-.2-.5-.4-1.1-.4-1.7s.1-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1.1,1.5.4.7.7.9,1.2c.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.5.9-.9,1.2-.4.3-.9.5-1.5.5ZM61.8,89.3c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9.1-.4.2-.8.2-1.3s0-.9-.2-1.3c-.1-.4-.3-.7-.6-.9s-.6-.3-1-.3-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2s0,.9.2,1.2c.1.4.4.7.6.9.3.2.6.3,1.1.3ZM63.7,90.1v-4.8h-.1v-3.9h1.1v8.6h-1Z" fill="#bfbfbf"/>
          </g>
          <g>
            <path d="M404.2,90.3c-.6,0-1.2-.1-1.7-.3s-.9-.5-1.2-.9c-.3-.4-.5-.8-.6-1.4l1.1-.2c.1.5.4,1,.9,1.3.4.3,1,.5,1.6.5s.7,0,1-.2.6-.3.7-.5c.2-.2.3-.5.3-.8s0-.4-.1-.5c0-.1-.2-.3-.3-.4-.1,0-.2-.2-.4-.2-.1,0-.3-.1-.4-.2l-2.2-.7c-.2,0-.5-.2-.7-.3s-.4-.2-.6-.4c-.2-.2-.3-.4-.4-.6-.1-.2-.2-.5-.2-.8,0-.5.1-.9.4-1.3.3-.4.6-.6,1.1-.8.5-.2,1-.3,1.5-.3.6,0,1.1.1,1.6.3s.8.5,1.1.9.5.8.6,1.3l-1.1.2c0-.3-.2-.7-.4-.9s-.5-.5-.8-.6c-.3-.1-.6-.2-1-.2-.4,0-.7,0-1,.2s-.5.3-.7.5-.3.4-.3.7,0,.5.2.6c.2.2.3.3.6.4s.4.2.7.2l1.6.5c.2,0,.4.1.7.2s.5.2.7.4c.2.2.4.4.6.7.2.3.2.6.2,1s0,.8-.2,1.1-.4.6-.7.8c-.3.2-.6.4-1,.5-.4.1-.8.2-1.3.2Z" fill="#bfbfbf"/>
            <path d="M408.6,90.1v-8.6h1v4.8h.1v3.9h-1.1ZM413.3,90.1v-3.2c0-.3,0-.6,0-.9s-.1-.5-.3-.8c-.1-.2-.3-.4-.5-.5-.2-.1-.5-.2-.9-.2s-.5,0-.7.1c-.2,0-.4.2-.6.4s-.3.4-.4.7c0,.3-.1.6-.1,1l-.7-.2c0-.6.1-1.2.3-1.6.2-.5.5-.8,1-1s.9-.4,1.4-.4.8,0,1.1.2.5.3.7.5c.2.2.3.5.5.7.1.3.2.5.2.8,0,.3,0,.5,0,.8v3.6h-1.1Z" fill="#bfbfbf"/>
            <path d="M416,82.5v-1.1h1.1v1.1h-1.1ZM416,90.1v-6.5h1.1v6.5h-1.1Z" fill="#bfbfbf"/>
            <path d="M418.9,93v-9.4h1v4.8h.1v4.6h-1.1ZM421.9,90.3c-.6,0-1.1-.2-1.5-.5-.4-.3-.7-.7-.9-1.2s-.3-1.1-.3-1.7.1-1.2.3-1.7.5-.9.9-1.2c.4-.3.9-.4,1.5-.4s1.1.1,1.6.4c.4.3.8.7,1,1.2.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.6.9-1,1.2-.4.3-1,.4-1.6.4ZM421.7,89.3c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9.1-.4.2-.8.2-1.2s0-.9-.2-1.2c-.1-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8.1-1,.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.3s0,.9.2,1.3c.1.4.3.7.6.9.3.2.6.3,1.1.3Z" fill="#bfbfbf"/>
            <path d="M426.1,93v-9.4h1v4.8h.1v4.6h-1.1ZM429.1,90.3c-.6,0-1.1-.2-1.5-.5-.4-.3-.7-.7-.9-1.2s-.3-1.1-.3-1.7.1-1.2.3-1.7.5-.9.9-1.2.9-.4,1.5-.4,1.1.1,1.6.4c.4.3.8.7,1,1.2.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.6.9-1,1.2-.4.3-1,.4-1.6.4ZM428.9,89.3c.4,0,.8-.1,1.1-.3s.5-.5.6-.9c.1-.4.2-.8.2-1.2s0-.9-.2-1.2c-.1-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8.1-1,.3-.5.5-.6.9-.2.8-.2,1.3,0,.9.2,1.3c.1.4.3.7.6.9s.6.3,1.1.3Z" fill="#bfbfbf"/>
            <path d="M436.1,90.3c-.6,0-1.2-.1-1.7-.4-.5-.3-.8-.7-1.1-1.2-.3-.5-.4-1.1-.4-1.8s.1-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8-.3-.4-.8-.6-1.4-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.4.5,1.8c.3.4.8.6,1.5.6s.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3-.5.3-1.1.5-1.7.5ZM433.7,87.1v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
            <path d="M442.9,90.3c-.6,0-1.1-.1-1.6-.4-.4-.3-.8-.7-1-1.2-.2-.5-.4-1.1-.4-1.7s.1-1.2.4-1.7c.2-.5.6-.9,1-1.2.4-.3.9-.4,1.5-.4s1.1.1,1.5.4.7.7.9,1.2.3,1.1.3,1.7-.1,1.2-.3,1.7-.5.9-.9,1.2c-.4.3-.9.5-1.5.5ZM443.1,89.3c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9.1-.4.2-.8.2-1.3s0-.9-.2-1.3-.3-.7-.6-.9c-.3-.2-.6-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2s0,.9.2,1.2.4.7.6.9.6.3,1.1.3ZM445,90.1v-4.8h-.1v-3.9h1.1v8.6h-1Z" fill="#bfbfbf"/>
          </g>
          <g>
            <path d="M212.2,90.1v-8.6h3.4c0,0,.2,0,.3,0,.1,0,.2,0,.3,0,.5,0,.9.2,1.2.5.3.2.6.6.7.9.2.4.2.8.2,1.3s0,.9-.2,1.3c-.2.4-.4.7-.7.9s-.7.4-1.2.5c-.1,0-.2,0-.3,0-.1,0-.2,0-.3,0h-2.3v3.2h-1.1ZM213.3,85.9h2.3c0,0,.2,0,.3,0s.2,0,.3,0c.3,0,.5-.2.7-.4.2-.2.3-.4.4-.6s.1-.5.1-.7,0-.5-.1-.7c0-.2-.2-.4-.4-.6-.2-.2-.4-.3-.7-.4,0,0-.2,0-.3,0,0,0-.2,0-.3,0h-2.3v3.4Z" fill="#bfbfbf"/>
            <path d="M221.2,90.3c-.5,0-.9,0-1.2-.3-.3-.2-.6-.4-.7-.7s-.2-.6-.2-1,0-.6.2-.9.3-.5.5-.6c.2-.2.5-.3.8-.4.3,0,.7-.2,1-.2.4,0,.8-.1,1.2-.2.4,0,.8-.1,1.1-.2l-.4.2c0-.6-.1-1-.3-1.3-.2-.3-.7-.4-1.2-.4s-.7,0-1,.3c-.3.2-.5.5-.6.9l-1-.3c.2-.6.5-1,.9-1.3.4-.3,1-.5,1.8-.5s1.1.1,1.5.3c.4.2.7.5.9,1,0,.2.2.4.2.7,0,.2,0,.5,0,.7v4h-.9v-1.6h.2c-.2.7-.6,1.1-1,1.3-.4.3-1,.4-1.6.4ZM221.4,89.4c.4,0,.7,0,1-.2.3-.1.5-.3.7-.5.2-.2.3-.5.3-.7,0-.2,0-.4,0-.7,0-.2,0-.4,0-.6l.4.2c-.3,0-.7.1-1,.1s-.7,0-1,.1-.6.1-.8.2c-.2,0-.3.1-.5.2-.2,0-.3.2-.4.3,0,.1-.1.3-.1.5s0,.3.1.5c0,.2.2.3.4.4.2.1.4.2.7.2Z" fill="#bfbfbf"/>
            <path d="M228.9,90.3c-.7,0-1.2-.1-1.7-.4s-.8-.7-1-1.2c-.2-.5-.4-1.1-.4-1.8,0-.7.1-1.3.4-1.8.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5c.5.3.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2,0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5Z" fill="#bfbfbf"/>
            <path d="M232.9,90.1v-8.6h1.1v5.3l2.7-3.1h1.4l-2.9,3.2,3.2,3.2h-1.5l-2.9-3.1v3.1h-1.1Z" fill="#bfbfbf"/>
            <path d="M239.1,82.5v-1.1h1.1v1.1h-1.1ZM239.1,90.1v-6.5h1.1v6.5h-1.1Z" fill="#bfbfbf"/>
            <path d="M242,90.1v-6.5h1v1.7h.1v4.7h-1.1ZM246.6,90.1v-3.2c0-.3,0-.6,0-.9,0-.3-.1-.5-.3-.8-.1-.2-.3-.4-.5-.5-.2-.1-.5-.2-.9-.2s-.5,0-.7.1c-.2,0-.4.2-.6.4-.2.2-.3.4-.4.7,0,.3-.1.6-.1,1l-.7-.2c0-.6.1-1.2.3-1.6.2-.5.5-.8,1-1s.9-.4,1.4-.4.8,0,1.1.2.5.3.7.5c.2.2.4.5.5.7.1.3.2.5.2.8,0,.3,0,.5,0,.8v3.6h-1.1Z" fill="#bfbfbf"/>
            <path d="M251.8,90.3c-.6,0-1.1-.1-1.6-.4-.4-.3-.8-.7-1-1.2-.2-.5-.4-1.1-.4-1.7s.1-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1.1,1.5.4.7.7.9,1.2c.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.5.9-.9,1.2-.4.3-.9.5-1.5.5ZM251.9,93.1c-.3,0-.7,0-1-.2-.3-.1-.6-.3-.9-.5s-.5-.5-.7-.8l1-.5c.2.3.4.5.7.7.3.1.6.2.9.2s.8,0,1-.2c.3-.1.5-.4.6-.7.1-.3.2-.7.2-1.1v-1.8h.1v-4.7h.9v6.5c0,.2,0,.4,0,.5,0,.2,0,.3,0,.5,0,.5-.3.8-.5,1.1-.2.3-.6.5-1,.7-.4.1-.8.2-1.4.2ZM251.9,89.3c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9.1-.4.2-.8.2-1.3s0-.9-.2-1.3c-.1-.4-.3-.7-.6-.9s-.6-.3-1-.3-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2s0,.9.2,1.2c.1.4.4.7.6.9.3.2.6.3,1.1.3Z" fill="#bfbfbf"/>
          </g>
        </g>
        <line id="Line_60" x1="562.8" y1="39.1" x2="617.6" y2="39.1" fill="none" stroke="#d4d4d4" stroke-opacity="0"/>
        <g id="Group_551">
          <circle id="Ellipse_90" cx="620.5" cy="38.5" r="38.5" fill="#ffd5d5"/>
          <circle id="Ellipse_85" cx="620.5" cy="38.5" r="30.5" fill="#f55353"/>
        </g>
        <circle id="Ellipse_87" cx="582" cy="40" r="5" fill="#bfbfbf"/>
        <g id="Group_4697">
          <path id="Path_3791" d="M612.7,30l16.6,16.6" fill="none" stroke="#fff"/>
          <path id="Path_3792" d="M629.3,30l-16.6,16.6" fill="none" stroke="#fff"/>
        </g>
        <g>
            <path d="M592.1,98.8c-1,0-1.8-.2-2.5-.7-.7-.4-1.2-1-1.6-1.8-.4-.8-.6-1.7-.6-2.7s.2-1.9.6-2.7c.4-.8.9-1.4,1.6-1.8.7-.4,1.6-.7,2.5-.7s2.1.3,2.9.9,1.3,1.3,1.6,2.3l-1.7.5c-.2-.6-.5-1.2-1-1.5-.5-.4-1.1-.5-1.8-.5s-1.2.1-1.7.4c-.4.3-.8.7-1,1.3s-.3,1.2-.3,1.9c0,.7.1,1.4.3,1.9s.6,1,1,1.3c.4.3,1,.4,1.7.4s1.3-.2,1.8-.5.8-.9,1-1.5l1.7.5c-.3,1-.8,1.7-1.6,2.3s-1.7.9-2.9.9Z"/>
            <path d="M600.2,98.8c-.5,0-1-.1-1.4-.3-.4-.2-.7-.5-.8-.8-.2-.3-.3-.7-.3-1.1s0-.7.2-1c.1-.3.3-.5.6-.7s.6-.4,1-.5c.3,0,.6-.2,1-.3.4,0,.8-.2,1.3-.2.5,0,1-.1,1.5-.2l-.6.3c0-.5-.1-.9-.3-1.1-.2-.2-.6-.4-1.2-.4s-.7,0-1,.2c-.3.2-.5.4-.7.8l-1.5-.5c.2-.6.5-1.1,1-1.5.5-.4,1.2-.6,2.1-.6s1.2.1,1.7.3c.5.2.9.6,1.1,1.1.1.3.2.5.2.8,0,.3,0,.6,0,.9v4.6h-1.5v-1.6l.2.3c-.3.5-.7.9-1.2,1.2s-1,.4-1.7.4ZM600.5,97.5c.4,0,.7,0,1-.2.3-.1.5-.3.6-.5.2-.2.3-.4.3-.5,0-.2.1-.5.1-.7,0-.3,0-.5,0-.7l.5.2c-.5,0-.9.1-1.3.2-.4,0-.7.1-.9.2-.3,0-.5.1-.7.2-.2,0-.4.1-.5.2s-.2.2-.3.3c0,.1-.1.3-.1.4s0,.3.1.5c0,.1.2.3.4.3.2,0,.4.1.7.1Z"/>
            <path d="M606,98.6v-7.4h1.5v2.3h.2v5.1h-1.7ZM611.2,98.6v-3.6c0-.2,0-.5,0-.8,0-.3-.1-.6-.2-.8-.1-.3-.3-.5-.5-.7s-.5-.3-.9-.3-.4,0-.6.1c-.2,0-.4.2-.6.4-.2.2-.3.4-.4.7-.1.3-.2.7-.2,1.1l-1-.4c0-.6.1-1.2.4-1.7.2-.5.6-.9,1.1-1.2.5-.3,1.1-.5,1.8-.5s1,0,1.4.3c.4.2.6.4.9.7s.4.6.5.9c.1.3.2.6.2.9,0,.3,0,.5,0,.7v4.2h-1.7Z"/>
            <path d="M617.7,98.8c-.8,0-1.4-.2-2-.5-.5-.3-1-.8-1.2-1.4-.3-.6-.4-1.3-.4-2,0-.8.2-1.4.5-2,.3-.6.7-1.1,1.3-1.4.5-.3,1.2-.5,1.9-.5s1.6.2,2.1.6c.6.4,1,1,1.1,1.7l-1.7.4c-.1-.4-.3-.7-.6-.9-.3-.2-.6-.3-1-.3s-.8.1-1.1.3-.5.5-.6.8c-.1.4-.2.8-.2,1.2,0,.7.2,1.3.5,1.7.3.4.8.7,1.4.7s.8-.1,1-.3c.3-.2.4-.5.6-.9l1.7.4c-.2.8-.6,1.3-1.2,1.7-.6.4-1.3.6-2.1.6Z"/>
            <path d="M625.6,98.8c-.8,0-1.4-.2-2-.5-.6-.3-1-.8-1.3-1.4-.3-.6-.5-1.2-.5-2s.2-1.5.5-2.1c.3-.6.7-1.1,1.3-1.4.6-.3,1.2-.5,1.9-.5s1.4.2,2,.5c.5.4.9.9,1.2,1.5s.4,1.4.3,2.3h-1.6v-.6c0-.8-.1-1.4-.4-1.8-.3-.4-.7-.6-1.3-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.3.5,1.7c.3.4.8.6,1.4.6s.8,0,1.1-.3c.3-.2.5-.4.7-.8l1.6.5c-.3.7-.7,1.2-1.3,1.6-.6.4-1.2.6-2,.6ZM623,95.4v-1.3h5.1v1.3h-5.1Z"/>
            <path d="M630.6,98.6v-10.1h1.7v10.1h-1.7Z"/>
            <path d="M634.5,98.6v-10.1h1.7v10.1h-1.7Z"/>
            <path d="M641.6,98.8c-.8,0-1.4-.2-2-.5-.6-.3-1-.8-1.3-1.4-.3-.6-.5-1.2-.5-2s.2-1.5.5-2.1c.3-.6.7-1.1,1.3-1.4.6-.3,1.2-.5,1.9-.5s1.4.2,2,.5c.5.4.9.9,1.2,1.5s.4,1.4.3,2.3h-1.6v-.6c0-.8-.1-1.4-.4-1.8-.3-.4-.7-.6-1.3-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.3.5,1.7c.3.4.8.6,1.4.6s.8,0,1.1-.3c.3-.2.5-.4.7-.8l1.6.5c-.3.7-.7,1.2-1.3,1.6-.6.4-1.2.6-2,.6ZM639,95.4v-1.3h5.1v1.3h-5.1Z"/>
            <path d="M649.5,98.8c-.7,0-1.3-.2-1.8-.5-.5-.3-.9-.8-1.2-1.4-.3-.6-.4-1.3-.4-2s.1-1.4.4-2,.7-1.1,1.2-1.4,1.1-.5,1.8-.5,1.3.2,1.8.5.8.8,1.1,1.4c.2.6.4,1.3.4,2s-.1,1.4-.4,2c-.2.6-.6,1.1-1.1,1.4-.5.3-1.1.5-1.8.5ZM649.8,97.4c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.3,0-.9-.2-1.3c-.1-.4-.3-.6-.6-.9-.3-.2-.6-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2s0,.9.2,1.2c.1.4.3.7.6.9s.6.3,1.1.3ZM651.6,98.6v-5.2h-.2v-4.7h1.7v9.9h-1.5Z"/>
        </g>
      </g>
    </svg>
  );

  const deliveryRefunded = (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 659 103.1">

      <g id="d_s_refund">
        <g id="Group_4698" opacity=".6">
          <line id="Line_65" x1="436.9" y1="39.1" x2="590.8" y2="39.1" fill="none" stroke="#bfbfbf"/>
          <line id="Line_64" x1="60.2" y1="39.1" x2="157" y2="39.1" fill="none" stroke="#2ec278" stroke-opacity="0"/>
          <line id="Line_59" x1="69.5" y1="39.1" x2="202.5" y2="39.1" fill="none" stroke="#bfbfbf"/>
          <path id="Path_666" d="M231.6,39.1h170.4" fill="none" stroke="#bfbfbf"/>
          <path id="Path_665" d="M38.5,0c21.3,0,38.5,17.2,38.5,38.5s-17.2,38.5-38.5,38.5S0,59.8,0,38.5,17.2,0,38.5,0Z" fill="#ffd5d5" fill-opacity="0"/>
          <circle id="Ellipse_82" cx="38.5" cy="38.5" r="30.5" fill="#2ec278"/>
          <g id="Group_552">
            <circle id="Ellipse_88" cx="233.5" cy="38.5" r="38.5" fill="#acffd6" fill-opacity="0"/>
            <path id="Path_638" d="M233.4,8.2c16.7,0,30.3,13.6,30.3,30.3s-13.6,30.3-30.3,30.3-30.3-13.6-30.3-30.3c0-16.7,13.6-30.3,30.3-30.3h0Z" fill="#f1f1f1"/>
          </g>
          <g id="Group_550">
            <circle id="Ellipse_89" cx="423.5" cy="38.5" r="38.5" fill="#ffeaaf" fill-opacity="0"/>
            <circle id="Ellipse_84" cx="423.5" cy="38.5" r="30.5" fill="#f1f1f1"/>
          </g>
          <g id="verified-24px">
            <g id="Group_564">
              <rect id="Rectangle_235" x="25" y="26" width="28" height="28" fill="none"/>
            </g>
            <g id="Group_565">
              <path id="Path_623" d="M51.5,39.6l-2.8-3.3.4-4.3-4.2-1-2.2-3.7-4,1.7-4-1.7-2.2,3.7-4.2.9.4,4.3-2.9,3.3,2.8,3.3-.4,4.3,4.2,1,2.2,3.7,4-1.7,4,1.7,2.2-3.7,4.2-1-.4-4.3,2.9-3.3ZM36.4,45.2l-4.4-4.4,1.7-1.7,2.7,2.7,6.8-6.8,1.7,1.7-8.5,8.6Z" fill="#fff"/>
            </g>
          </g>
          <g id="local_shipping_black_24dp">
            <path id="Path_3927" d="M412,27h26v26h-26v-26Z" fill="none"/>
            <path id="Path_3928" d="M433.5,36h-3.2v-4h-14.9c-1.1,0-2.1.8-2.1,2v10.9h2.1c0,1.7,1.5,3,3.2,3h0c1.7,0,3.1-1.3,3.2-3,0,0,0,0,0,0h6.4c0,1.7,1.5,3,3.2,3,0,0,0,0,0,0,1.7,0,3.1-1.3,3.2-3,0,0,0,0,0,0h2.1v-4.9l-3.2-4ZM418.6,46.4c-.8,0-1.5-.6-1.6-1.4,0-.8.6-1.5,1.4-1.6,0,0,.1,0,.2,0,.8,0,1.5.6,1.6,1.4,0,.8-.6,1.5-1.4,1.6,0,0-.1,0-.2,0ZM433,37.5l2.1,2.5h-4.8v-2.5h2.7ZM431.4,46.4c-.8,0-1.4-.8-1.4-1.6s.8-1.4,1.6-1.4c.8,0,1.4.7,1.4,1.5,0,.9-.7,1.5-1.6,1.5,0,0,0,0,0,0Z" fill="#d9d9d9"/>
          </g>
          <g id="inventory_2_black_24dp">
            <g id="Group_4910">
              <rect id="Rectangle_1309" x="221" y="27" width="26" height="26" fill="none"/>
            </g>
            <g id="Group_4911">
              <path id="Path_3926" d="M242.3,29.6h-16.6c-1.1,0-2,.9-2.1,2.1v3.1c0,.7.4,1.4,1,1.8v11.8c0,1.1,1,2,2.1,2.1h14.6c1.1,0,2-1,2.1-2.1v-11.8c.6-.4,1-1,1-1.8v-3.1c0-1.1-.9-2-2.1-2.1ZM237.1,42.1h-6.2v-2.1h6.2v2.1ZM242.3,34.8h-16.6v-3.1h16.6v3.1Z" fill="#ddd"/>
            </g>
          </g>
          <g>
            <path d="M212.2,90.1v-8.6h3.4c0,0,.2,0,.3,0,.1,0,.2,0,.3,0,.5,0,.9.2,1.2.5.3.2.6.6.7.9.2.4.2.8.2,1.3s0,.9-.2,1.3c-.2.4-.4.7-.7.9s-.7.4-1.2.5c-.1,0-.2,0-.3,0-.1,0-.2,0-.3,0h-2.3v3.2h-1.1ZM213.3,85.9h2.3c0,0,.2,0,.3,0s.2,0,.3,0c.3,0,.5-.2.7-.4.2-.2.3-.4.4-.6s.1-.5.1-.7,0-.5-.1-.7c0-.2-.2-.4-.4-.6-.2-.2-.4-.3-.7-.4,0,0-.2,0-.3,0,0,0-.2,0-.3,0h-2.3v3.4Z" fill="#bfbfbf"/>
            <path d="M221.2,90.3c-.5,0-.9,0-1.2-.3-.3-.2-.6-.4-.7-.7s-.2-.6-.2-1,0-.6.2-.9.3-.5.5-.6c.2-.2.5-.3.8-.4.3,0,.7-.2,1-.2.4,0,.8-.1,1.2-.2.4,0,.8-.1,1.1-.2l-.4.2c0-.6-.1-1-.3-1.3-.2-.3-.7-.4-1.2-.4s-.7,0-1,.3c-.3.2-.5.5-.6.9l-1-.3c.2-.6.5-1,.9-1.3.4-.3,1-.5,1.8-.5s1.1.1,1.5.3c.4.2.7.5.9,1,0,.2.2.4.2.7,0,.2,0,.5,0,.7v4h-.9v-1.6h.2c-.2.7-.6,1.1-1,1.3-.4.3-1,.4-1.6.4ZM221.4,89.4c.4,0,.7,0,1-.2.3-.1.5-.3.7-.5.2-.2.3-.5.3-.7,0-.2,0-.4,0-.7,0-.2,0-.4,0-.6l.4.2c-.3,0-.7.1-1,.1s-.7,0-1,.1-.6.1-.8.2c-.2,0-.3.1-.5.2-.2,0-.3.2-.4.3,0,.1-.1.3-.1.5s0,.3.1.5c0,.2.2.3.4.4.2.1.4.2.7.2Z" fill="#bfbfbf"/>
            <path d="M228.9,90.3c-.7,0-1.2-.1-1.7-.4s-.8-.7-1-1.2c-.2-.5-.4-1.1-.4-1.8,0-.7.1-1.3.4-1.8.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5c.5.3.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2,0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5Z" fill="#bfbfbf"/>
            <path d="M232.9,90.1v-8.6h1.1v5.3l2.7-3.1h1.4l-2.9,3.2,3.2,3.2h-1.5l-2.9-3.1v3.1h-1.1Z" fill="#bfbfbf"/>
            <path d="M239.1,82.5v-1.1h1.1v1.1h-1.1ZM239.1,90.1v-6.5h1.1v6.5h-1.1Z" fill="#bfbfbf"/>
            <path d="M242,90.1v-6.5h1v1.7h.1v4.7h-1.1ZM246.6,90.1v-3.2c0-.3,0-.6,0-.9,0-.3-.1-.5-.3-.8-.1-.2-.3-.4-.5-.5-.2-.1-.5-.2-.9-.2s-.5,0-.7.1c-.2,0-.4.2-.6.4-.2.2-.3.4-.4.7,0,.3-.1.6-.1,1l-.7-.2c0-.6.1-1.2.3-1.6.2-.5.5-.8,1-1s.9-.4,1.4-.4.8,0,1.1.2.5.3.7.5c.2.2.4.5.5.7.1.3.2.5.2.8,0,.3,0,.5,0,.8v3.6h-1.1Z" fill="#bfbfbf"/>
            <path d="M251.8,90.3c-.6,0-1.1-.1-1.6-.4-.4-.3-.8-.7-1-1.2-.2-.5-.4-1.1-.4-1.7s.1-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1.1,1.5.4.7.7.9,1.2c.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.5.9-.9,1.2-.4.3-.9.5-1.5.5ZM251.9,93.1c-.3,0-.7,0-1-.2-.3-.1-.6-.3-.9-.5s-.5-.5-.7-.8l1-.5c.2.3.4.5.7.7.3.1.6.2.9.2s.8,0,1-.2c.3-.1.5-.4.6-.7.1-.3.2-.7.2-1.1v-1.8h.1v-4.7h.9v6.5c0,.2,0,.4,0,.5,0,.2,0,.3,0,.5,0,.5-.3.8-.5,1.1-.2.3-.6.5-1,.7-.4.1-.8.2-1.4.2ZM251.9,89.3c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9.1-.4.2-.8.2-1.3s0-.9-.2-1.3c-.1-.4-.3-.7-.6-.9s-.6-.3-1-.3-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2s0,.9.2,1.2c.1.4.4.7.6.9.3.2.6.3,1.1.3Z" fill="#bfbfbf"/>
          </g>
          <g>
            <path d="M11.8,90.1l2.9-8.6h1.5l2.9,8.6h-1.1l-2.7-8h.3l-2.7,8h-1.1ZM13.2,88.1v-1h4.5v1h-4.5Z" fill="#bfbfbf"/>
            <path d="M22.9,90.3c-.7,0-1.2-.1-1.7-.4s-.8-.7-1-1.2c-.2-.5-.4-1.1-.4-1.8,0-.7.1-1.3.4-1.8.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5c.5.3.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2,0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5Z" fill="#bfbfbf"/>
            <path d="M29.4,90.3c-.7,0-1.2-.1-1.7-.4s-.8-.7-1-1.2c-.2-.5-.4-1.1-.4-1.8,0-.7.1-1.3.4-1.8.2-.5.6-.9,1.1-1.2s1-.4,1.7-.4,1.3.2,1.8.5c.5.3.8.8,1,1.4l-1.1.3c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2,0,.7.2,1.3.5,1.7.3.4.8.7,1.5.7s.8,0,1.1-.3c.3-.2.5-.5.6-.8l1.1.3c-.2.6-.6,1.1-1,1.4-.5.3-1,.5-1.7.5Z" fill="#bfbfbf"/>
            <path d="M36,90.3c-.6,0-1.2-.1-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8-.3-.4-.8-.6-1.4-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.4.5,1.8c.3.4.8.6,1.5.6s.8,0,1.1-.3.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3-.5.3-1.1.5-1.7.5ZM33.6,87.1v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
            <path d="M40.1,93v-9.4h1v4.8h.1v4.6h-1.1ZM43.1,90.3c-.6,0-1.1-.2-1.5-.5-.4-.3-.7-.7-.9-1.2s-.3-1.1-.3-1.7.1-1.2.3-1.7.5-.9.9-1.2c.4-.3.9-.4,1.5-.4s1.1.1,1.6.4.8.7,1,1.2c.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.6.9-1,1.2-.4.3-1,.4-1.6.4ZM43,89.3c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9s.2-.8.2-1.2,0-.9-.2-1.2c-.1-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8.1-1,.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.3s0,.9.2,1.3c.1.4.3.7.6.9.3.2.6.3,1.1.3Z" fill="#bfbfbf"/>
            <path d="M46.6,84.5v-.9h4.3v.9h-4.3ZM51,90.1c-.4,0-.8.1-1.1,0-.4,0-.7,0-1-.2s-.5-.4-.7-.7-.2-.5-.2-.8c0-.3,0-.6,0-.9v-5.8h1.1v5.8c0,.3,0,.5,0,.7,0,.2,0,.4.1.5.1.3.4.4.7.5s.7,0,1.1,0v.9Z" fill="#bfbfbf"/>
            <path d="M54.8,90.3c-.6,0-1.2-.1-1.7-.4s-.8-.7-1.1-1.2-.4-1.1-.4-1.8.1-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8-.3-.4-.8-.6-1.4-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.4.5,1.8c.3.4.8.6,1.5.6s.8,0,1.1-.3.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3-.5.3-1.1.5-1.7.5ZM52.4,87.1v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
            <path d="M61.6,90.3c-.6,0-1.1-.1-1.6-.4-.4-.3-.8-.7-1-1.2-.2-.5-.4-1.1-.4-1.7s.1-1.2.4-1.7c.2-.5.6-.9,1-1.2s.9-.4,1.5-.4,1.1.1,1.5.4.7.7.9,1.2c.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.5.9-.9,1.2-.4.3-.9.5-1.5.5ZM61.8,89.3c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9.1-.4.2-.8.2-1.3s0-.9-.2-1.3c-.1-.4-.3-.7-.6-.9s-.6-.3-1-.3-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2s0,.9.2,1.2c.1.4.4.7.6.9.3.2.6.3,1.1.3ZM63.7,90.1v-4.8h-.1v-3.9h1.1v8.6h-1Z" fill="#bfbfbf"/>
          </g>
          <g>
            <path d="M404.2,90.3c-.6,0-1.2-.1-1.7-.3s-.9-.5-1.2-.9c-.3-.4-.5-.8-.6-1.4l1.1-.2c.1.5.4,1,.9,1.3.4.3,1,.5,1.6.5s.7,0,1-.2.6-.3.7-.5c.2-.2.3-.5.3-.8s0-.4-.1-.5c0-.1-.2-.3-.3-.4-.1,0-.2-.2-.4-.2-.1,0-.3-.1-.4-.2l-2.2-.7c-.2,0-.5-.2-.7-.3s-.4-.2-.6-.4c-.2-.2-.3-.4-.4-.6-.1-.2-.2-.5-.2-.8,0-.5.1-.9.4-1.3.3-.4.6-.6,1.1-.8.5-.2,1-.3,1.5-.3.6,0,1.1.1,1.6.3s.8.5,1.1.9.5.8.6,1.3l-1.1.2c0-.3-.2-.7-.4-.9s-.5-.5-.8-.6c-.3-.1-.6-.2-1-.2-.4,0-.7,0-1,.2s-.5.3-.7.5-.3.4-.3.7,0,.5.2.6c.2.2.3.3.6.4s.4.2.7.2l1.6.5c.2,0,.4.1.7.2s.5.2.7.4c.2.2.4.4.6.7.2.3.2.6.2,1s0,.8-.2,1.1-.4.6-.7.8c-.3.2-.6.4-1,.5-.4.1-.8.2-1.3.2Z" fill="#bfbfbf"/>
            <path d="M408.6,90.1v-8.6h1v4.8h.1v3.9h-1.1ZM413.3,90.1v-3.2c0-.3,0-.6,0-.9s-.1-.5-.3-.8c-.1-.2-.3-.4-.5-.5-.2-.1-.5-.2-.9-.2s-.5,0-.7.1c-.2,0-.4.2-.6.4s-.3.4-.4.7c0,.3-.1.6-.1,1l-.7-.2c0-.6.1-1.2.3-1.6.2-.5.5-.8,1-1s.9-.4,1.4-.4.8,0,1.1.2.5.3.7.5c.2.2.3.5.5.7.1.3.2.5.2.8,0,.3,0,.5,0,.8v3.6h-1.1Z" fill="#bfbfbf"/>
            <path d="M416,82.5v-1.1h1.1v1.1h-1.1ZM416,90.1v-6.5h1.1v6.5h-1.1Z" fill="#bfbfbf"/>
            <path d="M418.9,93v-9.4h1v4.8h.1v4.6h-1.1ZM421.9,90.3c-.6,0-1.1-.2-1.5-.5-.4-.3-.7-.7-.9-1.2s-.3-1.1-.3-1.7.1-1.2.3-1.7.5-.9.9-1.2c.4-.3.9-.4,1.5-.4s1.1.1,1.6.4c.4.3.8.7,1,1.2.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.6.9-1,1.2-.4.3-1,.4-1.6.4ZM421.7,89.3c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9.1-.4.2-.8.2-1.2s0-.9-.2-1.2c-.1-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8.1-1,.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.3s0,.9.2,1.3c.1.4.3.7.6.9.3.2.6.3,1.1.3Z" fill="#bfbfbf"/>
            <path d="M426.1,93v-9.4h1v4.8h.1v4.6h-1.1ZM429.1,90.3c-.6,0-1.1-.2-1.5-.5-.4-.3-.7-.7-.9-1.2s-.3-1.1-.3-1.7.1-1.2.3-1.7.5-.9.9-1.2.9-.4,1.5-.4,1.1.1,1.6.4c.4.3.8.7,1,1.2.2.5.3,1.1.3,1.7s-.1,1.2-.3,1.7c-.2.5-.6.9-1,1.2-.4.3-1,.4-1.6.4ZM428.9,89.3c.4,0,.8-.1,1.1-.3s.5-.5.6-.9c.1-.4.2-.8.2-1.2s0-.9-.2-1.2c-.1-.4-.4-.7-.6-.9-.3-.2-.6-.3-1.1-.3s-.8.1-1,.3-.5.5-.6.9-.2.8-.2,1.3,0,.9.2,1.3c.1.4.3.7.6.9s.6.3,1.1.3Z" fill="#bfbfbf"/>
            <path d="M436.1,90.3c-.6,0-1.2-.1-1.7-.4-.5-.3-.8-.7-1.1-1.2-.3-.5-.4-1.1-.4-1.8s.1-1.3.4-1.8c.3-.5.6-.9,1.1-1.2.5-.3,1-.4,1.6-.4s1.2.2,1.7.5.8.7,1,1.3c.2.6.3,1.2.3,2h-1.1v-.4c0-.8-.2-1.4-.5-1.8-.3-.4-.8-.6-1.4-.6s-1.2.2-1.5.6c-.3.4-.5,1-.5,1.8s.2,1.4.5,1.8c.3.4.8.6,1.5.6s.8,0,1.1-.3c.3-.2.6-.5.7-.8l1,.4c-.3.6-.6,1-1.1,1.3-.5.3-1.1.5-1.7.5ZM433.7,87.1v-.9h4.8v.9h-4.8Z" fill="#bfbfbf"/>
            <path d="M442.9,90.3c-.6,0-1.1-.1-1.6-.4-.4-.3-.8-.7-1-1.2-.2-.5-.4-1.1-.4-1.7s.1-1.2.4-1.7c.2-.5.6-.9,1-1.2.4-.3.9-.4,1.5-.4s1.1.1,1.5.4.7.7.9,1.2.3,1.1.3,1.7-.1,1.2-.3,1.7-.5.9-.9,1.2c-.4.3-.9.5-1.5.5ZM443.1,89.3c.4,0,.8-.1,1.1-.3.3-.2.5-.5.6-.9.1-.4.2-.8.2-1.3s0-.9-.2-1.3-.3-.7-.6-.9c-.3-.2-.6-.3-1-.3s-.8.1-1.1.3c-.3.2-.5.5-.6.9-.1.4-.2.8-.2,1.2s0,.9.2,1.2.4.7.6.9.6.3,1.1.3ZM445,90.1v-4.8h-.1v-3.9h1.1v8.6h-1Z" fill="#bfbfbf"/>
          </g>
        </g>
        <line id="Line_60" x1="562.8" y1="39.1" x2="617.6" y2="39.1" fill="none" stroke="#d4d4d4" stroke-opacity="0"/>
        <g id="Group_551">
          <circle id="Ellipse_90" cx="620.5" cy="38.5" r="38.5" fill="#ffdad2"/>
          <circle id="Ellipse_85" cx="620.5" cy="38.5" r="30.5" fill="#ff775b"/>
        </g>
        <circle id="Ellipse_87" cx="582" cy="40" r="5" fill="#bfbfbf"/>
        <g id="refund">
          <g id="Group_5852">
            <g id="Group_5851">
              <path id="Path_7004" d="M627.8,29.6l-1.3,1.7c4.4,3.4,5.2,9.7,1.8,14.1-3.4,4.4-9.7,5.2-14.1,1.8-4.4-3.4-5.2-9.7-1.8-14.1,2-2.6,5.2-4.1,8.5-3.9l-1.9,2,1.4,1.3,3-3.1h0l1.3-1.4-1.4-1.3h0l-3.1-3-1.3,1.4,2,1.9c-6.7-.3-12.5,4.9-12.8,11.7-.3,6.2,4.2,11.7,10.3,12.6.6.1,1.3.2,1.9.2,6.8,0,12.2-5.5,12.2-12.3,0-3.8-1.8-7.3-4.7-9.6Z" fill="#fff"/>
              <path id="Path_7005" d="M621.5,45.5h0v-1.3c1.6,0,2.9-1.3,2.9-2.9,0-2.4-2.3-2.7-3.9-2.9-1.1-.1-1.9-.3-1.9-.9s1.2-1,1.8-1c.7,0,1.5.3,1.9.9h0c0,.1,1.7-.6,1.7-.6v-.2c-.6-1-1.5-1.7-2.6-1.8v-1.1h-1.9v1.1c-1.8.3-2.9,1.3-2.9,2.7,0,2.3,2.1,2.6,3.7,2.7,1.4.2,2,.5,2,1.1,0,1.1-1.5,1.2-2,1.2-.9,0-1.8-.4-2.3-1.2v-.2c0,0-1.9.8-1.9.8v.2c.7,1.3,1.9,2.1,3.3,2.2v1.2h1.9Z" fill="#fff"/>
            </g>
          </g>
        </g>
        <g>
          <path d="M588.8,98.6v-10.1h4.1c0,0,.2,0,.4,0,.1,0,.3,0,.4,0,.6,0,1,.3,1.4.6.4.3.7.7.8,1.1.2.4.3.9.3,1.5,0,.8-.2,1.5-.6,2.1-.4.6-1,.9-1.9,1.1h-.6c0,0-2.9,0-2.9,0v3.6h-1.5ZM590.3,93.6h2.6c0,0,.2,0,.3,0,.1,0,.2,0,.3,0,.3,0,.5-.2.7-.4.2-.2.3-.4.4-.7,0-.2.1-.5.1-.7s0-.5-.1-.7c0-.2-.2-.5-.4-.7-.2-.2-.4-.3-.7-.4-.1,0-.2,0-.3,0-.1,0-.2,0-.3,0h-2.6v3.7ZM594.8,98.6l-2-4.1,1.5-.4,2.2,4.5h-1.7Z"/>
          <path d="M601.3,98.8c-.8,0-1.4-.2-2-.5-.6-.3-1-.8-1.3-1.4-.3-.6-.5-1.3-.5-2.1s.2-1.5.5-2.2c.3-.6.7-1.1,1.3-1.4.6-.3,1.2-.5,1.9-.5s1.4.2,2,.5c.5.4.9.9,1.2,1.5.3.7.4,1.4.3,2.3h-1.5v-.5c0-.9-.2-1.5-.5-1.9s-.8-.6-1.5-.6-1.3.2-1.7.7-.6,1.1-.6,2,.2,1.5.6,1.9.9.7,1.6.7.9-.1,1.2-.3.6-.5.8-.9l1.4.5c-.3.7-.7,1.2-1.3,1.6-.6.4-1.3.6-2,.6ZM598.6,95.2v-1.1h5.4v1.1h-5.4Z"/>
          <path d="M605.8,92.2v-1.2h4.6v1.2h-4.6ZM607,98.6v-7.8c0-.2,0-.4,0-.6,0-.2,0-.4.1-.6,0-.2.2-.4.3-.6.2-.2.4-.4.6-.4.2,0,.5-.1.7-.2.2,0,.4,0,.6,0h1v1.2h-.9c-.3,0-.6,0-.8.3s-.3.4-.3.7v8.1h-1.5Z"/>
          <path d="M614.4,98.8c-.5,0-1,0-1.3-.3-.4-.2-.6-.4-.9-.7-.2-.3-.4-.6-.5-.9s-.2-.6-.2-.9,0-.6,0-.8v-4.2h1.5v3.7c0,.3,0,.6,0,.9,0,.3.1.6.3.9s.3.5.6.6c.2.2.6.2,1,.2s.5,0,.7-.1c.2,0,.4-.2.6-.4.2-.2.3-.4.4-.8.1-.3.2-.7.2-1.1l.9.3c0,.7-.1,1.3-.4,1.8-.3.5-.6.9-1.1,1.2-.5.3-1.1.4-1.7.4ZM616.9,98.6v-2.2h-.2v-5.4h1.5v7.6h-1.3Z"/>
          <path d="M620.1,98.6v-7.6h1.3v2.2h.2v5.4h-1.5ZM625.5,98.6v-3.7c0-.3,0-.6,0-.9s-.1-.6-.3-.9c-.1-.3-.3-.5-.6-.6-.2-.2-.6-.2-1-.2s-.5,0-.7.1c-.2,0-.4.2-.6.4-.2.2-.3.4-.4.8-.1.3-.2.7-.2,1.1l-.9-.3c0-.7.1-1.3.4-1.8.3-.5.6-.9,1.1-1.2.5-.3,1.1-.4,1.7-.4s1,0,1.3.3c.4.2.6.4.9.7s.4.6.5.9c.1.3.2.6.2.9,0,.3,0,.6,0,.8v4.2h-1.5Z"/>
          <path d="M631.7,98.8c-.7,0-1.3-.2-1.8-.5s-.9-.8-1.2-1.4-.4-1.3-.4-2,.1-1.4.4-2,.7-1.1,1.2-1.4c.5-.3,1.1-.5,1.8-.5s1.3.2,1.8.5c.5.3.9.8,1.1,1.4.2.6.4,1.3.4,2s-.1,1.4-.4,2c-.3.6-.6,1.1-1.1,1.4-.5.4-1.1.5-1.8.5ZM631.9,97.5c.5,0,.9-.1,1.2-.3.3-.2.5-.5.7-.9.1-.4.2-.9.2-1.4s0-1-.2-1.4c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1.1-.3s-.9.1-1.2.4c-.3.2-.5.6-.7,1-.2.4-.2.9-.2,1.3s0,1,.2,1.4c.2.4.4.7.7,1,.3.2.7.4,1.2.4ZM634,98.6v-5.4h-.2v-4.6h1.5v10.1h-1.3Z"/>
          <path d="M640.6,98.8c-.8,0-1.4-.2-2-.5-.6-.3-1-.8-1.3-1.4-.3-.6-.5-1.3-.5-2.1s.2-1.5.5-2.2c.3-.6.7-1.1,1.3-1.4.6-.3,1.2-.5,1.9-.5s1.4.2,2,.5c.5.4.9.9,1.2,1.5.3.7.4,1.4.3,2.3h-1.5v-.5c0-.9-.2-1.5-.5-1.9s-.8-.6-1.5-.6-1.3.2-1.7.7-.6,1.1-.6,2,.2,1.5.6,1.9.9.7,1.6.7.9-.1,1.2-.3.6-.5.8-.9l1.4.5c-.3.7-.7,1.2-1.3,1.6-.6.4-1.3.6-2,.6ZM637.9,95.2v-1.1h5.4v1.1h-5.4Z"/>
          <path d="M648.6,98.8c-.7,0-1.3-.2-1.8-.5s-.9-.8-1.2-1.4-.4-1.3-.4-2,.1-1.4.4-2,.7-1.1,1.2-1.4c.5-.3,1.1-.5,1.8-.5s1.3.2,1.8.5c.5.3.9.8,1.1,1.4.2.6.4,1.3.4,2s-.1,1.4-.4,2c-.3.6-.6,1.1-1.1,1.4-.5.4-1.1.5-1.8.5ZM648.8,97.5c.5,0,.9-.1,1.2-.3.3-.2.5-.5.7-.9.1-.4.2-.9.2-1.4s0-1-.2-1.4c-.1-.4-.4-.7-.7-.9-.3-.2-.7-.3-1.1-.3s-.9.1-1.2.4c-.3.2-.5.6-.7,1-.2.4-.2.9-.2,1.3s0,1,.2,1.4c.2.4.4.7.7,1,.3.2.7.4,1.2.4ZM650.8,98.6v-5.4h-.2v-4.6h1.5v10.1h-1.3Z"/>
        </g>
      </g>
    </svg>
  );
  const orderMethods = [
    {
      type: "pickup",
      status: [
        {
          id: 1, // accepted
          forMobileSvg: statusAcceptedMobile,
          forDesktopSvg: statusAcceptedDesktop,
        },
        {
          id: 2, // packed svg
          forMobileSvg: statusPackedMobile,
          forDesktopSvg: statusPackedDesktop,
        },
        {
          id: 3, // ready svg
          forMobileSvg: statusReadyMobile,
          forDesktopSvg: statusReadyDesktop,
        },
        {
          id: 4, //completed svg
          forMobileSvg: statusCompleteMobile,
          forDesktopSvg: statusCompleteDesktop,
        },
        {
          id: 5, // cancelled svg
          forMobileSvg: statusCancelledMobile,
          forDesktopSvg: statusCancelledDesktop,
        },
        {
          id: 7, // refunded svg
          forMobileSvg: statusRefundedMobile,
          forDesktopSvg: statusRefundedDesktop,
        },
      ],
    },
    {
      type: "delivery",
      status: [
        {
          id: 1, // accepted
          forMobileSvg: deliveryAccept,
          forDesktopSvg: deliveryAccept,
        },
        {
          id: 2, // packed svg
          forMobileSvg: deliveryPacking,
          forDesktopSvg: deliveryPacking,
        },
        {
          id: 3, // shipped svg
          forMobileSvg: deliveryShipped,
          forDesktopSvg: deliveryShipped,
        },
        {
          id: 4, //completed svg
          forMobileSvg: deliveryCompleted,
          forDesktopSvg: deliveryCompleted,
        },
        {
          id: 5, // cancelled svg
          forMobileSvg: deliveryCanceled,
          forDesktopSvg: deliveryCanceled,
        },
        {
          id: 7, // refunded svg
          forMobileSvg: deliveryRefunded,
          forDesktopSvg: deliveryRefunded,
        },
      ],
    },
  ];

  const getOrderMethod = () => {
    return orderMethods.find(
      (method) =>
        orderSummeryData?.order_detail?.order_method.toLowerCase() ==
        method.type
    );
  };
  const OrderMethod = getOrderMethod();
  const getStatus = () => {
    return (
      OrderMethod &&
      OrderMethod?.status.find(
        (item) =>
          orderSummeryData?.order_detail &&
          
          ((orderSummeryData?.order_detail?.m_status == 6 && item.id == 2) ||
            orderSummeryData?.order_detail?.m_status == item.id)
      )
    );
  };
  const OrderStatus = getStatus();
  return (
    <div className="orderSummeryPageOrderStatus">{isMobile ? OrderStatus?.forMobileSvg : OrderStatus?.forDesktopSvg}</div>
  );
};

export default OrderSummery_pickup_delivery_status;

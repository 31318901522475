import {
  Grid,
  Skeleton as SkeletonMui,
  Tooltip as MuiTooltip,
} from "@mui/material";
import React, { useMemo } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { FiRefreshCcw } from "react-icons/fi";
import CircularProgress from "@mui/material/CircularProgress";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";
import { priceFormate } from "../../hooks/priceFormate";

const CustomTooltip = ({ payload, title }) => {
  // console.log("payload: ", payload);
  // console.log("title: ", title);
  if (payload && payload[0] && payload[0]["payload"]) {
    // let uv =
    //   parseFloat(payload[0]["payload"]?.uv) > 0
    //     ? parseFloat(payload[0]["payload"]?.uv)
    //     : 0;

    let uv = parseFloat(payload[0]["payload"]?.uv)
      ? parseFloat(payload[0]["payload"]?.uv)
      : 0;

    // if (title === "Revenue") {
    //   uv = `$${uv > 0 ? priceFormate(uv.toFixed(2)) : 0}`;
    // } else if (title === "Gross Profit") {
    //   uv = `$${uv > 0 ? priceFormate(uv.toFixed(2)) : 0}`;
    // } else if (title === "Discounted") {
    //   uv = `$${uv > 0 ? priceFormate(uv.toFixed(2)) : 0}`;
    // } else if (title === "Discounted %") {
    //   uv = `${uv > 0 ? uv.toFixed(2) : 0}%`;
    // } else if (title === "Avg. sale value") {
    //   uv = `$${uv > 0 ? priceFormate(uv.toFixed(2)) : 0}`;
    // } else if (title === "Avg. items per sale") {
    //   uv = `${uv > 0 ? uv.toFixed(2) : 0}`;
    // }

    if (title === "Revenue") {
      uv = `$${uv ? priceFormate(uv.toFixed(2)) : 0}`;
    } else if (title === "Gross Profit") {
      uv = `$${uv ? priceFormate(uv.toFixed(2)) : 0}`;
    } else if (title === "Discounted") {
      uv = `$${uv ? priceFormate(uv.toFixed(2)) : 0}`;
    } else if (title === "Discounted %") {
      uv = `${uv ? uv.toFixed(2) : 0}%`;
    } else if (title === "Avg. sale value") {
      uv = `$${uv ? priceFormate(uv.toFixed(2)) : 0}`;
    } else if (title === "Avg. items per sale") {
      uv = `${uv ? uv.toFixed(2) : 0}`;
    }

    // else if (title === "Sales Count") {
    //   uv = parseFloat(uv);
    // }

    return (
      <div className="custom-tooltip auto">
        <p>{payload[0]["payload"]?.name}</p>
        <p>{uv}</p>
      </div>
    );
  }
};

export default function SpikeCharts({
  title,
  growth,
  mainOutlet,
  amount,
  activeType,
  xAxisData,
  maxValue,
  minValue,
  yAxisOptions,
  type,
  formatFunction,
  loading = false,
  presentDate,
  refreshAPI,
  refreshLoading,
  yAxisText,
  // xAxisText = "Time period (e.g., days or weeks).",
}) {
  const filterBy = useMemo(() => {
    return activeType === "Day"
      ? "day"
      : activeType === "Week"
        ? "week"
        : "month";
  }, [activeType]);

  const xAxisText = useMemo(() => {
    return activeType === "Day"
      ? "Time Period (days)."
      : activeType === "Week"
        ? "Time Period (weeks)."
        : "Time Period (months).";
  }, [activeType]);

  const titleHanlde = ["Discounted %", "Gross Profit"];

  return (
    <Grid container className="box_shadow_div" sx={{ p: 2.5, m: 0 }}>
      {loading ? (
        <SkeletonMui
          sx={{ height: 300, width: "100%", transform: "scale(1)" }}
          xs={12}
          md={6}
        />
      ) : (
        <>
          <Grid
            item
            xs={12}
            sx={{ px: { xs: 1, md: 0 } }}
            className="flex justify-between"
          >
            <p className="CircularSTDMedium-18px">{title}</p>
            <div className="flex flex-wrap gap-3 items-center">
              {growth && (
                <div className="flex items-center gap-1">
                  {growth > 0 ? (
                    <>
                      <FaCaretUp className="text-[#1EC285]" />
                      <p className="CircularSTDBook-15px text-[#1EC285]">{`${growth}% up previous ${filterBy}`}</p>
                    </>
                  ) : growth < 0 ? (
                    <>
                      <FaCaretDown className="text-[#ff3737]" />
                      <p className="CircularSTDBook-15px text-[#ff3737]">{`${Math.abs(
                        growth
                      )}% down previous ${filterBy}`}</p>
                    </>
                  ) : null}
                </div>
              )}
              <MuiTooltip title={"Refresh"} arrow>
                <button
                  className="CircularSTDBook-15px refresh-icon"
                  onClick={() => refreshAPI()}
                  disabled={refreshLoading}
                >
                  {refreshLoading ? (
                    <CircularProgress color={"inherit"} width={15} size={15} />
                  ) : (
                    <FiRefreshCcw />
                  )}
                </button>
              </MuiTooltip>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ px: { xs: 1, md: 0 } }}
            className="flex items-center"
          >
            <p className=" CircularSTDMedium-32px">{amount}</p>
            <Link
              className="CircularSTDMedium-18px ml-2 pt-3 text-blue-600 underline text-base"
              to={{
                pathname: "/dashboard-chart-view-reports",
              }}
              state={{
                activeType: activeType,
                title: title,
                currentPresentDate: presentDate,
              }}
            >
              {titleHanlde.includes(title) ? "" : "View Reports"}
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ px: { xs: 2, md: 3 }, py: { xs: 1, md: 2 }, mb: 2, mt: 2 }}
            className="flex justify-between bg-[#F6F6F6] rounded-full"
          >
            <p className="CircularSTDMedium-15px">Main Outlet</p>
            <p className="CircularSTDBook-15px text-[#0A64F9]">{mainOutlet}</p>
          </Grid>
          <Grid item xs={12}>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={xAxisData}
                // data={data}
                // margin={{
                //   right: 50,
                //   left:
                //     yAxisOptions.sort((a, b) => b - a)[0]?.toString()?.length *
                //     2,
                // }}

                margin={{ top: 20, right: 20, bottom: 20, left: 20 }} // Adjust bottom and left margins
              >
                <CartesianGrid strokeDasharray="3 3" />
                <Line dataKey="uv" stroke="#0A64F9" strokeWidth={2} />
                <Tooltip content={<CustomTooltip title={title} />} />
                <XAxis
                  dataKey="name"
                  // label={{
                  //   value: "",
                  //   // position: "bottom",
                  //   position: "insideBottom",
                  //   style: { textAnchor: "middle" },
                  //   offset: 0,
                  // }}
                >
                  <Label
                    value={xAxisText}
                    offset={-15}
                    position="insideBottom"
                  />
                </XAxis>
                <YAxis
                  padding={{ top: 15, left: 10, right: 10 }}
                  type={type}
                  domain={[minValue, maxValue]}
                  tickFormatter={(tickItem) => formatFunction(tickItem)}
                  label={{
                    value: yAxisText,
                    style: { textAnchor: "middle" },
                    angle: -90,
                    // position: "left",
                    position: "insideLeft",
                    offset: -10,
                  }}
                />
              </LineChart>
            </ResponsiveContainer>
          </Grid>
          {/* <Grid item xs={12}>
            <p className="m-0 text-center text-[#111] text-[14px] text-opacity-70">
              Time period (e.g., days or weeks).
            </p>
          </Grid> */}
        </>
      )}
    </Grid>
  );
}

import React from "react";
import { IoInformationCircleOutline } from "react-icons/io5";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Zoom } from "@mui/material";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
  },
});
const HoverInfoLogo = ({title}) => {
  return (
    <CustomWidthTooltip
      title={title}
      arrow
      placement="bottom-end"
      componentsProps={{
        tooltip: {
          sx: {
            // width:"40%",
            backgroundColor: "#F5F5F5",
            color: "#000", // Text color
            fontSize: "0.875rem", // Adjust font size
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)", // Optional shadow
            border: "1px solid #ddd", // Optional border for clarity
          },
        },
        arrow: {
          sx: {
            color: "#F5F5F5", // Matches the tooltip background
          },
        },
      }}
      slots={{
        transition: Zoom,
      }}
    >
      <div className="ms-2" style={{ display: "inline-block" }}>
        <IoInformationCircleOutline
          style={{
            height: "20px",
            width: "20px",
            cursor: "pointer",
          }}
        />
      </div>
    </CustomWidthTooltip>
  );
};

export default HoverInfoLogo;

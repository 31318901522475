import React, { useState, useEffect } from "react";

import { Box, Button, Modal } from "@mui/material";
import AddIcon from "../../../Assests/Category/addIcon.svg";

import CrossIcon from "../../../Assests/Dashboard/cross.svg";
import axios from "axios";
import { fetchtaxesData } from "../../../Redux/features/Taxes/taxesSlice";
import { useDispatch } from "react-redux";
import { useAuthDetails } from "../../../Common/cookiesHelper";
import {
  BASE_URL,
  ADD_TAXES,
  TAXE_CATEGORY_LIST,
} from "../../../Constants/Config";
import BasicTextFields from "../../../reuseableComponents/TextInputField";
import TextField from "@mui/material/TextField";
import { ToastifyAlert } from "../../../CommonComponents/ToastifyAlert";
import CircularProgress from "@mui/material/CircularProgress";
import PasswordShow from "../../../Common/passwordShow";
import ThreeDecimalInputHelperFun from "../../../helperFunctions/ThreeDecimalInputHelperFun";
import SelectDropDown from "../../../reuseableComponents/SelectDropDown";

const AddTaxesModal = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setTaxes({
      title: "",
      percent: "",
    });
    setErrorMessage("");
    setErrorTitleMessage("");
    setErrorPerMessage("");
    setOpen(false);
    setSelectedOption("taxchoice");
    setApplyToCategory(false);
  };
  const [errorMessage, setErrorMessage] = useState("");
  const [errorTitleMessage, setErrorTitleMessage] = useState("");
  const [errorPerMessage, setErrorPerMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [applyToCategory, setApplyToCategory] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [storeToError, setStoreToError] = useState("");
  const [category, setCategory] = useState("--Choose a Category--");
  const [categoryDropdownVisible, setCategoryDropdownVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedOption, setSelectedOption] = useState("taxchoice");

  const { handleCoockieExpire, getUnAutherisedTokenMessage, getNetworkError } =
    PasswordShow();
  const dispatch = useDispatch();

  const myStyles = {
    // width: "58rem",
    position: "absolute",
    top: "47%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  const width = {
    width: "6.5rem",
  };

  const { LoginGetDashBoardRecordJson, LoginAllStore, userTypeData } =
    useAuthDetails();
  let merchant_id = LoginGetDashBoardRecordJson?.data?.merchant_id;
  const [taxes, setTaxes] = useState({
    title: "",
    percent: "",
    merchant_id: merchant_id,
    token_id: userTypeData?.token_id,
    login_type: userTypeData?.login_type,
  });

  // const inputChange = (e) => {
  //   const { name, value } = e.target;
  //   const regex = /^[A-Za-z0-9 ]*$/;
  //   if (name === "title") {
  //     if (regex.test(value)) {
  //       setTaxes({ ...taxes, title: value });
  //       setErrorTitleMessage(value ? "" : "Title is required");
  //       setErrorMessage("");
  //     }
  //   } else {
  //     let fieldValue = ThreeDecimalInputHelperFun(value);

  //      // Ensure value is numeric and greater than or equal to 0.001
  //       const numericValue = parseFloat(value);
  //       if (numericValue >= 0.001 || value === "") {
  //         setErrorPerMessage("");
  //       } else {
  //         setErrorPerMessage("Percent must be greater than or equal to 0.001");
  //       }
  //     // fieldValue = value
  //     // Remove extra dots and ensure only one dot exists at most
  //     //   .replace(/[^\d.]/g, "") // Allow digits and dots only
  //     //   .replace(/^(\d*\.)(.*)\./, "$1$2") // Remove extra dots
  //     //   .replace(/^(\d*\.\d*)(.*)\./, "$1$2"); // Remove extra dots after the decimal point
  //     // let inputStr = fieldValue.replace(/\D/g, "");
  //     // inputStr = inputStr.replace(/^0+/, "");

  //     // if (inputStr.length == "") {
  //     //   fieldValue = "0.00";
  //     // } else if (inputStr.length === 1) {
  //     //   fieldValue = "0.0" + inputStr;
  //     // } else if (inputStr.length === 2) {
  //     //   fieldValue = "0." + inputStr;
  //     // } else {
  //     //   fieldValue =
  //     //     inputStr.slice(0, inputStr.length - 2) + "." + inputStr.slice(-2);
  //     // }

  //     // console.log("fieldValue",fieldValue,"value", value)
  //     if (fieldValue.trim() === "") {
  //       setTaxes({ ...taxes, title: "" });
  //     } else {
  //       setTaxes((preValue) => {
  //         return {
  //           ...preValue,
  //           [name]: name === "percent" ? fieldValue : fieldValue,
  //         };
  //       });
  //       setErrorPerMessage("");
  //     }
  //     setErrorPerMessage(value ? "" : "Percent is required");
  //   }
  // };
  const inputChange = (e) => {
    const { name, value, selectionStart } = e.target;
    const regex = /^[A-Za-z0-9 ]*$/;
  
    if (name === "title") {
      if (regex.test(value)) {
        setTaxes({ ...taxes, title: value });
        setErrorTitleMessage(value ? "" : "Title is required");
        setErrorMessage("");
      }
    } else if (name === "percent") {
      // Format the input using the helper function
      const formattedValue = ThreeDecimalInputHelperFun(value);
  
      // Calculate the adjustment for the cursor position
      const cursorAdjustment = formattedValue.length - value.length;
  
      // Validate the input
      const numericValue = parseFloat(formattedValue);
      if (!value) {
        setErrorPerMessage("Percent is required");
      } else if (numericValue < 0.001) {
        setErrorPerMessage("Percent must be greater than or equal to 0.001");
      } else {
        setErrorPerMessage("");
      }
  
      // Update the state
      setTaxes((prev) => ({
        ...prev,
        percent: formattedValue,
      }));
  
      // Preserve the cursor position
      requestAnimationFrame(() => {
        e.target.setSelectionRange(selectionStart + cursorAdjustment, selectionStart + cursorAdjustment);
      });
    }
  };

  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9.]+$/;

    if (!regex.test(keyValue)) {
      e.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const categoryId = selectedCategory;

    if (
      applyToCategory &&
      (!categoryId || categoryId === "--Choose a Category--")
    ) {
      setStoreToError("Please select a category");
      return; // Prevent form submission
    } else {
      setStoreToError("");
    }

    formData.append("title", taxes.title);
    formData.append("percent", taxes.percent);
    formData.append("merchant_id", merchant_id);

    formData.append("applytaxtocat", applyToCategory ? 1 : 0);
    formData.append(
      "taxchoice",
      applyToCategory ? (selectedOption === "taxchoice" ? 0 : 1) : ""
    ); // 1 for updating tax, 0 for additional tax
    formData.append("cate_id", applyToCategory ? categoryId : "");
    formData.append("token_id", userTypeData?.token_id);
    formData.append("login_type", userTypeData?.login_type);

    if (taxes.title === "" || taxes.percent === "") {
      setErrorTitleMessage(taxes.title ? "" : "Title is required");
      setErrorPerMessage(taxes.percent ? "" : "Percent is required");
      return;
    } else if (
      (applyToCategory && storeToError) ||
      (applyToCategory && categoryId === "--Choose a Category--")
    ) {
      setStoreToError("Please select a category");
      return;
    }
    // Ensure value is numeric and greater than or equal to 0.001
    const numericValue = parseFloat(taxes.percent);
    if (numericValue >= 0.001) {
      setErrorPerMessage("");
    } else {
      setErrorPerMessage("Percent must be greater than or equal to 0.001");
      return;
    }
    setLoader(true);
    try {
      const res = await axios.post(BASE_URL + ADD_TAXES, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userTypeData?.token}`,
        },
      });
      const data = await res.data.status;
      const update_message = await res.data.msg;
      // console.log(update_message)
      // alert(update_message);
      if (data == "Success") {
        // alert(update_message);
        ToastifyAlert("Added Successfully", "success");
        let data = {
          merchant_id: merchant_id,
          ...userTypeData,
        };
        if (data) {
          dispatch(fetchtaxesData(data));
        }
        setTaxes({
          title: "",
          percent: "",
          merchant_id: merchant_id,
        });
        setCategory("--Choose a Category--");
        handleClose();
      } else if (
        data == "Failed" &&
        update_message == "DefaultTax Taxes Already Exist"
      ) {
        setErrorMessage("*The name is already exist");
      } else if (data == "Failed" && update_message == "Title is required") {
        setErrorMessage(update_message);
      }
    } catch (error) {
      if (error.status == 401 || error.response.status === 401) {
        getUnAutherisedTokenMessage();
        handleCoockieExpire();
      } else if (error.status == "Network Error") {
        getNetworkError();
      }
    }
    setLoader(false);
  };

  const updateTaxByOptions = (e) => {
    const { name } = e.target;

    setSelectedOption(name);
  };

  const handleOptionClick = async (option, dropdown) => {
    switch (dropdown) {
      case "category":
        if (option === "--Choose a Category--") {
          setCategory("--Choose a Category--");
          setCategoryDropdownVisible(false);
        } else {
          setCategory(option.title);
          setCategoryDropdownVisible(false);
        }
        if (option == "--Choose a Category--") {
          setStoreToError("This field is required");
          setSelectedCategory("");
        } else {
          setStoreToError("");
          const selId = option.id;
          setSelectedCategory(selId);
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const formData = new FormData();
      formData.append("merchant_id", merchant_id);
      formData.append("token_id", userTypeData?.token_id);
      formData.append("login_type", userTypeData?.login_type);
      try {
        const response = await axios.post(
          BASE_URL + TAXE_CATEGORY_LIST,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userTypeData?.token}`,
            },
          }
        );

        // Assuming the API response has a data property containing the category list
        const categoryList = response.data.result;
        // Extracting category IDs and view titles
        const mappedOptions = categoryList.map((category) => ({
          id: category.id,
          title: category.title,
        }));

        setCategoryOptions(mappedOptions);
        setLoadingCategories(false);
      } catch (error) {
        if (error?.status == 401 || error?.response?.status === 401) {
          getUnAutherisedTokenMessage();
          handleCoockieExpire();
        } else if (error.status == "Network Error") {
          getNetworkError();
        }
      } finally {
        setLoadingCategories(false);
      }
    };

    fetchData();
  }, []); // Fetch categories only once when the component mounts
  return (
    <div>
      <div
        className="flex justify-evenly categories-items categories-items-btn"
        onClick={handleOpen}
      >
        <p className="mr-2">Add Tax</p>
        <span className="categories-items categories-items-btn">
          <img src={AddIcon} alt="edit-icon" />{" "}
        </span>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="view-category-item-modal"
          sx={{ width: { xs: "90%", sm: "60%" } }}
          style={myStyles}
        >
          {/* <div className='view-category-item-modal-header'> */}
          <div
            className="q-add-categories-section-header"
            style={{ justifyContent: "space-between" }}
          >
            <span style={{ cursor: "unset" }}>Add Tax</span>

            <div className="float-right">
              <img
                src={CrossIcon}
                alt="icon"
                className="quic-btn-cancle w-6 h-6 cursor-pointer"
                onClick={() => handleClose()}
              />
            </div>
          </div>

          {/* </div> */}
          <div className="view-category-item-modal-header">
            <form onSubmit={handleSubmit} enctype="multipart/form-data">
              <div className="q-add-categories-section-middle-form">
                <div className="q-add-categories-single-input">
                  <label for="title">Tax Title</label>
                  {/* <input
                    type="text"
                    id="title"
                    name="title"
                    onChange={inputChange}
                    value={taxes.title}
                  /> */}
                </div>
                <BasicTextFields
                  value={taxes.title}
                  onChangeFun={inputChange}
                  placeholder="Enter Title"
                  name="title"
                  type="text"
                  maxLength={50}
                  // required={true}
                />
                {errorMessage && (
                  <p className="error-message">{errorMessage}</p>
                )}
                {errorTitleMessage && (
                  <p className="error-message">{errorTitleMessage}</p>
                )}

                <div className="q-add-categories-single-input mt-4">
                  <label for="Percentage">Enter Tax Rate (%) </label>
                  <input
                    type="text"
                    id="percent"
                    // pattern="/d*"
                    inputMode="numeric"
                    min="0.00"
                    max="99.99"
                    name="percent"
                    value={taxes.percent}
                    placeholder="00.00"
                    onChange={inputChange}
                    // onKeyPress={handleKeyPress}
                  />
                </div>
                {/* <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "black",
                      },
                    },
                  }}
                  id="outlined-basic"
                  name="percent"
                  value={taxes.percent}
                  // inputProps={{ maxLength: 6, type: "text" }}
                  inputProps={{ maxLength: 6, type: "number", min: "0.001", step: "0.001" }}
                  onChange={inputChange}
                  placeholder="00.000"
                  variant="outlined"
                  size="small"
                  required={true}
                  onKeyPress={handleKeyPress}
                /> */}
                {errorPerMessage && (
                  <p className="error-message ">{errorPerMessage}</p>
                )}

                <div className="category-checkmark-div m-2 mt-4 mb-4">
                  <label className="category-checkmark-label">
                      Select a Category to Apply Tax
                    <input
                      type="checkbox"
                      id="applytaxtocat"
                      name="applytaxtocat"
                      checked={applyToCategory}
                      onChange={() => setApplyToCategory(!applyToCategory)}
                    />
                    <span className="category-checkmark"></span>
                  </label>
                </div>

                {applyToCategory && (
                  <>
                    <div className="q-add-categories-single-input ">
                      {loadingCategories ? (
                        <p>Loading categories...</p>
                      ) : (
                        <>
                          {/* <div className="custom-dropdown">
                            <div
                              className="custom-dropdown-header"
                              onClick={() => toggleDropdown("category")}
                            >
                              <span className="selected-option mt-1">
                                {category}
                              </span>
                              <img
                                src={DownIcon}
                                alt="Down Icon"
                                className="w-8 h-8"
                              />
                            </div>
                            {categoryDropdownVisible && (
                              <div
                                className="dropdown-content"
                                style={myDropStyles}
                              >
                                <div
                                  onClick={() =>
                                    handleOptionClick(
                                      {
                                        label: "--Choose a Category--",
                                        id: null,
                                      },
                                      "category"
                                    )
                                  }
                                >
                                  --Choose a Category--
                                </div>
                                {categoryOptions &&
                                  categoryOptions.map((option) => (
                                    <div
                                      key={option.id}
                                      onClick={() =>
                                        handleOptionClick(
                                          {
                                            label: option.title,
                                            id: option.id,
                                          },
                                          "category"
                                        )
                                      }
                                    >
                                      {option.title}
                                    </div>
                                  ))}
                              </div>
                            )}
                          </div> */}

                          <SelectDropDown
                            listItem={categoryOptions}
                            heading={"--Choose a Category--"}
                            title={"title"}
                            onClickHandler={handleOptionClick}
                            selectedOption={category}
                            dropdownFor={"category"}
                          />

                          <span className="input-error error-message">
                            {storeToError && (
                              <span className="input-error ">
                                {storeToError}
                              </span>
                            )}
                          </span>
                        </>
                      )}
                    </div>

                    <div
                      className="q-add-categories-single-input mt-3 d-flex taxesApplyCategory"
                      style={{ width: "fit-content" }}
                    >
                      <label className="q_receipt_page_main ">
                      Add Extra Tax to This Category?
                        <input
                          type="radio"
                          name="taxchoice"
                          checked={selectedOption === "taxchoice"}
                          onChange={updateTaxByOptions}
                        />
                        <span className="checkmark_section"></span>
                      </label>
                      <label className="q_receipt_page_main ml-3">
                      Replace Existing Tax for This Category?
                        <input
                          type="radio"
                          name="taxupdate"
                          checked={selectedOption === "taxupdate"}
                          onChange={updateTaxByOptions}
                        />
                        <span className="checkmark_section"></span>
                      </label>
                    </div>
                  </>
                )}
              </div>

              <div className="q-add-categories-section-middle-footer">
                <button
                  className="quic-btn quic-btn-save attributeUpdateBTN"
                  disabled={loader}
                >
                  {loader ? (
                    <>
                      <CircularProgress
                        color={"inherit"}
                        className="loaderIcon"
                        width={15}
                        size={15}
                      />{" "}
                      Add
                    </>
                  ) : (
                    "Add"
                  )}
                </button>

                <button
                  onClick={() => handleClose()}
                  className="quic-btn quic-btn-cancle"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddTaxesModal;

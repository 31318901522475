// export const getCurrentDateRange = () => {
//     const today = new Date();
//     const year = today.getFullYear();
//     const month = String(today.getMonth() + 1).padStart(2, '0');
//     const day = String(today.getDate()).padStart(2, '0');
//     const endDate = `${year}-${month}-${day}`;
//     const pastDate = new Date();
//     pastDate.setDate(today.getDate() - 6);
//     const pastYear = pastDate.getFullYear();
//     const pastMonth = String(pastDate.getMonth() + 1).padStart(2, '0');
//     const pastDay = String(pastDate.getDate()).padStart(2, '0');
//     const startDate = `${pastYear}-${pastMonth}-${pastDay}`;
//     return {
//       start_date: startDate,
//       end_date: endDate
//     };
//   };
export const getCurrentDateRange = () => {
  const today = new Date();

  const yesterday = new Date();
  // yesterday.setDate(today.getDate() - 1);
  yesterday.setDate(today.getDate());
  const year = yesterday.getFullYear();
  const month = String(yesterday.getMonth() + 1).padStart(2, '0');
  const day = String(yesterday.getDate()).padStart(2, '0');
  const endDate = `${year}-${month}-${day}`;

  const pastDate = new Date();
  // pastDate.setDate(today.getDate() - 7);
  pastDate.setDate(today.getDate() - 6);
  const pastYear = pastDate.getFullYear();
  const pastMonth = String(pastDate.getMonth() + 1).padStart(2, '0');
  const pastDay = String(pastDate.getDate()).padStart(2, '0');
  const startDate = `${pastYear}-${pastMonth}-${pastDay}`;

  return {
    start_date: startDate,
    end_date: endDate
  };
};

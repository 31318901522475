// ----------------------------------order Summery start here ---------------------------------
import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../../../Styles/summery.css";
import imageLogo from "../../../../Assests/NewImage/imageLogo.svg";
import imageAddress from "../../../../Assests/NewImage/Address.svg";
import imagePhone from "../../../../Assests/NewImage/Phone.svg";
import emailLogo from "../../../../Assests/Dashboard/email.svg";
import { useLocation } from "react-router-dom";
import Miles from "../../../../Assests/NewImage/Miles.svg";
import Phone1 from "../../../../Assests/NewImage/Phone1.svg";
import Miles1 from "../../../../Assests/NewImage/Miles1.svg";
import Map1 from "../../../../Assests/NewImage/Map.svg";
import { format } from "date-fns";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FetchOrderSummeryDetails } from "../../../../Redux/features/orderSummeryHistory/orderSummerySlice";
import OrderSummeryBanner1 from "../../../../Assests/NewImage/OrderSummeryBanner1.png";
import OrderSummeryBanner2 from "../../../../Assests/NewImage/OrderSummeryBanner2.png";
import OrderSummeryBanner3 from "../../../../Assests/NewImage/OrderSummeryBanner3.png";
import OrderSummeryBannerMobile1 from "../../../../Assests/NewImage/OrderSummeryBannerMobile1.jpg";
import OrderSummeryBannerMobile2 from "../../../../Assests/NewImage/OrderSummeryBannerMobile2.jpg";
import OrderSummeryBannerMobile3 from "../../../../Assests/NewImage/OrderSummeryBannerMobile3.jpg";
import ProductDefaultImage from "../../../../Assests/Products/productDefaultIMage.png";
import { Grid, useMediaQuery, Box, Modal } from "@mui/material";
import Cookies from "js-cookie";
import Loaderfile from "../../../../Assests/Loader/loaderfile";
import OrderSummery_pickup_delivery_status from "./OrderSummery_pickup_delivery_status";
import Footer from "../../../../Components/Footer/footer";
import { Tooltip, tooltipClasses } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formatCurrency } from "../../../../helperFunctions/formatCurrency";
import { positions } from "@mui/system";
import { priceFormate } from "../../../../hooks/priceFormate";

const StyledTable = styled(Table)(({ theme }) => ({
  padding: 0.5, // Adjust padding as needed
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F1F1",
    fontSize: "clamp(14px, 2vw, 16px)",
    fontFamily: "QuicksandSemiBold !important",
    color: "#000000 !important",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "clamp(14px, 2vw, 16px)",
    fontFamily: "QuicksandSemiBold !important",
    // color: "#6a6969 !important",
    color: "##000000 !important",
  },
  [`&.${tableCellClasses.table}`]: {
    fontSize: "clamp(14px, 2vw, 16px)",
    fontFamily: "QuicksandSemiBold !important",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "none",
  },
  "&:last-child td, &:last-child th": {},
  "& td, & th": {
    border: "none",
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#f5f5f9",
    "&::before": {
      border: "1px solid #dadde9",
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 500,
    border: "1px solid #dadde9",
  },
}));

export default function PaymentCalDetails() {
  const Navigate = useNavigate();
  const OrderSummeryDetails = useSelector((state) => state.orderSummeryList);
  const [orderSummeryloader, setOrderSummeryloader] = useState(true);
  if (OrderSummeryDetails?.loading == true && orderSummeryloader == true) {
    setOrderSummeryloader(false);
  }
  const [orderSummeryData, setOrderSummeryData] = useState({});
  const [dateFormat, setDateFormat] = useState({});
  const [dateOfBirth, setBateOfBirth] = useState("");
  const [couponDetails, setCouponDetails] = useState("");
  const [productInfo, setProductInfo] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const dispatch = useDispatch();
  const [refund, setRefund] = useState([]);
  const [nonrefund, setNonrefund] = useState([]);
  const [imageLoading, setImageLoading] = useState(true);
  const location = useLocation();

  const isMobile = useMediaQuery("(max-width:480px)");
  const { merchant_id, order_id } = useParams();

  // dynamic required-----------------------------
  const data = {
    merchant_id: merchant_id,
    order_id: order_id,
  };

  // dynamic requierd---------------------
  useEffect(() => {
    if (data) {
      dispatch(FetchOrderSummeryDetails(data));
    }
  }, [data.merchant_id, data.order_id]);
  useEffect(() => {
    if (
      !OrderSummeryDetails.loading &&
      OrderSummeryDetails.orderSummeryDetails
    ) {
      setOrderSummeryData(OrderSummeryDetails.orderSummeryDetails);
      // let refund = orderSummeryData.cart_data?.filter((i) => {
      //   return i.is_refunded === "1" || i.is_refunded === "2";
      // });
      // let non_refund = orderSummeryData.cart_data?.filter((i) => {
      //   return i.is_refunded === "0" || i.is_refunded === "2";
      // });

      // setRefund(refund);
      // setNonrefund(non_refund);

      let refund = orderSummeryData.cart_data?.filter((i) => {
        return i.is_refunded === "1" || i.is_refunded === "2";
      });
      let non_refund = orderSummeryData.cart_data?.filter((i) => {
        return i.is_refunded === "0" || i.is_refunded === "2";
      });
      // console.log("orderSummeryData: ", orderSummeryData);
      // console.log(
      //   "OrderSummeryDetails.orderSummeryDetails: ",
      //   OrderSummeryDetails.orderSummeryDetails
      // );
      // console.log("=======================")
      // console.log("refund prods: ", refund);
      // console.log("non_refund prods: ", non_refund);

      const isProductVariant = (prod) => {
        return (
          prod.variant_id &&
          parseFloat(prod.variant_id) &&
          parseFloat(prod.variant_id) > 0
        );
      };

      // const products = (lineItems, type) => {
      //   const prods = lineItems.reduce((acc, curr) => {
      //     const productFound =
      //       acc && acc.length > 0
      //         ? acc.find((prod) => {
      //             const isVariant = isProductVariant(prod);
      //             if (isVariant) {
      //               return (
      //                 prod.id === curr.id &&
      //                 prod.variant_id === curr.variant_id &&
      //                 prod.name === curr.name
      //               );
      //             } else {
      //               return prod.id === curr.id && prod.name === curr.name;
      //             }
      //           })
      //         : false;

      //     if (productFound) {
      //       acc = acc.map((prod) => {
      //         const isVariant = isProductVariant(prod);
      //         const key = type === "refund" ? "refund_qty" : "qty";
      //         if (
      //           isVariant &&
      //           prod.id === productFound.id &&
      //           prod.variant_id === productFound.variant_id &&
      //           prod.name === productFound.name
      //         ) {
      //           return { ...prod, [key]: parseFloat(prod[key]) + 1 };
      //         } else if (prod.id === productFound.id && !isVariant) {
      //           return { ...prod, [key]: parseFloat(prod[key]) + 1 };
      //         } else {
      //           return prod;
      //         }
      //       });
      //     } else {
      //       // acc.push(curr);
      //       const key = type === "refund" ? "refund_qty" : "qty";
      //       if (parseFloat(curr[key]) === 0 && curr.name) {
      //         acc.push({ ...curr, [key]: 1 });
      //       } else {
      //         acc.push(curr);
      //       }
      //     }

      //     return acc;
      //   }, []);

      //   return prods;
      // };

      const refundProducts = refund && refund.length > 0 ? refund : [];
      const nonRefundProducts =
        non_refund && non_refund.length > 0 ? non_refund : [];
      // console.log("orderSummeryData.cart_data: ", orderSummeryData.cart_data);
      // console.log("refund products: ", refundProducts);
      // console.log("non refund products: ", nonRefundProducts);

      setRefund(refundProducts);
      setNonrefund(nonRefundProducts);

      // console.log("OrderSummeryDetails", OrderSummeryDetails);
      if (OrderSummeryDetails.orderSummeryDetails.id_card_detail) {
        const originalDateString =
          OrderSummeryDetails.orderSummeryDetails.id_card_detail.i_card_ex_date;
        const DateOfBirth =
          OrderSummeryDetails.orderSummeryDetails.id_card_detail.i_card_dob;

        if (originalDateString !== "0000-00-00") {
          const originalDate = new Date(originalDateString);

          const formattedDate = format(originalDate, "MM/dd/yyyy");

          setDateFormat(formattedDate);
          DateOfBirthAccessor(DateOfBirth);
        } else {
          setDateFormat("");
          DateOfBirthAccessor("");
        }
      }

      if (
        !!OrderSummeryDetails?.orderSummeryDetails?.order_detail?.coupon_code
      ) {
        CouponData(
          OrderSummeryDetails?.orderSummeryDetails?.order_detail?.coupon_code
        );
      }
      setPaymentMethod(
        OrderSummeryDetails?.orderSummeryDetails?.order_detail?.payment_id
      );
    }
  }, [
    OrderSummeryDetails,
    OrderSummeryDetails.loading,
    orderSummeryData,
    OrderSummeryDetails.orderSummeryDetails,
  ]);
  function DateOfBirthAccessor(method) {
    if (method !== "") {
      const originalDate = new Date(method);
      // const formattedDate = format(originalDate, "dd MMM yyyy");
      const formattedDate = format(originalDate, "MM/dd/yyyy");
      setBateOfBirth(formattedDate);
    }
  }
  // -------------------------------------------------
  function CouponData(data) {
    let cuopondetails = JSON.parse(data);
    setCouponDetails(cuopondetails);
  }

  const [testData, setTestData] = useState(false);
  const handleClick = () => {
    setTestData(true);
  };
  // const getOtherTaxes = (taxObj,cartData) => {
  //   // old Taxes
  //   // return Object.keys(taxObj).map((key) => (
  //   //   <p key={key}>
  //   //     {key}
  //   //     <span>${parseFloat(taxObj[key]).toFixed(2)}</span>
  //   //   </p>
  //   // ));
  //   // New taxes
  //   return Object.keys(taxObj).map((key) => (
  //       <StyledTableRow>
  //         <StyledTableCell ><span className="tablecellColor">{key}</span></StyledTableCell>
  //         <StyledTableCell >
  //           {parseFloat(taxObj[key]).toFixed(2)}%
  //         </StyledTableCell>
  //         <StyledTableCell sx={{ textAlign: "center" }}>
  //           calculate from all Card Data where Key will find in othertax
  //         </StyledTableCell>
  //         <StyledTableCell sx={{ textAlign: "right" }}>
  //         cartData where find key in other_taxes_desc:"{\"gst\":32.21387782076991,\"test108\":1.1870698652808767}"
  //         like when key is gst the sum of all 32.21387782076991 value if key is test108 then find sum of all key where
  //         other_taxes_desc have key the sum of all card item value
  //         </StyledTableCell>
  //       </StyledTableRow>
  //     ));
  // };

  const getOtherTaxes = (taxObj, cartData) => {
    if (!Array.isArray(cartData)) {
      console.error("cartData is not an array or is undefined");
      return (
        <StyledTableRow>
          <StyledTableCell colSpan={4} sx={{ textAlign: "center" }}>
            No cart data available.
          </StyledTableCell>
        </StyledTableRow>
      );
    }

    const calculateOtherTaxSum = (key, cartData) => {
      return cartData.reduce((sum, item) => {
        let otherTaxesDesc = {};
        if (item?.other_taxes_desc) {
          try {
            otherTaxesDesc = JSON.parse(item.other_taxes_desc);
          } catch (error) {
            console.error(
              "Error parsing other_taxes_desc:",
              item.other_taxes_desc,
              error
            );
          }
        }
        const value = parseFloat(otherTaxesDesc[key]) || 0;
        return sum + value;
      }, 0);
    };

    const calculateNetTaxAmount = (key, cartData) => {
      return cartData.reduce((sum, item) => {
        let otherTaxesDesc = {};
        if (item?.other_taxes_desc) {
          try {
            otherTaxesDesc = JSON.parse(item.other_taxes_desc);
          } catch (error) {
            console.error(
              "Error parsing other_taxes_desc:",
              item.other_taxes_desc,
              error
            );
          }
        }
        // Check if the key exists in other_taxes_desc
        if (key in otherTaxesDesc) {
          const price = parseFloat(item.price) || 0;
          const discount = parseFloat(item.discount_amt) || 0;
          sum += price - discount;
        }
        return sum;
      }, 0);
    };
    return Object.keys(taxObj).map((key) => {
      const totalTaxAmount = calculateOtherTaxSum(key, cartData);
      const totalNetTaxAmount = calculateNetTaxAmount(key, cartData);
      return (
        <StyledTableRow key={key}>
          <StyledTableCell>
            <span className="tablecellColor">{key}</span>
          </StyledTableCell>
          <StyledTableCell>
            {parseFloat(taxObj[key] || 0).toFixed(3)}%
          </StyledTableCell>
          <StyledTableCell sx={{ textAlign: "center" }}>
            ${totalNetTaxAmount.toFixed(2)}
          </StyledTableCell>
          <StyledTableCell sx={{ textAlign: "right" }}>
            ${totalTaxAmount.toFixed(2)}
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  };

  function removeHtmlTags(str) {
    return str.replace(/<[^>]*>/g, "");
  }
  const formatDateOnly = (dateString) => {
    const parsedDate = new Date(dateString);
    if (isNaN(parsedDate.getTime())) {
      return "Error: Invalid date";
    }
    const day = String(parsedDate.getDate()).padStart(2, "0");
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const year = parsedDate.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const formatTimeOnly = (inputDate) => {
    const parsedTime = new Date(inputDate);
    if (isNaN(parsedTime.getTime())) {
      return <div>Error: Invalid time format</div>;
    }
    let hours = parsedTime.getHours();
    const minutes = String(parsedTime.getMinutes()).padStart(2, "0");
    const meridiem = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    hours = String(hours).padStart(2, "0");
    const formattedTime = `${hours}:${minutes} ${meridiem}`;
    return formattedTime;
  };

  function formatDateTimeOnly(dateTime) {
    const date = new Date(dateTime);
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Add leading zero
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format
    hours = String(hours).padStart(2, "0"); // Add leading zero
    return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
  }

  const isGreaterThan10 = (num) => (+num >= 10 ? num : `0${num}`);

  const formatDate = (inputDate, type) => {
    const parsedTime = new Date(inputDate);
    if (isNaN(parsedTime.getTime())) {
      return <div>Error: Invalid time format</div>;
    }

    const day = parsedTime.getDate();
    const month = parsedTime.toLocaleString("default", { month: "short" });
    const year = parsedTime.getFullYear();
    let hours = parsedTime.getHours();
    const minutes = parsedTime.getMinutes();
    const seconds = parsedTime.getSeconds();
    const meridiem = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    let formattedTime = `${day} ${month} ${year} ${isGreaterThan10(
      hours
    )}:${isGreaterThan10(minutes)}:${isGreaterThan10(seconds)} ${meridiem}`;

    if (type === "future-order") {
      formattedTime = `${day} ${month} ${year} ${isGreaterThan10(
        hours
      )}:${isGreaterThan10(minutes)} ${meridiem}`;
    }
    return formattedTime;
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // -------------------------------
  const localUserData = Cookies.get("userLoginData");
  const currentPath = window.location.pathname.replace(/\/$/, "");

  const formatCardNumber = (cardNum) => {
    const res = cardNum.slice(-4);
    const temp = `xxxxxxxxx${res}`;
    // return temp;
    return res;
  };

  const setPositionLoader = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const getPaymentMethod = (orderSummeryData) => {
    // const groupedPayments = orderSummeryData?.split_payments?.reduce(
    //   (acc, payment) => {
    //     const { pay_type, pay_amount, card_type } = payment;
    //     if (!card_type) {
    //       if (!acc[pay_type]) {
    //         acc[pay_type] = 0;
    //       }
    //       acc[pay_type] += parseFloat(pay_amount);
    //     }
    //     return acc;
    //   },
    //   {}
    // );

    // // return Object.keys(groupedPayments).map((key) => ({
    // //   pay_type: key,
    // //   total_amount: groupedPayments[key].toFixed(2),
    // // }));
    // return Object.keys(orderSummeryData?.split_payments).map((key) => ({
    //   pay_type: orderSummeryData?.split_payments[key]?.pay_type,
    //   total_amount: orderSummeryData?.split_payments[key]?.pay_amount,
    // }));
    const filteredPayments = orderSummeryData?.split_payments?.filter(
      (payment) => !payment.card_type
    );

    // Aggregate total amounts by pay_type
    return filteredPayments?.map((payment) => ({
      pay_type: payment.pay_type,
      total_amount: payment.pay_amount,
    }));
  };

  const getPaymentMethodEBT = (orderSummeryData) => {
    const groupedPayments = orderSummeryData?.split_payments?.reduce(
      (acc, payment) => {
        let {
          card_type,
          pay_amount,
          last_four_digit,
          pax_details,
          cash_back_amt,
        } = payment;
        if (card_type) {
          if (
            card_type.startsWith("credit/") ||
            card_type.startsWith("Credit/")
          ) {
            card_type = "Credit Card";
          }
          if (card_type.startsWith("Debit/")) {
            card_type = "Debit Card";
          }

          /*if (!acc[card_type]) {
            acc[card_type] = {
              total_amount: 0,
              details: [],
              cash_back_amt
            };
          }
          acc[card_type].total_amount += parseFloat(pay_amount);
          acc[card_type].details.push({
            last_four_digit,
            pax_details,
            cash_back_amt
          });*/
          const key = `${card_type}_${pay_amount}`;

          if (!acc[key]) {
            acc[key] = {
              card_type,
              total_amount: parseFloat(pay_amount),
              details: [],
            };
          }
          acc[key].details.push({
            last_four_digit,
            pax_details,
            cash_back_amt,
          });
        }
        return acc;
      },
      {}
    );
    const result = Object.keys(groupedPayments).map((key) => ({
      card_type: key,
      total_amount: groupedPayments[key]?.total_amount.toFixed(2),
      details: groupedPayments[key].details,
    }));
    console.log("result", result);
    return result;
  };

  const showIdLength = () => {
    if (isMobile) {
      return "15";
    } else {
      return "30";
    }
  };
  // // for Gross Sale
  // const calculateTotal = (cartData) => {
  //   return cartData?.reduce((total, item) => {
  //     const itemTotal = item.is_refunded === "1"
  //       ? parseFloat(item?.refund_qty * item?.inventory_price -item?.adjust_price - item?.discount_amt)
  //       : parseFloat(item?.qty * item?.inventory_price -item?.adjust_price - item?.discount_amt);

  //     return total + itemTotal;
  //   }, 0).toFixed(2);
  // };

  // const totalCartPrice = calculateTotal(orderSummeryData?.cart_data);

  const totalTaxAfterDiscount = orderSummeryData?.cart_data
    ? orderSummeryData.cart_data
        .reduce((sum, item) => {
          const defaultTaxAmount = parseFloat(item.default_tax_amount) || 0;
          if (defaultTaxAmount > 0) {
            const price = parseFloat(item.price) || 0;
            const discount = parseFloat(item.discount_amt) || 0;
            sum += price - discount; // Add both tax and price-discount
          }
          return sum;
        }, 0)
        .toFixed(2)
    : "0.00";

  const totalOtherTaxes = orderSummeryData?.cart_data
    ? orderSummeryData.cart_data
        .reduce((sum, item) => {
          let otherTaxes = {};
          if (item?.other_taxes_desc) {
            try {
              otherTaxes = JSON.parse(item.other_taxes_desc);
            } catch (error) {
              console.error(
                "Error parsing other_taxes_desc:",
                item.other_taxes_desc,
                error
              );
            }
          }
          const taxSum = Object.values(otherTaxes).reduce((subSum, value) => {
            return subSum + (parseFloat(value) || 0); // Ensure the value is a number
          }, 0);
          return sum + taxSum;
        }, 0)
        .toFixed(2)
    : "0.00";

  const calculateNetSale = (orderSummeryData, couponDetails) => {
    let netsale = orderSummeryData?.order_detail?.subtotal
      ? parseFloat(orderSummeryData.order_detail.subtotal)
      : 0;
    // let totalCartPrice = calculateTotal(orderSummeryData?.cart_data);
    // let netsale = parseFloat(totalCartPrice || 0);
    if (couponDetails?.loyalty_point_amt_spent > 0) {
      netsale -= parseFloat(couponDetails.loyalty_point_amt_spent);
    }
    if (couponDetails?.coupon_code_amt > 0) {
      netsale -= parseFloat(couponDetails?.coupon_code_amt);
    }
    if (orderSummeryData?.order_detail?.tip) {
      if (+orderSummeryData?.order_detail?.tip > 0) {
        // netsale += parseFloat(orderSummeryData?.order_detail?.tip) || 0;
      } else if (+orderSummeryData?.order_detail?.tip < 0) {
        netsale -= parseFloat(orderSummeryData?.order_detail?.tip) || 0;
      }
    }
    return parseFloat(netsale).toFixed(2);
  };
  const netsale = useMemo(() => {
    return calculateNetSale(orderSummeryData, couponDetails);
  }, [orderSummeryData, couponDetails]);

  const calculateNCA = (orderSummeryData) => {
    let total = 0;
    if (orderSummeryData?.order_detail?.cash_discounting > 0) {
      total +=
        parseFloat(orderSummeryData?.order_detail?.cash_discounting) || 0;
    }
    const totalNCARefund = orderSummeryData?.refund_data?.reduce(
      (total, item) => {
        const NCA = parseFloat(item?.nca_amt || 0);
        return total + NCA;
      },
      0
    );
    total += totalNCARefund;
    return total.toFixed(2); // Return the total as a fixed decimal string
  };
  const NCA = useMemo(() => {
    return calculateNCA(orderSummeryData);
  }, [orderSummeryData]);

  const calculateOtherFee = (orderSummeryData) => {
    let otherfee = 0;
    if (NCA > 0) {
      otherfee += parseFloat(NCA) || 0;
    }
    if (orderSummeryData?.order_detail?.del_fee > 0) {
      otherfee += parseFloat(orderSummeryData?.order_detail.del_fee) || 0;
    }
    if (orderSummeryData?.order_detail?.con_fee > 0) {
      otherfee += parseFloat(orderSummeryData?.order_detail.con_fee) || 0;
    }
    if (orderSummeryData?.order_detail?.tip) {
      otherfee += parseFloat(orderSummeryData?.order_detail?.tip) || 0;
    }
    if (+orderSummeryData?.order_detail?.tip_refund_amount > 0) {
      otherfee +=
        parseFloat(orderSummeryData?.order_detail?.tip_refund_amount) || 0;
    }
    if (orderSummeryData?.order_detail?.cash_back_fee > 0) {
      otherfee +=
        parseFloat(orderSummeryData?.order_detail?.cash_back_fee) || 0;
    }
    return parseFloat(otherfee).toFixed(2); // Ensure it's formatted to 2 decimal places
  };
  const otherfee = useMemo(() => {
    return calculateOtherFee(orderSummeryData);
  }, [orderSummeryData]);

  const calculateTotalTaxes = (orderSummeryData, totalOtherTaxes) => {
    if (!orderSummeryData?.cart_data) {
      return "0.00";
    }
    // const defaultTaxTotal = orderSummeryData.cart_data.reduce(
    //   (total, item) => total + parseFloat(item?.default_tax_amount || 0),
    //   0
    // );
    const defaultTaxTotal = parseFloat(
      orderSummeryData?.order_detail?.tax || 0
    );

    const total = defaultTaxTotal + parseFloat(totalOtherTaxes || 0);
    return parseFloat(total).toFixed(2);
  };

  const totalTaxes = useMemo(() => {
    return calculateTotalTaxes(orderSummeryData, totalOtherTaxes);
  }, [orderSummeryData, totalOtherTaxes]);
  const calculateGrandTotal = (netsale, totalTaxes, otherfee) => {
    const net = parseFloat(netsale || 0);
    const taxes = parseFloat(totalTaxes || 0);
    const fee = parseFloat(otherfee || 0);
    const total = net + taxes + fee;
    return total.toFixed(2); // Return formatted total
  };
  const grandTotal = useMemo(() => {
    return calculateGrandTotal(netsale, totalTaxes, otherfee);
  }, [netsale, totalTaxes, otherfee]);

  // for Refund cal start
  const refundTypes = [
    { key: "credit_amt", label: "Credit Card", refundTax: "credit_refund_tax" },
    { key: "cash_amt", label: "Cash", refundTax: "cash_refund_tax" },
    {
      key: "store_credit_amt",
      label: "Store Credit",
      refundTax: "store_credit_refund_tax",
    },
    // { key: "loyalty_point_amt", label: "Loyalty Points", refundTax: "loyality_refund_tax" },
    { key: "debit_amt", label: "Debit Card", refundTax: "debit_refund_tax" },
    {
      key: "giftcard_amt",
      label: "Gift Card",
      refundTax: "gift_card_refund_tax",
    },
  ];

  const refundData = orderSummeryData?.refund_data || [];

  let totalRefundAmount = 0;
  let totalRefundTax = 0;

  refundData?.forEach((refund) => {
    refundTypes.forEach(({ key, refundTax }) => {
      const amount = refund[key];
      if (amount > 0) {
        totalRefundAmount += parseFloat(amount) || 0;
        totalRefundTax += parseFloat(refund[refundTax]) || 0;
      }
    });
  });
  if (+orderSummeryData?.order_detail?.tip_refund_amount > 0) {
    totalRefundAmount +=
      parseFloat(orderSummeryData.order_detail.tip_refund_amount) || 0;
  }
  if (orderSummeryData?.order_detail?.tip) {
    if (+orderSummeryData?.order_detail?.tip > 0) {
      // netsale += parseFloat(orderSummeryData?.order_detail?.tip) || 0;
    } else if (+orderSummeryData?.order_detail?.tip < 0) {
      totalRefundAmount -= parseFloat(orderSummeryData?.order_detail?.tip) || 0;
    }
  }
  let totalNCARefund = orderSummeryData?.refund_data?.reduce((total, item) => {
    const NCA = parseFloat(item?.nca_amt || 0);
    return total + NCA;
  }, 0);
  if (totalNCARefund > 0) {
    totalRefundAmount += parseFloat(totalNCARefund) || 0;
  }
  // for Refund cal End

  function capitalizeFirstLetter(string, payemnt, status) {
    // console.log(payemnt);
    // return payemnt == "Cash" && status == "5"
    //   ? "Cancelled"
    //   : payemnt == "Cash"
    //     ? "Cash"
    //     : "Credit Card";
    return payemnt == "Cash" ? "Cash" : "Credit Card";
    // return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const calculateTenderTotal = () => {
    let total = 0;

    // Add split payment amounts
    if (orderSummeryData.split_payments?.length > 0) {
      const splitPaymentAmounts = getPaymentMethod(orderSummeryData)
        .map((op) => parseFloat(op?.total_amount || 0))
        .concat(
          getPaymentMethodEBT(orderSummeryData).map((op) =>
            parseFloat(op?.total_amount || 0)
          )
        );
      total += splitPaymentAmounts.reduce((sum, amount) => sum + amount, 0);
      const totalCashBackAmt = orderSummeryData?.split_payments.reduce((sum, payment) => {
        const cashBack = parseFloat(payment.cash_back_amt) || 0;
        return sum + cashBack;
      }, 0);
      total += totalCashBackAmt;
    } else {
      // Add main payment amount
      if (orderSummeryData?.order_detail) {
        let mainPayment = parseFloat(orderSummeryData?.order_detail?.amt || 0);

        if (couponDetails.store_credit_amt_spent > 0) {
          mainPayment -= parseFloat(couponDetails?.store_credit_amt_spent);
        }
        total += mainPayment;
      }
      if (+orderSummeryData?.order_detail?.cash_back_amt > 0) {
        total += parseFloat(orderSummeryData?.order_detail?.cash_back_amt || 0);
      }
    }
    // Add gift card amount
    if (couponDetails.gift_card_amount > 0) {
      total += parseFloat(couponDetails?.gift_card_amount);
    }

    // Add store credit amount
    if (couponDetails.store_credit_amt_spent > 0) {
      total += parseFloat(couponDetails?.store_credit_amt_spent);
    }

    // Add lottery payouts
    if (
      orderSummeryData?.order_detail?.is_online === "1" &&
      couponDetails.lottery_order_pay > 0
    ) {
      total += parseFloat(couponDetails?.lottery_order_pay);
    }

    // Add scratcher payouts
    if (
      orderSummeryData?.order_detail?.is_online === "1" &&
      couponDetails?.scratch_order_pay > 0
    ) {
      total += parseFloat(couponDetails?.scratch_order_pay);
    }
    if (+orderSummeryData?.order_detail?.cash_back_fee > 0) {
      total += parseFloat(orderSummeryData?.order_detail?.cash_back_fee || 0);
    }

    return `${formatCurrency(total.toFixed(2))}`; // Return the total as a fixed decimal string
  };

  const totalRefundPoints = orderSummeryData?.refund_data?.reduce(
    (total, item) => {
      const refundPoint = parseFloat(item?.reward_loyalty_refund_point || 0);
      return total + refundPoint;
    },
    0
  );

  return (
    <>
      {OrderSummeryDetails?.loading ? (
        <div style={setPositionLoader}>
          <div className="loaderarea">
            <Loaderfile />
          </div>
        </div>
      ) : (
        <>
          {/* <div className="headerSummery flex justify-between p-2">
            
          </div> */}

          <div className="mainSection">
            <div className="parent-container">
              <div className="flex justify-between sticky pt-3 pb-5 z-10 top-0 bg-[#fff]">
                <button className="bg-[#e1e1e1] back-button rounded px-3 py-1 cursor-pointer hidden">
                  Go back
                </button>
                <button className="bg-[#e1e1e1] back-button rounded px-3 py-1 cursor-pointer hidden">
                  Home
                </button>
              </div>
              <div className="MidMainSection">
                <div className="LeftMidMainSection">
                  {orderSummeryData &&
                  orderSummeryData.order_detail &&
                  orderSummeryData.order_detail.is_online === "0" ? (
                    ""
                  ) : (
                    <Grid
                      sx={{ mb: { xs: 0, md: 2 }, pb: { xs: 0, md: 2 } }}
                      className="left-font-header"
                    >
                      <div className="flex justify-between mb-6">
                        <h1 className="orderSummery_head">Order Status </h1>
                        <h1 className="orderSummery_head">
                          {orderSummeryData &&
                          orderSummeryData.order_detail &&
                          orderSummeryData.order_detail?.order_method?.toLowerCase() ===
                            "pickup"
                            ? "Pickup"
                            : "Delivery"}
                        </h1>
                      </div>
                      <div className="container">
                        <div className="row justify-content-center ">
                          <div className="col-12 order-status-svg">
                            <OrderSummery_pickup_delivery_status
                              orderSummeryData={orderSummeryData}
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>
                  )}
                  <h1 className="orderSummery_head">
                    {orderSummeryData &&
                    orderSummeryData.order_detail &&
                    orderSummeryData.order_detail.is_online === "0"
                      ? "In-Store Order"
                      : // : orderSummeryData.order_detail?.order_method.toLowerCase() ===
                        //     "pickup"
                        //   ? "Pickup"
                        //   : "Delivery"}
                        ""}

                    {nonrefund ? (
                      nonrefund.length > 0 &&
                      orderSummeryData.future_order_data &&
                      orderSummeryData?.future_order_data !== "NOW" ? (
                        <>
                          <div
                            style={{ marginTop: "0px" }}
                            className="OrderSummeryItemDiv"
                          >
                            <p>
                              Future Order -{" "}
                              {formatDateTimeOnly(
                                orderSummeryData?.future_order_data,
                                "future-order"
                              )}
                            </p>
                          </div>
                        </>
                      ) : null
                    ) : null}
                  </h1>
                  <Grid
                    sx={{ mt: { xs: 0, md: 2 } }}
                    className="deliverySection"
                  >
                    {nonrefund ? (
                      nonrefund.length > 0 ? (
                        <TableContainer>
                          <Table
                            sx={{ px: 0, mt: 2, mb: 2, minWidth: "380px" }}
                          >
                            <TableHead
                              sx={{
                                borderTop: "1px dashed #707070",
                                borderBottom: "1px dashed #707070",
                              }}
                            >
                              <TableRow>
                                <TableCell
                                  sx={{
                                    paddingLeft: 0,
                                    borderBottom: 0,
                                  }}
                                >
                                  <p
                                    style={{
                                      fontFamily: "QuicksandBold",
                                      fontSize: "clamp(16px, 2vw, 19px)",
                                      color: "#000000",
                                    }}
                                  >
                                    Items
                                  </p>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    borderBottom: 0,
                                    fontFamily: "QuicksandBold",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontFamily: "QuicksandBold",
                                      fontSize: "clamp(16px, 2vw, 19px)",
                                      color: "#000000",
                                    }}
                                  >
                                    Qty
                                  </p>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    paddingRight: 0,
                                    borderBottom: 0,
                                    fontFamily: "QuicksandBold",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontFamily: "QuicksandBold",
                                      fontSize: "clamp(16px, 2vw, 19px)",
                                      color: "#000000",
                                    }}
                                  >
                                    Amount
                                  </p>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {nonrefund.map((result, index) => (
                                <React.Fragment key={index}>
                                  <TableRow>
                                    <TableCell
                                      sx={{
                                        pl: 0,
                                        pb: 0,
                                        borderBottom: 0,
                                        width: {
                                          xs: "60%", // Full width on extra-small screens
                                          sm: "40%", // 60% width on small screens
                                          md: "70%",
                                        },
                                      }}
                                      height="50px"
                                    >
                                      <Grid
                                        sx={{
                                          display: "flex",
                                          gap: 2,
                                          alignItems: "center",
                                        }}
                                      >
                                        <div
                                          style={{ position: "unset" }}
                                          className="OrderSummeryProductL"
                                        >
                                          <img
                                            onError={(e) => {
                                              e.target.src =
                                                ProductDefaultImage;
                                            }}
                                            alt=""
                                            src={
                                              result?.img
                                                ? result.img?.split(",")[0]
                                                : ProductDefaultImage
                                            }
                                            className="deliveryImageSize"
                                            onLoad={() =>
                                              setImageLoading(false)
                                            }
                                          ></img>
                                        </div>
                                        <Grid
                                          container
                                          direction="column"
                                          sx={{ height: "100%" }}
                                          className="OrderSummeryProductRTop"
                                        >
                                          <Grid item>
                                            <h3 className="text-responsiveClampContentText">
                                              {result?.name}
                                            </h3>
                                            <span className="text-responsiveClampContentText">
                                              
                                              {formatCurrency(parseFloat(
                                                result?.inventory_price
                                              ).toFixed(2))}
                                              {/* ${(() => {
                                                  let adjust = parseFloat(
                                                    result?.inventory_price
                                                  ).toFixed(2);
                                                  if (+result?.adjust_price > 0) {
                                                    adjust =result?.inventory_price -result?.adjust_price;
                                                    return `${parseFloat(adjust).toFixed(2)}`;
                                                  }
                                                  if (+result?.adjust_price < 0) {
                                                    adjust =result?.inventory_price -result?.adjust_price;
                                                    return `${parseFloat(adjust).toFixed(2)}`;
                                                  }
                                                  return `${parseFloat(result?.inventory_price).toFixed(2)}`;
                                                })()} */}
                                            </span>
                                          </Grid>

                                          <Grid item>
                                            <Grid container spacing={0}>
                                              <Grid item xs={12}>
                                                {+result?.discount_amt > 0 ? (
                                                  <>
                                                    <div className="flex">
                                                      <div className="itemDiscountContainer">
                                                        <h5 className="whitespace-nowrap">
                                                          Item discount
                                                        </h5>
                                                      </div>
                                                      <div className=" itemDiscountContainer discoutCONTAMT">
                                                        <h5>
                                                          (-$
                                                          {result?.is_refunded ===
                                                          "1"
                                                            ? parseFloat(
                                                                result?.refund_qty *
                                                                  result?.discount_amt
                                                              ).toFixed(2)
                                                            : parseFloat(
                                                                result?.qty *
                                                                  result?.discount_amt
                                                              ).toFixed(2)}
                                                          )
                                                        </h5>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </Grid>
                                              <Grid item xs={12}>
                                                {+result?.adjust_price > 0 ? (
                                                  <>
                                                    <div className="flex">
                                                      <div className=" itemDiscountContainer">
                                                        <h5 className="whitespace-nowrap">
                                                          Price Override{" "}
                                                        </h5>
                                                      </div>
                                                      <div className=" itemDiscountContainer discoutCONTAMT">
                                                        <h5>
                                                          (-$
                                                          {result?.is_refunded ===
                                                          "1"
                                                            ? parseFloat(
                                                                result?.refund_qty *
                                                                  result?.adjust_price
                                                              ).toFixed(2)
                                                            : parseFloat(
                                                                result?.qty *
                                                                  result?.adjust_price
                                                              ).toFixed(2)}
                                                          )
                                                        </h5>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </Grid>
                                              <Grid item xs={12}>
                                                {+result?.adjust_price < 0 ? (
                                                  <>
                                                    <div className="flex">
                                                      <div className=" itemDiscountContainer">
                                                        <h5 className="whitespace-nowrap">
                                                          Price Override{" "}
                                                        </h5>
                                                      </div>
                                                      <div className=" itemDiscountContainer discoutCONTAMT">
                                                        <h5>
                                                          (+
                                                          {result?.is_refunded ===
                                                          "1"
                                                            ? parseFloat(
                                                                result?.refund_qty *
                                                                  result?.adjust_price
                                                              ).toFixed(2)
                                                            :  parseFloat(Math.abs(
                                                                result?.qty *
                                                                  result?.adjust_price
                                                              )).toFixed(2)}
                                                          )
                                                        </h5>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </TableCell>

                                    <TableCell
                                      sx={{
                                        borderBottom: 0,
                                        pb: 0,
                                        width: {
                                          xs: "20%", // Full width on extra-small screens
                                          sm: "30%", // 60% width on small screens
                                          md: "15%",
                                        },
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          width: "100%",
                                        }}
                                        className=""
                                      >
                                        <p className="text-responsiveClampContentText QuicksandSemiBold">
                                          {result?.is_refunded === "1"
                                            ? `${result?.refund_qty}`
                                            : `${result?.qty}`}
                                        </p>
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        borderBottom: 0,
                                        pb: 0,
                                        pr: 0,
                                        width: {
                                          xs: "20%", // Full width on extra-small screens
                                          sm: "30%", // 60% width on small screens
                                          md: "15%",
                                        },
                                      }}
                                      height="50px"
                                      align="right"
                                    >
                                      <Grid container justifyContent="flex-end">
                                        <Grid item>
                                          <p className="text-responsiveClampContentText QuicksandSemiBold">
                                                {
                                                   formatCurrency(parseFloat(
                                                    result?.qty *
                                                      result?.inventory_price -
                                                      result?.adjust_price -
                                                      result.discount_amt
                                                  ).toFixed(2))
                                                }
                                          </p>
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {/* {nonrefund ? (
                      nonrefund.length > 0 ? (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            borderTop: "1px dashed #707070",
                            borderBottom: "1px dashed #707070",
                          }}
                        >
                          <Grid container sx={{ my: 1.9 }} wrap="nowrap">
                            <Grid
                              item
                              xs={6}
                              sx={{
                                fontFamily: "QuicksandBold",
                                fontSize: "19px",
                              }}
                            >
                              <p>Items</p>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              sx={{
                                fontFamily: "QuicksandBold",
                                fontSize: "19px",
                                display: "flex",
                              }}
                            >
                              <p>Qty</p>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{
                                fontFamily: "QuicksandBold",
                                fontSize: "19px",
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              <p>Amount</p>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : null
                    ) : null}
                    {nonrefund && nonrefund.length > 0 && (
                      <>
                        <div className="OrderSummeryProductArea">
                          {nonrefund?.length
                            ? nonrefund?.map((result) => {
                                return (
                                  <div
                                    className="OrderSummeryProductDiv"
                                    key={result?.line_item_id}
                                  >
                                    <div className="OrderSummeryProductR">
                                      <div className="OrderSummeryProductL">
                                        <img
                                          onError={(e) => {
                                            e.target.src = ProductDefaultImage;
                                          }}
                                          alt=""
                                          src={
                                            result?.img
                                              ? result.img?.split(",")[0]
                                              : ProductDefaultImage
                                          }
                                          className="deliveryImageSize"
                                          onLoad={() => setImageLoading(false)}
                                        ></img>
                                      </div>
                                      <div className="OrderSummeryProductRTop">
                                        <h4>{result?.name}</h4>
                                      </div>
                                      <div className="OrderSummeryProductRBottom">
                                        <div className="OrderSummeryProductRBottomL">
                                          <span>
                                            $
                                            {(() => {
                                              let adjust = parseFloat(
                                                result?.inventory_price
                                              ).toFixed(2);
                                              if (+result?.adjust_price > 0) {
                                                adjust =
                                                  result?.inventory_price -
                                                  result?.adjust_price;
                                                return `${parseFloat(
                                                  adjust
                                                ).toFixed(2)}`;
                                              }
                                              if (+result?.adjust_price < 0) {
                                                adjust =
                                                  result?.inventory_price -
                                                  result?.adjust_price;
                                                return `${parseFloat(
                                                  adjust
                                                ).toFixed(2)}`;
                                              }
                                              return `${parseFloat(
                                                result?.inventory_price
                                              ).toFixed(2)}`;
                                            })()}
                                          </span>
                                        </div>
                                        <div className="OrderSummeryProductRBottomM">
                                          {"result?.is_refunded" === "1"
                                            ? `${result?.refund_qty}`
                                            : `${result?.qty}`}
                                        </div>
                                        <div className="OrderSummeryProductRBottomR">
                                          <span>
                                            $
                                            {"result?.is_refunded " === "1"
                                              ? parseFloat(
                                                  result?.refund_qty *
                                                    result?.inventory_price -
                                                    result?.discount_amt
                                                ).toFixed(2)
                                              : parseFloat(
                                                  result?.qty *
                                                    result?.inventory_price -
                                                    result?.discount_amt
                                                ).toFixed(2)}
                                          </span>
                                        </div>
                                        {+result?.discount_amt > 0 ? (
                                          <>
                                            <div className="OrderSummeryProductRBottomL itemDiscountContainer">
                                              <h4>Item discount</h4>
                                            </div>
                                            <div className="OrderSummeryProductRBottomR itemDiscountContainer discoutCONTAMT">
                                              <h4>
                                                -$
                                                {result?.is_refunded === "1"
                                                  ? parseFloat(
                                                      result?.refund_qty *
                                                        result?.discount_amt
                                                    ).toFixed(2)
                                                  : parseFloat(
                                                      result?.qty *
                                                        result?.discount_amt
                                                    ).toFixed(2)}
                                              </h4>
                                            </div>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {+result?.adjust_price > 0 ? (
                                          <>
                                            <div className="OrderSummeryProductRBottomL itemDiscountContainer">
                                              <h4>Price Override </h4>
                                            </div>
                                            <div className="OrderSummeryProductRBottomR itemDiscountContainer discoutCONTAMT">
                                              <h4>
                                                +$
                                                {result?.is_refunded === "1"
                                                  ? parseFloat(
                                                      result?.refund_qty *
                                                        result?.adjust_price
                                                    ).toFixed(2)
                                                  : parseFloat(
                                                      result?.qty *
                                                        result?.adjust_price
                                                    ).toFixed(2)}
                                              </h4>
                                            </div>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {+result?.adjust_price < 0 ? (
                                          <>
                                            <div className="OrderSummeryProductRBottomL itemDiscountContainer">
                                              <h4>Price Override </h4>
                                            </div>
                                            <div className="OrderSummeryProductRBottomR itemDiscountContainer discoutCONTAMT">
                                              <h4>
                                                {result?.is_refunded === "1"
                                                  ? formatCurrency(
                                                      parseFloat(
                                                        result?.refund_qty *
                                                          result?.adjust_price
                                                      ).toFixed(2)
                                                    )
                                                  : formatCurrency(
                                                      parseFloat(
                                                        result?.qty *
                                                          result?.adjust_price
                                                      ).toFixed(2)
                                                    )}
                                              </h4>
                                            </div>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : ""}
                        </div>
                      </>
                    )} */}
                    {refund && refund.length > 0 && (
                      <>
                        {/* <div className="refund-orderSummeryPage">
                          <h5 style={{ margin: "0px" }}>Refunded Products</h5>
                          <span style={{ fontSize: "14px" }}>
                            (Amount will be refunded within 48 hours)
                          </span>
                        </div> */}

                        <Grid container>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      position: "relative",
                                      paddingBottom: "8px",
                                      marginTop: "2rem",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontFamily: "QuicksandBold",
                                        fontSize: "clamp(16px, 2vw, 19px)",
                                        display: "inline-block",
                                        borderBottom: "3px solid #0A64F9",
                                        position: "relative",
                                        zIndex: "1",
                                      }}
                                    >
                                      Refunded Products
                                    </Typography>
                                    <Box
                                      sx={{
                                        zIndex: "0",
                                        position: "absolute",
                                        bottom: 8,
                                        left: 0,
                                        width: "100%",
                                        borderBottom: "1px dashed #707070",
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <TableContainer>
                          <Table
                            sx={{ px: 0, mb: 2, mt: -1.5, minWidth: "380px" }}
                          >
                            {nonrefund ? (
                              nonrefund.length === 0 ? (
                                <TableHead
                                  sx={{
                                    borderBottom: "1px dashed #707070",
                                  }}
                                >
                                  <TableRow>
                                    <TableCell
                                      sx={{
                                        paddingLeft: 0,
                                        borderBottom: 0,
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontFamily: "QuicksandBold",
                                          fontSize: "clamp(16px, 2vw, 19px)",
                                          color: "#000000",
                                        }}
                                      >
                                        Items
                                      </p>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        borderBottom: 0,
                                        fontFamily: "QuicksandBold",
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontFamily: "QuicksandBold",
                                          fontSize: "clamp(16px, 2vw, 19px)",
                                          color: "#000000",
                                        }}
                                      >
                                        Qty
                                      </p>
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      sx={{
                                        paddingRight: 0,
                                        borderBottom: 0,
                                        fontFamily: "QuicksandBold",
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontFamily: "QuicksandBold",
                                          fontSize: "clamp(16px, 2vw, 19px)",
                                          color: "#000000",
                                        }}
                                      >
                                        Amount
                                      </p>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}

                            <TableBody>
                              {refund?.length
                                ? refund?.map((result) => (
                                    <TableRow>
                                      <TableCell
                                        sx={{
                                          pl: 0,
                                          pb: 0,
                                          borderBottom: 0,
                                          width: {
                                            xs: "60%", // Full width on extra-small screens
                                            sm: "40%", // 60% width on small screens
                                            md: "70%",
                                          },
                                        }}
                                      >
                                        <Grid
                                          sx={{
                                            display: "flex",
                                            gap: 2,
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            style={{ position: "unset" }}
                                            className="OrderSummeryProductL"
                                          >
                                            <img
                                              alt=""
                                              onError={(e) => {
                                                e.target.src =
                                                  ProductDefaultImage;
                                              }}
                                              src={
                                                result?.img
                                                  ? result?.img?.split(",")[0]
                                                  : ProductDefaultImage
                                              }
                                              className="deliveryImageSize"
                                            ></img>
                                          </div>
                                          <Grid
                                            container
                                            direction="column"
                                            justifyContent="space-between"
                                            sx={{ height: "100%" }}
                                            className="OrderSummeryProductRTop"
                                          >
                                            <Grid item>
                                              <h3 className="text-responsiveClampContentText">
                                                {result?.name}
                                              </h3>
                                              <span className="text-responsiveClampContentText">
                                              {formatCurrency(parseFloat(
                                                  result?.inventory_price
                                                ).toFixed(2))}
                                                {/* {(() => {
                                                  let adjust = parseFloat(
                                                    result?.inventory_price
                                                  ).toFixed(2);
                                                  if (+result?.adjust_price > 0) {
                                                    adjust =result?.inventory_price -result?.adjust_price;
                                                    return `${parseFloat(adjust).toFixed(2)}`;
                                                  }
                                                  if (+result?.adjust_price < 0) {
                                                    adjust =result?.inventory_price -result?.adjust_price;
                                                    return `${parseFloat(adjust).toFixed(2)}`;
                                                  }
                                                  return `${parseFloat(result?.inventory_price).toFixed(2)}`;
                                                })()} */}
                                              </span>
                                            </Grid>
                                            <Grid item>
                                              <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                  {+result?.discount_amt > 0 ? (
                                                    <div className="flex">
                                                      <div className=" itemDiscountContainer">
                                                        <h5 className="whitespace-nowrap">
                                                          Item discount
                                                        </h5>
                                                      </div>
                                                      <div className="itemDiscountContainer discoutCONTAMT">
                                                        <h5>
                                                          (-$
                                                          {result?.is_refunded ===
                                                          "1"
                                                            ? parseFloat(
                                                                result?.refund_qty *
                                                                  result?.discount_amt
                                                              ).toFixed(2)
                                                            : parseFloat(
                                                                result?.qty *
                                                                  result?.discount_amt
                                                              ).toFixed(2)}
                                                          )
                                                        </h5>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Grid>
                                                <Grid item sx={12}>
                                                  {+result?.adjust_price > 0 ? (
                                                    <div className="flex">
                                                      <div className=" itemDiscountContainer">
                                                        <h5 className="whitespace-nowrap">
                                                          Price Override{" "}
                                                        </h5>
                                                      </div>
                                                      <div className=" itemDiscountContainer discoutCONTAMT">
                                                        <h5>
                                                          (-$
                                                          {result?.is_refunded ===
                                                          "1"
                                                            ? parseFloat(
                                                                result?.refund_qty *
                                                                  result?.adjust_price
                                                              ).toFixed(2)
                                                            : parseFloat(
                                                                result?.qty *
                                                                  result?.adjust_price
                                                              ).toFixed(2)}
                                                          )
                                                        </h5>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Grid>
                                                <Grid item xs={12}>
                                                  {+result?.adjust_price < 0 ? (
                                                    <div className="flex">
                                                      <div className=" itemDiscountContainer">
                                                        <h5 className="whitespace-nowrap">
                                                          Price Override{" "}
                                                        </h5>
                                                      </div>
                                                      <div className=" itemDiscountContainer discoutCONTAMT">
                                                        <h5>
                                                          (+
                                                          {result?.is_refunded ===
                                                          "1"
                                                            ? parseFloat(Math.abs(
                                                                (
                                                                  result?.refund_qty *
                                                                    result?.adjust_price
                                                                )
                                                              )).toFixed(2)
                                                            : formatCurrency(
                                                                parseFloat(
                                                                  result?.qty *
                                                                    result?.adjust_price
                                                                ).toFixed(2)
                                                              )}
                                                          )
                                                        </h5>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          borderBottom: 0,
                                          pb: 0,
                                          width: {
                                            xs: "20%", // Full width on extra-small screens
                                            sm: "30%", // 60% width on small screens
                                            md: "15%",
                                          },
                                        }}
                                      >
                                        <p
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                          className="text-responsiveClampContentText"
                                        >{`${result?.refund_qty}`}</p>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          borderBottom: 0,
                                          pb: 0,
                                          pr: 0,
                                          width: {
                                            xs: "20%", // Full width on extra-small screens
                                            sm: "30%", // 60% width on small screens
                                            md: "15%",
                                          },
                                        }}
                                        height="50px"
                                      >
                                        <Grid
                                          container
                                          direction="column"
                                          justifyContent="space-evenly"
                                          sx={{ height: "100%" }}
                                        >
                                          <Grid item sx={{ textAlign: "end" }}>
                                            <span className="text-end text-responsiveClampContentText">
                                              
                                              {/* {result?.is_refunded === '1'
                                            ? parseFloat(
                                                result?.refund_qty *
                                                  result?.price,
                                              ).toFixed(2)
                                            : parseFloat(
                                                result?.qty * result?.price,
                                              ).toFixed(2)} */}
                                              {formatCurrency(parseFloat(
                                                result?.refund_qty *
                                                  result?.inventory_price -
                                                  result?.adjust_price -
                                                  result?.discount_amt
                                              ).toFixed(2))}
                                            </span>
                                          </Grid>
                                        </Grid>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                : ""}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {/* {nonrefund ? (
                          nonrefund.length === 0 ? (
                            <Grid
                              item
                              xs={12}
                              sx={{ borderBottom: "1px dashed #707070" }}
                            >
                              <Grid
                                container
                                sx={{ my: 1, marginBottom: "1rem" }}
                                wrap="nowrap"
                              >
                                <Grid
                                  item
                                  xs={6}
                                  sx={{
                                    fontFamily: "QuicksandBold",
                                    fontSize: "19px",
                                  }}
                                >
                                  <p>Items</p>
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  sx={{
                                    fontFamily: "QuicksandBold",
                                    fontSize: "19px",
                                    display: "flex",
                                  }}
                                >
                                  <p>Qty</p>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  sx={{
                                    fontFamily: "QuicksandBold",
                                    fontSize: "19px",
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <p>Amount</p>
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : null
                        ) : null}
                        <div className="OrderSummeryProductArea">
                          {refund?.length
                            ? refund?.map((result) => {
                                return (
                                  <div
                                    className="OrderSummeryProductDiv"
                                    key={result?.line_item_id}
                                  >
                                    <div className="OrderSummeryProductR">
                                      <div className="OrderSummeryProductL">
                                        <img
                                          alt=""
                                          onError={(e) => {
                                            e.target.src = ProductDefaultImage;
                                          }}
                                          src={
                                            result?.img
                                              ? result?.img?.split(",")[0]
                                              : ProductDefaultImage
                                          }
                                          className="deliveryImageSize"
                                        ></img>
                                      </div>
                                      <div className="OrderSummeryProductRTop">
                                        <h4>{result?.name}</h4>
                                      </div>
                                      <div className="OrderSummeryProductRBottom">
                                        <div className="OrderSummeryProductRBottomL">
                                          <span>
                                            $
                                            {(() => {
                                              let adjust = parseFloat(
                                                result?.inventory_price
                                              ).toFixed(2);
                                              if (+result?.adjust_price > 0) {
                                                adjust =
                                                  result?.inventory_price -
                                                  result?.adjust_price;
                                                return `${parseFloat(
                                                  adjust
                                                ).toFixed(2)}`;
                                              }
                                              if (+result?.adjust_price < 0) {
                                                adjust =
                                                  result?.inventory_price -
                                                  result?.adjust_price;
                                                return `${parseFloat(
                                                  adjust
                                                ).toFixed(2)}`;
                                              }
                                              return `${parseFloat(
                                                result?.inventory_price
                                              ).toFixed(2)}`;
                                            })()}
                                          </span>
                                        </div>
                                        <div className="OrderSummeryProductRBottomM">
                                         
                                          {`${result?.refund_qty}`}
                                        </div>
                                        <div className="OrderSummeryProductRBottomR">
                                          <span>
                                            $
                                            
                                            {parseFloat(
                                              result?.refund_qty *
                                                result?.inventory_price -
                                                result?.discount_amt
                                            ).toFixed(2)}
                                          </span>
                                        </div>
                                        {+result?.discount_amt > 0 ? (
                                          <>
                                            <div className="OrderSummeryProductRBottomL itemDiscountContainer">
                                              <h4>Item discount</h4>
                                            </div>
                                            <div className="OrderSummeryProductRBottomR itemDiscountContainer discoutCONTAMT">
                                              <h4>
                                                -$
                                                {result?.is_refunded === "1"
                                                  ? parseFloat(
                                                      result?.refund_qty *
                                                        result?.discount_amt
                                                    ).toFixed(2)
                                                  : parseFloat(
                                                      result?.qty *
                                                        result?.discount_amt
                                                    ).toFixed(2)}
                                              </h4>
                                            </div>
                                          </>
                                        ) : (
                                          ""
                                        )}

                                        {+result?.adjust_price > 0 ? (
                                          <>
                                            <div className="OrderSummeryProductRBottomL itemDiscountContainer">
                                              <h4>Price Override </h4>
                                            </div>
                                            <div className="OrderSummeryProductRBottomR itemDiscountContainer discoutCONTAMT">
                                              <h4>
                                                +$
                                                {result?.is_refunded === "1"
                                                  ? parseFloat(
                                                      result?.refund_qty *
                                                        result?.adjust_price
                                                    ).toFixed(2)
                                                  : parseFloat(
                                                      result?.qty *
                                                        result?.adjust_price
                                                    ).toFixed(2)}
                                              </h4>
                                            </div>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {+result?.adjust_price < 0 ? (
                                          <>
                                            <div className="OrderSummeryProductRBottomL itemDiscountContainer">
                                              <h4>Price Override </h4>
                                            </div>
                                            <div className="OrderSummeryProductRBottomR itemDiscountContainer discoutCONTAMT">
                                              <h4>
                                                {result?.is_refunded === "1"
                                                  ? formatCurrency(
                                                      parseFloat(
                                                        result?.refund_qty *
                                                          result?.adjust_price
                                                      ).toFixed(2)
                                                    )
                                                  : formatCurrency(
                                                      parseFloat(
                                                        result?.qty *
                                                          result?.adjust_price
                                                      ).toFixed(2)
                                                    )}
                                              </h4>
                                            </div>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : ""}
                        </div> */}
                      </>
                    )}

                    <TableContainer>
                      <Table sx={{ px: 0, mb: 2, mt: -1.5, minWidth: "380px" }}>
                        {orderSummeryData?.cart_data ? (
                          orderSummeryData?.cart_data.length === 0 ? (
                            <TableHead
                              sx={{
                                borderBottom: "1px dashed #707070",
                              }}
                            >
                              <TableRow>
                                <TableCell
                                  sx={{
                                    paddingLeft: 0,
                                    borderBottom: 0,
                                  }}
                                >
                                  <p
                                    style={{
                                      fontFamily: "QuicksandBold",
                                      fontSize: "clamp(16px, 2vw, 19px)",
                                      color: "#000000",
                                    }}
                                  >
                                    Items
                                  </p>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    borderBottom: 0,
                                    fontFamily: "QuicksandBold",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontFamily: "QuicksandBold",
                                      fontSize: "clamp(16px, 2vw, 19px)",
                                      color: "#000000",
                                    }}
                                  >
                                    Qty
                                  </p>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    paddingRight: 0,
                                    borderBottom: 0,
                                    fontFamily: "QuicksandBold",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontFamily: "QuicksandBold",
                                      fontSize: "clamp(16px, 2vw, 19px)",
                                      color: "#000000",
                                    }}
                                  >
                                    Amount
                                  </p>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        <TableBody>
                          {orderSummeryData?.cart_data &&
                          orderSummeryData?.cart_data.length === 0 &&
                          orderSummeryData?.order_detail?.is_online === "0" &&
                          couponDetails.total_lottery_payout > 0 ? (
                            <>
                              <TableRow>
                                <TableCell
                                  sx={{
                                    pl: 0,
                                    pb: 0,
                                    borderBottom: 0,
                                    width: {
                                      xs: "60%", // Full width on extra-small screens
                                      sm: "40%", // 60% width on small screens
                                      md: "70%",
                                    },
                                  }}
                                >
                                  <Grid
                                    sx={{
                                      display: "flex",
                                      gap: 2,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction="column"
                                      justifyContent="space-between"
                                      sx={{ height: "100%" }}
                                      className="OrderSummeryProductRTop"
                                    >
                                      <Grid item>
                                        <h3 className="text-responsiveClampContentText">
                                          Lottery
                                        </h3>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: 0,
                                    pb: 0,
                                    width: {
                                      xs: "20%", // Full width on extra-small screens
                                      sm: "30%", // 60% width on small screens
                                      md: "15%",
                                    },
                                  }}
                                >
                                  <p
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                    className="text-responsiveClampContentText"
                                  ></p>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: 0,
                                    pb: 0,
                                    pr: 0,
                                    width: {
                                      xs: "20%", // Full width on extra-small screens
                                      sm: "30%", // 60% width on small screens
                                      md: "15%",
                                    },
                                  }}
                                  height="50px"
                                >
                                  <Grid
                                    container
                                    direction="column"
                                    justifyContent="space-evenly"
                                    sx={{ height: "100%" }}
                                  >
                                    <Grid item sx={{ textAlign: "end" }}>
                                      <span className="text-end text-responsiveClampContentText">
                                        
                                        {formatCurrency(parseFloat(
                                          couponDetails.total_lottery_payout
                                        ).toFixed(2))}
                                      </span>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            </>
                          ) : (
                            ""
                          )}

                          {orderSummeryData?.cart_data &&
                          orderSummeryData?.cart_data.length === 0 &&
                          orderSummeryData?.order_detail?.is_online === "0" &&
                          couponDetails.total_scratcher_payout > 0 ? (
                            <>
                              <TableRow>
                                <TableCell
                                  sx={{
                                    pl: 0,
                                    pb: 0,
                                    borderBottom: 0,
                                    width: {
                                      xs: "60%", // Full width on extra-small screens
                                      sm: "40%", // 60% width on small screens
                                      md: "70%",
                                    },
                                  }}
                                >
                                  <Grid
                                    sx={{
                                      display: "flex",
                                      gap: 2,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction="column"
                                      justifyContent="space-between"
                                      sx={{ height: "100%" }}
                                      className="OrderSummeryProductRTop"
                                    >
                                      <Grid item>
                                        <h3 className="text-responsiveClampContentText">
                                          Lottery Scratcher
                                        </h3>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: 0,
                                    pb: 0,
                                    width: {
                                      xs: "20%", // Full width on extra-small screens
                                      sm: "30%", // 60% width on small screens
                                      md: "15%",
                                    },
                                  }}
                                >
                                  <p
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                    className="text-responsiveClampContentText"
                                  ></p>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: 0,
                                    pb: 0,
                                    pr: 0,
                                    width: {
                                      xs: "20%", // Full width on extra-small screens
                                      sm: "30%", // 60% width on small screens
                                      md: "15%",
                                    },
                                  }}
                                  height="50px"
                                >
                                  <Grid
                                    container
                                    direction="column"
                                    justifyContent="space-evenly"
                                    sx={{ height: "100%" }}
                                  >
                                    <Grid item sx={{ textAlign: "end" }}>
                                      <span className="text-end text-responsiveClampContentText">
                                        
                                        {formatCurrency(parseFloat(
                                          couponDetails.total_scratcher_payout
                                        ).toFixed(2))}
                                      </span>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            </>
                          ) : (
                            ""
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12} sx={{ paddingBottom: "8px" }}>
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  position: "relative",
                                  paddingBottom: "8px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "QuicksandBold",
                                    fontSize: "clamp(16px, 2vw, 19px)",
                                    display: "inline-block",
                                    borderBottom: "3px solid #0A64F9",
                                    position: "relative",
                                    zIndex: "1",
                                  }}
                                >
                                  Order Details
                                </Typography>
                                <Box
                                  sx={{
                                    zIndex: "0",
                                    position: "absolute",
                                    bottom: 8,
                                    left: 0,
                                    width: "100%",
                                    borderBottom: "1px dashed #707070",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        className="flex justify-center OrderDetailContainer xs:px-0 sm:px-4"
                      >
                        <Grid item xs={6}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography
                                sx={{
                                  fontFamily: "QuicksandBold",
                                  fontSize: "clamp(14px, 2vw, 16px)",
                                  color: "#818181",
                                  mt: 1.5,
                                }}
                              >
                                Order Id
                              </Typography>
                              <Box
                                sx={{
                                  zIndex: "0",
                                  position: "relative",
                                  bottom: -5,
                                  left: 0,
                                  width: "100%",
                                  borderBottom: "1px solid #E9E9E9",
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                sx={{
                                  fontFamily: "QuicksandBold",
                                  fontSize: "clamp(14px, 2vw, 16px)",
                                  color: "#818181",
                                  mt: 1.5,
                                }}
                              >
                                Date
                              </Typography>
                              <Box
                                sx={{
                                  zIndex: "0",
                                  position: "relative",
                                  bottom: -5,
                                  left: 0,
                                  width: "100%",
                                  borderBottom: "1px solid #E9E9E9",
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                sx={{
                                  fontFamily: "QuicksandBold",
                                  fontSize: "clamp(14px, 2vw, 16px)",
                                  color: "#818181",
                                  mt: 1.5,
                                }}
                              >
                                Time
                              </Typography>
                              <Box
                                sx={{
                                  zIndex: "0",
                                  position: "relative",
                                  bottom: -5,
                                  left: 0,
                                  width: "100%",
                                  borderBottom: "1px solid #E9E9E9",
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                sx={{
                                  fontFamily: "QuicksandBold",
                                  fontSize: "clamp(14px, 2vw, 16px)",
                                  color: "#818181",
                                  mt: 1.5,
                                }}
                              >
                                {orderSummeryData &&
                                orderSummeryData.order_detail &&
                                orderSummeryData.order_detail.is_online === "0"
                                  ? "Employee Name"
                                  : "Order Type"}
                              </Typography>
                              <Box
                                sx={{
                                  zIndex: "0",
                                  position: "relative",
                                  bottom: -5,
                                  left: 0,
                                  width: "100%",
                                  borderBottom: "1px solid #E9E9E9",
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                sx={{
                                  fontFamily: "QuicksandBold",
                                  fontSize: "clamp(14px, 2vw, 16px)",
                                  color: "#818181",
                                  mt: 1.5,
                                }}
                              >
                                Total Items
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography
                                sx={{
                                  fontFamily: "QuicksandBold",
                                  fontSize: "clamp(14px, 2vw, 16px)",
                                  color: "#000000",
                                  mt: 1.5,
                                }}
                                className="text-right"
                              >
                                {orderSummeryData &&
                                  orderSummeryData.order_detail &&
                                  orderSummeryData.order_detail?.order_id}
                              </Typography>
                              <Box
                                sx={{
                                  zIndex: "0",
                                  position: "relative",
                                  bottom: -5,
                                  left: 0,
                                  width: "100%",
                                  borderBottom: "1px solid #E9E9E9",
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                sx={{
                                  fontFamily: "QuicksandBold",
                                  fontSize: "clamp(14px, 2vw, 16px)",
                                  color: "#000000",
                                  mt: 1.5,
                                }}
                                className="text-right"
                              >
                                {orderSummeryData &&
                                  orderSummeryData.order_detail &&
                                  formatDateOnly(
                                    orderSummeryData.order_detail.date_time
                                  )}
                              </Typography>
                              <Box
                                sx={{
                                  zIndex: "0",
                                  position: "relative",
                                  bottom: -5,
                                  left: 0,
                                  width: "100%",
                                  borderBottom: "1px solid #E9E9E9",
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                sx={{
                                  fontFamily: "QuicksandBold",
                                  fontSize: "clamp(14px, 2vw, 16px)",
                                  color: "#000000",
                                  mt: 1.5,
                                }}
                                className="text-right"
                              >
                                {orderSummeryData &&
                                  orderSummeryData.order_detail &&
                                  formatTimeOnly(
                                    orderSummeryData.order_detail.date_time
                                  )}
                              </Typography>
                              <Box
                                sx={{
                                  zIndex: "0",
                                  position: "relative",
                                  bottom: -5,
                                  left: 0,
                                  width: "100%",
                                  borderBottom: "1px solid #E9E9E9",
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              {orderSummeryData &&
                              orderSummeryData.order_detail &&
                              orderSummeryData.order_detail.is_online ===
                                "0" ? (
                                <Typography
                                  sx={{
                                    fontFamily: "QuicksandBold",
                                    fontSize: "clamp(14px, 2vw, 16px)",
                                    color: "#000000",
                                    mt: 1.5,
                                    textTransform: "uppercase",
                                  }}
                                  className="text-right"
                                >
                                  {couponDetails?.employee_name
                                    ? couponDetails?.employee_name
                                    : "Employee Name"}
                                </Typography>
                              ) : (
                                <Typography
                                  sx={{
                                    fontFamily: "QuicksandBold",
                                    fontSize: "clamp(14px, 2vw, 16px)",
                                    color: "#D80B51",
                                    mt: 1.5,
                                    textTransform: "uppercase",
                                  }}
                                  className="text-right"
                                >
                                  {orderSummeryData.order_detail?.order_method?.toLowerCase() ===
                                  "pickup"
                                    ? "Pickup"
                                    : "Delivery"}
                                </Typography>
                              )}
                              <Box
                                sx={{
                                  zIndex: "0",
                                  position: "relative",
                                  bottom: -5,
                                  left: 0,
                                  width: "100%",
                                  borderBottom: "1px solid #E9E9E9",
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                sx={{
                                  fontFamily: "QuicksandBold",
                                  fontSize: "clamp(14px, 2vw, 16px)",
                                  color: "#000000",
                                  mt: 1.5,
                                }}
                                className="text-right"
                              >
                                {orderSummeryData &&
                                  orderSummeryData.cart_data &&
                                  (() => {
                                    const totalQty =
                                      orderSummeryData.cart_data.reduce(
                                        (sum, item) =>
                                          sum +
                                          Number(item?.qty) +
                                          Number(item?.refund_qty),
                                        0
                                      );
                                    return totalQty;
                                  })()}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container sx={{ marginTop: "1rem" }}>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid container sx={{ mt: 2 }}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  position: "relative",
                                  paddingBottom: "8px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "QuicksandBold",
                                    fontSize: "clamp(16px, 2vw, 19px)",
                                    display: "inline-block",
                                    borderBottom: "3px solid #0A64F9",
                                    position: "relative",
                                    zIndex: "1",
                                  }}
                                  className="orderSummery_head"
                                >
                                  Order Summary
                                </Typography>
                                <Box
                                  sx={{
                                    zIndex: "0",
                                    position: "absolute",
                                    bottom: 8,
                                    left: 0,
                                    width: "100%",
                                    borderBottom: "1px dashed #707070",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        className="OrderDetailContainer xs:px-0 sm:px-4"
                      >
                        <div className="OrderSummeryItemArea OrderSummaryItemDetail">
                          <div className="OrderSummeryItemDiv subtotalmargin">
                            {/* <div className="px-4"> */}
                            <p className="OrderSummaryBox_Shadow blackclr">
                              Gross Sale
                              <span className="pr-4">
                                
                                {orderSummeryData &&
                                  orderSummeryData.order_detail &&
                                  formatCurrency(parseFloat(
                                    orderSummeryData.order_detail.subtotal
                                  ).toFixed(2))}
                                {/* {orderSummeryData &&
                                  orderSummeryData?.order_detail &&
                                  parseFloat(totalCartPrice).toFixed(2)} */}
                              </span>
                            </p>

                            <Box
                              sx={{
                                zIndex: "0",
                                position: "relative",
                                bottom: 8,
                                left: 0,
                                width: "100%",
                                borderBottom: "1px dashed #707070",
                                marginTop: "1rem",
                              }}
                            />
                            <p className="redclr">
                              Loyalty Points Redeemed (
                              {parseFloat(
                                couponDetails.loyalty_point_spent
                              ).toFixed(2)}
                              )
                              <span className="pr-4">
                                -$
                                {parseFloat(
                                  couponDetails.loyalty_point_amt_spent
                                ).toFixed(2)}
                              </span>
                            </p>
                            <p className="redclr">
                              Discount
                              <span className="pr-4">
                                -$
                                {couponDetails &&
                                  parseFloat(
                                    couponDetails?.coupon_code_amt || 0
                                  ).toFixed(2)}
                              </span>
                            </p>
                            <Box
                              sx={{
                                zIndex: "0",
                                position: "relative",
                                bottom: 8,
                                left: 0,
                                width: "100%",
                                borderBottom: "1px dashed #707070",
                                marginTop: "1rem",
                              }}
                            />
                            <p className="blackclr">
                              Net Sale
                              <span className="pr-4">{`${formatCurrency(netsale)}`} </span>
                            </p>
                            {/* </div> */}
                            {/* start Taxes */}
                            <TableContainer>
                              <StyledTable aria-label="customized table">
                                <TableHead className="TaxesTable">
                                  <StyledTableCell>Taxes</StyledTableCell>
                                  <StyledTableCell>Rate</StyledTableCell>
                                  <StyledTableCell sx={{ textAlign: "center" }}>
                                    Taxable Amt
                                  </StyledTableCell>
                                  <StyledTableCell sx={{ textAlign: "right" }}>
                                    Sales Tax Due
                                  </StyledTableCell>
                                </TableHead>
                                <TableBody>
                                  {+orderSummeryData?.order_detail?.tax > 0 ? (
                                    <>
                                      <StyledTableRow>
                                        <StyledTableCell>
                                          <span className="tablecellColor">
                                            Sales Tax
                                          </span>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {orderSummeryData &&
                                            orderSummeryData.order_detail &&
                                            parseFloat(
                                              orderSummeryData?.order_detail
                                                ?.tax_rate || 0
                                            ).toFixed(3)}
                                          %
                                        </StyledTableCell>
                                        <StyledTableCell
                                          sx={{ textAlign: "center" }}
                                        >
                                          
                                          {formatCurrency(parseFloat(
                                            totalTaxAfterDiscount
                                          ).toFixed(2))}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          sx={{ textAlign: "right" }}
                                        >
                                          {/* ${orderSummeryData?.cart_data
                                    ? orderSummeryData?.cart_data.reduce(
                                        (total, item) => total + parseFloat(item.default_tax_amount || 0),
                                        0
                                      ).toFixed(2) 
                                    : 0.00} */}
                                          
                                          {orderSummeryData &&
                                            orderSummeryData.order_detail &&
                                            formatCurrency(parseFloat(
                                              orderSummeryData?.order_detail
                                                ?.tax || 0
                                            ).toFixed(2))}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    </>
                                  ) : (
                                    <>
                                      <StyledTableRow>
                                        <StyledTableCell>
                                          <span className="tablecellColor">
                                            Sales Tax
                                          </span>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {orderSummeryData &&
                                            orderSummeryData.order_detail &&
                                            parseFloat(
                                              orderSummeryData?.order_detail
                                                ?.tax_rate || 0
                                            ).toFixed(3)}
                                          %
                                        </StyledTableCell>
                                        <StyledTableCell
                                          sx={{ textAlign: "center" }}
                                        >
                                          
                                          {formatCurrency(parseFloat(
                                            totalTaxAfterDiscount
                                          ).toFixed(2))}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          sx={{ textAlign: "right" }}
                                        >
                                          {/* ${orderSummeryData?.cart_data
                                    ? orderSummeryData?.cart_data.reduce(
                                        (total, item) => total + parseFloat(item.default_tax_amount || 0),
                                        0
                                      ).toFixed(2) 
                                    : 0.00} */}
                                          
                                          {orderSummeryData &&
                                            orderSummeryData.order_detail &&
                                            formatCurrency(parseFloat(
                                              orderSummeryData?.order_detail
                                                ?.tax || 0
                                            ).toFixed(2))}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    </>
                                  )}

                                  {orderSummeryData?.order_detail
                                    ?.other_taxes_rate_desc
                                    ? getOtherTaxes(
                                        JSON.parse(
                                          orderSummeryData?.order_detail
                                            ?.other_taxes_rate_desc
                                        ),
                                        orderSummeryData?.cart_data
                                      )
                                    : ""}
                                  <StyledTableRow>
                                    <StyledTableCell
                                      colSpan={4}
                                      sx={{ padding: 0 }}
                                    >
                                      <Box
                                        sx={{
                                          zIndex: "0",
                                          position: "relative",
                                          bottom: 0,
                                          left: 0,
                                          width: "100%",
                                          borderBottom: "1px dashed #707070",
                                        }}
                                      />
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell
                                      sx={{ padding: 1, paddingLeft: 2 }}
                                    >
                                      Total Tax
                                    </StyledTableCell>
                                    <StyledTableCell
                                      sx={{ padding: 1 }}
                                    ></StyledTableCell>
                                    <StyledTableCell
                                      sx={{ padding: 1 }}
                                    ></StyledTableCell>
                                    <StyledTableCell
                                      sx={{
                                        padding: 1,
                                        paddingRight: 2,
                                        textAlign: "right",
                                      }}
                                    >
                                      {`${formatCurrency(totalTaxes)}`}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell
                                      colSpan={4}
                                      sx={{ padding: 0 }}
                                    >
                                      <Box
                                        sx={{
                                          zIndex: "0",
                                          position: "relative",
                                          bottom: 0,
                                          left: 0,
                                          width: "100%",
                                          borderBottom: "1px dashed #707070",
                                          marginTop: "0.21rem",
                                          marginBottom: "0.45rem",
                                        }}
                                      />
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </TableBody>
                              </StyledTable>
                            </TableContainer>
                            {/* End Taxes */}

                            {/* start Order Fee  */}
                            <TableContainer sx={{ margin: "1rem 0rem" }}>
                              <StyledTable aria-label="customized table">
                                <TableHead className="TaxesTable">
                                  <StyledTableCell>Other Fees</StyledTableCell>
                                  <StyledTableCell sx={{ textAlign: "right" }}>
                                    Amount
                                  </StyledTableCell>
                                </TableHead>
                                <TableBody>
                                  {/* {NCA > 0 ? (
                                    <StyledTableRow>
                                      <StyledTableCell>
                                        <span className="tablecellColor">
                                          Non-Cash Adjustment Fees
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        sx={{ textAlign: "right" }}
                                      >
                                        $
                                        {orderSummeryData &&
                                          orderSummeryData.order_detail &&
                                          parseFloat(NCA).toFixed(2)}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ) : (
                                    ""
                                  )} */}

                                  {/* For New NCA start  */}
                                  {NCA > 0 &&
                                  orderSummeryData.split_payments?.length >
                                    0 ? (
                                    <>
                                      {orderSummeryData.split_payments.map(
                                        (payment, index) => {
                                          const { card_type } = payment || {};
                                          if (!card_type) return null;
                                          let paymentMethodDisplay = "";

                                          if (card_type) {
                                            if (
                                              card_type.startsWith("Credit/") ||
                                              card_type.startsWith("credit/")
                                            ) {
                                              paymentMethodDisplay = "Credit";
                                            } else if (
                                              card_type.startsWith("Debit/") ||
                                              card_type.startsWith("debit/")
                                            ) {
                                              paymentMethodDisplay = "Debit";
                                            }
                                          }
                                          return (
                                            <StyledTableRow key={index}>
                                              <StyledTableCell>
                                                <span className="tablecellColor">
                                                  Non-Cash Adjustment Fee (
                                                  {paymentMethodDisplay})
                                                </span>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                sx={{ textAlign: "right" }}
                                              >
                                                
                                                {formatCurrency(parseFloat(
                                                  payment.cash_discounting_amount
                                                ).toFixed(2))}
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : (
                                    (() => {
                                      const { card_type } =
                                        orderSummeryData?.order_detail || {};
                                      let paymentMethodDisplay = "";
                                      if (card_type) {
                                        if (
                                          card_type.startsWith("credit/") ||
                                          card_type.startsWith("Credit/")
                                        ) {
                                          paymentMethodDisplay = "Credit";
                                        } else if (
                                          card_type.startsWith("Debit")
                                        ) {
                                          paymentMethodDisplay = "Debit";
                                        }
                                      }
                                      return (
                                        <>
                                          {NCA > 0 && (
                                            <StyledTableRow>
                                              <StyledTableCell>
                                                <span className="tablecellColor">
                                                  Non-Cash Adjustment Fee (
                                                  {paymentMethodDisplay})
                                                </span>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                sx={{ textAlign: "right" }}
                                              >
                                                
                                                {orderSummeryData?.order_detail &&
                                                  formatCurrency(parseFloat(NCA).toFixed(2))}
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          )}
                                        </>
                                      );
                                    })()
                                  )}
                                  {/* For New NCA End  */}

                                  {orderSummeryData &&
                                  +orderSummeryData?.order_detail?.del_fee >
                                    0 ? (
                                    <StyledTableRow>
                                      <StyledTableCell>
                                        <span className="tablecellColor">
                                          Delivery Fee
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        sx={{ textAlign: "right" }}
                                      >
                                        
                                        {formatCurrency(parseFloat(
                                          orderSummeryData?.order_detail
                                            ?.del_fee || 0
                                        ).toFixed(2))}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ) : (
                                    ""
                                  )}

                                  {orderSummeryData &&
                                  orderSummeryData.order_detail ? (
                                    <StyledTableRow>
                                      <StyledTableCell>
                                        <span className="tablecellColor">
                                          Cash Back Fees
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        sx={{ textAlign: "right" }}
                                      >
                                        
                                        {formatCurrency(parseFloat(
                                          orderSummeryData?.order_detail
                                            ?.cash_back_fee || 0
                                        ).toFixed(2))}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ) : (
                                    ""
                                  )}
                                  {orderSummeryData &&
                                    orderSummeryData.order_detail &&
                                    orderSummeryData?.order_detail?.con_fee &&
                                    parseFloat(
                                      orderSummeryData?.order_detail?.con_fee
                                    ) > 0 && (
                                      <StyledTableRow>
                                        <StyledTableCell>
                                          <span className="tablecellColor">
                                            Convenience Fee
                                          </span>
                                        </StyledTableCell>
                                        <StyledTableCell
                                          sx={{ textAlign: "right" }}
                                        >
                                          
                                          {formatCurrency(parseFloat(
                                            orderSummeryData?.order_detail
                                              ?.con_fee
                                          ).toFixed(2))}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    )}

                                  {/* { +orderSummeryData?.order_detail?.cash_back_fee > 0 ? (
                                <StyledTableRow>
                                <StyledTableCell ><span className="tablecellColor">Cash Back Fees</span></StyledTableCell>
                                <StyledTableCell sx={{ textAlign: "right" }}>
                                  ${parseFloat(orderSummeryData?.order_detail?.cash_back_fee).toFixed(2)}
                                  </StyledTableCell>
                              </StyledTableRow>
                              ) : ("")} */}
                                  {/* {orderSummeryData?.order_detail?.tip &&
                                  parseFloat(orderSummeryData?.order_detail?.tip) > 0 ? ( */}
                                  <StyledTableRow>
                                    <StyledTableCell>
                                      <span className="tablecellColor">
                                        Tips
                                      </span>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      sx={{ textAlign: "right" }}
                                    >
                                      {/* ${parseFloat(orderSummeryData?.order_detail?.tip).toFixed(2)} */}
                                      {(() => {
                                        let TipTotal = 0;
                                        if (
                                          orderSummeryData?.order_detail?.tip
                                        ) {
                                          TipTotal +=
                                            parseFloat(
                                              orderSummeryData?.order_detail
                                                ?.tip
                                            ) || 0;
                                        }
                                        if (
                                          +orderSummeryData?.order_detail
                                            ?.tip_refund_amount > 0
                                        ) {
                                          TipTotal +=
                                            parseFloat(
                                              orderSummeryData.order_detail
                                                .tip_refund_amount
                                            ) || 0;
                                        }
                                        return `$${TipTotal.toFixed(2)}`;
                                      })()}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  {/* ) : ("")}  */}
                                  {/* <StyledTableRow>
                              <StyledTableCell ><span className="tablecellColor">Check Cashing Fees</span></StyledTableCell>
                              <StyledTableCell sx={{ textAlign: "right" }}>$0.00</StyledTableCell>
                            </StyledTableRow> */}
                                  <StyledTableRow>
                                    <StyledTableCell
                                      colSpan={4}
                                      sx={{ padding: 0 }}
                                    >
                                      <Box
                                        sx={{
                                          zIndex: "0",
                                          position: "relative",
                                          left: 0,
                                          width: "100%",
                                          borderBottom: "1px dashed #707070",
                                        }}
                                      />
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell
                                      sx={{ padding: 1, paddingLeft: 2 }}
                                    >
                                      Total
                                    </StyledTableCell>

                                    <StyledTableCell
                                      sx={{
                                        padding: 1,
                                        paddingRight: 2,
                                        textAlign: "right",
                                      }}
                                    >
                                      {`${formatCurrency(otherfee)}`}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell
                                      colSpan={4}
                                      sx={{ padding: 0 }}
                                    >
                                      <Box
                                        sx={{
                                          zIndex: "0",
                                          position: "relative",
                                          bottom: 0,
                                          left: 0,
                                          width: "100%",
                                          borderBottom: "1px dashed #707070",
                                        }}
                                      />
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </TableBody>
                              </StyledTable>
                            </TableContainer>
                            {/* End Order Fee */}

                            {/* {
                        orderSummeryData.order_detail?.cash_discounting > 0 ?
                      <p>
                        NCA
                        <span>
                          $
                          {orderSummeryData &&
                            orderSummeryData.order_detail &&
                            parseFloat(
                              orderSummeryData.order_detail?.cash_discounting
                            ).toFixed(2)}
                        </span>
                      </p>
                      :""
                      } */}
                            {/* {orderSummeryData?.order_detail?.del_fee ||
                        parseFloat(orderSummeryData?.order_detail?.del_fee) >
                        0 ? (
                        " "
                      ) : (
                        <p>
                          Delivery Fee
                          <span>
                            $
                            {parseFloat(
                              orderSummeryData &&
                              orderSummeryData.order_detail &&
                              orderSummeryData.order_detail.del_fee
                            ).toFixed(2)}
                          </span>
                        </p>
                      )} */}

                            {/* {
                      //  !!orderSummeryData.order_detail?.discount && +orderSummeryData.order_detail?.discount > 0
                       !!couponDetails?.coupon_code && couponDetails?.coupon_code_amt > 0 ? (
                        // couponDetails?.coupon_code_amt + couponDetails?.loyalty_point_amt_spent > 0 ? (
                        <>
                        <p>
                        {couponDetails?.coupon_code === "Discount" ?(
                            <>
                            {couponDetails?.coupon_code}
                            </>
                          ):(
                            <>
                             Coupon ({couponDetails?.coupon_code})
                            </>
                          )
                        }
                        <span>
                          - $
                          {couponDetails &&
                            couponDetails?.coupon_code_amt &&
                            parseFloat(
                              couponDetails?.coupon_code_amt
                            ).toFixed(2)}
                        </span>
                      </p>
                      </>
                        ):(
                      <>
                      {couponDetails.coupon_code !== "" &&
                        !!couponDetails.coupon_code_amt ? (
                        <p>
                          Coupon ({couponDetails.coupon_code})
                          <span>
                            $
                            {parseFloat(couponDetails.coupon_code_amt).toFixed(
                              2
                            )}
                          </span>
                        </p>
                      ) : (
                        ""
                      )}
                      </>
                        )
                      } */}

                            {/* {orderSummeryData.order_detail?.loyalty_point_spent !== "" &&
                        +orderSummeryData.order_detail?.loyalty_point_spent > 0 ? (
                        <p>
                          Points Applied ()
                          <span>
                            $
                            {parseFloat( orderSummeryData.order_detail?.loyalty_point_spent).toFixed(
                              2
                            )}
                          </span>
                        </p>
                      ) : (
                        ""
                      )} */}

                            {/* {orderSummeryData &&
                        orderSummeryData.order_detail &&
                        orderSummeryData.order_detail.con_fee &&
                        parseFloat(orderSummeryData.order_detail.con_fee) >
                        0 && (
                          <p>
                            Convenience Fee
                            <span>
                              $
                              {parseFloat(
                                orderSummeryData.order_detail.con_fee
                              ).toFixed(2)}
                            </span>
                          </p>
                        )} */}
                            {/* {
                        +orderSummeryData?.order_detail?.tax > 0 ? (
                          <>
                          <p>
                        Sales Tax (
                        {orderSummeryData &&
                          orderSummeryData.order_detail &&
                          orderSummeryData.order_detail.tax_rate}
                        %)
                        <span>
                          $
                          {orderSummeryData &&
                            orderSummeryData?.order_detail &&
                            orderSummeryData?.order_detail?.tax &&
                            parseFloat(
                              orderSummeryData?.order_detail?.tax
                            ).toFixed(2)}
                        </span>
                      </p>
                          </>
                        ):("")} */}
                            {/* {orderSummeryData?.order_detail?.tip &&
                        parseFloat(orderSummeryData?.order_detail?.tip) > 0 ? (
                        <p>
                          Tip{" "}
                          <span>
                            $
                            {parseFloat(
                              orderSummeryData?.order_detail?.tip
                            ).toFixed(2)}
                          </span>
                        </p>
                      ) : ("")} */}
                            {/* {orderSummeryData?.order_detail?.other_taxes_desc
                        ? getOtherTaxes(
                          JSON.parse(
                            orderSummeryData?.order_detail?.other_taxes_desc
                          )
                        ): ""} */}
                            {/* {orderSummeryData &&
                        orderSummeryData.order_detail &&
                        orderSummeryData.order_detail.is_refunded == "1" ? (
                        <p className="yellowclr">
                          Refunded Amount
                          <span>
                            - $
                            {orderSummeryData &&
                              orderSummeryData.order_detail &&
                              parseFloat(
                                orderSummeryData.order_detail.refund_amount
                              ).toFixed(2)}
                          </span>
                        </p>
                      ) : ("")} */}

                            <div className="blackclr marginBottomZero QuicksandSemiBold GrandTotalRow text-responsiveClampContentText flex justify-between pb-4">
                              <h5
                                style={{ fontSize: "clamp(14px, 2vw, 16px)" }}
                              >
                                Grand Total
                              </h5>
                              <span
                                style={{ fontSize: "clamp(14px, 2vw, 16px)" }}
                              >
                                Net Sale + Tax + Other Fees ={" "}
                                <span className="greenclr">
                                  &nbsp;{`${formatCurrency(grandTotal)}`}
                                </span>
                              </span>
                            </div>
                            {/* Old Grand Total Start */}
                            {/* {orderSummeryData && orderSummeryData.order_detail ? (
                        <p className="blackclr">
                          Grand Total
                          <span>
                            {(() => {
                              let grandTotal = parseFloat(
                                orderSummeryData.order_detail.amt
                              );
                              // Assuming $coupon_desc is also part of your orderSummeryData
                              if (couponDetails.loyalty_point_spent > 0) {
                                grandTotal += parseFloat(
                                  couponDetails.loyalty_point_amt_spent
                                );
                              }
                              if (couponDetails.gift_card_amount > 0) {
                                grandTotal += parseFloat(
                                  couponDetails.gift_card_amount
                                );
                              }
                              if (+orderSummeryData.order_detail?.cash_back_amt > 0) {
                                grandTotal += parseFloat(
                                  orderSummeryData.order_detail?.cash_back_amt
                                );
                              }
                              if (+orderSummeryData.order_detail?.cash_back_fee > 0) {
                                grandTotal += parseFloat(
                                  orderSummeryData.order_detail?.cash_back_fee
                                );
                              }
                              // Adjust for refund if applicable
                              if (
                                orderSummeryData.order_detail.is_refunded ===
                                "1"
                              ) {
                                grandTotal -= parseFloat(
                                  orderSummeryData.order_detail.refund_amount
                                );
                              }
                              return `$${parseFloat(grandTotal) <= 0.02 &&
                                  orderSummeryData?.order_detail?.m_status === "7"
                                  ? "0.00"
                                  : grandTotal.toFixed(2)
                                }`;
                            })()}
                          </span>
                        </p>
                      ) : (
                        ""
                      )} */}
                            {/* Old Grand Total  End*/}
                            <style>
                              {`
                    .MuiAccordion-root:before {
                      background-color: unset;
                    }
                      .moreordersummary{
                        box-shadow: none !important;
                        margin:0px !important;
                      }
                      .moreordersummary-container, .dropOrdersummary{
                        margin:0px !important;
                        padding:0px ;
                      }
                      .moreordersummary-container div:first-child{
                        margin:0px !important;
                      }
                      .moreordersummary div:first-child{
                        min-height: unset !important;
                      }
                      .moreordersummary-container .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.Mui-expanded.MuiIconButton-edgeEnd, .moreordersummary-container .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.MuiIconButton-edgeEnd{
                        margin:0px !important;
                        padding:0px !important;
                      }
                      .dropOrdersummary{
                        flex-direction:column;
                      }
                      .dropOrdersummary p{
                        width:100%;
                      }
                      .moreordersummary-container .MuiIconButton-label{
                        color: #000;
                      }
                      `}
                            </style>
                            {/* old View Details Start  */}
                            {/* {(couponDetails.loyalty_point_spent > 0 ||
                        couponDetails.store_credit_amt_spent > 0 ||
                        orderSummeryData?.order_detail ||
                        couponDetails.loyalty_point_earned > 0) && (
                          <Accordion className="moreordersummary">
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              className="moreordersummary-container ps-0"
                            >
                              <p className="blackclr pb-0"> View Details</p>
                            </AccordionSummary>
                            <AccordionDetails className="dropOrdersummary">
                              {couponDetails.loyalty_point_spent > 0 ? (
                                <p className="">
                                  Points Applied (
                                  {parseFloat(
                                    couponDetails.loyalty_point_spent
                                  ).toFixed(2)}
                                  )
                                  <span>
                                    - $
                                    {parseFloat(
                                      couponDetails.loyalty_point_amt_spent
                                    ).toFixed(2)}
                                  </span>
                                </p>
                              ) : (
                                ""
                              )}
                              {couponDetails.gift_card_amount > 0 ? (
                                <p className="">
                                  Gift Card Applied
                                  <span>
                                    - $
                                    {parseFloat(
                                      couponDetails?.gift_card_amount
                                    ).toFixed(2)}
                                  </span>
                                </p>
                              ) : (
                                ""
                              )}
                              {
                              orderSummeryData.split_payments?.length > 0 ?(
                                  <>
                                   { getPaymentMethod(orderSummeryData)?.map((op) => {
                                      return (
                                        <>
                                          <p  style={{ textTransform: 'capitalize'}}>{op?.pay_type}
                                          <span> ${op?.total_amount}</span>
                                          </p>
                                        </>
                                      );
                                    })}
                                    { getPaymentMethodEBT(orderSummeryData)?.map((op) => {
                                      const formattedCardType = op?.card_type
                                      ?.replace('CashEbt/', 'Cash EBT')
                                      ?.replace('FoodEbt/', 'Food EBT');
                                      return (
                                        <>
                                          <p  style={{ textTransform: 'capitalize'}}>{formattedCardType}
                                          <span> ${op?.total_amount}</span>
                                          </p>
                                        </>
                                      );
                                    })}
                                  </>
                                ):(
                                  <>
                                 {orderSummeryData &&
                                orderSummeryData.order_detail ? (
                                <p className="">

                                  {(() => {
                                    const { card_type } = orderSummeryData.order_detail;
                                    let paymentMethodDisplay = 'Paid via Card'; // Default message

                                    // Check if card_type exists and adjust display text
                                    if (card_type) {
                                      if (card_type.startsWith('CashEbt/')) {
                                        paymentMethodDisplay = 'Cash EBT';
                                      } else if (card_type.startsWith('FoodEbt/')) {
                                        paymentMethodDisplay = 'Food EBT';
                                      }
                                    } else if (paymentMethod && paymentMethod.toLowerCase() === 'cash') {
                                      paymentMethodDisplay = 'Paid via Cash';
                                    }

                                    return (
                                      <>
                                        {paymentMethodDisplay}
                                        <span>
                                          {(() => {
                                            let payment = parseFloat(orderSummeryData.order_detail.amt);

                                            // Adjust payment based on store credit
                                            if (couponDetails.store_credit_amt_spent > 0) {
                                              payment -= parseFloat(couponDetails.store_credit_amt_spent);
                                            }

                                            // Format and return payment amount
                                            return `$${payment.toFixed(2)}`;
                                          })()}
                                        </span>
                                      </>
                                    );
                                  })()}
                                </p>
                              ) : (
                                ""
                              )}
                                  </>
                                )
                              }

                              {couponDetails.store_credit_amt_spent > 0 ? (
                                <p className="">
                                  Paid via Store Credit{" "}
                                  <span>
                                    $
                                    {parseFloat(
                                      couponDetails.store_credit_amt_spent
                                    ).toFixed(2)}
                                  </span>
                                </p>
                              ) : (
                                ""
                              )}

                              {couponDetails?.loyalty_point_earned > 0 ? (
                                <p className="yellowclr">
                                  {" "}
                                  Points Awarded{" "}
                                  <span>
                                    {parseFloat(
                                      couponDetails?.loyalty_point_earned
                                    ).toFixed(2)}
                                  </span>
                                </p>
                              ) : (
                                ""
                              )}

                              {+orderSummeryData.order_detail?.cash_back_amt > 0 ? (
                                <p className="">
                                  {" "}
                                  Cashback Amount{" "}
                                  <span>
                                    ${parseFloat(
                                      orderSummeryData.order_detail?.cash_back_amt
                                    ).toFixed(2)}
                                  </span>
                                </p>
                              ) : (
                                ""
                              )}
                              {+orderSummeryData.order_detail.cash_back_fee > 0 ? (
                                <p className="">
                                  {" "}
                                  Cashback Fee{" "}
                                  <span>
                                    ${parseFloat(
                                      orderSummeryData.order_detail.cash_back_fee
                                    ).toFixed(2)}
                                  </span>
                                </p>
                              ) : (
                                ""
                              )}
                            </AccordionDetails>
                          </Accordion>
                      )} */}
                            {/* old View Details End  */}

                            {/* start Tenders Start  */}
                            <TableContainer sx={{ marginTop: "0rem" }}>
                              <StyledTable aria-label="customized table">
                                <TableHead className="TaxesTable">
                                  <StyledTableCell>Tenders</StyledTableCell>
                                  <StyledTableCell>Auth Code</StyledTableCell>
                                  <StyledTableCell sx={{ textAlign: "right" }}>
                                    Amount
                                  </StyledTableCell>
                                </TableHead>
                                {orderSummeryData?.cart_data ? (
                                  orderSummeryData?.cart_data.length > 0 ? (
                                    <>
                                      <TableBody>
                                        <StyledTableRow>
                                          <StyledTableCell
                                            colSpan={4}
                                            sx={{ padding: 0 }}
                                          >
                                            <Box
                                              sx={{
                                                zIndex: "0",
                                                position: "relative",
                                                bottom: 0,
                                                left: 0,
                                                width: "100%",
                                                borderBottom:
                                                  "1px solid #E9E9E9",
                                              }}
                                            />
                                          </StyledTableCell>
                                        </StyledTableRow>

                                        {/* -----------------------------for new card Details start  *---------------*/}

                                        {
                                          // for split_payments
                                          orderSummeryData.split_payments
                                            ?.length > 0 ? (
                                            <>
                                              {getPaymentMethod(
                                                orderSummeryData
                                              )?.map((op, index) => {
                                                return (
                                                  <>
                                                    <StyledTableRow key={index}>
                                                      <StyledTableCell>
                                                        <span className="tablecellColor capitalize">
                                                          {op?.pay_type}
                                                        </span>
                                                      </StyledTableCell>
                                                      <StyledTableCell></StyledTableCell>
                                                      <StyledTableCell
                                                        sx={{
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        -$
                                                        {parseFloat(
                                                          op?.total_amount
                                                        ).toFixed(2)}
                                                      </StyledTableCell>
                                                    </StyledTableRow>
                                                    <StyledTableRow>
                                                      <StyledTableCell
                                                        colSpan={4}
                                                        sx={{ padding: 0 }}
                                                      >
                                                        <Box
                                                          sx={{
                                                            zIndex: "0",
                                                            position:
                                                              "relative",
                                                            bottom: 0,
                                                            left: 0,
                                                            width: "100%",
                                                            borderBottom:
                                                              "1px solid #E9E9E9",
                                                          }}
                                                        />
                                                      </StyledTableCell>
                                                    </StyledTableRow>
                                                  </>
                                                );
                                              })}
                                              {getPaymentMethodEBT(
                                                orderSummeryData
                                              )?.map((op, index) => {
                                                const details =
                                                  Object.fromEntries(
                                                    op?.details[0]?.pax_details
                                                      .split("\n")
                                                      .map((line) =>
                                                        line
                                                          .split(":")
                                                          .map((item) =>
                                                            item.trim()
                                                          )
                                                      )
                                                  );
                                                const {
                                                  AID,
                                                  AuthCode,
                                                  CardMethod,
                                                  Last4Digit,
                                                  PaymentMode,
                                                } = details;
                                                // const formattedCardType =
                                                //   op?.card_type
                                                //     ?.replace(
                                                //       "CashEbt/",
                                                //       `Cash EBT:${op?.details[0]?.last_four_digit}`
                                                //     )
                                                //     ?.replace(
                                                //       "FoodEbt/",
                                                //       `Food EBT:${op?.details[0]?.last_four_digit}`
                                                //     )
                                                //     ?.replace(
                                                //       "Credit Card",
                                                //       `Credit Card:${op?.details[0]?.last_four_digit}`
                                                //     )
                                                //     ?.replace(
                                                //       "credit/",
                                                //       `Credit Card:${op?.details[0]?.last_four_digit}`
                                                //     )
                                                //     ?.replace(
                                                //       "Debit",
                                                //       `Debit Card:${op?.details[0]?.last_four_digit}`
                                                //     );

                                                let formattedCardType;

                                                if (
                                                  op?.card_type?.startsWith(
                                                    "CashEbt/"
                                                  )
                                                ) {
                                                  formattedCardType = `Cash EBT:${op?.details[0]?.last_four_digit}`;
                                                } else if (
                                                  op?.card_type?.startsWith(
                                                    "FoodEbt/"
                                                  )
                                                ) {
                                                  formattedCardType = `Food EBT:${op?.details[0]?.last_four_digit}`;
                                                } else if (
                                                  op?.card_type ===
                                                    "Credit Card" ||
                                                  op?.card_type?.startsWith(
                                                    "credit/"
                                                  ) ||
                                                  op?.card_type?.startsWith(
                                                    "Credit"
                                                  )
                                                ) {
                                                  formattedCardType = `Credit Card:${op?.details[0]?.last_four_digit}`;
                                                } else if (
                                                  op?.card_type === "Debit" ||
                                                  op?.card_type?.startsWith(
                                                    "Debit"
                                                  )
                                                ) {
                                                  formattedCardType = `Debit Card:${op?.details[0]?.last_four_digit}`;
                                                } else {
                                                  formattedCardType =
                                                    op?.card_type; // fallback for unhandled cases
                                                }
                                                return (
                                                  <>
                                                    <StyledTableRow key={index}>
                                                      <StyledTableCell>
                                                        <span className="tablecellColor">
                                                          {formattedCardType}
                                                          {formattedCardType.startsWith(
                                                            "Debit"
                                                          ) &&
                                                            +op?.details[0]
                                                              .cash_back_amt >
                                                              0 && (
                                                              <>
                                                                <br />
                                                                <span className="blackclr">
                                                                  +$
                                                                  {parseFloat(
                                                                    op
                                                                      ?.details[0]
                                                                      .cash_back_amt
                                                                  ).toFixed(
                                                                    2
                                                                  )}{" "}
                                                                  Cash Back
                                                                </span>
                                                              </>
                                                            )}
                                                        </span>
                                                      </StyledTableCell>
                                                      <StyledTableCell>
                                                        {AuthCode
                                                          ? AuthCode
                                                          : "N/A"}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        sx={{
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        {(() => {
                                                          let payment = parseFloat(op?.total_amount) || 0;
                                                          // Adjust payment based on store credit
                                                          if (+op?.details[0]?.cash_back_amt > 0) {
                                                            payment += parseFloat(op?.details[0]?.cash_back_amt || 0);
                                                          }
                                                          if (+orderSummeryData?.order_detail?.cash_back_fee > 0) {
                                                            payment += parseFloat(orderSummeryData?.order_detail?.cash_back_fee || 0);
                                                          }
                                                          return `-$${priceFormate(payment.toFixed(2))}`;
                                                        })()}
                                                        {/* -${op?.total_amount} */}
                                                      </StyledTableCell>
                                                    </StyledTableRow>
                                                    <StyledTableRow>
                                                      <StyledTableCell
                                                        colSpan={4}
                                                        sx={{ padding: 0 }}
                                                      >
                                                        <Box
                                                          sx={{
                                                            zIndex: "0",
                                                            position:
                                                              "relative",
                                                            bottom: 0,
                                                            left: 0,
                                                            width: "100%",
                                                            borderBottom:
                                                              "1px solid #E9E9E9",
                                                          }}
                                                        />
                                                      </StyledTableCell>
                                                    </StyledTableRow>
                                                  </>
                                                );
                                              })}
                                            </>
                                          ) : (
                                            <>
                                              {orderSummeryData &&
                                              orderSummeryData.order_detail ? (
                                                <>
                                                  <StyledTableRow>
                                                    {(() => {
                                                      const { card_type } =
                                                        orderSummeryData?.order_detail;
                                                      let paymentMethodDisplay =
                                                        "Card"; // Default message

                                                      const details =
                                                        orderSummeryData
                                                          ?.order_detail
                                                          ?.pax_details
                                                          ? Object.fromEntries(
                                                              orderSummeryData.order_detail.pax_details
                                                                .split("\n")
                                                                .map((line) =>
                                                                  line
                                                                    .split(":")
                                                                    .map(
                                                                      (item) =>
                                                                        item.trim()
                                                                    )
                                                                )
                                                            )
                                                          : {};
                                                      const {
                                                        AID,
                                                        AuthCode,
                                                        CardMethod,
                                                        Last4Digit,
                                                        PaymentMode,
                                                      } = details;

                                                      // Check if card_type exists and adjust display text
                                                      if (card_type) {
                                                        if (
                                                          card_type.startsWith(
                                                            "CashEbt/"
                                                          )
                                                        ) {
                                                          paymentMethodDisplay = `Cash EBT:${Last4Digit}`;
                                                        } else if (
                                                          card_type.startsWith(
                                                            "FoodEbt/"
                                                          )
                                                        ) {
                                                          paymentMethodDisplay = `Food EBT:${Last4Digit}`;
                                                        } else if (
                                                          card_type.startsWith(
                                                            "credit/"
                                                          )
                                                        ) {
                                                          paymentMethodDisplay = `Credit Card:${
                                                            orderSummeryData &&
                                                            orderSummeryData.order_detail &&
                                                            orderSummeryData
                                                              .order_detail
                                                              ?.card_num &&
                                                            formatCardNumber(
                                                              orderSummeryData
                                                                .order_detail
                                                                ?.card_num
                                                            )
                                                          }`;
                                                        } else if (
                                                          card_type.startsWith(
                                                            "Credit/"
                                                          )
                                                        ) {
                                                          paymentMethodDisplay = `Credit Card:${Last4Digit}`;
                                                        } else if (
                                                          card_type.startsWith(
                                                            "Debit"
                                                          )
                                                        ) {
                                                          paymentMethodDisplay = `Debit Card:${Last4Digit}`;
                                                        }
                                                      } else if (
                                                        paymentMethod &&
                                                        paymentMethod.toLowerCase() ===
                                                          "cash"
                                                      ) {
                                                        paymentMethodDisplay =
                                                          "Cash";
                                                      }
                                                      return (
                                                        <>
                                                          <StyledTableCell>
                                                            <span className="tablecellColor">
                                                              {
                                                                paymentMethodDisplay
                                                              }
                                                              {paymentMethodDisplay.startsWith(
                                                                "Debit"
                                                              ) &&
                                                                orderSummeryData
                                                                  ?.order_detail
                                                                  ?.cash_back_amt >
                                                                  0 && (
                                                                  <>
                                                                    <br />
                                                                    <span className="blackclr">
                                                                      +$
                                                                      {parseFloat(
                                                                        orderSummeryData
                                                                          ?.order_detail
                                                                          ?.cash_back_amt
                                                                      ).toFixed(
                                                                        2
                                                                      )}{" "}
                                                                      Cash Back
                                                                    </span>
                                                                  </>
                                                                )}
                                                            </span>
                                                          </StyledTableCell>
                                                          <StyledTableCell>
                                                            {paymentMethodDisplay !==
                                                              "Cash" && (
                                                              <>
                                                                {AuthCode
                                                                  ? AuthCode
                                                                  : "N/A"}
                                                              </>
                                                            )}
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            sx={{
                                                              textAlign:
                                                                "right",
                                                            }}
                                                          >
                                                            {(() => {
                                                              let payment =
                                                                parseFloat(
                                                                  orderSummeryData
                                                                    .order_detail
                                                                    .amt
                                                                );

                                                                if (+orderSummeryData?.order_detail?.cash_back_amt > 0) {
                                                                  payment += parseFloat(orderSummeryData?.order_detail?.cash_back_amt || 0);
                                                                }
                                                                if (+orderSummeryData?.order_detail?.cash_back_fee > 0) {
                                                                  payment += parseFloat(orderSummeryData?.order_detail?.cash_back_fee || 0);
                                                                }
                                                              // Adjust payment based on store credit
                                                              if (
                                                                couponDetails.store_credit_amt_spent >
                                                                0
                                                              ) {
                                                                payment -=
                                                                  parseFloat(
                                                                    couponDetails.store_credit_amt_spent
                                                                  );
                                                              }
                                                              if (
                                                                orderSummeryData
                                                                  ?.order_detail
                                                                  ?.is_online ===
                                                                  "0" &&
                                                                couponDetails.lottery_order_pay >
                                                                  0
                                                              ) {
                                                                payment -=
                                                                  parseFloat(
                                                                    couponDetails.lottery_order_pay
                                                                  );
                                                              }
                                                              if (
                                                                orderSummeryData
                                                                  ?.order_detail
                                                                  ?.is_online ===
                                                                  "0" &&
                                                                couponDetails.scratch_order_pay >
                                                                  0
                                                              ) {
                                                                payment -=
                                                                  parseFloat(
                                                                    couponDetails.scratch_order_pay
                                                                  );
                                                              }
                                                              if (payment < 0) {
                                                                return `${formatCurrency(payment.toFixed(2))}`;
                                                              } else {
                                                                return `-$${priceFormate(payment.toFixed(2))}`;
                                                              }
                                                            })()}
                                                          </StyledTableCell>
                                                        </>
                                                      );
                                                    })()}
                                                  </StyledTableRow>
                                                  <StyledTableRow>
                                                    <StyledTableCell
                                                      colSpan={4}
                                                      sx={{ padding: 0 }}
                                                    >
                                                      <Box
                                                        sx={{
                                                          zIndex: "0",
                                                          position: "relative",
                                                          bottom: 0,
                                                          left: 0,
                                                          width: "100%",
                                                          borderBottom:
                                                            "1px solid #E9E9E9",
                                                        }}
                                                      />
                                                    </StyledTableCell>
                                                  </StyledTableRow>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          )
                                        }
                                        {/*------------------------ for new card Details End------------------------------ */}

                                        <StyledTableRow>
                                          <StyledTableCell
                                            colSpan={4}
                                            sx={{ padding: 0 }}
                                          >
                                            <Box
                                              sx={{
                                                zIndex: "0",
                                                position: "relative",
                                                bottom: 0,
                                                left: 0,
                                                width: "100%",
                                                borderBottom:
                                                  "1px solid #E9E9E9",
                                              }}
                                            />
                                          </StyledTableCell>
                                        </StyledTableRow>
                                        {couponDetails.gift_card_amount > 0 ? (
                                          <>
                                            <StyledTableRow>
                                              <StyledTableCell>
                                                <span className="tablecellColor">
                                                  Gift Card
                                                </span>
                                              </StyledTableCell>
                                              <StyledTableCell></StyledTableCell>
                                              <StyledTableCell
                                                sx={{ textAlign: "right" }}
                                              >
                                                -$
                                                {parseFloat(
                                                  couponDetails?.gift_card_amount
                                                ).toFixed(2)}
                                              </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                              <StyledTableCell
                                                colSpan={4}
                                                sx={{ padding: 0 }}
                                              >
                                                <Box
                                                  sx={{
                                                    zIndex: "0",
                                                    position: "relative",
                                                    bottom: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    borderBottom:
                                                      "1px solid #E9E9E9",
                                                  }}
                                                />
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {couponDetails.store_credit_amt_spent >
                                        0 ? (
                                          <>
                                            <StyledTableRow>
                                              <StyledTableCell>
                                                <span className="tablecellColor">
                                                  Store Credit
                                                </span>
                                              </StyledTableCell>
                                              <StyledTableCell></StyledTableCell>
                                              <StyledTableCell
                                                sx={{ textAlign: "right" }}
                                              >
                                                -$
                                                {parseFloat(
                                                  couponDetails.store_credit_amt_spent
                                                ).toFixed(2)}
                                              </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                              <StyledTableCell
                                                colSpan={4}
                                                sx={{ padding: 0 }}
                                              >
                                                <Box
                                                  sx={{
                                                    zIndex: "0",
                                                    position: "relative",
                                                    bottom: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    borderBottom:
                                                      "1px solid #E9E9E9",
                                                  }}
                                                />
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          </>
                                        ) : (
                                          ""
                                        )}

                                        {orderSummeryData?.order_detail
                                          ?.is_online === "0" &&
                                        couponDetails.lottery_order_pay > 0 ? (
                                          <>
                                            <StyledTableRow>
                                              <StyledTableCell>
                                                <span className="tablecellColor">
                                                  Lottery
                                                </span>
                                              </StyledTableCell>
                                              <StyledTableCell></StyledTableCell>
                                              <StyledTableCell
                                                sx={{ textAlign: "right" }}
                                              >
                                                -$
                                                {parseFloat(
                                                  couponDetails.lottery_order_pay
                                                ).toFixed(2)}
                                              </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                              <StyledTableCell
                                                colSpan={4}
                                                sx={{ padding: 0 }}
                                              >
                                                <Box
                                                  sx={{
                                                    zIndex: "0",
                                                    position: "relative",
                                                    bottom: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    borderBottom:
                                                      "1px solid #E9E9E9",
                                                  }}
                                                />
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          </>
                                        ) : (
                                          ""
                                        )}

                                        {orderSummeryData?.order_detail
                                          ?.is_online === "0" &&
                                        couponDetails.scratch_order_pay > 0 ? (
                                          <>
                                            <StyledTableRow>
                                              <StyledTableCell>
                                                <span className="tablecellColor">
                                                  Lottery Scratcher
                                                </span>
                                              </StyledTableCell>
                                              <StyledTableCell></StyledTableCell>
                                              <StyledTableCell
                                                sx={{ textAlign: "right" }}
                                              >
                                                -$
                                                {parseFloat(
                                                  couponDetails.scratch_order_pay
                                                ).toFixed(2)}
                                              </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                              <StyledTableCell
                                                colSpan={4}
                                                sx={{ padding: 0 }}
                                              >
                                                <Box
                                                  sx={{
                                                    zIndex: "0",
                                                    position: "relative",
                                                    bottom: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    borderBottom:
                                                      "1px solid #E9E9E9",
                                                  }}
                                                />
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </TableBody>
                                    </>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}
                              </StyledTable>
                              <p
                                className={`blackclr GrandTotalRow text-responsiveClampContentText ${
                                  orderSummeryData?.cart_data &&
                                  orderSummeryData?.cart_data.length === 0
                                    ? "empty-class"
                                    : ""
                                }`}
                              >
                                Total
                                <span className="text-responsiveClampContentText">{`${calculateTenderTotal()}`}</span>
                              </p>
                            </TableContainer>
                            {/* End Tenders End */}
                            {orderSummeryData?.order_detail?.is_online ===
                            "0" ? (
                              <p className="blackclr mt-4 pl-4">
                                Cash/Change Due to Customer
                                <span className="pr-4">
                                  {+orderSummeryData?.order_detail?.change_due
                                    ? formatCurrency(
                                        parseFloat(
                                          orderSummeryData?.order_detail
                                            ?.change_due
                                        ).toFixed(2)
                                      )
                                    : "$0.00"}
                                </span>
                              </p>
                            ) : (
                              ""
                            )}
                            {/* Refund Start   */}
                            {orderSummeryData?.refund_data &&
                            orderSummeryData?.refund_data.length > 0 ? (
                              <>
                                <Grid
                                  container
                                  sx={{ transform: "translate(0px, 10px)" }}
                                >
                                  <Grid item xs={12}>
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <Grid container>
                                          <Grid
                                            item
                                            xs={12}
                                            sx={{ position: "relative" }}
                                          >
                                            <Typography
                                              sx={{
                                                fontFamily: "QuicksandBold",
                                                fontSize:
                                                  "clamp(16px, 2vw, 19px)",
                                                display: "inline-block",
                                                borderBottom:
                                                  "3px solid #0A64F9",
                                                position: "relative",
                                                zIndex: "1",
                                                color: "#000000 !important",
                                              }}
                                              className="orderSummery_head"
                                            >
                                              Refunds
                                            </Typography>
                                            <Box
                                              sx={{
                                                zIndex: "0",
                                                position: "absolute",
                                                bottom: 10,
                                                left: 0,
                                                width: "100%",
                                                borderBottom:
                                                  "1px dashed #707070",
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <TableContainer
                                  sx={{ marginTop: "0rem !important" }}
                                >
                                  <StyledTable aria-label="customized table">
                                    <TableHead className="TaxesTable">
                                      <StyledTableCell></StyledTableCell>
                                      <StyledTableCell>
                                        Auth Code
                                      </StyledTableCell>
                                      <StyledTableCell
                                        sx={{ textAlign: "center" }}
                                      >
                                        Tax Refunded
                                      </StyledTableCell>
                                      <StyledTableCell
                                        sx={{ textAlign: "right" }}
                                      >
                                        Total Refunded
                                      </StyledTableCell>
                                    </TableHead>
                                    <TableBody>
                                      {[
                                        ...(orderSummeryData?.refund_data ||
                                          []),
                                      ]
                                        ?.sort(
                                          (a, b) =>
                                            new Date(a.created_at) -
                                            new Date(b.created_at)
                                        )
                                        ?.map((refund, index) => {
                                          const refundTypes = [
                                            {
                                              key: "credit_amt",
                                              label: "Credit Card",
                                              refundTax: "credit_refund_tax",
                                            },
                                            {
                                              key: "cash_amt",
                                              label: "Cash",
                                              refundTax: "cash_refund_tax",
                                            },
                                            {
                                              key: "store_credit_amt",
                                              label: "Store Credit",
                                              refundTax:
                                                "store_credit_refund_tax",
                                            },
                                            // { key: "loyalty_point_amt", label: "Loyalty Points", refundTax: "loyality_refund_tax" },
                                            {
                                              key: "debit_amt",
                                              label: "Debit Card",
                                              refundTax: "debit_refund_tax",
                                            },
                                            {
                                              key: "giftcard_amt",
                                              label: "Gift Card",
                                              refundTax: "gift_card_refund_tax",
                                            },
                                          ];
                                          let first = 0;
                                          return refundTypes.map(
                                            ({ key, label, refundTax }) => {
                                              const amount = refund[key];
                                              if (amount > 0) {
                                                const shouldShowTipAndNCA =
                                                  first === 0 &&
                                                  label !== "Cash" &&
                                                  (+refund.tip_amt > 0 ||
                                                    +refund.nca_amt > 0);
                                                first++;
                                                const details =
                                                  refund?.refund_pax_details
                                                    ? Object.fromEntries(
                                                        refund?.refund_pax_details
                                                          .split("\n")
                                                          .map((line) =>
                                                            line
                                                              .split(":")
                                                              .map((item) =>
                                                                item.trim()
                                                              )
                                                          )
                                                      )
                                                    : {};
                                                const {
                                                  AID,
                                                  AuthCode,
                                                  CardMethod,
                                                  Last4Digit,
                                                  PaymentMode,
                                                } = details;
                                                return (
                                                  <>
                                                    <StyledTableRow key={index}>
                                                      <StyledTableCell>
                                                        <span className="tablecellColor">
                                                          {label === "Cash"
                                                            ? "Cash"
                                                            : label ===
                                                                "Store Credit"
                                                              ? "Store Credit"
                                                              : // label === "Loyalty Points" ? "Loyalty Points" :
                                                                label ===
                                                                  "Gift Card"
                                                                ? "Gift Card"
                                                                : `${label}:${Last4Digit ? Last4Digit : "N/A"}`}
                                                        </span>
                                                        <br />
                                                        <span className="nowrap">
                                                          {formatDateTimeOnly(
                                                            refund.created_at
                                                          )}
                                                        </span>
                                                      </StyledTableCell>
                                                      <StyledTableCell>
                                                        {label === "Credit Card"
                                                          ? `${AuthCode ? AuthCode : "N/A"}`
                                                          : label ===
                                                              "Debit Card"
                                                            ? `${AuthCode ? AuthCode : "N/A"}`
                                                            : label === "Cash"
                                                              ? ""
                                                              : label ===
                                                                  "Store Credit"
                                                                ? ""
                                                                : label ===
                                                                    "Gift Card"
                                                                  ? ""
                                                                  : // label === "Loyalty Points" ? "Auth Code" :
                                                                    ``}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        sx={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {(!!refund[refundTax] &&
                                                          parseFloat(
                                                            refund[refundTax]
                                                          )) <= 0
                                                          ? "No Tax Refunded"
                                                          : // : `$${parseFloat(
                                                            //     refund[refundTax]
                                                            //   ).toFixed(2)}`}
                                                            `$${(
                                                              Math.round(
                                                                parseFloat(
                                                                  refund[
                                                                    refundTax
                                                                  ]
                                                                ) * 100
                                                              ) / 100
                                                            ).toFixed(2)}`}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        sx={{
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        
                                                        {formatCurrency(parseFloat(
                                                          amount
                                                        ).toFixed(2))}
                                                        <br />
                                                        {shouldShowTipAndNCA &&
                                                          `(Tip:-$${parseFloat(
                                                            refund.tip_amt
                                                          ).toFixed(
                                                            2
                                                          )} NCA:-$${parseFloat(
                                                            refund.nca_amt
                                                          ).toFixed(2)})`}
                                                      </StyledTableCell>
                                                    </StyledTableRow>
                                                    <StyledTableRow>
                                                      <StyledTableCell
                                                        colSpan={4}
                                                        sx={{ padding: 0 }}
                                                      >
                                                        <Box
                                                          sx={{
                                                            zIndex: "0",
                                                            position:
                                                              "relative",
                                                            bottom: 0,
                                                            left: 0,
                                                            width: "100%",
                                                            borderBottom:
                                                              "1px solid #E9E9E9",
                                                          }}
                                                        />
                                                      </StyledTableCell>
                                                    </StyledTableRow>
                                                  </>
                                                );
                                              }
                                              return null;
                                            }
                                          );
                                        })}

                                      <StyledTableRow>
                                        <StyledTableCell
                                          colSpan={4}
                                          sx={{ padding: 0 }}
                                        >
                                          <Box
                                            sx={{
                                              zIndex: "0",
                                              position: "relative",
                                              bottom: 0,
                                              left: 0,
                                              width: "100%",
                                              borderBottom:
                                                "1px dashed #707070",
                                            }}
                                          />
                                        </StyledTableCell>
                                      </StyledTableRow>

                                      <StyledTableRow>
                                        <StyledTableCell
                                          sx={{ padding: 0, paddingLeft: 2 }}
                                        >
                                          Total
                                        </StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell
                                          sx={{ textAlign: "center" }}
                                        >
                                          {formatCurrency(totalRefundTax.toFixed(2))}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          sx={{
                                            padding: 0,
                                            paddingRight: 2,
                                            textAlign: "right",
                                          }}
                                        >
                                          {formatCurrency(totalRefundAmount.toFixed(2))}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                      <StyledTableRow>
                                        <StyledTableCell
                                          colSpan={4}
                                          sx={{ padding: 0 }}
                                        >
                                          <Box
                                            sx={{
                                              zIndex: "0",
                                              position: "relative",
                                              bottom: 0,
                                              left: 0,
                                              width: "100%",
                                              borderBottom:
                                                "1px dashed #707070",
                                              marginTop: "0.21rem",
                                              marginBottom: "0.45rem",
                                            }}
                                          />
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    </TableBody>
                                  </StyledTable>
                                </TableContainer>
                                <div className="mt-4">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                    className="blackclr GrandTotalRow TotalRefund "
                                  >
                                    <h5
                                      className="QuicksandSemiBold"
                                      style={{
                                        fontSize: "clamp(14px, 2vw, 16px)",
                                      }}
                                    >
                                      Grand Total After Refund
                                    </h5>
                                    <span
                                      className="QuicksandSemiBold"
                                      style={{
                                        fontSize: "clamp(14px, 2vw, 16px)",
                                      }}
                                    >
                                      Grand Total - Total Refunded ={" "}
                                      <span className="greenclr">
                                        &nbsp;
                                        {formatCurrency(
                                          (
                                            grandTotal - totalRefundAmount
                                          ).toFixed(2)
                                        )}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            {/* Refund End   */}

                            {/* Loyalty Start   */}
                            {parseFloat(couponDetails?.loyalty_point_earned) +
                              totalRefundPoints >
                            0 ? (
                              <>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <Grid container sx={{ mt: 2 }}>
                                          <Grid
                                            item
                                            xs={12}
                                            sx={{ position: "relative" }}
                                          >
                                            <Typography
                                              sx={{
                                                fontFamily: "QuicksandBold",
                                                fontSize:
                                                  "clamp(16px, 2vw, 19px)",
                                                display: "inline-block",
                                                borderBottom:
                                                  "3px solid #0A64F9",
                                                position: "relative",
                                                zIndex: "1",
                                                color: "#000000 !important",
                                              }}
                                              className="orderSummery_head"
                                            >
                                              Loyalty Points
                                            </Typography>
                                            <Box
                                              sx={{
                                                zIndex: "0",
                                                position: "absolute",
                                                bottom: 10,
                                                left: 0,
                                                width: "100%",
                                                borderBottom:
                                                  "1px dashed #707070",
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <TableContainer>
                                  <StyledTable aria-label="customized table">
                                    <TableBody>
                                      <StyledTableRow>
                                        <StyledTableCell>
                                          <span className="tablecellColor">
                                            Awarded
                                          </span>{" "}
                                          <br />
                                          <span>
                                            {orderSummeryData &&
                                              orderSummeryData.order_detail &&
                                              formatDateTimeOnly(
                                                orderSummeryData.order_detail
                                                  .date_time
                                              )}
                                          </span>
                                        </StyledTableCell>
                                        <StyledTableCell
                                          sx={{ textAlign: "right" }}
                                          className="blackclr"
                                        >
                                          <p className="greenclr">
                                            {/* {parseFloat(couponDetails?.loyalty_point_earned).toFixed(2)} */}
                                            {(() => {
                                              if (
                                                couponDetails?.loyalty_point_earned
                                              ) {
                                                const sanitizedValue =
                                                  couponDetails.loyalty_point_earned.replace(
                                                    /,/g,
                                                    ""
                                                  );
                                                let roundedValue = (
                                                  Math.round(
                                                    parseFloat(sanitizedValue) *
                                                      100
                                                  ) / 100
                                                ).toFixed(2);
                                                // return `${parseFloat(sanitizedValue).toFixed(2)}`;
                                                if (
                                                  orderSummeryData?.refund_data &&
                                                  orderSummeryData.refund_data
                                                    .length > 0
                                                ) {
                                                  roundedValue = (
                                                    parseFloat(roundedValue) +
                                                    totalRefundPoints
                                                  ).toFixed(2);
                                                }
                                                return `${roundedValue}`;
                                              }
                                              return "0.00";
                                            })()}
                                          </p>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                      <StyledTableRow>
                                        <StyledTableCell
                                          colSpan={4}
                                          sx={{ padding: 0 }}
                                        >
                                          <Box
                                            sx={{
                                              zIndex: "0",
                                              position: "relative",
                                              bottom: 0,
                                              left: 0,
                                              width: "100%",
                                              borderBottom: "1px solid #E9E9E9",
                                            }}
                                          />
                                        </StyledTableCell>
                                      </StyledTableRow>
                                      {/* {orderSummeryData && orderSummeryData?.refund_data &&
                                  orderSummeryData?.refund_data?.length > 0  &&
                                  orderSummeryData?.refund_data?.every(item => item?.reward_loyalty_refund_point > 0) && (
                                    <>
                                      {orderSummeryData.refund_data.map((item, index) => (
                                        <>
                                        <StyledTableRow key={index}>
                                          <StyledTableCell >
                                            <span className="tablecellColor">
                                              {`Removed (Due to Refund ${index + 1})`}
                                            </span> <br /> 
                                          <span> {formatDateTimeOnly(item.created_at)}</span></StyledTableCell>
                                          <StyledTableCell sx={{ textAlign: "right" }}>
                                            <p className="redclr"><span>-{parseFloat(item?.reward_loyalty_refund_point).toFixed(2)}</span></p></StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow >
                                          <StyledTableCell colSpan={4} sx={{ padding: 0 }}>
                                          <Box sx={{ zIndex:'0',position:'relative',bottom:0,left: 0,width:'100%',borderBottom: '1px solid #E9E9E9'}} />
                                          </StyledTableCell>
                                        </StyledTableRow>
                                        </>
                                      ))}
                                    </>
                                  )} */}
                                      {orderSummeryData &&
                                        orderSummeryData?.refund_data &&
                                        orderSummeryData?.refund_data?.length >
                                          0 &&
                                        orderSummeryData?.refund_data?.some(
                                          (item) =>
                                            item?.reward_loyalty_refund_point >
                                            0
                                        ) && (
                                          <>
                                            {(() => {
                                              let count = 0;
                                              const sortedRefundData = [
                                                ...orderSummeryData?.refund_data,
                                              ].sort(
                                                (a, b) =>
                                                  new Date(a?.created_at) -
                                                  new Date(b?.created_at)
                                              );
                                              return sortedRefundData.map(
                                                (item, index) => {
                                                  // Increment count only if reward_loyalty_refund_point > 0
                                                  if (
                                                    item?.reward_loyalty_refund_point >
                                                    0
                                                  ) {
                                                    count += 1;
                                                    return (
                                                      <React.Fragment
                                                        key={index}
                                                      >
                                                        <StyledTableRow>
                                                          <StyledTableCell>
                                                            <span className="tablecellColor">
                                                              {`Removed (Due to Refund ${count})`}
                                                            </span>
                                                            <br />
                                                            <span>
                                                              {formatDateTimeOnly(
                                                                item.created_at
                                                              )}
                                                            </span>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            sx={{
                                                              textAlign:
                                                                "right",
                                                            }}
                                                          >
                                                            <p className="redclr">
                                                              <span>
                                                                -
                                                                {parseFloat(
                                                                  item?.reward_loyalty_refund_point
                                                                ).toFixed(2)}
                                                              </span>
                                                            </p>
                                                          </StyledTableCell>
                                                        </StyledTableRow>
                                                        <StyledTableRow>
                                                          <StyledTableCell
                                                            colSpan={4}
                                                            sx={{ padding: 0 }}
                                                          >
                                                            <Box
                                                              sx={{
                                                                zIndex: "0",
                                                                position:
                                                                  "relative",
                                                                bottom: 0,
                                                                left: 0,
                                                                width: "100%",
                                                                borderBottom:
                                                                  "1px solid #E9E9E9",
                                                              }}
                                                            />
                                                          </StyledTableCell>
                                                        </StyledTableRow>
                                                      </React.Fragment>
                                                    );
                                                  }
                                                  return null;
                                                }
                                              );
                                            })()}
                                          </>
                                        )}
                                    </TableBody>
                                  </StyledTable>
                                </TableContainer>
                                <p className="blackclr GrandTotalRow">
                                  Total
                                  <span>
                                    <span className="greenclr">
                                      {(() => {
                                        let loyaltyPoint = 0;
                                        if (
                                          couponDetails?.loyalty_point_earned
                                        ) {
                                          let sanitizedValue =
                                            couponDetails?.loyalty_point_earned.replace(
                                              /,/g,
                                              ""
                                            );
                                          let roundedValue = (
                                            Math.round(
                                              parseFloat(sanitizedValue) * 100
                                            ) / 100
                                          ).toFixed(2);
                                          // loyaltyPoint += parseFloat(sanitizedValue).toFixed(2);
                                          if (
                                            orderSummeryData?.refund_data &&
                                            orderSummeryData.refund_data
                                              .length > 0
                                          ) {
                                            // Add refund points to roundedValue
                                            roundedValue = (
                                              parseFloat(roundedValue) +
                                              totalRefundPoints
                                            ).toFixed(2);
                                          }
                                          loyaltyPoint +=
                                            parseFloat(roundedValue).toFixed(2);
                                        }
                                        if (
                                          orderSummeryData &&
                                          orderSummeryData?.refund_data &&
                                          orderSummeryData.refund_data.length >
                                            0
                                        ) {
                                          orderSummeryData.refund_data.forEach(
                                            (item) => {
                                              loyaltyPoint -= parseFloat(
                                                item.reward_loyalty_refund_point
                                              ).toFixed(2);
                                            }
                                          );
                                        }
                                        return `${parseFloat(
                                          loyaltyPoint
                                        ).toFixed(2)}`;
                                      })()}
                                    </span>
                                  </span>
                                </p>
                              </>
                            ) : (
                              ""
                            )}
                            {/* Loyalty End   */}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className="orderStatus">
                    {/* <h5>Order Summery (3 -items)</h5> */}
                    <div className="orderSummeryList">
                      <div className="orderSummeryLeftList">
                        {/* {
                        orderSummeryData && orderSummeryData.order_detail && orderSummeryData.order_detail.del_fee !=='0'? <p className='orderSumeryText'>Delivery fee</p>:''
                      } */}
                        <p className="orderSumeryText-number"></p>
                        {/* {
                      couponDetails.coupon_code_amt !== "" ? <p className='orderSumeryText-number'>${couponDetails.coupon_code_amt}</p> : ''
                    } */}
                        {/* <p className='orderSumeryText-number'>${orderSummeryData?.order_detail?.con_fee}</p> */}
                        <p className="orderSumeryText-number"></p>
                        <h6 className="order-summery-grand-total"></h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="RightMidMainSection relative">
                  <div className="sticky top-8">
                    {orderSummeryData?.order_detail?.is_online === "1" ? (
                      <div className="orderSummeryRightTop">
                        <div className="orderSummeryRightTopL">
                          <h1 className="orderSummery_head text-responsiveClampHeading">
                            Payment Details
                          </h1>
                        </div>
                        <div className="orderSummeryRightTopR">
                          <span className="successbtn">SUCCESS</span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="orderSummeryRightTopC">
                      {orderSummeryData?.order_detail?.is_online === "1"
                        ? (() => {
                            const { pax_details } =
                              orderSummeryData?.order_detail || {};

                            if (!pax_details) {
                              return (
                                <>
                                  <p>
                                    Payment Mode
                                    <span>
                                      {capitalizeFirstLetter(
                                        orderSummeryData.order_detail
                                          .order_status || "",
                                        orderSummeryData.order_detail
                                          .payment_id || "",
                                        orderSummeryData.order_detail.m_status
                                      )}
                                    </span>
                                  </p>
                                  {/* {orderSummeryData &&
                                        orderSummeryData.order_detail &&
                                        orderSummeryData.order_detail?.card_num ? (
                                          <p>
                                            Card Number (Last 4 Digit)
                                            <span>
                                              {orderSummeryData &&
                                                orderSummeryData.order_detail &&
                                                orderSummeryData.order_detail?.card_num &&
                                                formatCardNumber(
                                                  orderSummeryData.order_detail?.card_num
                                                )}
                                            </span>
                                          </p>
                                        ) : null} */}

                                  {orderSummeryData &&
                                  orderSummeryData.order_detail &&
                                  orderSummeryData.order_detail?.card_num ? (
                                    <p>
                                      Visa Ending With
                                      <span>
                                        {orderSummeryData &&
                                          orderSummeryData.order_detail &&
                                          orderSummeryData.order_detail
                                            ?.card_num &&
                                          formatCardNumber(
                                            orderSummeryData.order_detail
                                              ?.card_num
                                          )}
                                      </span>
                                    </p>
                                  ) : null}

                                  <Box
                                    sx={{
                                      zIndex: "0",
                                      position: "relative",
                                      bottom: 8,
                                      left: 0,
                                      width: "100%",
                                      borderBottom: "1px dashed #707070",
                                      marginTop: "2rem",
                                    }}
                                  />
                                </>
                              );
                            }
                            // Parse the pax_details string into a key-value object
                            const details = Object.fromEntries(
                              pax_details
                                .split("\n")
                                .map((line) =>
                                  line.split(":").map((item) => item.trim())
                                )
                            );
                            const {
                              AID,
                              AuthCode,
                              CardMethod,
                              Last4Digit,
                              PaymentMode,
                            } = details;
                            return (
                              <>
                                {PaymentMode && (
                                  <p>
                                    Payment Mode
                                    <span>{PaymentMode + " Card"}</span>
                                  </p>
                                )}
                                {Last4Digit && (
                                  <p>
                                    Visa Ending With<span>{Last4Digit}</span>
                                  </p>
                                )}
                                {AuthCode && (
                                  <p>
                                    Auth Code<span>{AuthCode}</span>
                                  </p>
                                )}
                                <p>
                                  Payment Method <span>Contactless</span>
                                </p>
                                {AID && (
                                  <p>
                                    AID<span>{AID}</span>
                                  </p>
                                )}
                                <Box
                                  sx={{
                                    zIndex: "0",
                                    position: "relative",
                                    bottom: 8,
                                    left: 0,
                                    width: "100%",
                                    borderBottom: "1px dashed #707070",
                                    marginTop: "2rem",
                                  }}
                                />
                              </>
                            );
                          })()
                        : null}

                      {/* <p>
                      Order Id
                      <span>
                        {orderSummeryData &&
                          orderSummeryData.order_detail &&
                          orderSummeryData.order_detail.order_id}
                      </span>
                    </p>
                    <p>
                      Payment Date
                      <span>
                        {orderSummeryData &&
                          orderSummeryData.order_detail &&
                          formatDate(orderSummeryData.order_detail.date_time)}
                      </span>
                    </p>
                    <p>
                      Order Number
                      <span>
                        {orderSummeryData &&
                          orderSummeryData.order_detail &&
                          orderSummeryData.order_detail.order_number}
                      </span>
                    </p>
                    <p>
                      Payment Id
                      <span>
                        {orderSummeryData &&
                          orderSummeryData.order_detail &&
                          orderSummeryData.order_detail.payment_id}
                      </span>
                    </p>
                    {orderSummeryData &&
                    orderSummeryData.order_detail &&
                    orderSummeryData.order_detail?.card_num ? (
                      <p>
                        Card Number (Last 4 Digit)
                        <span>
                          {orderSummeryData &&
                            orderSummeryData.order_detail &&
                            orderSummeryData.order_detail?.card_num &&
                            formatCardNumber(
                              orderSummeryData.order_detail?.card_num
                            )}
                        </span>
                      </p>
                    ) : null}
                    {orderSummeryData && orderSummeryData.order_detail ? (
                      <p className="amountrow">
                        Amount
                        <span>
                          {(() => {
                            let grandTotal = parseFloat(
                              orderSummeryData.order_detail.amt
                            );
                            // Assuming $coupon_desc is also part of your orderSummeryData
                            if (couponDetails.loyalty_point_spent > 0) {
                              grandTotal += parseFloat(
                                couponDetails.loyalty_point_amt_spent
                              );
                            }
                            // Adjust for refund if applicable
                            if (
                              orderSummeryData.order_detail.is_refunded === "1"
                            ) {
                              grandTotal -= parseFloat(
                                orderSummeryData.order_detail.refund_amount
                              );
                            }
                            return `$${
                              parseFloat(grandTotal) <= 0.02 &&
                              orderSummeryData?.order_detail?.m_status === "7"
                                ? "0.00"
                                : parseFloat(grandTotal).toFixed(2)
                            }`;
                          })()}
                        </span>
                      </p>
                    ) : (
                      ""
                    )} */}
                    </div>
                    {/* <div className="orderSummeryCustomerDetailsArea">
                  <h1 className="orderSummery_head">Customer Details</h1> */}
                    {orderSummeryData &&
                      orderSummeryData.order_detail &&
                      orderSummeryData.order_detail?.order_method?.toLowerCase() ===
                        "pickup" &&
                      (orderSummeryData.order_detail.billing_name ||
                        orderSummeryData.order_detail.customer_phone ||
                        orderSummeryData.order_detail.delivery_phn ||
                        orderSummeryData.order_detail.customer_email ||
                        orderSummeryData.order_detail?.email) && (
                        <div className="orderSummeryCustomerDetailsArea">
                          <h1 className="orderSummery_head">
                            Customer Details
                          </h1>
                          <div className="orderSummeryCustomerDetailsInner">
                            <h5>
                              {orderSummeryData &&
                                orderSummeryData?.order_detail &&
                                orderSummeryData?.order_detail
                                  ?.billing_name}{" "}
                              <span className="btngreen_orderSummery">
                                Billing
                                {orderSummeryData?.order_detail?.billing_add
                                  ? " Address"
                                  : " Info"}
                              </span>
                            </h5>
                            <p className="flex items-center text-responsiveClampContentText">
                              {orderSummeryData &&
                              orderSummeryData?.order_detail &&
                              orderSummeryData?.order_detail?.billing_add ? (
                                <img
                                  src={imageAddress}
                                  alt="delivery address"
                                />
                              ) : (
                                ""
                              )}{" "}
                              {""}
                              {orderSummeryData &&
                              orderSummeryData?.order_detail
                                ? removeHtmlTags(
                                    orderSummeryData?.order_detail?.billing_add
                                  )
                                : ""}
                            </p>
                            <p className="flex items-center text-responsiveClampContentText">
                              {(orderSummeryData?.order_detail &&
                                !!orderSummeryData?.order_detail
                                  ?.customer_phone) ||
                              !!orderSummeryData?.order_detail?.delivery_phn ? (
                                <div className="pe-1">
                                  <img src={imagePhone} alt="Pickup phone" />
                                </div>
                              ) : (
                                ""
                              )}
                              <div>
                                {orderSummeryData?.order_detail
                                  ?.customer_phone ||
                                  orderSummeryData?.order_detail?.delivery_phn}
                              </div>
                            </p>
                            <p className="flex items-center text-responsiveClampContentText">
                              {(orderSummeryData.order_detail &&
                                !!orderSummeryData?.order_detail
                                  ?.customer_email) ||
                              !!orderSummeryData?.order_detail.email ? (
                                <div className="pe-1">
                                  <img src={emailLogo} alt="Email" />
                                </div>
                              ) : (
                                ""
                              )}
                              <div>
                                {orderSummeryData?.order_detail
                                  ?.customer_email ||
                                  orderSummeryData?.order_detail?.email}
                              </div>
                            </p>
                          </div>
                        </div>
                      )}
                    {orderSummeryData &&
                      orderSummeryData.order_detail &&
                      orderSummeryData.order_detail?.order_method?.toLowerCase() ===
                        "delivery" && (
                        <>
                          <div className="orderSummeryCustomerDetailsArea">
                            <h1 className="orderSummery_head">
                              Customer Details
                            </h1>
                            <div className="orderSummeryCustomerDetailsInner">
                              <h5>
                                {orderSummeryData &&
                                  orderSummeryData?.order_detail &&
                                  orderSummeryData?.order_detail
                                    ?.deliver_name}{" "}
                                <span className="btngreen_orderSummery">
                                  Delivery Address
                                </span>
                              </h5>
                              <p className="flex items-center">
                                {orderSummeryData &&
                                orderSummeryData?.order_detail &&
                                orderSummeryData?.order_detail
                                  ?.delivery_addr ? (
                                  <img
                                    src={imageAddress}
                                    alt="delivery address"
                                  />
                                ) : (
                                  ""
                                )}{" "}
                                {""}
                                {orderSummeryData &&
                                orderSummeryData?.order_detail
                                  ? removeHtmlTags(
                                      orderSummeryData?.order_detail
                                        ?.delivery_addr
                                    )
                                  : ""}
                              </p>
                              <div className="flex">
                                <p className="flex items-center">
                                  <img src={imagePhone} alt="Pickup phone" />
                                  {"  "}
                                  {orderSummeryData &&
                                    orderSummeryData?.order_detail &&
                                    orderSummeryData?.order_detail
                                      ?.delivery_phn}
                                </p>

                                {/* <p className="flex items-center pl-20 text-nowrap">
                            <img src={Miles} alt="Miles" />
                            5 Miles away
                          </p> */}
                              </div>
                            </div>

                            <div className="orderSummeryCustomerDetailsInner">
                              <h5>
                                {orderSummeryData &&
                                  orderSummeryData.order_detail &&
                                  orderSummeryData?.order_detail
                                    ?.billing_name}{" "}
                                <span className="btngreen_orderSummery">
                                  Billing
                                  {orderSummeryData?.order_detail?.billing_add
                                    ? " Address"
                                    : " Info"}
                                </span>
                              </h5>
                              <p className="flex items-center">
                                {orderSummeryData &&
                                orderSummeryData?.order_detail &&
                                orderSummeryData?.order_detail?.billing_add ? (
                                  <img
                                    src={imageAddress}
                                    alt="delivery address"
                                  />
                                ) : (
                                  ""
                                )}{" "}
                                {""}
                                {orderSummeryData &&
                                orderSummeryData?.order_detail
                                  ? removeHtmlTags(
                                      orderSummeryData?.order_detail
                                        ?.billing_add
                                    )
                                  : ""}
                              </p>
                              <p className="flex items-center">
                                {(orderSummeryData.order_detail &&
                                  !!orderSummeryData?.order_detail
                                    ?.customer_phone) ||
                                !!orderSummeryData?.order_detail
                                  ?.delivery_phn ? (
                                  <div className="pe-1">
                                    <img src={imagePhone} alt="Pickup phone" />
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div>
                                  {orderSummeryData?.order_detail
                                    ?.customer_phone ||
                                    orderSummeryData?.order_detail
                                      ?.delivery_phn}
                                </div>
                              </p>
                              <p className="flex items-center">
                                {(orderSummeryData?.order_detail &&
                                  !!orderSummeryData?.order_detail
                                    ?.customer_email) ||
                                !!orderSummeryData?.order_detail?.email ? (
                                  <div className="pe-1">
                                    <img src={emailLogo} alt="Email" />
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div>
                                  {orderSummeryData?.order_detail
                                    ?.customer_email ||
                                    orderSummeryData?.order_detail?.email}
                                </div>
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    {/* </div> */}

                    <div className="orderSummeryIdentification text-responsiveClampContentText">
                      {orderSummeryData?.id_card_detail &&
                        ((orderSummeryData.id_card_detail.i_card_number &&
                          orderSummeryData.id_card_detail.i_card_number !==
                            "verify_non_id_person") ||
                          dateFormat ||
                          // (dateOfBirth && dateOfBirth !== "31 Dec 1969")) && (
                          (dateOfBirth && dateOfBirth !== "12/31/1969")) && (
                          <>
                            <h1 className="orderSummery_head text-responsiveClampContentText">
                              {orderSummeryData.order_detail?.is_online === "0"
                                ? "Identification Details"
                                : "Identification Card"}
                            </h1>
                            <div className="orderSummeryRightTopC">
                              {orderSummeryData.id_card_detail &&
                              orderSummeryData.id_card_detail.i_card_number ? (
                                <p>
                                  ID Number
                                  {orderSummeryData &&
                                  orderSummeryData.id_card_detail &&
                                  orderSummeryData.id_card_detail.i_card_number
                                    ?.length > 30 ? (
                                    <BootstrapTooltip
                                      placement="bottom-start"
                                      title={
                                        <p className="capitalize">
                                          {orderSummeryData &&
                                            orderSummeryData.id_card_detail &&
                                            orderSummeryData.id_card_detail
                                              .i_card_number}
                                        </p>
                                      }
                                    >
                                      <span>
                                        {orderSummeryData &&
                                          orderSummeryData.id_card_detail &&
                                          orderSummeryData.id_card_detail.i_card_number
                                            ?.slice(0, showIdLength())
                                            .concat(
                                              orderSummeryData &&
                                                orderSummeryData.id_card_detail &&
                                                orderSummeryData.id_card_detail
                                                  .i_card_number?.length > 30
                                                ? "..."
                                                : ""
                                            )}
                                      </span>
                                    </BootstrapTooltip>
                                  ) : (
                                    <span>
                                      {orderSummeryData &&
                                        orderSummeryData.id_card_detail &&
                                        orderSummeryData.id_card_detail
                                          .i_card_number}
                                    </span>
                                  )}
                                </p>
                              ) : (
                                ""
                              )}
                              {orderSummeryData.id_card_detail && dateFormat ? (
                                <p>
                                  Expiration Date
                                  <span>
                                    {orderSummeryData &&
                                      orderSummeryData.id_card_detail &&
                                      dateFormat}
                                  </span>
                                </p>
                              ) : (
                                ""
                              )}
                              {orderSummeryData &&
                                orderSummeryData.id_card_detail &&
                                dateOfBirth &&
                                // dateOfBirth !== "31 Dec 1969" && ( // Add a condition here to check if dateOfBirth is not '31 Dec 1969'
                                dateOfBirth !== "12/31/1969" && ( // Add a condition here to check if dateOfBirth is not '31 Dec 1969'
                                  <p className="orderSumeryText">
                                    Date Of Birth <span>{dateOfBirth}</span>
                                  </p>
                                )}
                            </div>
                          </>
                        )}
                      {orderSummeryData &&
                      orderSummeryData.order_detail &&
                      orderSummeryData.order_detail.is_online === "0" ? (
                        ""
                      ) : (
                        <div className="ordersummaryR_div_file">
                          <label className="photo_area">
                            <div
                              id="img_container"
                              data-toggle="modal"
                              data-target="#id_cart_popup_front_side"
                              onClick={handleClickOpen}
                            >
                              <img
                                src={
                                  orderSummeryData &&
                                  orderSummeryData.id_card_detail &&
                                  orderSummeryData.id_card_detail.image_url
                                    ? orderSummeryData.id_card_detail?.image_url
                                    : ""
                                }
                                alt=""
                                className="id-cart-image"
                              ></img>
                            </div>
                          </label>
                          <div className="photo_area_text">
                            <span>Front Side</span>
                            <div className="custom-file"></div>
                          </div>
                        </div>
                      )}
                    </div>

                    <br></br>
                    <br></br>

                    <Modal
                      className="for-scroll"
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box
                        sx={{
                          width: { xs: "95%", sm: "60%", md: "50%", lg: "30%" },
                        }}
                        className="basic-modal"
                      >
                        <div id="modal-modal-title">
                          <span>
                            {orderSummeryData.order_detail?.is_online === "0"
                              ? "Identification Details"
                              : "Identification Card"}
                          </span>
                          <CloseIcon
                            onClick={handleClose}
                            className="modal-close-icon"
                          />
                        </div>
                        <div
                          data-toggle="modal"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "1rem",
                          }}
                        >
                          <img
                            src={
                              orderSummeryData &&
                              orderSummeryData.id_card_detail &&
                              orderSummeryData.id_card_detail.image_url
                                ? orderSummeryData.id_card_detail?.image_url
                                : ""
                            }
                            // src={
                            //   orderSummeryData && orderSummeryData.id_card_detail
                            //     ? `https://sandbox.quickvee.com/upload/customer/id_proof/${orderSummeryData.id_card_detail.i_card_front_img}`
                            //     : ''
                            // }
                            onError={(e) => {
                              e.target.src = ProductDefaultImage;
                            }}
                            className="id-cart-image"
                          />
                        </div>
                      </Box>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
      {/* )} */}
      {/* --------------------------- */}
    </>
  );
}
// ----------------------------------order Summery end  here ----------------------------------

import React from "react";
import SystemView from "./SystemView";
import { Grid } from "@mui/material";


const MainSyastemAccess = () => {
  return (
    <>
    
      <Grid sx={{pb:14}} className="q-attributes-main-page">
        <SystemView />
      </Grid>
    </>
  );
};

export default MainSyastemAccess;

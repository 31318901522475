import React, { useState, useEffect, useRef } from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  Line,
} from "recharts";
import BlueArrowdown from '../../Assests/Filter/blueArrow.svg';
import BlueArrowUp from '../../Assests/Filter/blueARROWup.svg';
import { priceFormate } from "../../hooks/priceFormate";
import { Grid } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import { useSelector, useDispatch } from "react-redux";

const BarCharts = ({graphData}) => {
  const [selectedFilter, setSelectedFilter] = useState("Time");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(["Net Sales", "Sale Count"]);
  const dropdownRef = useRef(null);

  const [xAxisDates, setXAxisDates] = useState([]);

  const menuItems = [
    { label: "Net Sales", key: "net_sales" },
    { label: "Sale Count", key: "sales_count" },
    { label: "Cost of Goods Sold", key: "cost_price" },
    { label: "Gross Profit", key: "gross_profit" },
    { label: "Margin (%)", key: "margin" },
    { label: "Tax", key: "tax" },
  ];

  const getLegendPayload = () => {
    return menuItems
      .filter((item) => selectedColumns.includes(item.label))
      .map((item) => ({
        value: item.label,
        type: item.key === "net_sales" ? "square" : "line",
        color: item.key === "cost_price" ? "#FF7300" : 
              item.key === "gross_profit" ? "red" : 
              item.key === "margin" ? "blue" : 
              item.key === "tax" ? "green" : "#81AEFF"
      }));
  };

  const handleColumnChange = (column) => {
    setSelectedColumns((prevColumns) =>
      prevColumns.includes(column)
        ? prevColumns.filter((item) => item !== column)
        : [...prevColumns, column]
    );
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  
  
  // const CustomTooltip = ({ active, payload, label }) => {
  //   if (active && payload && payload.length) {
  //     return (
  //       <div className="custom-tooltip" >
  //         <p className="label">Time</p>
  //         <p className="label  mb-2">{label}</p>
  //         {selectedColumns.map((item, index) => {
  //            const value = payload[index]?.value;
  //            const formattedValue = typeof value === "number" ? value.toFixed(2) : value;
  //            const isNegative = Number(formattedValue) < 0;
   
  //           return(
  //           <>
  //           <div className="flex justify-between" key={index}>
  //             <p>{item}</p>
  //             <p>
  //               {item === "Sale Count" || item === "Margin (%)"
  //                 ? formattedValue
  //                 : isNegative
  //                 ? `-$${Math.abs(parseFloat(formattedValue)).toFixed(2)}`
  //                 : `$${parseFloat(formattedValue).toFixed(2)}`}{" "}
  //               {item === "Margin (%)" ? "%" : ""}
  //             </p>
  //           </div>
  //           </>
  //           )
  //           })}
  //       </div>
  //     );
  //   }
  //   return null;
  // };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload.map((item) => ({
        ...item,
        selectedKey:
          item.dataKey === "net_sales"
            ? "Net Sales"
            : item.dataKey === "sales_count"
            ? "Sale Count"
            : item.dataKey === "cost_price"
            ? "Cost of Goods Sold"
            : item.dataKey === "gross_profit"
            ? "Gross Profit"
            : item.dataKey === "margin"
            ? "Margin (%)"
            : item.dataKey === "tax"
            ? "Tax"
            : "",
      }));
  
      // Define the desired order for columns
      const desiredOrder = [
        "Net Sales",
        "Sale Count",
        "Cost of Goods Sold",
        "Gross Profit",
        "Margin (%)",
        "Tax",
      ];
  
      const orderedColumns = selectedColumns.sort((a, b) => desiredOrder.indexOf(a) - desiredOrder.indexOf(b));
  
      return (
        <div className="custom-tooltip">
          <p className="label">Time</p>
          <p className="label mb-1">{label}</p>
          {orderedColumns.map((column, index) => {
            const foundItem = data.find((i) => i.selectedKey === column);
            const value = foundItem ? foundItem.value : null;
            const formattedValue = typeof value === "number" ? value.toFixed(2) : value;
            const isNegative = Number(formattedValue) < 0;
            return (
              <div className="flex justify-between" key={index}>
                <p>{column}</p>
                {column !== "Sale Count" ? (
                  <p>
                    {column === "Margin (%)"
                      ? formattedValue
                      : isNegative
                      ? `-$${Math.abs(parseFloat(formattedValue)).toFixed(2)}`
                      : `$${parseFloat(formattedValue).toFixed(2)}`}
                    {column === "Margin (%)" ? "%" : ""}
                  </p>
                  ):(<p>{value}</p>)
                }
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };
  // for custome Tooltip End



  function formatTime(timeString) {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + " : " + minute + (hour < 12 ? "AM" : "PM");
}

  const formattedGraphData = graphData
  ? Object.entries(graphData).map(([time, data]) => ({
      name: formatTime(time),
      ...data,
    }))
  : [];

  const formatDollar = (value) => `$${value}`;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Detect screen width and update state
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    // Cleanup listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getInterval = () => {
    if (screenWidth < 786) {
      return 3;  // For devices smaller than 786px
    } else if (screenWidth >= 786 && screenWidth < 1100) {
      return 2;  // For devices between 786px and 1100px
    } else {
      return 1;  // For devices larger than 1100px (show all ticks)
    }
  };
  const allshiftsummaryDataState = useSelector( (state) => state.SalesByHoursData);
  return (
    <div className="w-full box_shadow_div">
      <div className="flex justify-between items-center mb-10 ">
        <h1 className="pl-6">Sales by Hour of Day</h1>
        <div className="flex items-center pt-4" ref={dropdownRef}>
        <div className="q-order-page-filter mr-5">
        
          <div className="custom-dropdown " onClick={() => setDropdownOpen(!dropdownOpen)}>
              <div className="q_netsales_header salesbyhrDropdown">
                <span className="selected-option q_drop_down_filter  whitespace-nowrap">Display Options</span>
                <img src={BlueArrowdown} alt="Down Icon" className={`w-4 h-4 ${dropdownOpen ? 'hidden' : ''}`} />
                <img src={BlueArrowUp} alt="Up Icon" className={`w-4 h-4 ${dropdownOpen ? '' : 'hidden'}`} />
              </div>
          </div>
          {dropdownOpen && (
            <div className="flex flex-col Graph-dropdown-content">
              {menuItems.map((item, index) => (
                <div className="qv_checkbox" key={index}>
                  <label className="qv_checkbox_add_checkmark_label whitespace-nowrap">
                    {item.label}
                    <input
                      type="checkbox"
                      id={item.label}
                      name={item.label}
                      value={item.key}
                      checked={selectedColumns.includes(item.label)}
                      onChange={() => handleColumnChange(item.label)}
                    />
                    <span className="qv_add_checkmark"></span>
                  </label>
                </div>
              ))}
              </div>
            )}
          </div>
          </div>
        </div>
        <div className="h-80 mb-8 pr-8">
        {!allshiftsummaryDataState?.loading  ? (
          <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={formattedGraphData}>
            {/* <XAxis dataKey="name" /> */}
            <XAxis
              dataKey="name"
              tickFormatter={(time) => time} // Ensures proper time formatting
              interval={getInterval()}
              tick={{ fontSize: "clamp(12px, 2vw, 16px)" }} 
            />

          <YAxis
              domain={[0, "auto"]} // Starts from 0, auto-calculates max value
              tickFormatter={formatDollar} // Format ticks with $
              tick={{ fontSize: "clamp(12px, 2vw, 16px)" }} 
            />
            <Tooltip  content={<CustomTooltip />}/>
            <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
            <Legend payload={getLegendPayload()} />
            {selectedColumns.includes('Net Sales') && <Bar dataKey="net_sales" barSize={30} fill="#81AEFF" barCategoryGap={3}/>}
            {selectedColumns.includes('Sale Count') && <Line dataKey="sales_count" stroke="#81AEFF" />}
            {selectedColumns.includes('Cost of Goods Sold') && <Line type="monotone" dataKey="cost_price" stroke="#FF7300" />}
            {selectedColumns.includes('Gross Profit') && <Line dataKey="gross_profit" stroke="red" />}
            {selectedColumns.includes('Margin (%)') && <Line type="monotone" dataKey="margin" stroke="blue" />}
            {selectedColumns.includes('Tax') && <Line type="monotone" dataKey="tax" stroke="green" />}
          </ComposedChart>

          </ResponsiveContainer>
 
        ) : (
          <Grid item xs={12} md={6}>
            <Grid sx={{ my: 0, p: 2 }} className="box_shadow_div">
              <Skeleton width="100%" height={"280px"} />
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default BarCharts;

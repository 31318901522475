import { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { editEmployee } from "../../../Redux/features/StoreSettings/AddEmployee/AddEmployeeSlice";
import Validation from "../../../Constants/Validation";

import {
  BASE_URL,
  ADDEDIT_EMPLOYEE,
  SEND_INVITATION_EMPLOYEE,
} from "../../../Constants/Config";
import { useAuthDetails } from "../../../Common/cookiesHelper";
import { ToastifyAlert } from "../../../CommonComponents/ToastifyAlert";
import useDebounce from "../../../hooks/useDebouncs";
import PasswordShow from "../../../Common/passwordShow";
import {
  checkEmailValidation,
  checkPinValidation,
} from "../../../Redux/features/EmployeeList/EmployeeListSlice";

const AddEmployeeFormLogic = ({ employee, employeeList, setSuggestionList  }) => {
  const dispatch = useDispatch();
  const {
    validateFirstName,
    validateLastName,
    validateEmail,
    validatePhoneNumber,
    validatePinNumber,
    validateWages,
    Address_line_1,
    validateCity,
    validateState,
    validateZipCode,
    validatePasswordAndenable,
    validatePasswordAndEnableOnEdit
  } = Validation();
  const [submitmessage, setsubmitmessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [loaderForInvitation, setloaderForInvitation] = useState(false);
  const [backendAccess, setbackendAccess] = useState(false);
  const [posAccess, setPosAccess] = useState(false);
  // const Navigate = useNavigate();
  const scrollRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [havePassword, sethavePassword] = useState(false);

  const [pinData, setPinData] = useState("");
  const [emailData, setEmailData] = useState("");

  const pinDebouncedValue = useDebounce(pinData, 300);
  const emailDebouncedValue = useDebounce(emailData, 300);

  const { LoginGetDashBoardRecordJson, LoginAllStore, userTypeData } =
    useAuthDetails();
  const { handleCoockieExpire, getUnAutherisedTokenMessage, getNetworkError } =
    PasswordShow();
  let AuthDecryptDataDashBoardJSONFormat = LoginGetDashBoardRecordJson;
  const merchant_id = AuthDecryptDataDashBoardJSONFormat?.data?.merchant_id;
  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    pin: "",
    wages: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    zipcode: "",
    state: "",
    backendAccess: false,
    enablePOSAccess: false,
    passwordSource: "manual",
    send_password_via_mail: 0,
    employeePassword: "",
    errors: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      pin: "",
      wages: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      zipcode: "",
      state: "",
      employeePassword: "",
    },
  });

  const emailExists1 = `Email already used by an Employee, Please change your email.`;
  const emailExists2 = `Email already used by an Admin, Please change your email.`;

  const checkValidEmailAPI = async () => {
    const obj = {
      email: emailDebouncedValue?.trim(),
      id: employee.id,
      ...userTypeData,
    };
    try {
      const data = await dispatch(checkEmailValidation(obj)).unwrap();
      if (data?.message === emailExists1 || data?.message === emailExists2) {
        setValues((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            email: "Email Id Already Exist",
          },
        }));
      } else if (data?.message === "New User") {
        const EmailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-_]+\.[A-Z]{2,4}$/i;
        const emoji =
          /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FAB0}-\u{1FABF}\u{1FAC0}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}]/gu;

        const email = emailDebouncedValue?.trim();
        setValues((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            // email: prev.errors.email ? prev.errors.email : "",
            email: !EmailReg.test(email)
              ? "Enter valid email address"
              : emoji.test(email)
                ? "Emoji not allowed"
                : "",
          },
        }));
      }
    } catch (error) {
      if (error?.status == 401 || error?.response?.status === 401) {
        getUnAutherisedTokenMessage();
        handleCoockieExpire();
      } else if (error?.status == "Network Error") {
        getNetworkError();
      }
    }
  };

  const checkValidPinAPI = async () => {
    const obj = {
      email: LoginGetDashBoardRecordJson?.data?.email?.trim(),
      pin: pinDebouncedValue?.trim(),
      id: employee?.id,
      ...userTypeData,
    };
    try {
      const data = await dispatch(checkPinValidation(obj)).unwrap();
      if (data?.message === "The Pin already exists") {
        setSuggestionList(data?.suggested_pins ?? []);
        setValues((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            pin: "Pin already exist",
          },
        }));
      } else if (data?.message === "New Pin") {
        setSuggestionList([]);
        setValues((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            pin: prev.errors.pin ? prev.errors.pin : "",
          },
        }));
      }
    } catch (error) {
      setSuggestionList([]);
      if (error?.status == 401 || error?.response?.status === 401) {
        getUnAutherisedTokenMessage();
        handleCoockieExpire();
      } else if (error?.status == "Network Error") {
        getNetworkError();
      }
    }
  };

  useEffect(() => {
    if(showModal){
      checkValidEmailAPI();
    }
  }, [emailDebouncedValue, showModal]);

  useEffect(() => {
    if(showModal){
      checkValidPinAPI();
    }
  }, [pinDebouncedValue, showModal]);

  useEffect(() => {
    if(showModal){
      setPinData('')
      setEmailData('')
    }
  }, [showModal]);

  useEffect(() => {
    if (!values?.backendAccess && !values?.enablePOSAccess) {
      setValues((prev) => ({
        ...prev,
        errors: {
          ...prev.errors,
          ["email"]:
            prev.errors.email === "Email Address is required"
              ? ""
              : prev.errors.email,
        },
      }));
    }
  }, [values?.backendAccess, values?.enablePOSAccess]);

  // const handleSubmitMessage = () => {
  //   setTimeout(() => {
  //     setsubmitmessage("");
  //   }, 2000);
  // };

  useEffect(() => {
    if (employee && employee?.password) {
      sethavePassword(true);
    }
    setValues((prevValues) => ({
      ...prevValues,
      firstname: employee && employee.f_name ? employee.f_name : "",
      lastname: employee && employee.l_name ? employee.l_name : "",
      email: employee && employee.email ? employee.email.trim() : "",
      phone: employee && employee.phone ? employee.phone : "",
      pin: employee && employee.pin ? employee.pin : "",
      wages: employee && employee.wages_per_hr ? employee.wages_per_hr : "",
      address_line_1: employee && employee.address ? employee.address : "",
      city: employee && employee.city ? employee.city : "",
      zipcode: employee && employee.zipcode ? employee.zipcode : "",
      state: employee && employee.state ? employee.state : "",
      enablePOSAccess:
        employee && employee?.enable_pos_access != "0" ? true : false,
      backendAccess:
        employee && employee?.enable_backend_access != "0" ? true : false,
      passwordSource: "manual",
    }));
  }, [employee, showModal]);

  const handleEditEmployeeInput = (event) => {
    let { errors } = values;
    let fieldName = event.target.name;
    let fieldValue = event.target.value;

    switch (fieldName) {
      case "firstname":
        validateFirstName(fieldValue, errors);
        break;
      case "lastname":
        validateLastName(fieldValue, errors);
        break;
      case "email":
        validateEmail(fieldValue?.trim(), errors, values);
        setEmailData(fieldValue);
        break;
      // case "phone":
      //   validatePhoneNumber(fieldValue, errors);
      //   break;
      case "pin":
        validatePinNumber(fieldValue, errors, employeeList);
        break;
      case "wages":
        validateWages(fieldValue, errors);
        break;
      case "address_line_1":
        Address_line_1(fieldValue, errors);
        break;
      case "city":
        validateCity(fieldValue, errors);
        break;
      case "zipcode":
        validateZipCode(fieldValue, errors);
        break;
      case "state":
        validateState(fieldValue, errors);
        break;
      case "employeePassword":
        validatePasswordAndenable(
          values.backendAccess,
          fieldValue,
          values?.employeePassword,
          errors
        );
        break;
      default:
        break;
    }
    if (fieldName === "backendAccess") {
      // setbackendAccess(!backendAccess);
      // fieldValue = !backendAccess ? true : false;
      fieldValue = fieldValue === "true" ? true : false;
    }
    if (fieldName === "enablePOSAccess") {
      // setPosAccess(!posAccess);
      fieldValue = fieldValue === "true" ? true : false;
    }
    // if (fieldName === "send_password_via_mail") {
    //   if (fieldValue === "0") {
    //     fieldValue = "1";
    //   } else if (fieldValue === "1") {
    //     fieldValue = "0";
    //   }
    // }
    setValues((prevValues) => ({
      errors,
      ...prevValues,
      [fieldName]: fieldValue,
    }));
  };

  const handlePhoneInput = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    setValues((prevValues) => ({
      ...prevValues,
      phone: value,
    }));
  };
  const handlePinInput = (event) => {
    let { errors } = values;
    const value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setPinData(value);
    validatePinNumber(value, errors, employeeList);
    setValues((prevValues) => ({
      ...prevValues,
      pin: value,
    }));
  };
  const handleZipInput = (event) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setValues((prevValues) => ({
      ...prevValues,
      zipcode: value,
    }));
  };

  const normalizeSpace = (str) => {
    if (!str) return ''; 
    return str.trim().replace(/\s+/g, ' '); 
  };

  const handleChangePinValue=(value)=>{
    setValues((prev)=>({
      ...prev,
      ['pin']: value,
      errors:{
        ...prev.errors,
        ['pin']: "",
      }
    }))
  }

  const handleEditEmployee = async (e) => {
    e.preventDefault();
    let { errors } = values;
    await validateFirstName(values.firstname, errors);
    await validateLastName(values.lastname, errors);
    await validateEmail(values.email?.trim(), errors, values);
    // await validatePhoneNumber(values.phone, errors);
    await validatePinNumber(values.pin, errors);
    await validateWages(values.wages, errors);
    await Address_line_1(values.address_line_1, errors);
    await validateCity(values.city, errors);
    await validateZipCode(values.zipcode, errors);
    await validateState(values.state, errors);
    await validatePasswordAndEnableOnEdit(
      values.backendAccess,
      values?.passwordSource,
      values?.employeePassword ? values?.employeePassword : employee?.password,
      errors,
      values?.enablePOSAccess,
      employee,
    );

    if (
      errors.firstname === "" &&
      errors.lastname === "" &&
      errors.email === "" &&
      errors.phone === "" &&
      errors.pin === "" &&
      errors.wages === "" &&
      errors.address_line_1 === "" &&
      errors.city === "" &&
      errors.zipcode === "" &&
      errors.state === "" &&
      errors.employeePassword === ""
    ) {
      const data = {
        merchant_id: merchant_id,
        // "admin_id":"MAL0100CA",
        admin_id: "",
        employee_id: employee.id,
        f_name: normalizeSpace(values.firstname),
        l_name: normalizeSpace(values.lastname),
        email: values.email.toLowerCase().trim(),
        phone: values.phone,
        pin: values.pin,
        wages: values.wages,
        address_line_1: normalizeSpace(values.address_line_1),
        city: values.city,
        zip: values.zipcode,
        state: values.state,
        token_id: userTypeData?.token_id,
        login_type: userTypeData?.login_type,
        backendAccess: values.backendAccess,
        enablePOSAccess: values.enablePOSAccess,
        employeePassword: values.employeePassword
          ? values.employeePassword
          : "",
        // send_password_via_mail: values.send_password_via_mail,
      };
      setLoader(true);

      try {
        const response = await axios.post(BASE_URL + ADDEDIT_EMPLOYEE, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userTypeData?.token}`,
          },
        });
        if (response.data.status === true) {
          ToastifyAlert("Updated Successfully", "success");
          dispatch(editEmployee(data));
          values.employeePassword = "";
          setLoader(false);
          setShowModal(false);

          // Navigate("/");
        } else if (response.data?.code == 301) {
          errors.pin = response.data.message;
          setLoader(false);
          setShowModal(true);
        } else if (response.data?.code == 204) {
          errors.email = response.data.message;
          setLoader(false);
          setShowModal(true);
        } else {
          //   await handleScrollClick()
          setsubmitmessage(response.data.message);
          // handleSubmitMessage();
          setLoader(false);
          setShowModal(true);
        }
      } catch (error) {
        setLoader(false);
        const errorMessage =
          error.response?.data?.message || "Something went wrong!";
        ToastifyAlert(errorMessage, "error"); // Display backend error message or fallback message
        // return new Error(error);
      }
    }

    setValues((prevState) => ({
      ...prevState,
      errors,
    }));
  };

  const handleSendInvitationLinkEmployee = async (e) => {
    e.preventDefault();
    let { errors } = values;
    await validateFirstName(values.firstname, errors);
    await validateLastName(values.lastname, errors);
    await validateEmail(values.email?.trim(), errors);

    if (
      errors.firstname === "" &&
      errors.lastname === "" &&
      errors.email === ""
    ) {
      const data = {
        merchant_id: merchant_id,
        admin_id: "",
        employee_id: employee.id,
        f_name: values.firstname,
        l_name: values.lastname,
        email: values.email.toLowerCase(),
      };
      setloaderForInvitation(true);

      try {
        const response = await axios.post(
          BASE_URL + SEND_INVITATION_EMPLOYEE,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userTypeData?.token}`,
            },
          }
        );
        if (response.data.status === "success") {
          ToastifyAlert(response.data.message, "success");
          setloaderForInvitation(false);
        } else {
          ToastifyAlert(response.data.message, "error");
          setloaderForInvitation(false);
        }
      } catch (error) {
        setloaderForInvitation(false);
      }
    }

    setValues((prevState) => ({
      ...prevState,
      errors,
    }));
  };
  return {
    handleSendInvitationLinkEmployee,
    loaderForInvitation,
    handleEditEmployeeInput,
    handlePhoneInput,
    handlePinInput,
    handleZipInput,
    values,
    handleEditEmployee,
    submitmessage,
    setsubmitmessage,
    showModal,
    setShowModal,
    scrollRef,
    loader,
    havePassword,
    handleChangePinValue,
    setValues,
    setPinData,
    setEmailData
  };
};

export default AddEmployeeFormLogic;

import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import SpikeCharts from "../SpikeCharts";
import { useSelector } from "react-redux";
import {
  removeCurrencySign,
  getDefaultDateRange,
  getXAxisData,
  getYAxisTicks,
  getStartDateAndEndDate,
  getPercentValue,
  getLastRangeStartDate,
  formatCurrency,
  dateInYYYYMMDD,
  dataExists,
  getMinMaxValues,
  updateDataObject,
  deleteKey,
} from "../../../Constants/utils";
import { useDispatch } from "react-redux";
import PasswordShow from "../../../Common/passwordShow";
import { getPercentageDiscount } from "../../../Redux/features/Dashboard/Charts/percentageDiscountChartSlice";

export const PercentageDiscountChart = ({
  merchantId,
  activeType,
  presentDate,
  percentageDiscountChartInView,
  percentageDiscountChartRef,
}) => {
  const { handleCoockieExpire, getUnAutherisedTokenMessage, getNetworkError } =
    PasswordShow();
  const dispatch = useDispatch();
  const [xAxisDates, setXAxisDates] = useState([]);
  const [percentageDiscountChartState, setPercentageDiscountChartState] =
    useState(false);
  const chartKey = "percentageDiscountChart";

  const defaultObj = {
    percent: 0,
    xAxisData: [],
    minValue: 0,
    maxValue: 0,
    yAxisOptions: [],
    type: "number",
    totalValue: formatCurrency(0),
  };
  const [percentageDiscountChartData, setPercentageDiscountChartData] =
    useState(defaultObj);
  const [refreshLoading, setRefreshLoading] = useState(false);

  const percentageDiscountChart = useSelector(
    (state) => state.percentageDiscountChart
  );

  useEffect(() => {
    const bool =
      percentageDiscountChart &&
      percentageDiscountChart?.percentageDiscountData &&
      percentageDiscountChart?.percentageDiscountData.length > 3 &&
      !percentageDiscountChart.loading;

    const date = dateInYYYYMMDD(presentDate);
    const dashboardData = sessionStorage.getItem("dashboardData");
    const oldDashboardData = dashboardData ? JSON.parse(dashboardData) : {};

    const dashboardDataExists = dataExists(
      oldDashboardData,
      merchantId,
      activeType,
      date,
      chartKey
    );

    // setting already present api data from Session storage
    if (Object.keys(dashboardDataExists).length > 0) {
      return;
    } else {
      if (bool) {
        const getValue = (data, index) => {
          const value =
            data &&
            data.percentageDiscountData &&
            data.percentageDiscountData.length > 2 &&
            data.percentageDiscountData[
              data.percentageDiscountData.length - index
            ];
          return value.discount_in_per && parseFloat(value.discount_in_per)
            ? parseFloat(value.discount_in_per).toFixed(2)
            : 0;
        };

        // for Percentage variance --------------------------------------
        const oldValue = getValue(percentageDiscountChart, 2);
        const newValue = getValue(percentageDiscountChart, 1);
        const percent = getPercentValue(oldValue, newValue);

        // for X Axis data ----------------------------------------------
        const dataOfXAxis = percentageDiscountChart?.percentageDiscountData.map(
          (item, index) => ({
            name: xAxisDates[index],
            uv: item.discount_in_per
              ? parseFloat(parseFloat(item.discount_in_per).toFixed(2))
              : 0,
          })
        );

        // for Y Axis Data ----------------------------------------------
        const minAndMaxValue =
          dataOfXAxis && dataOfXAxis.length > 0 && getMinMaxValues(dataOfXAxis);

        const temp = getYAxisTicks(minAndMaxValue.maxUv || 0);
        const yAxisOptions = temp.map((val) => parseFloat(val));

        const amt =
          !isNaN(percentageDiscountChart.totalPercentageDiscount) &&
          percentageDiscountChart.totalPercentageDiscount
            ? percentageDiscountChart.totalPercentageDiscount
            : 0;
        const totalValue = `${parseFloat(amt).toFixed(2)}%`;

        const obj = {
          percent: parseFloat(percent)?.toFixed(2),
          xAxisData: dataOfXAxis,
          minValue: minAndMaxValue.minUv,
          maxValue: minAndMaxValue.maxUv,
          yAxisOptions,
          type: "number",
          totalValue,
        };

        const newDashboardData = updateDataObject(
          date,
          activeType,
          merchantId,
          obj,
          chartKey,
          { ...oldDashboardData }
        );

        sessionStorage.setItem(
          "dashboardData",
          JSON.stringify(newDashboardData)
        );
        setPercentageDiscountChartData(() => obj);
      }
    }
  }, [percentageDiscountChart, xAxisDates]);

  useEffect(() => {
    if (percentageDiscountChartInView && !percentageDiscountChartState) {
      setPercentageDiscountChartState(() => true);
    }
  }, [percentageDiscountChartInView, percentageDiscountChartState]);

  // setting date range
  useEffect(() => {
    const dates = getDefaultDateRange(activeType, presentDate);
    setPercentageDiscountChartState(() => false);

    if (!dates || !dates.date_range) return;
    const data = getXAxisData(activeType, dates.date_range);
    setXAxisDates(data);
  }, [activeType, presentDate]);

  const fetchPercentageDiscount = async () => {
    try {
      setRefreshLoading(true);
      const dateRange = getDefaultDateRange(activeType, presentDate);
      if (
        dateRange &&
        dateRange?.date_range &&
        merchantId &&
        percentageDiscountChartState
      ) {
        const { endDate } = getStartDateAndEndDate(dateRange?.date_range);

        const data = {
          merchant_id: merchantId,
          start_date: getLastRangeStartDate(dateRange?.date_range),
          end_date: endDate,
          date_range: dateRange?.date_range,
        };
        await dispatch(getPercentageDiscount(data)).unwrap();
      }
    } catch (error) {
      if (error?.status === 401 || error?.response?.status === 401) {
        getUnAutherisedTokenMessage();
        handleCoockieExpire();
      } else if (error?.status === "Network Error") {
        // getNetworkError();
      }
    } finally {
      setRefreshLoading(false);
    }
  };

  useEffect(() => {
    const date = dateInYYYYMMDD(presentDate);
    const dashboardData = sessionStorage.getItem("dashboardData");
    const oldDashboardData = dashboardData ? JSON.parse(dashboardData) : {};

    const dashboardDataExists = dataExists(
      oldDashboardData,
      merchantId,
      activeType,
      date,
      chartKey
    );

    // setting already present api data from Session storage
    if (Object.keys(dashboardDataExists).length > 0) {
      setPercentageDiscountChartData(() => dashboardDataExists);
      return;
    }

    if (merchantId && percentageDiscountChartState) {
      fetchPercentageDiscount();
    }
  }, [merchantId, percentageDiscountChartState, presentDate, activeType]);

  // upon refresh - remove chart data from session storage
  useEffect(() => {
    if (refreshLoading) {
      deleteKey(merchantId, activeType, presentDate, chartKey);
    }
  }, [refreshLoading, presentDate, activeType, merchantId]);

  return (
    <Grid item xs={12} md={6} lg={6} ref={percentageDiscountChartRef}>
      <SpikeCharts
        title={"Discounts Given (Percentage)"}
        growth={percentageDiscountChartData.percent}
        mainOutlet={percentageDiscountChartData?.totalValue}
        amount={percentageDiscountChartData?.totalValue}
        activeType={activeType}
        xAxisData={percentageDiscountChartData.xAxisData}
        maxValue={percentageDiscountChartData.maxValue}
        minValue={percentageDiscountChartData.minValue}
        yAxisOptions={percentageDiscountChartData.yAxisOptions}
        type={percentageDiscountChartData.type}
        formatFunction={removeCurrencySign}
        loading={
          percentageDiscountChartData.yAxisOptions.length > 0
            ? false
            : percentageDiscountChart?.loading ||
              percentageDiscountChart?.isCancel
        }
        presentDate={presentDate}
        refreshAPI={fetchPercentageDiscount}
        refreshLoading={refreshLoading}
        yAxisText={"Discounts Given (Percentage)"}
      />
    </Grid>
  );
};

import React, { useState } from "react";
import ItemSalesFilter from "./ItemSalesFilter";

import NetSalesFilter from "./NetSalesFilter";
import ItemSalesDetails from "./ItemSalesDetails";
import DateRangeComponent from "../../../reuseableComponents/DateRangeComponent";
import useDebounce from "../../../hooks/useDebouncs";
import DashDateRangeComponent from "../../../reuseableComponents/DashDateRangeComponent";
import useMultiSelect from "../../../hooks/useMultiSelect";
import BrandLogic from "../../Brands/brandLogic";

const MainItemSales = ({ hide, setCSVData, setCSVHeader, setCSVFileName }) => {
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [OrderSourceData, setOrderSourceData] = useState(null);
  const [OrderTypeData, setOrderTypeData] = useState(null);
  const [SelectCatData, setSelectCatData] = useState(null);
  const [BrandData, setBrandData] = useState(null);
  const [TagData, setTagData] = useState(null);
  const [allOptionSelected, setAllOptionSelected] = useState(false);
  const [items, setItems] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [TagList, setGetTagList] = useState([]);
  // const { getBrandList } = BrandLogic();
  const [brandList, setBrandList] = useState([]);
  const {
    selectedItems: selectedBrandList,
    allOptionSelected: allBrandSelected,
    handleOptionClick: handleBrandClick,
  } = useMultiSelect(brandList);
  const {
    selectedItems: selectedTagList,
    allOptionSelected: allTagSelected,
    handleOptionClick: handleTagClick,
  } = useMultiSelect(TagList);

  const {
    selectedItems: selectedCategoryList,
    allOptionSelected: allCategorySelected,
    handleOptionClick: handleCategoryClick,
  } = useMultiSelect(categoryOptions);
  const [searchRecord, setSearchRecord] = useState("");
  const debouncedValue = useDebounce(searchRecord, 500);

  const handleDateRangeChange = (dateRange) => {
    setSelectedDateRange(dateRange);
  };

  const handleFilterDataChange = (
    OrderSource,
    OrderType,
    SelectCat,
    searchItems,
    brand,
    tag
  ) => {
    setOrderSourceData(OrderSource);
    setOrderTypeData(OrderType);
    setSelectCatData(SelectCat);
    setItems(searchItems);
    setBrandData(brand);
    setTagData(tag);
  };

  return (
    <>
      <ItemSalesFilter
        onFilterDataChange={handleFilterDataChange}
        setSearchRecord={setSearchRecord}
        debouncedValue={debouncedValue}
        hide={hide}
        allOptionSelected={allOptionSelected}
        setAllOptionSelected={setAllOptionSelected}
        categoryOptions={categoryOptions}
        setCategoryOptions={setCategoryOptions}
        TagList={TagList}
        setGetTagList={setGetTagList}
        selectedBrandList={selectedBrandList}
        allBrandSelected={allBrandSelected}
        handleBrandClick={handleBrandClick}
        selectedTagList={selectedTagList}
        allTagSelected={allTagSelected}
        handleTagClick={handleTagClick}
        selectedCategoryList={selectedCategoryList}
        allCategorySelected={allCategorySelected}
        handleCategoryClick={handleCategoryClick}
        brandList={brandList}
        setBrandList={setBrandList}
      />
      {!debouncedValue ? (
        hide ? (
          <DashDateRangeComponent onDateRangeChange={handleDateRangeChange} />
        ) : (
          <DateRangeComponent onDateRangeChange={handleDateRangeChange} />
        )
      ) : (
        ""
      )}

      <NetSalesFilter />

      <ItemSalesDetails
        selectedDateRange={selectedDateRange}
        searchRecord={searchRecord}
        setSearchRecord={setSearchRecord}
        debouncedValue={debouncedValue}
        OrderSourceData={OrderSourceData}
        OrderTypeData={OrderTypeData}
        SelectCatData={SelectCatData}
        items={debouncedValue}
        brand={BrandData}
        tag={TagData}
        setCSVHeader={setCSVHeader}
        setCSVData={setCSVData}
        setCSVFileName={setCSVFileName}
        selectedCategoryList={selectedCategoryList}
        allCategorySelected={allCategorySelected}
        selectedBrandList={selectedBrandList}
        allBrandSelected={allBrandSelected}
        selectedTagList={selectedTagList}
        allTagSelected={allTagSelected}
      />
    </>
  );
};

export default MainItemSales;

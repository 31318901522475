import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import SpikeCharts from "../SpikeCharts";
import { useSelector } from "react-redux";
import {
  removeCurrencySign,
  getDefaultDateRange,
  getXAxisData,
  getYAxisTicks,
  getStartDateAndEndDate,
  getPercentValue,
  getLastRangeStartDate,
  formatCurrency,
  dateInYYYYMMDD,
  dataExists,
  getMinMaxValues,
  updateDataObject,
  dataIsMatching,
  deleteKey,
} from "../../../Constants/utils";
import { useDispatch } from "react-redux";
import PasswordShow from "../../../Common/passwordShow";
import { getCustomerCount } from "../../../Redux/features/Dashboard/Charts/customerCountSlice";
import { priceFormate } from "../../../hooks/priceFormate";

export const CustomerCountChart = ({
  merchantId,
  activeType,
  presentDate,
  customerCountChartInView,
  customerCountChartRef,
}) => {
  const { handleCoockieExpire, getUnAutherisedTokenMessage } = PasswordShow();
  const dispatch = useDispatch();
  const [xAxisDates, setXAxisDates] = useState([]);
  const [customerCountChartState, setCustomerCountChartState] = useState(false);
  const customerCountChart = useSelector((state) => state.customerCountChart);
  const chartKey = "customerCountChart";
  const defaultObj = {
    percent: 0,
    xAxisData: [],
    minValue: 0,
    maxValue: 0,
    yAxisOptions: [],
    type: "number",
    totalValue: formatCurrency(0),
  };

  const [customerCountChartData, setCustomerCountChartData] =
    useState(defaultObj);
  const [refreshLoading, setRefreshLoading] = useState(false);

  useEffect(() => {
    if (customerCountChartInView && !customerCountChartState) {
      setCustomerCountChartState(() => true);
    }
  }, [customerCountChartInView, customerCountChartState]);

  useEffect(() => {
    const bool =
      customerCountChart &&
      customerCountChart?.customerCountData &&
      customerCountChart?.customerCountData?.length > 3 &&
      !customerCountChart.loading;

    const date = dateInYYYYMMDD(presentDate);
    const dashboardData = sessionStorage.getItem("dashboardData");
    const oldDashboardData = dashboardData ? JSON.parse(dashboardData) : {};

    const dashboardDataExists = dataExists(
      oldDashboardData,
      merchantId,
      activeType,
      date,
      chartKey
    );

    // check if new chart data is same as old one
    const chartsDataIsMatching = dataIsMatching(
      customerCountChart?.customerCountData,
      dashboardDataExists.xAxisData,
      "total_customer"
    );

    // setting already present api data from Session storage
    if (Object.keys(dashboardDataExists).length > 0 && chartsDataIsMatching) {
      return;
    } else {
      if (bool) {
        const getValue = (data, index) => {
          const value =
            data &&
            data?.customerCountData &&
            data?.customerCountData.length > 2 &&
            data?.customerCountData[data?.customerCountData?.length - index];
          return value?.total_customer && parseFloat(value?.total_customer)
            ? parseFloat(value?.total_customer).toFixed(2)
            : 0;
        };

        // for Percentage variance --------------------------------------
        const oldValue = getValue(customerCountChart, 2);
        const newValue = getValue(customerCountChart, 1);
        const percent = getPercentValue(oldValue, newValue);

        // for X Axis data ----------------------------------------------
        const dataOfXAxis = customerCountChart?.customerCountData?.map(
          (item, index) => ({
            name: xAxisDates[index],
            uv: item.total_customer
              ? parseFloat(parseFloat(item.total_customer).toFixed(2))
              : 0,
          })
        );

        // for Y Axis Data ----------------------------------------------
        const minAndMaxValue =
          dataOfXAxis &&
          dataOfXAxis?.length > 0 &&
          getMinMaxValues(dataOfXAxis);

        const temp = getYAxisTicks(minAndMaxValue?.maxUv || 0);
        const yAxisOptions = temp?.map((val) => parseFloat(val));

        const amt =
          !isNaN(customerCountChart?.totalCustomerCount) &&
          customerCountChart?.totalCustomerCount
            ? parseFloat(customerCountChart?.totalCustomerCount)
            : 0;
        const totalValue = priceFormate(parseFloat(amt));

        const obj = {
          percent: parseFloat(percent)?.toFixed(2),
          xAxisData: dataOfXAxis,
          minValue: minAndMaxValue.minUv,
          maxValue: minAndMaxValue.maxUv,
          yAxisOptions,
          type: "number",
          totalValue,
        };

        const newDashboardData = updateDataObject(
          date,
          activeType,
          merchantId,
          obj,
          chartKey,
          { ...oldDashboardData }
        );
        sessionStorage.setItem(
          "dashboardData",
          JSON.stringify(newDashboardData)
        );
        setCustomerCountChartData(() => obj);
      }
    }
  }, [customerCountChart, xAxisDates]);

  // setting date range & X Axis data
  useEffect(() => {
    const dates = getDefaultDateRange(activeType, presentDate);
    setCustomerCountChartState(() => false);

    if (!dates || !dates.date_range) return;
    const data = getXAxisData(activeType, dates?.date_range);
    setXAxisDates(data);
  }, [activeType, presentDate]);

  const fetchCustomerCount = async () => {
    try {
      setRefreshLoading(true);
      const dateRange = getDefaultDateRange(activeType, presentDate);
      if (dateRange && dateRange?.date_range) {
        const { endDate } = getStartDateAndEndDate(dateRange?.date_range);

        const data = {
          merchant_id: merchantId,
          start_date: getLastRangeStartDate(dateRange?.date_range),
          end_date: endDate,
          date_range: dateRange?.date_range,
        };
        await dispatch(getCustomerCount(data)).unwrap();
      }
    } catch (error) {
      if (error?.status == 401 || error?.response?.status === 401) {
        getUnAutherisedTokenMessage();
        handleCoockieExpire();
      } else if (error?.status == "Network Error") {
        // getNetworkError();
      }
    } finally {
      setRefreshLoading(false);
    }
  };

  // get customer count chart data
  useEffect(() => {
    const date = dateInYYYYMMDD(presentDate);
    const dashboardData = sessionStorage.getItem("dashboardData");
    const oldDashboardData = dashboardData ? JSON.parse(dashboardData) : {};

    const dashboardDataExists = dataExists(
      oldDashboardData,
      merchantId,
      activeType,
      date,
      chartKey
    );

    // setting already present api data from Session storage
    if (Object.keys(dashboardDataExists).length > 0) {
      setCustomerCountChartData(() => dashboardDataExists);
      return;
    }

    if (merchantId && customerCountChartState) {
      fetchCustomerCount();
    }
  }, [merchantId, customerCountChartState, presentDate, activeType]);

  // upon refresh - remove chart data from session storage
  useEffect(() => {
    if (refreshLoading) {
      deleteKey(merchantId, activeType, presentDate, chartKey);
    }
  }, [refreshLoading, presentDate, activeType, merchantId]);

  return (
    <Grid item xs={12} md={6} lg={6} ref={customerCountChartRef}>
      <SpikeCharts
        title={"Unique Customers"}
        growth={customerCountChartData?.percent}
        mainOutlet={customerCountChartData?.totalValue}
        amount={customerCountChartData?.totalValue}
        activeType={activeType}
        xAxisData={customerCountChartData?.xAxisData}
        maxValue={customerCountChartData?.maxValue}
        minValue={customerCountChartData?.minValue}
        yAxisOptions={customerCountChartData?.yAxisOptions}
        type={customerCountChartData?.type}
        formatFunction={removeCurrencySign}
        loading={
          customerCountChartData.yAxisOptions.length > 0
            ? false
            : customerCountChart?.loading || customerCountChart?.isCancel
        }
        presentDate={presentDate}
        refreshAPI={fetchCustomerCount}
        refreshLoading={refreshLoading}
        yAxisText={"Unique Customers"}
      />
    </Grid>
  );
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, NEW_CUSTOMER_LIST } from "../../../Constants/Config";

const initialState = {
  loading: false,
  newCustomerList: [],
  status: false,
  error: "",
  offset: 0,
  limit: 10,
  hasMore: true,
};

export const fetchNewCustomerList = createAsyncThunk(
  "customerSlice/fetchNewCustomerList",
  async (data, { rejectWithValue }) => {
    try {
      const { token, ...dataNew } = data;
      const response = await axios.post(BASE_URL + NEW_CUSTOMER_LIST, dataNew, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log("fetchNewCustomerList ", response.data);
      if (
        response.data.status === true &&
        Array.isArray(response?.data?.result)
      ) {
        console.log("inside ", response);
        const arr = response?.data?.result;
        const status = response.data.status;
        const totalCustomerCount = response.data.total_count;
        return { arr, status, totalCustomerCount };
      } else if (response.data.status === false) {
        const arr = [];
        const status = false;
        return { arr, status };
      }
    } catch (error) {
      const customError = {
        message: error.message,
        status: error.response ? error.response.status : "Network Error",
        data: error.response ? error.response.data : null,
      };
      return rejectWithValue(customError);
    }
  }
);

const customerSlice = createSlice({
  name: "customerSlice",
  initialState,
  reducers: {
    emptyCustomer: (state, action) => {
      // console.log("empty action payload: ", action.payload);
      state.productsData = [];
      state.offset = 0;
      state.limit = 10;
      state.hasMore = true;
      state.page = 1;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchNewCustomerList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchNewCustomerList.fulfilled, (state, action) => {
      state.loading = false;
      // state.newCustomerList = action.payload.arr;

      state.status = action?.payload?.status;
      state.error = "";
      state.hasMore =
        action.payload.totalCustomerCount > state.newCustomerList.length;

      // state.limit === 10 ||
      // action?.payload?.arr.length > state.newCustomerList.length;
      if (action.payload.totalCustomerCount > state.newCustomerList.length) {
        state.limit += 10; // Increment only if there are new products
      }
      if (Array.isArray(action?.payload?.arr)) {
        state.newCustomerList = action.payload.arr;
      }
    });
    builder.addCase(fetchNewCustomerList.rejected, (state, action) => {
      state.loading = false;
      state.newCustomerList = [];
      state.error = action.error.message;
    });
  },
});
export const { emptyCustomer } = customerSlice.actions;
export default customerSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  BASE_URL,
  GET_TOP_CATEGORY_BY_QUANTITY_SOLD,
  GET_TOP_CATEGORY_BY_SALES_AMOUNT,
  GET_TOP_CATEGORY_SALES_REPORT_LIST,
  SALES_REPORT_LIST,
} from "../../../../Constants/Config";
import { useAuthDetails } from "../../../../Common/cookiesHelper";
const initialState = {
  loading: false,
  SalesReportData: [],
  successMessage: "",
  error: "",

  qtyLoading: false,
  qtyData: [],
  qtyError: "",

  salesAmtLoading: false,
  salesAmtData: [],
  salesAmtError: "",

  reportListLoading: false,
  reportListData: [],
  reportListError: "",
};

// Generate pening , fulfilled and rejected action type
export const fetchSalesReportData = createAsyncThunk(
  "SalesReportList/fetchSalesReportData.",
  async (data, { rejectWithValue }) => {
    const { userTypeData } = useAuthDetails();
    try {
      const { token, ...otherUserData } = userTypeData;
      // console.log(BASE_URL + VENDORS_SALES_REPORT)
      const response = await axios.post(
        BASE_URL + SALES_REPORT_LIST,
        { ...data, ...otherUserData },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response)
      if (response.data.status === true) {
        // console.log(response.data.sales_data
        //     )
        return response.data.result;
      }
    } catch (error) {
      // throw new Error(error.response.data.message);
      const customError = {
        message: error.message,
        status: error.response ? error.response.status : "Network Error",
        data: error.response ? error.response.data : null,
      };
      return rejectWithValue(customError);
    }
  }
);

// fetch (Category) Sales Report ()
export const fetchCategoryQtySoldSalesReport = createAsyncThunk(
  "SalesReportList/fetchCategoryQtySoldSalesReport.",
  async (data, { rejectWithValue }) => {
    const { userTypeData } = useAuthDetails();
    try {
      const { token, ...otherUserData } = userTypeData;
      const response = await axios.post(
        BASE_URL + GET_TOP_CATEGORY_BY_QUANTITY_SOLD,
        { ...data, ...otherUserData },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      const customError = {
        message: error.message,
        status: error.response ? error.response.status : "Network Error",
        data: error.response ? error.response.data : null,
      };
      return rejectWithValue(customError);
    }
  }
);

export const fetchCategorySalesAmountSalesReport = createAsyncThunk(
  "SalesReportList/fetchCategorySalesAmountSalesReport.",
  async (data, { rejectWithValue }) => {
    const { userTypeData } = useAuthDetails();
    try {
      const { token, ...otherUserData } = userTypeData;
      const response = await axios.post(
        BASE_URL + GET_TOP_CATEGORY_BY_SALES_AMOUNT,
        { ...data, ...otherUserData },
        {
          // throw new Error(error.response.data.message);
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      const customError = {
        message: error.message,
        status: error.response ? error.response.status : "Network Error",
        data: error.response ? error.response.data : null,
      };
      return rejectWithValue(customError);
    }
  }
);

export const fetchCategorySalesReportList = createAsyncThunk(
  "SalesReportList/fetchCategorySalesReportList.",
  async (data, { rejectWithValue }) => {
    const { userTypeData } = useAuthDetails();
    try {
      const { token, ...otherUserData } = userTypeData;
      // console.log(BASE_URL + VENDORS_SALES_REPORT)
      const response = await axios.post(
        BASE_URL + GET_TOP_CATEGORY_SALES_REPORT_LIST,
        { ...data, ...otherUserData },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const categorySalesReportListUpdated = response?.data?.category_data.map(
        (obj) => ({
          ...obj,
          netSales: (obj?.net_sales || 0)
          // (obj?.sale_amount || 0) - parseFloat(obj.discount_amt || 0)
          ,
          grossProfit: (obj.gross_profit || 0)
            // (obj.sale_amount || 0) - parseFloat(obj.discount_amt || 0) - (obj.costs || 0)
            ,
          grossMargin:
          // obj?.costs > 0
          obj?.net_sales > 0
          ?
          (( (obj.gross_profit || 0) /(obj.net_sales || 0)) *100)
          :100 
            // (( (obj.gross_profit || 0) /(obj.costs || 0)) *100)
            // :100 
            ,
          // grossMargin:
          // obj?.costs > 0
          //   ? (
          //       ((obj?.sale_amount || 0) - (obj?.discount_amt || 0) - (obj?.costs || 0)) /
          //       obj?.costs
          //     ) * 100
          //   : 100,
        })
      );
      return categorySalesReportListUpdated;
    } catch (error) {
      const customError = {
        message: error.message,
        status: error.response ? error.response.status : "Network Error",
        data: error.response ? error.response.data : null,
      };
      return rejectWithValue(customError);
    }
  }
);

const SalesReportSlice = createSlice({
  name: "SalesReportList",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchSalesReportData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSalesReportData.fulfilled, (state, action) => {
      state.loading = false;
      state.SalesReportData = action.payload;
      state.error = "";
    });
    builder.addCase(fetchSalesReportData.rejected, (state, action) => {
      state.loading = false;
      state.SalesReportData = {};
      state.error = action.error.message;
    });

    // top_category_by_qty_sold
    builder.addCase(fetchCategoryQtySoldSalesReport.pending, (state) => {
      state.qtyLoading = true;
    });
    builder.addCase(
      fetchCategoryQtySoldSalesReport.fulfilled,
      (state, action) => {
        state.qtyLoading = false;
        state.qtyData = action.payload.category_data;
        state.qtyError = "";
      }
    );
    builder.addCase(
      fetchCategoryQtySoldSalesReport.rejected,
      (state, action) => {
        state.qtyLoading = false;
        state.qtyData = {};
        state.qtyError = action.error.message;
      }
    );

    // top_category_by_sales_amt
    builder.addCase(fetchCategorySalesAmountSalesReport.pending, (state) => {
      state.salesAmtLoading = true;
    });
    builder.addCase(
      fetchCategorySalesAmountSalesReport.fulfilled,
      (state, action) => {
        state.salesAmtData = action.payload.category_data;
        state.salesAmtError = "";
        state.salesAmtLoading = false;
      }
    );
    builder.addCase(
      fetchCategorySalesAmountSalesReport.rejected,
      (state, action) => {
        state.salesAmtData = {};
        state.salesAmtError = action.error.message;
        state.salesAmtLoading = false;
      }
    );

    //category_sales_report_list
    builder.addCase(fetchCategorySalesReportList.pending, (state) => {
      state.reportListLoading = true;
    });
    builder.addCase(fetchCategorySalesReportList.fulfilled, (state, action) => {
      state.reportListData = action.payload;
      state.reportListError = "";
      state.reportListLoading = false;
    });
    builder.addCase(fetchCategorySalesReportList.rejected, (state, action) => {
      state.reportListData = {};
      state.reportListError = action.error.message;
      state.reportListLoading = false;
    });
  },
});

export default SalesReportSlice.reducer;

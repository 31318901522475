import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  BASE_URL,
  STORE_CREDIT_REPORT,
  STORE_CREDIT_REPORT_COUNT,
} from "../../../../Constants/Config";

const initialState = {
  loading: false,
  StoreCreditReportArr: [],
  status: false,
  successMessage: "",
  error: "",
  offset: 0,
  limit: 10,
  hasMore: true,

  countLoading: false,
  countData: 0,
};

export const fetchStoreCreditReportArr = createAsyncThunk(
  "StoreCreditReport/fetchStoreCreditReportArr",
  async (data, { rejectWithValue }) => {
    try {
      const { token, ...dataNew } = data;
      const res = await axios.post(BASE_URL + STORE_CREDIT_REPORT, dataNew, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(res.data);
      if (res.data.status === true) {
        const arr = res.data.store_credit_data;
        const status = res.data.status;
        const totalCustomerCount = res.data.total_count;

        return { arr, status, totalCustomerCount };
      } else if (res.data.status === false) {
        console.log("inside else if");
        const arr = [];
        const status = false;
        return { arr, status };
      }
    } catch (error) {
      const customError = {
        message: error.message,
        status: error.response ? error.response.status : "Network Error",
        data: error.response ? error.response.data : null,
      };
      return rejectWithValue(customError);
    }
  }
);

export const fetchStoreCreditReportCount = createAsyncThunk(
  "products/fetchStoreCreditReportCount",
  async (payload, { rejectWithValue }) => {
    const { token, ...dataNew } = payload;
    try {
      const response = await axios.post(
        BASE_URL + STORE_CREDIT_REPORT_COUNT,
        dataNew,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.status) {
        return response?.data?.total_count;
      }
    } catch (error) {
      // throw new Error("Internal Server Error");
      const customError = {
        message: error.message,
        status: error.response ? error.response.status : "Network Error",
        data: error.response ? error.response.data : null,
      };
      return rejectWithValue(customError);
    }
  }
);

const StoreCreditReportSlice = createSlice({
  name: "StoreCreditReport",
  initialState,
  reducers: {
    emptyStoreCreditReportData: (state, action) => {
      state.loading = false;
      state.StoreCreditReportArr = [];
      state.status = false;
      state.successMessage = "";
      state.error = "";
      state.offset = 0;
      state.limit = 10;
      state.hasMore = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStoreCreditReportArr.pending, (state) => {
      state.loading = true;
      state.StoreCreditReportArr = [];
    });
    builder.addCase(fetchStoreCreditReportArr.fulfilled, (state, action) => {
      state.loading = false;
      state.StoreCreditReportArr = action.payload.arr;
      state.status = action.payload.status;
      state.error = "";

      // state.hasMore =
      //   +action?.payload?.totalCustomerCount >
      //   +state?.StoreCreditReportArr?.length;

      // if (
      //   +action?.payload?.totalCustomerCount >
      //   +state?.StoreCreditReportArr?.length
      // ) {
      //   state.limit += 10; // Increment only if there are new products
      // }
      // if (Array.isArray(action?.payload?.arr)) {
      //   state.StoreCreditReportArr = action.payload.arr;
      // }
    });
    builder.addCase(fetchStoreCreditReportArr.rejected, (state, action) => {
      state.loading = false;
      state.StoreCreditReportArr = [];
      state.error = action.error.message;
    });

    builder.addCase(fetchStoreCreditReportCount.pending, (state) => {
      state.countLoading = true;
      state.countData = [];
    });
    builder.addCase(fetchStoreCreditReportCount.fulfilled, (state, action) => {
      state.countLoading = false;
      state.countData = action.payload;
    });
    builder.addCase(fetchStoreCreditReportCount.rejected, (state, action) => {
      state.countLoading = false;
      state.countData = [];
    });
  },
});
export const { emptyStoreCreditReportData } = StoreCreditReportSlice.actions;

export default StoreCreditReportSlice.reducer;

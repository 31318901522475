import { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addToEmployeeList } from "../../../Redux/features/StoreSettings/AddEmployee/AddEmployeeSlice";
import Validation from "../../../Constants/Validation";
import {
  BASE_URL,
  ADDEDIT_EMPLOYEE,
  CHECK_EXISTING_PIN,
} from "../../../Constants/Config";
import { useAuthDetails } from "../../../Common/cookiesHelper";
import { ToastifyAlert } from "../../../CommonComponents/ToastifyAlert";
import useDebounce from "../../../hooks/useDebouncs";
import {
  checkEmailValidation,
  checkPinValidation,
  chekPinValidation,
} from "../../../Redux/features/EmployeeList/EmployeeListSlice";
import PasswordShow from "../../../Common/passwordShow";

const AddEmployeeFormLogic = ({ employeeList, setSuggestionList, closeModal, setVisible, setEmployeeId }) => {
  const [backendAccess, setbackendAccess] = useState(false);
  const [posAccess, setPosAccess] = useState(false);
  const dispatch = useDispatch();
  const {
    validateFirstName,
    validateEmpFirstName,
    validateLastName,
    validateEmail,
    validatePhoneNumber,
    validatePinNumber,
    validateWages,
    Address_line_1,
    validateCity,
    validateState,
    validateZipCode,
    validatePasswordAndenable,
  } = Validation();
  const [submitmessage, setsubmitmessage] = useState("");
  // const Navigate = useNavigate();
  const scrollRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const [pinData, setPinData] = useState("");
  const [emailData, setEmailData] = useState("");

  const pinDebouncedValue = useDebounce(pinData, 300);
  const emailDebouncedValue = useDebounce(emailData, 300);

  const { LoginGetDashBoardRecordJson, LoginAllStore, userTypeData } =
    useAuthDetails();
  const { handleCoockieExpire, getUnAutherisedTokenMessage, getNetworkError } =
    PasswordShow();
  let AuthDecryptDataDashBoardJSONFormat = LoginGetDashBoardRecordJson;
  const merchant_id = AuthDecryptDataDashBoardJSONFormat?.data?.merchant_id;

  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    pin: "",
    wages: "",
    address_line_1: "",
    passwordSource: "manual",
    send_password_via_mail: 0,
    address_line_2: "",
    city: "",
    zipcode: "",
    state: "",
    backendAccess: false,
    enablePOSAccess: false,
    employeePassword: "",
    errors: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      pin: "",
      wages: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      zipcode: "",
      state: "",
      employeePassword: "",
    },
  });

  const emailExists1 = `Email already used by an Employee, Please change your email.`;
  const emailExists2 = `Email already used by an Admin, Please change your email.`;

  const checkValidEmailAPI = async () => {
    const obj = {
      email: emailDebouncedValue?.trim(),
      id: "",
      ...userTypeData,
    };
    try {
      const data = await dispatch(checkEmailValidation(obj)).unwrap();
      if (data?.message === emailExists1 || data?.message === emailExists2) {
        setValues((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            email: "Email Id Already Exist",
          },
        }));
      } else if (data?.message === "New User") {
        const EmailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-_]+\.[A-Z]{2,4}$/i;
        const emoji =
          /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FAB0}-\u{1FABF}\u{1FAC0}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}]/gu;

        const email = emailDebouncedValue?.trim();
        setValues((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            // email: prev.errors.email ? prev.errors.email : "",
            email: !EmailReg.test(email)
              ? "Enter valid email address"
              : emoji.test(email)
                ? "Emoji not allowed"
                : "",
          },
        }));
      }
    } catch (error) {
      if (error?.status == 401 || error?.response?.status === 401) {
        getUnAutherisedTokenMessage();
        handleCoockieExpire();
      } else if (error?.status == "Network Error") {
        getNetworkError();
      }
    }
  };

  const checkValidPinAPI = async () => {
    const obj = {
      email: LoginGetDashBoardRecordJson?.data?.email?.trim(),
      pin: pinDebouncedValue?.trim(),
      id: "",
      ...userTypeData,
    };
    try {
      const data = await dispatch(checkPinValidation(obj)).unwrap();
      if (data?.message === "The Pin already exists") {
        setSuggestionList(data?.suggested_pins ?? []);
        setValues((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            pin: "Pin already exist",
          },
        }));
      } else if (data?.message === "New Pin") {
        setSuggestionList([]);
        setValues((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            pin: prev.errors.pin ? prev.errors.pin : "",
          },
        }));
      }
    } catch (error) {
      setSuggestionList([]);
      if (error?.status == 401 || error?.response?.status === 401) {
        getUnAutherisedTokenMessage();
        handleCoockieExpire();
      } else if (error?.status == "Network Error") {
        getNetworkError();
      }
    }
  };

  useEffect(() => {
    checkValidEmailAPI();
  }, [emailDebouncedValue]);

  useEffect(() => {
    checkValidPinAPI();
  }, [pinDebouncedValue]);

  useEffect(() => {
    if (!values?.backendAccess && !values?.enablePOSAccess) {
      setValues((prev) => ({
        ...prev,
        ['employeePassword']: '',
        errors: {
          ...prev.errors,
          ["email"]:
            prev.errors.email === "Email Address is required"
              ? ""
              : prev.errors.email,
          ["employeePassword"]:'',
        },
      }));
    }
  }, [values?.backendAccess, values?.enablePOSAccess]);

  const handleChangePinValue=(value)=>{
    setValues((prev)=>({
      ...prev,
      ['pin']: value,
      errors:{
        ...prev.errors,
        ['pin']: "",
      }
    }))
  }

  const handleKeyPress = (e) => {
    // Allow only numeric characters (key codes 48 to 57) and backspace (key code 8)
    if ((e.charCode < 48 || e.charCode > 57) && e.charCode !== 8) {
      e.preventDefault();
    }
  };
  // ===================== PIN CHECK
  // const checkValidPin = async (data) => {
  //   const { token, ...newData } = userTypeData;
  //   const dataNew = { ...data, ...newData };
  //   try {
  //     const response = await axios.post(
  //       BASE_URL + CHECK_EXISTING_PIN,
  //       dataNew,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     return response.data; // Assuming this data indicates whether email is valid or not
  //   } catch (error) {
  //     console.error("Error validating email:", error);
  //     throw error;
  //   }
  // };
  // ================== PIN CHECK

  // const handleBlur = async (name) => {
  //   if (name == "pin") {
  //     if (merchant_id !== "" && !!merchant_id && values.pin !== "") {
  //       try {
  //         let data = { merchant_id: merchant_id, pin: values.pin };
  //         let response = await checkValidPin(data);
  //         if (response.status == true) {
  //           setValues((prevValues) => ({
  //             ...prevValues,
  //             errors: {
  //               ...prevValues.errors,
  //               pin: "",
  //             },
  //           }));
  //         } else {
  //           setValues((prevValues) => ({
  //             ...prevValues,
  //             errors: {
  //               ...prevValues.errors,
  //               pin: "Pin is Exists",
  //             },
  //           }));
  //         }
  //       } catch (error) {}
  //     }
  //   }
  // };

  // const handleAddEmployeeInput = async (event) => {
  //   let { errors } = values;
  //   const fieldName = event.target.name;
  //   const fieldValue = event.target.value;

  //   switch (fieldName) {
  //     case "firstname":
  //       await validateFirstName(fieldValue, errors);
  //       break;
  //     case "lastname":
  //       await validateLastName(fieldValue, errors);
  //       break;
  //     case "email":
  //       await validateEmail(fieldValue, errors);
  //       break;
  //     case "phone":
  //       await validatePhoneNumber(fieldValue, errors);
  //       break;
  //     case "pin":
  //       await validatePinNumber(fieldValue, errors, employeeList);
  //       break;
  //     case "wages":
  //       await validateWages(fieldValue, errors);
  //       break;
  //     case "address_line_1":
  //       await Address_line_1(fieldValue, errors);
  //       break;
  //     case "city":
  //       await validateCity(fieldValue, errors);
  //       break;
  //     case "zipcode":
  //       await validateZipCode(fieldValue, errors);
  //       break;
  //     case "state":
  //       await validateState(fieldValue, errors);
  //       break;
  //     default:
  //       break;
  //   }

  //   setValues((prevValues) => ({
  //     errors,
  //     ...prevValues,
  //     [fieldName]: fieldValue,
  //   }));
  // };
  const handleAddEmployeeInput = async (event) => {
    let { errors } = values;
    let fieldName = event.target.name;
    let fieldValue = event.target.value;

    switch (fieldName) {
      case "firstname":
        validateEmpFirstName(fieldValue, errors);
        break;
      case "lastname":
        validateLastName(fieldValue, errors);
        break;
      case "email":
        validateEmail(fieldValue?.trim(), errors, values);
        setEmailData(fieldValue);
        break;
      // case "phone":
      //   validatePhoneNumber(fieldValue, errors);
      //   break;
      case "pin":
        validatePinNumber(fieldValue, errors, employeeList);
        setPinData(fieldValue);
        break;
      case "wages":
        validateWages(fieldValue, errors);
        break;
      case "address_line_1":
        Address_line_1(fieldValue, errors);
        break;
      case "city":
        validateCity(fieldValue, errors);
        break;
      case "zipcode":
        validateZipCode(fieldValue, errors);
        break;
      case "state":
        validateState(fieldValue, errors);
        break;
      case "employeePassword":
        validatePasswordAndenable(
          values.backendAccess,
          fieldValue,
          values?.employeePassword,
          errors
        );
        break;
      default:
        break;
    }
    if (fieldName === "backendAccess") {
      // const newbackendAccess = !backendAccess;
      // setbackendAccess(newbackendAccess);
      fieldValue = fieldValue === "true" ? true : false;;
    }
    if (fieldName === "enablePOSAccess") {
      // const newPosAccess = !posAccess;
      // setPosAccess(newPosAccess);
      fieldValue = fieldValue === "true" ? true : false;

    }
    // if (fieldName === "send_password_via_mail") {
    //   if (fieldValue === "0") {
    //     fieldValue = "1";
    //   } else if (fieldValue === "1") {
    //     fieldValue = "0";
    //   }
    // }

    setValues((prevValues) => ({
      errors,
      ...prevValues,
      [fieldName]: fieldValue,
    }));
  };
  const handlePhoneInput = (event) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setValues((prevValues) => ({
      ...prevValues,
      phone: value,
    }));
  };
  const handlePinInput = (event) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setValues((prevValues) => ({
      ...prevValues,
      pin: value,
    }));
  };
  const handleZipInput = (event) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setValues((prevValues) => ({
      ...prevValues,
      zipcode: value,
    }));
  };

  const normalizeSpace = (str) => {
    if (!str) return ''; 
    return str.trim().replace(/\s+/g, ' '); 
  };

  const handleAddEmployee = async (e) => {
    e.preventDefault();
    const { errors } = values;
    await validateFirstName(values.firstname, errors);
    await validateLastName(values.lastname, errors);
    await validateEmail(values.email?.trim(), errors, values);
    // await validatePhoneNumber(values.phone, errors);
    await validatePinNumber(values.pin, errors, employeeList);
    await validateWages(values.wages, errors);
    await Address_line_1(values.address_line_1, errors);
    await validateCity(values.city, errors);
    await validateZipCode(values.zipcode, errors);
    await validateState(values.state, errors);
    await validatePasswordAndenable(
      values.backendAccess,
      values?.passwordSource,
      values?.employeePassword,
      errors,
      values?.enablePOSAccess
    );

    if (
      errors.firstname === "" &&
      errors.lastname === "" &&
      errors.email === "" &&
      errors.phone === "" &&
      errors.pin === "" &&
      errors.wages === "" &&
      errors.address_line_1 === "" &&
      errors.city === "" &&
      errors.zipcode === "" &&
      errors.state === "" &&
      errors.employeePassword === ""
    ) {
      const data = {
        merchant_id: merchant_id,
        // admin_id: "MAL0100CA",
        admin_id: "",
        f_name: normalizeSpace(values.firstname),
        l_name: !!values.lastname ? normalizeSpace(values.lastname) : "",
        email: values.email.toLowerCase().trim(),
        phone: values.phone,
        pin: values.pin,
        wages: values.wages,
        address_line_1: normalizeSpace(values.address_line_1),
        city: values.city,
        zip: values.zipcode,
        state: values.state,
        backendAccess: values.backendAccess,
        enablePOSAccess: values.enablePOSAccess,
        employeePassword: values.employeePassword,
        // passwordSource: values.passwordSource,
        // send_password_via_mail: values.send_password_via_mail,
        token_id: userTypeData?.token_id,
        login_type: userTypeData?.login_type,
      };
      setLoader(true);

      try {
        const response = await axios.post(BASE_URL + ADDEDIT_EMPLOYEE, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userTypeData?.token}`,
          },
        });
        if (response.data.status === true) {
          setLoader(false);
          ToastifyAlert("Added Successfully", "success");
          const latest_employee = response.data.inserted_data;
          dispatch(addToEmployeeList(latest_employee));
          // setShowModal(false);
          setVisible("EmployeePermission");
          setEmployeeId(latest_employee?.id);
          closeModal();
          // values.firstname = "";
          // values.lastname = "";
          // values.email = "";
          // values.phone = "";
          // values.pin = "";
          // values.wages = "";
          // values.address_line_1 = "";
          // values.city = "";
          // values.zipcode = "";
          // values.passwordSource = "manual";
          // values.send_password_via_mail = 0;
          // values.state = "";
          // values.backendAccess = false;
          // values.enablePOSAccess = false;
          // values.employeePassword = "";
          // values.errors.firstname = "";
          // values.errors.lastname = "";
          // values.errors.email = "";
          // values.errors.phone = "";
          // values.errors.pin = "";
          // values.errors.wages = "";
          // values.errors.address_line_1 = "";
          // values.errors.city = "";
          // values.errors.zipcode = "";
          // values.errors.state = "";
          // values.errors.employeePassword = "";
          // Navigate("/");
        } else if (response?.data?.code == 204) {
          setLoader(false);
          ToastifyAlert(response.data.message, "error");
        } else if (response.data?.code == 400) {
          errors.pin = response.data.message;
          setLoader(false);
          setShowModal(true);
        } else if (response.data?.code == 301) {
          errors.pin = response.data.message;
          setLoader(false);
          setShowModal(true);
        } else {
          setLoader(false);
          setsubmitmessage(response.data.message);
          setShowModal(true);
        }
      } catch (error) {
        return new Error(error);
      }
    }

    setValues((prevState) => {
      return {
        ...prevState,
        errors,
      };
    });
  };

  return {
    handleAddEmployeeInput,
    handlePhoneInput,
    handlePinInput,
    handleZipInput,
    values,
    handleAddEmployee,
    submitmessage,
    setsubmitmessage,
    showModal,
    setShowModal,
    scrollRef,
    handleKeyPress,
    loader,
    handleChangePinValue,
    // handleBlur,
    setValues,
    setPinData,
    setEmailData
  };
};

export default AddEmployeeFormLogic;

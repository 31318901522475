import React, { useEffect, useMemo, useState } from "react";
import "../../../Styles/EmployeeWorking.css";
import { fetchinstantactivityData } from "../../../Redux/features/InstantActivity/instantactivitySlice";
import { useSelector, useDispatch } from "react-redux";
import { SortTableItemsHelperFun } from "../../../helperFunctions/SortTableItemsHelperFun";

import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import sortIcon from "../../../Assests/Category/SortingW.svg";
import NoDataFound from "../../../reuseableComponents/NoDataFound";
import useDelayedNodata from "../../../hooks/useDelayedNoData";
import { format } from "date-fns";

import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import { PERMISSIONS } from "../../../Constants/permission";

const StyledTable = styled(Table)(({ theme }) => ({
  padding: 2, // Adjust padding as needed
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#253338",
    color: theme.palette.common.white,
    fontFamily: "CircularSTDMedium",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "CircularSTDBook !important",
  },
  [`&.${tableCellClasses.table}`]: {
    fontSize: 14,
    fontFamily: "CircularSTDBook !important",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {},
  "&:last-child td, &:last-child th": {},
  "& td, & th": {
    // border: "none",
    borderBottomWidth: 1,
    borderBottomColor: "rgba(224, 224, 224, 1)",
  },
}));

const MainInstantDetails = ({
  setPoActivityListData,
  instantactivityDataState,
  scrollForProduct,
  hasMore,
  loading,
  isLoading
}) => {
  const [sortOrder, setSortOrder] = useState("asc");
  const { permissions, status, error } = useSelector(
    (state) => state.permissions
  );
  const sortByItemName = (type, name) => {
    if (instantactivityDataState.length > 0) {
      const { sortedItems, newOrder } = SortTableItemsHelperFun(
        instantactivityDataState,
        type,
        name,
        sortOrder
      );
      setSortOrder(newOrder);
      setPoActivityListData(sortedItems);
    }
  };
  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const dateOptions = { year: "numeric", month: "short", day: "numeric" };
    const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedDate = date.toLocaleDateString("en-US", dateOptions);
    return `${formattedDate}`;
  };

  const renderLoader = () => {
    return (
      <table>
        <tbody>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((row) => (
            <tr key={row} style={{ background: "rgba(0, 0, 0, 0.04)" }}>
              {["", "", "", "", "", "", ""].map((col, index) => (
                <td key={index}>
                  <Skeleton />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const handleVarientNavigation = (product) => {
    return {
      link: `/inventory/products/edit/${product.product_id}`,
      data: {
        productId: product.variant_id ? product.variant_id : product.product_id,
        variantName: product.variant,
        from: window.location.href,
      },
    };
  };

  const allowToEditProduct = useMemo(() => {
    return (
      !permissions.length ||
      permissions.indexOf(PERMISSIONS.MENU_EDIT_ITEMS) > -1
    );
  }, [permissions]);

  return (
    <>
      <Grid container className="box_shadow_div">
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <InfiniteScroll
                dataLength={instantactivityDataState.length}
                next={scrollForProduct}
                hasMore={hasMore}
                loader={
                  isLoading && <div className="custom-table">{renderLoader()}</div>
                }
              >
                <TableContainer>
                  <StyledTable
                    sx={{ minWidth: 500 }}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <StyledTableCell>
                        <button
                          className="flex items-center"
                          onClick={() => sortByItemName("str", "title")}
                        >
                          <p>Instant PO Info</p>
                          <img src={sortIcon} alt="" className="pl-1" />
                        </button>
                      </StyledTableCell>

                      <StyledTableCell>
                        <button
                          className="flex items-center"
                          onClick={() => sortByItemName("str", "emp_name")}
                        >
                          <p>Employee</p>
                          <img src={sortIcon} alt="" className="pl-1" />
                        </button>
                      </StyledTableCell>

                      <StyledTableCell>
                        <button
                          className="flex items-center"
                          onClick={() => sortByItemName("num", "current_qty")}
                        >
                          <p>Before Adjust Qty</p>
                          <img src={sortIcon} alt="" className="pl-1" />
                        </button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <button
                          className="flex items-center"
                          onClick={() => sortByItemName("num", "qty")}
                        >
                          <p>Adjust Qty</p>
                          <img src={sortIcon} alt="" className="pl-1" />
                        </button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <button
                          className="flex items-center"
                          onClick={() => sortByItemName("num", "qty")}
                        >
                          <p>After Adjust Qty</p>
                          <img src={sortIcon} alt="" className="pl-1" />
                        </button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <button
                          className="flex items-center"
                          onClick={() => sortByItemName("num", "price")}
                        >
                          <p>Per Item Cost</p>
                          <img src={sortIcon} alt="" className="pl-1" />
                        </button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <button
                          className="flex items-center"
                          onClick={() => sortByItemName("num", "price")}
                        >
                          <p>Total Cost</p>
                          <img src={sortIcon} alt="" className="pl-1" />
                        </button>
                      </StyledTableCell>
                    </TableHead>
                    <TableBody>
                      {loading && instantactivityDataState.length === 0 ? (
                        <></>
                        // <StyledTableRow>
                        //   <StyledTableCell colSpan={7}>
                        //     <div className="custom-table">{renderLoader()}</div>
                        //   </StyledTableCell>
                        // </StyledTableRow>
                      ) : instantactivityDataState.length > 0 ? (
                        instantactivityDataState?.map(
                          (instantactivity, index) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell>
                                <div>
                                  <p>
                                    {allowToEditProduct ? (
                                      <Link
                                        to={{
                                          pathname:
                                            handleVarientNavigation(
                                              instantactivity
                                            ).link,
                                          state:
                                            handleVarientNavigation(
                                              instantactivity
                                            ).data,
                                        }}
                                      >
                                        {instantactivity.title
                                          ? instantactivity.title
                                              .charAt(0)
                                              .toUpperCase() +
                                            instantactivity.title.slice(1)
                                          : ""}
                                      </Link>
                                    ) : instantactivity.title ? (
                                      instantactivity.title
                                        .charAt(0)
                                        .toUpperCase() +
                                      instantactivity.title.slice(1)
                                    ) : (
                                      ""
                                    )}
                                  </p>
                                  <p className="text-[#0A64F9]">
                                    {allowToEditProduct ? (
                                      <Link
                                        to={{
                                          pathname:
                                            handleVarientNavigation(
                                              instantactivity
                                            ).link,
                                          state:
                                            handleVarientNavigation(
                                              instantactivity
                                            ).data,
                                        }}
                                      >
                                        {instantactivity.variant
                                          ? instantactivity.variant
                                              .charAt(0)
                                              .toUpperCase() +
                                            instantactivity.variant.slice(1)
                                          : ""}
                                      </Link>
                                    ) : instantactivity.variant ? (
                                      instantactivity.variant
                                        .charAt(0)
                                        .toUpperCase() +
                                      instantactivity.variant.slice(1)
                                    ) : (
                                      ""
                                    )}
                                  </p>

                                  <div className="flex ">
                                    <p
                                      className="text-[#818181] me-3"
                                      style={{
                                        color: "#818181",
                                      }}
                                    >
                                      {format(
                                        new Date(instantactivity.created_at),
                                        "MM/dd/yyyy hh:mm a"
                                      )}
                                    </p>
                                    {/* <p
                                      style={{
                                        color: "#818181",
                                      }}
                                    >
                                      {new Date(
                                        instantactivity.created_at
                                      ).toLocaleTimeString("en-US", {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        second: "2-digit",
                                      })}
                                    </p> */}
                                  </div>
                                </div>
                              </StyledTableCell>

                              <StyledTableCell>
                                {/* <p>
                                  { instantactivity.emp_name}
                                </p> */}
                                <p>
                                  {instantactivity.emp_name
                                    ? instantactivity.emp_name
                                        .charAt(0)
                                        .toUpperCase() +
                                      instantactivity.emp_name.slice(1)
                                    : ""}
                                </p>
                              </StyledTableCell>

                              <StyledTableCell>
                                <p>{instantactivity.current_qty}</p>
                              </StyledTableCell>
                              <StyledTableCell>
                                <p>{instantactivity.qty}</p>
                              </StyledTableCell>
                              <StyledTableCell>
                                {/* <p>
                                  {priceFormate(
                                    isNaN(instantactivity.afterAdjustQty)
                                      ? 0
                                      : instantactivity.afterAdjustQty
                                  )}
                                </p> */}
                                <p>
                                  {isNaN(instantactivity.real_qty)
                                    ? 0
                                    : instantactivity.real_qty}
                                </p>
                              </StyledTableCell>
                              <StyledTableCell>
                                <p>{instantactivity.price}</p>
                              </StyledTableCell>
                              <StyledTableCell>
                                <p>
                                  {instantactivity.qty !== 0 &&
                                  instantactivity.price !== 0
                                    ? `$${(
                                        instantactivity.qty *
                                        instantactivity.price
                                      ).toFixed(2)}`
                                    : ""}
                                </p>
                              </StyledTableCell>
                            </StyledTableRow>
                          )
                        )
                      ) : (
                        /* Show "No Data Found" when no data is available and not loading */
                        !loading &&
                        instantactivityDataState.length === 0 && (
                          <StyledTableRow>
                            <StyledTableCell colSpan={7}>
                              <div className="inv-no-record">
                                <NoDataFound message="No Data Found" />
                              </div>
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      )}
                    </TableBody>
                  </StyledTable>
                </TableContainer>
              </InfiniteScroll>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MainInstantDetails;

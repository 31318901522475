import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import SpikeCharts from "../SpikeCharts";
import { useSelector } from "react-redux";
import {
  removeCurrencySign,
  getDefaultDateRange,
  getXAxisData,
  getYAxisTicks,
  getStartDateAndEndDate,
  getPercentValue,
  getLastRangeStartDate,
  formatCurrency,
  dateInYYYYMMDD,
  dataExists,
  getMinMaxValues,
  updateDataObject,
  dataIsMatching,
  deleteKey,
} from "../../../Constants/utils";
import { useDispatch } from "react-redux";
import PasswordShow from "../../../Common/passwordShow";
import { getDiscount } from "../../../Redux/features/Dashboard/Charts/discountChartSlice";

export const DiscountChart = ({
  merchantId,
  activeType,
  presentDate,
  discountChartInView,
  discountChartRef,
}) => {
  const { handleCoockieExpire, getUnAutherisedTokenMessage } = PasswordShow();
  const dispatch = useDispatch();
  const [xAxisDates, setXAxisDates] = useState([]);
  const [discountChartState, setDiscountChartState] = useState(false);
  const discountChart = useSelector((state) => state.discountChart);
  const chartKey = "discountChart";
  const defaultObj = {
    percent: 0,
    xAxisData: [],
    minValue: 0,
    maxValue: 0,
    yAxisOptions: [],
    type: "number",
    totalValue: formatCurrency(0),
  };
  const [discountChartData, setDiscountChartData] = useState(defaultObj);
  const [refreshLoading, setRefreshLoading] = useState(false);

  useEffect(() => {
    const bool =
      discountChart &&
      discountChart?.discountData &&
      discountChart?.discountData?.length > 3 &&
      !discountChart.loading;

    const date = dateInYYYYMMDD(presentDate);
    const dashboardData = sessionStorage.getItem("dashboardData");
    const oldDashboardData = dashboardData ? JSON.parse(dashboardData) : {};

    const dashboardDataExists = dataExists(
      oldDashboardData,
      merchantId,
      activeType,
      date,
      chartKey
    );

    // check if new chart data is same as old one
    const chartsDataIsMatching = dataIsMatching(
      discountChart?.discountData,
      dashboardDataExists.xAxisData,
      "total_discount"
    );

    // setting already present api data from Session storage
    if (Object.keys(dashboardDataExists).length > 0 && chartsDataIsMatching) {
      return;
    } else {
      if (bool) {
        const getValue = (data, index) => {
          const value =
            data &&
            data?.discountData &&
            data?.discountData.length > 2 &&
            data?.discountData[data?.discountData?.length - index];
          return value?.total_discount && parseFloat(value?.total_discount)
            ? parseFloat(value?.total_discount).toFixed(2)
            : 0;
        };

        // for Percentage variance --------------------------------------
        const oldValue = getValue(discountChart, 2);
        const newValue = getValue(discountChart, 1);
        const percent = getPercentValue(oldValue, newValue);

        // for X Axis data ----------------------------------------------
        const dataOfXAxis = discountChart?.discountData?.map((item, index) => ({
          name: xAxisDates[index],
          uv: item.total_discount
            ? parseFloat(parseFloat(item.total_discount).toFixed(2))
            : 0,
        }));

        // for Y Axis Data ----------------------------------------------
        const minAndMaxValue =
          dataOfXAxis &&
          dataOfXAxis?.length > 0 &&
          getMinMaxValues(dataOfXAxis);

        const temp = getYAxisTicks(minAndMaxValue?.maxUv || 0);
        const yAxisOptions = temp?.map((val) => parseFloat(val));

        const amt =
          !isNaN(discountChart?.totalDiscount) && discountChart?.totalDiscount
            ? discountChart?.totalDiscount
            : 0;
        const totalValue = formatCurrency(parseFloat(amt || 0));

        const obj = {
          percent: parseFloat(percent)?.toFixed(2),
          xAxisData: dataOfXAxis,
          minValue: minAndMaxValue.minUv,
          maxValue: minAndMaxValue.maxUv,
          yAxisOptions,
          type: "number",
          totalValue,
        };

        const newDashboardData = updateDataObject(
          date,
          activeType,
          merchantId,
          obj,
          chartKey,
          { ...oldDashboardData }
        );

        sessionStorage.setItem(
          "dashboardData",
          JSON.stringify(newDashboardData)
        );
        setDiscountChartData(() => obj);
      }
    }
  }, [discountChart, xAxisDates]);

  useEffect(() => {
    if (discountChartInView && !discountChartState) {
      setDiscountChartState(() => true);
    }
  }, [discountChartInView, discountChartState]);

  // setting date range
  useEffect(() => {
    const dates = getDefaultDateRange(activeType, presentDate);
    setDiscountChartState(() => false);

    if (!dates || !dates.date_range) return;
    const data = getXAxisData(activeType, dates?.date_range);
    setXAxisDates(data);
  }, [activeType, presentDate]);

  const fetchDiscount = async () => {
    try {
      setRefreshLoading(true);
      const dateRange = getDefaultDateRange(activeType, presentDate);
      if (dateRange && dateRange?.date_range) {
        const { endDate } = getStartDateAndEndDate(dateRange?.date_range);
        const data = {
          merchant_id: merchantId,
          start_date: getLastRangeStartDate(dateRange?.date_range),
          end_date: endDate,
          date_range: dateRange?.date_range,
        };

        await dispatch(getDiscount(data)).unwrap();
      }
    } catch (error) {
      if (error?.status === 401 || error?.response?.status === 401) {
        getUnAutherisedTokenMessage();
        handleCoockieExpire();
      } else if (error?.status === "Network Error") {
        // getNetworkError();
      }
    } finally {
      setRefreshLoading(false);
    }
  };

  // get discount chart data
  useEffect(() => {
    const date = dateInYYYYMMDD(presentDate);
    const dashboardData = sessionStorage.getItem("dashboardData");
    const oldDashboardData = dashboardData ? JSON.parse(dashboardData) : {};

    const dashboardDataExists = dataExists(
      oldDashboardData,
      merchantId,
      activeType,
      date,
      chartKey
    );

    // setting already present api data from Session storage
    if (Object.keys(dashboardDataExists).length > 0) {
      setDiscountChartData(() => dashboardDataExists);
      return;
    }

    if (merchantId && discountChartState) {
      fetchDiscount();
    }
  }, [merchantId, discountChartState, presentDate, activeType]);

  // upon refresh - remove chart data from session storage
  useEffect(() => {
    if (refreshLoading) {
      deleteKey(merchantId, activeType, presentDate, chartKey);
    }
  }, [refreshLoading, presentDate, activeType, merchantId]);

  return (
    <Grid item xs={12} md={6} lg={6} ref={discountChartRef}>
      <SpikeCharts
        title={"Discounts Given (Amount)"}
        growth={discountChartData?.percent}
        mainOutlet={discountChartData?.totalValue}
        amount={discountChartData?.totalValue}
        activeType={activeType}
        xAxisData={discountChartData?.xAxisData}
        maxValue={discountChartData?.maxValue}
        minValue={discountChartData?.minValue}
        yAxisOptions={discountChartData?.yAxisOptions}
        type={discountChartData?.type}
        formatFunction={removeCurrencySign}
        loading={
          discountChartData?.yAxisOptions.length > 0
            ? false
            : discountChart?.loading || discountChart?.isCancel
        }
        presentDate={presentDate}
        refreshAPI={fetchDiscount}
        refreshLoading={refreshLoading}
        yAxisText={"Metric (e.g., revenue in $)"}
      />
    </Grid>
  );
};

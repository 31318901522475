import React, { useState } from "react";
import EditIcon from "../../../Assests/Category/editIcon.svg";
import CrossIcon from "../../../Assests/Dashboard/cross.svg";
import EditEmployeeFormLogic from "../../../Components/StoreSetting/AddEmployee/EditEmployeeFormLogic";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box, Grid, Modal } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "../../../Assests/Category/addIcon.svg";
import AddEmployeeFormLogic from "./AddEmployeeFormLogic";
import Switch from "@mui/material/Switch";
import "../../../Styles/EmployeeList/employeeList.css";
import Chip from '@mui/material/Chip';
import { useSelector } from "react-redux";
const AddEmployeeModal = ({ employee, states, employeeList, setVisible, setEmployeeId }) => {
  const { loadingPin } = useSelector((state) => state.employeeDataList || {});
  const [backendAccessRadio, setBackendAccessRadio] = useState("0");
  const handleOpen = () => setShowModal(true);
  // const handleClose = () => setShowModal(false);
  // const [showModal, setShowModal] = useState(false);
  // const openModal = () => {
  //     setShowModal(true);
  //   };
  const [suggestionList, setSuggestionList] = useState([]);
  const closeModal = () => {
    setShowModal(false);

    const updateData={
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      pin: "",
      wages: "",
      address_line_1: "",
      passwordSource: "manual",
      send_password_via_mail: 0,
      address_line_2: "",
      city: "",
      zipcode: "",
      state: "",
      backendAccess: false,
      enablePOSAccess: false,
      employeePassword: "",
      errors: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        pin: "",
        wages: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        zipcode: "",
        state: "",
        employeePassword: "",
      },
    }
    setValues(updateData)
    setPinData('');
    setEmailData('');
    setSuggestionList([])
  };

  const {
    handleAddEmployeeInput,
    handlePhoneInput,
    handlePinInput,
    handleZipInput,
    values,
    handleAddEmployee,
    submitmessage,
    showModal,
    setShowModal,
    scrollRef,
    setsubmitmessage,
    handleKeyPress,
    handleChangePinValue,
    loader,
    setValues,
    setPinData,
    setEmailData
    // handleBlur,
  } = AddEmployeeFormLogic({ employeeList, setSuggestionList, closeModal, setVisible, setEmployeeId });
  const myStyles = {
    // width: "58rem",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "unset",
  };

  const backendAccessHandler = (event) => {
    setBackendAccessRadio(event.target.value);
  };

  return (
    <>
      <div>
        <div className="box">
          <span
            className="categories-items categories-items-btn"
            onClick={handleOpen}
          >
            <p>
              Add Employee <img src={AddIcon} alt="add-icon" />
            </p>
          </span>

          <Modal
            open={showModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              className="q-custom-modal-content modal_custom EditEmployeeModal overflow-auto"
              sx={{
                width: { xs: "95%", sm: "90%", md: "80%" },
                // height: { xs: "90%", md: "80%" },
              }}
              style={myStyles}
            >
              <div
                className="q-add-categories-section-header"
                style={{ justifyContent: "space-between" }}
              >
                <span style={{ cursor: "unset" }}>Add Employee</span>
                <div className="float-right ">
                  <img
                    src={CrossIcon}
                    alt="icon"
                    className="quic-btn-cancle w-6 h-6 cursor-pointer"
                    onClick={closeModal}
                  />
                </div>
              </div>

              <div className="px-0 custom-scroll mt-3 overflow-auto h-[60vh]  xl:h-[60vh] xxl:h-auto pb-20 md:pb-20 xl:pb-15 xxl:pb-0">
                <div className=" grid sm:grid-cols-1 md:grid-cols-3 gap-2 px-3 lg:px-6">
                  <div className="">
                    <div className="input_area">
                      <label>
                        First Name <span className="Asterisk_error">*</span>
                      </label>
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                        }}
                        id="outlined-basic"
                        type="text"
                        name="firstname"
                        placeholder="First Name"
                        value={values.firstname}
                        onChange={handleAddEmployeeInput}
                        className="q-custom-input-field"
                        variant="outlined"
                        size="small"
                      />
                      <span className="input-error">
                        {values.errors.firstname !== ""
                          ? values.errors.firstname
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <div className="input_area">
                      <label>Last Name</label>
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                        }}
                        id="outlined-basic"
                        type="text"
                        name="lastname"
                        placeholder="Last Name"
                        value={values.lastname}
                        onChange={handleAddEmployeeInput}
                        className="q-custom-input-field"
                        variant="outlined"
                        size="small"
                      />
                      <span className="input-error">
                        {values.errors.lastname !== ""
                          ? values.errors.lastname
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <div className="input_area">
                      <label>
                        Email Address{" "}
                        {values?.backendAccess || values?.enablePOSAccess ? (
                          <span className="Asterisk_error">*</span>
                        ) : null}
                      </label>
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                        }}
                        id="outlined-basic"
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={values.email}
                        onChange={handleAddEmployeeInput}
                        className="q-custom-input-field"
                        variant="outlined"
                        size="small"
                      />
                      <span className="input-error">{values.errors.email}</span>
                    </div>
                  </div>
                </div>
                <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-2 px-3 lg:px-6">
                  <div className="col-span-2 md:col-span-1">
                    <div className="input_area">
                      <label>Phone Number</label>
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                        }}
                        id="outlined-basic"
                        type="text"
                        name="phone"
                        placeholder="Phone"
                        value={values.phone}
                        inputProps={{ maxLength: 10 }}
                        // onChange={handlePhoneInput}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            handleAddEmployeeInput(e);
                          }
                        }}
                        className="q-custom-input-field"
                        variant="outlined"
                        size="small"
                        onKeyPress={handleKeyPress}
                      />
                      <span className="input-error">
                        {values.errors.phone !== "" ? values.errors.phone : ""}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <div className="input_area">
                      <label>
                        PIN <span className="Asterisk_error">*</span>
                      </label>
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                        }}
                        id="outlined-basic"
                        type="text"
                        name="pin"
                        placeholder="Pin"
                        value={values.pin}
                        inputProps={{ maxLength: 4 }}
                        onChange={handleAddEmployeeInput}
                        // onChange={handlePinInput}
                        className="q-custom-input-field"
                        variant="outlined"
                        size="small"
                        onKeyPress={handleKeyPress}
                        // onBlur={() => handleBlur("pin")}
                      />
                      <span className="input-error err-size" >
                        {values.errors.pin !== "" ? values.errors.pin : ""}
                      </span>
                        {
                          (values.errors.pin === "Pin already exist" || suggestionList.length > 0) && !loadingPin ? 
                          <span style={{display:"block", fontSize:'12px', marginTop:'5px'}}>
                            Suggested Pin &nbsp; {suggestionList?.length ? suggestionList.map((i)=>{
                              return <Chip label={i} variant="outlined" className="suggestListItem" style={{backgroundColor: values?.pin?.toLowerCase() === i?.toLowerCase() ? "#e9e9e9": "#fff"}} onClick={()=> handleChangePinValue(i)}/>
                            }):''}
                          </span>: null
                        }
                   {loadingPin ? (
                    <CircularProgress 
                      size="15px" 
                      sx={{ color: '#000' }} // Custom color via sx prop
                    />
                  ) : null}
                    </div>
                  </div>
                  <div className="">
                    <div className="input_area">
                      <label>Wages ($/hr)</label>

                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                        }}
                        id="outlined-basic"
                        type="text"
                        name="wages"
                        placeholder="Wages Per Hour"
                        value={values.wages}
                        onChange={handleAddEmployeeInput}
                        className="q-custom-input-field"
                        variant="outlined"
                        size="small"
                      />
                      <span className="input-error">
                        {values.errors.wages !== "" ? values.errors.wages : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-2 px-3 lg:px-6">
                  <div className="col-span-3">
                    <div className="input_area">
                      <label>Address</label>

                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                        }}
                        id="outlined-basic"
                        type="text"
                        name="address_line_1"
                        placeholder="Address Line 1"
                        value={values.address_line_1}
                        onChange={handleAddEmployeeInput}
                        className="q-custom-input-field"
                        variant="outlined"
                        size="small"
                      />
                      <span className="input-error">
                        {values.errors.address_line_1 !== ""
                          ? values.errors.address_line_1
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="col-span-3 md:col-span-2">
                    <div className="grid grid-cols-2 gap-2">
                      <div className="">
                        <div className="input_area">
                          <TextField
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "black",
                                },
                              },
                            }}
                            id="outlined-basic"
                            type="text"
                            name="city"
                            placeholder="City"
                            value={values.city}
                            onChange={handleAddEmployeeInput}
                            className="q-custom-input-field"
                            variant="outlined"
                            size="small"
                          />
                          <span className="input-error">
                            {values.errors.city !== ""
                              ? values.errors.city
                              : ""}
                          </span>
                        </div>
                      </div>
                      <div className="">
                        <div className="input_area">
                          <TextField
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "black",
                                },
                              },
                            }}
                            id="outlined-basic"
                            type="text"
                            name="zipcode"
                            placeholder="Zip"
                            value={values.zipcode}
                            inputProps={{ maxLength: 5 }}
                            onChange={handleZipInput}
                            className="q-custom-input-field"
                            variant="outlined"
                            size="small"
                          />
                          <span className="input-error">
                            {values.errors.zipcode !== ""
                              ? values.errors.zipcode
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-3 md:col-span-1">
                    <div className="input_area addEmployeeState">
                      <Select
                        size="small"
                        name="state"
                        value={values.state}
                        onChange={handleAddEmployeeInput}
                        className="q-custom-input-field"
                        displayEmpty
                      >
                        <MenuItem value="">Select a state</MenuItem>
                        {states &&
                          states.map((state, index) => (
                            <MenuItem key={index} value={state.State}>
                              {state.State}
                            </MenuItem>
                          ))}
                      </Select>

                      <span className="input-error">
                        {values.errors.state !== "" ? values.errors.state : ""}
                      </span>
                    </div>
                  </div>
                </div>

                {/* add new switch button and password field  */}
                <div
                  className="grid grid-cols-2 md:grid-cols-3 gap-2 px-3 lg:px-6"
                >
                  <div
                   
                    className="col-span-3 md:col-span-1 flex items-center"
                  >
                    <label>Enable Backend Access</label>
                    <Switch
                    className="ml-2"
                      checked={values?.backendAccess}
                      name="backendAccess"
                      onChange={(event) => {
                        handleAddEmployeeInput(event);
                      }}
                      value={values?.backendAccess ? false : true}

                    />
                  </div>
                  <div
                   
                    className="col-span-3 md:col-span-1 flex items-center"
                  >
                    <label>Enable POS Access</label>
                    <Switch
                    className="ml-2"
                      checked={values?.posAccess}
                      name="enablePOSAccess"
                      onChange={(event) => {
                        handleAddEmployeeInput(event);
                      }}
                      value={values?.enablePOSAccess ? false : true}

                    />
                  </div>
                </div>
                {/* check enable backend access  */}
                {(values?.backendAccess || values?.enablePOSAccess) && (
                  <>
                    {/* add new radio buttons  */}
                    {/* <Grid
                      container
                      spacing={3}
                      sx={{
                        pb: 2.5,
                      }}
                    >
                      <Grid item xs={12} sm={4}>
                        <div className="input_rediobutton_area">
                          <input
                            className="inputredio"
                            type="radio"
                            id="radio1"
                            name="passwordSource"
                            value="manual"
                            checked={values.passwordSource === "manual"}
                            onChange={handleAddEmployeeInput}
                          />
                          <label htmlFor="radio1">Set password manually</label>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="input_rediobutton_area">
                          <input
                            className="inputredio"
                            type="radio"
                            id="radio2"
                            name="passwordSource"
                            value="sendLink"
                            checked={values.passwordSource === "sendLink"}
                            onChange={handleAddEmployeeInput}
                            // onKeyDown={keyEnter}
                          />
                          <label htmlFor="radio2">Set password via link</label>
                        </div>
                      </Grid>
                    </Grid> */}

                    {/* <Grid
                      item
                      xs={12}
                      sm={4}
                      sx={{
                        pb: 2,
                      }}
                    >
                      <div
                        className="input_rediobutton_area"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div className="category-checkmark-div ">
                          <label className="category-checkmark-label">
                            Send Password Via Link
                            <input
                              className="inputredio"
                              type="checkbox"
                              id="radio2"
                              name="send_password_via_mail"
                              value={values?.send_password_via_mail}
                              checked={values?.send_password_via_mail === "1"}
                              onChange={handleAddEmployeeInput}
                            />
                            <span className="category-checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </Grid> */}

                    {/* manula entry check container  */}
                    {/* {values.passwordSource === "manual" && ( */}
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-2 px-3 lg:px-6">
                      <div className="col-span-3 md:col-span-2">
                        <div className="grid grid-cols-2 gap-2 mt-5">
                          <div className="">
                            <div className="input_area">
                              <label>Password for Employee</label>
                              <TextField
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black",
                                    },
                                  },
                                }}
                                id="outlined-basic"
                                type="text"
                                name="employeePassword"
                                placeholder="Password for Employee"
                                value={values.employeePassword}
                                onChange={handleAddEmployeeInput}
                                className="q-custom-input-field"
                                variant="outlined"
                                size="small"
                              />
                              <span className="input-error">
                                {values.errors.employeePassword !== ""
                                  ? values.errors.employeePassword
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* )} */}

                    {/* manula entry check container  */}
                    {/* {values.passwordSource === "sendLink" && (
                      <Grid
                        sm={8}
                        sx={{
                          mb: 0,
                          width: "100%",
                          height: "auto",
                          borderRadius: "8px",
                          backgroundColor: "#fff",
                          overflow: "hidden",
                         
                          bottom: { xs: 0, md: "unset" },
                        }} className=" px-3 lg:px-0 py-4 flex justify-end gap-3">
                         
                        <button
                          
                          className="quic-btn quic-btn-save attributeUpdateBTN"
                        
                        >
                         
                         Send invitation link
                          
                        </button>
                     
                        
                      </Grid>
                    )} */}
                  </>
                )}

                <Grid
                  sx={{
                     // marginTop: 3,
                     mb: 0,
                     // p:0,
                     // px:1.5,
                     width: "100%",
                     height: "auto",
                     // boxShadow: { xs: "0 5px 10px 1px #ddd", sm: "unset" },
                     // mx: { xs: "-10px", md: "0px" },
                    //  borderRadius: "8px",
                     backgroundColor: "#fff",
                     // padding: "0px",
                     overflow: "hidden",
                     position: { xs: "fixed", xxl: "relative" },
                     bottom: { xs: 0, xxl: "unset" },
                  }}
                  className=" px-3 xl:px-6 py-4 flex justify-end gap-3"
                >
                  <button
                    onClick={handleAddEmployee}
                    className="quic-btn quic-btn-save attributeUpdateBTN"
                    disabled={loader}
                  >
                    {loader ? (
                      <>
                        <CircularProgress
                          color={"inherit"}
                          className="loaderIcon"
                          width={15}
                          size={15}
                        />
                        Add
                      </>
                    ) : (
                      "Add"
                    )}
                  </button>
                  <button
                    onClick={closeModal}
                    className="quic-btn quic-btn-cancle"
                  >
                    {" "}
                    Cancel{" "}
                  </button>
                </Grid>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default AddEmployeeModal;

import React, { useState } from "react";
import EditIcon from "../../../Assests/Category/editIcon.svg";
import CrossIcon from "../../../Assests/Dashboard/cross.svg";
import EditEmployeeFormLogic from "../../../Components/StoreSetting/AddEmployee/EditEmployeeFormLogic";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box, Grid, Modal } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from "@mui/material/Switch";
import { useSelector } from "react-redux";
import Chip from '@mui/material/Chip';

const EditEmployeeModal = ({ employee, states, employeeList }) => {
  const { loadingPin } = useSelector((state) => state.employeeDataList || {});
  const [suggestionList, setSuggestionList] = useState([]);

  // const [open, setOpen] = useState(false);
  const handleOpen = () => setShowModal(true);

  

  const {
    handleSendInvitationLinkEmployee,
    loaderForInvitation,
    handleEditEmployeeInput,
    handlePhoneInput,
    handlePinInput,
    handleZipInput,
    values,
    handleEditEmployee,
    submitmessage,
    showModal,
    setShowModal,
    scrollRef,
    setsubmitmessage,
    loader,
    havePassword,
    handleChangePinValue,
    setValues,
    setPinData,
    setEmailData
  } = EditEmployeeFormLogic({ employee, employeeList, setSuggestionList });

  const closeModal = () => {
    setShowModal(false);

    const updateData = {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      pin: "",
      wages: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      zipcode: "",
      state: "",
      backendAccess: false,
      enablePOSAccess: false,
      passwordSource: "manual",
      send_password_via_mail: 0,
      employeePassword: "",
      errors: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        pin: "",
        wages: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        zipcode: "",
        state: "",
        employeePassword: "",
      },
    }
    setValues(updateData)
    setPinData('');
    setEmailData('');
    setSuggestionList([])
  };



  const myStyles = {
    // width: "58rem",
    position: "absolute",
    top: "47%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "unset",
  };

  return (
    <>
      <div>
        <div className="box">
          <span
            className="categories-items categories-items-btn"
            onClick={handleOpen}
          >
            {" "}
            <img src={EditIcon} alt="edit-icon" />{" "}
          </span>

          <Modal
            open={showModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              className="q-custom-modal-content modal_custom EditEmployeeModal"
              sx={{
                width: { xs: "95%", sm: "90%", md: "80%" },
                // height: { xs: "90%", md: "80%" },
              }}
              style={myStyles}
            >
              <div
                className="q-add-categories-section-header"
                style={{ justifyContent: "space-between" }}
              >
                <span style={{ cursor: "unset" }}>Edit Employee</span>
                <div className="">
                  <img
                    src={CrossIcon}
                    alt="icon"
                    className="quic-btn-cancle w-6 h-6 cursor-pointer"
                    onClick={closeModal}
                  />
                </div>
              </div>

              <div className="px-0 custom-scroll mt-3 overflow-auto h-[60vh]  xl:h-[60vh] xxl:h-auto pb-20 md:pb-20 xl:pb-15 xxl:pb-0">
                <div className="=  grid sm:grid-cols-1 md:grid-cols-3 gap-2 px-3 lg:px-6">
                  <div className="">
                    <div className="input_area text-left">
                      <label>
                        First Name <span className="Asterisk_error">*</span>
                      </label>
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                        }}
                        id="outlined-barelativesic"
                        type="text"
                        name="firstname"
                        placeholder="First Name"
                        value={values.firstname}
                        onChange={handleEditEmployeeInput}
                        className="q-custom-input-field"
                        variant="outlined"
                        size="small"
                      />
                      <span className="input-error">
                        {values.errors.firstname !== ""
                          ? values.errors.firstname
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <div className="input_area text-left">
                      <label>Last Name</label>
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                        }}
                        id="outlined-basic"
                        type="text"
                        name="lastname"
                        placeholder="Last Name"
                        value={values.lastname}
                        onChange={handleEditEmployeeInput}
                        className="q-custom-input-field"
                        variant="outlined"
                        size="small"
                      />
                      <span className="input-error">
                        {values.errors.lastname !== ""
                          ? values.errors.lastname
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <div className="input_area text-left">
                      <label>
                        Email Address{" "}
                        {values?.backendAccess || values?.enablePOSAccess ? (
                          <span className="Asterisk_error">*</span>
                        ) : null}
                      </label>
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                        }}
                        id="outlined-basic"
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={values.email}
                        onChange={handleEditEmployeeInput}
                        className="q-custom-input-field"
                        variant="outlined"
                        size="small"
                      />
                      <span className="input-error">{values.errors.email}</span>
                    </div>
                  </div>
                </div>
                <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-2 px-3 lg:px-6">
                  <div className="col-span-2 md:col-span-1">
                    <div className="input_area text-left">
                      <label>Phone Number</label>
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                        }}
                        id="outlined-basic"
                        type="text"
                        name="phone"
                        placeholder="Phone"
                        value={values.phone}
                        inputProps={{ maxLength: 10 }}
                        onChange={handlePhoneInput}
                        className="q-custom-input-field"
                        variant="outlined"
                        size="small"
                      />
                      <span className="input-error">
                        {values.errors.phone !== "" ? values.errors.phone : ""}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <div className="input_area text-left">
                      <label>
                        PIN <span className="Asterisk_error">*</span>
                      </label>
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                        }}
                        id="outlined-basic"
                        type="text"
                        name="pin"
                        placeholder="Pin"
                        value={values.pin}
                        onChange={handlePinInput}
                        inputProps={{ maxLength: 4 }}
                        className="q-custom-input-field"
                        variant="outlined"
                        size="small"
                      />
                      <span className="input-error err-size">
                        {values.errors.pin !== "" ? values.errors.pin : ""}
                      </span>
                      {
                          (values.errors.pin === "Pin already exist" || suggestionList.length > 0) && !loadingPin ? 
                          <span style={{display:"block", fontSize:'12px', marginTop:'5px'}}>
                            Suggested Pin &nbsp; {suggestionList?.length ? suggestionList.map((i)=>{
                              return <Chip label={i} variant="outlined" className="suggestListItem" style={{backgroundColor: values?.pin?.toLowerCase() === i?.toLowerCase() ? "#e9e9e9": "#fff"}} onClick={()=> handleChangePinValue(i)}/>
                            }):''}
                          </span>: null
                        }
                   {loadingPin ? (
                    <CircularProgress 
                      size="15px" 
                      sx={{ color: '#000' }} // Custom color via sx prop
                    />
                  ) : null}
                    </div>
                  </div>
                  <div className="">
                    <div className="input_area text-left">
                      <label>Wages ($/hr)</label>
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                        }}
                        id="outlined-basic"
                        type="text"
                        name="wages"
                        placeholder="Wages Per Hour"
                        value={values.wages}
                        onChange={handleEditEmployeeInput}
                        className="q-custom-input-field"
                        variant="outlined"
                        size="small"
                      />
                      <span className="input-error">
                        {values.errors.wages !== "" ? values.errors.wages : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-2 px-3 lg:px-6">
                  <div className="col-span-3">
                    <div className="input_area text-left">
                      <label>Address</label>
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                        }}
                        id="outlined-basic"
                        type="text"
                        name="address_line_1"
                        placeholder="Address Line 1"
                        value={values.address_line_1}
                        onChange={handleEditEmployeeInput}
                        className="q-custom-input-field"
                        variant="outlined"
                        size="small"
                      />
                      <span className="input-error">
                        {values.errors.address_line_1 !== ""
                          ? values.errors.address_line_1
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="col-span-3 md:col-span-2">
                    <div className="grid grid-cols-2 gap-2">
                      <div className="">
                        <div className="input_area">
                          <TextField
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "black",
                                },
                              },
                            }}
                            id="outlined-basic"
                            type="text"
                            name="city"
                            placeholder="City"
                            value={values.city}
                            onChange={handleEditEmployeeInput}
                            className="q-custom-input-field"
                            variant="outlined"
                            size="small"
                          />
                          <span className="input-error">
                            {values.errors.city !== ""
                              ? values.errors.city
                              : ""}
                          </span>
                        </div>
                      </div>
                      <div className="">
                        <div className="input_area">
                          <TextField
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "black",
                                },
                              },
                            }}
                            id="outlined-basic"
                            type="text"
                            name="zipcode"
                            placeholder="Zip"
                            value={values.zipcode}
                            onChange={handleZipInput}
                            inputProps={{ maxLength: 5 }}
                            className="q-custom-input-field"
                            variant="outlined"
                            size="small"
                          />
                          <span className="input-error">
                            {values.errors.zipcode !== ""
                              ? values.errors.zipcode
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-3 md:col-span-1">
                    <div className="input_area addEmployeeState text-left">
                      <Select
                        size="small"
                        name="state"
                        value={values.state}
                        onChange={handleEditEmployeeInput}
                        className="q-custom-input-field"
                        displayEmpty
                      >
                        <MenuItem value="">Select a state</MenuItem>
                        {states &&
                          states.map((state, index) => (
                            <MenuItem
                              key={index}
                              value={state.State}
                              selected={
                                values.state === state.State ? "selected" : ""
                              }
                            >
                              {state.State}
                            </MenuItem>
                          ))}
                      </Select>

                      <span className="input-error">
                        {values.errors.state !== "" ? values.errors.state : ""}
                      </span>
                    </div>
                  </div>
                </div>
                {submitmessage ? (
                  <span className="error">{submitmessage}</span>
                ) : (
                  ""
                )}
                <div className="grid grid-cols-2 md:grid-cols-3 gap-2 px-3 lg:px-6">
                  <div
                   className="col-span-3 md:col-span-1 flex items-center"
                  >
                    <label>Enable Backend Access</label>
                    <Switch
                      checked={values?.backendAccess}
                      name="backendAccess"
                      onChange={(event) => {
                        handleEditEmployeeInput(event);
                        // handBackendAccess(event.target.checked);
                      }}
                      value={values?.backendAccess ? false : true}
                    />
                  </div>
                  <div
                 className="col-span-3 md:col-span-1 flex items-center"
                  >
                    <label>Enable POS Access</label>
                    <Switch
                      checked={values?.enablePOSAccess}
                      name="enablePOSAccess"
                      onChange={(event) => {
                        handleEditEmployeeInput(event);
                      }}
                      value={values?.enablePOSAccess ? false : true}
                    />
                  </div>
                </div>

                {values &&
                  (values?.backendAccess || values?.enablePOSAccess) &&
                  havePassword && (
                    /* <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-2 px-3 lg:px-6">
                    <div className="col-span-2 md:col-span-2">
                      <div className="grid grid-cols-1 gap-2">
                        <div className="">
                          {havePassword && (
                            <div className="input_area">
                              <label>Password for Employee</label>
                              <TextField
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black",
                                    },
                                  },
                                }}
                                id="outlined-basic"
                                type="text"
                                name="employeePassword"
                                placeholder="Password for Employee"
                                value={values.employeePassword}
                                onChange={handleEditEmployeeInput}
                                className="q-custom-input-field"
                                variant="outlined"
                                size="small"
                              />
                              <span className="input-error">
                                {values.errors.employeePassword !== ""
                                  ? values.errors.employeePassword
                                  : ""}
                              </span>
                            </div>
                          )}
                          <Grid
                            sx={{
                              mb: 0,
                              width: "100%",
                              height: "auto",
                              borderRadius: "8px",
                              backgroundColor: "#fff",
                              overflow: "hidden",
                              // position: { xs: "fixed", md: "relative" },
                              bottom: { xs: 0, md: "unset" },
                            }}
                            className=" lg:px-0 py-4 flex justify-end gap-3"
                          >
                            {havePassword && (
                              <button
                                onClick={handleSendInvitationLinkEmployee}
                                className="quic-btn quic-btn-save attributeUpdateBTN w-full"
                                disabled={loaderForInvitation}
                              >
                                {loaderForInvitation ? (
                                  <>
                                    <CircularProgress
                                      color={"inherit"}
                                      className="loaderIcon"
                                      width={15}
                                      size={15}
                                    />
                                    Set password via link
                                  </>
                                ) : (
                                  "Reset Password"
                                )}
                              </button>
                            )}
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </div> */
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-2 px-3 lg:px-6">
                      <div className="col-span-3 md:col-span-2">
                        <div className="grid grid-cols-2 gap-2 mt-5">
                          <div className="">
                            <div className="input_area">
                              <label>Password for Employee</label>

                              <TextField
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black",
                                    },
                                  },
                                }}
                                id="outlined-basic"
                                type="text"
                                name="employeePassword"
                                placeholder="Password for Employee"
                                value={values.employeePassword}
                                onChange={handleEditEmployeeInput}
                                className="q-custom-input-field"
                                variant="outlined"
                                size="small"
                              />
                              <span className="input-error">
                                {values.errors.employeePassword !== ""
                                  ? values.errors.employeePassword
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {(values?.backendAccess || values?.enablePOSAccess) &&
                  !havePassword && (
                    <>
                      {/* add new radio buttons  */}
                      {/* <Grid
                      container
                      spacing={3}
                      sx={{
                        pb: 2.5,
                      }}
                    >
                      <Grid item xs={12} sm={4}>
                        <div className="input_rediobutton_area">
                          <input
                            className="inputredio"
                            type="radio"
                            id="radio1"
                            name="passwordSource"
                            value="manual"
                            checked={values.passwordSource === "manual"}
                            onChange={handleEditEmployeeInput}
                          />
                          <label htmlFor="radio1">Set password manually</label>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="input_rediobutton_area">
                          <input
                            className="inputredio"
                            type="radio"
                            id="radio2"
                            name="passwordSource"
                            value="sendLink"
                            checked={values.passwordSource === "sendLink"}
                            onChange={handleEditEmployeeInput}
                            // onKeyDown={keyEnter}
                          />
                          <label htmlFor="radio2">Set password via link</label>
                        </div>
                      </Grid>
                    </Grid> */}

                      {/* manula entry check container  */}
                      {/* {values.passwordSource === "manual" && ( */}
                      <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-2 px-3 lg:px-6">
                        <div className="col-span-3 md:col-span-1">
                          <div className="grid gap-2 mt-5">
                            <div className="">
                              <div className="input_area">
                                <label>Password for Employee</label>
                                <TextField
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor: "black",
                                      },
                                    },
                                  }}
                                  id="outlined-basic"
                                  type="text"
                                  name="employeePassword"
                                  placeholder="Password for Employee"
                                  value={values.employeePassword}
                                  onChange={handleEditEmployeeInput}
                                  className="q-custom-input-field"
                                  variant="outlined"
                                  size="small"
                                />
                                <span className="input-error">
                                  {values.errors.employeePassword !== ""
                                    ? values.errors.employeePassword
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* )} */}

                      {/* manula entry check container  */}
                      {/* {values.passwordSource === "sendLink" && (
                      <Grid
                        sm={8}
                        sx={{
                          mb: 0,
                          width: "100%",
                          height: "auto",
                          borderRadius: "8px",
                          backgroundColor: "#fff",
                          overflow: "hidden",
                         
                          bottom: { xs: 0, md: "unset" },
                        }} className=" px-3 lg:px-0 py-4 flex justify-end gap-3">
                         
                        <button
                          
                          className="quic-btn quic-btn-save attributeUpdateBTN"
                        
                        >
                         
                         Send invitation link
                          
                        </button>
                     
                        
                      </Grid>
                    )} */}
                    </>
                  )}

              </div>
                <Grid
                  sx={{
                    // marginTop: 3,
                    mb: 0,
                    // p:0,
                    // px:1.5,
                    width: "100%",
                    height: "auto",
                    // boxShadow: { xs: "0 5px 10px 1px #ddd", sm: "unset" },
                    // mx: { xs: "-10px", md: "0px" },
                    // borderRadius: "8px",
                    backgroundColor: "#fff",
                    // padding: "0px",
                    overflow: "hidden",
                    position: { xs: "fixed", xl: "relative" },
                    bottom: { xs: 0, xl: "unset" },
                  }}
                  className=" px-3 xl:px-6 py-4 flex justify-end gap-3"
                >
                  <button
                    onClick={handleEditEmployee}
                    className="quic-btn quic-btn-save attributeUpdateBTN"
                    disabled={loader}
                  >
                    {loader ? (
                      <>
                        <CircularProgress
                          color={"inherit"}
                          className="loaderIcon"
                          width={15}
                          size={15}
                        />
                        Update
                      </>
                    ) : (
                      "Update"
                    )}
                  </button>
                  <button
                    onClick={closeModal}
                    className="quic-btn quic-btn-cancle"
                  >
                    Cancel
                  </button>
                </Grid>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default React.memo(EditEmployeeModal);
